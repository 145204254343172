// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment N2ColumnHero on N2ColumnHeroBannerContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    background_colour  {
      __typename
      value
    }
    background_colour_2  {
      __typename
      value
    }
    copy_block_alignment  {
      __typename
      value  {
        __typename
        codename
      }
    }
    copy_block_alignment_2  {
      __typename
      value  {
        __typename
        codename
      }
    }
    image_treatment  {
      __typename
      value  {
        __typename
        codename
      }
    }
    image_treatment_2  {
      __typename
      value  {
        __typename
        codename
      }
    }
    title  {
      __typename
      value
    }
    title_2  {
      __typename
      value
    }
    highlight_text_colour  {
      __typename
      value
    }
    highlight_text_colour_2  {
      __typename
      value
    }
    title_font_style  {
      __typename
      value  {
        __typename
        codename
      }
    }
    title_font_style_2  {
      __typename
      value  {
        __typename
        codename
      }
    }
    title_text_colour  {
      __typename
      value
    }
    title_text_colour_2  {
      __typename
      value
    }
    title_font_size  {
      __typename
      value  {
        __typename
        codename
      }
    }
    title_font_size_2  {
      __typename
      value  {
        __typename
        codename
      }
    }
    description  {
      __typename
      value
    }
    description_2  {
      __typename
      value
    }
    description_text_colour  {
      __typename
      value
    }
    description_text_colour_2  {
      __typename
      value
    }
    description_font_size  {
      __typename
      value  {
        __typename
        codename
      }
    }
    description_font_size_2  {
      __typename
      value  {
        __typename
        codename
      }
    }
    cta_s  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    cta_s_2  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    desktop_image  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    desktop_image_2  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    tablet_image  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    tablet_image_2  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    mobile_image  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    mobile_image_2  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    display_badge  {
      __typename
      value  {
        __typename
        codename
      }
    }
    badge_alignment  {
      __typename
      value  {
        __typename
        codename
      }
    }
    badge_text  {
      __typename
      value
    }
    badge_text_colour  {
      __typename
      value
    }
    badge_background_colour  {
      __typename
      value
    }
    badge_outline_colour  {
      __typename
      value
    }
  }
  ${frag_0}
`)(Gql__CallToAction__Fragment.CallToAction.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.background_colour;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.background_colour_2;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.copy_block_alignment;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.copy_block_alignment_2;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$9) : undefined
    };
  }
  var value$10 = value.image_treatment;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$11) : undefined
    };
  }
  var value$12 = value.image_treatment_2;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$13) : undefined
    };
  }
  var value$14 = value.title;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.title_2;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.highlight_text_colour;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.highlight_text_colour_2;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? value$21 : undefined
    };
  }
  var value$22 = value.title_font_style;
  var tmp$10;
  if (value$22 == null) {
    tmp$10 = undefined;
  } else {
    var value$23 = value$22.value;
    tmp$10 = {
      __typename: value$22.__typename,
      value: !(value$23 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$23) : undefined
    };
  }
  var value$24 = value.title_font_style_2;
  var tmp$11;
  if (value$24 == null) {
    tmp$11 = undefined;
  } else {
    var value$25 = value$24.value;
    tmp$11 = {
      __typename: value$24.__typename,
      value: !(value$25 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$25) : undefined
    };
  }
  var value$26 = value.title_text_colour;
  var tmp$12;
  if (value$26 == null) {
    tmp$12 = undefined;
  } else {
    var value$27 = value$26.value;
    tmp$12 = {
      __typename: value$26.__typename,
      value: !(value$27 == null) ? value$27 : undefined
    };
  }
  var value$28 = value.title_text_colour_2;
  var tmp$13;
  if (value$28 == null) {
    tmp$13 = undefined;
  } else {
    var value$29 = value$28.value;
    tmp$13 = {
      __typename: value$28.__typename,
      value: !(value$29 == null) ? value$29 : undefined
    };
  }
  var value$30 = value.title_font_size;
  var tmp$14;
  if (value$30 == null) {
    tmp$14 = undefined;
  } else {
    var value$31 = value$30.value;
    tmp$14 = {
      __typename: value$30.__typename,
      value: !(value$31 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$31) : undefined
    };
  }
  var value$32 = value.title_font_size_2;
  var tmp$15;
  if (value$32 == null) {
    tmp$15 = undefined;
  } else {
    var value$33 = value$32.value;
    tmp$15 = {
      __typename: value$32.__typename,
      value: !(value$33 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$33) : undefined
    };
  }
  var value$34 = value.description;
  var tmp$16;
  if (value$34 == null) {
    tmp$16 = undefined;
  } else {
    var value$35 = value$34.value;
    tmp$16 = {
      __typename: value$34.__typename,
      value: !(value$35 == null) ? value$35 : undefined
    };
  }
  var value$36 = value.description_2;
  var tmp$17;
  if (value$36 == null) {
    tmp$17 = undefined;
  } else {
    var value$37 = value$36.value;
    tmp$17 = {
      __typename: value$36.__typename,
      value: !(value$37 == null) ? value$37 : undefined
    };
  }
  var value$38 = value.description_text_colour;
  var tmp$18;
  if (value$38 == null) {
    tmp$18 = undefined;
  } else {
    var value$39 = value$38.value;
    tmp$18 = {
      __typename: value$38.__typename,
      value: !(value$39 == null) ? value$39 : undefined
    };
  }
  var value$40 = value.description_text_colour_2;
  var tmp$19;
  if (value$40 == null) {
    tmp$19 = undefined;
  } else {
    var value$41 = value$40.value;
    tmp$19 = {
      __typename: value$40.__typename,
      value: !(value$41 == null) ? value$41 : undefined
    };
  }
  var value$42 = value.description_font_size;
  var tmp$20;
  if (value$42 == null) {
    tmp$20 = undefined;
  } else {
    var value$43 = value$42.value;
    tmp$20 = {
      __typename: value$42.__typename,
      value: !(value$43 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$43) : undefined
    };
  }
  var value$44 = value.description_font_size_2;
  var tmp$21;
  if (value$44 == null) {
    tmp$21 = undefined;
  } else {
    var value$45 = value$44.value;
    tmp$21 = {
      __typename: value$44.__typename,
      value: !(value$45 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$45) : undefined
    };
  }
  var value$46 = value.cta_s;
  var tmp$22;
  if (value$46 == null) {
    tmp$22 = undefined;
  } else {
    var value$47 = value$46.value;
    tmp$22 = {
      __typename: value$46.__typename,
      value: !(value$47 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$47) : undefined
    };
  }
  var value$48 = value.cta_s_2;
  var tmp$23;
  if (value$48 == null) {
    tmp$23 = undefined;
  } else {
    var value$49 = value$48.value;
    tmp$23 = {
      __typename: value$48.__typename,
      value: !(value$49 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$49) : undefined
    };
  }
  var value$50 = value.desktop_image;
  var tmp$24;
  if (value$50 == null) {
    tmp$24 = undefined;
  } else {
    var value$51 = value$50.value;
    tmp$24 = {
      __typename: value$50.__typename,
      value: !(value$51 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$51) : undefined
    };
  }
  var value$52 = value.desktop_image_2;
  var tmp$25;
  if (value$52 == null) {
    tmp$25 = undefined;
  } else {
    var value$53 = value$52.value;
    tmp$25 = {
      __typename: value$52.__typename,
      value: !(value$53 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$53) : undefined
    };
  }
  var value$54 = value.tablet_image;
  var tmp$26;
  if (value$54 == null) {
    tmp$26 = undefined;
  } else {
    var value$55 = value$54.value;
    tmp$26 = {
      __typename: value$54.__typename,
      value: !(value$55 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$55) : undefined
    };
  }
  var value$56 = value.tablet_image_2;
  var tmp$27;
  if (value$56 == null) {
    tmp$27 = undefined;
  } else {
    var value$57 = value$56.value;
    tmp$27 = {
      __typename: value$56.__typename,
      value: !(value$57 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$57) : undefined
    };
  }
  var value$58 = value.mobile_image;
  var tmp$28;
  if (value$58 == null) {
    tmp$28 = undefined;
  } else {
    var value$59 = value$58.value;
    tmp$28 = {
      __typename: value$58.__typename,
      value: !(value$59 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$59) : undefined
    };
  }
  var value$60 = value.mobile_image_2;
  var tmp$29;
  if (value$60 == null) {
    tmp$29 = undefined;
  } else {
    var value$61 = value$60.value;
    tmp$29 = {
      __typename: value$60.__typename,
      value: !(value$61 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$61) : undefined
    };
  }
  var value$62 = value.display_badge;
  var tmp$30;
  if (value$62 == null) {
    tmp$30 = undefined;
  } else {
    var value$63 = value$62.value;
    tmp$30 = {
      __typename: value$62.__typename,
      value: !(value$63 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$63) : undefined
    };
  }
  var value$64 = value.badge_alignment;
  var tmp$31;
  if (value$64 == null) {
    tmp$31 = undefined;
  } else {
    var value$65 = value$64.value;
    tmp$31 = {
      __typename: value$64.__typename,
      value: !(value$65 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$65) : undefined
    };
  }
  var value$66 = value.badge_text;
  var tmp$32;
  if (value$66 == null) {
    tmp$32 = undefined;
  } else {
    var value$67 = value$66.value;
    tmp$32 = {
      __typename: value$66.__typename,
      value: !(value$67 == null) ? value$67 : undefined
    };
  }
  var value$68 = value.badge_text_colour;
  var tmp$33;
  if (value$68 == null) {
    tmp$33 = undefined;
  } else {
    var value$69 = value$68.value;
    tmp$33 = {
      __typename: value$68.__typename,
      value: !(value$69 == null) ? value$69 : undefined
    };
  }
  var value$70 = value.badge_background_colour;
  var tmp$34;
  if (value$70 == null) {
    tmp$34 = undefined;
  } else {
    var value$71 = value$70.value;
    tmp$34 = {
      __typename: value$70.__typename,
      value: !(value$71 == null) ? value$71 : undefined
    };
  }
  var value$72 = value.badge_outline_colour;
  var tmp$35;
  if (value$72 == null) {
    tmp$35 = undefined;
  } else {
    var value$73 = value$72.value;
    tmp$35 = {
      __typename: value$72.__typename,
      value: !(value$73 == null) ? value$73 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          background_colour: tmp,
          background_colour_2: tmp$1,
          copy_block_alignment: tmp$2,
          copy_block_alignment_2: tmp$3,
          image_treatment: tmp$4,
          image_treatment_2: tmp$5,
          title: tmp$6,
          title_2: tmp$7,
          highlight_text_colour: tmp$8,
          highlight_text_colour_2: tmp$9,
          title_font_style: tmp$10,
          title_font_style_2: tmp$11,
          title_text_colour: tmp$12,
          title_text_colour_2: tmp$13,
          title_font_size: tmp$14,
          title_font_size_2: tmp$15,
          description: tmp$16,
          description_2: tmp$17,
          description_text_colour: tmp$18,
          description_text_colour_2: tmp$19,
          description_font_size: tmp$20,
          description_font_size_2: tmp$21,
          cta_s: tmp$22,
          cta_s_2: tmp$23,
          desktop_image: tmp$24,
          desktop_image_2: tmp$25,
          tablet_image: tmp$26,
          tablet_image_2: tmp$27,
          mobile_image: tmp$28,
          mobile_image_2: tmp$29,
          display_badge: tmp$30,
          badge_alignment: tmp$31,
          badge_text: tmp$32,
          badge_text_colour: tmp$33,
          badge_background_colour: tmp$34,
          badge_outline_colour: tmp$35
        };
}

function serialize(value) {
  var value$1 = value.badge_outline_colour;
  var badge_outline_colour;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    badge_outline_colour = {
      __typename: value$4,
      value: value$3
    };
  } else {
    badge_outline_colour = null;
  }
  var value$5 = value.badge_background_colour;
  var badge_background_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    badge_background_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    badge_background_colour = null;
  }
  var value$9 = value.badge_text_colour;
  var badge_text_colour;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    badge_text_colour = {
      __typename: value$12,
      value: value$11
    };
  } else {
    badge_text_colour = null;
  }
  var value$13 = value.badge_text;
  var badge_text;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    badge_text = {
      __typename: value$16,
      value: value$15
    };
  } else {
    badge_text = null;
  }
  var value$17 = value.badge_alignment;
  var badge_alignment;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    badge_alignment = {
      __typename: value$20,
      value: value$19
    };
  } else {
    badge_alignment = null;
  }
  var value$21 = value.display_badge;
  var display_badge;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$22) : null;
    var value$24 = value$21.__typename;
    display_badge = {
      __typename: value$24,
      value: value$23
    };
  } else {
    display_badge = null;
  }
  var value$25 = value.mobile_image_2;
  var mobile_image_2;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$26) : null;
    var value$28 = value$25.__typename;
    mobile_image_2 = {
      __typename: value$28,
      value: value$27
    };
  } else {
    mobile_image_2 = null;
  }
  var value$29 = value.mobile_image;
  var mobile_image;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$30) : null;
    var value$32 = value$29.__typename;
    mobile_image = {
      __typename: value$32,
      value: value$31
    };
  } else {
    mobile_image = null;
  }
  var value$33 = value.tablet_image_2;
  var tablet_image_2;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$34) : null;
    var value$36 = value$33.__typename;
    tablet_image_2 = {
      __typename: value$36,
      value: value$35
    };
  } else {
    tablet_image_2 = null;
  }
  var value$37 = value.tablet_image;
  var tablet_image;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$38) : null;
    var value$40 = value$37.__typename;
    tablet_image = {
      __typename: value$40,
      value: value$39
    };
  } else {
    tablet_image = null;
  }
  var value$41 = value.desktop_image_2;
  var desktop_image_2;
  if (value$41 !== undefined) {
    var value$42 = value$41.value;
    var value$43 = value$42 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$42) : null;
    var value$44 = value$41.__typename;
    desktop_image_2 = {
      __typename: value$44,
      value: value$43
    };
  } else {
    desktop_image_2 = null;
  }
  var value$45 = value.desktop_image;
  var desktop_image;
  if (value$45 !== undefined) {
    var value$46 = value$45.value;
    var value$47 = value$46 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$46) : null;
    var value$48 = value$45.__typename;
    desktop_image = {
      __typename: value$48,
      value: value$47
    };
  } else {
    desktop_image = null;
  }
  var value$49 = value.cta_s_2;
  var cta_s_2;
  if (value$49 !== undefined) {
    var value$50 = value$49.value;
    var value$51 = value$50 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$50) : null;
    var value$52 = value$49.__typename;
    cta_s_2 = {
      __typename: value$52,
      value: value$51
    };
  } else {
    cta_s_2 = null;
  }
  var value$53 = value.cta_s;
  var cta_s;
  if (value$53 !== undefined) {
    var value$54 = value$53.value;
    var value$55 = value$54 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$54) : null;
    var value$56 = value$53.__typename;
    cta_s = {
      __typename: value$56,
      value: value$55
    };
  } else {
    cta_s = null;
  }
  var value$57 = value.description_font_size_2;
  var description_font_size_2;
  if (value$57 !== undefined) {
    var value$58 = value$57.value;
    var value$59 = value$58 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$58) : null;
    var value$60 = value$57.__typename;
    description_font_size_2 = {
      __typename: value$60,
      value: value$59
    };
  } else {
    description_font_size_2 = null;
  }
  var value$61 = value.description_font_size;
  var description_font_size;
  if (value$61 !== undefined) {
    var value$62 = value$61.value;
    var value$63 = value$62 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$62) : null;
    var value$64 = value$61.__typename;
    description_font_size = {
      __typename: value$64,
      value: value$63
    };
  } else {
    description_font_size = null;
  }
  var value$65 = value.description_text_colour_2;
  var description_text_colour_2;
  if (value$65 !== undefined) {
    var value$66 = value$65.value;
    var value$67 = value$66 !== undefined ? value$66 : null;
    var value$68 = value$65.__typename;
    description_text_colour_2 = {
      __typename: value$68,
      value: value$67
    };
  } else {
    description_text_colour_2 = null;
  }
  var value$69 = value.description_text_colour;
  var description_text_colour;
  if (value$69 !== undefined) {
    var value$70 = value$69.value;
    var value$71 = value$70 !== undefined ? value$70 : null;
    var value$72 = value$69.__typename;
    description_text_colour = {
      __typename: value$72,
      value: value$71
    };
  } else {
    description_text_colour = null;
  }
  var value$73 = value.description_2;
  var description_2;
  if (value$73 !== undefined) {
    var value$74 = value$73.value;
    var value$75 = value$74 !== undefined ? value$74 : null;
    var value$76 = value$73.__typename;
    description_2 = {
      __typename: value$76,
      value: value$75
    };
  } else {
    description_2 = null;
  }
  var value$77 = value.description;
  var description;
  if (value$77 !== undefined) {
    var value$78 = value$77.value;
    var value$79 = value$78 !== undefined ? value$78 : null;
    var value$80 = value$77.__typename;
    description = {
      __typename: value$80,
      value: value$79
    };
  } else {
    description = null;
  }
  var value$81 = value.title_font_size_2;
  var title_font_size_2;
  if (value$81 !== undefined) {
    var value$82 = value$81.value;
    var value$83 = value$82 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$82) : null;
    var value$84 = value$81.__typename;
    title_font_size_2 = {
      __typename: value$84,
      value: value$83
    };
  } else {
    title_font_size_2 = null;
  }
  var value$85 = value.title_font_size;
  var title_font_size;
  if (value$85 !== undefined) {
    var value$86 = value$85.value;
    var value$87 = value$86 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$86) : null;
    var value$88 = value$85.__typename;
    title_font_size = {
      __typename: value$88,
      value: value$87
    };
  } else {
    title_font_size = null;
  }
  var value$89 = value.title_text_colour_2;
  var title_text_colour_2;
  if (value$89 !== undefined) {
    var value$90 = value$89.value;
    var value$91 = value$90 !== undefined ? value$90 : null;
    var value$92 = value$89.__typename;
    title_text_colour_2 = {
      __typename: value$92,
      value: value$91
    };
  } else {
    title_text_colour_2 = null;
  }
  var value$93 = value.title_text_colour;
  var title_text_colour;
  if (value$93 !== undefined) {
    var value$94 = value$93.value;
    var value$95 = value$94 !== undefined ? value$94 : null;
    var value$96 = value$93.__typename;
    title_text_colour = {
      __typename: value$96,
      value: value$95
    };
  } else {
    title_text_colour = null;
  }
  var value$97 = value.title_font_style_2;
  var title_font_style_2;
  if (value$97 !== undefined) {
    var value$98 = value$97.value;
    var value$99 = value$98 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$98) : null;
    var value$100 = value$97.__typename;
    title_font_style_2 = {
      __typename: value$100,
      value: value$99
    };
  } else {
    title_font_style_2 = null;
  }
  var value$101 = value.title_font_style;
  var title_font_style;
  if (value$101 !== undefined) {
    var value$102 = value$101.value;
    var value$103 = value$102 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$102) : null;
    var value$104 = value$101.__typename;
    title_font_style = {
      __typename: value$104,
      value: value$103
    };
  } else {
    title_font_style = null;
  }
  var value$105 = value.highlight_text_colour_2;
  var highlight_text_colour_2;
  if (value$105 !== undefined) {
    var value$106 = value$105.value;
    var value$107 = value$106 !== undefined ? value$106 : null;
    var value$108 = value$105.__typename;
    highlight_text_colour_2 = {
      __typename: value$108,
      value: value$107
    };
  } else {
    highlight_text_colour_2 = null;
  }
  var value$109 = value.highlight_text_colour;
  var highlight_text_colour;
  if (value$109 !== undefined) {
    var value$110 = value$109.value;
    var value$111 = value$110 !== undefined ? value$110 : null;
    var value$112 = value$109.__typename;
    highlight_text_colour = {
      __typename: value$112,
      value: value$111
    };
  } else {
    highlight_text_colour = null;
  }
  var value$113 = value.title_2;
  var title_2;
  if (value$113 !== undefined) {
    var value$114 = value$113.value;
    var value$115 = value$114 !== undefined ? value$114 : null;
    var value$116 = value$113.__typename;
    title_2 = {
      __typename: value$116,
      value: value$115
    };
  } else {
    title_2 = null;
  }
  var value$117 = value.title;
  var title;
  if (value$117 !== undefined) {
    var value$118 = value$117.value;
    var value$119 = value$118 !== undefined ? value$118 : null;
    var value$120 = value$117.__typename;
    title = {
      __typename: value$120,
      value: value$119
    };
  } else {
    title = null;
  }
  var value$121 = value.image_treatment_2;
  var image_treatment_2;
  if (value$121 !== undefined) {
    var value$122 = value$121.value;
    var value$123 = value$122 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$122) : null;
    var value$124 = value$121.__typename;
    image_treatment_2 = {
      __typename: value$124,
      value: value$123
    };
  } else {
    image_treatment_2 = null;
  }
  var value$125 = value.image_treatment;
  var image_treatment;
  if (value$125 !== undefined) {
    var value$126 = value$125.value;
    var value$127 = value$126 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$126) : null;
    var value$128 = value$125.__typename;
    image_treatment = {
      __typename: value$128,
      value: value$127
    };
  } else {
    image_treatment = null;
  }
  var value$129 = value.copy_block_alignment_2;
  var copy_block_alignment_2;
  if (value$129 !== undefined) {
    var value$130 = value$129.value;
    var value$131 = value$130 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$130) : null;
    var value$132 = value$129.__typename;
    copy_block_alignment_2 = {
      __typename: value$132,
      value: value$131
    };
  } else {
    copy_block_alignment_2 = null;
  }
  var value$133 = value.copy_block_alignment;
  var copy_block_alignment;
  if (value$133 !== undefined) {
    var value$134 = value$133.value;
    var value$135 = value$134 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$134) : null;
    var value$136 = value$133.__typename;
    copy_block_alignment = {
      __typename: value$136,
      value: value$135
    };
  } else {
    copy_block_alignment = null;
  }
  var value$137 = value.background_colour_2;
  var background_colour_2;
  if (value$137 !== undefined) {
    var value$138 = value$137.value;
    var value$139 = value$138 !== undefined ? value$138 : null;
    var value$140 = value$137.__typename;
    background_colour_2 = {
      __typename: value$140,
      value: value$139
    };
  } else {
    background_colour_2 = null;
  }
  var value$141 = value.background_colour;
  var background_colour;
  if (value$141 !== undefined) {
    var value$142 = value$141.value;
    var value$143 = value$142 !== undefined ? value$142 : null;
    var value$144 = value$141.__typename;
    background_colour = {
      __typename: value$144,
      value: value$143
    };
  } else {
    background_colour = null;
  }
  var value$145 = value.system;
  var value$146 = value$145.codename;
  var value$147 = value$145.id;
  var value$148 = value$145.__typename;
  var system = {
    __typename: value$148,
    id: value$147,
    codename: value$146
  };
  var value$149 = value.__typename;
  return {
          __typename: value$149,
          system: system,
          background_colour: background_colour,
          background_colour_2: background_colour_2,
          copy_block_alignment: copy_block_alignment,
          copy_block_alignment_2: copy_block_alignment_2,
          image_treatment: image_treatment,
          image_treatment_2: image_treatment_2,
          title: title,
          title_2: title_2,
          highlight_text_colour: highlight_text_colour,
          highlight_text_colour_2: highlight_text_colour_2,
          title_font_style: title_font_style,
          title_font_style_2: title_font_style_2,
          title_text_colour: title_text_colour,
          title_text_colour_2: title_text_colour_2,
          title_font_size: title_font_size,
          title_font_size_2: title_font_size_2,
          description: description,
          description_2: description_2,
          description_text_colour: description_text_colour,
          description_text_colour_2: description_text_colour_2,
          description_font_size: description_font_size,
          description_font_size_2: description_font_size_2,
          cta_s: cta_s,
          cta_s_2: cta_s_2,
          desktop_image: desktop_image,
          desktop_image_2: desktop_image_2,
          tablet_image: tablet_image,
          tablet_image_2: tablet_image_2,
          mobile_image: mobile_image,
          mobile_image_2: mobile_image_2,
          display_badge: display_badge,
          badge_alignment: badge_alignment,
          badge_text: badge_text,
          badge_text_colour: badge_text_colour,
          badge_background_colour: badge_background_colour,
          badge_outline_colour: badge_outline_colour
        };
}

function verifyArgsAndParse(_N2ColumnHero, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var N2ColumnHero = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var CallToAction;

export {
  CallToAction ,
  N2ColumnHero ,
}
/* query Not a pure module */
