// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "LinkItemContentType") {
    return ;
  }
  var c = content.VAL;
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(c.url, (function (c) {
              return c.value;
            })), "");
  var open_in_new_window = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.open_in_new_window, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), false, (function (c) {
          if (c === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  if (url === "") {
    return ;
  } else {
    return {
            url: url,
            open_in_new_window: open_in_new_window
          };
  }
}

export {
  get ,
}
/* No side effect */
