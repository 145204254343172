// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__IconCardBlock__Decoder__Card from "./Gql__IconCardBlock__Decoder__Card.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var theme = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.theme, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "");
  var background_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  var icon_cards = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon_cards, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__IconCardBlock__Decoder__Card.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          description: description,
          theme: theme,
          icon_cards: icon_cards,
          background_colour: background_colour,
          smartLink: smartLink
        };
}

var IconCard;

export {
  IconCard ,
  get ,
}
/* No side effect */
