// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";
import * as Gql__DeviceSizeImage__Decoder from "../DeviceSizeImage/Gql__DeviceSizeImage__Decoder.bs.js";

function convertPerRowToInt(str) {
  switch (str) {
    case "eight" :
        return 8;
    case "five" :
        return 5;
    case "four" :
        return 4;
    case "one" :
        return 1;
    case "seven" :
        return 7;
    case "six" :
        return 6;
    case "three" :
        return 3;
    case "two" :
        return 2;
    default:
      return 1;
  }
}

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var images = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.images, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (i) {
          if (typeof i !== "object") {
            return ;
          }
          var variant = i.NAME;
          if (variant === "ImageContentType") {
            return {
                    NAME: "Image",
                    VAL: Gql__Image__Decoder.get(i.VAL.image)
                  };
          } else if (variant === "DeviceSizeImageContentType") {
            return {
                    NAME: "DeviceSizeImage",
                    VAL: Gql__DeviceSizeImage__Decoder.get(i.VAL.deviceSizeImage)
                  };
          } else {
            return ;
          }
        }));
  var display_per_row_desktop = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.display_per_row_desktop, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), 1, convertPerRowToInt);
  var display_per_row_tablet = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.display_per_row_tablet, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), 1, convertPerRowToInt);
  var display_per_row_mobile = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.display_per_row_mobile, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), 1, convertPerRowToInt);
  var gap_desktop = Belt_Option.getWithDefault(Belt_Option.flatMap(c.gap_desktop, (function (c) {
              return c.value;
            })), 0);
  var gap_tablet = Belt_Option.getWithDefault(Belt_Option.flatMap(c.gap_tablet, (function (c) {
              return c.value;
            })), 0);
  var gap_mobile = Belt_Option.getWithDefault(Belt_Option.flatMap(c.gap_mobile, (function (c) {
              return c.value;
            })), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          description: description,
          images: images,
          display_per_row_desktop: display_per_row_desktop,
          display_per_row_tablet: display_per_row_tablet,
          display_per_row_mobile: display_per_row_mobile,
          gap_desktop: gap_desktop,
          gap_tablet: gap_tablet,
          gap_mobile: gap_mobile,
          smartLink: smartLink
        };
}

var $$Image;

var DeviceSizeImage;

export {
  $$Image ,
  DeviceSizeImage ,
  convertPerRowToInt ,
  get ,
}
/* No side effect */
