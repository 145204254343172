// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../css/Theme.bs.js";
import * as React from "react";
import * as SmartLink from "../components/library/SmartLink.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as LayoutDataContext from "../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../components/pages/index/KontentContextProvider.bs.js";
import * as AlertBanner$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/AlertBanner/AlertBanner.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as AlertBanner__Text$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/AlertBanner/AlertBanner__Text.bs.js";

var iconColor = Curry._1(Css.style, {
      hd: Css.color(Theme.Color.white),
      tl: /* [] */0
    });

var buttonWrapper = Curry._1(Css.style, {
      hd: Css.width(Css.px(44)),
      tl: {
        hd: Css.height(Css.px(44)),
        tl: {
          hd: Css.position("absolute"),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.right(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var alertBanner = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.important(Css.minHeight(Css.px(44))),
        tl: /* [] */0
      }
    });

var alertBannerText = Curry._1(Css.style, {
      hd: Css.important(Css.fontSize(Css.px(14))),
      tl: {
        hd: Css.important(Css.marginRight(Css.px(44))),
        tl: {
          hd: Css.important(Css.marginLeft(Css.px(44))),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  iconColor: iconColor,
  buttonWrapper: buttonWrapper,
  alertBanner: alertBanner,
  alertBannerText: alertBannerText
};

function AlertBanner(props) {
  var state = LayoutDataContext.useContext(undefined);
  var match = React.useState(function () {
        return true;
      });
  var setBannerVisibility = match[1];
  var bannerData = Belt_Option.flatMap(state, (function (s) {
          return s.alertBanner;
        }));
  var alertColour = Belt_Option.getWithDefault(Belt_Option.map(bannerData, (function (s) {
              return s.bannerColor;
            })), /* Green */1);
  var href = Belt_Option.getWithDefault(Belt_Option.flatMap(bannerData, (function (s) {
              return s.url;
            })), "");
  var desktopText = Belt_Option.getWithDefault(Belt_Option.flatMap(bannerData, (function (s) {
              return s.desktopText;
            })), "");
  var mobileText = Belt_Option.getWithDefault(Belt_Option.flatMap(bannerData, (function (s) {
              return s.mobileText;
            })), "");
  var match$1 = Belt_Option.mapWithDefault(bannerData, SmartLink.emptySmartLink, (function (s) {
          return s.smartLink;
        }));
  var elementCodename = match$1.codename;
  var itemId = match$1.itemId;
  var onHideBanner = function (e) {
    e.preventDefault();
    Curry._1(setBannerVisibility, (function (param) {
            return false;
          }));
  };
  var displayBanner = href === "" ? JsxRuntime.jsx(KontentContextProvider.make, {
          children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                children: JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs(AlertBanner$PrezzeeRibbon.make, {
                            children: [
                              JsxRuntime.jsx(AlertBanner__Text$PrezzeeRibbon.make, {
                                    desktopText: desktopText,
                                    mobileText: mobileText,
                                    link: true,
                                    optionalStyles: alertBannerText
                                  }),
                              JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                    children: JsxRuntime.jsx("button", {
                                          children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                icon: [
                                                  "fas",
                                                  "times"
                                                ]
                                              }),
                                          className: "w-full h-full bg-transparent",
                                          onClick: onHideBanner
                                        }),
                                    variant: /* Icon */2,
                                    className: buttonWrapper
                                  })
                            ],
                            alertColour: alertColour,
                            optionalStyles: alertBanner
                          })
                    })
              }),
          itemId: itemId,
          elementCodename: elementCodename
        }) : JsxRuntime.jsx(KontentContextProvider.make, {
          children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                children: JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: JsxRuntime.jsxs(AlertBanner$PrezzeeRibbon.make, {
                                  children: [
                                    JsxRuntime.jsx(AlertBanner__Text$PrezzeeRibbon.make, {
                                          desktopText: desktopText,
                                          mobileText: mobileText,
                                          link: true,
                                          optionalStyles: alertBannerText
                                        }),
                                    JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                          children: JsxRuntime.jsx("button", {
                                                children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                      icon: [
                                                        "fas",
                                                        "times"
                                                      ],
                                                      className: "flex-1"
                                                    }),
                                                "aria-label": "Dismiss alert",
                                                className: "w-full h-full bg-transparent",
                                                title: "Dismiss alert",
                                                onClick: onHideBanner
                                              }),
                                          variant: /* Icon */2,
                                          className: buttonWrapper
                                        })
                                  ],
                                  alertColour: alertColour,
                                  optionalStyles: alertBanner
                                }),
                            href: href
                          })
                    })
              }),
          itemId: itemId,
          elementCodename: elementCodename
        });
  if (Belt_Option.isSome(bannerData) && match[0]) {
    return displayBanner;
  } else {
    return null;
  }
}

var AlertBanner$1;

var Button;

var $$Text;

var Link;

var KontentContextConsumer;

var make = AlertBanner;

export {
  AlertBanner$1 as AlertBanner,
  Button ,
  $$Text ,
  Link ,
  KontentContextConsumer ,
  Styles ,
  make ,
}
/* iconColor Not a pure module */
