// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";
import * as Gql__DeviceSizeImage__Decoder from "../DeviceSizeImage/Gql__DeviceSizeImage__Decoder.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "PromoTileContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var link = Belt_Option.getWithDefault(Belt_Option.flatMap(c.url, (function (c) {
              return c.value;
            })), "");
  var image = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (i) {
          if (typeof i !== "object") {
            return ;
          }
          var variant = i.NAME;
          if (variant === "ImageContentType") {
            return {
                    NAME: "Image",
                    VAL: Gql__Image__Decoder.get(i.VAL.image)
                  };
          } else if (variant === "DeviceSizeImageContentType") {
            return {
                    NAME: "DeviceSizeImage",
                    VAL: Gql__DeviceSizeImage__Decoder.get(i.VAL.deviceSizeImage)
                  };
          } else {
            return ;
          }
        }));
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.graphic_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var graphic = result;
  var background_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  var text_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (c) {
              return c.value;
            })), "");
  var desktop_text_width = Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_text_width, (function (c) {
              return c.value;
            })), "");
  var icon_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon_colour, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          image: image,
          graphic: graphic,
          link: link,
          background_colour: background_colour,
          text_colour: text_colour,
          desktop_text_width: desktop_text_width,
          icon_colour: icon_colour,
          smartLink: smartLink
        };
}

var $$Image;

var DeviceSizeImage;

export {
  $$Image ,
  DeviceSizeImage ,
  get ,
}
/* No side effect */
