// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var desktop_image;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    desktop_image = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    desktop_image = undefined;
  }
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var tablet_image;
  if (value$1 !== undefined) {
    var name$1 = Belt_Option.getWithDefault(value$1.name, "");
    var description$1 = Belt_Option.getWithDefault(value$1.description, "");
    var url$1 = Belt_Option.getWithDefault(value$1.url, "");
    var width$1 = Belt_Option.getWithDefault(value$1.width, 0);
    var height$1 = Belt_Option.getWithDefault(value$1.height, 0);
    tablet_image = {
      name: name$1,
      description: description$1,
      url: url$1,
      width: width$1,
      height: height$1
    };
  } else {
    tablet_image = undefined;
  }
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var mobile_image;
  if (value$2 !== undefined) {
    var name$2 = Belt_Option.getWithDefault(value$2.name, "");
    var description$2 = Belt_Option.getWithDefault(value$2.description, "");
    var url$2 = Belt_Option.getWithDefault(value$2.url, "");
    var width$2 = Belt_Option.getWithDefault(value$2.width, 0);
    var height$2 = Belt_Option.getWithDefault(value$2.height, 0);
    mobile_image = {
      name: name$2,
      description: description$2,
      url: url$2,
      width: width$2,
      height: height$2
    };
  } else {
    mobile_image = undefined;
  }
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          desktop_image: desktop_image,
          tablet_image: tablet_image,
          mobile_image: mobile_image,
          smartLink: smartLink
        };
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
