// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Gql__SeoMeta__Decoder from "../SeoMetaContentType/Gql__SeoMeta__Decoder.bs.js";
import * as Gql__EnrichedTextBlock__Decoder from "../EnrichedTextBlock/Gql__EnrichedTextBlock__Decoder.bs.js";

function seoMetaBlockSwitch(content) {
  if (typeof content === "object" && content.NAME === "SeoMetaContentType") {
    return {
            NAME: "SeoMetaBlock",
            VAL: Gql__SeoMeta__Decoder.get(content.VAL.seoMetaBlock)
          };
  }
  
}

var SeoMetaMapper = {
  seoMetaBlockSwitch: seoMetaBlockSwitch
};

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "StoreCategoryContentType") {
    return ;
  }
  var c = content.VAL;
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var top_content = Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.page_top_content, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (f) {
                  if (typeof f === "object" && f.NAME === "EnrichedTextBlockContentType") {
                    return Gql__EnrichedTextBlock__Decoder.get(f.VAL.enrichedTextBlock);
                  }
                  
                })), GqlUtils.id), 0);
  var seo_meta = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.seo_meta, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), seoMetaBlockSwitch), 0);
  return {
          smartLink: smartLink,
          top_content: top_content,
          seo_meta: seo_meta
        };
}

var StoreCategory = {
  get: get
};

function decode(gqlQueryResult) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.itemsByType, []), 0), GqlUtils.id), get);
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  Pino.logger.error({
        err: result._0,
        type: "Store Category decoder"
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

function parse(data) {
  return Belt_Option.map(data === null ? undefined : Caml_option.some(data), Json.parseOrRaise);
}

var SeoMetaBlock;

export {
  SeoMetaBlock ,
  SeoMetaMapper ,
  StoreCategory ,
  Decoder ,
  parse ,
}
/* Pino Not a pure module */
