// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  var elementId = content.system.id;
  var codename = content.system.codename;
  var smartLink = {
    itemId: elementId,
    codename: codename
  };
  var preTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(content.pre_title, (function (c) {
              return c.value;
            })), "");
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(content.title, (function (c) {
              return c.value;
            })), "");
  var formContactMethodLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(content.form_contact_method_label, (function (c) {
              return c.value;
            })), "");
  var formContactMethodUrl = Belt_Option.getWithDefault(Belt_Option.flatMap(content.form_contact_method_url, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.phone_contact_method_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var phoneContactMethodIcon;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    phoneContactMethodIcon = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    phoneContactMethodIcon = undefined;
  }
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.form_contact_method_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var formContactMethodIcon;
  if (value$1 !== undefined) {
    var name$1 = Belt_Option.getWithDefault(value$1.name, "");
    var description$1 = Belt_Option.getWithDefault(value$1.description, "");
    var url$1 = Belt_Option.getWithDefault(value$1.url, "");
    var width$1 = Belt_Option.getWithDefault(value$1.width, 0);
    var height$1 = Belt_Option.getWithDefault(value$1.height, 0);
    formContactMethodIcon = {
      name: name$1,
      description: description$1,
      url: url$1,
      width: width$1,
      height: height$1
    };
  } else {
    formContactMethodIcon = undefined;
  }
  var text = Belt_Option.getWithDefault(Belt_Option.flatMap(content.text, (function (c) {
              return c.value;
            })), "");
  var localPhoneNumber = Belt_Option.getWithDefault(Belt_Option.flatMap(content.local_phone_number, (function (c) {
              return c.value;
            })), "");
  var internationalPhoneNumberText = Belt_Option.getWithDefault(Belt_Option.flatMap(content.international_phone_number_text, (function (c) {
              return c.value;
            })), "");
  var internationalPhoneNumber = Belt_Option.getWithDefault(Belt_Option.flatMap(content.international_phone_number, (function (c) {
              return c.value;
            })), "");
  var subtext = Belt_Option.getWithDefault(Belt_Option.flatMap(content.subtext, (function (c) {
              return c.value;
            })), "");
  return {
          preTitle: preTitle,
          title: title,
          text: text,
          formContactMethodIcon: formContactMethodIcon,
          formContactMethodLabel: formContactMethodLabel,
          formContactMethodUrl: formContactMethodUrl,
          phoneContactMethodIcon: phoneContactMethodIcon,
          localPhoneNumber: localPhoneNumber,
          internationalPhoneNumberText: internationalPhoneNumberText,
          internationalPhoneNumber: internationalPhoneNumber,
          subtext: subtext,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
