// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Belt_Result from "@rescript/std/lib/es6/belt_Result.js";
import * as IndexStyles from "../pages/index/IndexStyles.bs.js";
import * as LayoutDataContext from "../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as KontentContextProvider from "../pages/index/KontentContextProvider.bs.js";
import * as Gql__DictionaryItems__Decoder from "../../api/graphql/DictionaryItems/Gql__DictionaryItems__Decoder.bs.js";
import * as RegionIndicatorStrip$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorStrip.bs.js";
import * as RegionIndicatorInline$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorInline.bs.js";
import * as RegionListLineContent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionList/RegionListLineContent.bs.js";
import * as RegionIndicatorCountryDisplay$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorCountryDisplay.bs.js";

function removeTrailingSlash(url) {
  var slashIndex = url.lastIndexOf("/");
  if (slashIndex === (url.length - 1 | 0)) {
    return url.substring(0, slashIndex);
  } else {
    return url;
  }
}

var isStg = process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL.includes("stg");

function toStgUrl(url) {
  return url.replace("https://www.prezzee", "https://stg.prezzee");
}

function RegionIndicators$RegionInline(props) {
  var selectedRegion = props.selectedRegion;
  var regionListLines = props.regionListLines;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(RegionIndicatorInline$PrezzeeRibbon.make, {
                    regions: regionListLines,
                    strings: props.regionPickerStrings,
                    selected: selectedRegion,
                    children: JsxRuntime.jsx(KontentContextProvider.make, {
                          children: JsxRuntime.jsx(RegionIndicatorCountryDisplay$PrezzeeRibbon.make, {
                                text: props.regionIndicatorText,
                                regions: regionListLines,
                                selected: selectedRegion
                              }),
                          itemId: props.regionIndicatorTextId,
                          elementCodename: props.regionIndicatorTextCodename
                        }),
                    onChange: props.onChange
                  }),
              itemId: props.regionModalItemId,
              elementCodename: props.regionModalCodename
            });
}

var RegionInline = {
  make: RegionIndicators$RegionInline
};

function RegionIndicators$RegionStrip(props) {
  var selectedRegion = props.selectedRegion;
  var regionListLines = props.regionListLines;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(RegionIndicatorStrip$PrezzeeRibbon.make, {
                    regions: regionListLines,
                    strings: props.regionPickerStrings,
                    selected: selectedRegion,
                    children: JsxRuntime.jsx(KontentContextProvider.make, {
                          children: JsxRuntime.jsx(RegionIndicatorCountryDisplay$PrezzeeRibbon.make, {
                                text: props.regionIndicatorText,
                                regions: regionListLines,
                                selected: selectedRegion
                              }),
                          itemId: props.regionIndicatorTextId,
                          elementCodename: props.regionIndicatorTextCodename
                        }),
                    onChange: props.onChange,
                    className: IndexStyles.fullWidthSection
                  }),
              itemId: props.regionModalItemId,
              elementCodename: props.regionModalCodename
            });
}

var RegionStrip = {
  make: RegionIndicators$RegionStrip
};

function GenericRegionPicker(Indicator) {
  var RegionIndicators$GenericRegionPicker = function (props) {
    var state = LayoutDataContext.useContext(undefined);
    var regions = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                    return r.location;
                  })), (function (r) {
                return r.regions;
              })), []);
    var domainPostfix = Belt_Result.mapWithDefault(Util.EnvUtil.getCountry(undefined), "", (function (c) {
            return Util.EnvUtil.getDomainPostfix(c);
          }));
    var regionIndex = Belt_Option.getWithDefault(Belt_Array.getIndexBy(regions, (function (region) {
                return removeTrailingSlash(region.url).endsWith(domainPostfix);
              })), 0);
    var match = React.useState(function () {
          return regionIndex;
        });
    var setSelectedRegion = match[1];
    var onChange = function (selected) {
      Curry._1(setSelectedRegion, (function (param) {
              return selected;
            }));
      var region = Belt_Array.get(regions, selected);
      var url = Belt_Option.getWithDefault(Belt_Option.map(region, (function (r) {
                  if (isStg) {
                    return r.url.replace("https://www.prezzee", "https://stg.prezzee");
                  } else {
                    return r.url;
                  }
                })), "");
      var changeLocation = (function cl(url) { window.location.assign(url) });
      return changeLocation(url);
    };
    var regionIndicator = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (s) {
                    return s.dictionaryItems;
                  })), (function (s) {
                return s.app_yourcurrentshoppingregion;
              })), {
          value: "You're currently shopping in {0}",
          smartLink: Gql__DictionaryItems__Decoder.emptyDictionaryEntry.smartLink
        }, (function (param) {
            var smartLink = param.smartLink;
            var value = param.value;
            if (value === "") {
              return {
                      value: "You're currently shopping in {0}",
                      smartLink: smartLink
                    };
            } else {
              return {
                      value: value,
                      smartLink: smartLink
                    };
            }
          }));
    var regionIndicatorText = regionIndicator.value;
    var match$1 = regionIndicator.smartLink;
    var regionModalItemId = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                    return r.location;
                  })), (function (r) {
                return r.smartLink.itemId;
              })), "");
    var regionModalCodename = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                    return r.location;
                  })), (function (r) {
                return r.smartLink.codename;
              })), "");
    var regionPickerTitle = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                    return r.location;
                  })), (function (r) {
                return r.title;
              })), "Change location");
    var regionPickerSubtitle = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                    return r.location;
                  })), (function (r) {
                return r.subtitle;
              })), "Want to send gift cards to family and friends in another country? Change your region below.");
    var regionPickerCtaText = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                    return r.location;
                  })), (function (r) {
                return r.ctaText;
              })), "Save location");
    var regionPickerStrings = {
      heading: regionPickerTitle,
      heading_subtext: regionPickerSubtitle,
      cta_button: regionPickerCtaText
    };
    var regionListLines = Belt_Array.map(regions, (function (r) {
            var match = r.regionListLine;
            var smartLink = match.smartLink;
            var flag = match.flag;
            var currency = match.currency;
            var name = match.name;
            var el = JsxRuntime.jsx(KontentContextProvider.make, {
                  children: JsxRuntime.jsx(RegionListLineContent$PrezzeeRibbon.make, {
                        flag: flag,
                        name: name,
                        currency: currency
                      }),
                  itemId: smartLink.itemId,
                  elementCodename: smartLink.codename
                });
            return {
                    name: name,
                    currency: currency,
                    flag: flag,
                    el: el
                  };
          }));
    return JsxRuntime.jsx(Indicator.make, {
                regionModalItemId: regionModalItemId,
                regionModalCodename: regionModalCodename,
                regionListLines: regionListLines,
                regionPickerStrings: regionPickerStrings,
                selectedRegion: match[0],
                regionIndicatorText: regionIndicatorText,
                regionIndicatorTextId: match$1.itemId,
                regionIndicatorTextCodename: match$1.codename,
                onChange: onChange
              });
  };
  return {
          make: RegionIndicators$GenericRegionPicker
        };
}

var RegionPicker;

var RegionIndicatorStrip;

var RegionIndicatorInline;

var RegionIndicatorCountryDisplay;

export {
  RegionPicker ,
  RegionIndicatorStrip ,
  RegionIndicatorInline ,
  RegionIndicatorCountryDisplay ,
  removeTrailingSlash ,
  isStg ,
  toStgUrl ,
  RegionInline ,
  RegionStrip ,
  GenericRegionPicker ,
}
/* isStg Not a pure module */
