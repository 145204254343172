// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__SeoMeta__Fragment from "../SeoMetaContentType/Gql__SeoMeta__Fragment.bs.js";
import * as Gql__SkuTheme__Fragment from "../SkuTheme/Gql__SkuTheme__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment SkuPresentationFragment on SkuPresentationContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    themes  {
      __typename
      value  {
        __typename
        ...on SkuThemeContentType   {
          ...SkuThemeFragment
        }
      }
    }
    seo_meta  {
      __typename
      value  {
        __typename
        ...on SeoMetaContentType   {
          ...SeoMetaBlock
        }
      }
    }
    sold_out  {
      __typename
      value  {
        __typename
        codename
      }
    }
    audience  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
    interest  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
    occasion  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
    location_au  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
    location___nz  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
    location___uk  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
    location___us  {
      __typename
      name
      value  {
        __typename
        name
        codename
      }
      taxonomyGroup
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__SeoMeta__Fragment.SeoMetaBlock.query, Gql__SkuTheme__Fragment.SkuThemeFragment.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.themes;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "SkuThemeContentType" ? ({
                      NAME: "SkuThemeContentType",
                      VAL: {
                        skuThemeFragment: Gql__SkuTheme__Fragment.SkuThemeFragment.verifyArgsAndParse("SkuThemeFragment", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$3) : undefined
    };
  }
  var value$4 = value.seo_meta;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "SeoMetaContentType" ? ({
                      NAME: "SeoMetaContentType",
                      VAL: {
                        seoMetaBlock: Gql__SeoMeta__Fragment.SeoMetaBlock.verifyArgsAndParse("SeoMetaBlock", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$5) : undefined
    };
  }
  var value$6 = value.sold_out;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.audience;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    var value$10 = value$8.taxonomyGroup;
    tmp$3 = {
      __typename: value$8.__typename,
      name: value$8.name,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$9) : undefined,
      taxonomyGroup: !(value$10 == null) ? value$10 : undefined
    };
  }
  var value$11 = value.interest;
  var tmp$4;
  if (value$11 == null) {
    tmp$4 = undefined;
  } else {
    var value$12 = value$11.value;
    var value$13 = value$11.taxonomyGroup;
    tmp$4 = {
      __typename: value$11.__typename,
      name: value$11.name,
      value: !(value$12 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$12) : undefined,
      taxonomyGroup: !(value$13 == null) ? value$13 : undefined
    };
  }
  var value$14 = value.occasion;
  var tmp$5;
  if (value$14 == null) {
    tmp$5 = undefined;
  } else {
    var value$15 = value$14.value;
    var value$16 = value$14.taxonomyGroup;
    tmp$5 = {
      __typename: value$14.__typename,
      name: value$14.name,
      value: !(value$15 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$15) : undefined,
      taxonomyGroup: !(value$16 == null) ? value$16 : undefined
    };
  }
  var value$17 = value.location_au;
  var tmp$6;
  if (value$17 == null) {
    tmp$6 = undefined;
  } else {
    var value$18 = value$17.value;
    var value$19 = value$17.taxonomyGroup;
    tmp$6 = {
      __typename: value$17.__typename,
      name: value$17.name,
      value: !(value$18 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$18) : undefined,
      taxonomyGroup: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.location___nz;
  var tmp$7;
  if (value$20 == null) {
    tmp$7 = undefined;
  } else {
    var value$21 = value$20.value;
    var value$22 = value$20.taxonomyGroup;
    tmp$7 = {
      __typename: value$20.__typename,
      name: value$20.name,
      value: !(value$21 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$21) : undefined,
      taxonomyGroup: !(value$22 == null) ? value$22 : undefined
    };
  }
  var value$23 = value.location___uk;
  var tmp$8;
  if (value$23 == null) {
    tmp$8 = undefined;
  } else {
    var value$24 = value$23.value;
    var value$25 = value$23.taxonomyGroup;
    tmp$8 = {
      __typename: value$23.__typename,
      name: value$23.name,
      value: !(value$24 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$24) : undefined,
      taxonomyGroup: !(value$25 == null) ? value$25 : undefined
    };
  }
  var value$26 = value.location___us;
  var tmp$9;
  if (value$26 == null) {
    tmp$9 = undefined;
  } else {
    var value$27 = value$26.value;
    var value$28 = value$26.taxonomyGroup;
    tmp$9 = {
      __typename: value$26.__typename,
      name: value$26.name,
      value: !(value$27 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        name: value.name,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$27) : undefined,
      taxonomyGroup: !(value$28 == null) ? value$28 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          themes: tmp,
          seo_meta: tmp$1,
          sold_out: tmp$2,
          audience: tmp$3,
          interest: tmp$4,
          occasion: tmp$5,
          location_au: tmp$6,
          location___nz: tmp$7,
          location___uk: tmp$8,
          location___us: tmp$9
        };
}

function serialize(value) {
  var value$1 = value.location___us;
  var location___us;
  if (value$1 !== undefined) {
    var value$2 = value$1.taxonomyGroup;
    var taxonomyGroup = value$2 !== undefined ? value$2 : null;
    var value$3 = value$1.value;
    var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$3) : null;
    var value$5 = value$1.name;
    var value$6 = value$1.__typename;
    location___us = {
      __typename: value$6,
      name: value$5,
      value: value$4,
      taxonomyGroup: taxonomyGroup
    };
  } else {
    location___us = null;
  }
  var value$7 = value.location___uk;
  var location___uk;
  if (value$7 !== undefined) {
    var value$8 = value$7.taxonomyGroup;
    var taxonomyGroup$1 = value$8 !== undefined ? value$8 : null;
    var value$9 = value$7.value;
    var value$10 = value$9 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$9) : null;
    var value$11 = value$7.name;
    var value$12 = value$7.__typename;
    location___uk = {
      __typename: value$12,
      name: value$11,
      value: value$10,
      taxonomyGroup: taxonomyGroup$1
    };
  } else {
    location___uk = null;
  }
  var value$13 = value.location___nz;
  var location___nz;
  if (value$13 !== undefined) {
    var value$14 = value$13.taxonomyGroup;
    var taxonomyGroup$2 = value$14 !== undefined ? value$14 : null;
    var value$15 = value$13.value;
    var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$15) : null;
    var value$17 = value$13.name;
    var value$18 = value$13.__typename;
    location___nz = {
      __typename: value$18,
      name: value$17,
      value: value$16,
      taxonomyGroup: taxonomyGroup$2
    };
  } else {
    location___nz = null;
  }
  var value$19 = value.location_au;
  var location_au;
  if (value$19 !== undefined) {
    var value$20 = value$19.taxonomyGroup;
    var taxonomyGroup$3 = value$20 !== undefined ? value$20 : null;
    var value$21 = value$19.value;
    var value$22 = value$21 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$21) : null;
    var value$23 = value$19.name;
    var value$24 = value$19.__typename;
    location_au = {
      __typename: value$24,
      name: value$23,
      value: value$22,
      taxonomyGroup: taxonomyGroup$3
    };
  } else {
    location_au = null;
  }
  var value$25 = value.occasion;
  var occasion;
  if (value$25 !== undefined) {
    var value$26 = value$25.taxonomyGroup;
    var taxonomyGroup$4 = value$26 !== undefined ? value$26 : null;
    var value$27 = value$25.value;
    var value$28 = value$27 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$27) : null;
    var value$29 = value$25.name;
    var value$30 = value$25.__typename;
    occasion = {
      __typename: value$30,
      name: value$29,
      value: value$28,
      taxonomyGroup: taxonomyGroup$4
    };
  } else {
    occasion = null;
  }
  var value$31 = value.interest;
  var interest;
  if (value$31 !== undefined) {
    var value$32 = value$31.taxonomyGroup;
    var taxonomyGroup$5 = value$32 !== undefined ? value$32 : null;
    var value$33 = value$31.value;
    var value$34 = value$33 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$33) : null;
    var value$35 = value$31.name;
    var value$36 = value$31.__typename;
    interest = {
      __typename: value$36,
      name: value$35,
      value: value$34,
      taxonomyGroup: taxonomyGroup$5
    };
  } else {
    interest = null;
  }
  var value$37 = value.audience;
  var audience;
  if (value$37 !== undefined) {
    var value$38 = value$37.taxonomyGroup;
    var taxonomyGroup$6 = value$38 !== undefined ? value$38 : null;
    var value$39 = value$37.value;
    var value$40 = value$39 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      name: value$2,
                      codename: codename
                    };
            }), value$39) : null;
    var value$41 = value$37.name;
    var value$42 = value$37.__typename;
    audience = {
      __typename: value$42,
      name: value$41,
      value: value$40,
      taxonomyGroup: taxonomyGroup$6
    };
  } else {
    audience = null;
  }
  var value$43 = value.sold_out;
  var sold_out;
  if (value$43 !== undefined) {
    var value$44 = value$43.value;
    var value$45 = value$44 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$44) : null;
    var value$46 = value$43.__typename;
    sold_out = {
      __typename: value$46,
      value: value$45
    };
  } else {
    sold_out = null;
  }
  var value$47 = value.seo_meta;
  var seo_meta;
  if (value$47 !== undefined) {
    var value$48 = value$47.value;
    var value$49 = value$48 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "SeoMetaContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__SeoMeta__Fragment.SeoMetaBlock.serialize(value.VAL.seoMetaBlock)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$48) : null;
    var value$50 = value$47.__typename;
    seo_meta = {
      __typename: value$50,
      value: value$49
    };
  } else {
    seo_meta = null;
  }
  var value$51 = value.themes;
  var themes;
  if (value$51 !== undefined) {
    var value$52 = value$51.value;
    var value$53 = value$52 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__SkuTheme__Fragment.SkuThemeFragment.serialize(value.VAL.skuThemeFragment)]);
                }
              } else {
                return null;
              }
            }), value$52) : null;
    var value$54 = value$51.__typename;
    themes = {
      __typename: value$54,
      value: value$53
    };
  } else {
    themes = null;
  }
  var value$55 = value.system;
  var value$56 = value$55.codename;
  var value$57 = value$55.id;
  var value$58 = value$55.__typename;
  var system = {
    __typename: value$58,
    id: value$57,
    codename: value$56
  };
  var value$59 = value.__typename;
  return {
          __typename: value$59,
          system: system,
          themes: themes,
          seo_meta: seo_meta,
          sold_out: sold_out,
          audience: audience,
          interest: interest,
          occasion: occasion,
          location_au: location_au,
          location___nz: location___nz,
          location___uk: location___uk,
          location___us: location___us
        };
}

function verifyArgsAndParse(_SkuPresentationFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SkuPresentationFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var SkuThemeFragment;

var SeoMetaBlock;

export {
  SkuThemeFragment ,
  SeoMetaBlock ,
  SkuPresentationFragment ,
}
/* query Not a pure module */
