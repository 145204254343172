// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__Video__Fragment from "../Video/Gql__Video__Fragment.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1, frag_2) => require("@apollo/client").gql`
  fragment HeroTileBlock on HeroTileBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    block_style  {
      __typename
      value  {
        __typename
        codename
      }
    }
    bottom_distance  {
      __typename
      value
    }
    top_distance  {
      __typename
      value
    }
    hero_tiles  {
      __typename
      value  {
        __typename
        ...on HeroTileContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          title  {
            __typename
            value
          }
          subtitle  {
            __typename
            value
          }
          description  {
            __typename
            value
          }
          text_colour  {
            __typename
            value  {
              __typename
              codename
            }
          }
          video_cta_text  {
            __typename
            value
          }
          buttons  {
            __typename
            value  {
              __typename
              ...on CallToActionLinkD6a3d61ContentType   {
                ...CallToAction
              }
            }
          }
          foreground_image  {
            __typename
            name
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          background_image  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          mobile_background_image  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          tablet_background_image  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          background_colour  {
            __typename
            value
          }
          background_video_new  {
            __typename
            value  {
              __typename
              ...on VideoContentType   {
                ...Video
              }
            }
          }
          mobile_background_video  {
            __typename
            value  {
              __typename
              ...on VideoContentType   {
                ...Video
              }
            }
          }
          tablet_background_video  {
            __typename
            value  {
              __typename
              ...on VideoContentType   {
                ...Video
              }
            }
          }
          loop_background_video  {
            __typename
            value  {
              __typename
              codename
            }
          }
          content_vertical_alignment  {
            __typename
            value  {
              __typename
              codename
            }
          }
          background_horizontal_alignment  {
            __typename
            value  {
              __typename
              codename
            }
          }
          cta  {
            __typename
            value  {
              __typename
              codename
            }
          }
          info_cta  {
            __typename
            value  {
              __typename
              codename
            }
          }
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
  ${frag_2}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__Image__Fragment.$$Image.query, Gql__Video__Fragment.Video.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.block_style;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.bottom_distance;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.top_distance;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.hero_tiles;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "HeroTileContentType") {
                  var value$1 = value.system;
                  var value$2 = value.title;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.subtitle;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.description;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.text_colour;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$9) : undefined
                    };
                  }
                  var value$10 = value.video_cta_text;
                  var tmp$5;
                  if (value$10 == null) {
                    tmp$5 = undefined;
                  } else {
                    var value$11 = value$10.value;
                    tmp$5 = {
                      __typename: value$10.__typename,
                      value: !(value$11 == null) ? value$11 : undefined
                    };
                  }
                  var value$12 = value.buttons;
                  var tmp$6;
                  if (value$12 == null) {
                    tmp$6 = undefined;
                  } else {
                    var value$13 = value$12.value;
                    tmp$6 = {
                      __typename: value$12.__typename,
                      value: !(value$13 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                      NAME: "CallToActionLinkD6a3d61ContentType",
                                      VAL: {
                                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$13) : undefined
                    };
                  }
                  var value$14 = value.foreground_image;
                  var tmp$7;
                  if (value$14 == null) {
                    tmp$7 = undefined;
                  } else {
                    var value$15 = value$14.value;
                    tmp$7 = {
                      __typename: value$14.__typename,
                      name: value$14.name,
                      value: !(value$15 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$15) : undefined
                    };
                  }
                  var value$16 = value.background_image;
                  var tmp$8;
                  if (value$16 == null) {
                    tmp$8 = undefined;
                  } else {
                    var value$17 = value$16.value;
                    tmp$8 = {
                      __typename: value$16.__typename,
                      value: !(value$17 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$17) : undefined
                    };
                  }
                  var value$18 = value.mobile_background_image;
                  var tmp$9;
                  if (value$18 == null) {
                    tmp$9 = undefined;
                  } else {
                    var value$19 = value$18.value;
                    tmp$9 = {
                      __typename: value$18.__typename,
                      value: !(value$19 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$19) : undefined
                    };
                  }
                  var value$20 = value.tablet_background_image;
                  var tmp$10;
                  if (value$20 == null) {
                    tmp$10 = undefined;
                  } else {
                    var value$21 = value$20.value;
                    tmp$10 = {
                      __typename: value$20.__typename,
                      value: !(value$21 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$21) : undefined
                    };
                  }
                  var value$22 = value.background_colour;
                  var tmp$11;
                  if (value$22 == null) {
                    tmp$11 = undefined;
                  } else {
                    var value$23 = value$22.value;
                    tmp$11 = {
                      __typename: value$22.__typename,
                      value: !(value$23 == null) ? value$23 : undefined
                    };
                  }
                  var value$24 = value.background_video_new;
                  var tmp$12;
                  if (value$24 == null) {
                    tmp$12 = undefined;
                  } else {
                    var value$25 = value$24.value;
                    tmp$12 = {
                      __typename: value$24.__typename,
                      value: !(value$25 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "VideoContentType" ? ({
                                      NAME: "VideoContentType",
                                      VAL: {
                                        video: Gql__Video__Fragment.Video.verifyArgsAndParse("Video", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$25) : undefined
                    };
                  }
                  var value$26 = value.mobile_background_video;
                  var tmp$13;
                  if (value$26 == null) {
                    tmp$13 = undefined;
                  } else {
                    var value$27 = value$26.value;
                    tmp$13 = {
                      __typename: value$26.__typename,
                      value: !(value$27 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "VideoContentType" ? ({
                                      NAME: "VideoContentType",
                                      VAL: {
                                        video: Gql__Video__Fragment.Video.verifyArgsAndParse("Video", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$27) : undefined
                    };
                  }
                  var value$28 = value.tablet_background_video;
                  var tmp$14;
                  if (value$28 == null) {
                    tmp$14 = undefined;
                  } else {
                    var value$29 = value$28.value;
                    tmp$14 = {
                      __typename: value$28.__typename,
                      value: !(value$29 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "VideoContentType" ? ({
                                      NAME: "VideoContentType",
                                      VAL: {
                                        video: Gql__Video__Fragment.Video.verifyArgsAndParse("Video", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$29) : undefined
                    };
                  }
                  var value$30 = value.loop_background_video;
                  var tmp$15;
                  if (value$30 == null) {
                    tmp$15 = undefined;
                  } else {
                    var value$31 = value$30.value;
                    tmp$15 = {
                      __typename: value$30.__typename,
                      value: !(value$31 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$31) : undefined
                    };
                  }
                  var value$32 = value.content_vertical_alignment;
                  var tmp$16;
                  if (value$32 == null) {
                    tmp$16 = undefined;
                  } else {
                    var value$33 = value$32.value;
                    tmp$16 = {
                      __typename: value$32.__typename,
                      value: !(value$33 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$33) : undefined
                    };
                  }
                  var value$34 = value.background_horizontal_alignment;
                  var tmp$17;
                  if (value$34 == null) {
                    tmp$17 = undefined;
                  } else {
                    var value$35 = value$34.value;
                    tmp$17 = {
                      __typename: value$34.__typename,
                      value: !(value$35 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$35) : undefined
                    };
                  }
                  var value$36 = value.cta;
                  var tmp$18;
                  if (value$36 == null) {
                    tmp$18 = undefined;
                  } else {
                    var value$37 = value$36.value;
                    tmp$18 = {
                      __typename: value$36.__typename,
                      value: !(value$37 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$37) : undefined
                    };
                  }
                  var value$38 = value.info_cta;
                  var tmp$19;
                  if (value$38 == null) {
                    tmp$19 = undefined;
                  } else {
                    var value$39 = value$38.value;
                    tmp$19 = {
                      __typename: value$38.__typename,
                      value: !(value$39 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$39) : undefined
                    };
                  }
                  tmp = {
                    NAME: "HeroTileContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      title: tmp$1,
                      subtitle: tmp$2,
                      description: tmp$3,
                      text_colour: tmp$4,
                      video_cta_text: tmp$5,
                      buttons: tmp$6,
                      foreground_image: tmp$7,
                      background_image: tmp$8,
                      mobile_background_image: tmp$9,
                      tablet_background_image: tmp$10,
                      background_colour: tmp$11,
                      background_video_new: tmp$12,
                      mobile_background_video: tmp$13,
                      tablet_background_video: tmp$14,
                      loop_background_video: tmp$15,
                      content_vertical_alignment: tmp$16,
                      background_horizontal_alignment: tmp$17,
                      cta: tmp$18,
                      info_cta: tmp$19
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$9) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          block_style: tmp,
          bottom_distance: tmp$1,
          top_distance: tmp$2,
          hero_tiles: tmp$3
        };
}

function serialize(value) {
  var value$1 = value.hero_tiles;
  var hero_tiles;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.info_cta;
              var info_cta;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                info_cta = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                info_cta = null;
              }
              var value$6 = value$1.cta;
              var cta;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$7) : null;
                var value$9 = value$6.__typename;
                cta = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                cta = null;
              }
              var value$10 = value$1.background_horizontal_alignment;
              var background_horizontal_alignment;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$11) : null;
                var value$13 = value$10.__typename;
                background_horizontal_alignment = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                background_horizontal_alignment = null;
              }
              var value$14 = value$1.content_vertical_alignment;
              var content_vertical_alignment;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$15) : null;
                var value$17 = value$14.__typename;
                content_vertical_alignment = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                content_vertical_alignment = null;
              }
              var value$18 = value$1.loop_background_video;
              var loop_background_video;
              if (value$18 !== undefined) {
                var value$19 = value$18.value;
                var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$19) : null;
                var value$21 = value$18.__typename;
                loop_background_video = {
                  __typename: value$21,
                  value: value$20
                };
              } else {
                loop_background_video = null;
              }
              var value$22 = value$1.tablet_background_video;
              var tablet_background_video;
              if (value$22 !== undefined) {
                var value$23 = value$22.value;
                var value$24 = value$23 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "VideoContentType") {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Video__Fragment.Video.serialize(value.VAL.video)]);
                            } else {
                              return {};
                            }
                          } else {
                            return null;
                          }
                        }), value$23) : null;
                var value$25 = value$22.__typename;
                tablet_background_video = {
                  __typename: value$25,
                  value: value$24
                };
              } else {
                tablet_background_video = null;
              }
              var value$26 = value$1.mobile_background_video;
              var mobile_background_video;
              if (value$26 !== undefined) {
                var value$27 = value$26.value;
                var value$28 = value$27 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "VideoContentType") {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Video__Fragment.Video.serialize(value.VAL.video)]);
                            } else {
                              return {};
                            }
                          } else {
                            return null;
                          }
                        }), value$27) : null;
                var value$29 = value$26.__typename;
                mobile_background_video = {
                  __typename: value$29,
                  value: value$28
                };
              } else {
                mobile_background_video = null;
              }
              var value$30 = value$1.background_video_new;
              var background_video_new;
              if (value$30 !== undefined) {
                var value$31 = value$30.value;
                var value$32 = value$31 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "VideoContentType") {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Video__Fragment.Video.serialize(value.VAL.video)]);
                            } else {
                              return {};
                            }
                          } else {
                            return null;
                          }
                        }), value$31) : null;
                var value$33 = value$30.__typename;
                background_video_new = {
                  __typename: value$33,
                  value: value$32
                };
              } else {
                background_video_new = null;
              }
              var value$34 = value$1.background_colour;
              var background_colour;
              if (value$34 !== undefined) {
                var value$35 = value$34.value;
                var value$36 = value$35 !== undefined ? value$35 : null;
                var value$37 = value$34.__typename;
                background_colour = {
                  __typename: value$37,
                  value: value$36
                };
              } else {
                background_colour = null;
              }
              var value$38 = value$1.tablet_background_image;
              var tablet_background_image;
              if (value$38 !== undefined) {
                var value$39 = value$38.value;
                var value$40 = value$39 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$39) : null;
                var value$41 = value$38.__typename;
                tablet_background_image = {
                  __typename: value$41,
                  value: value$40
                };
              } else {
                tablet_background_image = null;
              }
              var value$42 = value$1.mobile_background_image;
              var mobile_background_image;
              if (value$42 !== undefined) {
                var value$43 = value$42.value;
                var value$44 = value$43 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$43) : null;
                var value$45 = value$42.__typename;
                mobile_background_image = {
                  __typename: value$45,
                  value: value$44
                };
              } else {
                mobile_background_image = null;
              }
              var value$46 = value$1.background_image;
              var background_image;
              if (value$46 !== undefined) {
                var value$47 = value$46.value;
                var value$48 = value$47 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$47) : null;
                var value$49 = value$46.__typename;
                background_image = {
                  __typename: value$49,
                  value: value$48
                };
              } else {
                background_image = null;
              }
              var value$50 = value$1.foreground_image;
              var foreground_image;
              if (value$50 !== undefined) {
                var value$51 = value$50.value;
                var value$52 = value$51 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$51) : null;
                var value$53 = value$50.name;
                var value$54 = value$50.__typename;
                foreground_image = {
                  __typename: value$54,
                  name: value$53,
                  value: value$52
                };
              } else {
                foreground_image = null;
              }
              var value$55 = value$1.buttons;
              var buttons;
              if (value$55 !== undefined) {
                var value$56 = value$55.value;
                var value$57 = value$56 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                            } else {
                              return {};
                            }
                          } else {
                            return null;
                          }
                        }), value$56) : null;
                var value$58 = value$55.__typename;
                buttons = {
                  __typename: value$58,
                  value: value$57
                };
              } else {
                buttons = null;
              }
              var value$59 = value$1.video_cta_text;
              var video_cta_text;
              if (value$59 !== undefined) {
                var value$60 = value$59.value;
                var value$61 = value$60 !== undefined ? value$60 : null;
                var value$62 = value$59.__typename;
                video_cta_text = {
                  __typename: value$62,
                  value: value$61
                };
              } else {
                video_cta_text = null;
              }
              var value$63 = value$1.text_colour;
              var text_colour;
              if (value$63 !== undefined) {
                var value$64 = value$63.value;
                var value$65 = value$64 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$64) : null;
                var value$66 = value$63.__typename;
                text_colour = {
                  __typename: value$66,
                  value: value$65
                };
              } else {
                text_colour = null;
              }
              var value$67 = value$1.description;
              var description;
              if (value$67 !== undefined) {
                var value$68 = value$67.value;
                var value$69 = value$68 !== undefined ? value$68 : null;
                var value$70 = value$67.__typename;
                description = {
                  __typename: value$70,
                  value: value$69
                };
              } else {
                description = null;
              }
              var value$71 = value$1.subtitle;
              var subtitle;
              if (value$71 !== undefined) {
                var value$72 = value$71.value;
                var value$73 = value$72 !== undefined ? value$72 : null;
                var value$74 = value$71.__typename;
                subtitle = {
                  __typename: value$74,
                  value: value$73
                };
              } else {
                subtitle = null;
              }
              var value$75 = value$1.title;
              var title;
              if (value$75 !== undefined) {
                var value$76 = value$75.value;
                var value$77 = value$76 !== undefined ? value$76 : null;
                var value$78 = value$75.__typename;
                title = {
                  __typename: value$78,
                  value: value$77
                };
              } else {
                title = null;
              }
              var value$79 = value$1.system;
              var value$80 = value$79.codename;
              var value$81 = value$79.id;
              var value$82 = value$79.__typename;
              var system = {
                __typename: value$82,
                id: value$81,
                codename: value$80
              };
              return {
                      __typename: "HeroTileContentType",
                      system: system,
                      title: title,
                      subtitle: subtitle,
                      description: description,
                      text_colour: text_colour,
                      video_cta_text: video_cta_text,
                      buttons: buttons,
                      foreground_image: foreground_image,
                      background_image: background_image,
                      mobile_background_image: mobile_background_image,
                      tablet_background_image: tablet_background_image,
                      background_colour: background_colour,
                      background_video_new: background_video_new,
                      mobile_background_video: mobile_background_video,
                      tablet_background_video: tablet_background_video,
                      loop_background_video: loop_background_video,
                      content_vertical_alignment: content_vertical_alignment,
                      background_horizontal_alignment: background_horizontal_alignment,
                      cta: cta,
                      info_cta: info_cta
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    hero_tiles = {
      __typename: value$4,
      value: value$3
    };
  } else {
    hero_tiles = null;
  }
  var value$5 = value.top_distance;
  var top_distance;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    top_distance = {
      __typename: value$8,
      value: value$7
    };
  } else {
    top_distance = null;
  }
  var value$9 = value.bottom_distance;
  var bottom_distance;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    bottom_distance = {
      __typename: value$12,
      value: value$11
    };
  } else {
    bottom_distance = null;
  }
  var value$13 = value.block_style;
  var block_style;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$14) : null;
    var value$16 = value$13.__typename;
    block_style = {
      __typename: value$16,
      value: value$15
    };
  } else {
    block_style = null;
  }
  var value$17 = value.system;
  var value$18 = value$17.codename;
  var value$19 = value$17.id;
  var value$20 = value$17.__typename;
  var system = {
    __typename: value$20,
    id: value$19,
    codename: value$18
  };
  var value$21 = value.__typename;
  return {
          __typename: value$21,
          system: system,
          block_style: block_style,
          bottom_distance: bottom_distance,
          top_distance: top_distance,
          hero_tiles: hero_tiles
        };
}

function verifyArgsAndParse(_HeroTileBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var HeroTileBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var Video;

var CallToAction;

export {
  $$Image ,
  Video ,
  CallToAction ,
  HeroTileBlock ,
}
/* query Not a pure module */
