import { camelKeys, snakeKeys } from 'js-convert-case';

import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { PaymentIntentPayload, PaymentIntentResponse } from 'models/payment.model';

/**
 * Request to get payment intent from Prezzee API.
 * @param url - the url to the api endpoint
 * @param payload - the request body
 * @returns
 */
const getPaymentIntent = (url: string, payload: PaymentIntentPayload): Promise<PaymentIntentResponse> =>
  createRequest(url, {
    body: JSON.stringify(snakeKeys(payload)),
    isUrl: true,
    logRequest: true,
    method: 'POST',
    mode: 'cors',
    withAuth: true,
    withSecurityToken: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as PaymentIntentResponse);

export default getPaymentIntent;
