// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__SeoMeta__Decoder from "../SeoMetaContentType/Gql__SeoMeta__Decoder.bs.js";
import * as Gql__SkuTheme__Decoder from "../SkuTheme/Gql__SkuTheme__Decoder.bs.js";

var emptyTaxonomy = {
  name: "",
  value: {
    name: "",
    codename: ""
  },
  taxonomyGroup: ""
};

function decode(f) {
  var seo_meta = Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(f.seo_meta, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (f) {
                  if (typeof f === "object" && f.NAME === "SeoMetaContentType") {
                    return {
                            NAME: "SeoMetaBlock",
                            VAL: Gql__SeoMeta__Decoder.get(f.VAL.seoMetaBlock)
                          };
                  }
                  
                })), GqlUtils.id), 0);
  var soldOut = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(f.sold_out, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), false, (function (str) {
          if (str === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  var codename = f.system.codename;
  var themes = Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(f.themes, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "SkuThemeContentType") {
            return Gql__SkuTheme__Decoder.get(f.VAL.skuThemeFragment);
          }
          
        }));
  var audience = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.audience, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  var interest = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.interest, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  var occasion = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.occasion, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  var locationAu = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.location_au, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  var locationNz = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.location___nz, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  var locationUk = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.location___uk, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  var locationUs = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(f.location___us, (function (c) {
                  return c.value;
                })), []), (function (f) {
          if (f !== undefined) {
            return {
                    name: f.name,
                    value: {
                      name: f.name,
                      codename: f.codename
                    },
                    taxonomyGroup: ""
                  };
          } else {
            return emptyTaxonomy;
          }
        }));
  return {
          codename: codename,
          seo_meta: seo_meta,
          soldOut: soldOut,
          themes: themes,
          audience: audience,
          interest: interest,
          occasion: occasion,
          locationAu: locationAu,
          locationNz: locationNz,
          locationUk: locationUk,
          locationUs: locationUs
        };
}

var SeoMetaBlock;

var SkuTheme;

export {
  SeoMetaBlock ,
  SkuTheme ,
  emptyTaxonomy ,
  decode ,
}
/* No side effect */
