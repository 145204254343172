import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

/**
 * @secure
 * Request to send a customers MFA token.
 * @param email the customer email
 * @param phone the customer phone number for receiving the code
 * @returns Promise of decoded request security code response.
 */
const requestSecurityCode = (email: string, phone: string) => {
  return createRequest('api/v3/customer/securitycode/request/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify({ email, phone }),
  }).then(handleErrors);
};

export default requestSecurityCode;
