// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Gql__ProductHeroIcon__Decoder from "../ProductHeroIcon/Gql__ProductHeroIcon__Decoder.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var subtitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c.subtitle, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.enhanced_description, (function (c) {
              return c.value;
            })), "");
  var icons = Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icons, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "ProductHeroIconContentType") {
            return Gql__ProductHeroIcon__Decoder.get(f.VAL.productHeroIcon);
          }
          
        }));
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description$1,
    url: url,
    width: width,
    height: height
  };
  var primaryButton = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.primary_button, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var secondaryButton = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.secondary_button, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var productSlug = Belt_Option.getWithDefault(Belt_Option.flatMap(c.product_slug, (function (c) {
              return c.value;
            })), "");
  var additionalDescription = Belt_Option.getWithDefault(Belt_Option.flatMap(c.additional_description, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          subtitle: subtitle,
          description: description,
          icons: icons,
          image: result,
          primaryButton: primaryButton,
          secondaryButton: secondaryButton,
          productSlug: productSlug,
          additionalDescription: additionalDescription,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
