// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__HeroTiles__Decoder__Button from "./Gql__HeroTiles__Decoder__Button.bs.js";
import * as Gql__HeroTiles__Decoder__BackgroundImage from "./Gql__HeroTiles__Decoder__BackgroundImage.bs.js";
import * as Gql__HeroTiles__Decoder__BackgroundVideo from "./Gql__HeroTiles__Decoder__BackgroundVideo.bs.js";
import * as Gql__HeroTiles__Decoder__ForegroundImage from "./Gql__HeroTiles__Decoder__ForegroundImage.bs.js";
import * as Gql__HeroTiles__Decoder__MobileBackgroundImage from "./Gql__HeroTiles__Decoder__MobileBackgroundImage.bs.js";
import * as Gql__HeroTiles__Decoder__MobileBackgroundVideo from "./Gql__HeroTiles__Decoder__MobileBackgroundVideo.bs.js";
import * as Gql__HeroTiles__Decoder__TabletBackgroundImage from "./Gql__HeroTiles__Decoder__TabletBackgroundImage.bs.js";
import * as Gql__HeroTiles__Decoder__TabletBackgroundVideo from "./Gql__HeroTiles__Decoder__TabletBackgroundVideo.bs.js";

var emptyHeroTile_buttons = [];

var emptyHeroTile = {
  title: "",
  subtitle: "",
  description: "",
  text_colour: /* Dark */0,
  video_cta_text: "",
  buttons: emptyHeroTile_buttons,
  foreground_image: undefined,
  background_image: undefined,
  mobile_background_image: undefined,
  tablet_background_image: undefined,
  background_colour: undefined,
  background_video: undefined,
  mobile_background_video: undefined,
  tablet_background_video: undefined,
  loop_background_video: true,
  content_vertical_alignment: "",
  background_horizontal_alignment: "",
  smartLink: SmartLink.emptySmartLink,
  cta: false,
  infoCta: false
};

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "HeroTileContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var subtitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c.subtitle, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var text_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), /* Dark */0, (function (str) {
          switch (str) {
            case "dark" :
                return /* Dark */0;
            case "light" :
                return /* Light */1;
            default:
              return /* Dark */0;
          }
        }));
  var video_cta_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.video_cta_text, (function (c) {
              return c.value;
            })), "");
  var buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.buttons, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__HeroTiles__Decoder__Button.get);
  var foreground_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.foreground_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__ForegroundImage.get), 0);
  var background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__BackgroundImage.get), 0);
  var mobile_background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__MobileBackgroundImage.get), 0);
  var tablet_background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__TabletBackgroundImage.get), 0);
  var background_colour = Belt_Option.flatMap(c.background_colour, (function (c) {
          return c.value;
        }));
  var background_video = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_video_new, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__BackgroundVideo.get), 0);
  var mobile_background_video = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_background_video, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__MobileBackgroundVideo.get), 0);
  var tablet_background_video = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_background_video, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__HeroTiles__Decoder__TabletBackgroundVideo.get), 0);
  var loop_background_video = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.loop_background_video, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), true, (function (str) {
          switch (str) {
            case "false" :
                return false;
            case "true" :
                return true;
            default:
              return true;
          }
        }));
  var content_vertical_alignment = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.content_vertical_alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "");
  var background_horizontal_alignment = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_horizontal_alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var cta = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), false, (function (v) {
          return v === "enable_as_cta";
        }));
  var infoCta = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.info_cta, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), false, (function (v) {
          return v === "enable_as_info_cta";
        }));
  return {
          title: title,
          subtitle: subtitle,
          description: description,
          text_colour: text_colour,
          video_cta_text: video_cta_text,
          buttons: buttons,
          foreground_image: foreground_image,
          background_image: background_image,
          mobile_background_image: mobile_background_image,
          tablet_background_image: tablet_background_image,
          background_colour: background_colour,
          background_video: background_video,
          mobile_background_video: mobile_background_video,
          tablet_background_video: tablet_background_video,
          loop_background_video: loop_background_video,
          content_vertical_alignment: content_vertical_alignment,
          background_horizontal_alignment: background_horizontal_alignment,
          smartLink: smartLink,
          cta: cta,
          infoCta: infoCta
        };
}

var $$Image;

var ForegroundImage;

var BackgroundImage;

var MobileBackgroundImage;

var TabletBackgroundImage;

var Button;

var BackgroundVideo;

var MobileBackgroundVideo;

var TabletBackgroundVideo;

export {
  $$Image ,
  ForegroundImage ,
  BackgroundImage ,
  MobileBackgroundImage ,
  TabletBackgroundImage ,
  Button ,
  BackgroundVideo ,
  MobileBackgroundVideo ,
  TabletBackgroundVideo ,
  emptyHeroTile ,
  get ,
}
/* No side effect */
