// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "CategoryTileContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var link = Belt_Option.getWithDefault(Belt_Option.flatMap(c.url, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var image;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    image = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    image = undefined;
  }
  var labelBackgroundColor = Belt_Option.getWithDefault(Belt_Option.flatMap(c.label_background_color, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          image: image,
          link: link,
          labelBackgroundColor: labelBackgroundColor,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
