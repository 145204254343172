// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "TextListItemContentType") {
    return ;
  }
  var item = content.VAL;
  var text = Belt_Option.getWithDefault(Belt_Option.flatMap(item.text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = item.system.id;
  var smartLink_codename = item.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          text: text,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
