// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.classic_logo_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var classicLogoWidth = Belt_Option.getWithDefault(Belt_Option.flatMap(c.classic_logo_width, (function (f) {
              return f.value;
            })), 0);
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.business_logo_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.name;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.description;
            })), "");
  var url$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.url;
            })), "");
  var width$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.width;
            })), 0);
  var height$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.height;
            })), 0);
  var result$1 = {
    name: name$1,
    description: description$1,
    url: url$1,
    width: width$1,
    height: height$1
  };
  var businessLogoWidth = Belt_Option.getWithDefault(Belt_Option.flatMap(c.business_logo_width, (function (f) {
              return f.value;
            })), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          classicLogoAsset: result,
          classicLogoWidth: classicLogoWidth,
          businessLogoAsset: result$1,
          businessLogoWidth: businessLogoWidth,
          smartLink: smartLink
        };
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
