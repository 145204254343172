import { FC } from 'react';

type Props = {
  text: string;
};

const CookieSettingButton: FC<Props> = ({ text }) => {
  const onClick = () => {
    window.OneTrust?.ToggleInfoDisplay();
  };

  return (
    <a id="ot-sdk-btn" className="cursor-pointer" onClick={onClick}>
      {text}
    </a>
  );
};

export default CookieSettingButton;
