// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

var emptyImage = {
  name: "",
  description: "",
  url: "",
  width: 0,
  height: 0,
  smartLink: SmartLink.emptySmartLink,
  maxWidth: ""
};

function get(content) {
  var maxWidth = Belt_Option.getWithDefault(Belt_Option.flatMap(content.width, (function (w) {
              return w.value;
            })), "");
  var file = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.file, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.height;
            })), 0);
  var smartLink_itemId = content.system.id;
  var smartLink_codename = content.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          name: name,
          description: description,
          url: url,
          width: width,
          height: height,
          smartLink: smartLink,
          maxWidth: maxWidth
        };
}

export {
  emptyImage ,
  get ,
}
/* No side effect */
