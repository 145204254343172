// Generated by ReScript, PLEASE EDIT WITH CARE

import LoggerTs from "../utils/logger.ts";

var logger = LoggerTs;

export {
  logger ,
}
/* logger Not a pure module */
