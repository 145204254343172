// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__AppStoreItem__Decoder from "../AppStoreItem/Gql__AppStoreItem__Decoder.bs.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Gql__GiftLandingPage__Decoder__FAQ from "./Gql__GiftLandingPage__Decoder__FAQ.bs.js";

function get(c) {
  if (typeof c !== "object") {
    return ;
  }
  if (c.NAME !== "GiftLandingPageContentType") {
    return ;
  }
  var c$1 = c.VAL;
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.classic_logo_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.business_logo_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.name;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.description;
            })), "");
  var url$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.url;
            })), "");
  var width$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.width;
            })), 0);
  var height$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.height;
            })), 0);
  var result$1 = {
    name: name$1,
    description: description$1,
    url: url$1,
    width: width$1,
    height: height$1
  };
  var senderMessage = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.sender_message, (function (c) {
              return c.value;
            })), "");
  var giftRevealCtaText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.gift_reveal_cta_text, (function (c) {
              return c.value;
            })), "");
  var confettiLottie = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.confetti_lottie, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var faqs = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.faqs, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__GiftLandingPage__Decoder__FAQ.get);
  var expiryLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.expiry_label, (function (c) {
              return c.value;
            })), "");
  var noExpiryText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.no_expiry_text, (function (c) {
              return c.value;
            })), "");
  var checkBalanceText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.check_balance_text, (function (c) {
              return c.value;
            })), "");
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.check_balance_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var checkBalanceIcon;
  if (value$2 !== undefined) {
    var name$2 = Belt_Option.getWithDefault(value$2.name, "");
    var description$2 = Belt_Option.getWithDefault(value$2.description, "");
    var url$2 = Belt_Option.getWithDefault(value$2.url, "");
    var width$2 = Belt_Option.getWithDefault(value$2.width, 0);
    var height$2 = Belt_Option.getWithDefault(value$2.height, 0);
    checkBalanceIcon = {
      name: name$2,
      description: description$2,
      url: url$2,
      width: width$2,
      height: height$2
    };
  } else {
    checkBalanceIcon = undefined;
  }
  var storeInWalletCtaTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.store_in_wallet_cta_title, (function (c) {
              return c.value;
            })), "");
  var swapCardCtaTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.swap_egift_card_cta_title, (function (c) {
              return c.value;
            })), "");
  var optionalGiftCardCta = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.optional_gift_card_cta, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var value$3 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.replay_button_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var replayButtonIcon;
  if (value$3 !== undefined) {
    var name$3 = Belt_Option.getWithDefault(value$3.name, "");
    var description$3 = Belt_Option.getWithDefault(value$3.description, "");
    var url$3 = Belt_Option.getWithDefault(value$3.url, "");
    var width$3 = Belt_Option.getWithDefault(value$3.width, 0);
    var height$3 = Belt_Option.getWithDefault(value$3.height, 0);
    replayButtonIcon = {
      name: name$3,
      description: description$3,
      url: url$3,
      width: width$3,
      height: height$3
    };
  } else {
    replayButtonIcon = undefined;
  }
  var replayButtonText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.replay_button_text, (function (c) {
              return c.value;
            })), "");
  var redeemOnlineText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.redeem_online_text, (function (c) {
              return c.value;
            })), "");
  var redeemOnlineTextColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.redeem_online_text_colour, (function (c) {
              return c.value;
            })), "");
  var redeemOnlineButton = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.redeem_online_button, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var downloadOptionText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.download_option_text, (function (c) {
              return c.value;
            })), "");
  var downloadOptionTextColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.download_option_text_colour, (function (c) {
              return c.value;
            })), "");
  var downloadButton = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.download_button, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var giftUnwrapCtaText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.gift_unwrap_cta_text, (function (c) {
              return c.value;
            })), "");
  var giftUnwrapLottieSegments = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.gift_unwrap_lottie_segments, (function (c) {
              return c.value;
            })), "");
  var classicGiftUnwrapLottie = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.classic_gift_unwrap_lottie, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var businessGiftUnwrapLottie = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.business_gift_unwrap_lottie, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var spendItNowHeading = Belt_Option.flatMap(c$1.spend_it_now_heading, (function (c) {
          return c.value;
        }));
  var clipboardItemCopyText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.clipboard_item_copy_text, (function (c) {
              return c.value;
            })), "");
  var clipboardItemCopiedText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.clipboard_item_copied_text, (function (c) {
              return c.value;
            })), "");
  var barcodeNumberClipboardHeading = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.barcode_number_clipboard_heading, (function (c) {
              return c.value;
            })), "");
  var inStoreNumberClipboardHeading = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.in_store_number_clipboard_heading, (function (c) {
              return c.value;
            })), "");
  var onlineNumberClipboardHeading = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.online_number_clipboard_heading, (function (c) {
              return c.value;
            })), "");
  var onlineAndInStoreNumberClipboardHeading = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.online_and_in_store_clipboard_heading, (function (c) {
              return c.value;
            })), "");
  var swapCardsTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.swap_cards_title, (function (c) {
              return c.value;
            })), "");
  var swapCardAlt = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.swap_card_alt, (function (c) {
              return c.value;
            })), "");
  var swapCardOriginalValueLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.swap_card_original_value_label, (function (c) {
              return c.value;
            })), "");
  var swapCardButtonStyle = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.swap_card_button_style, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var saveInAppleGoogleWalletText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.save_in_apple_google_wallet_text, (function (c) {
              return c.value;
            })), "");
  var saveInAppleGoogleWalletTextColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.save_in_apple_google_wallet_text_colour, (function (c) {
              return c.value;
            })), "");
  var value$4 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.google_wallet_image_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var googleWalletImage;
  if (value$4 !== undefined) {
    var name$4 = Belt_Option.getWithDefault(value$4.name, "");
    var description$4 = Belt_Option.getWithDefault(value$4.description, "");
    var url$4 = Belt_Option.getWithDefault(value$4.url, "");
    var width$4 = Belt_Option.getWithDefault(value$4.width, 0);
    var height$4 = Belt_Option.getWithDefault(value$4.height, 0);
    googleWalletImage = {
      name: name$4,
      description: description$4,
      url: url$4,
      width: width$4,
      height: height$4
    };
  } else {
    googleWalletImage = undefined;
  }
  var value$5 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.apple_wallet_image_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var appleWalletImage;
  if (value$5 !== undefined) {
    var name$5 = Belt_Option.getWithDefault(value$5.name, "");
    var description$5 = Belt_Option.getWithDefault(value$5.description, "");
    var url$5 = Belt_Option.getWithDefault(value$5.url, "");
    var width$5 = Belt_Option.getWithDefault(value$5.width, 0);
    var height$5 = Belt_Option.getWithDefault(value$5.height, 0);
    appleWalletImage = {
      name: name$5,
      description: description$5,
      url: url$5,
      width: width$5,
      height: height$5
    };
  } else {
    appleWalletImage = undefined;
  }
  var footerCopyrightText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.footer_copyright_text, (function (c) {
              return c.value;
            })), "");
  var footerDownloadText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.footer_download_text, (function (c) {
              return c.value;
            })), "");
  var appStoreIcons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.app_store_icons, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (c) {
          if (typeof c === "object" && c.NAME === "AppStoreItemContentType") {
            return Gql__AppStoreItem__Decoder.get(c.VAL.appStoreItem);
          }
          
        }));
  var createAccountModalTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.create_account_modal_title, (function (c) {
              return c.value;
            })), "");
  var createAccountModalList = Belt_Option.flatMap(c$1.create_account_modal_list, (function (c) {
          return c.value;
        }));
  var createAccountModalCtaText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.create_account_modal_cta_text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c$1.system.id;
  var smartLink_codename = c$1.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          classicLogo: result,
          businessLogo: result$1,
          senderMessage: senderMessage,
          giftRevealCtaText: giftRevealCtaText,
          confettiLottie: confettiLottie,
          faqs: faqs,
          expiryLabel: expiryLabel,
          noExpiryText: noExpiryText,
          checkBalanceText: checkBalanceText,
          checkBalanceIcon: checkBalanceIcon,
          storeInWalletCtaTitle: storeInWalletCtaTitle,
          swapCardCtaTitle: swapCardCtaTitle,
          optionalGiftCardCta: optionalGiftCardCta,
          replayButtonIcon: replayButtonIcon,
          replayButtonText: replayButtonText,
          redeemOnlineText: redeemOnlineText,
          redeemOnlineTextColour: redeemOnlineTextColour,
          redeemOnlineButton: redeemOnlineButton,
          downloadOptionText: downloadOptionText,
          downloadOptionTextColour: downloadOptionTextColour,
          downloadButton: downloadButton,
          giftUnwrapCtaText: giftUnwrapCtaText,
          giftUnwrapLottieSegments: giftUnwrapLottieSegments,
          classicGiftUnwrapLottie: classicGiftUnwrapLottie,
          businessGiftUnwrapLottie: businessGiftUnwrapLottie,
          smartLink: smartLink,
          spendItNowHeading: spendItNowHeading,
          clipboardItemCopyText: clipboardItemCopyText,
          clipboardItemCopiedText: clipboardItemCopiedText,
          barcodeNumberClipboardHeading: barcodeNumberClipboardHeading,
          inStoreNumberClipboardHeading: inStoreNumberClipboardHeading,
          onlineNumberClipboardHeading: onlineNumberClipboardHeading,
          onlineAndInStoreNumberClipboardHeading: onlineAndInStoreNumberClipboardHeading,
          swapCardsTitle: swapCardsTitle,
          swapCardAlt: swapCardAlt,
          swapCardOriginalValueLabel: swapCardOriginalValueLabel,
          swapCardButtonStyle: swapCardButtonStyle,
          saveInAppleGoogleWalletText: saveInAppleGoogleWalletText,
          saveInAppleGoogleWalletTextColour: saveInAppleGoogleWalletTextColour,
          googleWalletImage: googleWalletImage,
          appleWalletImage: appleWalletImage,
          footerCopyrightText: footerCopyrightText,
          footerDownloadText: footerDownloadText,
          appStoreIcons: appStoreIcons,
          createAccountModalTitle: createAccountModalTitle,
          createAccountModalList: createAccountModalList,
          createAccountModalCtaText: createAccountModalCtaText
        };
}

var Icon;

var $$Image;

var Faq;

var AppStoreItem;

export {
  Icon ,
  $$Image ,
  Faq ,
  AppStoreItem ,
  get ,
}
/* No side effect */
