// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Apollo from "./Apollo.bs.js";
import * as Js_math from "@rescript/std/lib/es6/js_math.js";
import * as GqlUtils from "./GqlUtils.bs.js";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Js_option from "@rescript/std/lib/es6/js_option.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Gql__Page__Query from "./Page/Gql__Page__Query.bs.js";
import * as Gql__Page__Decoder from "./Page/Gql__Page__Decoder.bs.js";
import * as Gql__LandingPage__Query from "./LandingPage/Gql__LandingPage__Query.bs.js";
import * as Gql__LandingPage__Decoder from "./LandingPage/Gql__LandingPage__Decoder.bs.js";
import * as Gql__StoreCategory__Query from "./StoreCategory/Gql__StoreCategory__Query.bs.js";
import * as Gql__SkuPresentation__Query from "./SkuPresentation/Gql__SkuPresentation__Query.bs.js";
import * as Gql__StoreCategory__Decoder from "./StoreCategory/Gql__StoreCategory__Decoder.bs.js";
import * as Gql__SkuPresentation__Decoder from "./SkuPresentation/Gql__SkuPresentation__Decoder.bs.js";
import * as Gql__GlobalConfiguration__Query from "./GlobalConfiguration/Gql__GlobalConfiguration__Query.bs.js";
import * as Gql__GiftLandingPageTheme__Query from "./GiftLandingPageTheme/Gql__GiftLandingPageTheme__Query.bs.js";
import * as Gql__GlobalConfiguration__Decoder from "./GlobalConfiguration/Gql__GlobalConfiguration__Decoder.bs.js";
import * as Gql__SkuPresentation__Query__List from "./SkuPresentation/Gql__SkuPresentation__Query__List.bs.js";
import * as Gql__GiftLandingPageTheme__Decoder from "./GiftLandingPageTheme/Gql__GiftLandingPageTheme__Decoder.bs.js";
import * as Gql__SkuPresentation__Decoder__List from "./SkuPresentation/Gql__SkuPresentation__Decoder__List.bs.js";
import * as Gql__EnrichedTextBlock__Query__Fields from "./EnrichedTextBlock/Gql__EnrichedTextBlock__Query__Fields.bs.js";
import * as Gql__EnrichedTextBlock__Decoder__Fields from "./EnrichedTextBlock/Gql__EnrichedTextBlock__Decoder__Fields.bs.js";

var KontentData = {};

function getGlobalConfiguration(language, preview) {
  var variables = Gql__GlobalConfiguration__Query.Query.makeVariables(language, preview, undefined);
  return Js_promise.then_(Gql__GlobalConfiguration__Decoder.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__GlobalConfiguration__Query.Query.query,
                  Raw: Gql__GlobalConfiguration__Query.Query.Raw,
                  parse: Gql__GlobalConfiguration__Query.Query.parse,
                  serialize: Gql__GlobalConfiguration__Query.Query.serialize,
                  serializeVariables: Gql__GlobalConfiguration__Query.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function getStoreCategory(language, preview, codename) {
  var variables = Gql__StoreCategory__Query.Query.makeVariables(language, preview, codename, undefined);
  return Js_promise.then_(Gql__StoreCategory__Decoder.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__StoreCategory__Query.Query.query,
                  Raw: Gql__StoreCategory__Query.Query.Raw,
                  parse: Gql__StoreCategory__Query.Query.parse,
                  serialize: Gql__StoreCategory__Query.Query.serialize,
                  serializeVariables: Gql__StoreCategory__Query.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function getLandingPage(language, preview, url) {
  var variables = Gql__LandingPage__Query.Query.makeVariables(language, preview, url, undefined);
  return Js_promise.then_(Gql__LandingPage__Decoder.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__LandingPage__Query.Query.query,
                  Raw: Gql__LandingPage__Query.Query.Raw,
                  parse: Gql__LandingPage__Query.Query.parse,
                  serialize: Gql__LandingPage__Query.Query.serialize,
                  serializeVariables: Gql__LandingPage__Query.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function getPageContent(language, preview, url) {
  var variables = Gql__Page__Query.Query.makeVariables(language, preview, url, undefined);
  return Js_promise.then_(Gql__Page__Decoder.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__Page__Query.Query.query,
                  Raw: Gql__Page__Query.Query.Raw,
                  parse: Gql__Page__Query.Query.parse,
                  serialize: Gql__Page__Query.Query.serialize,
                  serializeVariables: Gql__Page__Query.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function getGiftLandingPageTheme(language, preview, themeId) {
  var variables = Gql__GiftLandingPageTheme__Query.Query.makeVariables(language, preview, themeId, undefined);
  return Js_promise.then_(Gql__GiftLandingPageTheme__Decoder.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__GiftLandingPageTheme__Query.Query.query,
                  Raw: Gql__GiftLandingPageTheme__Query.Query.Raw,
                  parse: Gql__GiftLandingPageTheme__Query.Query.parse,
                  serialize: Gql__GiftLandingPageTheme__Query.Query.serialize,
                  serializeVariables: Gql__GiftLandingPageTheme__Query.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function getEnrichedTextBlockFields(language, preview, codenames) {
  var variables = Gql__EnrichedTextBlock__Query__Fields.Query.makeVariables(language, preview, codenames, undefined);
  return Js_promise.then_(Gql__EnrichedTextBlock__Decoder__Fields.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__EnrichedTextBlock__Query__Fields.Query.query,
                  Raw: Gql__EnrichedTextBlock__Query__Fields.Query.Raw,
                  parse: Gql__EnrichedTextBlock__Query__Fields.Query.parse,
                  serialize: Gql__EnrichedTextBlock__Query__Fields.Query.serialize,
                  serializeVariables: Gql__EnrichedTextBlock__Query__Fields.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function getCmsData(language, preview, isInEditorMode) {
  return Js_promise.then_((function (res) {
                var globalConfiguration = Belt_Option.flatMap(res, (function (p) {
                        return p.globalConfig;
                      }));
                var dictionaryItems = Belt_Option.flatMap(res, (function (p) {
                        return p.dictionaryItems;
                      }));
                var $$location = Belt_Option.flatMap(res, (function (p) {
                        return p.locations;
                      }));
                var giftLandingPage = Belt_Option.flatMap(res, (function (p) {
                        return p.giftLandingPage;
                      }));
                var login = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.login;
                      }));
                var register = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.register;
                      }));
                var footer = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.footerBlock;
                      }));
                var businessFooter = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.businessFooter;
                      }));
                var header = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.headerBlock;
                      }));
                var businessHeader = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.businessHeader;
                      }));
                var mobileNavMenuBlock = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (c) {
                            return c.mobileNavMenuBlock;
                          })), []);
                var desktopNavMenuBlock = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (c) {
                            return c.desktopNavMenuBlock;
                          })), []);
                var alertBanner = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.alertBanner;
                      }));
                var cookiePopup = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.cookiePopup;
                      }));
                var warnings = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (c) {
                            return c.warnings;
                          })), []);
                var headerLogo = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.headerLogo;
                      }));
                var classicLoginCtaList = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (p) {
                            return p.classicLoginCtaList;
                          })), []);
                var businessLoginCtaList = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (p) {
                            return p.businessLoginCtaList;
                          })), []);
                var searchBarPlaceholder = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (p) {
                            return p.searchBarPlaceholder;
                          })), "");
                var noSearchResults = Belt_Option.flatMap(globalConfiguration, (function (p) {
                        return p.noSearchResults;
                      }));
                var soldOutBadgeText = Belt_Option.getWithDefault(Belt_Option.map(globalConfiguration, (function (p) {
                            return p.soldOutBadgeText;
                          })), "");
                var kontentData = isInEditorMode ? ({
                      projectId: "7af951a6-2a13-004b-f0eb-a87382a5b2e7",
                      languageCodename: language
                    }) : undefined;
                return Promise.resolve({
                            location: $$location,
                            footer: footer,
                            businessFooter: businessFooter,
                            dictionaryItems: dictionaryItems,
                            header: header,
                            businessHeader: businessHeader,
                            mobileNavMenuBlock: mobileNavMenuBlock,
                            desktopNavMenuBlock: desktopNavMenuBlock,
                            alertBanner: alertBanner,
                            kontentData: kontentData,
                            giftLandingPage: giftLandingPage,
                            login: login,
                            register: register,
                            cookiePopup: cookiePopup,
                            warnings: warnings,
                            headerLogo: headerLogo,
                            classicLoginCtaList: classicLoginCtaList,
                            businessLoginCtaList: businessLoginCtaList,
                            searchBarPlaceholder: searchBarPlaceholder,
                            noSearchResults: noSearchResults,
                            soldOutBadgeText: soldOutBadgeText
                          });
              }), getGlobalConfiguration(language, preview));
}

function getSkuPresentation(language, preview, codename) {
  var variables = Gql__SkuPresentation__Query.Query.makeVariables(language, preview, codename, undefined);
  return Js_promise.then_(Gql__SkuPresentation__Decoder.Decoder.handleResult, Curry._6(Apollo.client.rescript_query, {
                  query: Gql__SkuPresentation__Query.Query.query,
                  Raw: Gql__SkuPresentation__Query.Query.Raw,
                  parse: Gql__SkuPresentation__Query.Query.parse,
                  serialize: Gql__SkuPresentation__Query.Query.serialize,
                  serializeVariables: Gql__SkuPresentation__Query.Query.serializeVariables
                }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, variables));
}

function querySkuPresentationList(language, preview, codenames) {
  return Curry._6(Apollo.client.rescript_query, {
              query: Gql__SkuPresentation__Query__List.Query.query,
              Raw: Gql__SkuPresentation__Query__List.Query.Raw,
              parse: Gql__SkuPresentation__Query__List.Query.parse,
              serialize: Gql__SkuPresentation__Query__List.Query.serialize,
              serializeVariables: Gql__SkuPresentation__Query__List.Query.serializeVariables
            }, Caml_option.some(GqlUtils.getQueryContext(preview)), undefined, /* NoCache */3, undefined, Gql__SkuPresentation__Query__List.Query.makeVariables(language, preview, codenames, undefined));
}

function getSkuPresentationList(language, preview, codenames) {
  if (codenames.length <= 35) {
    return Js_promise.then_(Gql__SkuPresentation__Decoder__List.Decoder.handleResult, querySkuPresentationList(language, preview, codenames));
  }
  var halfCodenameLength = Js_math.ceil(codenames.length / 2 | 0);
  return Js_promise.then_((function (result) {
                return Js_promise.$$catch((function (error) {
                              console.log(error);
                              return Promise.resolve([]);
                            }), Js_promise.then_((function (listResult) {
                                  return Promise.resolve(Js_array.concat(Js_option.getExn(listResult[0]), Js_option.getExn(listResult[1])));
                                }), Promise.all([
                                    Gql__SkuPresentation__Decoder__List.Decoder.handleResult(result[0]),
                                    Gql__SkuPresentation__Decoder__List.Decoder.handleResult(result[1])
                                  ])));
              }), Promise.all([
                  querySkuPresentationList(language, preview, Belt_Array.slice(codenames, 0, halfCodenameLength)),
                  querySkuPresentationList(language, preview, Belt_Array.slice(codenames, -halfCodenameLength | 0, codenames.length - halfCodenameLength | 0))
                ]));
}

export {
  KontentData ,
  getGlobalConfiguration ,
  getStoreCategory ,
  getLandingPage ,
  getPageContent ,
  getGiftLandingPageTheme ,
  getEnrichedTextBlockFields ,
  getCmsData ,
  getSkuPresentation ,
  querySkuPresentationList ,
  getSkuPresentationList ,
}
/* Apollo Not a pure module */
