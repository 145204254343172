// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Page from "./Page.bs.js";
import * as Themes from "../css/Themes.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as BusinessFooter from "./BusinessFooter.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function BusinessPage(props) {
  var m = Belt_Option.getWithDefault(props.mainClassName, Page.Styles.mainContainer);
  return JsxRuntime.jsx(Page.make, {
              children: props.children,
              mainClassName: m,
              footer: Caml_option.some(JsxRuntime.jsx(BusinessFooter.make, {})),
              isBusinessContainer: true,
              theme: Themes.business
            });
}

var make = BusinessPage;

export {
  make ,
}
/* Page Not a pure module */
