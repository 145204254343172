// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__TextField__Decoder from "../TextField/Gql__TextField__Decoder.bs.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.email_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var email_icon;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    email_icon = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    email_icon = undefined;
  }
  var email = Belt_Option.getWithDefault(Belt_Option.flatMap(c.email, (function (c) {
              return c.value;
            })), "");
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.phone_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var phone_icon;
  if (value$1 !== undefined) {
    var name$1 = Belt_Option.getWithDefault(value$1.name, "");
    var description$1 = Belt_Option.getWithDefault(value$1.description, "");
    var url$1 = Belt_Option.getWithDefault(value$1.url, "");
    var width$1 = Belt_Option.getWithDefault(value$1.width, 0);
    var height$1 = Belt_Option.getWithDefault(value$1.height, 0);
    phone_icon = {
      name: name$1,
      description: description$1,
      url: url$1,
      width: width$1,
      height: height$1
    };
  } else {
    phone_icon = undefined;
  }
  var phone = Belt_Option.getWithDefault(Belt_Option.flatMap(c.phone, (function (c) {
              return c.value;
            })), "");
  var body_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_text, (function (c) {
              return c.value;
            })), "");
  var body_ctas = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_ctas, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var fields = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.fields, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "TextFieldContentType") {
            return Gql__TextField__Decoder.get(f.VAL.textField);
          }
          
        }));
  var submit_cta_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.submit_cta_text, (function (c) {
              return c.value;
            })), "");
  var smart_link_itemId = c.system.id;
  var smart_link_codename = c.system.codename;
  var smart_link = {
    itemId: smart_link_itemId,
    codename: smart_link_codename
  };
  return {
          title: title,
          email_icon: email_icon,
          email: email,
          phone_icon: phone_icon,
          phone: phone,
          body_text: body_text,
          body_ctas: body_ctas,
          fields: fields,
          submit_cta_text: submit_cta_text,
          smart_link: smart_link
        };
}

var TextField;

var CardIcon;

export {
  TextField ,
  CardIcon ,
  get ,
}
/* No side effect */
