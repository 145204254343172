// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  var codename = content.system.codename;
  var smartLink_itemId = content.system.id;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: codename
  };
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(content.title, (function (c) {
              return c.value;
            })), "");
  var text = Belt_Option.getWithDefault(Belt_Option.flatMap(content.text, (function (c) {
              return c.value;
            })), "");
  var backgroundColour = Belt_Option.getWithDefault(Belt_Option.flatMap(content.background_colour, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.banner_asset_desktop, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var bannerImageDesktop;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    bannerImageDesktop = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    bannerImageDesktop = undefined;
  }
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.banner_asset_tablet, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var bannerImageTablet;
  if (value$1 !== undefined) {
    var name$1 = Belt_Option.getWithDefault(value$1.name, "");
    var description$1 = Belt_Option.getWithDefault(value$1.description, "");
    var url$1 = Belt_Option.getWithDefault(value$1.url, "");
    var width$1 = Belt_Option.getWithDefault(value$1.width, 0);
    var height$1 = Belt_Option.getWithDefault(value$1.height, 0);
    bannerImageTablet = {
      name: name$1,
      description: description$1,
      url: url$1,
      width: width$1,
      height: height$1
    };
  } else {
    bannerImageTablet = undefined;
  }
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.banner_asset_mobile, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var bannerImageMobile;
  if (value$2 !== undefined) {
    var name$2 = Belt_Option.getWithDefault(value$2.name, "");
    var description$2 = Belt_Option.getWithDefault(value$2.description, "");
    var url$2 = Belt_Option.getWithDefault(value$2.url, "");
    var width$2 = Belt_Option.getWithDefault(value$2.width, 0);
    var height$2 = Belt_Option.getWithDefault(value$2.height, 0);
    bannerImageMobile = {
      name: name$2,
      description: description$2,
      url: url$2,
      width: width$2,
      height: height$2
    };
  } else {
    bannerImageMobile = undefined;
  }
  var address = Belt_Option.getWithDefault(Belt_Option.flatMap(content.address, (function (c) {
              return c.value;
            })), "");
  return {
          title: title,
          text: text,
          backgroundColour: backgroundColour,
          bannerImageDesktop: bannerImageDesktop,
          bannerImageTablet: bannerImageTablet,
          bannerImageMobile: bannerImageMobile,
          address: address,
          smartLink: smartLink
        };
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
