import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';
import { Sku } from 'models/sku.model';

/**
 * Request to fetch exchange information about a product.
 * @param uid The product unique identifier.
 * @returns Promise with exchange store response.
 */
const exchangeStore = (uid: string): Promise<Sku[]> => {
  return createRequest(`api/v3/sku/${uid}/exchange-store/`, {
    withAuth: true,
    withMagicUserAgent: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true })['skus'] as Sku[]);
};

export default exchangeStore;
