// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Video__Decoder from "../Video/Gql__Video__Decoder.bs.js";

function get(c) {
  if (typeof c !== "object") {
    return ;
  }
  var variant = c.NAME;
  if (variant !== "RichTextBlockContentType") {
    if (variant === "VideoContentType") {
      return {
              NAME: "Video",
              VAL: Gql__Video__Decoder.get(c.VAL.video)
            };
    } else {
      return ;
    }
  }
  var content = Belt_Option.getWithDefault(Belt_Option.flatMap(c.VAL.content, (function (c) {
              return c.value;
            })), "");
  return {
          NAME: "RichText",
          VAL: content
        };
}

var Video;

export {
  Video ,
  get ,
}
/* No side effect */
