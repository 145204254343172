// Generated by ReScript, PLEASE EDIT WITH CARE

import CmsImageTsx from "./CmsImage.tsx";

var make = CmsImageTsx;

export {
  make ,
}
/* make Not a pure module */
