import { gql } from '@apollo/client';

const CALL_TO_ACTION_LINK = gql`
  fragment CallToActionFields on CallToActionLinkD6a3d61 {
    _system_ {
      id
      codename
    }
    ctaText
    ctaUrl
    openInNewWindow {
      items {
        _system_ {
          codename
        }
      }
    }
    _ctaStyle {
      style {
        items {
          _system_ {
            codename
          }
        }
      }
    }
  }
`;

export default CALL_TO_ACTION_LINK;
