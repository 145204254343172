import logger from 'utils/logger';
import { initializeApollo } from 'api/graphql';
import GET_STORE_CATEGORY from '../graphql/modules/StoreCategory/query';
import { decodeQuery, EnrichedTextBlockType } from './modifyBlocks';
import { GetServerSidePropsContext } from 'next';

interface StoreCategoryType {
  top_content: EnrichedTextBlockType | undefined;
}

export const fetchStoreCategory = async (
  codename: string,
  context: GetServerSidePropsContext,
  language: string
): Promise<StoreCategoryType | undefined> => {
  let res;
  try {
    const client = initializeApollo({}, context);
    res = await client.query({
      query: GET_STORE_CATEGORY,
      variables: { codename, language },
    });
  } catch (error) {
    logger.error(
      {
        err: error,
        journey: 'Page Content',
      },
      'error: fetching store category'
    );
  }

  let data = undefined;

  try {
    if (res.data?.storeCategory?.pageTopContent) {
      data = {
        top_content: decodeQuery(res.data.storeCategory.pageTopContent),
      };
    }
  } catch (error) {
    logger.error(
      {
        err: error,
        journey: 'Page Content',
      },
      'error: decoding store category'
    );
  }

  return data;
};

export default fetchStoreCategory;
