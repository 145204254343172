// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__TextField__Decoder from "../TextField/Gql__TextField__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "TextFieldContentType") {
    return {
            NAME: "TextField",
            VAL: Gql__TextField__Decoder.get(content.VAL.textField)
          };
  }
  
}

var TextFieldDecoder;

export {
  TextFieldDecoder ,
  get ,
}
/* No side effect */
