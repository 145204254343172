// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment UniqueSellingPointsBlock on UniqueSellingPointsBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    tagline  {
      __typename
      value
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    background_image  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    background_image_position  {
      __typename
      value  {
        __typename
        codename
      }
    }
    unique_selling_points  {
      __typename
      value  {
        __typename
        ...on UniqueSellingPointContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          title  {
            __typename
            value
          }
          icon_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
          fontawesome_icon_class  {
            __typename
            value
          }
        }
      }
    }
  }
  ${frag_0}
`)(Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.tagline;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.title;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.description;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.background_image;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$9) : undefined
    };
  }
  var value$10 = value.background_image_position;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$11) : undefined
    };
  }
  var value$12 = value.unique_selling_points;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "UniqueSellingPointContentType") {
                  var value$1 = value.system;
                  var value$2 = value.title;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.icon_asset;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$5) : undefined
                    };
                  }
                  var value$6 = value.fontawesome_icon_class;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  tmp = {
                    NAME: "UniqueSellingPointContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      title: tmp$1,
                      icon_asset: tmp$2,
                      fontawesome_icon_class: tmp$3
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$13) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          tagline: tmp,
          title: tmp$1,
          description: tmp$2,
          background_image: tmp$3,
          background_image_position: tmp$4,
          unique_selling_points: tmp$5
        };
}

function serialize(value) {
  var value$1 = value.unique_selling_points;
  var unique_selling_points;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.fontawesome_icon_class;
              var fontawesome_icon_class;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                fontawesome_icon_class = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                fontawesome_icon_class = null;
              }
              var value$6 = value$1.icon_asset;
              var icon_asset;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$7) : null;
                var value$9 = value$6.__typename;
                icon_asset = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                icon_asset = null;
              }
              var value$10 = value$1.title;
              var title;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                title = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                title = null;
              }
              var value$14 = value$1.system;
              var value$15 = value$14.codename;
              var value$16 = value$14.id;
              var value$17 = value$14.__typename;
              var system = {
                __typename: value$17,
                id: value$16,
                codename: value$15
              };
              return {
                      __typename: "UniqueSellingPointContentType",
                      system: system,
                      title: title,
                      icon_asset: icon_asset,
                      fontawesome_icon_class: fontawesome_icon_class
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    unique_selling_points = {
      __typename: value$4,
      value: value$3
    };
  } else {
    unique_selling_points = null;
  }
  var value$5 = value.background_image_position;
  var background_image_position;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    background_image_position = {
      __typename: value$8,
      value: value$7
    };
  } else {
    background_image_position = null;
  }
  var value$9 = value.background_image;
  var background_image;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    background_image = {
      __typename: value$12,
      value: value$11
    };
  } else {
    background_image = null;
  }
  var value$13 = value.description;
  var description;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    description = {
      __typename: value$16,
      value: value$15
    };
  } else {
    description = null;
  }
  var value$17 = value.title;
  var title;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    title = {
      __typename: value$20,
      value: value$19
    };
  } else {
    title = null;
  }
  var value$21 = value.tagline;
  var tagline;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    tagline = {
      __typename: value$24,
      value: value$23
    };
  } else {
    tagline = null;
  }
  var value$25 = value.system;
  var value$26 = value$25.codename;
  var value$27 = value$25.id;
  var value$28 = value$25.__typename;
  var system = {
    __typename: value$28,
    id: value$27,
    codename: value$26
  };
  var value$29 = value.__typename;
  return {
          __typename: value$29,
          system: system,
          tagline: tagline,
          title: title,
          description: description,
          background_image: background_image,
          background_image_position: background_image_position,
          unique_selling_points: unique_selling_points
        };
}

function verifyArgsAndParse(_UniqueSellingPointsBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var UniqueSellingPointsBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var Icon;

export {
  $$Image ,
  Icon ,
  UniqueSellingPointsBlock ,
}
/* query Not a pure module */
