// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__Video__Fragment from "../Video/Gql__Video__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment VideoTextPromoBlock on VideoTextPromoBoxContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    tagline  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    text_colour  {
      __typename
      value  {
        __typename
        codename
      }
    }
    featured_video  {
      __typename
      value  {
        __typename
        ...on VideoContentType   {
          ...Video
        }
      }
    }
    featured_video_mobile  {
      __typename
      value  {
        __typename
        ...on VideoContentType   {
          ...Video
        }
      }
    }
    loop_featured_video  {
      __typename
      value  {
        __typename
        codename
      }
    }
    feature_video_poster  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    background_colour  {
      __typename
      value
    }
    background_image  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__Image__Fragment.$$Image.query, Gql__Video__Fragment.Video.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.tagline;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.description;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.text_colour;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$9) : undefined
    };
  }
  var value$10 = value.featured_video;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "VideoContentType" ? ({
                      NAME: "VideoContentType",
                      VAL: {
                        video: Gql__Video__Fragment.Video.verifyArgsAndParse("Video", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$11) : undefined
    };
  }
  var value$12 = value.featured_video_mobile;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "VideoContentType" ? ({
                      NAME: "VideoContentType",
                      VAL: {
                        video: Gql__Video__Fragment.Video.verifyArgsAndParse("Video", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$13) : undefined
    };
  }
  var value$14 = value.loop_featured_video;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$15) : undefined
    };
  }
  var value$16 = value.feature_video_poster;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$17) : undefined
    };
  }
  var value$18 = value.background_colour;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.background_image;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$21) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          tagline: tmp$1,
          description: tmp$2,
          text_colour: tmp$3,
          featured_video: tmp$4,
          featured_video_mobile: tmp$5,
          loop_featured_video: tmp$6,
          feature_video_poster: tmp$7,
          background_colour: tmp$8,
          background_image: tmp$9
        };
}

function serialize(value) {
  var value$1 = value.background_image;
  var background_image;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    background_image = {
      __typename: value$4,
      value: value$3
    };
  } else {
    background_image = null;
  }
  var value$5 = value.background_colour;
  var background_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    background_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    background_colour = null;
  }
  var value$9 = value.feature_video_poster;
  var feature_video_poster;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    feature_video_poster = {
      __typename: value$12,
      value: value$11
    };
  } else {
    feature_video_poster = null;
  }
  var value$13 = value.loop_featured_video;
  var loop_featured_video;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$14) : null;
    var value$16 = value$13.__typename;
    loop_featured_video = {
      __typename: value$16,
      value: value$15
    };
  } else {
    loop_featured_video = null;
  }
  var value$17 = value.featured_video_mobile;
  var featured_video_mobile;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "VideoContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Video__Fragment.Video.serialize(value.VAL.video)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$18) : null;
    var value$20 = value$17.__typename;
    featured_video_mobile = {
      __typename: value$20,
      value: value$19
    };
  } else {
    featured_video_mobile = null;
  }
  var value$21 = value.featured_video;
  var featured_video;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "VideoContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Video__Fragment.Video.serialize(value.VAL.video)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$22) : null;
    var value$24 = value$21.__typename;
    featured_video = {
      __typename: value$24,
      value: value$23
    };
  } else {
    featured_video = null;
  }
  var value$25 = value.text_colour;
  var text_colour;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$26) : null;
    var value$28 = value$25.__typename;
    text_colour = {
      __typename: value$28,
      value: value$27
    };
  } else {
    text_colour = null;
  }
  var value$29 = value.description;
  var description;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? value$30 : null;
    var value$32 = value$29.__typename;
    description = {
      __typename: value$32,
      value: value$31
    };
  } else {
    description = null;
  }
  var value$33 = value.tagline;
  var tagline;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    tagline = {
      __typename: value$36,
      value: value$35
    };
  } else {
    tagline = null;
  }
  var value$37 = value.title;
  var title;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? value$38 : null;
    var value$40 = value$37.__typename;
    title = {
      __typename: value$40,
      value: value$39
    };
  } else {
    title = null;
  }
  var value$41 = value.system;
  var value$42 = value$41.codename;
  var value$43 = value$41.id;
  var value$44 = value$41.__typename;
  var system = {
    __typename: value$44,
    id: value$43,
    codename: value$42
  };
  var value$45 = value.__typename;
  return {
          __typename: value$45,
          system: system,
          title: title,
          tagline: tagline,
          description: description,
          text_colour: text_colour,
          featured_video: featured_video,
          featured_video_mobile: featured_video_mobile,
          loop_featured_video: loop_featured_video,
          feature_video_poster: feature_video_poster,
          background_colour: background_colour,
          background_image: background_image
        };
}

function verifyArgsAndParse(_VideoTextPromoBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var VideoTextPromoBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var Video;

export {
  $$Image ,
  Video ,
  VideoTextPromoBlock ,
}
/* query Not a pure module */
