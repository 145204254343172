// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "DictionaryItemContentType") {
    return ;
  }
  var d = content.VAL;
  var id = d.system.id;
  var codename = d.system.codename;
  var value = Belt_Option.flatMap(d.value, (function (v) {
          return v.value;
        }));
  var smartLink = {
    itemId: id,
    codename: codename
  };
  return Belt_Option.map(value, (function (value) {
                return {
                        codename: codename,
                        value: value,
                        smartLink: smartLink
                      };
              }));
}

export {
  get ,
}
/* No side effect */
