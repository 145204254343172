// Generated by ReScript, PLEASE EDIT WITH CARE


var emptySmartLink = {
  itemId: "",
  codename: ""
};

export {
  emptySmartLink ,
}
/* No side effect */
