// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "GiftLandingPageFaqItemContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var bodyText = Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_text, (function (c) {
              return c.value;
            })), "");
  var includeHowToUse = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.include_how_to_use, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), false, (function (v) {
          if (v === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  var includePdfDownload = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.include_pdf_download, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), false, (function (v) {
          if (v === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  var includeCardMerchantTerms = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.include_card_merchant_terms, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), false, (function (v) {
          if (v === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          bodyText: bodyText,
          includeHowToUse: includeHowToUse,
          includePdfDownload: includePdfDownload,
          includeCardMerchantTerms: includeCardMerchantTerms,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
