import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/css';

import { DEFAULT_PAGE, usePaginatedDrawer } from 'contexts/PaginatedDrawerContext';
import { Color } from 'css/theme';

const styles = {
  back: {
    root: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0 16px;
    `,
    icon: css`
      font-size: 24px;
      margin-top: 2px;
    `,
    button: css`
      padding-left: 8px;
    `,
  },
  list: css`
    display: flex;
    flex-direction: column;
  `,
  link: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 16px;
    border-bottom: 1px solid ${Color.Gray100};
    cursor: pointer;
  `,
};

export type OptionLinks = {
  text: string;
  url: string;
};

type Props = {
  links: OptionLinks[];
};

const MobileNavPage: FC<Props> = ({ links }) => {
  const { setPage } = usePaginatedDrawer();

  return (
    <div>
      <div className={styles.back.root}>
        <FontAwesomeIcon className={styles.back.icon} icon={['far', 'angle-left']} />
        <button className={styles.back.button} onClick={() => setPage(DEFAULT_PAGE)}>
          Back
        </button>
      </div>
      <ul className={styles.list}>
        {links.map((el, idx) => (
          <li key={idx}>
            <a className={styles.link} href={el.url}>
              {el.text}
              <FontAwesomeIcon icon={['far', 'arrow-right']} color={Color.Purple400} size="sm" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileNavPage;
