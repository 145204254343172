// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(content.url, (function (c) {
                    return c.value;
                  })), "");
}

export {
  get ,
}
/* No side effect */
