// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Util from "../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../css/Theme.bs.js";
import * as React from "react";
import * as Config from "../config/Config.bs.js";
import * as Segment from "../bindings/Segment.bs.js";
import * as CmsImage from "../components/CmsImage.bs.js";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Belt_Result from "@rescript/std/lib/es6/belt_Result.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as FormHelpers from "../utils/FormHelpers.bs.js";
import * as IndexStyles from "../components/pages/index/IndexStyles.bs.js";
import * as Router from "next/router";
import * as LayoutDataContext from "../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CookieSettingButton from "../components/CookieSettingButton.bs.js";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Footer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../components/pages/index/KontentContextProvider.bs.js";
import * as NewsletterSubscription from "../components/library/NewsletterSubscription.bs.js";
import * as TextField$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TextField/TextField.bs.js";
import * as Footer__Link$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Link.bs.js";
import * as ThemeContext$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext.bs.js";
import * as Footer__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Theme.bs.js";
import * as AppStoreBadges$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/AppStoreBadges/AppStoreBadges.bs.js";
import * as Footer__Column$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Column.bs.js";
import * as Footer__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Heading.bs.js";
import * as Gql__DictionaryItems__Decoder from "../api/graphql/DictionaryItems/Gql__DictionaryItems__Decoder.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as Footer__LinkList$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__LinkList.bs.js";
import * as Footer__Copyright$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Copyright.bs.js";
import * as Footer__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Responsive.bs.js";
import * as Footer__SocialIcon$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__SocialIcon.bs.js";
import * as RegionIndicatorFlag$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorFlag.bs.js";
import PrezzeeCampaignActivateButtonTsx from "./PrezzeeCampaignActivateButton.tsx";
import * as AppStoreBadge__Single$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/AppStoreBadges/AppStoreBadge__Single.bs.js";
import * as RegionListLineContent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionList/RegionListLineContent.bs.js";
import * as RegionIndicatorTextual$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorTextual.bs.js";
import * as RegionIndicatorCountryDisplay$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorCountryDisplay.bs.js";

var make = PrezzeeCampaignActivateButtonTsx;

var PrezzeeCampaignActivateButton = {
  make: make
};

var footer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

var subscribeColumn = Curry._1(Css.style, {
      hd: Css.media(Footer__Responsive$PrezzeeRibbon.mobile, {
            hd: Css.gridRowStart(1),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.media(Footer__Responsive$PrezzeeRibbon.tablet, {
              hd: Css.important(Css.unsafe("gridColumn", "span 3")),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var successText = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(18)),
      tl: {
        hd: Css.lineHeight(Css.px(24)),
        tl: {
          hd: Css.backgroundColor(Css.rgba(255, 255, 255, {
                    NAME: "num",
                    VAL: 0.1
                  })),
          tl: {
            hd: Css.borderRadius(Css.px(100)),
            tl: {
              hd: Css.width(Css.px(250)),
              tl: {
                hd: Css.height(Css.px(38)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var emailField = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(10)),
      tl: {
        hd: Css.flexGrow(1),
        tl: {
          hd: Css.flexShrink(1),
          tl: {
            hd: Css.marginBottom(Css.px(25)),
            tl: /* [] */0
          }
        }
      }
    });

var regionChange = Curry._1(Css.style, {
      hd: Css.selector("." + RegionIndicatorFlag$PrezzeeRibbon.Styles.flagBlock, {
            hd: Css.color(Theme.Color.blue400),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var Styles = {
  footer: footer,
  subscribeColumn: subscribeColumn,
  successText: successText,
  emailField: emailField,
  regionChange: regionChange
};

function linkListIcon(lli) {
  if (lli.TAG !== /* FontAwesomeIcon */0) {
    return null;
  }
  var fa = lli._0;
  return JsxRuntime.jsx(Footer__SocialIcon$PrezzeeRibbon.make, {
              children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                    icon: [
                      fa.style,
                      fa.icon
                    ]
                  })
            });
}

function linkItem(ll) {
  var router = Router.useRouter();
  var icon = Belt_Option.getWithDefault(Belt_Option.map(ll.icon, linkListIcon), null);
  var match = ll.smartLink;
  var codename = match.codename;
  var itemId = match.itemId;
  var href = ll.url;
  var target = ll.open_in_new_window ? "_blank" : "_self";
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Footer__Link$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsxs("a", {
                          children: [
                            icon,
                            ll.link_text
                          ],
                          className: "cursor-pointer",
                          onClick: (function (param) {
                              if (Js_array.includes(codename, [
                                      "footer_link___faqs",
                                      "faqs_8e9b029",
                                      "footer_link___faqs_991e620",
                                      "footer_link___faqs_1d1815d"
                                    ])) {
                                Js_promise.$$catch((function (param) {
                                        return Promise.resolve((window.open(href, target), undefined));
                                      }), Js_promise.then_((function (param) {
                                            return Promise.resolve((window.open(href, target), undefined));
                                          }), Segment.customElementInteractionAsync({
                                              element: "bottomnav_faqs",
                                              path: router.asPath
                                            })));
                              } else if (Js_array.includes(codename, ["footer_link___contact_us"])) {
                                Js_promise.$$catch((function (param) {
                                        return Promise.resolve((window.open(href, target), undefined));
                                      }), Js_promise.then_((function (param) {
                                            return Promise.resolve((window.open(href, target), undefined));
                                          }), Segment.customElementInteractionAsync({
                                              element: "bottomnav_contact_us",
                                              path: router.asPath
                                            })));
                              } else {
                                window.open(href, target);
                              }
                            })
                        })
                  }),
              itemId: itemId,
              elementCodename: codename
            }, itemId);
}

function mailtoItem(ll) {
  var match = ll.smartLink;
  var itemId = match.itemId;
  var href = "mailto:" + ll.email_address;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Footer__Link$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsx("a", {
                          children: ll.link_text,
                          href: href
                        })
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function cookieSettingItem(ll) {
  var match = ll.smartLink;
  var itemId = match.itemId;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Footer__Link$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsx(CookieSettingButton.make, {
                          text: ll.text
                        })
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function linkList(ll) {
  var variant = ll.NAME;
  if (variant === "MailtoLinkContentType") {
    return mailtoItem(ll.VAL);
  } else if (variant === "OnetrustCookieSettingButtonContentType") {
    return cookieSettingItem(ll.VAL);
  } else {
    return linkItem(ll.VAL);
  }
}

function columns(col) {
  var match = col.smartLink;
  var itemId = match.itemId;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsxs(Footer__Column$PrezzeeRibbon.make, {
                    children: [
                      JsxRuntime.jsx(Kontent$PrezzeeRibbon.Context.Provider.make, {
                            children: JsxRuntime.jsx(Footer__Heading$PrezzeeRibbon.make, {
                                  children: col.title
                                }),
                            elementCodename: "title"
                          }),
                      JsxRuntime.jsx(Footer__LinkList$PrezzeeRibbon.make, {
                            children: Belt_Array.map(col.links, linkList)
                          })
                    ]
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function appStores(stores) {
  var badges = function (s) {
    var match = s.smartLink;
    var match$1 = s.app_store_image;
    var description = match$1.description;
    return JsxRuntime.jsx(AppStoreBadge__Single$PrezzeeRibbon.make, {
                children: JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("a", {
                                  children: JsxRuntime.jsx(CmsImage.make, {
                                        src: match$1.url,
                                        alt: description !== "" ? description : s.app_store_name,
                                        width: match$1.width,
                                        height: match$1.height
                                      }),
                                  href: s.app_store_download_link
                                })
                          }),
                      itemId: match.itemId,
                      elementCodename: match.codename
                    })
              }, s.app_store_name);
  };
  return JsxRuntime.jsx(AppStoreBadges$PrezzeeRibbon.make, {
              children: Belt_Array.map(stores, badges)
            });
}

function removeTrailingSlash(url) {
  var slashIndex = url.lastIndexOf("/");
  if (slashIndex === (url.length - 1 | 0)) {
    return url.substring(0, slashIndex);
  } else {
    return url;
  }
}

var isStg = process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL.includes("stg");

function toStgUrl(url) {
  return url.replace("https://www.prezzee", "https://stg.prezzee");
}

function Footer$NewsletterForm(props) {
  var form = props.form;
  var state = LayoutDataContext.useContext(undefined);
  var footer = Belt_Option.flatMap(state, (function (s) {
          return s.footer;
        }));
  var subscribe_helper_text = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.subscribe_helper_text;
            })), "");
  var subscribe_cta = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.subscribe_cta;
            })), "");
  var match = Belt_Option.mapWithDefault(footer, [
        /* Regular */0,
        /* Primary */5,
        /* Contained */0
      ], (function (f) {
          return f.subscribe_cta_style;
        }));
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(TextField$PrezzeeRibbon.make, {
                            className: emailField,
                            label: subscribe_helper_text,
                            name: "email_address",
                            theme: /* Light */0,
                            type_: "email",
                            error: Belt_Option.isSome(FormHelpers.getFieldError(form.errors, "email_address")),
                            helperText: FormHelpers.getFieldError(form.errors, "email_address"),
                            inputRef: Caml_option.some(Curry._1(form.register, NewsletterSubscription.newsletterInput))
                          }),
                      JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                            children: JsxRuntime.jsx("button", {
                                  children: Util.ReactStuff.s(subscribe_cta),
                                  disabled: form.formState.isSubmitted,
                                  type: "submit"
                                }),
                            fill: match[2],
                            colour: match[1],
                            variant: match[0]
                          })
                    ],
                    className: "flex flex-wrap"
                  }),
              onSubmit: Curry._2(form.handleSubmit, props.onSubmit, FormHelpers.onError)
            });
}

var NewsletterForm = {
  make: Footer$NewsletterForm
};

function Footer(props) {
  var data = props.data;
  var state = LayoutDataContext.useContext(undefined);
  var footer$1 = data !== undefined ? data : Belt_Option.flatMap(state, (function (s) {
            return s.footer;
          }));
  var footerItemId = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
              return f.smartLink.itemId;
            })), "");
  var footerCodename = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
              return f.smartLink.codename;
            })), "");
  var subscribe_title = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
              return f.subscribe_title;
            })), "");
  var region_selector_text = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
              return f.region_selector_text;
            })), "CHANGE");
  var currentYear = (new Date().getFullYear() | 0).toString();
  var disclaimer_level_1 = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
                return f.disclaimer_level_1;
              })), "").replace("{0}", currentYear);
  var disclaimer_level_2 = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
                return f.disclaimer_level_2;
              })), "").replace("{0}", currentYear);
  var app_store_title = Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
              return Util.ReactStuff.s(f.app_store_title);
            })), null);
  var app_stores = appStores(Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
                  return f.app_stores_container;
                })), []));
  var footerTheme = Belt_Option.mapWithDefault(footer$1, Footer__Theme$PrezzeeRibbon.t(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (x) {
          return x.theme;
        }));
  var currentTheme = ThemeContext$PrezzeeRibbon.useTheme(undefined);
  var init = currentTheme.components;
  var newTheme_primary = currentTheme.primary;
  var newTheme_secondary = currentTheme.secondary;
  var newTheme_tertiary = currentTheme.tertiary;
  var newTheme_displayFont = currentTheme.displayFont;
  var newTheme_headingFont = currentTheme.headingFont;
  var newTheme_bodyFont = currentTheme.bodyFont;
  var newTheme_borderRadius = currentTheme.borderRadius;
  var newTheme_components = {
    fullWidthCarousel: init.fullWidthCarousel,
    iconCard: init.iconCard,
    contentCard: init.contentCard,
    regionStrip: init.regionStrip,
    regionPicker: init.regionPicker,
    tag: init.tag,
    tagline: init.tagline,
    uniqueSellingPoints: init.uniqueSellingPoints,
    videoTextPromoBlock: init.videoTextPromoBlock,
    footer: footerTheme,
    textField: init.textField,
    emailSignUp: init.emailSignUp,
    smallHeroTile: init.smallHeroTile,
    largeHeroTile: init.largeHeroTile,
    categoryTile: init.categoryTile,
    downloadAppsBanner: init.downloadAppsBanner,
    promoTiles: init.promoTiles,
    customerCareBanner: init.customerCareBanner,
    customerCareCallUs: init.customerCareCallUs
  };
  var newTheme = {
    primary: newTheme_primary,
    secondary: newTheme_secondary,
    tertiary: newTheme_tertiary,
    displayFont: newTheme_displayFont,
    headingFont: newTheme_headingFont,
    bodyFont: newTheme_bodyFont,
    borderRadius: newTheme_borderRadius,
    components: newTheme_components
  };
  var regions = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.regions;
            })), []);
  var domainPostfix = Belt_Result.mapWithDefault(Util.EnvUtil.getCountry(undefined), "", (function (c) {
          return Util.EnvUtil.getDomainPostfix(c);
        }));
  var regionIndex = Belt_Option.getWithDefault(Belt_Array.getIndexBy(regions, (function (region) {
              return removeTrailingSlash(region.url).endsWith(domainPostfix);
            })), 0);
  var match = React.useState(function () {
        return regionIndex;
      });
  var setSelectedRegion = match[1];
  var selectedRegion = match[0];
  var onChange = function (selected) {
    Curry._1(setSelectedRegion, (function (param) {
            return selected;
          }));
    var region = Belt_Array.get(regions, selected);
    var url = Belt_Option.getWithDefault(Belt_Option.map(region, (function (r) {
                if (isStg) {
                  return r.url.replace("https://www.prezzee", "https://stg.prezzee");
                } else {
                  return r.url;
                }
              })), "");
    var changeLocation = (function cl(url) { window.location.assign(url) });
    return changeLocation(url);
  };
  var newsletterSuccess = JsxRuntime.jsx("p", {
        children: Util.ReactStuff.s("Woohoo! You're on the list."),
        className: successText
      });
  var regionIndicator = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (s) {
                  return s.dictionaryItems;
                })), (function (s) {
              return s.app_yourcurrentshoppingregion;
            })), {
        value: "You're currently shopping in {0}",
        smartLink: Gql__DictionaryItems__Decoder.emptyDictionaryEntry.smartLink
      }, (function (param) {
          var smartLink = param.smartLink;
          var value = param.value;
          if (value === "") {
            return {
                    value: "You're currently shopping in {0}",
                    smartLink: smartLink
                  };
          } else {
            return {
                    value: value,
                    smartLink: smartLink
                  };
          }
        }));
  var regionIndicatorText = regionIndicator.value;
  var match$1 = regionIndicator.smartLink;
  var regionModalItemId = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.smartLink.itemId;
            })), "");
  var regionModalCodename = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.smartLink.codename;
            })), "");
  var regionPickerTitle = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.title;
            })), "Change location");
  var regionPickerSubtitle = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.subtitle;
            })), "Want to send gift cards to family and friends in another country? Change your region below.");
  var regionPickerCtaText = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.ctaText;
            })), "Save location");
  var regionPickerStrings = {
    heading: regionPickerTitle,
    heading_subtext: regionPickerSubtitle,
    cta_button: regionPickerCtaText
  };
  var regionListLines = Belt_Array.map(regions, (function (r) {
          var match = r.regionListLine;
          var smartLink = match.smartLink;
          var flag = match.flag;
          var currency = match.currency;
          var name = match.name;
          var el = JsxRuntime.jsx(KontentContextProvider.make, {
                children: JsxRuntime.jsx(RegionListLineContent$PrezzeeRibbon.make, {
                      flag: flag,
                      name: name,
                      currency: currency
                    }),
                itemId: smartLink.itemId,
                elementCodename: smartLink.codename
              });
          return {
                  name: name,
                  currency: currency,
                  flag: flag,
                  el: el
                };
        }));
  return JsxRuntime.jsxs(ThemeContext$PrezzeeRibbon.Provider.make, {
              value: newTheme,
              children: [
                JsxRuntime.jsxs(Footer$PrezzeeRibbon.make, {
                      children: [
                        Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.map(footer$1, (function (f) {
                                        return f.link_columns;
                                      })), []), columns),
                        JsxRuntime.jsxs(Footer__Column$PrezzeeRibbon.make, {
                              children: [
                                JsxRuntime.jsxs(KontentContextProvider.make, {
                                      children: [
                                        JsxRuntime.jsx(Footer__Heading$PrezzeeRibbon.make, {
                                              children: Util.ReactStuff.s(subscribe_title)
                                            }),
                                        JsxRuntime.jsx(NewsletterSubscription.make, {
                                              formComponent: Footer$NewsletterForm,
                                              successElement: newsletterSuccess
                                            })
                                      ],
                                      itemId: footerItemId,
                                      elementCodename: footerCodename
                                    }),
                                JsxRuntime.jsx(KontentContextProvider.make, {
                                      children: JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(RegionIndicatorTextual$PrezzeeRibbon.make, {
                                                  regions: regionListLines,
                                                  strings: regionPickerStrings,
                                                  selected: selectedRegion,
                                                  children: JsxRuntime.jsx(KontentContextProvider.make, {
                                                        children: JsxRuntime.jsx(RegionIndicatorCountryDisplay$PrezzeeRibbon.make, {
                                                              text: regionIndicatorText,
                                                              regions: regionListLines,
                                                              selected: selectedRegion
                                                            }),
                                                        itemId: match$1.itemId,
                                                        elementCodename: match$1.codename
                                                      }),
                                                  onChange: onChange,
                                                  text: region_selector_text
                                                }),
                                            className: regionChange
                                          }),
                                      itemId: regionModalItemId,
                                      elementCodename: regionModalCodename
                                    }),
                                JsxRuntime.jsxs(KontentContextProvider.make, {
                                      children: [
                                        JsxRuntime.jsx(Footer__Heading$PrezzeeRibbon.make, {
                                              children: app_store_title
                                            }),
                                        app_stores
                                      ],
                                      itemId: footerItemId,
                                      elementCodename: footerCodename
                                    }),
                                JsxRuntime.jsx(make, {
                                      displayWhen: "tabletOrDesktop"
                                    })
                              ],
                              colWidth: 2,
                              className: subscribeColumn
                            }),
                        JsxRuntime.jsx(make, {
                              displayWhen: "mobile"
                            }),
                        JsxRuntime.jsxs(Footer__Copyright$PrezzeeRibbon.make, {
                              children: [
                                disclaimer_level_1 !== "" ? JsxRuntime.jsx("div", {
                                        children: disclaimer_level_1
                                      }) : null,
                                disclaimer_level_2 !== "" ? JsxRuntime.jsx("div", {
                                        children: disclaimer_level_2
                                      }) : null
                              ]
                            })
                      ],
                      className: Cn.make([
                            IndexStyles.section,
                            footer
                          ])
                    }),
                JsxRuntime.jsx("div", {
                      children: Util.ReactStuff.s(Config.buildVersion),
                      className: "hidden"
                    })
              ]
            });
}

var Kontent;

var Footer$1;

var RegionIndicatorTextual;

var RegionIndicatorCountryDisplay;

var RegionPicker;

var AppStoreBadges;

var FooterContainer;

var ThemeContext;

var make$1 = Footer;

export {
  PrezzeeCampaignActivateButton ,
  Kontent ,
  Footer$1 as Footer,
  RegionIndicatorTextual ,
  RegionIndicatorCountryDisplay ,
  RegionPicker ,
  AppStoreBadges ,
  FooterContainer ,
  ThemeContext ,
  Styles ,
  linkListIcon ,
  linkItem ,
  mailtoItem ,
  cookieSettingItem ,
  linkList ,
  columns ,
  appStores ,
  removeTrailingSlash ,
  isStg ,
  toStgUrl ,
  NewsletterForm ,
  make$1 as make,
}
/* make Not a pure module */
