// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function toRequestPayload(d) {
  return Json_encode.object_({
              hd: [
                "first_name",
                d.firstName
              ],
              tl: {
                hd: [
                  "last_name",
                  d.lastName
                ],
                tl: {
                  hd: [
                    "email",
                    d.email
                  ],
                  tl: {
                    hd: [
                      "password",
                      d.password
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Encode = {
  toRequestPayload: toRequestPayload
};

export {
  Encode ,
}
/* No side effect */
