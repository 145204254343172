// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../bindings/Pino.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Regex from "../../utils/Regex.bs.js";
import * as React from "react";
import * as Segment from "../../bindings/Segment.bs.js";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as ReactHookForm from "../../bindings/ReactHookForm.bs.js";
import * as PrezzeePublicClient from "../../api/PrezzeePublicClient.bs.js";

var newsletterInput = {
  required: {
    value: true,
    message: "Your email is required"
  },
  pattern: {
    value: Regex.emailRegex,
    message: "Please enter a valid email address"
  }
};

function NewsletterSubscription(props) {
  var store = props.store;
  var store$1 = store !== undefined ? store : "consumer";
  var newsletterInputFormOpt = {
    mode: "onTouched"
  };
  var match = React.useState(function () {
        return false;
      });
  var setIsSubscribed = match[1];
  var form = ReactHookForm.useForm(newsletterInputFormOpt, undefined);
  var setError = form.setError;
  var reset = form.reset;
  var subscribeWithApi = function (data) {
    return Js_promise.then_((function (result) {
                  if (result.TAG === /* Ok */0) {
                    Segment.newsletterSubscribed(data.email_address);
                    return Promise.resolve(Curry._1(setIsSubscribed, (function (param) {
                                      return true;
                                    })));
                  }
                  Pino.logger.error({
                        err: result._0,
                        type: "Newsletter Subscription",
                        source: "Subscriptions API"
                      }, "error: Could not opt in to EDM");
                  return Promise.resolve(undefined);
                }), PrezzeePublicClient.edmOptIn(data.email_address));
  };
  var subscribeWithBff = function (data) {
    return Js_promise.then_((function (result) {
                  if (result.TAG === /* Ok */0) {
                    return Promise.resolve(Curry._1(setIsSubscribed, (function (param) {
                                      return true;
                                    })));
                  }
                  Curry._1(reset, undefined);
                  Curry._2(setError, "email_address", {
                        type: "server",
                        message: "Something went wrong. Please try again."
                      });
                  Curry._1(setIsSubscribed, (function (param) {
                          return false;
                        }));
                  Pino.logger.error({
                        err: result._0,
                        type: "Newsletter Subscription",
                        source: "Subscriptions BFF"
                      }, "error: Could not opt in to EDM");
                  return Promise.resolve(undefined);
                }), PrezzeePublicClient.BFFNewsletter.subscribe(data.email_address, process.env.NEXT_PUBLIC_APP_COUNTRY_CODE, true, store$1));
  };
  var onSubmit = function (data) {
    var bffUrlOpt = process.env.NEXT_PUBLIC_BFF_ENDPOINT;
    var bffUrl = Belt_Option.getWithDefault((bffUrlOpt == null) ? undefined : Caml_option.some(bffUrlOpt), "");
    if (bffUrl === "") {
      Pino.logger.error({
            source: "Newsletter subscription"
          }, "BFF URL not defined");
      subscribeWithApi(data);
      return ;
    }
    Js_promise.then_((function (featureBffNewsletter) {
            if (featureBffNewsletter) {
              return Promise.resolve(subscribeWithBff(data));
            } else {
              return Promise.resolve(subscribeWithApi(data));
            }
          }), PrezzeePublicClient.BFFNewsletterConfig.getConfig(undefined));
  };
  if (match[0]) {
    return props.successElement;
  } else {
    return Curry._1(props.formComponent, {
                form: form,
                onSubmit: onSubmit
              });
  }
}

var make = NewsletterSubscription;

export {
  newsletterInput ,
  make ,
}
/* Pino Not a pure module */
