// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CategoryTiles__Decoder__Button from "./Gql__CategoryTiles__Decoder__Button.bs.js";
import * as Gql__CategoryTiles__Decoder__CategoryTile from "./Gql__CategoryTiles__Decoder__CategoryTile.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var tiles = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tiles, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__CategoryTiles__Decoder__CategoryTile.get);
  var button = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.button, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__CategoryTiles__Decoder__Button.get), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          tiles: tiles,
          button: button,
          smartLink: smartLink
        };
}

var CategoryTile;

var Button;

export {
  CategoryTile ,
  Button ,
  get ,
}
/* No side effect */
