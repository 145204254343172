// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PaginatedDrawerContextTsx from "../contexts/PaginatedDrawerContext.tsx";

function useContext(prim) {
  return PaginatedDrawerContextTsx.usePaginatedDrawer();
}

var defaultPage = PaginatedDrawerContextTsx.DEFAULT_PAGE;

var make = PaginatedDrawerContextTsx.PaginatedDrawerProvider;

var PaginatedDrawerProvider = {
  make: make
};

export {
  useContext ,
  defaultPage ,
  PaginatedDrawerProvider ,
}
/* defaultPage Not a pure module */
