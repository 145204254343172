// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function error(json) {
  return {
          detail: Json_decode.field("detail", Json_decode.string, json)
        };
}

function safeDecodeError(json) {
  var e;
  try {
    e = error(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* JsonParsingError */1,
                _0: "Could not decode error from the server."
              }
            };
    }
    throw exn;
  }
  return {
          TAG: /* Error */1,
          _0: {
            TAG: /* ServerError */2,
            _0: {
              TAG: /* ServerValidationError */0,
              _0: e.detail
            }
          }
        };
}

var Decode = {
  error: error,
  safeDecodeError: safeDecodeError
};

export {
  Decode ,
}
/* No side effect */
