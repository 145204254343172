import 'swiper/css';
import 'swiper/css/navigation';
import 'react-datepicker/dist/react-datepicker.css';
import 'css/index.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-phone-input-2/lib/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/informer/dist/style.css';
import 'intersection-observer';
import 'lazysizes';
import 'utils/fontAwesome';

import '@module-federation/nextjs-mf/src/include-defaults';

import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import theme from '../theme';

import KontentSmartLink from '@kentico/kontent-smart-link';
import ThemeProvider from '@prezzee/ribbon-ui/lib/ThemeProvider';

import { getCmsData } from 'api/graphql/CmsData.bs';
import AuthContext from 'auth/user/AuthContext.bs';
import { cookieName } from 'auth/user/AuthUtils.bs';
import * as Segment from 'bindings/segment';
import * as SegmentRe from 'bindings/Segment.bs';
import { make as Pages } from 'common/Pages.bs';
import * as Util from 'common/util.bs';
import * as Config from 'config/Config.bs';
import { LayoutDataContext } from 'contexts/LayoutDataContext';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { ScreenClassProvider } from '@prezzee/grid';
import { AuthProvider, AuthContext as AuthContextTS } from 'contexts/AuthContext';
import { SegmentCookieIdentifier } from '../utils/segment';
import { Business as businessUtils } from 'common/util.bs';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from 'next/app';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { QueryClientProvider } from '@tanstack/react-query';
import client from 'api/client';
import webConfig from 'api/queries/webConfig';
import { ConfigProvider } from 'contexts/ConfigProvider';
import { WebConfig } from 'models/webConfig.model';
import { AdaSupportProvider } from 'contexts/AdaSupport';
import { ErrorModalProvider } from 'contexts/ErrorContext';
import ErrorModal from 'components/common/ErrorModal';
import { NavVisibilityProvider } from 'contexts/NavVisibilityContext';
import { ConsumerFeaturesProvider } from 'contexts/ConsumerFeaturesProvider';
import { Scripts } from 'components/scripts';

const country = process.env.NEXT_PUBLIC_APP_COUNTRY_CODE;
const serverUrl = process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL;
const indexBlackList = ['staging', 'sandbox', 'demo', 'dev', 'model', 'arena', 'local'];
const appEnv = process.env.NEXT_PUBLIC_APP_ENV;
const defaultTitle = (() => {
  switch (country) {
    case 'AU':
      return 'Prezzee AU | Digital Gift Cards and Gift Vouchers Online';
    case 'NZ':
      return 'Prezzee NZ | Digital Gift Cards and Vouchers Online';
    case 'GB':
      return 'Prezzee UK | Digital Gift Cards and Gift Vouchers Online';
    case 'US':
      return 'eGift Cards | Digital Gift Cards & Vouchers | Prezzee US';
    default:
      return 'Prezzee AU | Digital Gift Cards and Gift Vouchers Online';
  }
})();

// Determines whether noindex meta tag added to head of document
const noIndex = indexBlackList.some(v => appEnv.includes(v));

// Used by next-integration-fixture.ts to pass requestInterceptor to each test,
// where it can be used to set up the server-side request mocks.
export const requestInterceptor =
  process.env.PLAYWRIGHT === '1' && typeof window === 'undefined'
    ? (() => {
        const { setupServer } = require('msw/node');
        const requestInterceptor = setupServer();
        requestInterceptor.listen({
          // Throw an error when a request is made to a service that hasn't been mocked
          onUnhandledRequest: process.env.SHOW_REQ_WARN === '1' ? 'warn' : 'error',
        });
        return requestInterceptor;
      })()
    : undefined;

const App = ({ Component, pageProps }): JSX.Element => {
  const router = useRouter();
  // const RESTRICTED_ROUTES =
  //   /^\/gift\/+(open(?:-ndl)?|say-thanks)\/[^\/]+(\/auth|\/login|\/signup)?\/|^\/confirmed\/?$/g;
  const openAda = router.query['openAda'];
  const authCookie = Cookies.get(cookieName);
  const [layoutState, _] = useState(pageProps?.pageLayoutData || null);
  const currentConfig = useRef<WebConfig>(pageProps?.config || null);

  // These 4 variables are used to determine when to load segment
  const isGdprCountry = Util.EnvUtil.checkGdprCountry(country);
  const hasAcceptedCookies = true;
  const isPb = router.pathname.startsWith(businessUtils.pathname);

  useEffect(() => {
    let interval = setInterval(() => {
      const hasLoaded = window['analytics'] !== undefined;

      if (hasLoaded) {
        const analytics: SegmentAnalytics.AnalyticsJS = window['analytics'];

        if (analytics?.user) {
          if (!authCookie && analytics?.user()?.id()) {
            analytics?.reset();
          }

          clearInterval(interval);
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const isInitialPB = useMemo(() => router.pathname.startsWith(Util.Business.pathname), []);

  useEffect(() => {
    const datadogConfig: LogsInitConfiguration = {
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      env: appEnv,
      version: Config.buildVersion,
      service: 'prezzee-frontend',
      forwardErrorsToLogs: true,
      silentMultipleInit: true,
      sessionSampleRate: 100,
    };

    const datadogRumConfig: RumInitConfiguration = {
      applicationId: 'aee5144b-778c-4823-b851-f70f2504a6ff',
      clientToken: 'pube84233671369669c2bf2337f204f7ec2',
      site: 'datadoghq.com',
      service: 'Prezzee Frontend',
      env: appEnv,
      version: Config.buildVersion,
      sessionSampleRate: noIndex ? 100 : 10,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      silentMultipleInit: true,
      allowedTracingUrls: [serverUrl],
      beforeSend: event => {
        event.context = { ...event.context, country: country };
      },
    };

    datadogLogs.init(datadogConfig);
    datadogRum.init(datadogRumConfig);
    Segment.debug(noIndex);
    Segment.brazeAlias();

    // Open chatbot if query includes ada
    if (openAda !== undefined) {
      window['adaSettings'] = {
        adaReadyCallback: () => {
          window['adaEmbed'].toggle();
        },
      };
    }

    router.events.on('routeChangeStart', (url: string) => {
      const isPB = url.startsWith(Util.Business.pathname);
      const pathPCtoPB = isPB && !isInitialPB;
      const pathPBtoPC = !isPB && isInitialPB;

      if (pathPCtoPB || pathPBtoPC) {
        window.location.assign(window.location.origin + url);
      }
    });

    if (!Cookies.get('fingerprint-pz')) {
      Cookies.set('fingerprint-pz', uuidv4(), { expires: 400 });
    }

    const defaultTraits = isPb ? SegmentRe.businessStore : SegmentRe.consumerStore;
    const traits =
      isGdprCountry && !hasAcceptedCookies
        ? defaultTraits
        : Object.assign({}, defaultTraits, { cookie_policy: SegmentCookieIdentifier.Accept });

    analytics.ready(() => {
      SegmentRe.identifyWithContext(void 0, traits, isPb);
    });

    const TTCLID_STORAGE_NAME = 'ttclid'; // @note: the setting parameter

    if (router.query && router.query.ttclid && typeof router.query.ttclid === 'string') {
      localStorage.setItem(TTCLID_STORAGE_NAME, router.query.ttclid);
    }

    if (localStorage.getItem('chakra-ui-color-mode') !== 'light') {
      localStorage.setItem('chakra-ui-color-mode', 'light');
      document.getElementsByTagName('html')[0].setAttribute('data-theme', 'light');
    }
  }, []);

  // Segment hash page on router change
  useEffect(() => {
    const acceptCookie = Cookies.get(Util.CookieUtils.acceptCookies);
    const isPb = router.pathname.startsWith(businessUtils.pathname);

    SegmentRe.hashPage(country === 'GB' ? acceptCookie === 'true' : true, isPb);

    // Open chatbot if query includes ada
    openAda && window['adaEmbed'] && window['adaEmbed'].toggle();
  }, [router.route, router.query]);

  // Kentico Kontent initialize
  useEffect(() => {
    const { projectId, languageCodename } = layoutState?.kontentData || {};

    if (projectId && languageCodename) {
      const body = document.querySelector('body');

      if (body) {
        body.setAttribute('data-kontent-project-id', projectId);
        body.setAttribute('data-kontent-language-codename', languageCodename);
      }
    }

    const smartLink = KontentSmartLink.initialize({
      debug: false,
      queryParam: 'editor-mode',
      defaultDataAttributes: {
        projectId: projectId,
        languageCodename: languageCodename,
      },
    });

    return () => {
      if (smartLink && smartLink.destroy) smartLink.destroy();
    };
  }, [layoutState]);

  // Setting react-modal app element - http://reactcommunity.org/react-modal/accessibility/#app-element
  Modal.setAppElement('#__next');

  return (
    <ThemeProvider theme={theme}>
      <Scripts />
      <ScreenClassProvider>
        <ConfigProvider value={currentConfig.current}>
          <ErrorModalProvider>
            <QueryClientProvider client={client}>
              <AdaSupportProvider value={{ genAdaEnabled: currentConfig.current.featureGenAda }}>
                <LayoutDataContext.Provider value={layoutState}>
                  <AuthProvider>
                    <ConsumerFeaturesProvider>
                      <AuthContextTS.Consumer>
                        {({ user }) => (
                          <AuthContext value={user || undefined}>
                            <DefaultSeo
                              title={defaultTitle}
                              dangerouslySetAllPagesToNoIndex={noIndex}
                              dangerouslySetAllPagesToNoFollow={noIndex}
                            />
                            <NavVisibilityProvider>
                              <Pages router={router} content={<Component {...pageProps} />} />
                            </NavVisibilityProvider>
                          </AuthContext>
                        )}
                      </AuthContextTS.Consumer>
                    </ConsumerFeaturesProvider>
                  </AuthProvider>
                </LayoutDataContext.Provider>
              </AdaSupportProvider>
              <ErrorModal />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ErrorModalProvider>
        </ConfigProvider>
      </ScreenClassProvider>
    </ThemeProvider>
  );
};

App.getInitialProps = async ({ ctx: { req, res }, router: { query } }: AppContext) => {
  const isPreview = query.preview !== undefined;
  const language = query.lang;
  const isInBrowser = typeof window !== 'undefined';
  const isInEditorMode = !!(query['editor-mode'] === 'inline');
  const config = await webConfig();

  if (!isInBrowser) {
    const pageLayoutData = await getCmsData(Util.EnvUtil.getCmsLanguage(language), isPreview, isInEditorMode);
    const userIp =
      (req.headers['x-real-ip'] as string) || (req.headers['x-forwarded-for'] as string) || req.socket.remoteAddress;

    res.setHeader('Set-Cookie', [`user-ip=${userIp};`]);

    return {
      pageProps: { pageLayoutData, config },
    };
  } else {
    return { pageProps: null };
  }
};

export default App;
