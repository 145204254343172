import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';

/**
 * @secure
 * Request to sign out current user.
 * @returns Decoded promise of signOut API response.
 */
const signOut = async () => {
  const response = await createRequest('api/v3/signout/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    withAuth: true,
  });
  const data = await handleErrors(response);
  return camelKeys(data, { recursive: true });
};

export default signOut;
