import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { Maybe } from 'models/types';

export type PaginatedDrawerContextType = {
  page?: Maybe<Page>;
  setPage: (page: Page) => void;
};

export type Page = {
  code: string;
  parent?: Maybe<Page>;
};

export const DEFAULT_PAGE: Page = {
  code: 'default',
  parent: void 0,
};

export const PaginatedDrawerContext = createContext<PaginatedDrawerContextType>({
  page: DEFAULT_PAGE,
  setPage: () => void 0,
});

export const PaginatedDrawerProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useState<Page>(DEFAULT_PAGE);

  return <PaginatedDrawerContext.Provider value={{ page, setPage }}>{children}</PaginatedDrawerContext.Provider>;
};

export const usePaginatedDrawer = () => {
  const ctx = useContext(PaginatedDrawerContext);

  if (!ctx) throw new Error('usePaginationDrawer must be used within a PaginatedDrawerProvider');

  return ctx;
};
