// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__ImageCollection__Decoder from "../ImageCollection/Gql__ImageCollection__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "ImageCollectionContentType") {
    return Gql__ImageCollection__Decoder.get(content.VAL.imageCollection);
  }
  
}

var ImageCollection;

export {
  ImageCollection ,
  get ,
}
/* No side effect */
