import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';
import { LoginResponse } from 'models/auth.model';

export const loginErrors = {
  emailPassNotFound: 'A user with that email and password could not be found.',
  locked: `Your Prezzee account has been locked for security reasons.\n\n Please contact help@prezzee.com for assistance or try again after 30 minutes.`,
};
/**
 * @secure
 * Request to login a user via email and password.
 * @param email Email to attempt login with.
 * @param password Password to attempt login with.
 * @returns Decoded promise of login API response.
 */
const login = (email: string, password: string): Promise<LoginResponse> => {
  return createRequest('api/v3/signin-by-email-password/email/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ email, password }),
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as LoginResponse);
};

export default login;
