// Generated by ReScript, PLEASE EDIT WITH CARE

import StaticImageTsx from "./StaticImage.tsx";

var make = StaticImageTsx;

export {
  make ,
}
/* make Not a pure module */
