// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment NewsletterBlock on EmailSignUpBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    text_colour  {
      __typename
      value  {
        __typename
        codename
      }
    }
    input_box_placeholder_text  {
      __typename
      value
    }
    success_message  {
      __typename
      value
    }
    background_image  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    background_image_tablet  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    background_image_mobile  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    background_colour  {
      __typename
      value
    }
    button  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.description;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.text_colour;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.input_box_placeholder_text;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.success_message;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? value$11 : undefined
    };
  }
  var value$12 = value.background_image;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$13) : undefined
    };
  }
  var value$14 = value.background_image_tablet;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$15) : undefined
    };
  }
  var value$16 = value.background_image_mobile;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$17) : undefined
    };
  }
  var value$18 = value.background_colour;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.button;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$21) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          description: tmp$1,
          text_colour: tmp$2,
          input_box_placeholder_text: tmp$3,
          success_message: tmp$4,
          background_image: tmp$5,
          background_image_tablet: tmp$6,
          background_image_mobile: tmp$7,
          background_colour: tmp$8,
          button: tmp$9
        };
}

function serialize(value) {
  var value$1 = value.button;
  var button;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    button = {
      __typename: value$4,
      value: value$3
    };
  } else {
    button = null;
  }
  var value$5 = value.background_colour;
  var background_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    background_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    background_colour = null;
  }
  var value$9 = value.background_image_mobile;
  var background_image_mobile;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    background_image_mobile = {
      __typename: value$12,
      value: value$11
    };
  } else {
    background_image_mobile = null;
  }
  var value$13 = value.background_image_tablet;
  var background_image_tablet;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$14) : null;
    var value$16 = value$13.__typename;
    background_image_tablet = {
      __typename: value$16,
      value: value$15
    };
  } else {
    background_image_tablet = null;
  }
  var value$17 = value.background_image;
  var background_image;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$18) : null;
    var value$20 = value$17.__typename;
    background_image = {
      __typename: value$20,
      value: value$19
    };
  } else {
    background_image = null;
  }
  var value$21 = value.success_message;
  var success_message;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    success_message = {
      __typename: value$24,
      value: value$23
    };
  } else {
    success_message = null;
  }
  var value$25 = value.input_box_placeholder_text;
  var input_box_placeholder_text;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? value$26 : null;
    var value$28 = value$25.__typename;
    input_box_placeholder_text = {
      __typename: value$28,
      value: value$27
    };
  } else {
    input_box_placeholder_text = null;
  }
  var value$29 = value.text_colour;
  var text_colour;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$30) : null;
    var value$32 = value$29.__typename;
    text_colour = {
      __typename: value$32,
      value: value$31
    };
  } else {
    text_colour = null;
  }
  var value$33 = value.description;
  var description;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    description = {
      __typename: value$36,
      value: value$35
    };
  } else {
    description = null;
  }
  var value$37 = value.title;
  var title;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? value$38 : null;
    var value$40 = value$37.__typename;
    title = {
      __typename: value$40,
      value: value$39
    };
  } else {
    title = null;
  }
  var value$41 = value.system;
  var value$42 = value$41.codename;
  var value$43 = value$41.id;
  var value$44 = value$41.__typename;
  var system = {
    __typename: value$44,
    id: value$43,
    codename: value$42
  };
  var value$45 = value.__typename;
  return {
          __typename: value$45,
          system: system,
          title: title,
          description: description,
          text_colour: text_colour,
          input_box_placeholder_text: input_box_placeholder_text,
          success_message: success_message,
          background_image: background_image,
          background_image_tablet: background_image_tablet,
          background_image_mobile: background_image_mobile,
          background_colour: background_colour,
          button: button
        };
}

function verifyArgsAndParse(_NewsletterBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var NewsletterBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var CallToAction;

export {
  $$Image ,
  CallToAction ,
  NewsletterBlock ,
}
/* query Not a pure module */
