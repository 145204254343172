// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../common/util.bs.js";
import * as BsCss from "../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Footer from "./Footer.bs.js";
import * as Header from "./Header.bs.js";
import * as Shared from "./Shared.bs.js";
import * as Themes from "../css/Themes.bs.js";
import * as Fresnel from "../bindings/Fresnel.bs.js";
import * as Segment from "../bindings/Segment.bs.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as MenuCard from "../components/library/MenuCard.bs.js";
import * as Belt_List from "@rescript/std/lib/es6/belt_List.js";
import * as SmartLink from "../components/library/SmartLink.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as AlertBanner from "./AlertBanner.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as UserContext from "../auth/user/UserContext.bs.js";
import * as Router from "next/router";
import * as SegmentUtils from "../utils/SegmentUtils.bs.js";
import * as MyAccountContext from "../components/pages/myAccount/MyAccountContext.bs.js";
import * as RegionIndicators from "../components/library/RegionIndicators.bs.js";
import * as LayoutDataContext from "../utils/LayoutDataContext.bs.js";
import * as NZDisclaimerAlert from "../components/NZDisclaimerAlert.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../components/pages/index/KontentContextProvider.bs.js";
import * as PaginatedDrawerContext from "../utils/PaginatedDrawerContext.bs.js";
import ConsumerNavigationTsx from "./ConsumerNavigation.tsx";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as Navigation__Personal$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Navigation/Navigation__Personal.bs.js";
import * as TopNavigationBar__Business$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TopNavigationBar/TopNavigationBar__Business.bs.js";
import * as TopNavigationBar__Navlinks$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/TopNavigationBar/TopNavigationBar__Navlinks.bs.js";
import HeaderContainerTsx from "../components/library/HeaderContainer.tsx";
import * as TopNavigationBar__AuthContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/TopNavigationBar/TopNavigationBar__AuthContainer.bs.js";
import * as TopNavigationBar__Background__Personal$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TopNavigationBar/TopNavigationBar__Background__Personal.bs.js";

var make = ConsumerNavigationTsx;

var ConsumerNavigation = {
  make: make
};

var make$1 = HeaderContainerTsx;

var HeaderContainer = {
  make: make$1
};

var sharedStyle_0 = BsCss.position("relative");

var sharedStyle_1 = {
  hd: BsCss.flex({
        NAME: "num",
        VAL: 1
      }),
  tl: {
    hd: BsCss.flexGrow(1),
    tl: {
      hd: BsCss.flexShrink(1),
      tl: {
        hd: BsCss.flexBasis(BsCss.pct(0)),
        tl: /* [] */0
      }
    }
  }
};

var sharedStyle = {
  hd: sharedStyle_0,
  tl: sharedStyle_1
};

var mainContainer = Curry._1(BsCss.style, sharedStyle);

var fullHeightMainContainer = Curry._1(BsCss.style, Belt_List.concat(sharedStyle, {
          hd: BsCss.display("flex"),
          tl: /* [] */0
        }));

var navContainerBase = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.pct(100)),
      tl: {
        hd: BsCss.display("flex"),
        tl: {
          hd: BsCss.justifyContent("center"),
          tl: {
            hd: BsCss.borderBottom(BsCss.px(1), "solid", Theme__Color$PrezzeeRibbon.Personal.paleGrey),
            tl: /* [] */0
          }
        }
      }
    });

var businessContainer = Cn.make([
      navContainerBase,
      Curry._1(BsCss.style, {
            hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.Business.stone),
            tl: /* [] */0
          })
    ]);

var consumerContainer = Cn.make([
      navContainerBase,
      Curry._1(BsCss.style, {
            hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.Personal.white),
            tl: /* [] */0
          })
    ]);

var navInnerContainer = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(1440)),
      tl: {
        hd: BsCss.width(BsCss.pct(100)),
        tl: /* [] */0
      }
    });

function logoImg(imgWidth) {
  return Curry._1(BsCss.style, {
              hd: BsCss.width(BsCss.px(imgWidth)),
              tl: {
                hd: BsCss.maxWidth("unset"),
                tl: /* [] */0
              }
            });
}

var Styles = {
  sharedStyle: sharedStyle,
  mainContainer: mainContainer,
  fullHeightMainContainer: fullHeightMainContainer,
  navContainerBase: navContainerBase,
  businessContainer: businessContainer,
  consumerContainer: consumerContainer,
  navInnerContainer: navInnerContainer,
  logoImg: logoImg
};

var flexibleContainer = "flex flex-col min-h-screen";

var myAccountRoutes = [
  "my-details",
  "orders"
];

var RegionStrip = RegionIndicators.GenericRegionPicker(RegionIndicators.RegionStrip);

var RegionPickerInline = RegionIndicators.GenericRegionPicker(RegionIndicators.RegionInline);

var isMenuOpen = {
  contents: false
};

function Page(props) {
  var theme = props.theme;
  var isBusinessContainer = props.isBusinessContainer;
  var showRegionStrip = props.showRegionStrip;
  var alertFooter = props.alertFooter;
  var footerInFlexibleContainer = props.footerInFlexibleContainer;
  var alertBanner = props.alertBanner;
  var footer = props.footer;
  var header = props.header;
  var mainClassName = props.mainClassName;
  var children = props.children;
  var mainClassName$1 = mainClassName !== undefined ? mainClassName : "";
  var header$1 = header !== undefined ? Caml_option.valFromOption(header) : JsxRuntime.jsx(Header.make, {});
  var footer$1 = footer !== undefined ? Caml_option.valFromOption(footer) : JsxRuntime.jsx(Footer.make, {});
  var alertBanner$1 = alertBanner !== undefined ? Caml_option.valFromOption(alertBanner) : JsxRuntime.jsx(AlertBanner.make, {});
  var footerInFlexibleContainer$1 = footerInFlexibleContainer !== undefined ? footerInFlexibleContainer : true;
  var alertFooter$1 = alertFooter !== undefined ? alertFooter : false;
  var showRegionStrip$1 = showRegionStrip !== undefined ? showRegionStrip : true;
  var isBusinessContainer$1 = isBusinessContainer !== undefined ? isBusinessContainer : false;
  var theme$1 = theme !== undefined ? theme : Themes.personal;
  var state = LayoutDataContext.useContext(undefined);
  var match = React.useReducer(MyAccountContext.reducer, MyAccountContext.initialState);
  var router = Router.useRouter();
  var match$1 = React.useState(function () {
        return false;
      });
  var setMenuOpen = match$1[1];
  var match$2 = UserContext.useUser(undefined);
  var isLoggedIn = match$2[0] ? true : false;
  var onMenuChange = function (menuState) {
    isMenuOpen.contents = menuState;
    Curry._1(setMenuOpen, (function (param) {
            return menuState;
          }));
  };
  var menuItemsData = Belt_Option.getWithDefault(Belt_Option.flatMap(state, (function (d) {
              if (isBusinessContainer$1) {
                return d.businessHeader;
              } else {
                return d.header;
              }
            })), []);
  var classicLogoUrl = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (f) {
                      return f.headerLogo;
                    })), (function (d) {
                  return d.classicLogoAsset;
                })), (function (d) {
              return d.url;
            })), (require("../../public/images/prezzee_logo.svg")));
  var classicLogoWidth = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (f) {
                  return f.headerLogo;
                })), (function (d) {
              return d.classicLogoWidth;
            })), 0);
  var businessLogoUrl = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (f) {
                      return f.headerLogo;
                    })), (function (d) {
                  return d.businessLogoAsset;
                })), (function (d) {
              return d.url;
            })), (require("../../public/images/PrezzeeBusinessLogo.svg")));
  var businessLogoWidth = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (f) {
                  return f.headerLogo;
                })), (function (d) {
              return d.businessLogoWidth;
            })), 0);
  var menuItems = Belt_Array.map(menuItemsData, (function (param) {
          var smartLink = param.smartLink;
          var itemId = smartLink.itemId;
          var url = param.url;
          var title = param.title;
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Navigation__Personal$PrezzeeRibbon.Item.make, {
                            children: JsxRuntime.jsx(Link, {
                                  href: url,
                                  children: JsxRuntime.jsx("a", {
                                        children: title,
                                        onClick: (function (param) {
                                            if (url === "/contact/") {
                                              return Segment.customElementInteraction({
                                                          element: SegmentUtils.SegmentUtils.mapElement(title, "topnav", undefined),
                                                          path: router.asPath
                                                        });
                                            }
                                            
                                          })
                                      })
                                })
                          }),
                      itemId: itemId,
                      elementCodename: smartLink.codename
                    }, itemId);
        }));
  var navigation = JsxRuntime.jsx(Navigation__Personal$PrezzeeRibbon.make, {
        children: menuItems
      });
  var regionStrip = JsxRuntime.jsx(RegionStrip.make, {});
  var regionPicker = JsxRuntime.jsx(RegionPickerInline.make, {});
  var navigationBlock = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          JsxRuntime.jsx(TopNavigationBar__Navlinks$PrezzeeRibbon.make, {
                children: navigation
              }),
          JsxRuntime.jsx(TopNavigationBar__AuthContainer$PrezzeeRibbon.make, {
                authMenu: isBusinessContainer$1 ? JsxRuntime.jsx(MenuCard.BusinessAuthMenu.make, {}) : JsxRuntime.jsx(MenuCard.AuthMenu.make, {}),
                authMenuLowerBreakpoint: "rbLargeDesktop"
              })
        ]
      });
  var closeMenuObservable_subscribe = function (fn) {
    router.events.on("routeChangeComplete", (function (param) {
            Curry._1(fn, undefined);
          }));
  };
  var closeMenuObservable_unsubscribe = function (fn) {
    router.events.off("routeChangeComplete", (function (param) {
            Curry._1(fn, undefined);
          }));
  };
  var closeMenuObservable = {
    subscribe: closeMenuObservable_subscribe,
    unsubscribe: closeMenuObservable_unsubscribe
  };
  var logo = React.useMemo((function () {
          var smartLink = Belt_Option.mapWithDefault(Belt_Option.flatMap(state, (function (c) {
                      return c.headerLogo;
                    })), SmartLink.emptySmartLink, (function (logo) {
                  return logo.smartLink;
                }));
          var logoImg$1 = isBusinessContainer$1 ? JsxRuntime.jsx(Link, {
                  href: "/",
                  prefetch: false,
                  children: JsxRuntime.jsx("a", {
                        children: JsxRuntime.jsx("img", {
                              className: logoImg(businessLogoWidth),
                              alt: "Prezzee Logo",
                              src: businessLogoUrl
                            }),
                        title: "Homepage"
                      })
                }) : JsxRuntime.jsx(Link, {
                  href: "/",
                  prefetch: false,
                  children: JsxRuntime.jsx("a", {
                        children: JsxRuntime.jsx("img", {
                              className: logoImg(classicLogoWidth),
                              alt: "Prezzee Logo",
                              src: classicLogoUrl
                            }),
                        title: "Homepage"
                      })
                });
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: logoImg$1
                                })
                          }),
                      itemId: smartLink.itemId,
                      elementCodename: smartLink.codename
                    });
        }), [isBusinessContainer$1]);
  return JsxRuntime.jsxs(Shared.make, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(make$1, {
                              children: JsxRuntime.jsx(PaginatedDrawerContext.PaginatedDrawerProvider.make, {
                                    children: showRegionStrip$1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                            children: [
                                              JsxRuntime.jsx(Fresnel.Media.make, {
                                                    greaterThanOrEqual: "tablet",
                                                    children: regionStrip
                                                  }),
                                              isBusinessContainer$1 ? null : alertBanner$1,
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(TopNavigationBar__Background__Personal$PrezzeeRibbon.StaticBackground.make, {
                                                                children: isBusinessContainer$1 ? JsxRuntime.jsx(TopNavigationBar__Business$PrezzeeRibbon.make, {
                                                                        logo: logo,
                                                                        navigationBlock: navigationBlock,
                                                                        regionPicker: regionPicker,
                                                                        closeMenuObservable: closeMenuObservable,
                                                                        onLogoutClick: (function (param) {
                                                                            router.push("/logout");
                                                                          }),
                                                                        isLoggedIn: isLoggedIn,
                                                                        onMenuChange: onMenuChange
                                                                      }) : JsxRuntime.jsx(make, {
                                                                        logo: logo,
                                                                        navigationBlock: navigationBlock,
                                                                        regionPicker: regionPicker,
                                                                        closeMenuObservable: closeMenuObservable,
                                                                        onLogoutClick: (function (param) {
                                                                            router.push("/logout");
                                                                          }),
                                                                        isLoggedIn: isLoggedIn,
                                                                        isMenuOpen: isMenuOpen
                                                                      })
                                                              }),
                                                          className: navInnerContainer
                                                        }),
                                                    className: isBusinessContainer$1 ? businessContainer : consumerContainer
                                                  })
                                            ]
                                          }) : header$1
                                  }),
                              isMenuOpen: isMenuOpen
                            }),
                        JsxRuntime.jsx("main", {
                              children: !isBusinessContainer$1 && Belt_Array.some(myAccountRoutes, (function (myAccountRoute) {
                                      return router.pathname.includes(myAccountRoute);
                                    })) ? JsxRuntime.jsx(MyAccountContext.MyAccountContextProvider.make, {
                                      value: [
                                        match[0],
                                        match[1]
                                      ],
                                      children: children
                                    }) : children,
                              className: Cn.make([
                                    mainContainer,
                                    mainClassName$1
                                  ])
                            }),
                        footerInFlexibleContainer$1 ? footer$1 : null,
                        Caml_obj.equal(Util.EnvUtil.getCountry(undefined), {
                              TAG: /* Ok */0,
                              _0: /* NewZealand */3
                            }) ? JsxRuntime.jsx(NZDisclaimerAlert.make, {
                                alertFooter: alertFooter$1
                              }) : null
                      ],
                      className: flexibleContainer
                    }),
                footerInFlexibleContainer$1 ? null : footer$1
              ],
              theme: theme$1
            });
}

var Head;

var Link$1;

var RegionPicker;

var RegionIndicatorStrip;

var RegionIndicatorInline;

var RegionIndicatorCountryDisplay;

var TopNavigationBar;

var BusinessTopNavigationBar;

var TopNavigationBarNavLinks;

var TopNavigationBarAuthContainer;

var Navigation;

var NavigationItem;

var StickyBar;

var StaticBackground;

var KontentContextConsumer;

var make$2 = Page;

export {
  ConsumerNavigation ,
  HeaderContainer ,
  Head ,
  Link$1 as Link,
  RegionPicker ,
  RegionIndicatorStrip ,
  RegionIndicatorInline ,
  RegionIndicatorCountryDisplay ,
  TopNavigationBar ,
  BusinessTopNavigationBar ,
  TopNavigationBarNavLinks ,
  TopNavigationBarAuthContainer ,
  Navigation ,
  NavigationItem ,
  StickyBar ,
  StaticBackground ,
  KontentContextConsumer ,
  Styles ,
  flexibleContainer ,
  myAccountRoutes ,
  RegionStrip ,
  RegionPickerInline ,
  isMenuOpen ,
  make$2 as make,
}
/* make Not a pure module */
