// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (f) {
              return f.value;
            })), "");
  var subtitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c.subtitle, (function (f) {
              return f.value;
            })), "");
  var alignment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var banner_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.banner_colour, (function (f) {
              return f.value;
            })), "");
  var banner_height = Belt_Option.getWithDefault(Belt_Option.flatMap(c.banner_height, (function (f) {
              return f.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          subtitle: subtitle,
          banner_colour: banner_colour,
          banner_height: banner_height,
          alignment: alignment,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
