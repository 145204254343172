import { DraftOrderPayload } from './../../models/payment.model';
import { DraftOrderResponse } from 'models/payment.model';
import { createRequest, RequestParams } from 'api/helpers';
import { camelKeys } from 'js-convert-case';
import { PurchaseKind } from 'models/purchaseForm.model';
import handleErrors from 'api/handleErrors';

const updateDraftOrder = async ({
  orderUrl,
  payload,
  purchaseKind,
  isLayer,
}: {
  orderUrl: string;
  payload: DraftOrderPayload;
  purchaseKind: PurchaseKind;
  isLayer: boolean;
}): Promise<DraftOrderResponse> => {
  let params: RequestParams = {
    body: JSON.stringify({ ...payload, ...(isLayer && { is_layer: true }) }),
    method: 'PUT',
    mode: 'cors',
    logRequest: true,
    withAuth: true,
    withSecurityToken: true,
    isUrl: true,
  };

  if (purchaseKind === 'user') params.withAuth = true;

  return createRequest(orderUrl, params)
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as DraftOrderResponse);
};

export default updateDraftOrder;
