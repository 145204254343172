// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";

function id(x) {
  return x;
}

function ifNotEmpty(value) {
  if (value === "") {
    return ;
  } else {
    return value;
  }
}

function getQueryContext(preview) {
  return JSON.parse(preview ? " {\"headers\":{\"x-preview-token\":1}} " : " {} ");
}

function flatMap(arr, fn) {
  return Belt_Array.concatMany(Belt_Array.map(arr, fn));
}

var $$Array = {
  flatMap: flatMap
};

var Utils = {
  $$Array: $$Array
};

export {
  id ,
  ifNotEmpty ,
  getQueryContext ,
  Utils ,
}
/* No side effect */
