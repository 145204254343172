// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          icon: result,
          text: text,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
