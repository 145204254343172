import { createDeliveryClient } from '../helpers';
import { IContentItem, Elements } from '@kentico/kontent-delivery';

export type ZagBuyingPrompt = IContentItem<{
  image: Elements.AssetsElement;
  title: Elements.TextElement;
  description: Elements.TextElement;
  zag_explanation_modal_link: Elements.TextElement;
}>;

const zagBuyingPrompt = async (language: string, isPreview: boolean) => {
  const client = createDeliveryClient(language, isPreview);
  const data = await (await client.item<ZagBuyingPrompt>('zag_buying_prompt').toPromise()).data;

  return data;
};

export default zagBuyingPrompt;
