// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function decode(gqlQueryResult) {
  var callToActionList = Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(gqlQueryResult.callToActions, []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var imageList = Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(gqlQueryResult.images, []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "ImageContentType") {
            return Gql__Image__Decoder.get(f.VAL.image);
          }
          
        }));
  return {
          callToActionList: callToActionList,
          imageList: imageList
        };
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  Pino.logger.error({
        "error:": result._0,
        type: "Enriched Text decoder"
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

var CallToAction;

var $$Image;

export {
  CallToAction ,
  $$Image ,
  Decoder ,
}
/* Pino Not a pure module */
