// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__DeviceSizeImage__Fragment from "../DeviceSizeImage/Gql__DeviceSizeImage__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment PromoTileBlock on PromoTileBlockContentType   {
    __typename
    tiles  {
      __typename
      value  {
        __typename
        ...on PromoTileContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          title  {
            __typename
            value
          }
          url  {
            __typename
            value
          }
          background_colour  {
            __typename
            value
          }
          background_image  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
              ...on DeviceSizeImageContentType   {
                ...DeviceSizeImage
              }
            }
          }
          graphic_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
          text_colour  {
            __typename
            value
          }
          desktop_text_width  {
            __typename
            value
          }
          icon_colour  {
            __typename
            value
          }
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__DeviceSizeImage__Fragment.DeviceSizeImage.query, Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.tiles;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.value;
    tmp = {
      __typename: value$1.__typename,
      value: !(value$2 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "PromoTileContentType") {
                  var value$1 = value.system;
                  var value$2 = value.title;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.url;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.background_colour;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.background_image;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp;
                                switch (typename) {
                                  case "DeviceSizeImageContentType" :
                                      tmp = {
                                        NAME: "DeviceSizeImageContentType",
                                        VAL: {
                                          deviceSizeImage: Gql__DeviceSizeImage__Fragment.DeviceSizeImage.verifyArgsAndParse("DeviceSizeImage", value)
                                        }
                                      };
                                      break;
                                  case "ImageContentType" :
                                      tmp = {
                                        NAME: "ImageContentType",
                                        VAL: {
                                          image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                        }
                                      };
                                      break;
                                  default:
                                    tmp = {
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    };
                                }
                                return tmp;
                              }), value$9) : undefined
                    };
                  }
                  var value$10 = value.graphic_asset;
                  var tmp$5;
                  if (value$10 == null) {
                    tmp$5 = undefined;
                  } else {
                    var value$11 = value$10.value;
                    tmp$5 = {
                      __typename: value$10.__typename,
                      value: !(value$11 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$11) : undefined
                    };
                  }
                  var value$12 = value.text_colour;
                  var tmp$6;
                  if (value$12 == null) {
                    tmp$6 = undefined;
                  } else {
                    var value$13 = value$12.value;
                    tmp$6 = {
                      __typename: value$12.__typename,
                      value: !(value$13 == null) ? value$13 : undefined
                    };
                  }
                  var value$14 = value.desktop_text_width;
                  var tmp$7;
                  if (value$14 == null) {
                    tmp$7 = undefined;
                  } else {
                    var value$15 = value$14.value;
                    tmp$7 = {
                      __typename: value$14.__typename,
                      value: !(value$15 == null) ? value$15 : undefined
                    };
                  }
                  var value$16 = value.icon_colour;
                  var tmp$8;
                  if (value$16 == null) {
                    tmp$8 = undefined;
                  } else {
                    var value$17 = value$16.value;
                    tmp$8 = {
                      __typename: value$16.__typename,
                      value: !(value$17 == null) ? value$17 : undefined
                    };
                  }
                  tmp = {
                    NAME: "PromoTileContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      title: tmp$1,
                      url: tmp$2,
                      background_colour: tmp$3,
                      background_image: tmp$4,
                      graphic_asset: tmp$5,
                      text_colour: tmp$6,
                      desktop_text_width: tmp$7,
                      icon_colour: tmp$8
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$2) : undefined
    };
  }
  return {
          __typename: value.__typename,
          tiles: tmp
        };
}

function serialize(value) {
  var value$1 = value.tiles;
  var tiles;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.icon_colour;
              var icon_colour;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                icon_colour = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                icon_colour = null;
              }
              var value$6 = value$1.desktop_text_width;
              var desktop_text_width;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                desktop_text_width = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                desktop_text_width = null;
              }
              var value$10 = value$1.text_colour;
              var text_colour;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                text_colour = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                text_colour = null;
              }
              var value$14 = value$1.graphic_asset;
              var graphic_asset;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$15) : null;
                var value$17 = value$14.__typename;
                graphic_asset = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                graphic_asset = null;
              }
              var value$18 = value$1.background_image;
              var background_image;
              if (value$18 !== undefined) {
                var value$19 = value$18.value;
                var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var variant = value.NAME;
                          if (variant === "UnspecifiedFragment") {
                            return {};
                          } else if (variant === "DeviceSizeImageContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__DeviceSizeImage__Fragment.DeviceSizeImage.serialize(value.VAL.deviceSizeImage)]);
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                          }
                        }), value$19) : null;
                var value$21 = value$18.__typename;
                background_image = {
                  __typename: value$21,
                  value: value$20
                };
              } else {
                background_image = null;
              }
              var value$22 = value$1.background_colour;
              var background_colour;
              if (value$22 !== undefined) {
                var value$23 = value$22.value;
                var value$24 = value$23 !== undefined ? value$23 : null;
                var value$25 = value$22.__typename;
                background_colour = {
                  __typename: value$25,
                  value: value$24
                };
              } else {
                background_colour = null;
              }
              var value$26 = value$1.url;
              var url;
              if (value$26 !== undefined) {
                var value$27 = value$26.value;
                var value$28 = value$27 !== undefined ? value$27 : null;
                var value$29 = value$26.__typename;
                url = {
                  __typename: value$29,
                  value: value$28
                };
              } else {
                url = null;
              }
              var value$30 = value$1.title;
              var title;
              if (value$30 !== undefined) {
                var value$31 = value$30.value;
                var value$32 = value$31 !== undefined ? value$31 : null;
                var value$33 = value$30.__typename;
                title = {
                  __typename: value$33,
                  value: value$32
                };
              } else {
                title = null;
              }
              var value$34 = value$1.system;
              var value$35 = value$34.codename;
              var value$36 = value$34.id;
              var value$37 = value$34.__typename;
              var system = {
                __typename: value$37,
                id: value$36,
                codename: value$35
              };
              return {
                      __typename: "PromoTileContentType",
                      system: system,
                      title: title,
                      url: url,
                      background_colour: background_colour,
                      background_image: background_image,
                      graphic_asset: graphic_asset,
                      text_colour: text_colour,
                      desktop_text_width: desktop_text_width,
                      icon_colour: icon_colour
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    tiles = {
      __typename: value$4,
      value: value$3
    };
  } else {
    tiles = null;
  }
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          tiles: tiles
        };
}

function verifyArgsAndParse(_PromoTileBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var PromoTileBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var DeviceSizeImage;

export {
  $$Image ,
  DeviceSizeImage ,
  PromoTileBlock ,
}
/* query Not a pure module */
