// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";

function navBarLinksContainer(isOpen) {
  var containerStyle = "fixed left-0 right-0 bottom-0 top-0 mt-18 bg-white z-50";
  return Cn.make([
              containerStyle,
              isOpen ? "block" : "hidden",
              containerStyle
            ]);
}

var responsiveLeftButton = "block w-5 rounded hover:border-gray-400 hover:text-prezzee-red focus:outline-none";

var responsiveRightButton = "block w-5 rounded hover:border-gray-400 hover:text-prezzee-red focus:outline-none";

var navBarLink = "block text-mid-gray border-white hover:text-prezzee-red hover:border-prezzee-red flex items-center justify-between focus:text-prezzee-red focus:border-prezzee-red active:text-prezzee-red active:border-prezzee-red h-11 transition-all duration-500 ease-in-out bg-white px-4";

var linkText = "";

var grayLine = " border-gray-200 border-b w-full";

export {
  responsiveLeftButton ,
  responsiveRightButton ,
  navBarLinksContainer ,
  navBarLink ,
  linkText ,
  grayLine ,
}
/* No side effect */
