import { HTMLElement, parse } from 'node-html-parser';

export const parseList = str => {
  const htmlDoc = parse(str);

  return htmlDoc.childNodes
    .filter(el => (el as HTMLElement).nodeType === 1 && (el as HTMLElement).tagName === 'LI')
    .map(el => (el as HTMLElement).innerHTML);
};

export const parseRichTextData = str => {
  const htmlDoc = parse(str);

  let items = htmlDoc.childNodes.filter(({ nodeType }) => nodeType === 1);
  let isObjectItems = false;

  let elements = items.map(element => {
    let el = element as HTMLElement;
    let tagName = el.tagName;
    switch (tagName) {
      case 'OBJECT':
        isObjectItems = true;
        return {
          tag: 'OBJECT',
          data: el.getAttribute('data-codename'),
        };
      default:
        return {
          tag: tagName,
          data: el.innerHTML,
        };
    }
  });

  return {
    elements: elements,
    isObjectItems,
  };
};
