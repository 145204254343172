// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";
import * as Gql__PageBuilder__Decoder from "../PageBuilder/Gql__PageBuilder__Decoder.bs.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Gql__Header__Decoder__Homepage from "../Header/Gql__Header__Decoder__Homepage.bs.js";
import * as Gql__Footer__Decoder__FooterContainer from "../Footer/Gql__Footer__Decoder__FooterContainer.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "LandingPageContentType") {
    return ;
  }
  var c = content.VAL;
  var logo = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.logo, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (i) {
                  if (typeof i === "object" && i.NAME === "ImageContentType") {
                    return Gql__Image__Decoder.get(i.VAL.image);
                  }
                  
                })), 0), Gql__Image__Decoder.emptyImage);
  var navigation_buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.navigation_buttons, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var headerBlockData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.subpages, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var header = headerBlockData !== undefined && typeof headerBlockData === "object" && headerBlockData.NAME === "PageContentType" ? Gql__Header__Decoder__Homepage.get(headerBlockData.VAL.headerBlock) : undefined;
  var contentData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.content, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var content$1 = contentData !== undefined && typeof contentData === "object" && contentData.NAME === "PageBuilderContentType" ? Gql__PageBuilder__Decoder.get(contentData.VAL.pageContent) : undefined;
  var footerBlockData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.footer, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var footer = footerBlockData !== undefined && typeof footerBlockData === "object" && footerBlockData.NAME === "FooterContainerContentType" ? Gql__Footer__Decoder__FooterContainer.get(footerBlockData.VAL.footerBlock) : undefined;
  return {
          logo: logo,
          navigation_buttons: navigation_buttons,
          header: header,
          content: content$1,
          footer: footer
        };
}

var LandingPage = {
  get: get
};

function decode(gqlQueryResult) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.itemsByType, []), 0), GqlUtils.id), get);
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  Pino.logger.error({
        err: result._0,
        type: "Landing Page decoder"
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

var $$Image;

var HeaderBlock;

var PageBuilder;

var FooterBlock;

export {
  $$Image ,
  HeaderBlock ,
  PageBuilder ,
  FooterBlock ,
  LandingPage ,
  Decoder ,
}
/* Pino Not a pure module */
