import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

// TODO: Add all possible claim gift errors.
/**
 * All possible errors returned from gift claim response.
 */
export const claimGiftErrors = {};

/**
 * Request to claim gift.
 * @param uid The gift unique identifier.
 * @param blob The gift encrypted blob.
 * @param pin The 4 digit pin code entered at unlock screen.
 * @returns Promise with decoded gift claim response.
 */
const claimGift = (uid: string, blob?: string, pin?: string, type: 'tree' | 'single' = 'single') => {
  let route = `api/v3/gift/claim/${uid}/`;

  if (type === 'tree') {
    route = `/api/v3/gift/claim-tree/${uid}/`;
  }

  let options = {
    withAuth: true,
  };
  const body = {};

  if (blob) {
    route += `?b=${blob}`;
  }

  if (pin) {
    options['method'] = 'POST';
    body['pin'] = pin;
  }
  if (Object.keys(body).length > 0) {
    options['body'] = JSON.stringify(body);
  }
  return createRequest(route, options).then(handleErrors);
};

export default claimGift;
