import { Asset } from './Models';
import { Nullable } from './types';

export type Theme = {
  uid: string;
  code: string;
  image: string;
  cmsImage?: Nullable<Asset>;
};

export type Template = {
  uid: string;
  imageFrame: string;
  imagePreview: string;
  cmsImageFrame?: Nullable<Asset>;
  cmsImagePreview?: Nullable<Asset>;
};

export type CustomThemeDesign = {
  templateUid: string;
  code: string;
  image: string;
  keyColour: string;
  textColour: string;
  uid: string;
};

export type Promo = {
  uid: string;
  title: string;
  enabled: boolean;
  promoText: string;
  terms: string;
};

export type Denomination = {
  code: string;
  value: string;
  valueCurrency: string;
  price: string;
  priceCurrency: string;
};

export type Category = {
  name: string;
  slug: string;
  position: number;
  order: number;
  skuPosition: number;
};

export type Alert = {
  title: string;
  messageMarkdown: string;
  level: string;
  priority: number;
};

export type Merchant = {
  terms: string;
};

export type LayerType = 'upgrade' | 'default';

export type Sku = {
  uid: string;
  name: string;
  cmsId: string;
  searchableTerms: string;
  similarTerms: string;
  displayName: string;
  productType: string;
  code: string;
  templates: Template[];
  themes: Theme[];
  promos: Promo[];
  denominations: Denomination[];
  description: string;
  descriptionShort: string;
  longDescription: string | null;
  tagline: string | null;
  descriptionMarkdown: string;
  isSwappable: boolean;
  categories: Category[];
  expiryTermDisplay: string;
  alerts: Alert[];
  slug: string;
  hideFromAllBusinessCategories: boolean;
  hideFromAllClassicCategories: boolean;
  merchant: Merchant;
  multiPurchase: SkuMultiToggleType;
  isPbCategoryHidden: boolean;
  isPcCategoryHidden: boolean;
  createdAt: string;
  layerEnabled: boolean;
  layerType: LayerType;
  layerExpiryTerm: string | null;
  layerDisplayName: string | null;
};

export type Inventory = {
  skus: Sku[];
  totalCards: number;
  international: []; // Unsure what this is meant to be
  promotions: []; // Unsure what this is meant to be
  templates: []; // Unsure what this is meant to be
};

export enum SkuMultiToggleType {
  On = 'on',
  Off = 'off',
  Default = 'default',
}
