// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";

function navBarLinksContainer(isOpen) {
  var containerStyle = "lg:flex lg:justify-between sm:py-0 bg-white lg:h-full lg:my-auto z-50 w-full";
  return Cn.make([
              containerStyle,
              isOpen ? "block" : "hidden",
              containerStyle
            ]);
}

var responsiveLeftButton = "block lg:hidden w-5 h-5 rounded hover:border-gray-400 hover:text-prezzee-red focus:outline-none";

var responsiveRightButton = "block w-8 rounded hover:border-prezzee-red hover:text-prezzee-red focus:outline-none";

var navBarLink = "text-mid-gray lg:border-t-3 border-white hover:text-prezzee-red hover:border-prezzee-red flex items-center justify-between focus:text-prezzee-red focus:border-prezzee-red active:text-prezzee-red active:border-prezzee-red h-11 lg:h-full transition-all duration-500 ease-in-out bg-white px-4 lg:px-0 md:text-sm xl:text-base";

var linkText = "";

var grayLine = " border-gray-200 border-b w-full lg:hidden";

export {
  responsiveLeftButton ,
  responsiveRightButton ,
  navBarLinksContainer ,
  navBarLink ,
  linkText ,
  grayLine ,
}
/* No side effect */
