import { useLayoutData } from 'contexts/LayoutDataContext';
import { Nullable } from 'models/types';

type Props = {
  id?: string;
  itemId?: string; // NOTE: this value provides back compatibility with Rescript.
  codename?: string;
  button?: boolean;
  insertPosition?: 'start' | 'before' | 'after' | 'end';
  renderPosition?:
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end';
};

const useSmartLink = (elem: Props): Nullable<Record<string, string>> => {
  const layoutData = useLayoutData();
  const elemId: string = elem.id || elem.itemId;

  // kontentData field is present when ?editor-mode=inline argument is present
  if (layoutData.kontentData) {
    const dataProps = {};
    if (elem.codename) {
      dataProps['data-kontent-element-codename'] = elem.codename;
    }
    if (elemId) {
      dataProps['data-kontent-item-id'] = elemId;
    }

    if (elem.button) {
      dataProps['data-kontent-add-button'] = true;

      if (elem.insertPosition) {
        dataProps['data-kontent-add-button-insert-position'] = elem.insertPosition;
      }

      if (elem.renderPosition) {
        dataProps['data-kontent-add-button-render-position'] = elem.renderPosition;
      }
    }

    return dataProps;
  }

  return null;
};

export default useSmartLink;
