// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__OffsetTilesBlock__Decoder__Images from "./Gql__OffsetTilesBlock__Decoder__Images.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var background_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), undefined, (function (c) {
          if (c === "") {
            return ;
          } else {
            return c;
          }
        }));
  var tiles = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tiles, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__OffsetTilesBlock__Decoder__Images.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          background_colour: background_colour,
          tiles: tiles,
          smartLink: smartLink
        };
}

var ImageCollection;

var Images;

export {
  ImageCollection ,
  Images ,
  get ,
}
/* No side effect */
