import { camelKeys } from 'js-convert-case';
import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { MasqueradeResponse } from 'models/auth.model';

/**
 * Request to masquerade provided user.
 * @arg url masquerade endpoint
 * @arg uid user uid
 * @returns Promise with decoded token response.
 */
const masquerade = (url: string, uid: string): Promise<MasqueradeResponse> =>
  createRequest(url, {
    withAuth: true,
    withMagicUserAgent: true,
    method: 'POST',
    isUrl: true,
    body: JSON.stringify({
      masq_user: uid,
    }),
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as MasqueradeResponse);

export default masquerade;
