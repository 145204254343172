// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment TeamMembersBlock on TeamMembersBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    cards_per_row  {
      __typename
      value  {
        __typename
        codename
      }
    }
    team_member_cards  {
      __typename
      value  {
        __typename
        ...on TeamMemberCardContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          photo_front_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
          photo_back_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
          name  {
            __typename
            value
          }
          title  {
            __typename
            value
          }
          description  {
            __typename
            value
          }
          link  {
            __typename
            value
          }
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.cards_per_row;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$5) : undefined
    };
  }
  var value$6 = value.team_member_cards;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "TeamMemberCardContentType") {
                  var value$1 = value.system;
                  var value$2 = value.photo_front_asset;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$3) : undefined
                    };
                  }
                  var value$4 = value.photo_back_asset;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$5) : undefined
                    };
                  }
                  var value$6 = value.name;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.title;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? value$9 : undefined
                    };
                  }
                  var value$10 = value.description;
                  var tmp$5;
                  if (value$10 == null) {
                    tmp$5 = undefined;
                  } else {
                    var value$11 = value$10.value;
                    tmp$5 = {
                      __typename: value$10.__typename,
                      value: !(value$11 == null) ? value$11 : undefined
                    };
                  }
                  var value$12 = value.link;
                  var tmp$6;
                  if (value$12 == null) {
                    tmp$6 = undefined;
                  } else {
                    var value$13 = value$12.value;
                    tmp$6 = {
                      __typename: value$12.__typename,
                      value: !(value$13 == null) ? value$13 : undefined
                    };
                  }
                  tmp = {
                    NAME: "TeamMemberCardContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      photo_front_asset: tmp$1,
                      photo_back_asset: tmp$2,
                      name: tmp$3,
                      title: tmp$4,
                      description: tmp$5,
                      link: tmp$6
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$7) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          cards_per_row: tmp$1,
          team_member_cards: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.team_member_cards;
  var team_member_cards;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.link;
              var link;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                link = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                link = null;
              }
              var value$6 = value$1.description;
              var description;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                description = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                description = null;
              }
              var value$10 = value$1.title;
              var title;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                title = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                title = null;
              }
              var value$14 = value$1.name;
              var name;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? value$15 : null;
                var value$17 = value$14.__typename;
                name = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                name = null;
              }
              var value$18 = value$1.photo_back_asset;
              var photo_back_asset;
              if (value$18 !== undefined) {
                var value$19 = value$18.value;
                var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$19) : null;
                var value$21 = value$18.__typename;
                photo_back_asset = {
                  __typename: value$21,
                  value: value$20
                };
              } else {
                photo_back_asset = null;
              }
              var value$22 = value$1.photo_front_asset;
              var photo_front_asset;
              if (value$22 !== undefined) {
                var value$23 = value$22.value;
                var value$24 = value$23 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$23) : null;
                var value$25 = value$22.__typename;
                photo_front_asset = {
                  __typename: value$25,
                  value: value$24
                };
              } else {
                photo_front_asset = null;
              }
              var value$26 = value$1.system;
              var value$27 = value$26.codename;
              var value$28 = value$26.id;
              var value$29 = value$26.__typename;
              var system = {
                __typename: value$29,
                id: value$28,
                codename: value$27
              };
              return {
                      __typename: "TeamMemberCardContentType",
                      system: system,
                      photo_front_asset: photo_front_asset,
                      photo_back_asset: photo_back_asset,
                      name: name,
                      title: title,
                      description: description,
                      link: link
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    team_member_cards = {
      __typename: value$4,
      value: value$3
    };
  } else {
    team_member_cards = null;
  }
  var value$5 = value.cards_per_row;
  var cards_per_row;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    cards_per_row = {
      __typename: value$8,
      value: value$7
    };
  } else {
    cards_per_row = null;
  }
  var value$9 = value.title;
  var title;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    title = {
      __typename: value$12,
      value: value$11
    };
  } else {
    title = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.codename;
  var value$15 = value$13.id;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    id: value$15,
    codename: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          title: title,
          cards_per_row: cards_per_row,
          team_member_cards: team_member_cards
        };
}

function verifyArgsAndParse(_TeamMembersBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var TeamMembersBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

export {
  $$Image ,
  TeamMembersBlock ,
}
/* query Not a pure module */
