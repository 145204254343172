import colors from '@prezzee/ribbon-ui/lib/theme/foundations/colors';
import sizes from '@prezzee/ribbon-ui/lib/theme/foundations/sizes';
import breakpoints from '@prezzee/ribbon-ui/lib/theme/foundations/breakpoints';

const theme = {
  breakpoints: {
    ...breakpoints,
    xl: '1280px',
    '2xl': breakpoints.xl,
    '3xl': breakpoints['2xl'],
  },
  sizes: {
    container: {
      ...sizes.container,
    },
  },
  components: {
    Menu: {
      variants: {
        filter: {
          groupTitle: {
            fontSize: 'md',
            position: 'sticky',
            zIndex: 1,
            backgroundColor: 'white',
            top: 0,
            left: 0,
            margin: 0,
            padding: 4,
          },
          list: {
            paddingTop: 0,
            paddingBottom: 4,
          },
          item: {
            paddingX: 4,
            paddingY: 2,
            fontSize: 'sm',
          },
        },
      },
    },
    Button: {
      variants: {
        link: {
          fontWeight: 400,
        },
      },
    },
    Tabs: {
      variants: {
        'soft-rounded': {
          tablist: {
            border: `1px solid ${colors.gray[300]}`,
            padding: 1,
            rounded: 'full',
          },
          tab: {
            padding: 3,
          },
        },
      },
    },
  },
};

export default theme;
