// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Regex from "./Regex.bs.js";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";

function mapElement(text, prefix, param) {
  var spaceRegex = /\s+/g;
  var elemPrefix = prefix !== undefined ? [prefix] : [];
  return Js_array.concat(text.toLowerCase().replace(Regex.specialRegex, "").split(spaceRegex), elemPrefix).join("_");
}

var SegmentUtils = {
  mapElement: mapElement
};

export {
  SegmentUtils ,
}
/* No side effect */
