// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as LayoutDataContext from "../../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";

function KontentContextProvider(props) {
  var render = props.render;
  var elementCodename = props.elementCodename;
  var itemId = props.itemId;
  var children = props.children;
  var itemId$1 = itemId !== undefined ? itemId : "";
  var elementCodename$1 = elementCodename !== undefined ? elementCodename : "";
  var render$1 = render !== undefined ? render : true;
  if (!render$1) {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {
                children: Caml_option.some(children)
              });
  }
  if (itemId$1 === "") {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {
                children: Caml_option.some(children)
              });
  }
  var state = LayoutDataContext.useContext(undefined);
  var kontentData = Belt_Option.flatMap(state, (function (s) {
          return s.kontentData;
        }));
  if (kontentData !== undefined) {
    return JsxRuntime.jsx(Kontent$PrezzeeRibbon.Context.Provider.make, {
                children: children,
                itemId: itemId$1,
                elementCodename: elementCodename$1
              });
  } else {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {
                children: Caml_option.some(children)
              });
  }
}

var make = KontentContextProvider;

export {
  make ,
}
/* LayoutDataContext Not a pure module */
