// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__SeoMeta__Fragment from "../SeoMetaContentType/Gql__SeoMeta__Fragment.bs.js";
import * as Gql__EnrichedTextBlock__Fragment from "../EnrichedTextBlock/Gql__EnrichedTextBlock__Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  query PageContent($language: String!, $preview: Boolean, $codename: String!)  {
    itemsByType(type: "store_category", limit: 1, depth: 5, language: $language, preview: $preview, filters: [{type: EQUALS, prop: "system.codename", val: $codename}])  {
      __typename
      ...on StoreCategoryContentType   {
        __typename
        system  {
          __typename
          id
          codename
        }
        slug  {
          __typename
          value
        }
        display_name  {
          __typename
          value
        }
        page_top_content  {
          __typename
          value  {
            __typename
            ...on EnrichedTextBlockContentType   {
              ...EnrichedTextBlock
            }
          }
        }
        seo_meta  {
          __typename
          value  {
            __typename
            ...on SeoMetaContentType   {
              ...SeoMetaBlock
            }
          }
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.query, Gql__SeoMeta__Fragment.SeoMetaBlock.query);

function parse(value) {
  var value$1 = value.itemsByType;
  return {
          itemsByType: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "StoreCategoryContentType") {
                      var value$1 = value.system;
                      var value$2 = value.slug;
                      var tmp$1;
                      if (value$2 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$3 = value$2.value;
                        tmp$1 = {
                          __typename: value$2.__typename,
                          value: !(value$3 == null) ? value$3 : undefined
                        };
                      }
                      var value$4 = value.display_name;
                      var tmp$2;
                      if (value$4 == null) {
                        tmp$2 = undefined;
                      } else {
                        var value$5 = value$4.value;
                        tmp$2 = {
                          __typename: value$4.__typename,
                          value: !(value$5 == null) ? value$5 : undefined
                        };
                      }
                      var value$6 = value.page_top_content;
                      var tmp$3;
                      if (value$6 == null) {
                        tmp$3 = undefined;
                      } else {
                        var value$7 = value$6.value;
                        tmp$3 = {
                          __typename: value$6.__typename,
                          value: !(value$7 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "EnrichedTextBlockContentType" ? ({
                                          NAME: "EnrichedTextBlockContentType",
                                          VAL: {
                                            enrichedTextBlock: Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.verifyArgsAndParse("EnrichedTextBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$7) : undefined
                        };
                      }
                      var value$8 = value.seo_meta;
                      var tmp$4;
                      if (value$8 == null) {
                        tmp$4 = undefined;
                      } else {
                        var value$9 = value$8.value;
                        tmp$4 = {
                          __typename: value$8.__typename,
                          value: !(value$9 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "SeoMetaContentType" ? ({
                                          NAME: "SeoMetaContentType",
                                          VAL: {
                                            seoMetaBlock: Gql__SeoMeta__Fragment.SeoMetaBlock.verifyArgsAndParse("SeoMetaBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$9) : undefined
                        };
                      }
                      tmp = {
                        NAME: "StoreCategoryContentType",
                        VAL: {
                          __typename: value.__typename,
                          system: {
                            __typename: value$1.__typename,
                            id: value$1.id,
                            codename: value$1.codename
                          },
                          slug: tmp$1,
                          display_name: tmp$2,
                          page_top_content: tmp$3,
                          seo_meta: tmp$4
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.itemsByType;
  var itemsByType = value$1 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME === "UnspecifiedFragment") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.seo_meta;
            var seo_meta;
            if (value$2 !== undefined) {
              var value$3 = value$2.value;
              var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "SeoMetaContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__SeoMeta__Fragment.SeoMetaBlock.serialize(value.VAL.seoMetaBlock)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$3) : null;
              var value$5 = value$2.__typename;
              seo_meta = {
                __typename: value$5,
                value: value$4
              };
            } else {
              seo_meta = null;
            }
            var value$6 = value$1.page_top_content;
            var page_top_content;
            if (value$6 !== undefined) {
              var value$7 = value$6.value;
              var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "EnrichedTextBlockContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.serialize(value.VAL.enrichedTextBlock)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$7) : null;
              var value$9 = value$6.__typename;
              page_top_content = {
                __typename: value$9,
                value: value$8
              };
            } else {
              page_top_content = null;
            }
            var value$10 = value$1.display_name;
            var display_name;
            if (value$10 !== undefined) {
              var value$11 = value$10.value;
              var value$12 = value$11 !== undefined ? value$11 : null;
              var value$13 = value$10.__typename;
              display_name = {
                __typename: value$13,
                value: value$12
              };
            } else {
              display_name = null;
            }
            var value$14 = value$1.slug;
            var slug;
            if (value$14 !== undefined) {
              var value$15 = value$14.value;
              var value$16 = value$15 !== undefined ? value$15 : null;
              var value$17 = value$14.__typename;
              slug = {
                __typename: value$17,
                value: value$16
              };
            } else {
              slug = null;
            }
            var value$18 = value$1.system;
            var value$19 = value$18.codename;
            var value$20 = value$18.id;
            var value$21 = value$18.__typename;
            var system = {
              __typename: value$21,
              id: value$20,
              codename: value$19
            };
            return {
                    __typename: "StoreCategoryContentType",
                    system: system,
                    slug: slug,
                    display_name: display_name,
                    page_top_content: page_top_content,
                    seo_meta: seo_meta
                  };
          }), value$1) : null;
  return {
          itemsByType: itemsByType
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined,
          codename: inp.codename
        };
}

function makeVariables(language, preview, codename, param) {
  return {
          language: language,
          preview: preview,
          codename: codename
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var EnrichedTextBlock;

var SeoMetaBlock;

export {
  EnrichedTextBlock ,
  SeoMetaBlock ,
  Query ,
}
/* query Not a pure module */
