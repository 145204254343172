// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  if (typeof c !== "object") {
    return ;
  }
  if (c.NAME !== "GiftLandingPageThemeContentType") {
    return ;
  }
  var c$1 = c.VAL;
  var brandLogo = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.brand_logo, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), null, (function (f) {
          return {
                  description: Belt_Option.getWithDefault(f.description, ""),
                  url: Belt_Option.getWithDefault(f.url, "")
                };
        }));
  var giftUnwrapLottie = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.gift_unwrap_lottie, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var giftUnwrapLottieMobileSize = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.gift_unwrap_lottie_mobile_size, (function (c) {
              return c.value;
            })), "");
  var giftUnwrapLottieSegments = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.gift_unwrap_lottie_segments, (function (c) {
              return c.value;
            })), "");
  var confettiLottie = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.confetti_lottie, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var desktopBackgroundImage = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.desktop_background_image, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var desktopBackgroundImageSize = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.desktop_background_image_size, (function (c) {
              return c.value;
            })), "");
  var mobileBackgroundImage = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.mobile_background_image, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var mobileBackgroundImageSize = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.mobile_background_image_size, (function (c) {
              return c.value;
            })), "");
  return {
          brandLogo: brandLogo,
          giftUnwrapLottie: giftUnwrapLottie,
          giftUnwrapLottieMobileSize: giftUnwrapLottieMobileSize,
          giftUnwrapLottieSegments: giftUnwrapLottieSegments,
          confettiLottie: confettiLottie,
          desktopBackgroundImage: desktopBackgroundImage,
          desktopBackgroundImageSize: desktopBackgroundImageSize,
          mobileBackgroundImage: mobileBackgroundImage,
          mobileBackgroundImageSize: mobileBackgroundImageSize
        };
}

var GiftLandingPageTheme = {
  get: get
};

function decode(gqlQueryResult) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.itemsByType, []), 0), GqlUtils.id), get);
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  Pino.logger.error({
        err: result._0,
        type: "Gift Landing Page Theme decoder"
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

export {
  GiftLandingPageTheme ,
  Decoder ,
}
/* Pino Not a pure module */
