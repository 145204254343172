// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../common/util.bs.js";
import * as $$Array from "@rescript/std/lib/es6/array.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_SortArray from "@rescript/std/lib/es6/belt_SortArray.js";

var usa_screens = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl"
];

var usa = {
  name: "USA",
  position: 1,
  slug: "us-cards",
  cms_id: "",
  screens: usa_screens,
  img: "/images/categories/usa.png"
};

var nz_screens = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl"
];

var nz = {
  name: "NZ",
  position: 2,
  slug: "nz-cards",
  cms_id: "",
  screens: nz_screens,
  img: "/images/categories/nz.png"
};

var uk_screens = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl"
];

var uk = {
  name: "UK",
  position: 3,
  slug: "uk-cards",
  cms_id: "",
  screens: uk_screens,
  img: "/images/categories/uk.png"
};

var au_screens = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl"
];

var au = {
  name: "AU",
  position: 4,
  slug: "au-cards",
  cms_id: "",
  screens: au_screens,
  img: "/images/categories/au.png"
};

var all_screens = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl"
];

var all = {
  name: "Show All",
  position: 200,
  slug: "all",
  cms_id: "",
  screens: all_screens,
  img: "/images/icon_all.svg"
};

var more_screens = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl"
];

var more = {
  name: "More",
  position: 100,
  slug: "more",
  cms_id: "",
  screens: more_screens,
  img: "/images/categories/more.svg"
};

function getScreensForCategory(position) {
  if ((position + 1 | 0) <= 2) {
    return [
            "sm",
            "md",
            "lg",
            "xl",
            "xxl"
          ];
  } else if ((position + 1 | 0) === 3) {
    return [
            "md",
            "lg",
            "xl",
            "xxl"
          ];
  } else if ((position + 1 | 0) <= 6) {
    return [
            "lg",
            "xl",
            "xxl"
          ];
  } else if ((position + 1 | 0) <= 9) {
    return [
            "xl",
            "xxl"
          ];
  } else {
    return ["xxl"];
  }
}

function categoryDataToCategoryConf(cData) {
  return {
          name: cData.name,
          position: cData.position,
          slug: cData.slug,
          cms_id: cData.cms_id,
          screens: getScreensForCategory(cData.position),
          img: cData.image
        };
}

var countriesWithCountryCat = [
  "UK Cards",
  "NZ Cards",
  "US Cards",
  "AU Cards"
];

function catDataToCatConf(cats) {
  var cats$1 = Belt_Array.keep(Belt_Array.map(cats, categoryDataToCategoryConf), (function (c) {
          return !Belt_Array.some(countriesWithCountryCat, (function (country) {
                        return country === c.name;
                      }));
        }));
  Belt_SortArray.stableSortInPlaceBy(cats$1, (function (x, y) {
          return x.position - y.position | 0;
        }));
  return Belt_Array.concat([all], cats$1);
}

function toCategoriesConf(categoriesData) {
  return catDataToCatConf(categoriesData.results);
}

var match = Util.EnvUtil.currentCountry;

var categoriesByCountry;

if (match.TAG === /* Ok */0) {
  switch (match._0) {
    case /* Australia */0 :
        categoriesByCountry = [
          usa,
          nz,
          uk
        ];
        break;
    case /* GreatBritain */1 :
        categoriesByCountry = [
          au,
          usa,
          nz
        ];
        break;
    case /* UnitedStates */2 :
        categoriesByCountry = [
          au,
          uk,
          nz
        ];
        break;
    case /* NewZealand */3 :
        categoriesByCountry = [
          au,
          usa,
          uk
        ];
        break;
    
  }
} else {
  categoriesByCountry = [
    usa,
    nz,
    uk
  ];
}

function slugs(categoriesByProductType) {
  return Belt_Array.map(Belt_Array.keep($$Array.append(categoriesByProductType, categoriesByCountry), (function (c) {
                    return c.slug !== "all";
                  })), (function (c) {
                return c.slug;
              }));
}

function getCategoryBySlug(slug, categoriesByProductType) {
  return Belt_Array.get(Belt_Array.keep($$Array.append(categoriesByProductType, categoriesByCountry), (function (c) {
                    return c.slug === slug;
                  })), 0);
}

function getCategoryCodename(categories, slug) {
  var item = getCategoryBySlug(slug, categories);
  if (item !== undefined) {
    return item.cms_id;
  }
  
}

export {
  usa ,
  nz ,
  uk ,
  au ,
  all ,
  more ,
  getScreensForCategory ,
  categoryDataToCategoryConf ,
  countriesWithCountryCat ,
  catDataToCatConf ,
  toCategoriesConf ,
  categoriesByCountry ,
  slugs ,
  getCategoryBySlug ,
  getCategoryCodename ,
}
/* categoriesByCountry Not a pure module */
