// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment HeaderNavigation on AppContentType   {
    __typename
    subpages_subviews  {
      __typename
      value  {
        __typename
        ...on HeaderNavigationContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          label  {
            __typename
            value
          }
          url  {
            __typename
            value
          }
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.subpages_subviews;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.value;
    tmp = {
      __typename: value$1.__typename,
      value: !(value$2 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "HeaderNavigationContentType") {
                  var value$1 = value.system;
                  var value$2 = value.label;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.url;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  tmp = {
                    NAME: "HeaderNavigationContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      label: tmp$1,
                      url: tmp$2
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$2) : undefined
    };
  }
  return {
          __typename: value.__typename,
          subpages_subviews: tmp
        };
}

function serialize(value) {
  var value$1 = value.subpages_subviews;
  var subpages_subviews;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.url;
              var url;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                url = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                url = null;
              }
              var value$6 = value$1.label;
              var label;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                label = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                label = null;
              }
              var value$10 = value$1.system;
              var value$11 = value$10.codename;
              var value$12 = value$10.id;
              var value$13 = value$10.__typename;
              var system = {
                __typename: value$13,
                id: value$12,
                codename: value$11
              };
              return {
                      __typename: "HeaderNavigationContentType",
                      system: system,
                      label: label,
                      url: url
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    subpages_subviews = {
      __typename: value$4,
      value: value$3
    };
  } else {
    subpages_subviews = null;
  }
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          subpages_subviews: subpages_subviews
        };
}

function verifyArgsAndParse(_HeaderNavigation, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var HeaderNavigation = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  HeaderNavigation ,
}
/* query Not a pure module */
