import { WebConfig } from 'models/webConfig.model';
import { ReactNode, createContext, useContext } from 'react';

export const ConfigContext = createContext<WebConfig>(null);

export const ConfigProvider = ({ children, value }: { children: ReactNode; value: WebConfig }) => (
  <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
);

export const useConfig = () => {
  const ctx = useContext(ConfigContext);

  return ctx;
};
