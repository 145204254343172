// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "PageContentType") {
    return ;
  }
  var subpage = content.VAL;
  var showInNavigation = Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(subpage.show_in_navigation, (function (p) {
                              return p.value;
                            })), []), GqlUtils.id), 0), (function (x) {
              return x.name === "Yes";
            })), false);
  if (!showInNavigation) {
    return ;
  }
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(subpage.title, (function (t) {
              return t.value;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(subpage.url, (function (u) {
              return u.value;
            })), "");
  var smartLink_itemId = subpage.system.id;
  var smartLink_codename = subpage.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          url: url,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
