// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var initValue_0 = {
  notificaitonState: undefined
};

function initValue_1(param) {
  
}

var initValue = [
  initValue_0,
  initValue_1
];

var context = React.createContext(initValue);

function useContext(param) {
  return React.useContext(context);
}

function reducer(param, action) {
  if (typeof action === "number") {
    return {
            notificaitonState: undefined
          };
  }
  if (action.TAG === /* SetSuccessNotification */0) {
    var match = action._0;
    return {
            notificaitonState: {
              title: match.title,
              description: match.description,
              notificationType: /* NotificationSuccess */2
            }
          };
  }
  var match$1 = action._0;
  return {
          notificaitonState: {
            title: match$1.title,
            description: match$1.description,
            notificationType: /* NotificationError */0
          }
        };
}

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var MyAccountContextProvider = {
  makeProps: makeProps,
  make: make
};

var initialState = {
  notificaitonState: undefined
};

export {
  initialState ,
  initValue ,
  context ,
  useContext ,
  reducer ,
  MyAccountContextProvider ,
}
/* context Not a pure module */
