// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment IconCardBlock on IconCardBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    theme  {
      __typename
      value  {
        __typename
        codename
      }
    }
    background_colour  {
      __typename
      value
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    icon_cards  {
      __typename
      value  {
        __typename
        ...on IconCardContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          title  {
            __typename
            value
          }
          description  {
            __typename
            value
          }
          url  {
            __typename
            value
          }
          open_in_new_window  {
            __typename
            value  {
              __typename
              codename
            }
          }
          icon_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
          icon_size  {
            __typename
            value  {
              __typename
              codename
            }
          }
          title_colour  {
            __typename
            value
          }
          text_colour  {
            __typename
            value
          }
          background_colour  {
            __typename
            value
          }
          content_alignment  {
            __typename
            value  {
              __typename
              codename
            }
          }
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.theme;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.background_colour;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.title;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.description;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.icon_cards;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "IconCardContentType") {
                  var value$1 = value.system;
                  var value$2 = value.title;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.description;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.url;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.open_in_new_window;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$9) : undefined
                    };
                  }
                  var value$10 = value.icon_asset;
                  var tmp$5;
                  if (value$10 == null) {
                    tmp$5 = undefined;
                  } else {
                    var value$11 = value$10.value;
                    tmp$5 = {
                      __typename: value$10.__typename,
                      value: !(value$11 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$11) : undefined
                    };
                  }
                  var value$12 = value.icon_size;
                  var tmp$6;
                  if (value$12 == null) {
                    tmp$6 = undefined;
                  } else {
                    var value$13 = value$12.value;
                    tmp$6 = {
                      __typename: value$12.__typename,
                      value: !(value$13 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$13) : undefined
                    };
                  }
                  var value$14 = value.title_colour;
                  var tmp$7;
                  if (value$14 == null) {
                    tmp$7 = undefined;
                  } else {
                    var value$15 = value$14.value;
                    tmp$7 = {
                      __typename: value$14.__typename,
                      value: !(value$15 == null) ? value$15 : undefined
                    };
                  }
                  var value$16 = value.text_colour;
                  var tmp$8;
                  if (value$16 == null) {
                    tmp$8 = undefined;
                  } else {
                    var value$17 = value$16.value;
                    tmp$8 = {
                      __typename: value$16.__typename,
                      value: !(value$17 == null) ? value$17 : undefined
                    };
                  }
                  var value$18 = value.background_colour;
                  var tmp$9;
                  if (value$18 == null) {
                    tmp$9 = undefined;
                  } else {
                    var value$19 = value$18.value;
                    tmp$9 = {
                      __typename: value$18.__typename,
                      value: !(value$19 == null) ? value$19 : undefined
                    };
                  }
                  var value$20 = value.content_alignment;
                  var tmp$10;
                  if (value$20 == null) {
                    tmp$10 = undefined;
                  } else {
                    var value$21 = value$20.value;
                    tmp$10 = {
                      __typename: value$20.__typename,
                      value: !(value$21 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.codename;
                                return {
                                        __typename: value.__typename,
                                        codename: !(value$1 == null) ? value$1 : undefined
                                      };
                              }), value$21) : undefined
                    };
                  }
                  tmp = {
                    NAME: "IconCardContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      title: tmp$1,
                      description: tmp$2,
                      url: tmp$3,
                      open_in_new_window: tmp$4,
                      icon_asset: tmp$5,
                      icon_size: tmp$6,
                      title_colour: tmp$7,
                      text_colour: tmp$8,
                      background_colour: tmp$9,
                      content_alignment: tmp$10
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$11) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          theme: tmp,
          background_colour: tmp$1,
          title: tmp$2,
          description: tmp$3,
          icon_cards: tmp$4
        };
}

function serialize(value) {
  var value$1 = value.icon_cards;
  var icon_cards;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.content_alignment;
              var content_alignment;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                content_alignment = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                content_alignment = null;
              }
              var value$6 = value$1.background_colour;
              var background_colour;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                background_colour = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                background_colour = null;
              }
              var value$10 = value$1.text_colour;
              var text_colour;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                text_colour = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                text_colour = null;
              }
              var value$14 = value$1.title_colour;
              var title_colour;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? value$15 : null;
                var value$17 = value$14.__typename;
                title_colour = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                title_colour = null;
              }
              var value$18 = value$1.icon_size;
              var icon_size;
              if (value$18 !== undefined) {
                var value$19 = value$18.value;
                var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$19) : null;
                var value$21 = value$18.__typename;
                icon_size = {
                  __typename: value$21,
                  value: value$20
                };
              } else {
                icon_size = null;
              }
              var value$22 = value$1.icon_asset;
              var icon_asset;
              if (value$22 !== undefined) {
                var value$23 = value$22.value;
                var value$24 = value$23 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$23) : null;
                var value$25 = value$22.__typename;
                icon_asset = {
                  __typename: value$25,
                  value: value$24
                };
              } else {
                icon_asset = null;
              }
              var value$26 = value$1.open_in_new_window;
              var open_in_new_window;
              if (value$26 !== undefined) {
                var value$27 = value$26.value;
                var value$28 = value$27 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.codename;
                          var codename = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  codename: codename
                                };
                        }), value$27) : null;
                var value$29 = value$26.__typename;
                open_in_new_window = {
                  __typename: value$29,
                  value: value$28
                };
              } else {
                open_in_new_window = null;
              }
              var value$30 = value$1.url;
              var url;
              if (value$30 !== undefined) {
                var value$31 = value$30.value;
                var value$32 = value$31 !== undefined ? value$31 : null;
                var value$33 = value$30.__typename;
                url = {
                  __typename: value$33,
                  value: value$32
                };
              } else {
                url = null;
              }
              var value$34 = value$1.description;
              var description;
              if (value$34 !== undefined) {
                var value$35 = value$34.value;
                var value$36 = value$35 !== undefined ? value$35 : null;
                var value$37 = value$34.__typename;
                description = {
                  __typename: value$37,
                  value: value$36
                };
              } else {
                description = null;
              }
              var value$38 = value$1.title;
              var title;
              if (value$38 !== undefined) {
                var value$39 = value$38.value;
                var value$40 = value$39 !== undefined ? value$39 : null;
                var value$41 = value$38.__typename;
                title = {
                  __typename: value$41,
                  value: value$40
                };
              } else {
                title = null;
              }
              var value$42 = value$1.system;
              var value$43 = value$42.codename;
              var value$44 = value$42.id;
              var value$45 = value$42.__typename;
              var system = {
                __typename: value$45,
                id: value$44,
                codename: value$43
              };
              return {
                      __typename: "IconCardContentType",
                      system: system,
                      title: title,
                      description: description,
                      url: url,
                      open_in_new_window: open_in_new_window,
                      icon_asset: icon_asset,
                      icon_size: icon_size,
                      title_colour: title_colour,
                      text_colour: text_colour,
                      background_colour: background_colour,
                      content_alignment: content_alignment
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    icon_cards = {
      __typename: value$4,
      value: value$3
    };
  } else {
    icon_cards = null;
  }
  var value$5 = value.description;
  var description;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    description = {
      __typename: value$8,
      value: value$7
    };
  } else {
    description = null;
  }
  var value$9 = value.title;
  var title;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    title = {
      __typename: value$12,
      value: value$11
    };
  } else {
    title = null;
  }
  var value$13 = value.background_colour;
  var background_colour;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    background_colour = {
      __typename: value$16,
      value: value$15
    };
  } else {
    background_colour = null;
  }
  var value$17 = value.theme;
  var theme;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    theme = {
      __typename: value$20,
      value: value$19
    };
  } else {
    theme = null;
  }
  var value$21 = value.system;
  var value$22 = value$21.codename;
  var value$23 = value$21.id;
  var value$24 = value$21.__typename;
  var system = {
    __typename: value$24,
    id: value$23,
    codename: value$22
  };
  var value$25 = value.__typename;
  return {
          __typename: value$25,
          system: system,
          theme: theme,
          background_colour: background_colour,
          title: title,
          description: description,
          icon_cards: icon_cards
        };
}

function verifyArgsAndParse(_IconCardBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var IconCardBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Icon;

var FontAwesomeIcon;

var CardIcon;

export {
  Icon ,
  FontAwesomeIcon ,
  CardIcon ,
  IconCardBlock ,
}
/* query Not a pure module */
