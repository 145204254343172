// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function getButton(contents) {
  if (typeof contents === "object" && contents.NAME === "CallToActionLinkD6a3d61ContentType") {
    return Gql__CallToAction__Decoder.get(contents.VAL.callToAction);
  }
  
}

function get(c) {
  var uid = c.system.id;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var subtitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c.subtitle, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var button = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.button, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), getButton);
  var background = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "white");
  var skuList = Belt_Option.getWithDefault(Belt_Option.flatMap(c.sku_list, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          uid: uid,
          title: title,
          subtitle: subtitle,
          description: description,
          button: button,
          background: background,
          skuList: skuList,
          smartLink: smartLink
        };
}

export {
  getButton ,
  get ,
}
/* No side effect */
