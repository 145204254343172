// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FontAwesomeIcon__Decoder from "../FontAwesomeIcon/Gql__FontAwesomeIcon__Decoder.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "TheGiftExperienceListItemContentType") {
    return ;
  }
  var c = content.VAL;
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var icon = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.fontawesome_icon, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (f) {
              if (typeof f === "object" && f.NAME === "FontAwesomeContentType") {
                return Gql__FontAwesomeIcon__Decoder.get(f.VAL.fontAwesomeIcon);
              }
              
            })), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          icon: icon,
          description: description,
          smartLink: smartLink
        };
}

var FontAwesomeIcon;

export {
  FontAwesomeIcon ,
  get ,
}
/* No side effect */
