// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment FontAwesomeIcon on FontAwesomeContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    font_awesome_icon_name  {
      __typename
      value
    }
    style  {
      __typename
      value  {
        __typename
        codename
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.font_awesome_icon_name;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.style;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$5) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          font_awesome_icon_name: tmp,
          style: tmp$1
        };
}

function serialize(value) {
  var value$1 = value.style;
  var style;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    style = {
      __typename: value$4,
      value: value$3
    };
  } else {
    style = null;
  }
  var value$5 = value.font_awesome_icon_name;
  var font_awesome_icon_name;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    font_awesome_icon_name = {
      __typename: value$8,
      value: value$7
    };
  } else {
    font_awesome_icon_name = null;
  }
  var value$9 = value.system;
  var value$10 = value$9.codename;
  var value$11 = value$9.id;
  var value$12 = value$9.__typename;
  var system = {
    __typename: value$12,
    id: value$11,
    codename: value$10
  };
  var value$13 = value.__typename;
  return {
          __typename: value$13,
          system: system,
          font_awesome_icon_name: font_awesome_icon_name,
          style: style
        };
}

function verifyArgsAndParse(_FontAwesomeIcon, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var FontAwesomeIcon = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  FontAwesomeIcon ,
}
/* query Not a pure module */
