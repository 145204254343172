// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__StatsTiles__Decoder__StatsTile from "./Gql__StatsTiles__Decoder__StatsTile.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var stats_tiles = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.environmental_impact_cards, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__StatsTiles__Decoder__StatsTile.get);
  return {
          title: title,
          description: description,
          stats_tiles: stats_tiles
        };
}

var StatsTile;

export {
  StatsTile ,
  get ,
}
/* No side effect */
