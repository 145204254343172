import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { RegisterNewUser } from 'common/types';
import { camelKeys, snakeKeys } from 'js-convert-case';

export const registerErrors = {
  signUpDisabled:
    'Sign up is currently disabled. Please sign in using your existing account or contact us at help@prezzee.com.au or +61 2 9093 2777.',
  emailExists: 'Sorry, we already have an account registered with that email address.',
  phoneExists:
    'Sorry, we already have an account registered with that phone number. Please sign in with your existing account or contact us for further assistance.',
  incorrectSecurityCode: 'Incorrect PIN. Please check your SMS and try again.',
  commonPw: 'This password is too common.',
};

interface RegisterResponse {
  accessToken: string;
}

/**
 * @secure
 * Request to register a user.
 * @param registerPayload Object containing all required information to register a user
 * @returns Decoded promise of register API response.
 */
const register = (registerPayload: RegisterNewUser) => {
  return createRequest('api/v3/register-by-email-password/email/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    withSecurityToken: true,
    body: JSON.stringify(snakeKeys(registerPayload)),
  })
    .then(handleErrors)
    .then<RegisterResponse>(data => camelKeys(data, { recursive: true }) as RegisterResponse);
};

export default register;
