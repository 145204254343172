// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../common/util.bs.js";
import * as GiftConf from "../config/GiftConf.bs.js";
import * as Segment from "./segment";
import * as AuthUtils from "../auth/user/AuthUtils.bs.js";
import * as Js_string from "@rescript/std/lib/es6/js_string.js";
import * as CryptoJs from "crypto-js";
import * as JsCookie from "js-cookie";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as RegexTsx from "../utils/Regex.tsx";
import * as ReactDeviceDetect from "react-device-detect";

var writeKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;

function scriptNotLoaded(prim) {
  return Segment.scriptNotLoaded();
}

function debug(prim) {
  Segment.debug(prim);
}

function identify(prim0, prim1, prim2) {
  Segment.identify(prim0, prim1, prim2);
}

function track(prim0, prim1, prim2) {
  Segment.track(prim0, prim1, prim2);
}

function trackAsync(prim0, prim1, prim2) {
  return Segment.trackAsync(prim0, prim1, prim2);
}

function page(prim0, prim1, prim2, prim3) {
  Segment.page(prim0 !== undefined ? Caml_option.valFromOption(prim0) : undefined, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined, prim2, prim3);
}

function group(prim0, prim1) {
  Segment.group(prim0, prim1);
}

function reset(prim) {
  Segment.reset();
}

function setAnonymousId(prim) {
  Segment.setAnonymousId(prim);
}

function hash(prim) {
  return Segment.hash(prim);
}

function brazeAlias(prim) {
  Segment.brazeAlias();
}

var linuxOrWindows = RegexTsx.linuxOrWindows;

var contextE = {
  server_region: process.env.NEXT_PUBLIC_APP_COUNTRY_CODE
};

var consumerStore = {
  store: "consumer"
};

var businessStore = {
  store: "business"
};

var userType = {
  user_type: "consumer"
};

var niE = {
  nonInteraction: 1,
  store: "consumer"
};

function withGaClientId(props) {
  return Object.assign(props, {
              ga_clientid: Js_string.sliceToEnd(6, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(JsCookie.get("_ga")), ""))
            });
}

function setContext(storeTypeOpt, c) {
  var storeType = storeTypeOpt !== undefined ? Caml_option.valFromOption(storeTypeOpt) : consumerStore;
  var user = AuthUtils.getFullUserInfo(undefined);
  var deviceInfo = ReactDeviceDetect.parseUserAgent(navigator.userAgent);
  var deviceName = deviceInfo.os.name;
  var deviceType = Belt_Option.getWithDefault(deviceInfo.device.type, "desktop");
  var userTraits = {
    traits: {
      email: Belt_Option.map(user, (function (u) {
              return u.email;
            })),
      phone: Belt_Option.map(user, (function (u) {
              return u.phoneNumber;
            }))
    }
  };
  var deviceInfo$1 = {
    device: {
      name: deviceType.concat(" ", deviceName),
      type: deviceType
    }
  };
  var contextStoreRegion = Object.assign(Object.assign({}, contextE), storeType);
  return Object.assign(Object.assign(Object.assign(userTraits, deviceInfo$1), contextStoreRegion), c);
}

function identifyWithContext(userId, props, isPb) {
  var enriched = Object.assign(props, contextE);
  var storeType = isPb ? businessStore : consumerStore;
  var context = setContext(Caml_option.some(storeType), {});
  identify(userId, enriched, context);
}

function trackWithContext($$event, props, ctx) {
  var isPb = document.location.pathname.startsWith(Util.Business.pathname);
  var currentStoreType = isPb ? businessStore : consumerStore;
  var enriched = Object.assign(Object.assign(props, contextE), currentStoreType);
  var context = setContext(Caml_option.some(currentStoreType), ctx);
  track($$event, enriched, context);
}

function trackWithContextAsync($$event, props, ctx) {
  var isPb = document.location.pathname.startsWith(Util.Business.pathname);
  var currentStoreType = isPb ? businessStore : consumerStore;
  var enriched = Object.assign(Object.assign(props, contextE), currentStoreType);
  var context = setContext(Caml_option.some(currentStoreType), Object.assign({}, ctx));
  return Segment.trackAsync($$event, enriched, context);
}

function cardToProduct(card) {
  return {
          product_id: card.uid,
          sku: card.slug,
          categories: Belt_Array.map(card.categories, (function (g) {
                  return g.name;
                })),
          category: Belt_Option.mapWithDefault(Belt_Array.get(card.categories, 0), "", (function (c) {
                  return c.name;
                })),
          name: card.displayName,
          quantity: 1,
          image_url: card.imgUrl
        };
}

function orderCardToProduct(card, pathname) {
  return {
          categories: card.categories,
          category: card.category,
          name: card.skuDisplayName,
          product_id: card.skuUid,
          sku: card.skuSlug,
          url: pathname
        };
}

function cardToProductWithIndex(index, card) {
  return Object.assign(cardToProduct(card), {
              position: index + 1 | 0
            });
}

function cardToProductNoImageUrl(card) {
  return {
          product_id: card.uid,
          sku: card.slug,
          categories: Belt_Array.map(card.categories, (function (g) {
                  return g.name;
                })),
          category: Belt_Option.mapWithDefault(Belt_Array.get(card.categories, 0), "", (function (c) {
                  return c.name;
                })),
          name: card.displayName,
          quantity: 1
        };
}

function versionControl(version) {
  return {
          context: {
            protocols: {
              event_version: version
            }
          }
        };
}

function productListViewed(props) {
  var niEnriched = Object.assign(props, niE);
  var versioned = versionControl(2);
  trackWithContext("Product List Viewed", niEnriched, versioned);
}

function productViewed(props) {
  var niEnriched = withGaClientId(Object.assign(props, niE));
  var versioned = versionControl(2);
  trackWithContext("Product Viewed", niEnriched, versioned);
}

function productClicked(props) {
  var versioned = versionControl(2);
  trackWithContext("Product Clicked", withGaClientId(props), versioned);
}

function productsSearched(props) {
  trackWithContext("Products Searched", withGaClientId(props), {});
}

function couponEntered(props) {
  var enriched = Object.assign(props, userType);
  trackWithContext("Coupon Entered", enriched, {});
}

function couponApplied(props) {
  var enriched = Object.assign(props, userType);
  trackWithContext("Coupon Applied", enriched, {});
}

function couponDenied(props) {
  var enriched = Object.assign(props, userType);
  trackWithContext("Coupon Denied", enriched, {});
}

function couponRemoved(props) {
  var enriched = Object.assign(props, userType);
  trackWithContext("Coupon Removed", enriched, {});
}

function signedUp(props) {
  trackWithContext("Signed Up", withGaClientId(props), {});
}

function signedIn(props) {
  trackWithContext("Signed In", withGaClientId(props), {});
}

function signedOut(props) {
  trackWithContext("Signed Out", props, {});
}

function orderCompleted(props, ctx) {
  var versioned = Object.assign(versionControl(2), ctx);
  trackWithContext("Order Completed", withGaClientId(props), versioned);
}

function productAdded(props) {
  var versioned = versionControl(2);
  trackWithContext("Product Added", withGaClientId(props), versioned);
}

function productRemoved(props) {
  var versioned = versionControl(2);
  trackWithContext("Product Removed", props, versioned);
}

function checkoutStarted(props) {
  var versioned = versionControl(2);
  trackWithContext("Checkout Started", props, versioned);
}

function checkoutStepViewed(props) {
  trackWithContext("Checkout Step Viewed", props, {});
}

function checkoutStepCompleted(props) {
  var versioned = versionControl(2);
  trackWithContext("Checkout Step Completed", props, versioned);
}

function customLinkTracking(props) {
  trackWithContext("Custom Link Tracking", props, {});
}

function giftCardViewed(id, ctx) {
  trackWithContext("Gift Card Viewed", {
        gift_id: id
      }, ctx);
}

function newsletterSubscribed(email) {
  trackWithContext("Newsletter Subscribed", {
        email: email
      }, {
        traits: {
          email: email
        }
      });
}

function hashPage(acceptCookieOpt, isPb) {
  var uidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  var storeType = isPb ? businessStore : consumerStore;
  var ctx = setContext(Caml_option.some(storeType), {});
  var match = GiftConf.giftPaths.find(function (el) {
        return document.location.pathname.startsWith(el);
      });
  if (match === undefined) {
    return page(undefined, undefined, ctx, ctx);
  }
  var uidStartAt = Js_string.search(uidRegex, document.location.pathname);
  var hashedUid = String(CryptoJs.SHA256(document.location.pathname.substring(uidStartAt, document.location.pathname.indexOf("/", uidStartAt))));
  var hashedPath = document.location.pathname.replace(uidRegex, hashedUid);
  var hashedPage = {
    path: hashedPath,
    url: document.location.origin.concat(hashedPath)
  };
  page(undefined, undefined, Object.assign({
            path: hashedPath,
            url: document.location.origin.concat(hashedPath),
            page: hashedPage
          }, ctx), Object.assign({
            page: hashedPage
          }, ctx));
}

function hashContext(param) {
  var uidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  var uidStartAt = Js_string.search(uidRegex, document.location.pathname);
  if (uidStartAt === -1) {
    return {};
  }
  var hashedPath = document.location.pathname.replace(uidRegex, String(CryptoJs.SHA256(document.location.pathname.substring(uidStartAt, document.location.pathname.indexOf("/", uidStartAt)))));
  return {
          page: {
            path: hashedPath,
            url: document.location.origin.concat(hashedPath)
          }
        };
}

function giftUnwrapped(props) {
  trackWithContext("Gift Unwrapped", props, hashContext(undefined));
}

function giftUnwrappedCardViewed(props) {
  trackWithContext("Gift Card Viewed", props, hashContext(undefined));
}

function businessLeadSubmitted(props) {
  trackWithContext("Lead Submitted", withGaClientId(props), {});
}

function customElementInteraction(props) {
  trackWithContext("Custom Element Interaction", withGaClientId(Object.assign({}, props)), {});
}

function customElementInteractionAsync(props) {
  return trackWithContextAsync("Custom Element Interaction", withGaClientId(Object.assign({}, props)), {});
}

function getFailedAttemptData(path) {
  return {
          element: "login_fail",
          path: path
        };
}

function sendfailedAttempt(path) {
  customElementInteraction(getFailedAttemptData(path));
}

var Login = {
  getFailedAttemptData: getFailedAttemptData,
  sendfailedAttempt: sendfailedAttempt
};

function makeBaseData(element, signupInstanceId, path, signupType) {
  return {
          element: element,
          signup_instance_id: signupInstanceId,
          path: path,
          signup_type: signupType
        };
}

function getSignupTypeAsString(signupTypeValue) {
  switch (signupTypeValue) {
    case /* GiftOpen */0 :
        return "gift_open";
    case /* DuringPurchase */1 :
        return "during_purchase";
    case /* PostPurchaseForFriend */2 :
        return "post_purchase_friend";
    case /* PostPurchaseForSelf */3 :
        return "post_purchase_self";
    case /* SignupPage */4 :
        return "signup_page";
    
  }
}

var Registration = {
  makeBaseData: makeBaseData,
  getSignupTypeAsString: getSignupTypeAsString
};

function reminderSaved(props) {
  trackWithContext("Reminder Saved", props, {});
}

function reminderCreated(props) {
  trackWithContext("Reminder Created", props, {});
}

export {
  writeKey ,
  scriptNotLoaded ,
  debug ,
  identify ,
  track ,
  trackAsync ,
  page ,
  group ,
  reset ,
  setAnonymousId ,
  hash ,
  brazeAlias ,
  linuxOrWindows ,
  contextE ,
  consumerStore ,
  businessStore ,
  userType ,
  niE ,
  withGaClientId ,
  setContext ,
  identifyWithContext ,
  trackWithContext ,
  trackWithContextAsync ,
  cardToProduct ,
  orderCardToProduct ,
  cardToProductWithIndex ,
  cardToProductNoImageUrl ,
  versionControl ,
  productListViewed ,
  productViewed ,
  productClicked ,
  productsSearched ,
  couponEntered ,
  couponApplied ,
  couponDenied ,
  couponRemoved ,
  signedUp ,
  signedIn ,
  signedOut ,
  orderCompleted ,
  productAdded ,
  productRemoved ,
  checkoutStarted ,
  checkoutStepViewed ,
  checkoutStepCompleted ,
  customLinkTracking ,
  giftCardViewed ,
  newsletterSubscribed ,
  hashPage ,
  hashContext ,
  giftUnwrapped ,
  giftUnwrappedCardViewed ,
  businessLeadSubmitted ,
  customElementInteraction ,
  customElementInteractionAsync ,
  Login ,
  Registration ,
  reminderSaved ,
  reminderCreated ,
}
/* writeKey Not a pure module */
