import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

export const validateSecurityCodeErrors = {};

interface ValidateSecurityCodePayload {
  email: string;
  phone: string;
  code: string;
}

/**
 * @secure
 * Request to validate a customers MFA token.
 * @param payload the email, phone and code those require validating
 * @returns Promise of decoded validate email response.
 */
const validateSecurityCode = (payload: ValidateSecurityCodePayload) => {
  return createRequest('api/v3/customer/securitycode/validate/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify(payload),
    withAuth: true,
  }).then(handleErrors);
};

export default validateSecurityCode;
