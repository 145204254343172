// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Datadog from "../bindings/Datadog.bs.js";
import * as ApiErrors from "../api/ApiErrors.bs.js";
import * as AuthUtils from "../auth/user/AuthUtils.bs.js";
import * as ErrorData from "../api/Data/ErrorData.bs.js";
import * as JsCookie from "js-cookie";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as BrowserLogs from "@datadog/browser-logs";

function makeUrl(route) {
  return process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL + route;
}

var isClient = ((() => { try { return window !== undefined; } catch { return false; } })());

var logger = BrowserLogs.datadogLogs.logger;

function checkResponse(response, decoder) {
  var n = response.status;
  var exit = 0;
  if (n >= 401) {
    if (n === 461 || n === 460) {
      if (n >= 461) {
        return Promise.resolve({
                    TAG: /* Error */1,
                    _0: {
                      TAG: /* ServerError */2,
                      _0: /* ServerFraudError */1
                    }
                  });
      } else {
        return Promise.resolve({
                    TAG: /* Error */1,
                    _0: {
                      TAG: /* ServerError */2,
                      _0: /* ServerMFARequiredError */0
                    }
                  });
      }
    }
    if (n >= 402) {
      exit = 1;
    } else {
      JsCookie.remove(AuthUtils.cookieName);
      return Promise.resolve({
                  TAG: /* Error */1,
                  _0: {
                    TAG: /* ServerError */2,
                    _0: /* UnauthorizedError */2
                  }
                });
    }
  } else {
    exit = n >= 203 ? (
        n !== 204 ? 1 : 2
      ) : (
        n >= 200 ? 2 : 1
      );
  }
  switch (exit) {
    case 1 :
        if (n >= 400 && n < 500) {
          return Js_promise.then_((function (json) {
                        var e = ErrorData.Decode.safeDecodeError(json);
                        var tmp;
                        if (e.TAG === /* Ok */0) {
                          tmp = e;
                        } else {
                          var match = e._0;
                          switch (match.TAG | 0) {
                            case /* NetworkError */0 :
                            case /* JsonParsingError */1 :
                                tmp = e;
                                break;
                            case /* ServerError */2 :
                                var detail = match._0;
                                tmp = typeof detail === "number" || detail.TAG !== /* ServerValidationError */0 ? e : ({
                                      TAG: /* Error */1,
                                      _0: {
                                        TAG: /* ServerError */2,
                                        _0: {
                                          TAG: /* ServerValidationError */0,
                                          _0: detail._0
                                        }
                                      }
                                    });
                                break;
                            
                          }
                        }
                        return Promise.resolve(tmp);
                      }), Fetch.$$Response.json(response));
        } else {
          return Promise.resolve({
                      TAG: /* Error */1,
                      _0: ApiErrors.networkError(undefined, n)
                    });
        }
    case 2 :
        return Js_promise.then_((function (json) {
                      return Promise.resolve(Curry._1(decoder, json));
                    }), Fetch.$$Response.json(response));
    
  }
}

function checkResponse1(response, decoder) {
  var n = response.status;
  var exit = 0;
  if (n >= 205) {
    if (n !== 401) {
      if (n === 461) {
        return Promise.resolve({
                    TAG: /* Error */1,
                    _0: {
                      TAG: /* ServerError */2,
                      _0: /* ServerFraudError */1
                    }
                  });
      }
      exit = 1;
    } else {
      JsCookie.remove(AuthUtils.cookieName);
      return Promise.resolve({
                  TAG: /* Error */1,
                  _0: {
                    TAG: /* ServerError */2,
                    _0: /* UnauthorizedError */2
                  }
                });
    }
  } else {
    exit = n !== 200 && n < 204 ? 1 : 2;
  }
  switch (exit) {
    case 1 :
        if (n >= 400 && n < 500) {
          return Js_promise.then_((function (json) {
                        var e = ErrorData.Decode.safeDecodeError(json);
                        var tmp;
                        if (e.TAG === /* Ok */0) {
                          tmp = e;
                        } else {
                          var match = e._0;
                          switch (match.TAG | 0) {
                            case /* NetworkError */0 :
                            case /* JsonParsingError */1 :
                                tmp = e;
                                break;
                            case /* ServerError */2 :
                                var detail = match._0;
                                tmp = typeof detail === "number" || detail.TAG !== /* ServerValidationError */0 ? e : ({
                                      TAG: /* Error */1,
                                      _0: {
                                        TAG: /* ServerError */2,
                                        _0: {
                                          TAG: /* ServerValidationError */0,
                                          _0: detail._0
                                        }
                                      }
                                    });
                                break;
                            
                          }
                        }
                        return Promise.resolve(tmp);
                      }), Fetch.$$Response.json(response));
        } else {
          return Promise.resolve({
                      TAG: /* Error */1,
                      _0: ApiErrors.networkError(undefined, n)
                    });
        }
    case 2 :
        return Js_promise.then_((function (json) {
                      var result = Curry._1(decoder, json);
                      if (result.TAG === /* Ok */0) {
                        return Promise.resolve({
                                    TAG: /* Ok */0,
                                    _0: Caml_option.some(result._0)
                                  });
                      } else {
                        return Promise.resolve({
                                    TAG: /* Ok */0,
                                    _0: undefined
                                  });
                      }
                    }), Fetch.$$Response.json(response));
    
  }
}

function makeRequest(url, request, headerlessRequest, callback, logData) {
  var shouldLog = isClient && logData;
  if (shouldLog) {
    Datadog.Logger.requestInfo("Request " + url, headerlessRequest, logger);
  }
  return Js_promise.then_((function (response) {
                if (shouldLog) {
                  Datadog.Logger.responseInfo("Response " + url, response, logger);
                }
                return Curry._1(callback, response);
              }), fetch(url, request));
}

export {
  makeUrl ,
  isClient ,
  logger ,
  checkResponse ,
  checkResponse1 ,
  makeRequest ,
}
/* isClient Not a pure module */
