// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../common/util.bs.js";
import * as BsCss from "../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../css/Theme.bs.js";
import * as React from "react";
import * as Button from "./library/Button.bs.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var flagName = "nzDisclaimer";

var overlay = Curry._1(BsCss.style, {
      hd: BsCss.zIndex(55),
      tl: {
        hd: BsCss.width(BsCss.pct(100.0)),
        tl: {
          hd: BsCss.height(BsCss.pct(100.0)),
          tl: {
            hd: BsCss.position("fixed"),
            tl: /* [] */0
          }
        }
      }
    });

var content = Curry._1(BsCss.style, {
      hd: BsCss.color(BsCss.white),
      tl: {
        hd: BsCss.boxShadow(Css_Legacy_Core.Shadow.box(BsCss.px(0), BsCss.px(0), BsCss.px(25), undefined, undefined, BsCss.rgba(0, 0, 0, BsCss.pct(25.0)))),
        tl: {
          hd: BsCss.background(BsCss.rgba(49, 62, 73, BsCss.pct(95.0))),
          tl: {
            hd: BsCss.borderRadius(BsCss.rem(0.5)),
            tl: {
              hd: BsCss.display("flex"),
              tl: {
                hd: BsCss.flexDirection("column"),
                tl: {
                  hd: BsCss.alignItems("center"),
                  tl: {
                    hd: BsCss.paddingTop(BsCss.rem(2.5)),
                    tl: {
                      hd: BsCss.paddingBottom(BsCss.rem(2.5)),
                      tl: {
                        hd: BsCss.paddingLeft(BsCss.rem(2.0)),
                        tl: {
                          hd: BsCss.paddingRight(BsCss.rem(2.0)),
                          tl: {
                            hd: BsCss.right(BsCss.rem(1.0)),
                            tl: {
                              hd: BsCss.position("absolute"),
                              tl: {
                                hd: BsCss.left(BsCss.rem(1.0)),
                                tl: {
                                  hd: BsCss.top(BsCss.rem(7.5)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var contentBottom = Curry._1(BsCss.style, {
      hd: BsCss.bottom(BsCss.rem(1.0)),
      tl: {
        hd: BsCss.top("auto"),
        tl: /* [] */0
      }
    });

var textBox = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.rem(58.0)),
      tl: {
        hd: BsCss.textAlign("center"),
        tl: {
          hd: BsCss.marginBottom(BsCss.rem(1.5)),
          tl: /* [] */0
        }
      }
    });

var button = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.rem(10.0)),
      tl: {
        hd: BsCss.paddingTop(BsCss.rem(0.75)),
        tl: {
          hd: BsCss.paddingBottom(BsCss.rem(0.75)),
          tl: /* [] */0
        }
      }
    });

var warningText = Curry._1(BsCss.style, {
      hd: BsCss.color(Theme.Color.errorRed),
      tl: {
        hd: BsCss.marginTop(BsCss.rem(0.5)),
        tl: {
          hd: BsCss.textAlign("center"),
          tl: {
            hd: BsCss.marginBottom(BsCss.rem(-2.0)),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  overlay: overlay,
  content: content,
  contentBottom: contentBottom,
  textBox: textBox,
  button: button,
  warningText: warningText
};

function NZDisclaimerAlert(props) {
  var alertFooter = props.alertFooter;
  var alertFooter$1 = alertFooter !== undefined ? alertFooter : false;
  var match = React.useState(function () {
        return false;
      });
  var setIsShown = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDisplayWarning = match$1[1];
  React.useEffect((function () {
          var match = localStorage.getItem(flagName);
          if (match == null) {
            Curry._1(setIsShown, (function (param) {
                    return true;
                  }));
          } else {
            Curry._1(setIsShown, (function (param) {
                    return false;
                  }));
          }
        }), []);
  if (match[0]) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                Util.ReactStuff.s("I acknowledge and understand that \"Prezzee\" is not affiliated or associated in any way with \"PREZZY"),
                                Util.ReactStuff.s("®"),
                                Util.ReactStuff.s("\", \"PREZZY"),
                                Util.ReactStuff.s("®"),
                                Util.ReactStuff.s(" Card\" or EPAY NEW ZEALAND LIMITED (NZBN 9429036203420) and PREZZY"),
                                Util.ReactStuff.s("®"),
                                Util.ReactStuff.s(" is a registered trade mark of Epay New Zealand Limited, and agree to Prezzee's Privacy Policy and Terms and Conditions.")
                              ],
                              className: textBox
                            }),
                        JsxRuntime.jsx(Button.make, {
                              onClick: (function (param) {
                                  localStorage.setItem(flagName, "shown");
                                  Curry._1(setIsShown, (function (param) {
                                          return false;
                                        }));
                                }),
                              title: "Accept",
                              className: Cn.make([
                                    Button.primaryStyle,
                                    button
                                  ])
                            }),
                        match$1[0] ? JsxRuntime.jsx("span", {
                                children: Util.ReactStuff.s("Please make sure you have accepted the terms."),
                                className: warningText
                              }) : null
                      ],
                      className: Cn.make([
                            content,
                            alertFooter$1 ? contentBottom : ""
                          ])
                    }),
                className: overlay,
                onClick: (function (param) {
                    Curry._1(setDisplayWarning, (function (param) {
                            return true;
                          }));
                  })
              });
  } else {
    return null;
  }
}

var make = NZDisclaimerAlert;

export {
  flagName ,
  Styles ,
  make ,
}
/* overlay Not a pure module */
