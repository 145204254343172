import { gql } from '@apollo/client';

import ENRICHED_TEXT_BLOCK from '../EnrichedTextBlock/fragment';

const GET_STORE_CATEGORY = gql`
  ${ENRICHED_TEXT_BLOCK}

  query StoreCategoryQuery($language: String!, $codename: String!) {
    storeCategory(codename: $codename, languageFilter: { languageCodename: $language }) {
      _system_ {
        codename
        id
      }
      pageTopContent {
        ... on EnrichedTextBlock {
          ...EnrichedTextBlockFields
        }
      }
    }
  }
`;

export default GET_STORE_CATEGORY;
