import { camelKeys } from 'js-convert-case';
import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

type BraintreeTokenResponse = {
  clientToken: string;
};

/**
 * Request to fetch braintree client token.
 * @returns Promise with braintree client token response.
 */
const braintreeToken = (): Promise<BraintreeTokenResponse> =>
  createRequest('api/v3/braintree/client-token/')
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as BraintreeTokenResponse);

export default braintreeToken;
