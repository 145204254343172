// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorData from "./ErrorData.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function discount(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          valueCurrency: Json_decode.field("value_currency", Json_decode.string, json)
        };
}

function bonusCardtheme(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          image: Json_decode.field("image", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.string, json),
          keyColour: Json_decode.field("key_colour", Json_decode.string, json),
          textColour: Json_decode.field("text_colour", Json_decode.string, json)
        };
}

function bonusCard(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          valueCurrency: Json_decode.field("value_currency", Json_decode.string, json),
          theme: Json_decode.field("theme", (function (param) {
                  return Json_decode.optional(bonusCardtheme, param);
                }), json)
        };
}

function promoCodeData(json) {
  return {
          skuUID: Json_decode.field("sku_uid", Json_decode.string, json),
          denomination: Json_decode.field("denomination", Json_decode.string, json),
          denominationCurrency: Json_decode.field("denomination_currency", Json_decode.string, json),
          promoCode: Json_decode.field("promo_code", Json_decode.string, json),
          discounts: Json_decode.field("discounts", (function (param) {
                  return Json_decode.nullable((function (param) {
                                return Json_decode.array(discount, param);
                              }), param);
                }), json),
          bonusCards: Json_decode.field("bonus_cards", (function (param) {
                  return Json_decode.nullable((function (param) {
                                return Json_decode.array(bonusCard, param);
                              }), param);
                }), json),
          totalPrice: Json_decode.field("total_price", Json_decode.string, json),
          totalPriceCurrency: Json_decode.field("total_price_currency", Json_decode.string, json)
        };
}

function safeDecodePromoCodeData(json) {
  var result;
  try {
    result = promoCodeData(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode = {
  discount: discount,
  bonusCardtheme: bonusCardtheme,
  bonusCard: bonusCard,
  promoCodeData: promoCodeData,
  safeDecodePromoCodeData: safeDecodePromoCodeData
};

export {
  Decode ,
}
/* No side effect */
