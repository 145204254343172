import { camelKeys } from 'js-convert-case';
import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { Sku } from 'models/sku.model';

/**
 * Request to fetch sku information via sku slug.
 * @param slug The product unique identifier.
 * @returns Promise with decoded sku response.
 */
const skuBySlug = (slug: string, authToken?: string): Promise<Sku> => {
  return (
    createRequest(`api/v3/sku/by-slug/${slug}/`, {
      withAuth: true,
      withMagicUserAgent: true,
      withAuthHeaderFromServer: authToken,
    })
      .then(handleErrors)
      // layerType is a spoof field to keep layer upgrade functionality, in future this will come from the backend.
      .then(data => ({ ...camelKeys(data, { recursive: true, recursiveInArray: true }), layerType: 'default' } as Sku))
  );
};

export default skuBySlug;
