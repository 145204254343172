// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__Video__Decoder from "../Video/Gql__Video__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "VideoContentType") {
    return Gql__Video__Decoder.get(content.VAL.video);
  }
  
}

var Video;

export {
  Video ,
  get ,
}
/* No side effect */
