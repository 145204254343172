// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";
import * as Gql__ProductHeroIcon__Fragment from "../ProductHeroIcon/Gql__ProductHeroIcon__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment ProductHeroBlock on ProductHeroBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    subtitle  {
      __typename
      value
    }
    enhanced_description  {
      __typename
      value
    }
    icons  {
      __typename
      value  {
        __typename
        ...on ProductHeroIconContentType   {
          ...ProductHeroIcon
        }
      }
    }
    image  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    primary_button  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    secondary_button  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    product_slug  {
      __typename
      value
    }
    additional_description  {
      __typename
      value
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__ProductHeroIcon__Fragment.ProductHeroIcon.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.subtitle;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.enhanced_description;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.icons;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ProductHeroIconContentType" ? ({
                      NAME: "ProductHeroIconContentType",
                      VAL: {
                        productHeroIcon: Gql__ProductHeroIcon__Fragment.ProductHeroIcon.verifyArgsAndParse("ProductHeroIcon", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$9) : undefined
    };
  }
  var value$10 = value.image;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$11) : undefined
    };
  }
  var value$12 = value.primary_button;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$13) : undefined
    };
  }
  var value$14 = value.secondary_button;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$15) : undefined
    };
  }
  var value$16 = value.product_slug;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.additional_description;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          subtitle: tmp$1,
          enhanced_description: tmp$2,
          icons: tmp$3,
          image: tmp$4,
          primary_button: tmp$5,
          secondary_button: tmp$6,
          product_slug: tmp$7,
          additional_description: tmp$8
        };
}

function serialize(value) {
  var value$1 = value.additional_description;
  var additional_description;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    additional_description = {
      __typename: value$4,
      value: value$3
    };
  } else {
    additional_description = null;
  }
  var value$5 = value.product_slug;
  var product_slug;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    product_slug = {
      __typename: value$8,
      value: value$7
    };
  } else {
    product_slug = null;
  }
  var value$9 = value.secondary_button;
  var secondary_button;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    secondary_button = {
      __typename: value$12,
      value: value$11
    };
  } else {
    secondary_button = null;
  }
  var value$13 = value.primary_button;
  var primary_button;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$14) : null;
    var value$16 = value$13.__typename;
    primary_button = {
      __typename: value$16,
      value: value$15
    };
  } else {
    primary_button = null;
  }
  var value$17 = value.image;
  var image;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    image = {
      __typename: value$20,
      value: value$19
    };
  } else {
    image = null;
  }
  var value$21 = value.icons;
  var icons;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__ProductHeroIcon__Fragment.ProductHeroIcon.serialize(value.VAL.productHeroIcon)]);
                }
              } else {
                return null;
              }
            }), value$22) : null;
    var value$24 = value$21.__typename;
    icons = {
      __typename: value$24,
      value: value$23
    };
  } else {
    icons = null;
  }
  var value$25 = value.enhanced_description;
  var enhanced_description;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? value$26 : null;
    var value$28 = value$25.__typename;
    enhanced_description = {
      __typename: value$28,
      value: value$27
    };
  } else {
    enhanced_description = null;
  }
  var value$29 = value.subtitle;
  var subtitle;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? value$30 : null;
    var value$32 = value$29.__typename;
    subtitle = {
      __typename: value$32,
      value: value$31
    };
  } else {
    subtitle = null;
  }
  var value$33 = value.title;
  var title;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    title = {
      __typename: value$36,
      value: value$35
    };
  } else {
    title = null;
  }
  var value$37 = value.system;
  var value$38 = value$37.codename;
  var value$39 = value$37.id;
  var value$40 = value$37.__typename;
  var system = {
    __typename: value$40,
    id: value$39,
    codename: value$38
  };
  var value$41 = value.__typename;
  return {
          __typename: value$41,
          system: system,
          title: title,
          subtitle: subtitle,
          enhanced_description: enhanced_description,
          icons: icons,
          image: image,
          primary_button: primary_button,
          secondary_button: secondary_button,
          product_slug: product_slug,
          additional_description: additional_description
        };
}

function verifyArgsAndParse(_ProductHeroBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ProductHeroBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var CallToAction;

var ProductHeroIcon;

export {
  CallToAction ,
  ProductHeroIcon ,
  ProductHeroBlock ,
}
/* query Not a pure module */
