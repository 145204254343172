// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__Footer__Fragment from "../Footer/Gql__Footer__Fragment.bs.js";
import * as Gql__Header__Fragment from "../Header/Gql__Header__Fragment.bs.js";
import * as Gql__PageBuilder__Fragment from "../PageBuilder/Gql__PageBuilder__Fragment.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0, frag_1, frag_2, frag_3, frag_4) => require("@apollo/client").gql`
  query LandingPageContent($language: String!, $preview: Boolean, $url: String!)  {
    itemsByType(type: "landing_page", limit: 1, depth: 5, language: $language, preview: $preview, filters: [{type: EQUALS, prop: "elements.url", val: $url}])  {
      __typename
      ...on LandingPageContentType   {
        __typename
        logo  {
          __typename
          value  {
            __typename
            ...on ImageContentType   {
              ...Image
            }
          }
        }
        navigation_buttons  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
          }
        }
        subpages  {
          __typename
          value  {
            __typename
            ...on PageContentType   {
              ...HeaderBlock
            }
          }
        }
        content  {
          __typename
          value  {
            __typename
            ...on PageBuilderContentType   {
              ...PageContent
            }
          }
        }
        footer  {
          __typename
          value  {
            __typename
            ...on FooterContainerContentType   {
              ...FooterBlock
            }
          }
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
  ${frag_2}
  ${frag_3}
  ${frag_4}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__Footer__Fragment.FooterBlock.query, Gql__Header__Fragment.HeaderBlock.query, Gql__Image__Fragment.$$Image.query, Gql__PageBuilder__Fragment.PageContent.query);

function parse(value) {
  var value$1 = value.itemsByType;
  return {
          itemsByType: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "LandingPageContentType") {
                      var value$1 = value.logo;
                      var tmp$1;
                      if (value$1 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$2 = value$1.value;
                        tmp$1 = {
                          __typename: value$1.__typename,
                          value: !(value$2 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "ImageContentType" ? ({
                                          NAME: "ImageContentType",
                                          VAL: {
                                            image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$2) : undefined
                        };
                      }
                      var value$3 = value.navigation_buttons;
                      var tmp$2;
                      if (value$3 == null) {
                        tmp$2 = undefined;
                      } else {
                        var value$4 = value$3.value;
                        tmp$2 = {
                          __typename: value$3.__typename,
                          value: !(value$4 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                          NAME: "CallToActionLinkD6a3d61ContentType",
                                          VAL: {
                                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$4) : undefined
                        };
                      }
                      var value$5 = value.subpages;
                      var tmp$3;
                      if (value$5 == null) {
                        tmp$3 = undefined;
                      } else {
                        var value$6 = value$5.value;
                        tmp$3 = {
                          __typename: value$5.__typename,
                          value: !(value$6 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "PageContentType" ? ({
                                          NAME: "PageContentType",
                                          VAL: {
                                            headerBlock: Gql__Header__Fragment.HeaderBlock.verifyArgsAndParse("HeaderBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$6) : undefined
                        };
                      }
                      var value$7 = value.content;
                      var tmp$4;
                      if (value$7 == null) {
                        tmp$4 = undefined;
                      } else {
                        var value$8 = value$7.value;
                        tmp$4 = {
                          __typename: value$7.__typename,
                          value: !(value$8 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "PageBuilderContentType" ? ({
                                          NAME: "PageBuilderContentType",
                                          VAL: {
                                            pageContent: Gql__PageBuilder__Fragment.PageContent.verifyArgsAndParse("PageContent", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$8) : undefined
                        };
                      }
                      var value$9 = value.footer;
                      var tmp$5;
                      if (value$9 == null) {
                        tmp$5 = undefined;
                      } else {
                        var value$10 = value$9.value;
                        tmp$5 = {
                          __typename: value$9.__typename,
                          value: !(value$10 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "FooterContainerContentType" ? ({
                                          NAME: "FooterContainerContentType",
                                          VAL: {
                                            footerBlock: Gql__Footer__Fragment.FooterBlock.verifyArgsAndParse("FooterBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$10) : undefined
                        };
                      }
                      tmp = {
                        NAME: "LandingPageContentType",
                        VAL: {
                          __typename: value.__typename,
                          logo: tmp$1,
                          navigation_buttons: tmp$2,
                          subpages: tmp$3,
                          content: tmp$4,
                          footer: tmp$5
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.itemsByType;
  var itemsByType = value$1 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME === "UnspecifiedFragment") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.footer;
            var footer;
            if (value$2 !== undefined) {
              var value$3 = value$2.value;
              var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "FooterContainerContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__Footer__Fragment.FooterBlock.serialize(value.VAL.footerBlock)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$3) : null;
              var value$5 = value$2.__typename;
              footer = {
                __typename: value$5,
                value: value$4
              };
            } else {
              footer = null;
            }
            var value$6 = value$1.content;
            var content;
            if (value$6 !== undefined) {
              var value$7 = value$6.value;
              var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__PageBuilder__Fragment.PageContent.serialize(value.VAL.pageContent)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$7) : null;
              var value$9 = value$6.__typename;
              content = {
                __typename: value$9,
                value: value$8
              };
            } else {
              content = null;
            }
            var value$10 = value$1.subpages;
            var subpages;
            if (value$10 !== undefined) {
              var value$11 = value$10.value;
              var value$12 = value$11 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__Header__Fragment.HeaderBlock.serialize(value.VAL.headerBlock)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$11) : null;
              var value$13 = value$10.__typename;
              subpages = {
                __typename: value$13,
                value: value$12
              };
            } else {
              subpages = null;
            }
            var value$14 = value$1.navigation_buttons;
            var navigation_buttons;
            if (value$14 !== undefined) {
              var value$15 = value$14.value;
              var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$15) : null;
              var value$17 = value$14.__typename;
              navigation_buttons = {
                __typename: value$17,
                value: value$16
              };
            } else {
              navigation_buttons = null;
            }
            var value$18 = value$1.logo;
            var logo;
            if (value$18 !== undefined) {
              var value$19 = value$18.value;
              var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$19) : null;
              var value$21 = value$18.__typename;
              logo = {
                __typename: value$21,
                value: value$20
              };
            } else {
              logo = null;
            }
            return {
                    __typename: "LandingPageContentType",
                    logo: logo,
                    navigation_buttons: navigation_buttons,
                    subpages: subpages,
                    content: content,
                    footer: footer
                  };
          }), value$1) : null;
  return {
          itemsByType: itemsByType
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined,
          url: inp.url
        };
}

function makeVariables(language, preview, url, param) {
  return {
          language: language,
          preview: preview,
          url: url
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var CallToAction;

var PageContent;

var HeaderBlock;

var $$Image;

var FooterBlock;

export {
  CallToAction ,
  PageContent ,
  HeaderBlock ,
  $$Image ,
  FooterBlock ,
  Query ,
}
/* query Not a pure module */
