// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../bindings/BsCss.bs.js";

var prezzeeRed = BsCss.hex("E30B17");

var charcoal = BsCss.hex("313E49");

var activeBlue = BsCss.hex("3E77B0");

var darkGrey = BsCss.hex("444444");

var midGrey = BsCss.hex("767676");

var lightGrey = BsCss.hex("A9AAA9");

var errorRed = BsCss.hex("FF747C");

var paleGrey = BsCss.hex("F8F8F8");

var backgroundGrey = BsCss.hex("E5E5E5");

var green = BsCss.hex("25AE88");

var orange = BsCss.hex("DC5800");

var yellow = BsCss.hex("FAA731");

var purple700 = BsCss.hex("2B0840");

var purple600 = BsCss.hex("32064C");

var purple500 = BsCss.hex("410861");

var purple400 = BsCss.hex("48096C");

var purple300 = BsCss.hex("6D3A89");

var purple200 = BsCss.hex("916BA7");

var purple100 = BsCss.hex("DACEE2");

var purple50 = BsCss.hex("EDE6F0");

var red700 = BsCss.hex("480610");

var red600 = BsCss.hex("900C20");

var red500 = BsCss.hex("BE1807");

var red400 = BsCss.hex("ED1E09");

var red300 = BsCss.hex("F1453A");

var red200 = BsCss.hex("F4736B");

var red100 = BsCss.hex("FABCB5");

var red50 = BsCss.hex("FDE9E6");

var pink700 = BsCss.hex("CC7970");

var pink600 = BsCss.hex("FF978C");

var pink500 = BsCss.hex("FFB6AF");

var pink400 = BsCss.hex("FFD2BD");

var pink300 = BsCss.hex("FFE0D1");

var pink200 = BsCss.hex("FFE9DE");

var pink100 = BsCss.hex("FFF6F2");

var pink50 = BsCss.hex("FFFBF8");

var lilac700 = BsCss.hex("A09EB0");

var lilac600 = BsCss.hex("B7B5C9");

var lilac500 = BsCss.hex("CECBE2");

var lilac400 = BsCss.hex("E5E2FB");

var lilac300 = BsCss.hex("EAE8FC");

var lilac200 = BsCss.hex("EDEBFC7");

var lilac100 = BsCss.hex("F7F5FD");

var lilac50 = BsCss.hex("F6F7F9");

var blue700 = BsCss.hex("102C60");

var blue600 = BsCss.hex("174291");

var blue500 = BsCss.hex("1E54B7");

var blue400 = BsCss.hex("276EF1");

var blue300 = BsCss.hex("5B91F5");

var blue200 = BsCss.hex("A0BFF8");

var blue100 = BsCss.hex("D4E2FC");

var blue50 = BsCss.hex("EFF3FE");

var green700 = BsCss.hex("0B3429");

var green600 = BsCss.hex("166852");

var green500 = BsCss.hex("1E8B6D");

var green400 = BsCss.hex("25AE88");

var green300 = BsCss.hex("51BEA0");

var green200 = BsCss.hex("7CCEB8");

var green100 = BsCss.hex("A8DFCF");

var green50 = BsCss.hex("E9F7F3");

var yellow700 = BsCss.hex("806022");

var yellow600 = BsCss.hex("B3862F");

var yellow500 = BsCss.hex("CC9A36");

var yellow400 = BsCss.hex("FFC043");

var yellow300 = BsCss.hex("FFCF70");

var yellow200 = BsCss.hex("FFE3AC");

var yellow100 = BsCss.hex("FFF2D9");

var yellow50 = BsCss.hex("FFFAF0");

var orange700 = BsCss.hex("7D2714");

var orange600 = BsCss.hex("AE371C");

var orange500 = BsCss.hex("CC492C");

var orange400 = BsCss.hex("FF6937");

var orange300 = BsCss.hex("FA9269");

var orange200 = BsCss.hex("FABDA5");

var orange100 = BsCss.hex("FFE1D6");

var orange50 = BsCss.hex("FFF3EF");

var black = BsCss.hex("000000");

var gray800 = BsCss.hex("1E1E1E");

var gray700 = BsCss.hex("302F2F");

var gray600 = BsCss.hex("4B4B4B");

var gray500 = BsCss.hex("787878");

var gray400 = BsCss.hex("A0A0A0");

var gray300 = BsCss.hex("C7C3C3");

var gray200 = BsCss.hex("E0DEDE");

var gray100 = BsCss.hex("F0EDED");

var gray50 = BsCss.hex("F8F8F8");

var white = BsCss.hex("FFFFFF");

var accentPink = BsCss.hex("FFC5EE");

var accentYellow = BsCss.hex("C7C3C3");

var accentBlue = BsCss.hex("C7C3C3");

var Color = {
  prezzeeRed: prezzeeRed,
  charcoal: charcoal,
  activeBlue: activeBlue,
  darkGrey: darkGrey,
  midGrey: midGrey,
  lightGrey: lightGrey,
  errorRed: errorRed,
  paleGrey: paleGrey,
  backgroundGrey: backgroundGrey,
  green: green,
  orange: orange,
  yellow: yellow,
  purple700: purple700,
  purple600: purple600,
  purple500: purple500,
  purple400: purple400,
  purple300: purple300,
  purple200: purple200,
  purple100: purple100,
  purple50: purple50,
  red700: red700,
  red600: red600,
  red500: red500,
  red400: red400,
  red300: red300,
  red200: red200,
  red100: red100,
  red50: red50,
  pink700: pink700,
  pink600: pink600,
  pink500: pink500,
  pink400: pink400,
  pink300: pink300,
  pink200: pink200,
  pink100: pink100,
  pink50: pink50,
  lilac700: lilac700,
  lilac600: lilac600,
  lilac500: lilac500,
  lilac400: lilac400,
  lilac300: lilac300,
  lilac200: lilac200,
  lilac100: lilac100,
  lilac50: lilac50,
  blue700: blue700,
  blue600: blue600,
  blue500: blue500,
  blue400: blue400,
  blue300: blue300,
  blue200: blue200,
  blue100: blue100,
  blue50: blue50,
  green700: green700,
  green600: green600,
  green500: green500,
  green400: green400,
  green300: green300,
  green200: green200,
  green100: green100,
  green50: green50,
  yellow700: yellow700,
  yellow600: yellow600,
  yellow500: yellow500,
  yellow400: yellow400,
  yellow300: yellow300,
  yellow200: yellow200,
  yellow100: yellow100,
  yellow50: yellow50,
  orange700: orange700,
  orange600: orange600,
  orange500: orange500,
  orange400: orange400,
  orange300: orange300,
  orange200: orange200,
  orange100: orange100,
  orange50: orange50,
  black: black,
  gray800: gray800,
  gray700: gray700,
  gray600: gray600,
  gray500: gray500,
  gray400: gray400,
  gray300: gray300,
  gray200: gray200,
  gray100: gray100,
  gray50: gray50,
  white: white,
  accentPink: accentPink,
  accentYellow: accentYellow,
  accentBlue: accentBlue
};

var defaultFonts = BsCss.fontFamilies({
      hd: {
        NAME: "custom",
        VAL: "sofia-pro"
      },
      tl: {
        hd: {
          NAME: "custom",
          VAL: "-apple-system"
        },
        tl: {
          hd: {
            NAME: "custom",
            VAL: "BlinkMacSystemFont"
          },
          tl: {
            hd: {
              NAME: "custom",
              VAL: "Helvetica Neue"
            },
            tl: {
              hd: {
                NAME: "custom",
                VAL: "Arial"
              },
              tl: {
                hd: "sansSerif",
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Typography = {
  defaultFonts: defaultFonts
};

var small = BsCss.px(16);

var medium = BsCss.px(24);

var Spacing = {
  small: small,
  medium: medium
};

export {
  Color ,
  Typography ,
  Spacing ,
}
/* prezzeeRed Not a pure module */
