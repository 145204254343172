import { FC } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import logger from '../utils/logger';

interface Props {
  src: string;
  alt: string;
  width: number;
  height: number;
  sizes?: string;
  className?: string;
  priority?: boolean;
  fill?: boolean;
  loading?: 'lazy' | 'eager';
}

const isSvg = (url: string) => url.endsWith('.svg');

const loader = ({ src, width }) => `${src}?fm=png&auto=format&w=${width}`;

const loaderWithParams = ({ src, width }) => `${src}?fm=png&auto=format&w=${width}`;

const loaderSvg = ({ src }) => src;

const paramsRegex = /(?:\?|\&)([\w]+)(?:\=|\&?)([\w+,.-]*)/g;

const CmsImage: FC<Props> = props => {
  const { src, alt, width, height, className, priority, sizes, fill = false, loading } = props;
  const { asPath } = useRouter();

  let currentLoader = loader;

  if (src.match(paramsRegex)) {
    if (isSvg(src.slice(0, src.search(paramsRegex)))) {
      currentLoader = loaderSvg;
    } else {
      currentLoader = loaderWithParams;
    }
  } else if (isSvg(src)) {
    currentLoader = loaderSvg;
  }

  if (!src) {
    logger.info(
      {
        path: asPath,
        props,
        source: 'CMS',
      },
      'error: Image src is missing'
    );

    //Image requires src
    return null;
  }

  return (
    <Image
      loader={currentLoader}
      priority={priority}
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      sizes={sizes}
      fill={fill}
      loading={loading}
    />
  );
};

export default CmsImage;
