// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../common/util.bs.js";
import * as Themes from "../css/Themes.bs.js";
import * as Fresnel from "../bindings/Fresnel.bs.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import Head from "next/head";
import Script from "next/script";
import * as JsxRuntime from "react/jsx-runtime";
import * as Fresnel$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Fresnel/Fresnel.bs.js";
import * as ThemeContext$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext.bs.js";

var serverUrl = process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL;

var domain = serverUrl.replace("https://", "") + "api";

function Shared(props) {
  var theme = props.theme;
  var theme$1 = theme !== undefined ? theme : Themes.personal;
  return JsxRuntime.jsx(ThemeContext$PrezzeeRibbon.Provider.make, {
              value: theme$1,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs(Head, {
                            children: [
                              JsxRuntime.jsx("meta", {
                                    content: "width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover",
                                    name: "viewport"
                                  }),
                              JsxRuntime.jsx("meta", {
                                    content: domain,
                                    name: "prezzee-server-url"
                                  }),
                              Util.EnvUtil.getEnv(undefined) === /* Production */1 && Caml_obj.equal(Util.EnvUtil.getCountry(undefined), {
                                    TAG: /* Ok */0,
                                    _0: /* UnitedStates */2
                                  }) ? JsxRuntime.jsx("meta", {
                                      content: "04d7c9e0243ba060794077b781890d65",
                                      name: "p:domain_verify"
                                    }) : null,
                              JsxRuntime.jsx("style", {
                                    type: "text/css",
                                    dangerouslySetInnerHTML: {
                                      __html: Fresnel.mediaStyles
                                    }
                                  }),
                              JsxRuntime.jsx("style", {
                                    type: "text/css",
                                    dangerouslySetInnerHTML: {
                                      __html: Fresnel$PrezzeeRibbon.mediaStyles
                                    }
                                  })
                            ]
                          }),
                      JsxRuntime.jsx(Script, {
                            id: "datadomeScript",
                            type_: "text/javascript",
                            src: "/scripts/datadome.js"
                          }),
                      props.children
                    ]
                  })
            });
}

var Head$1;

var Script$1;

var make = Shared;

export {
  Head$1 as Head,
  Script$1 as Script,
  serverUrl ,
  domain ,
  make ,
}
/* serverUrl Not a pure module */
