// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__UniqueSellingPointsBlock__Decoder__BackgroundImage from "./Gql__UniqueSellingPointsBlock__Decoder__BackgroundImage.bs.js";
import * as Gql__UniqueSellingPointsBlock__Decoder__UniqueSellingPoint from "./Gql__UniqueSellingPointsBlock__Decoder__UniqueSellingPoint.bs.js";

function get(c) {
  var tagline = Belt_Option.getWithDefault(Belt_Option.flatMap(c.tagline, (function (c) {
              return c.value;
            })), "");
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__UniqueSellingPointsBlock__Decoder__BackgroundImage.get), 0);
  var background_image_position = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image_position, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), /* Right */1, (function (str) {
          switch (str) {
            case "left" :
                return /* Left */0;
            case "right" :
                return /* Right */1;
            default:
              return /* Right */1;
          }
        }));
  var unique_selling_points = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.unique_selling_points, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__UniqueSellingPointsBlock__Decoder__UniqueSellingPoint.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          tagline: tagline,
          title: title,
          description: description,
          background_image: background_image,
          background_image_position: background_image_position,
          unique_selling_points: unique_selling_points,
          smartLink: smartLink
        };
}

var $$Image;

var UniqueSellingPoint;

var BackgroundImage;

export {
  $$Image ,
  UniqueSellingPoint ,
  BackgroundImage ,
  get ,
}
/* No side effect */
