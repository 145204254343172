// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__DeviceSizeImage__Fragment from "../DeviceSizeImage/Gql__DeviceSizeImage__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment ImageCollection on ImageCollectionContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    images  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
        ...on DeviceSizeImageContentType   {
          ...DeviceSizeImage
        }
      }
    }
    display_per_row_desktop  {
      __typename
      value  {
        __typename
        codename
      }
    }
    display_per_row_tablet  {
      __typename
      value  {
        __typename
        codename
      }
    }
    display_per_row_mobile  {
      __typename
      value  {
        __typename
        codename
      }
    }
    gap_desktop  {
      __typename
      value
    }
    gap_tablet  {
      __typename
      value
    }
    gap_mobile  {
      __typename
      value
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__DeviceSizeImage__Fragment.DeviceSizeImage.query, Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.description;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.images;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                switch (typename) {
                  case "DeviceSizeImageContentType" :
                      tmp = {
                        NAME: "DeviceSizeImageContentType",
                        VAL: {
                          deviceSizeImage: Gql__DeviceSizeImage__Fragment.DeviceSizeImage.verifyArgsAndParse("DeviceSizeImage", value)
                        }
                      };
                      break;
                  case "ImageContentType" :
                      tmp = {
                        NAME: "ImageContentType",
                        VAL: {
                          image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                        }
                      };
                      break;
                  default:
                    tmp = {
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    };
                }
                return tmp;
              }), value$7) : undefined
    };
  }
  var value$8 = value.display_per_row_desktop;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$9) : undefined
    };
  }
  var value$10 = value.display_per_row_tablet;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$11) : undefined
    };
  }
  var value$12 = value.display_per_row_mobile;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$13) : undefined
    };
  }
  var value$14 = value.gap_desktop;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.gap_tablet;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.gap_mobile;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          description: tmp$1,
          images: tmp$2,
          display_per_row_desktop: tmp$3,
          display_per_row_tablet: tmp$4,
          display_per_row_mobile: tmp$5,
          gap_desktop: tmp$6,
          gap_tablet: tmp$7,
          gap_mobile: tmp$8
        };
}

function serialize(value) {
  var value$1 = value.gap_mobile;
  var gap_mobile;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    gap_mobile = {
      __typename: value$4,
      value: value$3
    };
  } else {
    gap_mobile = null;
  }
  var value$5 = value.gap_tablet;
  var gap_tablet;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    gap_tablet = {
      __typename: value$8,
      value: value$7
    };
  } else {
    gap_tablet = null;
  }
  var value$9 = value.gap_desktop;
  var gap_desktop;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    gap_desktop = {
      __typename: value$12,
      value: value$11
    };
  } else {
    gap_desktop = null;
  }
  var value$13 = value.display_per_row_mobile;
  var display_per_row_mobile;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$14) : null;
    var value$16 = value$13.__typename;
    display_per_row_mobile = {
      __typename: value$16,
      value: value$15
    };
  } else {
    display_per_row_mobile = null;
  }
  var value$17 = value.display_per_row_tablet;
  var display_per_row_tablet;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    display_per_row_tablet = {
      __typename: value$20,
      value: value$19
    };
  } else {
    display_per_row_tablet = null;
  }
  var value$21 = value.display_per_row_desktop;
  var display_per_row_desktop;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$22) : null;
    var value$24 = value$21.__typename;
    display_per_row_desktop = {
      __typename: value$24,
      value: value$23
    };
  } else {
    display_per_row_desktop = null;
  }
  var value$25 = value.images;
  var images;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var variant = value.NAME;
              if (variant === "UnspecifiedFragment") {
                return {};
              } else if (variant === "DeviceSizeImageContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__DeviceSizeImage__Fragment.DeviceSizeImage.serialize(value.VAL.deviceSizeImage)]);
              } else {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
              }
            }), value$26) : null;
    var value$28 = value$25.__typename;
    images = {
      __typename: value$28,
      value: value$27
    };
  } else {
    images = null;
  }
  var value$29 = value.description;
  var description;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? value$30 : null;
    var value$32 = value$29.__typename;
    description = {
      __typename: value$32,
      value: value$31
    };
  } else {
    description = null;
  }
  var value$33 = value.title;
  var title;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    title = {
      __typename: value$36,
      value: value$35
    };
  } else {
    title = null;
  }
  var value$37 = value.system;
  var value$38 = value$37.codename;
  var value$39 = value$37.id;
  var value$40 = value$37.__typename;
  var system = {
    __typename: value$40,
    id: value$39,
    codename: value$38
  };
  var value$41 = value.__typename;
  return {
          __typename: value$41,
          system: system,
          title: title,
          description: description,
          images: images,
          display_per_row_desktop: display_per_row_desktop,
          display_per_row_tablet: display_per_row_tablet,
          display_per_row_mobile: display_per_row_mobile,
          gap_desktop: gap_desktop,
          gap_tablet: gap_tablet,
          gap_mobile: gap_mobile
        };
}

function verifyArgsAndParse(_ImageCollection, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ImageCollection = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var DeviceSizeImage;

export {
  $$Image ,
  DeviceSizeImage ,
  ImageCollection ,
}
/* query Not a pure module */
