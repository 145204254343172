// Generated by ReScript, PLEASE EDIT WITH CARE


function requestInfo(arg1, arg2, obj) {
  obj.info(arg1, arg2);
}

function responseInfo(arg1, arg2, obj) {
  obj.info(arg1, arg2);
}

var Logger = {
  requestInfo: requestInfo,
  responseInfo: responseInfo
};

export {
  Logger ,
}
/* No side effect */
