// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function get(c) {
  var heading = Belt_Option.getWithDefault(Belt_Option.flatMap(c.heading, (function (c) {
              return c.value;
            })), "");
  var body_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_text, (function (c) {
              return c.value;
            })), "");
  var buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_button_s, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          heading: heading,
          body_text: body_text,
          buttons: buttons,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
