// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(linkItem) {
  var text = Belt_Option.getWithDefault(Belt_Option.flatMap(linkItem.text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = linkItem.system.id;
  var smartLink_codename = linkItem.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          text: text,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
