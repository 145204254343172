// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment CookiePopupBlock on GdprCookiePopupContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    cookie_popup_text  {
      __typename
      value
    }
    cookie_popup_accept_button  {
      __typename
      value
    }
    cookie_popup_decline_button  {
      __typename
      value
    }
    cookie_popup_icon  {
      __typename
      value  {
        __typename
        url
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.cookie_popup_text;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.cookie_popup_accept_button;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.cookie_popup_decline_button;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.cookie_popup_icon;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.url;
                return {
                        __typename: value.__typename,
                        url: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$9) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          cookie_popup_text: tmp,
          cookie_popup_accept_button: tmp$1,
          cookie_popup_decline_button: tmp$2,
          cookie_popup_icon: tmp$3
        };
}

function serialize(value) {
  var value$1 = value.cookie_popup_icon;
  var cookie_popup_icon;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.url;
              var url = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      url: url
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    cookie_popup_icon = {
      __typename: value$4,
      value: value$3
    };
  } else {
    cookie_popup_icon = null;
  }
  var value$5 = value.cookie_popup_decline_button;
  var cookie_popup_decline_button;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    cookie_popup_decline_button = {
      __typename: value$8,
      value: value$7
    };
  } else {
    cookie_popup_decline_button = null;
  }
  var value$9 = value.cookie_popup_accept_button;
  var cookie_popup_accept_button;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    cookie_popup_accept_button = {
      __typename: value$12,
      value: value$11
    };
  } else {
    cookie_popup_accept_button = null;
  }
  var value$13 = value.cookie_popup_text;
  var cookie_popup_text;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    cookie_popup_text = {
      __typename: value$16,
      value: value$15
    };
  } else {
    cookie_popup_text = null;
  }
  var value$17 = value.system;
  var value$18 = value$17.codename;
  var value$19 = value$17.id;
  var value$20 = value$17.__typename;
  var system = {
    __typename: value$20,
    id: value$19,
    codename: value$18
  };
  var value$21 = value.__typename;
  return {
          __typename: value$21,
          system: system,
          cookie_popup_text: cookie_popup_text,
          cookie_popup_accept_button: cookie_popup_accept_button,
          cookie_popup_decline_button: cookie_popup_decline_button,
          cookie_popup_icon: cookie_popup_icon
        };
}

function verifyArgsAndParse(_CookiePopupBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var CookiePopupBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  CookiePopupBlock ,
}
/* query Not a pure module */
