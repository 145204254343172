// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FullWidthHeroBanner__Decoder__Button from "./Gql__FullWidthHeroBanner__Decoder__Button.bs.js";
import * as Gql__FullWidthHeroBanner__Decoder__ImageMobile from "./Gql__FullWidthHeroBanner__Decoder__ImageMobile.bs.js";
import * as Gql__FullWidthHeroBanner__Decoder__ImageTablet from "./Gql__FullWidthHeroBanner__Decoder__ImageTablet.bs.js";
import * as Gql__FullWidthHeroBanner__Decoder__ImageDesktop from "./Gql__FullWidthHeroBanner__Decoder__ImageDesktop.bs.js";
import * as Gql__FullWidthHeroBanner__Decoder__ScrollingText from "./Gql__FullWidthHeroBanner__Decoder__ScrollingText.bs.js";
import * as Gql__FullWidthHeroBanner__Decoder__VideoButtonIcon from "./Gql__FullWidthHeroBanner__Decoder__VideoButtonIcon.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var scrolling_text = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.scrolling_text, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__ScrollingText.get), 0);
  var scrolling_text_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.scrolling_text_colour, (function (c) {
              return c.value;
            })), "");
  var background_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), undefined, (function (c) {
          if (c === "") {
            return ;
          } else {
            return c;
          }
        }));
  var desktop_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__ImageDesktop.get), 0);
  var tablet_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__ImageTablet.get), 0);
  var mobile_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__ImageMobile.get), 0);
  var buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.buttons, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__Button.get);
  var video_button_icon = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.video_button_icon, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__VideoButtonIcon.get), 0);
  var video_button_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.video_button_text, (function (c) {
              return c.value;
            })), "");
  var video_button_url = Belt_Option.getWithDefault(Belt_Option.flatMap(c.video_button_url, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          description: description,
          scrolling_text: scrolling_text,
          scrolling_text_colour: scrolling_text_colour,
          buttons: buttons,
          video_button_icon: video_button_icon,
          video_button_text: video_button_text,
          video_button_url: video_button_url,
          background_colour: background_colour,
          desktop_image: desktop_image,
          tablet_image: tablet_image,
          mobile_image: mobile_image,
          smartLink: smartLink
        };
}

var Button;

var $$Image;

var ImageDesktop;

var ImageTablet;

var ImageMobile;

var VideoButtonIcon;

var ScrollingText;

export {
  Button ,
  $$Image ,
  ImageDesktop ,
  ImageTablet ,
  ImageMobile ,
  VideoButtonIcon ,
  ScrollingText ,
  get ,
}
/* No side effect */
