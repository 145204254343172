import { Flex, Text } from '@chakra-ui/react';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@prezzee/ribbon-ui/lib/components/Button';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@prezzee/ribbon-ui/lib/components/Modal';
import { useErrorModal } from 'contexts/ErrorContext';

const ErrorModal = () => {
  const { errorMessage, hideErrorModal } = useErrorModal();
  const {
    title,
    message,
    onClose = () => {},
    primaryButtonText = 'close',
    secondaryButtonText,
    secondaryButtonAction,
  } = errorMessage ?? {};
  const handlePrimaryClose = () => {
    onClose?.();
    hideErrorModal();
  };
  const handleSecondaryClose = () => {
    secondaryButtonAction?.();
    hideErrorModal();
  };
  const isOpen = errorMessage !== null;
  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={handlePrimaryClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align="center">
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" />
            <br /> {title}
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text align="center">{message}</Text>
        </ModalBody>

        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            {secondaryButtonText && (
              <Button variant="outline" onClick={handleSecondaryClose} ml={2}>
                {secondaryButtonText}
              </Button>
            )}
            <Button onClick={handlePrimaryClose}>{primaryButtonText}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
