// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Login__Decoder from "../Login/Gql__Login__Decoder.bs.js";
import * as Gql__Warning__Decoder from "../Warning/Gql__Warning__Decoder.bs.js";
import * as Gql__Register__Decoder from "../Register/Gql__Register__Decoder.bs.js";
import * as Gql__AlertBanner__Decoder from "../AlertBanner/Gql__AlertBanner__Decoder.bs.js";
import * as Gql__CookiePopup__Decoder from "../CookiePopup/Gql__CookiePopup__Decoder.bs.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Gql__NavMenuItems__Decoder from "../NavMenuItems/Gql__NavMenuItems__Decoder.bs.js";
import * as Gql__NavbarSelectCta__Decoder from "../NavbarSelectCta/Gql__NavbarSelectCta__Decoder.bs.js";
import * as Gql__NoSearchResults__Decoder from "../NoSearchResults/Gql__NoSearchResults__Decoder.bs.js";
import * as Gql__HeaderNavigation__Decoder from "../HeaderNavigation/Gql__HeaderNavigation__Decoder.bs.js";
import * as Gql__Header__Decoder__Homepage from "../Header/Gql__Header__Decoder__Homepage.bs.js";
import * as Gql__HeaderLogo__Decoder__Block from "../HeaderLogo/Gql__HeaderLogo__Decoder__Block.bs.js";
import * as Gql__Footer__Decoder__FooterContainer from "../Footer/Gql__Footer__Decoder__FooterContainer.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "GlobalConfigurationContentType") {
    return ;
  }
  var c = content.VAL;
  var alertBannerData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.alert_banner, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var alertBanner = alertBannerData !== undefined && typeof alertBannerData === "object" && alertBannerData.NAME === "AlertBannerContentType" ? Gql__AlertBanner__Decoder.get(alertBannerData.VAL.alertBannerBlock) : undefined;
  var cookiePopupData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.gdpr_cookie_popup, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var cookiePopup = cookiePopupData !== undefined && typeof cookiePopupData === "object" && cookiePopupData.NAME === "GdprCookiePopupContentType" ? Gql__CookiePopup__Decoder.get(cookiePopupData.VAL.cookiePopupBlock) : undefined;
  var headerBlockData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.navigation_root, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var headerBlock;
  if (headerBlockData !== undefined && typeof headerBlockData === "object") {
    var variant = headerBlockData.NAME;
    headerBlock = variant === "AppContentType" ? Gql__HeaderNavigation__Decoder.get(headerBlockData.VAL.headerNavigation) : (
        variant === "PageContentType" ? Gql__Header__Decoder__Homepage.get(headerBlockData.VAL.headerBlock) : undefined
      );
  } else {
    headerBlock = undefined;
  }
  var businessHeaderData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.business_navigation, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var businessHeader;
  if (businessHeaderData !== undefined && typeof businessHeaderData === "object") {
    var variant$1 = businessHeaderData.NAME;
    businessHeader = variant$1 === "AppContentType" ? Belt_Option.flatMap(Gql__HeaderNavigation__Decoder.get(businessHeaderData.VAL.headerNavigation), (function (arr) {
              var copy = arr.slice();
              copy.unshift({
                    title: "Home",
                    url: "/business/",
                    smartLink: SmartLink.emptySmartLink
                  });
              return copy;
            })) : (
        variant$1 === "PageContentType" ? Gql__Header__Decoder__Homepage.get(businessHeaderData.VAL.headerBlock) : undefined
      );
  } else {
    businessHeader = undefined;
  }
  var mobileNavMenuData = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_navbar_menu_items, (function (c) {
                  return c.value;
                })), []), GqlUtils.id);
  var mobileNavMenuBlock = Belt_Array.keepMap(Belt_Array.map(mobileNavMenuData, (function (c) {
              if (typeof c === "object" && c.NAME === "NavbarMenuItemContentType") {
                return Gql__NavMenuItems__Decoder.get(c.VAL.navMenuBlock);
              }
              
            })), GqlUtils.id);
  var desktopNavMenuData = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_navbar_menu_items, (function (c) {
                  return c.value;
                })), []), GqlUtils.id);
  var desktopNavMenuBlock = Belt_Array.keepMap(Belt_Array.map(desktopNavMenuData, (function (c) {
              if (typeof c === "object" && c.NAME === "NavbarMenuItemContentType") {
                return Gql__NavMenuItems__Decoder.get(c.VAL.navMenuBlock);
              }
              
            })), GqlUtils.id);
  var footerBlockData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.footer, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var footerBlock = footerBlockData !== undefined && typeof footerBlockData === "object" && footerBlockData.NAME === "FooterContainerContentType" ? Gql__Footer__Decoder__FooterContainer.get(footerBlockData.VAL.footerBlock) : undefined;
  var businessFooterData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.business_footer, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var businessFooter = businessFooterData !== undefined && typeof businessFooterData === "object" && businessFooterData.NAME === "FooterContainerContentType" ? Gql__Footer__Decoder__FooterContainer.get(businessFooterData.VAL.footerBlock) : undefined;
  var login = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.login, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), Gql__Login__Decoder.get);
  var register = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.register, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), Gql__Register__Decoder.get);
  var warnings = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.warnings, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__Warning__Decoder.get);
  var headerLogoData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.header_logo, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var headerLogo = headerLogoData !== undefined && typeof headerLogoData === "object" && headerLogoData.NAME === "HeaderLogoContentType" ? Gql__HeaderLogo__Decoder__Block.get(headerLogoData.VAL.headerLogo) : undefined;
  var classicLoginCtaList = Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.not_logged_in_navbar_menu_items___consumer, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (f) {
              if (typeof f !== "object") {
                return ;
              }
              var variant = f.NAME;
              if (variant === "NavbarSelectCtaContentType") {
                return {
                        NAME: "NavbarSelectCTA",
                        VAL: Gql__NavbarSelectCta__Decoder.get(f.VAL.navbarSelectCTA)
                      };
              } else if (variant === "CallToActionLinkD6a3d61ContentType") {
                return {
                        NAME: "CallToAction",
                        VAL: Gql__CallToAction__Decoder.get(f.VAL.callToAction)
                      };
              } else {
                return ;
              }
            })), GqlUtils.id);
  var businessLoginCtaList = Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.not_logged_in_navbar_menu_items___business, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (f) {
              if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
                return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
              }
              
            })), GqlUtils.id);
  var searchBarPlaceholder = Belt_Option.getWithDefault(Belt_Option.flatMap(c.search_bar_placeholder_text, (function (c) {
              return c.value;
            })), "");
  var noSearchResults = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.no_search_result, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "NoSearchResultsContentType") {
            return Gql__NoSearchResults__Decoder.get(f.VAL.noSearchResults);
          }
          
        }));
  var soldOutBadgeText = Belt_Option.getWithDefault(Belt_Option.flatMap(c.sold_out_badge_text, (function (c) {
              return c.value;
            })), "");
  return {
          alertBanner: alertBanner,
          headerBlock: headerBlock,
          businessHeader: businessHeader,
          mobileNavMenuBlock: mobileNavMenuBlock,
          desktopNavMenuBlock: desktopNavMenuBlock,
          footerBlock: footerBlock,
          businessFooter: businessFooter,
          login: login,
          register: register,
          cookiePopup: cookiePopup,
          warnings: warnings,
          headerLogo: headerLogo,
          classicLoginCtaList: classicLoginCtaList,
          businessLoginCtaList: businessLoginCtaList,
          searchBarPlaceholder: searchBarPlaceholder,
          noSearchResults: noSearchResults,
          soldOutBadgeText: soldOutBadgeText
        };
}

var HeaderBlock;

var HeaderNavigation;

var NavMenuBlock;

var FooterBlock;

var AlertBanner;

var Login;

var Register;

var CookiePopup;

var Warning;

var HeaderLogo;

var CallToAction;

var NavbarSelectCTA;

var NoSearchResults;

export {
  HeaderBlock ,
  HeaderNavigation ,
  NavMenuBlock ,
  FooterBlock ,
  AlertBanner ,
  Login ,
  Register ,
  CookiePopup ,
  Warning ,
  HeaderLogo ,
  CallToAction ,
  NavbarSelectCTA ,
  NoSearchResults ,
  get ,
}
/* Gql__AlertBanner__Decoder Not a pure module */
