// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../bindings/Pino.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as ApolloClient from "rescript-apollo-client/src/ApolloClient.bs.js";
import * as ApolloClient__Link_Error from "rescript-apollo-client/src/@apollo/client/link/error/ApolloClient__Link_Error.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__Link_Http_HttpLink from "rescript-apollo-client/src/@apollo/client/link/http/ApolloClient__Link_Http_HttpLink.bs.js";
import * as ApolloClient__Cache_InMemory_InMemoryCache from "rescript-apollo-client/src/@apollo/client/cache/inmemory/ApolloClient__Cache_InMemory_InMemoryCache.bs.js";

var isServer = (typeof window === 'undefined');

var errorLink = ApolloClient__Link_Error.ErrorLink.make(function (param) {
      var networkError = param.networkError;
      var graphQLErrors = param.graphQLErrors;
      if (Belt_Option.isSome(networkError) && isServer) {
        Pino.logger.error({
              err: networkError,
              type: "Apollo"
            }, "error: Graphql network error");
      }
      if (Belt_Option.isSome(graphQLErrors) && isServer) {
        Pino.logger.error({
              err: graphQLErrors,
              type: "Apollo"
            }, "error: Graphql error");
      }
      return Caml_option.some(Curry._1(param.forward, param.operation));
    });

var httpLink = ApolloClient__Link_Http_HttpLink.make((function (param) {
        return process.env.NEXT_PUBLIC_APOLLO_API_ENDPOINT;
      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var client = ApolloClient.make(undefined, undefined, undefined, Caml_option.some(ReasonMLCommunity__ApolloClient.Link.from([
              errorLink,
              httpLink
            ])), ApolloClient__Cache_InMemory_InMemoryCache.make(undefined, undefined, undefined, false, undefined, undefined), undefined, true, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

export {
  isServer ,
  errorLink ,
  httpLink ,
  client ,
}
/* isServer Not a pure module */
