// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function get(c) {
  var displayBadge = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.display_badge, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "no", (function (str) {
          return str;
        }));
  var badgeAlignment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.badge_alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var badgeTextColor = Belt_Option.getWithDefault(Belt_Option.flatMap(c.badge_text_colour, (function (c) {
              return c.value;
            })), "");
  var badgeBackgroundColor = Belt_Option.getWithDefault(Belt_Option.flatMap(c.badge_background_colour, (function (c) {
              return c.value;
            })), "");
  var badgeOutlineColor = Belt_Option.getWithDefault(Belt_Option.flatMap(c.badge_outline_colour, (function (c) {
              return c.value;
            })), "");
  var badgeText = Belt_Option.getWithDefault(Belt_Option.flatMap(c.badge_text, (function (c) {
              return c.value;
            })), "");
  var bgColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  var bgColour2 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour_2, (function (c) {
              return c.value;
            })), "");
  var copyBlockAlignment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.copy_block_alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var copyBlockAlignment2 = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.copy_block_alignment_2, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var imageTreatment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image_treatment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "edge_to_edge", (function (str) {
          return str;
        }));
  var imageTreatment2 = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image_treatment_2, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "edge_to_edge", (function (str) {
          return str;
        }));
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var title2 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_2, (function (c) {
              return c.value;
            })), "");
  var highlightTextColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.highlight_text_colour, (function (c) {
              return c.value;
            })), "");
  var highlightTextColour2 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.highlight_text_colour_2, (function (c) {
              return c.value;
            })), "");
  var titleFont = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_font_style, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "sofia_pro", (function (str) {
          return str;
        }));
  var titleFont2 = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_font_style_2, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "sofia_pro", (function (str) {
          return str;
        }));
  var titleTextColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_text_colour, (function (c) {
              return c.value;
            })), "");
  var titleTextColour2 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_text_colour_2, (function (c) {
              return c.value;
            })), "");
  var titleFontSize = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_font_size, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "xxl", (function (str) {
          return str;
        }));
  var titleFontSize2 = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_font_size_2, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "xxl", (function (str) {
          return str;
        }));
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var description2 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description_2, (function (c) {
              return c.value;
            })), "");
  var descriptionTextColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description_text_colour, (function (c) {
              return c.value;
            })), "");
  var descriptionTextColour2 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description_text_colour_2, (function (c) {
              return c.value;
            })), "");
  var descriptionFontSize = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.description_font_size, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "regular", (function (str) {
          return str;
        }));
  var descriptionFontSize2 = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.description_font_size_2, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "regular", (function (str) {
          return str;
        }));
  var buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_s, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var buttons2 = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_s_2, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_image, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description$1,
    url: url,
    width: width,
    height: height
  };
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_image, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.name;
            })), "");
  var description$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.description;
            })), "");
  var url$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.url;
            })), "");
  var width$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.width;
            })), 0);
  var height$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.height;
            })), 0);
  var result$1 = {
    name: name$1,
    description: description$2,
    url: url$1,
    width: width$1,
    height: height$1
  };
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_image, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.name;
            })), "");
  var description$3 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.description;
            })), "");
  var url$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.url;
            })), "");
  var width$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.width;
            })), 0);
  var height$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.height;
            })), 0);
  var result$2 = {
    name: name$2,
    description: description$3,
    url: url$2,
    width: width$2,
    height: height$2
  };
  var value$3 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_image_2, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$3 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$3, (function (f) {
              return f.name;
            })), "");
  var description$4 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$3, (function (f) {
              return f.description;
            })), "");
  var url$3 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$3, (function (f) {
              return f.url;
            })), "");
  var width$3 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$3, (function (f) {
              return f.width;
            })), 0);
  var height$3 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$3, (function (f) {
              return f.height;
            })), 0);
  var result$3 = {
    name: name$3,
    description: description$4,
    url: url$3,
    width: width$3,
    height: height$3
  };
  var value$4 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_image_2, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$4 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$4, (function (f) {
              return f.name;
            })), "");
  var description$5 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$4, (function (f) {
              return f.description;
            })), "");
  var url$4 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$4, (function (f) {
              return f.url;
            })), "");
  var width$4 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$4, (function (f) {
              return f.width;
            })), 0);
  var height$4 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$4, (function (f) {
              return f.height;
            })), 0);
  var result$4 = {
    name: name$4,
    description: description$5,
    url: url$4,
    width: width$4,
    height: height$4
  };
  var value$5 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_image_2, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$5 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$5, (function (f) {
              return f.name;
            })), "");
  var description$6 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$5, (function (f) {
              return f.description;
            })), "");
  var url$5 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$5, (function (f) {
              return f.url;
            })), "");
  var width$5 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$5, (function (f) {
              return f.width;
            })), 0);
  var height$5 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$5, (function (f) {
              return f.height;
            })), 0);
  var result$5 = {
    name: name$5,
    description: description$6,
    url: url$5,
    width: width$5,
    height: height$5
  };
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          smartLink: smartLink,
          leftGroup: {
            displayBadge: displayBadge,
            badgeAlignment: badgeAlignment,
            badgeText: badgeText,
            badgeTextColor: badgeTextColor,
            badgeBackgroundColor: badgeBackgroundColor,
            badgeOutlineColor: badgeOutlineColor,
            bgColour: bgColour,
            copyBlockAlignment: copyBlockAlignment,
            imageTreatment: imageTreatment,
            title: title,
            highlightTextColour: highlightTextColour,
            titleFont: titleFont,
            titleTextColour: titleTextColour,
            titleFontSize: titleFontSize,
            description: description,
            descriptionTextColour: descriptionTextColour,
            descriptionFontSize: descriptionFontSize,
            buttons: buttons,
            imageDesktop: result,
            imageTablet: result$1,
            imageMobile: result$2
          },
          rightGroup: {
            bgColour: bgColour2,
            copyBlockAlignment: copyBlockAlignment2,
            imageTreatment: imageTreatment2,
            title: title2,
            highlightTextColour: highlightTextColour2,
            titleFont: titleFont2,
            titleTextColour: titleTextColour2,
            titleFontSize: titleFontSize2,
            description: description2,
            descriptionTextColour: descriptionTextColour2,
            descriptionFontSize: descriptionFontSize2,
            buttons: buttons2,
            imageDesktop: result$3,
            imageTablet: result$4,
            imageMobile: result$5
          }
        };
}

export {
  get ,
}
/* No side effect */
