// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

var emptyButton = {
  cta_text: "Subscribe",
  cta_url: "",
  cta_variant: "",
  variant: /* Regular */0,
  colour: /* Red */0,
  fill: /* Contained */0,
  open_in_new_window: false,
  smartLink: SmartLink.emptySmartLink
};

function get(content) {
  if (typeof content === "object" && content.NAME === "CallToActionLinkD6a3d61ContentType") {
    return Gql__CallToAction__Decoder.get(content.VAL.callToAction);
  }
  
}

export {
  emptyButton ,
  get ,
}
/* No side effect */
