// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment NavbarSelectCTA on NavbarSelectCtaContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    select_cta  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    option_ctas  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
  }
  ${frag_0}
`)(Gql__CallToAction__Fragment.CallToAction.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.select_cta;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$3) : undefined
    };
  }
  var value$4 = value.option_ctas;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$5) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          select_cta: tmp,
          option_ctas: tmp$1
        };
}

function serialize(value) {
  var value$1 = value.option_ctas;
  var option_ctas;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    option_ctas = {
      __typename: value$4,
      value: value$3
    };
  } else {
    option_ctas = null;
  }
  var value$5 = value.select_cta;
  var select_cta;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    select_cta = {
      __typename: value$8,
      value: value$7
    };
  } else {
    select_cta = null;
  }
  var value$9 = value.system;
  var value$10 = value$9.codename;
  var value$11 = value$9.id;
  var value$12 = value$9.__typename;
  var system = {
    __typename: value$12,
    id: value$11,
    codename: value$10
  };
  var value$13 = value.__typename;
  return {
          __typename: value$13,
          system: system,
          select_cta: select_cta,
          option_ctas: option_ctas
        };
}

function verifyArgsAndParse(_NavbarSelectCTA, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var NavbarSelectCTA = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var CallToAction;

export {
  CallToAction ,
  NavbarSelectCTA ,
}
/* query Not a pure module */
