// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function parse_cta_style(style) {
  switch (style) {
    case "business_ghost_charcoal" :
        return [
                /* Regular */0,
                /* BusinessCharcoal */4,
                /* Outlined */1
              ];
    case "business_primary_purple" :
        return [
                /* Regular */0,
                /* BusinessPurple */3,
                /* Contained */0
              ];
    case "ghost_charcoal" :
        return [
                /* Regular */0,
                /* Charcoal */1,
                /* Outlined */1
              ];
    case "ghost_red" :
        return [
                /* Regular */0,
                /* Red */0,
                /* Outlined */1
              ];
    case "primary_charcoal" :
        return [
                /* Regular */0,
                /* Charcoal */1,
                /* Contained */0
              ];
    case "primary_red" :
        return [
                /* Regular */0,
                /* Red */0,
                /* Contained */0
              ];
    case "primary_regular" :
        return [
                /* Regular */0,
                /* Primary */5,
                /* Contained */0
              ];
    case "primary_regular_ghost" :
        return [
                /* Regular */0,
                /* Primary */5,
                /* Outlined */1
              ];
    case "primary_small" :
        return [
                /* Small */1,
                /* Primary */5,
                /* Contained */0
              ];
    case "primary_small_ghost" :
        return [
                /* Small */1,
                /* Primary */5,
                /* Outlined */1
              ];
    case "secondary_charcoal" :
        return [
                /* Small */1,
                /* Charcoal */1,
                /* Contained */0
              ];
    case "secondary_red" :
        return [
                /* Small */1,
                /* Red */0,
                /* Contained */0
              ];
    case "secondary_regular" :
        return [
                /* Regular */0,
                /* Secondary */6,
                /* Contained */0
              ];
    case "secondary_regular_ghost" :
        return [
                /* Regular */0,
                /* Secondary */6,
                /* Outlined */1
              ];
    case "secondary_small" :
        return [
                /* Small */1,
                /* Secondary */6,
                /* Contained */0
              ];
    case "secondary_small_ghost" :
        return [
                /* Small */1,
                /* Secondary */6,
                /* Outlined */1
              ];
    case "tertiary_regular" :
        return [
                /* Regular */0,
                /* Tertiary */7,
                /* Contained */0
              ];
    case "tertiary_regular_ghost" :
        return [
                /* Regular */0,
                /* Tertiary */7,
                /* Outlined */1
              ];
    case "tertiary_small" :
        return [
                /* Small */1,
                /* Tertiary */7,
                /* Contained */0
              ];
    case "tertiary_small_ghost" :
        return [
                /* Small */1,
                /* Tertiary */7,
                /* Outlined */1
              ];
    case "video_play" :
        return [
                /* Icon */2,
                /* Charcoal */1,
                /* Outlined */1
              ];
    case "white_regular" :
        return [
                /* Regular */0,
                /* White */2,
                /* Contained */0
              ];
    case "white_regular_ghost" :
        return [
                /* Regular */0,
                /* White */2,
                /* Outlined */1
              ];
    case "white_small" :
        return [
                /* Small */1,
                /* White */2,
                /* Contained */0
              ];
    case "white_small_ghost" :
        return [
                /* Small */1,
                /* White */2,
                /* Outlined */1
              ];
    default:
      return [
              /* Regular */0,
              /* Primary */5,
              /* Contained */0
            ];
  }
}

var emptyButton = {
  cta_text: "",
  cta_url: "",
  cta_variant: "",
  variant: /* Regular */0,
  colour: /* Red */0,
  fill: /* Contained */0,
  open_in_new_window: false,
  smartLink: SmartLink.emptySmartLink
};

function get(c) {
  var cta_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_text, (function (c) {
              return c.value;
            })), "");
  var cta_url = Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_url, (function (f) {
              return f.value;
            })), "");
  var open_in_new_window = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.open_in_new_window, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), false, (function (str) {
          if (str === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  var match = parse_cta_style(Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_style__style, (function (c) {
                                  return c.value;
                                })), []), GqlUtils.id), (function (v) {
                      return v.codename;
                    })), 0), "primary_regular"));
  var cta_variant = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_style__style, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), "primary_regular");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          cta_text: cta_text,
          cta_url: cta_url,
          cta_variant: cta_variant,
          variant: match[0],
          colour: match[1],
          fill: match[2],
          open_in_new_window: open_in_new_window,
          smartLink: smartLink
        };
}

export {
  parse_cta_style ,
  emptyButton ,
  get ,
}
/* No side effect */
