// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../bindings/Pino.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function giftTheme(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          uid: Json_decode.field("uid", Json_decode.string, json),
          thumbImage: Json_decode.field("thumb_image", Json_decode.string, json),
          squareIcon: Json_decode.field("square_icon", Json_decode.string, json),
          textureImage: Json_decode.field("texture_image", Json_decode.string, json),
          keyColour: Json_decode.field("key_colour", Json_decode.string, json)
        };
}

function giftThemes(json) {
  return {
          giftThemes: Json_decode.field("giftthemes", (function (param) {
                  return Json_decode.array(giftTheme, param);
                }), json)
        };
}

function safeDecodeGiftData(json) {
  var result;
  try {
    result = giftThemes(json);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      Pino.logger.error({
            err: e._1,
            type: "Safe Decode Gift Themes Data"
          }, "error: Could not decode response from the server");
      return ;
    }
    throw e;
  }
  return result;
}

var Decode = {
  giftTheme: giftTheme,
  giftThemes: giftThemes,
  safeDecodeGiftData: safeDecodeGiftData
};

export {
  Decode ,
}
/* Pino Not a pure module */
