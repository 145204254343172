// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

function promotionResult(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          slug: Json_decode.field("slug", Json_decode.string, json),
          category: Json_decode.field("type", Json_decode.string, json)
        };
}

function promotionTerms(json) {
  return {
          results: Json_decode.field("results", (function (param) {
                  return Json_decode.array(promotionResult, param);
                }), json)
        };
}

function singlePromotionTerm(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          htmlTitle: Json_decode.field("html_title", Json_decode.string, json),
          metaDescription: Json_decode.field("meta_description", Json_decode.string, json),
          content: Json_decode.field("content", Json_decode.string, json)
        };
}

var Decode = {
  promotionResult: promotionResult,
  promotionTerms: promotionTerms,
  singlePromotionTerm: singlePromotionTerm
};

export {
  Decode ,
}
/* No side effect */
