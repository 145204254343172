// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__TestimonialCarouselBlock__Decoder__ImageGrid from "./Gql__TestimonialCarouselBlock__Decoder__ImageGrid.bs.js";
import * as Gql__TestimonialCarouselBlock__Decoder__CarouselItem from "./Gql__TestimonialCarouselBlock__Decoder__CarouselItem.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var testimonial_image_grid = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.testimonial_image_grid, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__TestimonialCarouselBlock__Decoder__ImageGrid.get), 0);
  var testimonial_carousel_items = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.testimonial_carousel_items, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__TestimonialCarouselBlock__Decoder__CarouselItem.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          testimonial_image_grid: testimonial_image_grid,
          testimonial_carousel_items: testimonial_carousel_items,
          smartLink: smartLink
        };
}

var $$Image;

var CarouselItem;

var ImageGrid;

var ImageCollection;

export {
  $$Image ,
  CarouselItem ,
  ImageGrid ,
  ImageCollection ,
  get ,
}
/* No side effect */
