// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Footer__Decoder__LinkItemIcon from "./Gql__Footer__Decoder__LinkItemIcon.bs.js";

function get(linkItem) {
  var link_text = Belt_Option.getWithDefault(Belt_Option.flatMap(linkItem.link_text, (function (c) {
              return c.value;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(linkItem.url, (function (c) {
              return c.value;
            })), "");
  var open_in_new_window = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(linkItem.open_in_new_window, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), false, (function (str) {
          if (str === "yes") {
            return true;
          } else {
            return false;
          }
        }));
  var icon = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(linkItem.links, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), Gql__Footer__Decoder__LinkItemIcon.get);
  var smartLink_itemId = linkItem.system.id;
  var smartLink_codename = linkItem.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          link_text: link_text,
          url: url,
          open_in_new_window: open_in_new_window,
          icon: icon,
          smartLink: smartLink
        };
}

var LinkItemIcon;

export {
  LinkItemIcon ,
  get ,
}
/* No side effect */
