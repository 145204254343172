// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var background_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), undefined, (function (c) {
          if (c === "") {
            return ;
          } else {
            return c;
          }
        }));
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_background_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var desktop_background;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description$1 = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    desktop_background = {
      name: name,
      description: description$1,
      url: url,
      width: width,
      height: height
    };
  } else {
    desktop_background = undefined;
  }
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_background_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var tablet_background;
  if (value$1 !== undefined) {
    var name$1 = Belt_Option.getWithDefault(value$1.name, "");
    var description$2 = Belt_Option.getWithDefault(value$1.description, "");
    var url$1 = Belt_Option.getWithDefault(value$1.url, "");
    var width$1 = Belt_Option.getWithDefault(value$1.width, 0);
    var height$1 = Belt_Option.getWithDefault(value$1.height, 0);
    tablet_background = {
      name: name$1,
      description: description$2,
      url: url$1,
      width: width$1,
      height: height$1
    };
  } else {
    tablet_background = undefined;
  }
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_background_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var mobile_background;
  if (value$2 !== undefined) {
    var name$2 = Belt_Option.getWithDefault(value$2.name, "");
    var description$3 = Belt_Option.getWithDefault(value$2.description, "");
    var url$2 = Belt_Option.getWithDefault(value$2.url, "");
    var width$2 = Belt_Option.getWithDefault(value$2.width, 0);
    var height$2 = Belt_Option.getWithDefault(value$2.height, 0);
    mobile_background = {
      name: name$2,
      description: description$3,
      url: url$2,
      width: width$2,
      height: height$2
    };
  } else {
    mobile_background = undefined;
  }
  var buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta_button_s, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          description: description,
          background_colour: background_colour,
          desktop_background: desktop_background,
          tablet_background: tablet_background,
          mobile_background: mobile_background,
          buttons: buttons,
          smartLink: smartLink
        };
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
