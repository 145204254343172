import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { CustomAttributes, FeatureDefinitions, GrowthbookFeatureProvider } from './GrowthBookContext';
import Cookies from 'js-cookie';
import useAuth from './AuthContext';
import useHashedValue from 'hooks/useHashedValue';

type GrowthbookFeaturesWrapperProps = {
  children: ReactNode;
};

export const ConsumerFeaturesProvider = ({ children }: GrowthbookFeaturesWrapperProps) => {
  const auth = useAuth();
  const featureDefinitions = useFeatureDefinitions();
  const deviceId = Cookies.get('fingerprint-pz');
  const country = process.env.NEXT_PUBLIC_APP_COUNTRY_CODE;
  const emailHash = useHashedValue(auth.user?.email.toLowerCase());

  const attributes: CustomAttributes = useMemo(
    () => ({
      emailHashed: emailHash?.slice(0, 16),
      deviceId,
      country,
    }),
    [deviceId, country, emailHash]
  );

  return (
    <GrowthbookFeatureProvider
      enableDevMode={process.env.NEXT_PUBLIC_GROWTHBOOK_DEV_MODE === 'true'}
      features={featureDefinitions}
      attributes={attributes}
    >
      {children}
    </GrowthbookFeatureProvider>
  );
};

const useFeatureDefinitions = () => {
  const [featureDefinitions, setFeatureDefinitions] = useState<FeatureDefinitions | undefined>();
  const growthbookUrl =
    process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://features.prezzeeapis.com/features/staging-general'
      : process.env.NEXT_PUBLIC_CLIENT_GROWTHBOOK_FEATURES_URL ||
        'https://features.prezzeeapis.com/features/production-general'; // As we're unable to uses branch level variables hardcoding staging for

  useEffect(() => {
    fetch(growthbookUrl)
      .then(response => response.json())
      .then(({ features }) => {
        setFeatureDefinitions(features);
      });
  }, []);

  return featureDefinitions;
};
