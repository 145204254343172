// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Util from "../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Belt_Result from "@rescript/std/lib/es6/belt_Result.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as FormHelpers from "../utils/FormHelpers.bs.js";
import * as IndexStyles from "../components/pages/index/IndexStyles.bs.js";
import * as StaticImage from "../components/StaticImage.bs.js";
import * as LayoutDataContext from "../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CookieSettingButton from "../components/CookieSettingButton.bs.js";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Footer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../components/pages/index/KontentContextProvider.bs.js";
import * as NewsletterSubscription from "../components/library/NewsletterSubscription.bs.js";
import * as TextField$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TextField/TextField.bs.js";
import * as Footer__Link$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Link.bs.js";
import * as ThemeContext$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext.bs.js";
import * as Footer__Column$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Column.bs.js";
import * as Footer__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Heading.bs.js";
import * as Gql__DictionaryItems__Decoder from "../api/graphql/DictionaryItems/Gql__DictionaryItems__Decoder.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as Footer__LinkList$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__LinkList.bs.js";
import * as Footer__Copyright$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Copyright.bs.js";
import * as Footer__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Responsive.bs.js";
import * as Footer__SocialIcon$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__SocialIcon.bs.js";
import * as RegionListLineContent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionList/RegionListLineContent.bs.js";
import * as RegionIndicatorTextual$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorTextual.bs.js";
import * as RegionIndicatorCountryDisplay$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionIndicator/RegionIndicatorCountryDisplay.bs.js";

var subscribeColumn = Curry._1(Css.style, {
      hd: Css.media(Footer__Responsive$PrezzeeRibbon.mobile, {
            hd: Css.gridRowStart(1),
            tl: {
              hd: Css.important(Css.unsafe("gridColumn", "span 1")),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: Css.media(Footer__Responsive$PrezzeeRibbon.tablet, {
              hd: Css.important(Css.unsafe("gridColumn", "span 2")),
              tl: {
                hd: Css.unsafe("gridArea", "1/2"),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var successText = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(18)),
      tl: {
        hd: Css.lineHeight(Css.px(24)),
        tl: {
          hd: Css.backgroundColor(Css.rgba(255, 255, 255, {
                    NAME: "num",
                    VAL: 0.1
                  })),
          tl: {
            hd: Css.borderRadius(Css.px(100)),
            tl: {
              hd: Css.width(Css.px(250)),
              tl: {
                hd: Css.height(Css.px(38)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var emailField = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(10)),
      tl: {
        hd: Css.flexGrow(1),
        tl: {
          hd: Css.flexShrink(1),
          tl: {
            hd: Css.marginBottom(Css.px(25)),
            tl: /* [] */0
          }
        }
      }
    });

var isoColumn = Curry._1(Css.style, {
      hd: Css.media(Footer__Responsive$PrezzeeRibbon.tablet, {
            hd: Css.gridArea({
                  NAME: "num",
                  VAL: 1
                }),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.media(Footer__Responsive$PrezzeeRibbon.mobile, {
              hd: Css.gridArea({
                    NAME: "num",
                    VAL: 2
                  }),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var isoImage = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.media(Footer__Responsive$PrezzeeRibbon.tablet, {
              hd: Css.width(Css.px(110)),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.media(Footer__Responsive$PrezzeeRibbon.mobile, {
                hd: Css.width(Css.px(110)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var linkColumn = Curry._1(Css.style, {
      hd: Css.whiteSpace("nowrap"),
      tl: /* [] */0
    });

var section = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "repeat",
                VAL: [
                  {
                    NAME: "num",
                    VAL: 7
                  },
                  {
                    NAME: "fr",
                    VAL: 1
                  }
                ]
              },
              tl: /* [] */0
            }),
        tl: {
          hd: Css.gridColumnGap({
                NAME: "percent",
                VAL: 5
              }),
          tl: {
            hd: Css.media(Footer__Responsive$PrezzeeRibbon.tablet, {
                  hd: Css.gridTemplateColumns({
                        hd: {
                          NAME: "repeat",
                          VAL: [
                            {
                              NAME: "num",
                              VAL: 3
                            },
                            {
                              NAME: "fr",
                              VAL: 1
                            }
                          ]
                        },
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.media(Footer__Responsive$PrezzeeRibbon.mobile, {
                    hd: Css.gridTemplateColumns({
                          hd: {
                            NAME: "fr",
                            VAL: 1
                          },
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles = {
  subscribeColumn: subscribeColumn,
  successText: successText,
  emailField: emailField,
  isoColumn: isoColumn,
  isoImage: isoImage,
  linkColumn: linkColumn,
  section: section
};

function linkListIcon(lli) {
  if (lli.TAG !== /* FontAwesomeIcon */0) {
    return null;
  }
  var fa = lli._0;
  return JsxRuntime.jsx(Footer__SocialIcon$PrezzeeRibbon.make, {
              children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                    icon: [
                      fa.style,
                      fa.icon
                    ]
                  })
            });
}

function linkItem(ll) {
  var icon = Belt_Option.getWithDefault(Belt_Option.map(ll.icon, linkListIcon), null);
  var match = ll.smartLink;
  var itemId = match.itemId;
  var href = ll.url;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Footer__Link$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsxs("a", {
                          children: [
                            icon,
                            ll.link_text
                          ],
                          href: href,
                          target: ll.open_in_new_window ? "_blank" : "_self"
                        })
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function mailtoItem(ll) {
  var match = ll.smartLink;
  var itemId = match.itemId;
  var href = "mailto:" + ll.email_address;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Footer__Link$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsx("a", {
                          children: ll.link_text,
                          href: href
                        })
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function cookieSettingItem(ll) {
  var match = ll.smartLink;
  var itemId = match.itemId;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Footer__Link$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsx(CookieSettingButton.make, {
                          text: ll.text
                        })
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function linkList(ll) {
  var variant = ll.NAME;
  if (variant === "MailtoLinkContentType") {
    return mailtoItem(ll.VAL);
  } else if (variant === "OnetrustCookieSettingButtonContentType") {
    return cookieSettingItem(ll.VAL);
  } else {
    return linkItem(ll.VAL);
  }
}

function columns(col) {
  var match = col.smartLink;
  var itemId = match.itemId;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsxs(Footer__Column$PrezzeeRibbon.make, {
                    children: [
                      JsxRuntime.jsx(Kontent$PrezzeeRibbon.Context.Provider.make, {
                            children: JsxRuntime.jsx(Footer__Heading$PrezzeeRibbon.make, {
                                  children: col.title
                                }),
                            elementCodename: "title"
                          }),
                      JsxRuntime.jsx(Footer__LinkList$PrezzeeRibbon.make, {
                            children: Belt_Array.map(col.links, linkList)
                          })
                    ],
                    className: linkColumn
                  }),
              itemId: itemId,
              elementCodename: match.codename
            }, itemId);
}

function removeTrailingSlash(url) {
  var slashIndex = url.lastIndexOf("/");
  if (slashIndex === (url.length - 1 | 0)) {
    return url.substring(0, slashIndex);
  } else {
    return url;
  }
}

var isStg = process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL.includes("stg");

function toStgUrl(url) {
  return url.replace("https://www.prezzee", "https://stg.prezzee");
}

function BusinessFooter$NewsletterForm(props) {
  var form = props.form;
  var state = LayoutDataContext.useContext(undefined);
  var footer = Belt_Option.flatMap(state, (function (s) {
          return s.businessFooter;
        }));
  var subscribe_helper_text = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.subscribe_helper_text;
            })), "");
  var subscribe_cta = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.subscribe_cta;
            })), "");
  var match = Belt_Option.mapWithDefault(footer, [
        /* Regular */0,
        /* White */2,
        /* Contained */0
      ], (function (f) {
          return f.subscribe_cta_style;
        }));
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(TextField$PrezzeeRibbon.make, {
                            className: emailField,
                            label: subscribe_helper_text,
                            name: "email_address",
                            theme: /* Dark */1,
                            type_: "email",
                            error: Belt_Option.isSome(FormHelpers.getFieldError(form.errors, "email_address")),
                            helperText: FormHelpers.getFieldError(form.errors, "email_address"),
                            inputRef: Caml_option.some(Curry._1(form.register, NewsletterSubscription.newsletterInput))
                          }),
                      JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                            children: JsxRuntime.jsx("button", {
                                  children: Util.ReactStuff.s(subscribe_cta),
                                  disabled: form.formState.isSubmitted,
                                  type: "submit"
                                }),
                            fill: match[2],
                            colour: match[1],
                            variant: match[0]
                          })
                    ],
                    className: "flex flex-wrap"
                  }),
              onSubmit: Curry._2(form.handleSubmit, props.onSubmit, FormHelpers.onError)
            });
}

var NewsletterForm = {
  make: BusinessFooter$NewsletterForm
};

function BusinessFooter(props) {
  var data = props.data;
  var state = LayoutDataContext.useContext(undefined);
  var theme = ThemeContext$PrezzeeRibbon.useTheme(undefined);
  var footer = data !== undefined ? data : Belt_Option.flatMap(state, (function (s) {
            return s.businessFooter;
          }));
  var footerItemId = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.smartLink.itemId;
            })), "");
  var footerCodename = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.smartLink.codename;
            })), "");
  var subscribe_title = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.subscribe_title;
            })), "");
  var region_selector_text = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
              return f.region_selector_text;
            })), "");
  var currentYear = (new Date().getFullYear() | 0).toString();
  var disclaimer_level_1 = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
                return f.disclaimer_level_1;
              })), "").replace("{0}", currentYear);
  var disclaimer_level_2 = Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
                return f.disclaimer_level_2;
              })), "").replace("{0}", currentYear);
  var regions = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.regions;
            })), []);
  var domainPostfix = Belt_Result.mapWithDefault(Util.EnvUtil.getCountry(undefined), "", (function (c) {
          return Util.EnvUtil.getDomainPostfix(c);
        }));
  var regionIndex = Belt_Option.getWithDefault(Belt_Array.getIndexBy(regions, (function (region) {
              return removeTrailingSlash(region.url).endsWith(domainPostfix);
            })), 0);
  var match = React.useState(function () {
        return regionIndex;
      });
  var setSelectedRegion = match[1];
  var selectedRegion = match[0];
  var onChange = function (selected) {
    Curry._1(setSelectedRegion, (function (param) {
            return selected;
          }));
    var region = Belt_Array.get(regions, selected);
    var url = Belt_Option.getWithDefault(Belt_Option.map(region, (function (r) {
                if (isStg) {
                  return r.url.replace("https://www.prezzee", "https://stg.prezzee");
                } else {
                  return r.url;
                }
              })), "");
    var changeLocation = (function cl(url) { window.location.assign(url) });
    return changeLocation(url);
  };
  var newsletterSuccess = JsxRuntime.jsx("p", {
        children: Util.ReactStuff.s("Woohoo! You're on the list."),
        className: successText
      });
  var regionIndicator = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(state, (function (s) {
                  return s.dictionaryItems;
                })), (function (s) {
              return s.app_yourcurrentshoppingregion;
            })), {
        value: "You're currently shopping in {0}",
        smartLink: Gql__DictionaryItems__Decoder.emptyDictionaryEntry.smartLink
      }, (function (param) {
          var smartLink = param.smartLink;
          var value = param.value;
          if (value === "") {
            return {
                    value: "You're currently shopping in {0}",
                    smartLink: smartLink
                  };
          } else {
            return {
                    value: value,
                    smartLink: smartLink
                  };
          }
        }));
  var regionIndicatorText = regionIndicator.value;
  var match$1 = regionIndicator.smartLink;
  var regionModalItemId = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.smartLink.itemId;
            })), "");
  var regionModalCodename = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.smartLink.codename;
            })), "");
  var regionPickerTitle = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.title;
            })), "Change location");
  var regionPickerSubtitle = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.subtitle;
            })), "Want to send gift cards to family and friends in another country? Change your region below.");
  var regionPickerCtaText = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(state, (function (r) {
                  return r.location;
                })), (function (r) {
              return r.ctaText;
            })), "Save location");
  var regionPickerStrings = {
    heading: regionPickerTitle,
    heading_subtext: regionPickerSubtitle,
    cta_button: regionPickerCtaText
  };
  var regionListLines = Belt_Array.map(regions, (function (r) {
          var match = r.regionListLine;
          var smartLink = match.smartLink;
          var flag = match.flag;
          var currency = match.currency;
          var name = match.name;
          var el = JsxRuntime.jsx(KontentContextProvider.make, {
                children: JsxRuntime.jsx(RegionListLineContent$PrezzeeRibbon.make, {
                      flag: flag,
                      name: name,
                      currency: currency
                    }),
                itemId: smartLink.itemId,
                elementCodename: smartLink.codename
              });
          return {
                  name: name,
                  currency: currency,
                  flag: flag,
                  el: el
                };
        }));
  return JsxRuntime.jsx("footer", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.map(footer, (function (f) {
                                      return f.link_columns;
                                    })), []), columns),
                      JsxRuntime.jsx(Footer__Column$PrezzeeRibbon.make, {
                            children: JsxRuntime.jsx(Footer__Heading$PrezzeeRibbon.make, {
                                  children: JsxRuntime.jsx(StaticImage.make, {
                                        src: "/images/lrqa_badge.png",
                                        alt: "LRQA certification badge",
                                        width: 110,
                                        height: 119,
                                        className: isoImage
                                      })
                                }),
                            colWidth: 1,
                            className: isoColumn
                          }),
                      JsxRuntime.jsxs(Footer__Column$PrezzeeRibbon.make, {
                            children: [
                              JsxRuntime.jsxs(KontentContextProvider.make, {
                                    children: [
                                      JsxRuntime.jsx(Footer__Heading$PrezzeeRibbon.make, {
                                            children: Util.ReactStuff.s(subscribe_title)
                                          }),
                                      JsxRuntime.jsx(NewsletterSubscription.make, {
                                            formComponent: BusinessFooter$NewsletterForm,
                                            successElement: newsletterSuccess,
                                            store: "business"
                                          })
                                    ],
                                    itemId: footerItemId,
                                    elementCodename: footerCodename
                                  }),
                              JsxRuntime.jsx(KontentContextProvider.make, {
                                    children: JsxRuntime.jsx(RegionIndicatorTextual$PrezzeeRibbon.make, {
                                          regions: regionListLines,
                                          strings: regionPickerStrings,
                                          selected: selectedRegion,
                                          children: JsxRuntime.jsx(KontentContextProvider.make, {
                                                children: JsxRuntime.jsx(RegionIndicatorCountryDisplay$PrezzeeRibbon.make, {
                                                      text: regionIndicatorText,
                                                      regions: regionListLines,
                                                      selected: selectedRegion
                                                    }),
                                                itemId: match$1.itemId,
                                                elementCodename: match$1.codename
                                              }),
                                          onChange: onChange,
                                          text: region_selector_text
                                        }),
                                    itemId: regionModalItemId,
                                    elementCodename: regionModalCodename
                                  })
                            ],
                            colWidth: 3,
                            className: subscribeColumn
                          }),
                      JsxRuntime.jsxs(Footer__Copyright$PrezzeeRibbon.make, {
                            children: [
                              disclaimer_level_1 !== "" ? JsxRuntime.jsx("div", {
                                      children: disclaimer_level_1
                                    }) : null,
                              disclaimer_level_2 !== "" ? JsxRuntime.jsx("div", {
                                      children: disclaimer_level_2
                                    }) : null
                            ]
                          })
                    ],
                    className: Cn.make([
                          IndexStyles.section,
                          section
                        ])
                  }),
              className: Footer$PrezzeeRibbon.Styles.footerBlock(theme)
            });
}

var Kontent;

var Footer;

var RegionIndicatorTextual;

var RegionIndicatorCountryDisplay;

var RegionPicker;

var FooterContainer;

var make = BusinessFooter;

export {
  Kontent ,
  Footer ,
  RegionIndicatorTextual ,
  RegionIndicatorCountryDisplay ,
  RegionPicker ,
  FooterContainer ,
  Styles ,
  linkListIcon ,
  linkItem ,
  mailtoItem ,
  cookieSettingItem ,
  linkList ,
  columns ,
  removeTrailingSlash ,
  isStg ,
  toStgUrl ,
  NewsletterForm ,
  make ,
}
/* subscribeColumn Not a pure module */
