// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment HeaderBlock on PageContentType   {
    __typename
    subpages  {
      __typename
      value  {
        __typename
        ...on PageContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          show_in_navigation  {
            __typename
            value  {
              __typename
              name
            }
          }
          title  {
            __typename
            value
          }
          url  {
            __typename
            value
          }
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.subpages;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.value;
    tmp = {
      __typename: value$1.__typename,
      value: !(value$2 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "PageContentType") {
                  var value$1 = value.system;
                  var value$2 = value.show_in_navigation;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? Js_array.map((function (value) {
                                if (!(value == null)) {
                                  return {
                                          __typename: value.__typename,
                                          name: value.name
                                        };
                                }
                                
                              }), value$3) : undefined
                    };
                  }
                  var value$4 = value.title;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.url;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  tmp = {
                    NAME: "PageContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      show_in_navigation: tmp$1,
                      title: tmp$2,
                      url: tmp$3
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$2) : undefined
    };
  }
  return {
          __typename: value.__typename,
          subpages: tmp
        };
}

function serialize(value) {
  var value$1 = value.subpages;
  var subpages;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.url;
              var url;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                url = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                url = null;
              }
              var value$6 = value$1.title;
              var title;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                title = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                title = null;
              }
              var value$10 = value$1.show_in_navigation;
              var show_in_navigation;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.name;
                          var value$2 = value.__typename;
                          return {
                                  __typename: value$2,
                                  name: value$1
                                };
                        }), value$11) : null;
                var value$13 = value$10.__typename;
                show_in_navigation = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                show_in_navigation = null;
              }
              var value$14 = value$1.system;
              var value$15 = value$14.codename;
              var value$16 = value$14.id;
              var value$17 = value$14.__typename;
              var system = {
                __typename: value$17,
                id: value$16,
                codename: value$15
              };
              return {
                      __typename: "PageContentType",
                      system: system,
                      show_in_navigation: show_in_navigation,
                      title: title,
                      url: url
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    subpages = {
      __typename: value$4,
      value: value$3
    };
  } else {
    subpages = null;
  }
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          subpages: subpages
        };
}

function verifyArgsAndParse(_HeaderBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var HeaderBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  HeaderBlock ,
}
/* query Not a pure module */
