// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__TestimonialCarouselBlock__Decoder__CarouselBg from "./Gql__TestimonialCarouselBlock__Decoder__CarouselBg.bs.js";
import * as Gql__TestimonialCarouselBlock__Decoder__CarouselLink from "./Gql__TestimonialCarouselBlock__Decoder__CarouselLink.bs.js";
import * as Gql__TestimonialCarouselBlock__Decoder__CarouselBgMobile from "./Gql__TestimonialCarouselBlock__Decoder__CarouselBgMobile.bs.js";
import * as Gql__TestimonialCarouselBlock__Decoder__CarouselBgTablet from "./Gql__TestimonialCarouselBlock__Decoder__CarouselBgTablet.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "TestimonialCarouselItemContentType") {
    return ;
  }
  var c = content.VAL;
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var image;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    image = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    image = undefined;
  }
  var quote = Belt_Option.getWithDefault(Belt_Option.flatMap(c.quote, (function (c) {
              return c.value;
            })), "");
  var author = Belt_Option.getWithDefault(Belt_Option.flatMap(c.author, (function (c) {
              return c.value;
            })), "");
  var background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__TestimonialCarouselBlock__Decoder__CarouselBg.get), 0);
  var background_image_tablet = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image_tablet, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__TestimonialCarouselBlock__Decoder__CarouselBgTablet.get), 0);
  var background_image_mobile = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image_mobile, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__TestimonialCarouselBlock__Decoder__CarouselBgMobile.get), 0);
  var background_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), undefined, (function (c) {
          if (c === "") {
            return ;
          } else {
            return c;
          }
        }));
  var url$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.url, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__TestimonialCarouselBlock__Decoder__CarouselLink.get), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          image: image,
          quote: quote,
          author: author,
          url: url$1,
          background_image: background_image,
          background_image_tablet: background_image_tablet,
          background_image_mobile: background_image_mobile,
          background_colour: background_colour,
          smartLink: smartLink
        };
}

var $$Image;

var Bg;

var BgTablet;

var BgMobile;

var Link;

export {
  $$Image ,
  Bg ,
  BgTablet ,
  BgMobile ,
  Link ,
  get ,
}
/* No side effect */
