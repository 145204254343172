// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Gql__DictionaryItems__Decoder__DictionaryItem from "./Gql__DictionaryItems__Decoder__DictionaryItem.bs.js";

var emptyDictionaryEntry = {
  value: "",
  smartLink: SmartLink.emptySmartLink
};

function get(content) {
  var items = Belt_Array.map(Belt_Array.keepMap(content, Gql__DictionaryItems__Decoder__DictionaryItem.get), (function (v) {
          return [
                  v.codename,
                  {
                    value: v.value,
                    smartLink: v.smartLink
                  }
                ];
        }));
  var map = Js_dict.fromArray(items);
  return {
          app_copyright: Js_dict.get(map, "app_copyright"),
          app_cta_login: Js_dict.get(map, "app_cta_login"),
          app_cta_signup: Js_dict.get(map, "app_cta_signup"),
          business_cta_signup: Js_dict.get(map, "business_cta_signup"),
          business_cta_login: Js_dict.get(map, "business_cta_login"),
          app_menu_hi: Js_dict.get(map, "app_menu_hi"),
          app_menu_myaccount: Js_dict.get(map, "app_menu_myaccount"),
          app_menu_mygifts: Js_dict.get(map, "app_menu_mygifts"),
          app_menu_mywallet: Js_dict.get(map, "app_menu_mywallet"),
          app_menu_signout: Js_dict.get(map, "app_menu_signout"),
          app_prezzeeabn: Js_dict.get(map, "app_prezzeeabn"),
          app_yourcurrentshoppingregion: Js_dict.get(map, "app_yourcurrentshoppingregion")
        };
}

var DictionaryItem;

export {
  DictionaryItem ,
  emptyDictionaryEntry ,
  get ,
}
/* No side effect */
