// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as ParseRichTextData from "../../../utils/parseRichTextData";

function parseRichTextData(prim) {
  return ParseRichTextData.parseRichTextData(prim);
}

function get(c) {
  var content = ParseRichTextData.parseRichTextData(Belt_Option.getWithDefault(Belt_Option.flatMap(c.content, (function (f) {
                  return f.value;
                })), ""));
  var width = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.width, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "n12columns", (function (str) {
          return str;
        }));
  var alignment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var distance_top = Belt_Option.flatMap(c.distance_top, (function (c) {
          return c.value;
        }));
  var distance_bottom = Belt_Option.flatMap(c.distance_bottom, (function (c) {
          return c.value;
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          smartLink: smartLink,
          content: content,
          width: width,
          alignment: alignment,
          distance_top: distance_top,
          distance_bottom: distance_bottom
        };
}

export {
  parseRichTextData ,
  get ,
}
/* ../../../utils/parseRichTextData Not a pure module */
