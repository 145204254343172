// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query GiftLandingPageThemeContent($language: String!, $preview: Boolean, $themeId: String!)  {
    itemsByType(type: "gift_landing_page_theme", limit: 1, depth: 5, language: $language, preview: $preview, filters: [{type: EQUALS, prop: "elements.theme_id", val: $themeId}])  {
      __typename
      ...on GiftLandingPageThemeContentType   {
        __typename
        theme_id  {
          __typename
          value
        }
        brand_logo  {
          __typename
          value  {
            __typename
            description
            url
          }
        }
        gift_unwrap_lottie  {
          __typename
          value  {
            __typename
            url
          }
        }
        gift_unwrap_lottie_mobile_size  {
          __typename
          value
        }
        gift_unwrap_lottie_segments  {
          __typename
          value
        }
        confetti_lottie  {
          __typename
          value  {
            __typename
            url
          }
        }
        desktop_background_image  {
          __typename
          value  {
            __typename
            url
          }
        }
        desktop_background_image_size  {
          __typename
          value
        }
        mobile_background_image  {
          __typename
          value  {
            __typename
            url
          }
        }
        mobile_background_image_size  {
          __typename
          value
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.itemsByType;
  return {
          itemsByType: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "GiftLandingPageThemeContentType") {
                      var value$1 = value.theme_id;
                      var tmp$1;
                      if (value$1 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$2 = value$1.value;
                        tmp$1 = {
                          __typename: value$1.__typename,
                          value: !(value$2 == null) ? value$2 : undefined
                        };
                      }
                      var value$3 = value.brand_logo;
                      var tmp$2;
                      if (value$3 == null) {
                        tmp$2 = undefined;
                      } else {
                        var value$4 = value$3.value;
                        tmp$2 = {
                          __typename: value$3.__typename,
                          value: !(value$4 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.description;
                                    var value$2 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            description: !(value$1 == null) ? value$1 : undefined,
                                            url: !(value$2 == null) ? value$2 : undefined
                                          };
                                  }), value$4) : undefined
                        };
                      }
                      var value$5 = value.gift_unwrap_lottie;
                      var tmp$3;
                      if (value$5 == null) {
                        tmp$3 = undefined;
                      } else {
                        var value$6 = value$5.value;
                        tmp$3 = {
                          __typename: value$5.__typename,
                          value: !(value$6 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$6) : undefined
                        };
                      }
                      var value$7 = value.gift_unwrap_lottie_mobile_size;
                      var tmp$4;
                      if (value$7 == null) {
                        tmp$4 = undefined;
                      } else {
                        var value$8 = value$7.value;
                        tmp$4 = {
                          __typename: value$7.__typename,
                          value: !(value$8 == null) ? value$8 : undefined
                        };
                      }
                      var value$9 = value.gift_unwrap_lottie_segments;
                      var tmp$5;
                      if (value$9 == null) {
                        tmp$5 = undefined;
                      } else {
                        var value$10 = value$9.value;
                        tmp$5 = {
                          __typename: value$9.__typename,
                          value: !(value$10 == null) ? value$10 : undefined
                        };
                      }
                      var value$11 = value.confetti_lottie;
                      var tmp$6;
                      if (value$11 == null) {
                        tmp$6 = undefined;
                      } else {
                        var value$12 = value$11.value;
                        tmp$6 = {
                          __typename: value$11.__typename,
                          value: !(value$12 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$12) : undefined
                        };
                      }
                      var value$13 = value.desktop_background_image;
                      var tmp$7;
                      if (value$13 == null) {
                        tmp$7 = undefined;
                      } else {
                        var value$14 = value$13.value;
                        tmp$7 = {
                          __typename: value$13.__typename,
                          value: !(value$14 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$14) : undefined
                        };
                      }
                      var value$15 = value.desktop_background_image_size;
                      var tmp$8;
                      if (value$15 == null) {
                        tmp$8 = undefined;
                      } else {
                        var value$16 = value$15.value;
                        tmp$8 = {
                          __typename: value$15.__typename,
                          value: !(value$16 == null) ? value$16 : undefined
                        };
                      }
                      var value$17 = value.mobile_background_image;
                      var tmp$9;
                      if (value$17 == null) {
                        tmp$9 = undefined;
                      } else {
                        var value$18 = value$17.value;
                        tmp$9 = {
                          __typename: value$17.__typename,
                          value: !(value$18 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$18) : undefined
                        };
                      }
                      var value$19 = value.mobile_background_image_size;
                      var tmp$10;
                      if (value$19 == null) {
                        tmp$10 = undefined;
                      } else {
                        var value$20 = value$19.value;
                        tmp$10 = {
                          __typename: value$19.__typename,
                          value: !(value$20 == null) ? value$20 : undefined
                        };
                      }
                      tmp = {
                        NAME: "GiftLandingPageThemeContentType",
                        VAL: {
                          __typename: value.__typename,
                          theme_id: tmp$1,
                          brand_logo: tmp$2,
                          gift_unwrap_lottie: tmp$3,
                          gift_unwrap_lottie_mobile_size: tmp$4,
                          gift_unwrap_lottie_segments: tmp$5,
                          confetti_lottie: tmp$6,
                          desktop_background_image: tmp$7,
                          desktop_background_image_size: tmp$8,
                          mobile_background_image: tmp$9,
                          mobile_background_image_size: tmp$10
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.itemsByType;
  var itemsByType = value$1 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME === "UnspecifiedFragment") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.mobile_background_image_size;
            var mobile_background_image_size;
            if (value$2 !== undefined) {
              var value$3 = value$2.value;
              var value$4 = value$3 !== undefined ? value$3 : null;
              var value$5 = value$2.__typename;
              mobile_background_image_size = {
                __typename: value$5,
                value: value$4
              };
            } else {
              mobile_background_image_size = null;
            }
            var value$6 = value$1.mobile_background_image;
            var mobile_background_image;
            if (value$6 !== undefined) {
              var value$7 = value$6.value;
              var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$7) : null;
              var value$9 = value$6.__typename;
              mobile_background_image = {
                __typename: value$9,
                value: value$8
              };
            } else {
              mobile_background_image = null;
            }
            var value$10 = value$1.desktop_background_image_size;
            var desktop_background_image_size;
            if (value$10 !== undefined) {
              var value$11 = value$10.value;
              var value$12 = value$11 !== undefined ? value$11 : null;
              var value$13 = value$10.__typename;
              desktop_background_image_size = {
                __typename: value$13,
                value: value$12
              };
            } else {
              desktop_background_image_size = null;
            }
            var value$14 = value$1.desktop_background_image;
            var desktop_background_image;
            if (value$14 !== undefined) {
              var value$15 = value$14.value;
              var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$15) : null;
              var value$17 = value$14.__typename;
              desktop_background_image = {
                __typename: value$17,
                value: value$16
              };
            } else {
              desktop_background_image = null;
            }
            var value$18 = value$1.confetti_lottie;
            var confetti_lottie;
            if (value$18 !== undefined) {
              var value$19 = value$18.value;
              var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$19) : null;
              var value$21 = value$18.__typename;
              confetti_lottie = {
                __typename: value$21,
                value: value$20
              };
            } else {
              confetti_lottie = null;
            }
            var value$22 = value$1.gift_unwrap_lottie_segments;
            var gift_unwrap_lottie_segments;
            if (value$22 !== undefined) {
              var value$23 = value$22.value;
              var value$24 = value$23 !== undefined ? value$23 : null;
              var value$25 = value$22.__typename;
              gift_unwrap_lottie_segments = {
                __typename: value$25,
                value: value$24
              };
            } else {
              gift_unwrap_lottie_segments = null;
            }
            var value$26 = value$1.gift_unwrap_lottie_mobile_size;
            var gift_unwrap_lottie_mobile_size;
            if (value$26 !== undefined) {
              var value$27 = value$26.value;
              var value$28 = value$27 !== undefined ? value$27 : null;
              var value$29 = value$26.__typename;
              gift_unwrap_lottie_mobile_size = {
                __typename: value$29,
                value: value$28
              };
            } else {
              gift_unwrap_lottie_mobile_size = null;
            }
            var value$30 = value$1.gift_unwrap_lottie;
            var gift_unwrap_lottie;
            if (value$30 !== undefined) {
              var value$31 = value$30.value;
              var value$32 = value$31 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$31) : null;
              var value$33 = value$30.__typename;
              gift_unwrap_lottie = {
                __typename: value$33,
                value: value$32
              };
            } else {
              gift_unwrap_lottie = null;
            }
            var value$34 = value$1.brand_logo;
            var brand_logo;
            if (value$34 !== undefined) {
              var value$35 = value$34.value;
              var value$36 = value$35 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.description;
                        var description = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.__typename;
                        return {
                                __typename: value$3,
                                description: description,
                                url: url
                              };
                      }), value$35) : null;
              var value$37 = value$34.__typename;
              brand_logo = {
                __typename: value$37,
                value: value$36
              };
            } else {
              brand_logo = null;
            }
            var value$38 = value$1.theme_id;
            var theme_id;
            if (value$38 !== undefined) {
              var value$39 = value$38.value;
              var value$40 = value$39 !== undefined ? value$39 : null;
              var value$41 = value$38.__typename;
              theme_id = {
                __typename: value$41,
                value: value$40
              };
            } else {
              theme_id = null;
            }
            return {
                    __typename: "GiftLandingPageThemeContentType",
                    theme_id: theme_id,
                    brand_logo: brand_logo,
                    gift_unwrap_lottie: gift_unwrap_lottie,
                    gift_unwrap_lottie_mobile_size: gift_unwrap_lottie_mobile_size,
                    gift_unwrap_lottie_segments: gift_unwrap_lottie_segments,
                    confetti_lottie: confetti_lottie,
                    desktop_background_image: desktop_background_image,
                    desktop_background_image_size: desktop_background_image_size,
                    mobile_background_image: mobile_background_image,
                    mobile_background_image_size: mobile_background_image_size
                  };
          }), value$1) : null;
  return {
          itemsByType: itemsByType
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined,
          themeId: inp.themeId
        };
}

function makeVariables(language, preview, themeId, param) {
  return {
          language: language,
          preview: preview,
          themeId: themeId
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

export {
  Query ,
}
/* query Not a pure module */
