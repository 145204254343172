// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FontAwesomeIcon__Decoder from "../FontAwesomeIcon/Gql__FontAwesomeIcon__Decoder.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.text, (function (c) {
              return c.value;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(c.url, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.menu_icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var icon;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url$1 = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    icon = {
      name: name,
      description: description,
      url: url$1,
      width: width,
      height: height
    };
  } else {
    icon = undefined;
  }
  var fontawesomeIcon = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.fontawesome_icon, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (f) {
              if (typeof f === "object" && f.NAME === "FontAwesomeContentType") {
                return Gql__FontAwesomeIcon__Decoder.get(f.VAL.fontAwesomeIcon);
              }
              
            })), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          icon: icon,
          fontawesomeIcon: fontawesomeIcon,
          url: url,
          smartLink: smartLink
        };
}

var CardIcon;

var FontAwesomeIcon;

export {
  CardIcon ,
  FontAwesomeIcon ,
  get ,
}
/* No side effect */
