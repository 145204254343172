// Generated by ReScript, PLEASE EDIT WITH CARE

import CookieSettingButtonTsx from "./CookieSettingButton.tsx";

var make = CookieSettingButtonTsx;

export {
  make ,
}
/* make Not a pure module */
