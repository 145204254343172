// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function toRequestPayload(d) {
  return Json_encode.object_({
              hd: [
                "reset_token",
                d.token
              ],
              tl: {
                hd: [
                  "new_password",
                  d.password
                ],
                tl: /* [] */0
              }
            });
}

var Encode = {
  toRequestPayload: toRequestPayload
};

export {
  Encode ,
}
/* No side effect */
