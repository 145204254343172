// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Gql__GiftExperience__Decoder__ListItem from "./Gql__GiftExperience__Decoder__ListItem.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.promo_image_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var promo_image;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    promo_image = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    promo_image = undefined;
  }
  var list_items = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.list_items, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__GiftExperience__Decoder__ListItem.get);
  var buttons = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.buttons, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          promo_image: promo_image,
          list_items: list_items,
          buttons: buttons,
          smartLink: smartLink
        };
}

var $$Image;

var IconCard;

var ListItem;

export {
  $$Image ,
  IconCard ,
  ListItem ,
  get ,
}
/* No side effect */
