// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Js_null from "@rescript/std/lib/es6/js_null.js";
import * as ErrorData from "./ErrorData.bs.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function optionalNullable(decode, json) {
  try {
    return Js_null.fromOption(Caml_option.some(Curry._1(decode, json)));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return null;
    }
    throw exn;
  }
}

function category(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          position: Json_decode.field("position", Json_decode.$$int, json),
          slug: Json_decode.field("slug", Json_decode.string, json),
          cms_id: Json_decode.field("cms_id", Json_decode.string, json),
          image: Json_decode.field("image", Json_decode.string, json)
        };
}

function categories(json) {
  return {
          count: Json_decode.field("count", Json_decode.$$int, json),
          next: optionalNullable((function (param) {
                  return Json_decode.field("next", Json_decode.string, param);
                }), json),
          previous: optionalNullable((function (param) {
                  return Json_decode.field("previous", Json_decode.string, param);
                }), json),
          results: Json_decode.field("results", (function (param) {
                  return Json_decode.array(category, param);
                }), json)
        };
}

function safeDecodeCategoriesData(json) {
  var result;
  try {
    result = categories(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode = {
  category: category,
  categories: categories,
  safeDecodeCategoriesData: safeDecodeCategoriesData
};

export {
  optionalNullable ,
  Decode ,
}
/* No side effect */
