// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Page__Decoder__Page from "./Gql__Page__Decoder__Page.bs.js";

function decode(gqlQueryResult) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.itemsByType, []), 0), GqlUtils.id), Gql__Page__Decoder__Page.get);
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  Pino.logger.error({
        err: result._0,
        type: "Page decoder"
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

var Page;

export {
  Page ,
  Decoder ,
}
/* Pino Not a pure module */
