// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment SellingPointsBlockItem on SellingPointsBlockItemContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    image  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    video_mp4  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    video_webm  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.image;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.title;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.description;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.video_mp4;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$9) : undefined
    };
  }
  var value$10 = value.video_webm;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$11) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          image: tmp,
          title: tmp$1,
          description: tmp$2,
          video_mp4: tmp$3,
          video_webm: tmp$4
        };
}

function serialize(value) {
  var value$1 = value.video_webm;
  var video_webm;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    video_webm = {
      __typename: value$4,
      value: value$3
    };
  } else {
    video_webm = null;
  }
  var value$5 = value.video_mp4;
  var video_mp4;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    video_mp4 = {
      __typename: value$8,
      value: value$7
    };
  } else {
    video_mp4 = null;
  }
  var value$9 = value.description;
  var description;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    description = {
      __typename: value$12,
      value: value$11
    };
  } else {
    description = null;
  }
  var value$13 = value.title;
  var title;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    title = {
      __typename: value$16,
      value: value$15
    };
  } else {
    title = null;
  }
  var value$17 = value.image;
  var image;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    image = {
      __typename: value$20,
      value: value$19
    };
  } else {
    image = null;
  }
  var value$21 = value.system;
  var value$22 = value$21.codename;
  var value$23 = value$21.id;
  var value$24 = value$21.__typename;
  var system = {
    __typename: value$24,
    id: value$23,
    codename: value$22
  };
  var value$25 = value.__typename;
  return {
          __typename: value$25,
          system: system,
          image: image,
          title: title,
          description: description,
          video_mp4: video_mp4,
          video_webm: video_webm
        };
}

function verifyArgsAndParse(_SellingPointsBlockItem, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SellingPointsBlockItem = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  SellingPointsBlockItem ,
}
/* query Not a pure module */
