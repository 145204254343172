import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { RegisterNewUser } from 'common/types';
import { snakeKeys } from 'js-convert-case';

interface ValidatePasswordResult {
  password?: string[];
  detail?: string;
}

function isPasswordInvalidError(obj: unknown): obj is ValidatePasswordResult {
  try {
    return (
      typeof obj === 'object' &&
      (obj as ValidatePasswordResult).password !== undefined &&
      (obj as ValidatePasswordResult).detail !== undefined
    );
  } catch (error) {
    return false;
  }
}

/**
 * Request to validate a customers password in our backend validator.
 * @param userData the data user filled in previous steps
 * @returns Promise of decoded validate password response.
 */
const validateCustomerPassword = async (
  userData: Pick<RegisterNewUser, 'firstName' | 'lastName' | 'email' | 'password'>
): Promise<ValidatePasswordResult> => {
  const response = await createRequest('api/v3/customer/validate-password/', {
    method: 'POST',
    body: JSON.stringify(snakeKeys(userData)),
    withMagicUserAgent: true,
  });
  if (response.status === 400) {
    const jsonData = await response.json();
    if (isPasswordInvalidError(jsonData)) {
      return jsonData;
    }
  }

  return handleErrors(response);
};

export default validateCustomerPassword;
