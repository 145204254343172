// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";

var section__0 = BsCss.maxWidth(BsCss.px(1300));

var section__1 = {
  hd: BsCss.marginLeft("auto"),
  tl: {
    hd: BsCss.marginRight("auto"),
    tl: {
      hd: BsCss.paddingLeft(BsCss.rem(1.25)),
      tl: {
        hd: BsCss.paddingRight(BsCss.rem(1.25)),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.tabletOrDesktop, {
                hd: BsCss.paddingLeft(BsCss.rem(1.875)),
                tl: {
                  hd: BsCss.paddingRight(BsCss.rem(1.875)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    }
  }
};

var section_ = {
  hd: section__0,
  tl: section__1
};

var section = Curry._1(BsCss.style, section_);

var fullWidthSection = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(1440)),
      tl: {
        hd: BsCss.paddingLeft(BsCss.rem(0.375)),
        tl: {
          hd: BsCss.paddingRight(BsCss.rem(0.375)),
          tl: {
            hd: BsCss.marginLeft("auto"),
            tl: {
              hd: BsCss.marginRight("auto"),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.tabletOrDesktop, {
                      hd: BsCss.paddingLeft(BsCss.rem(1.875)),
                      tl: {
                        hd: BsCss.paddingRight(BsCss.rem(1.875)),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

export {
  section_ ,
  section ,
  fullWidthSection ,
}
/* section_ Not a pure module */
