import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import getAccountDetails from 'api/queries/getAccountDetails';
import { AccountDetails } from 'models/customer.model';

export const useAccountDetailsQuery = (options?: UseQueryOptions<AccountDetails, Error>) => {
  return useQuery({
    queryKey: ['customer', 'account'],
    queryFn: () => getAccountDetails(),
    staleTime: 10000 * 60,
    cacheTime: 10000 * 60,
    retry: false,
    ...options,
  });
};
