// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Video__Fragment from "../Video/Gql__Video__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment FAQBlock on FaqBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    faq_sections  {
      __typename
      value  {
        __typename
        ...on FaqSectionContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          title  {
            __typename
            value
          }
          faq_items  {
            __typename
            value  {
              __typename
              ...on FaqItemContentType   {
                __typename
                system  {
                  __typename
                  id
                  codename
                }
                title  {
                  __typename
                  value
                }
                body_text  {
                  __typename
                  value
                }
                body_content  {
                  __typename
                  value  {
                    __typename
                    ...on RichTextBlockContentType   {
                      __typename
                      content  {
                        __typename
                        value
                      }
                    }
                    ...on VideoContentType   {
                      ...Video
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    additional_content  {
      __typename
      value
    }
  }
  ${frag_0}
`)(Gql__Video__Fragment.Video.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.faq_sections;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "FaqSectionContentType") {
                  var value$1 = value.system;
                  var value$2 = value.title;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.faq_items;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp;
                                if (typename === "FaqItemContentType") {
                                  var value$1 = value.system;
                                  var value$2 = value.title;
                                  var tmp$1;
                                  if (value$2 == null) {
                                    tmp$1 = undefined;
                                  } else {
                                    var value$3 = value$2.value;
                                    tmp$1 = {
                                      __typename: value$2.__typename,
                                      value: !(value$3 == null) ? value$3 : undefined
                                    };
                                  }
                                  var value$4 = value.body_text;
                                  var tmp$2;
                                  if (value$4 == null) {
                                    tmp$2 = undefined;
                                  } else {
                                    var value$5 = value$4.value;
                                    tmp$2 = {
                                      __typename: value$4.__typename,
                                      value: !(value$5 == null) ? value$5 : undefined
                                    };
                                  }
                                  var value$6 = value.body_content;
                                  var tmp$3;
                                  if (value$6 == null) {
                                    tmp$3 = undefined;
                                  } else {
                                    var value$7 = value$6.value;
                                    tmp$3 = {
                                      __typename: value$6.__typename,
                                      value: !(value$7 == null) ? Js_array.map((function (value) {
                                                if (value == null) {
                                                  return ;
                                                }
                                                var typename = value["__typename"];
                                                var tmp;
                                                switch (typename) {
                                                  case "RichTextBlockContentType" :
                                                      var value$1 = value.content;
                                                      var tmp$1;
                                                      if (value$1 == null) {
                                                        tmp$1 = undefined;
                                                      } else {
                                                        var value$2 = value$1.value;
                                                        tmp$1 = {
                                                          __typename: value$1.__typename,
                                                          value: !(value$2 == null) ? value$2 : undefined
                                                        };
                                                      }
                                                      tmp = {
                                                        NAME: "RichTextBlockContentType",
                                                        VAL: {
                                                          __typename: value.__typename,
                                                          content: tmp$1
                                                        }
                                                      };
                                                      break;
                                                  case "VideoContentType" :
                                                      tmp = {
                                                        NAME: "VideoContentType",
                                                        VAL: {
                                                          video: Gql__Video__Fragment.Video.verifyArgsAndParse("Video", value)
                                                        }
                                                      };
                                                      break;
                                                  default:
                                                    tmp = {
                                                      NAME: "UnspecifiedFragment",
                                                      VAL: typename
                                                    };
                                                }
                                                return tmp;
                                              }), value$7) : undefined
                                    };
                                  }
                                  tmp = {
                                    NAME: "FaqItemContentType",
                                    VAL: {
                                      __typename: value.__typename,
                                      system: {
                                        __typename: value$1.__typename,
                                        id: value$1.id,
                                        codename: value$1.codename
                                      },
                                      title: tmp$1,
                                      body_text: tmp$2,
                                      body_content: tmp$3
                                    }
                                  };
                                } else {
                                  tmp = {
                                    NAME: "UnspecifiedFragment",
                                    VAL: typename
                                  };
                                }
                                return tmp;
                              }), value$5) : undefined
                    };
                  }
                  tmp = {
                    NAME: "FaqSectionContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      title: tmp$1,
                      faq_items: tmp$2
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$5) : undefined
    };
  }
  var value$6 = value.additional_content;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          faq_sections: tmp$1,
          additional_content: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.additional_content;
  var additional_content;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    additional_content = {
      __typename: value$4,
      value: value$3
    };
  } else {
    additional_content = null;
  }
  var value$5 = value.faq_sections;
  var faq_sections;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME !== "FaqSectionContentType") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.faq_items;
              var faq_items;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          }
                          var value$1 = value.VAL;
                          var value$2 = value$1.body_content;
                          var body_content;
                          if (value$2 !== undefined) {
                            var value$3 = value$2.value;
                            var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                                      if (value === undefined) {
                                        return null;
                                      }
                                      var variant = value.NAME;
                                      if (variant === "UnspecifiedFragment") {
                                        return {};
                                      }
                                      if (variant === "VideoContentType") {
                                        return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                                      return {};
                                                    }), [Gql__Video__Fragment.Video.serialize(value.VAL.video)]);
                                      }
                                      var value$1 = value.VAL.content;
                                      var content;
                                      if (value$1 !== undefined) {
                                        var value$2 = value$1.value;
                                        var value$3 = value$2 !== undefined ? value$2 : null;
                                        var value$4 = value$1.__typename;
                                        content = {
                                          __typename: value$4,
                                          value: value$3
                                        };
                                      } else {
                                        content = null;
                                      }
                                      return {
                                              __typename: "RichTextBlockContentType",
                                              content: content
                                            };
                                    }), value$3) : null;
                            var value$5 = value$2.__typename;
                            body_content = {
                              __typename: value$5,
                              value: value$4
                            };
                          } else {
                            body_content = null;
                          }
                          var value$6 = value$1.body_text;
                          var body_text;
                          if (value$6 !== undefined) {
                            var value$7 = value$6.value;
                            var value$8 = value$7 !== undefined ? value$7 : null;
                            var value$9 = value$6.__typename;
                            body_text = {
                              __typename: value$9,
                              value: value$8
                            };
                          } else {
                            body_text = null;
                          }
                          var value$10 = value$1.title;
                          var title;
                          if (value$10 !== undefined) {
                            var value$11 = value$10.value;
                            var value$12 = value$11 !== undefined ? value$11 : null;
                            var value$13 = value$10.__typename;
                            title = {
                              __typename: value$13,
                              value: value$12
                            };
                          } else {
                            title = null;
                          }
                          var value$14 = value$1.system;
                          var value$15 = value$14.codename;
                          var value$16 = value$14.id;
                          var value$17 = value$14.__typename;
                          var system = {
                            __typename: value$17,
                            id: value$16,
                            codename: value$15
                          };
                          return {
                                  __typename: "FaqItemContentType",
                                  system: system,
                                  title: title,
                                  body_text: body_text,
                                  body_content: body_content
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                faq_items = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                faq_items = null;
              }
              var value$6 = value$1.title;
              var title;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                title = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                title = null;
              }
              var value$10 = value$1.system;
              var value$11 = value$10.codename;
              var value$12 = value$10.id;
              var value$13 = value$10.__typename;
              var system = {
                __typename: value$13,
                id: value$12,
                codename: value$11
              };
              return {
                      __typename: "FaqSectionContentType",
                      system: system,
                      title: title,
                      faq_items: faq_items
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    faq_sections = {
      __typename: value$8,
      value: value$7
    };
  } else {
    faq_sections = null;
  }
  var value$9 = value.title;
  var title;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    title = {
      __typename: value$12,
      value: value$11
    };
  } else {
    title = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.codename;
  var value$15 = value$13.id;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    id: value$15,
    codename: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          title: title,
          faq_sections: faq_sections,
          additional_content: additional_content
        };
}

function verifyArgsAndParse(_FAQBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var FAQBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Video;

export {
  Video ,
  FAQBlock ,
}
/* query Not a pure module */
