// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "ImageContentType") {
    return Gql__Image__Decoder.get(content.VAL.image);
  }
  
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
