// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__PromoTiles__Decoder__PromoTile from "./Gql__PromoTiles__Decoder__PromoTile.bs.js";

function get(c) {
  return Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.tiles, (function (c) {
                            return c.value;
                          })), []), GqlUtils.id), Gql__PromoTiles__Decoder__PromoTile.get);
}

var PromoTile;

export {
  PromoTile ,
  get ,
}
/* No side effect */
