import { useEffect, useState } from 'react';

// https://stackoverflow.com/a/48161723
const sha256 = async (message?: string) => {
  if (!crypto) return;
  //
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const useHashedValue = (value?: string) => {
  const [hash, setHash] = useState<string | undefined>();

  useEffect(() => {
    if (value == undefined || typeof window === 'undefined' || hash) return;

    sha256(value).then(v => {
      setHash(v);
    });
  }, [value]);

  return hash;
};

export default useHashedValue;
