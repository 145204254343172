// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../../../common/util.bs.js";
import * as Button from "../../library/Button.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function ServerError(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: Util.ReactStuff.s("SERVER ERROR"),
                      className: "text-left text-3xl font-bold"
                    }),
                JsxRuntime.jsx("p", {
                      children: Util.ReactStuff.s("Error 500"),
                      className: "text-left py-10 font-semibold"
                    }),
                JsxRuntime.jsx("p", {
                      children: Util.ReactStuff.s("There's been an error. It's been reported to the site administrators via email and should be fixed shortly. Thank you for your patience. "),
                      className: " text-left whitespace-pre-line"
                    }),
                JsxRuntime.jsx("a", {
                      children: JsxRuntime.jsx("button", {
                            children: Util.ReactStuff.s("Home"),
                            className: Cn.make([
                                  Button.primaryStyle,
                                  "w-40 h-12 my-16"
                                ])
                          }),
                      className: "text-center",
                      href: "/"
                    })
              ],
              className: "text-center w-11/12 md:w-2/3 lg:w-1/2 mx-auto my-20"
            });
}

var make = ServerError;

export {
  make ,
}
/* Util Not a pure module */
