// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Gql__SkuPresentation__Fragment__Decoder from "./Gql__SkuPresentation__Fragment__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "SkuPresentationContentType") {
    return Gql__SkuPresentation__Fragment__Decoder.decode(content.VAL.skuPresentationFragment);
  }
  
}

var SkuPresentation = {
  get: get
};

function decode(gqlQueryResult) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.itemsByType, []), 0), GqlUtils.id), get);
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  Pino.logger.error({
        err: result._0,
        type: "Sku Presentation decoder"
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

function parse(data) {
  return Belt_Option.map(data === null ? undefined : Caml_option.some(data), Json.parseOrRaise);
}

var FragmentDecoder;

export {
  FragmentDecoder ,
  SkuPresentation ,
  Decoder ,
  parse ,
}
/* Pino Not a pure module */
