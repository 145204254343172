// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import Link from "next/link";
import * as UserContext from "../../auth/user/UserContext.bs.js";
import * as HeaderStyles from "../../layouts/HeaderStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

function NavBarLinks(props) {
  var setVisible = props.setVisible;
  var visible = props.visible;
  var match = UserContext.useUser(undefined);
  var user = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Link, {
                      href: "/store",
                      prefetch: false,
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: Util.ReactStuff.s("Buy eGift Cards"),
                                    className: HeaderStyles.linkText
                                  }),
                              JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                    icon: [
                                      "fas",
                                      "angle-right"
                                    ],
                                    className: "lg:hidden"
                                  })
                            ],
                            className: HeaderStyles.navBarLink,
                            title: "Buy eGift Cards"
                          })
                    }),
                JsxRuntime.jsx("hr", {
                      className: HeaderStyles.grayLine
                    }),
                user ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(Link, {
                                href: "/wallet",
                                prefetch: false,
                                children: JsxRuntime.jsxs("a", {
                                      children: [
                                        Util.ReactStuff.s("My Wallet"),
                                        JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                              icon: [
                                                "fas",
                                                "angle-right"
                                              ],
                                              className: "lg:hidden"
                                            })
                                      ],
                                      className: Cn.make([
                                            HeaderStyles.navBarLink,
                                            "h-11 lg:h-full"
                                          ]),
                                      title: "wallet",
                                      onClick: (function (param) {
                                          Curry._1(setVisible, (function (param) {
                                                  return false;
                                                }));
                                        })
                                    })
                              }),
                          JsxRuntime.jsx("hr", {
                                className: HeaderStyles.grayLine
                              })
                        ]
                      }) : null,
                JsxRuntime.jsx(Link, {
                      href: "/business",
                      prefetch: false,
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: Util.ReactStuff.s("eGift Cards for Business"),
                                    className: HeaderStyles.linkText
                                  }),
                              JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                    icon: [
                                      "fas",
                                      "angle-right"
                                    ],
                                    className: "lg:hidden"
                                  })
                            ],
                            className: HeaderStyles.navBarLink,
                            title: "eGift Cards for Business",
                            onClick: (function (param) {
                                Curry._1(setVisible, (function (param) {
                                        return false;
                                      }));
                              })
                          })
                    }),
                JsxRuntime.jsx("hr", {
                      className: HeaderStyles.grayLine
                    }),
                JsxRuntime.jsx(Link, {
                      href: "/international",
                      prefetch: false,
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: Util.ReactStuff.s("International eGift Cards"),
                                    className: HeaderStyles.linkText
                                  }),
                              JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                    icon: [
                                      "fas",
                                      "angle-right"
                                    ],
                                    className: "lg:hidden"
                                  })
                            ],
                            className: HeaderStyles.navBarLink,
                            title: "International eGift Cards",
                            onClick: (function (param) {
                                Curry._1(setVisible, (function (param) {
                                        return false;
                                      }));
                              })
                          })
                    }),
                JsxRuntime.jsx("hr", {
                      className: HeaderStyles.grayLine
                    }),
                JsxRuntime.jsx(Link, {
                      href: "/contact",
                      prefetch: false,
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: Util.ReactStuff.s("Customer Care"),
                                    className: HeaderStyles.linkText
                                  }),
                              JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                    icon: [
                                      "fas",
                                      "angle-right"
                                    ],
                                    className: "lg:hidden"
                                  })
                            ],
                            className: HeaderStyles.navBarLink,
                            title: "Customer Care",
                            onClick: (function (param) {
                                Curry._1(setVisible, (function (param) {
                                        return false;
                                      }));
                              })
                          })
                    }),
                JsxRuntime.jsx("hr", {
                      className: HeaderStyles.grayLine
                    }),
                JsxRuntime.jsx(Link, {
                      href: "/about",
                      prefetch: false,
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: Util.ReactStuff.s("About Us"),
                                    className: HeaderStyles.linkText
                                  }),
                              JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                    icon: [
                                      "fas",
                                      "angle-right"
                                    ],
                                    className: "lg:hidden"
                                  })
                            ],
                            className: HeaderStyles.navBarLink,
                            title: "About Us",
                            onClick: (function (param) {
                                Curry._1(setVisible, (function (param) {
                                        return false;
                                      }));
                              })
                          })
                    }),
                JsxRuntime.jsx("hr", {
                      className: HeaderStyles.grayLine
                    }),
                user ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(Link, {
                                        href: "/my-details",
                                        prefetch: false,
                                        children: JsxRuntime.jsxs("a", {
                                              children: [
                                                Util.ReactStuff.s("My Account"),
                                                JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                      icon: [
                                                        "fas",
                                                        "angle-right"
                                                      ],
                                                      className: "lg:hidden"
                                                    })
                                              ],
                                              className: Cn.make([
                                                    HeaderStyles.navBarLink,
                                                    "lg:hidden"
                                                  ]),
                                              title: "account",
                                              onClick: (function (param) {
                                                  Curry._1(setVisible, (function (param) {
                                                          return false;
                                                        }));
                                                })
                                            })
                                      }),
                                  JsxRuntime.jsx("hr", {
                                        className: HeaderStyles.grayLine
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Link, {
                                href: "/logout",
                                prefetch: false,
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("button", {
                                            children: Util.ReactStuff.s("Sign Out"),
                                            "aria-label": "Logout Button",
                                            className: "mt-20 mb-20 lg:hidden w-full mx-auto text-prezzee-red"
                                          }),
                                      className: "bg-white shadow-2xl rounded-b-lg"
                                    })
                              })
                        ]
                      }) : JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(Link, {
                                href: "/login",
                                prefetch: false,
                                children: JsxRuntime.jsxs("a", {
                                      children: [
                                        Util.ReactStuff.s("Log In"),
                                        JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                              icon: [
                                                "fas",
                                                "angle-right"
                                              ],
                                              className: "lg:hidden"
                                            })
                                      ],
                                      className: Cn.make([
                                            HeaderStyles.navBarLink,
                                            "lg:hidden"
                                          ]),
                                      title: "Log In",
                                      onClick: (function (param) {
                                          Curry._1(setVisible, (function (param) {
                                                  return false;
                                                }));
                                        })
                                    })
                              }),
                          JsxRuntime.jsx("hr", {
                                className: HeaderStyles.grayLine
                              }),
                          JsxRuntime.jsx(Link, {
                                href: "/register",
                                prefetch: false,
                                children: JsxRuntime.jsxs("a", {
                                      children: [
                                        Util.ReactStuff.s("Sign up"),
                                        JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                              icon: [
                                                "fas",
                                                "angle-right"
                                              ],
                                              className: "lg:hidden"
                                            })
                                      ],
                                      className: Cn.make([
                                            HeaderStyles.navBarLink,
                                            "lg:hidden"
                                          ]),
                                      title: "register",
                                      onClick: (function (param) {
                                          Curry._1(setVisible, (function (param) {
                                                  return false;
                                                }));
                                        })
                                    })
                              }),
                          JsxRuntime.jsx("hr", {
                                className: HeaderStyles.grayLine
                              })
                        ],
                        className: "lg:hidden"
                      })
              ],
              className: HeaderStyles.navBarLinksContainer(visible),
              onClick: (function (param) {
                  Curry._1(setVisible, (function (param) {
                          return !visible;
                        }));
                })
            });
}

var Link$1;

var make = NavBarLinks;

export {
  Link$1 as Link,
  make ,
}
/* Util Not a pure module */
