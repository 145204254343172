import { ReactNode, FC, useMemo } from 'react';

import { usePaginatedDrawer, DEFAULT_PAGE } from 'contexts/PaginatedDrawerContext';
import useWindowSize from 'utils/useWindowSize';
import MobileNavPage, { OptionLinks } from 'components/library/MobileNavPage';
import { make as TopNavigationBar } from '@prezzee/ribbon/src/components/personal/TopNavigationBar/TopNavigationBar__Personal.bs';
import { largeScreenBreakpoint } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs';
import { useLayoutData } from 'contexts/LayoutDataContext';
import { EMPTY_ELEMENT } from 'common/utils/react';
import { Maybe } from 'models/types';

type TopNavigationProps = Omit<Props, 'isMenuOpen'> & {
  onMenuChange: (menuState: boolean) => void;
};

type Props = {
  logo: ReactNode;
  navigationBlock: JSX.Element;
  regionPicker: JSX.Element;
  closeMenuObservable: any;
  isLoggedIn: boolean;
  onLogoutClick: () => void;
  isMenuOpen: { contents: boolean };
};

const ConsumerNavigation: FC<Props> = ({
  logo,
  navigationBlock,
  regionPicker,
  closeMenuObservable,
  isLoggedIn,
  onLogoutClick,
  isMenuOpen,
}) => {
  const { page, setPage } = usePaginatedDrawer();
  const { classicLoginCtaList } = useLayoutData();
  const { width } = useWindowSize();

  const optionLinks: Maybe<OptionLinks[]> = useMemo(() => {
    return classicLoginCtaList
      .map(el => el.VAL)
      .find(el => el.smartLink.codename === page.code)
      ?.optionCTAList.map(el => ({ text: el.cta_text, url: el.cta_url }));
  }, [page.code]);

  const onMenuChange = (menuState: boolean) => {
    // NOTE: ref(bool) JS polyfill
    isMenuOpen.contents = menuState;
    setPage(DEFAULT_PAGE);
  };

  const topNavProps: TopNavigationProps = {
    logo,
    navigationBlock,
    regionPicker,
    closeMenuObservable,
    isLoggedIn,
    onLogoutClick,
    onMenuChange,
  };

  const configuredProps =
    page.code !== 'default'
      ? {
          ...topNavProps,
          regionPicker: EMPTY_ELEMENT,
          navigationBlock: <MobileNavPage links={optionLinks as OptionLinks[]} />,
        }
      : topNavProps;

  return (
    <>
      {width > largeScreenBreakpoint ? (
        <TopNavigationBar {...topNavProps} />
      ) : (
        <TopNavigationBar {...configuredProps} />
      )}
    </>
  );
};

export default ConsumerNavigation;
