import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';
import { GiftTheme } from 'models/gift.model';

/**
 * Request to fetch server web configuration.
 * @returns Promise with decoded web config response.
 */
const giftThemes = (skuCode?: string): Promise<GiftTheme[]> => {
  let route = 'api/v3/giftthemes/';

  if (skuCode) {
    route += '?sku_code=' + skuCode;
  }

  return createRequest(route, {
    withAuth: true,
    withMagicUserAgent: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true })['giftthemes'] as GiftTheme[]);
};

export default giftThemes;
