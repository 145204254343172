// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FAQ__Decoder__BodyContent from "./Gql__FAQ__Decoder__BodyContent.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "FaqItemContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var body_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_text, (function (c) {
              return c.value;
            })), "");
  var body_content = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_content, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__FAQ__Decoder__BodyContent.get);
  var smart_link_itemId = c.system.id;
  var smart_link_codename = c.system.codename;
  var smart_link = {
    itemId: smart_link_itemId,
    codename: smart_link_codename
  };
  return {
          title: title,
          body_text: body_text,
          body_content: body_content,
          smart_link: smart_link
        };
}

var BodyContent;

export {
  BodyContent ,
  get ,
}
/* No side effect */
