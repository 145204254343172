import { camelKeys } from 'js-convert-case';
import { createRequest } from 'api/helpers';
import handleErrors from 'api/handleErrors';

const edmOptIn = async (email: string) =>
  createRequest('api/v3/subscriptions/', {
    body: JSON.stringify({ email: email }),
    logRequest: true,
    method: 'POST',
    mode: 'cors',
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }));

export default edmOptIn;
