// Generated by ReScript, PLEASE EDIT WITH CARE


var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var notOnlySpaceRegex = /.*[^ ].*/;

var passwordRegex = /^((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,100})$/;

var phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

var vimeoRegex = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;

var specialRegex = /[$&+,:;=?@#|'<>.^*()\[|\]%!-]/g;

var htmlCommentRegex = /<!--(.*?)-->/g;

var spaceRegex = / /g;

export {
  emailRegex ,
  notOnlySpaceRegex ,
  passwordRegex ,
  phoneNumberRegex ,
  vimeoRegex ,
  specialRegex ,
  htmlCommentRegex ,
  spaceRegex ,
}
/* No side effect */
