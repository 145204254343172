import { camelKeys } from 'js-convert-case';
import poll from 'utils/poll';
import { createRequest } from 'api/helpers';
import handleErrors from 'api/handleErrors';

export type OrderStatusResponse = {
  orderUid: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

/**
 * Request to check status of an order.
 * @param orderUrl - The URL of the order to check the status of
 * @returns The response from the order status API
 */
const orderStatus = (orderUrl: string): Promise<OrderStatusResponse> =>
  createRequest(`${orderUrl}status`, {
    isUrl: true,
    logRequest: true,
    method: 'GET',
    mode: 'cors',
    withAuth: true,
    withSecurityToken: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as OrderStatusResponse);

export const orderStatusWithRetry = (orderUrl: string) => {
  return poll({
    fn: () => orderStatus(orderUrl),
    validate: data => {
      const validStatuses = [
        'COMPLETED',
        'CANCELLED',
        'PENDING_DELIVERY',
        'PENDING_FULFILLMENT',
        'PENDING_PAYMENT',
        'FAILED',
        'PENDING_REVIEW',
      ];

      return validStatuses.includes(data?.status);
    },
    interval: 1000,
    maxAttempts: 15,
  });
};

export default orderStatus;
