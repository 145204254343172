// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as ReactHookForm from "react-hook-form";

var _map = {"required":"required","min":"min","max":"max","noop":"noop","maxLength":"maxLength","pattern":"pattern"};

var _revMap = {"required":"required","min":"min","max":"max","noop":"noop","maxLength":"maxLength","pattern":"pattern"};

function validationTypeToJs(param) {
  return _map[param];
}

function validationTypeFromJs(param) {
  return _revMap[param];
}

function validationErrorExtToJs(param) {
  return {
          type_: param.type,
          message: param.message
        };
}

function validationErrorExtFromJs(param) {
  return {
          type: param.type_,
          message: param.message
        };
}

function useForm(options, param) {
  var formExt = ReactHookForm.useForm(options);
  var register = function (input) {
    return function (ref) {
      Curry._2(formExt.register, ref, input);
    };
  };
  var errors = Js_dict.fromArray(Belt_Array.map(Js_dict.entries(formExt.errors), (function (param) {
              var err = param[1];
              var match = err.type;
              var validationType;
              switch (match) {
                case "max" :
                    validationType = "max";
                    break;
                case "maxLength" :
                    validationType = "maxLength";
                    break;
                case "min" :
                    validationType = "min";
                    break;
                case "required" :
                    validationType = "required";
                    break;
                default:
                  validationType = "noop";
              }
              return [
                      param[0],
                      {
                        validationType: validationType,
                        message: err.message
                      }
                    ];
            })));
  return {
          register: register,
          reset: formExt.reset,
          setValue: formExt.setValue,
          handleSubmit: formExt.handleSubmit,
          errors: errors,
          setError: formExt.setError,
          getValues: formExt.getValues,
          formState: formExt.formState,
          trigger: formExt.trigger,
          watch: formExt.watch,
          control: formExt.control
        };
}

export {
  validationTypeToJs ,
  validationTypeFromJs ,
  validationErrorExtToJs ,
  validationErrorExtFromJs ,
  useForm ,
}
/* react-hook-form Not a pure module */
