import { css } from '@emotion/css';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';

import colors from '@prezzee/ribbon-ui/lib/theme/foundations/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSmartLink from 'hooks/useSmartLink';

const Styles = {
  popup: (isOpen, isRight) => css`
    display: block;
    position: absolute;
    top: 100%;
    opacity: ${isOpen ? 1 : 0};
    visibility: ${isOpen ? 'visible' : 'hidden'};
    transition: visibility 200ms linear, opacity 150ms linear;
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 20px 56px rgba(0, 0, 0, 0.29);
    border-radius: 8px;
    transform: translate(${isRight ? '0' : '50%'}, 8px);
    right: ${isRight ? '0' : 'calc(50% - 5px)'};
  `,
  linkArrow: css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    opacity: 0;
    transition: right 200ms ease-in, opacity 200ms ease-in;
  `,
  link: css`
    display: block;
    padding: 18px 47px 18px 12px;
    border-radius: 4px;
    white-space: nowrap;
    position: relative;
    transition: background-color 200ms ease-in;

    &:hover,
    &:focus {
      background-color: ${colors.gray[100]};

      svg {
        opacity: 1;
        right: 12px;
      }
    }
  `,
  button: isOpen => css`
    position: relative;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform-origin: center;
      transform: rotate(45deg) translate(-50%, 0);
      border: 8px solid transparent;
      border-top: 6px solid #ffffff;
      border-left: 6px solid #ffffff;
      z-index: 20;
      visibility: ${isOpen ? 'visible' : 'hidden'};
    }
  `,
};

interface Props {
  buttonLabel: string;
  isPopupRight: boolean;
  links: {
    text: string;
    url: string;
  }[];
  smartLink: {
    itemId: string;
    codename: string;
  };
}

const MenuPopup: FC<Props> = ({ buttonLabel, links, isPopupRight, smartLink }) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const spotlightProps = useSmartLink(smartLink);

  const closeOnOutsideClick = useCallback((e: MouseEvent) => {
    if (wrapperRef.current && wrapperRef.current !== e.target && !wrapperRef.current.contains(e.target)) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', closeOnOutsideClick);
    } else {
      document.removeEventListener('click', closeOnOutsideClick);
    }

    return () => {
      document.removeEventListener('click', closeOnOutsideClick);
    };
  }, [isOpen]);

  return (
    <div ref={wrapperRef} {...spotlightProps} style={{ position: 'relative' }}>
      <button onClick={() => setOpen(!isOpen)} className={Styles.button(isOpen)}>
        {buttonLabel}
      </button>
      <div className={Styles.popup(isOpen, isPopupRight)}>
        {links.map(({ text, url }, index) => (
          <Link key={url} href={url}>
            <a
              role="button"
              className={Styles.link}
              onClick={() => {
                setOpen(false);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setOpen(false);
                }
              }}
              tabIndex={index + 1}
            >
              {text}
              <FontAwesomeIcon
                icon={['far', 'arrow-right']}
                color={colors.purple[400]}
                className={Styles.linkArrow}
                size="sm"
              />
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MenuPopup;
