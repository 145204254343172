// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment FullWidthHeroBannerBlock on FullWidthHeroContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    scrolling_text  {
      __typename
      value  {
        __typename
        ...on TextListContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          text_list_items  {
            __typename
            value  {
              __typename
              ...on TextListItemContentType   {
                __typename
                system  {
                  __typename
                  id
                  codename
                }
                text  {
                  __typename
                  value
                }
              }
            }
          }
        }
      }
    }
    scrolling_text_colour  {
      __typename
      value
    }
    buttons  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    video_button_icon  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    video_button_url  {
      __typename
      value
    }
    video_button_text  {
      __typename
      value
    }
    background_colour  {
      __typename
      value
    }
    desktop_image  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    tablet_image  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    mobile_image  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.description;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.scrolling_text;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "TextListContentType") {
                  var value$1 = value.system;
                  var value$2 = value.text_list_items;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp;
                                if (typename === "TextListItemContentType") {
                                  var value$1 = value.system;
                                  var value$2 = value.text;
                                  var tmp$1;
                                  if (value$2 == null) {
                                    tmp$1 = undefined;
                                  } else {
                                    var value$3 = value$2.value;
                                    tmp$1 = {
                                      __typename: value$2.__typename,
                                      value: !(value$3 == null) ? value$3 : undefined
                                    };
                                  }
                                  tmp = {
                                    NAME: "TextListItemContentType",
                                    VAL: {
                                      __typename: value.__typename,
                                      system: {
                                        __typename: value$1.__typename,
                                        id: value$1.id,
                                        codename: value$1.codename
                                      },
                                      text: tmp$1
                                    }
                                  };
                                } else {
                                  tmp = {
                                    NAME: "UnspecifiedFragment",
                                    VAL: typename
                                  };
                                }
                                return tmp;
                              }), value$3) : undefined
                    };
                  }
                  tmp = {
                    NAME: "TextListContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      text_list_items: tmp$1
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$7) : undefined
    };
  }
  var value$8 = value.scrolling_text_colour;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.buttons;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$11) : undefined
    };
  }
  var value$12 = value.video_button_icon;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$13) : undefined
    };
  }
  var value$14 = value.video_button_url;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.video_button_text;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.background_colour;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.desktop_image;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$21) : undefined
    };
  }
  var value$22 = value.tablet_image;
  var tmp$10;
  if (value$22 == null) {
    tmp$10 = undefined;
  } else {
    var value$23 = value$22.value;
    tmp$10 = {
      __typename: value$22.__typename,
      value: !(value$23 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$23) : undefined
    };
  }
  var value$24 = value.mobile_image;
  var tmp$11;
  if (value$24 == null) {
    tmp$11 = undefined;
  } else {
    var value$25 = value$24.value;
    tmp$11 = {
      __typename: value$24.__typename,
      value: !(value$25 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$25) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          description: tmp$1,
          scrolling_text: tmp$2,
          scrolling_text_colour: tmp$3,
          buttons: tmp$4,
          video_button_icon: tmp$5,
          video_button_url: tmp$6,
          video_button_text: tmp$7,
          background_colour: tmp$8,
          desktop_image: tmp$9,
          tablet_image: tmp$10,
          mobile_image: tmp$11
        };
}

function serialize(value) {
  var value$1 = value.mobile_image;
  var mobile_image;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    mobile_image = {
      __typename: value$4,
      value: value$3
    };
  } else {
    mobile_image = null;
  }
  var value$5 = value.tablet_image;
  var tablet_image;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    tablet_image = {
      __typename: value$8,
      value: value$7
    };
  } else {
    tablet_image = null;
  }
  var value$9 = value.desktop_image;
  var desktop_image;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    desktop_image = {
      __typename: value$12,
      value: value$11
    };
  } else {
    desktop_image = null;
  }
  var value$13 = value.background_colour;
  var background_colour;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    background_colour = {
      __typename: value$16,
      value: value$15
    };
  } else {
    background_colour = null;
  }
  var value$17 = value.video_button_text;
  var video_button_text;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    video_button_text = {
      __typename: value$20,
      value: value$19
    };
  } else {
    video_button_text = null;
  }
  var value$21 = value.video_button_url;
  var video_button_url;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    video_button_url = {
      __typename: value$24,
      value: value$23
    };
  } else {
    video_button_url = null;
  }
  var value$25 = value.video_button_icon;
  var video_button_icon;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$26) : null;
    var value$28 = value$25.__typename;
    video_button_icon = {
      __typename: value$28,
      value: value$27
    };
  } else {
    video_button_icon = null;
  }
  var value$29 = value.buttons;
  var buttons;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$30) : null;
    var value$32 = value$29.__typename;
    buttons = {
      __typename: value$32,
      value: value$31
    };
  } else {
    buttons = null;
  }
  var value$33 = value.scrolling_text_colour;
  var scrolling_text_colour;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    scrolling_text_colour = {
      __typename: value$36,
      value: value$35
    };
  } else {
    scrolling_text_colour = null;
  }
  var value$37 = value.scrolling_text;
  var scrolling_text;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.text_list_items;
              var text_list_items;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          if (value.NAME !== "TextListItemContentType") {
                            return {};
                          }
                          var value$1 = value.VAL;
                          var value$2 = value$1.text;
                          var text;
                          if (value$2 !== undefined) {
                            var value$3 = value$2.value;
                            var value$4 = value$3 !== undefined ? value$3 : null;
                            var value$5 = value$2.__typename;
                            text = {
                              __typename: value$5,
                              value: value$4
                            };
                          } else {
                            text = null;
                          }
                          var value$6 = value$1.system;
                          var value$7 = value$6.codename;
                          var value$8 = value$6.id;
                          var value$9 = value$6.__typename;
                          var system = {
                            __typename: value$9,
                            id: value$8,
                            codename: value$7
                          };
                          return {
                                  __typename: "TextListItemContentType",
                                  system: system,
                                  text: text
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                text_list_items = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                text_list_items = null;
              }
              var value$6 = value$1.system;
              var value$7 = value$6.codename;
              var value$8 = value$6.id;
              var value$9 = value$6.__typename;
              var system = {
                __typename: value$9,
                id: value$8,
                codename: value$7
              };
              return {
                      __typename: "TextListContentType",
                      system: system,
                      text_list_items: text_list_items
                    };
            }), value$38) : null;
    var value$40 = value$37.__typename;
    scrolling_text = {
      __typename: value$40,
      value: value$39
    };
  } else {
    scrolling_text = null;
  }
  var value$41 = value.description;
  var description;
  if (value$41 !== undefined) {
    var value$42 = value$41.value;
    var value$43 = value$42 !== undefined ? value$42 : null;
    var value$44 = value$41.__typename;
    description = {
      __typename: value$44,
      value: value$43
    };
  } else {
    description = null;
  }
  var value$45 = value.title;
  var title;
  if (value$45 !== undefined) {
    var value$46 = value$45.value;
    var value$47 = value$46 !== undefined ? value$46 : null;
    var value$48 = value$45.__typename;
    title = {
      __typename: value$48,
      value: value$47
    };
  } else {
    title = null;
  }
  var value$49 = value.system;
  var value$50 = value$49.codename;
  var value$51 = value$49.id;
  var value$52 = value$49.__typename;
  var system = {
    __typename: value$52,
    id: value$51,
    codename: value$50
  };
  var value$53 = value.__typename;
  return {
          __typename: value$53,
          system: system,
          title: title,
          description: description,
          scrolling_text: scrolling_text,
          scrolling_text_colour: scrolling_text_colour,
          buttons: buttons,
          video_button_icon: video_button_icon,
          video_button_url: video_button_url,
          video_button_text: video_button_text,
          background_colour: background_colour,
          desktop_image: desktop_image,
          tablet_image: tablet_image,
          mobile_image: mobile_image
        };
}

function verifyArgsAndParse(_FullWidthHeroBannerBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var FullWidthHeroBannerBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var CallToAction;

export {
  $$Image ,
  CallToAction ,
  FullWidthHeroBannerBlock ,
}
/* query Not a pure module */
