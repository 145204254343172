// Generated by ReScript, PLEASE EDIT WITH CARE


function networkError(msgOpt, status) {
  var msg = msgOpt !== undefined ? msgOpt : "Network error";
  return {
          TAG: /* NetworkError */0,
          _0: status,
          _1: msg
        };
}

export {
  networkError ,
}
/* No side effect */
