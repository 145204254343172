import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import logger from 'utils/logger';
import poll from 'utils/poll';

/**
 * Request to fetch exchange information about a product.
 * @param uid The product unique identifier.
 * @returns Promise with exchange store response.
 */

const pollOrderWithRetry = (orderUrl: string): Promise<any> => {
  return poll({
    fn: () =>
      createRequest(orderUrl, {
        method: 'GET',
        isUrl: true,
        withAuth: true,
        withMagicUserAgent: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          prefer: 'respond-async',
        },
      }).then(handleErrors),
    validate: data => {
      return data?.status === 'COMPLETED' || data?.status === 'FAILED' || data?.status === 'PENDING_FULFILLMENT';
    },
    interval: 1500,
    maxAttempts: 10,
  });
};

const exchange = (uid: string, productCode: string, productThemeCode: string): Promise<any> => {
  return createRequest(`api/v3/exchange/${uid}/`, {
    method: 'POST',
    withAuth: true,
    withMagicUserAgent: true,
    logRequest: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Prefer: 'respond-async',
    },
    body: JSON.stringify({
      items: [
        {
          product_code: productCode,
          product_theme_code: productThemeCode,
        },
      ],
    }),
  })
    .then(handleErrors)
    .catch(error => {
      return Promise.reject(error);
    })
    .then(async data => {
      const retryUrl = data?.headers?.get('content-location');
      if (!retryUrl) return Promise.reject('No retry url');
      const result = await pollOrderWithRetry(retryUrl);
      return result;
    })
    .catch(error => {
      logger.error('Error while exchanging product', error);
      return Promise.reject(error);
    });
};

export default exchange;
