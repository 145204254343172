// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FAQ__Decoder__Item from "./Gql__FAQ__Decoder__Item.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "FaqSectionContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var faq_items = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.faq_items, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__FAQ__Decoder__Item.get);
  var smart_link_itemId = c.system.id;
  var smart_link_codename = c.system.codename;
  var smart_link = {
    itemId: smart_link_itemId,
    codename: smart_link_codename
  };
  return {
          title: title,
          faq_items: faq_items,
          smart_link: smart_link
        };
}

var Item;

export {
  Item ,
  get ,
}
/* No side effect */
