// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__HeroBanner__N1ColumnBanner__Fragment from "./Gql__HeroBanner__N1ColumnBanner__Fragment.bs.js";
import * as Gql__HeroBanner__N2ColumnBanner__Fragment from "./Gql__HeroBanner__N2ColumnBanner__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment HeroBanner on HeroBannerContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    width  {
      __typename
      value  {
        __typename
        codename
      }
    }
    search_bar_overlay  {
      __typename
      value  {
        __typename
        codename
      }
    }
    apply_radius  {
      __typename
      value  {
        __typename
        codename
      }
    }
    n1_column_banner_s  {
      __typename
      value  {
        __typename
        ...on N1ColumnHeroBannerContentType   {
          ...N1ColumnHero
        }
      }
    }
    n2_column_banner  {
      __typename
      value  {
        __typename
        ...on N2ColumnHeroBannerContentType   {
          ...N2ColumnHero
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__HeroBanner__N1ColumnBanner__Fragment.N1ColumnHero.query, Gql__HeroBanner__N2ColumnBanner__Fragment.N2ColumnHero.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.width;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.search_bar_overlay;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$5) : undefined
    };
  }
  var value$6 = value.apply_radius;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.n1_column_banner_s;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "N1ColumnHeroBannerContentType" ? ({
                      NAME: "N1ColumnHeroBannerContentType",
                      VAL: {
                        n1ColumnHero: Gql__HeroBanner__N1ColumnBanner__Fragment.N1ColumnHero.verifyArgsAndParse("N1ColumnHero", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$9) : undefined
    };
  }
  var value$10 = value.n2_column_banner;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "N2ColumnHeroBannerContentType" ? ({
                      NAME: "N2ColumnHeroBannerContentType",
                      VAL: {
                        n2ColumnHero: Gql__HeroBanner__N2ColumnBanner__Fragment.N2ColumnHero.verifyArgsAndParse("N2ColumnHero", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$11) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          width: tmp,
          search_bar_overlay: tmp$1,
          apply_radius: tmp$2,
          n1_column_banner_s: tmp$3,
          n2_column_banner: tmp$4
        };
}

function serialize(value) {
  var value$1 = value.n2_column_banner;
  var n2_column_banner;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "N2ColumnHeroBannerContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__HeroBanner__N2ColumnBanner__Fragment.N2ColumnHero.serialize(value.VAL.n2ColumnHero)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    n2_column_banner = {
      __typename: value$4,
      value: value$3
    };
  } else {
    n2_column_banner = null;
  }
  var value$5 = value.n1_column_banner_s;
  var n1_column_banner_s;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__HeroBanner__N1ColumnBanner__Fragment.N1ColumnHero.serialize(value.VAL.n1ColumnHero)]);
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    n1_column_banner_s = {
      __typename: value$8,
      value: value$7
    };
  } else {
    n1_column_banner_s = null;
  }
  var value$9 = value.apply_radius;
  var apply_radius;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$10) : null;
    var value$12 = value$9.__typename;
    apply_radius = {
      __typename: value$12,
      value: value$11
    };
  } else {
    apply_radius = null;
  }
  var value$13 = value.search_bar_overlay;
  var search_bar_overlay;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$14) : null;
    var value$16 = value$13.__typename;
    search_bar_overlay = {
      __typename: value$16,
      value: value$15
    };
  } else {
    search_bar_overlay = null;
  }
  var value$17 = value.width;
  var width;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    width = {
      __typename: value$20,
      value: value$19
    };
  } else {
    width = null;
  }
  var value$21 = value.system;
  var value$22 = value$21.codename;
  var value$23 = value$21.id;
  var value$24 = value$21.__typename;
  var system = {
    __typename: value$24,
    id: value$23,
    codename: value$22
  };
  var value$25 = value.__typename;
  return {
          __typename: value$25,
          system: system,
          width: width,
          search_bar_overlay: search_bar_overlay,
          apply_radius: apply_radius,
          n1_column_banner_s: n1_column_banner_s,
          n2_column_banner: n2_column_banner
        };
}

function verifyArgsAndParse(_HeroBanner, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var HeroBanner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var N1ColumnHero;

var N2ColumnHero;

export {
  N1ColumnHero ,
  N2ColumnHero ,
  HeroBanner ,
}
/* query Not a pure module */
