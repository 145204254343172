// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment N1ColumnHero on N1ColumnHeroBannerContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    background_colour  {
      __typename
      value
    }
    copy_block_alignment  {
      __typename
      value  {
        __typename
        codename
      }
    }
    image_treatment  {
      __typename
      value  {
        __typename
        codename
      }
    }
    title  {
      __typename
      value
    }
    highlight_text_colour  {
      __typename
      value
    }
    title_font_style  {
      __typename
      value  {
        __typename
        codename
      }
    }
    title_text_colour  {
      __typename
      value
    }
    title_font_size  {
      __typename
      value  {
        __typename
        codename
      }
    }
    description  {
      __typename
      value
    }
    description_text_colour  {
      __typename
      value
    }
    description_font_size  {
      __typename
      value  {
        __typename
        codename
      }
    }
    buttons  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    image_desktop  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    image_tablet  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    image_mobile  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    image__2_desktop  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    image__2_tablet  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    image__2_mobile  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    display_badge  {
      __typename
      value  {
        __typename
        codename
      }
    }
    badge_alignment  {
      __typename
      value  {
        __typename
        codename
      }
    }
    badge_text  {
      __typename
      value
    }
    badge_text_colour  {
      __typename
      value
    }
    badge_background_colour  {
      __typename
      value
    }
    badge_outline_colour  {
      __typename
      value
    }
  }
  ${frag_0}
`)(Gql__CallToAction__Fragment.CallToAction.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.background_colour;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.copy_block_alignment;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$5) : undefined
    };
  }
  var value$6 = value.image_treatment;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.title;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.highlight_text_colour;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? value$11 : undefined
    };
  }
  var value$12 = value.title_font_style;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$13) : undefined
    };
  }
  var value$14 = value.title_text_colour;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.title_font_size;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$17) : undefined
    };
  }
  var value$18 = value.description;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.description_text_colour;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? value$21 : undefined
    };
  }
  var value$22 = value.description_font_size;
  var tmp$10;
  if (value$22 == null) {
    tmp$10 = undefined;
  } else {
    var value$23 = value$22.value;
    tmp$10 = {
      __typename: value$22.__typename,
      value: !(value$23 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$23) : undefined
    };
  }
  var value$24 = value.buttons;
  var tmp$11;
  if (value$24 == null) {
    tmp$11 = undefined;
  } else {
    var value$25 = value$24.value;
    tmp$11 = {
      __typename: value$24.__typename,
      value: !(value$25 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$25) : undefined
    };
  }
  var value$26 = value.image_desktop;
  var tmp$12;
  if (value$26 == null) {
    tmp$12 = undefined;
  } else {
    var value$27 = value$26.value;
    tmp$12 = {
      __typename: value$26.__typename,
      value: !(value$27 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$27) : undefined
    };
  }
  var value$28 = value.image_tablet;
  var tmp$13;
  if (value$28 == null) {
    tmp$13 = undefined;
  } else {
    var value$29 = value$28.value;
    tmp$13 = {
      __typename: value$28.__typename,
      value: !(value$29 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$29) : undefined
    };
  }
  var value$30 = value.image_mobile;
  var tmp$14;
  if (value$30 == null) {
    tmp$14 = undefined;
  } else {
    var value$31 = value$30.value;
    tmp$14 = {
      __typename: value$30.__typename,
      value: !(value$31 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$31) : undefined
    };
  }
  var value$32 = value.image__2_desktop;
  var tmp$15;
  if (value$32 == null) {
    tmp$15 = undefined;
  } else {
    var value$33 = value$32.value;
    tmp$15 = {
      __typename: value$32.__typename,
      value: !(value$33 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$33) : undefined
    };
  }
  var value$34 = value.image__2_tablet;
  var tmp$16;
  if (value$34 == null) {
    tmp$16 = undefined;
  } else {
    var value$35 = value$34.value;
    tmp$16 = {
      __typename: value$34.__typename,
      value: !(value$35 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$35) : undefined
    };
  }
  var value$36 = value.image__2_mobile;
  var tmp$17;
  if (value$36 == null) {
    tmp$17 = undefined;
  } else {
    var value$37 = value$36.value;
    tmp$17 = {
      __typename: value$36.__typename,
      value: !(value$37 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$37) : undefined
    };
  }
  var value$38 = value.display_badge;
  var tmp$18;
  if (value$38 == null) {
    tmp$18 = undefined;
  } else {
    var value$39 = value$38.value;
    tmp$18 = {
      __typename: value$38.__typename,
      value: !(value$39 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$39) : undefined
    };
  }
  var value$40 = value.badge_alignment;
  var tmp$19;
  if (value$40 == null) {
    tmp$19 = undefined;
  } else {
    var value$41 = value$40.value;
    tmp$19 = {
      __typename: value$40.__typename,
      value: !(value$41 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$41) : undefined
    };
  }
  var value$42 = value.badge_text;
  var tmp$20;
  if (value$42 == null) {
    tmp$20 = undefined;
  } else {
    var value$43 = value$42.value;
    tmp$20 = {
      __typename: value$42.__typename,
      value: !(value$43 == null) ? value$43 : undefined
    };
  }
  var value$44 = value.badge_text_colour;
  var tmp$21;
  if (value$44 == null) {
    tmp$21 = undefined;
  } else {
    var value$45 = value$44.value;
    tmp$21 = {
      __typename: value$44.__typename,
      value: !(value$45 == null) ? value$45 : undefined
    };
  }
  var value$46 = value.badge_background_colour;
  var tmp$22;
  if (value$46 == null) {
    tmp$22 = undefined;
  } else {
    var value$47 = value$46.value;
    tmp$22 = {
      __typename: value$46.__typename,
      value: !(value$47 == null) ? value$47 : undefined
    };
  }
  var value$48 = value.badge_outline_colour;
  var tmp$23;
  if (value$48 == null) {
    tmp$23 = undefined;
  } else {
    var value$49 = value$48.value;
    tmp$23 = {
      __typename: value$48.__typename,
      value: !(value$49 == null) ? value$49 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          background_colour: tmp,
          copy_block_alignment: tmp$1,
          image_treatment: tmp$2,
          title: tmp$3,
          highlight_text_colour: tmp$4,
          title_font_style: tmp$5,
          title_text_colour: tmp$6,
          title_font_size: tmp$7,
          description: tmp$8,
          description_text_colour: tmp$9,
          description_font_size: tmp$10,
          buttons: tmp$11,
          image_desktop: tmp$12,
          image_tablet: tmp$13,
          image_mobile: tmp$14,
          image__2_desktop: tmp$15,
          image__2_tablet: tmp$16,
          image__2_mobile: tmp$17,
          display_badge: tmp$18,
          badge_alignment: tmp$19,
          badge_text: tmp$20,
          badge_text_colour: tmp$21,
          badge_background_colour: tmp$22,
          badge_outline_colour: tmp$23
        };
}

function serialize(value) {
  var value$1 = value.badge_outline_colour;
  var badge_outline_colour;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    badge_outline_colour = {
      __typename: value$4,
      value: value$3
    };
  } else {
    badge_outline_colour = null;
  }
  var value$5 = value.badge_background_colour;
  var badge_background_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    badge_background_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    badge_background_colour = null;
  }
  var value$9 = value.badge_text_colour;
  var badge_text_colour;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    badge_text_colour = {
      __typename: value$12,
      value: value$11
    };
  } else {
    badge_text_colour = null;
  }
  var value$13 = value.badge_text;
  var badge_text;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    badge_text = {
      __typename: value$16,
      value: value$15
    };
  } else {
    badge_text = null;
  }
  var value$17 = value.badge_alignment;
  var badge_alignment;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$18) : null;
    var value$20 = value$17.__typename;
    badge_alignment = {
      __typename: value$20,
      value: value$19
    };
  } else {
    badge_alignment = null;
  }
  var value$21 = value.display_badge;
  var display_badge;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$22) : null;
    var value$24 = value$21.__typename;
    display_badge = {
      __typename: value$24,
      value: value$23
    };
  } else {
    display_badge = null;
  }
  var value$25 = value.image__2_mobile;
  var image__2_mobile;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$26) : null;
    var value$28 = value$25.__typename;
    image__2_mobile = {
      __typename: value$28,
      value: value$27
    };
  } else {
    image__2_mobile = null;
  }
  var value$29 = value.image__2_tablet;
  var image__2_tablet;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$30) : null;
    var value$32 = value$29.__typename;
    image__2_tablet = {
      __typename: value$32,
      value: value$31
    };
  } else {
    image__2_tablet = null;
  }
  var value$33 = value.image__2_desktop;
  var image__2_desktop;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$34) : null;
    var value$36 = value$33.__typename;
    image__2_desktop = {
      __typename: value$36,
      value: value$35
    };
  } else {
    image__2_desktop = null;
  }
  var value$37 = value.image_mobile;
  var image_mobile;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$38) : null;
    var value$40 = value$37.__typename;
    image_mobile = {
      __typename: value$40,
      value: value$39
    };
  } else {
    image_mobile = null;
  }
  var value$41 = value.image_tablet;
  var image_tablet;
  if (value$41 !== undefined) {
    var value$42 = value$41.value;
    var value$43 = value$42 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$42) : null;
    var value$44 = value$41.__typename;
    image_tablet = {
      __typename: value$44,
      value: value$43
    };
  } else {
    image_tablet = null;
  }
  var value$45 = value.image_desktop;
  var image_desktop;
  if (value$45 !== undefined) {
    var value$46 = value$45.value;
    var value$47 = value$46 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$46) : null;
    var value$48 = value$45.__typename;
    image_desktop = {
      __typename: value$48,
      value: value$47
    };
  } else {
    image_desktop = null;
  }
  var value$49 = value.buttons;
  var buttons;
  if (value$49 !== undefined) {
    var value$50 = value$49.value;
    var value$51 = value$50 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$50) : null;
    var value$52 = value$49.__typename;
    buttons = {
      __typename: value$52,
      value: value$51
    };
  } else {
    buttons = null;
  }
  var value$53 = value.description_font_size;
  var description_font_size;
  if (value$53 !== undefined) {
    var value$54 = value$53.value;
    var value$55 = value$54 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$54) : null;
    var value$56 = value$53.__typename;
    description_font_size = {
      __typename: value$56,
      value: value$55
    };
  } else {
    description_font_size = null;
  }
  var value$57 = value.description_text_colour;
  var description_text_colour;
  if (value$57 !== undefined) {
    var value$58 = value$57.value;
    var value$59 = value$58 !== undefined ? value$58 : null;
    var value$60 = value$57.__typename;
    description_text_colour = {
      __typename: value$60,
      value: value$59
    };
  } else {
    description_text_colour = null;
  }
  var value$61 = value.description;
  var description;
  if (value$61 !== undefined) {
    var value$62 = value$61.value;
    var value$63 = value$62 !== undefined ? value$62 : null;
    var value$64 = value$61.__typename;
    description = {
      __typename: value$64,
      value: value$63
    };
  } else {
    description = null;
  }
  var value$65 = value.title_font_size;
  var title_font_size;
  if (value$65 !== undefined) {
    var value$66 = value$65.value;
    var value$67 = value$66 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$66) : null;
    var value$68 = value$65.__typename;
    title_font_size = {
      __typename: value$68,
      value: value$67
    };
  } else {
    title_font_size = null;
  }
  var value$69 = value.title_text_colour;
  var title_text_colour;
  if (value$69 !== undefined) {
    var value$70 = value$69.value;
    var value$71 = value$70 !== undefined ? value$70 : null;
    var value$72 = value$69.__typename;
    title_text_colour = {
      __typename: value$72,
      value: value$71
    };
  } else {
    title_text_colour = null;
  }
  var value$73 = value.title_font_style;
  var title_font_style;
  if (value$73 !== undefined) {
    var value$74 = value$73.value;
    var value$75 = value$74 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$74) : null;
    var value$76 = value$73.__typename;
    title_font_style = {
      __typename: value$76,
      value: value$75
    };
  } else {
    title_font_style = null;
  }
  var value$77 = value.highlight_text_colour;
  var highlight_text_colour;
  if (value$77 !== undefined) {
    var value$78 = value$77.value;
    var value$79 = value$78 !== undefined ? value$78 : null;
    var value$80 = value$77.__typename;
    highlight_text_colour = {
      __typename: value$80,
      value: value$79
    };
  } else {
    highlight_text_colour = null;
  }
  var value$81 = value.title;
  var title;
  if (value$81 !== undefined) {
    var value$82 = value$81.value;
    var value$83 = value$82 !== undefined ? value$82 : null;
    var value$84 = value$81.__typename;
    title = {
      __typename: value$84,
      value: value$83
    };
  } else {
    title = null;
  }
  var value$85 = value.image_treatment;
  var image_treatment;
  if (value$85 !== undefined) {
    var value$86 = value$85.value;
    var value$87 = value$86 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$86) : null;
    var value$88 = value$85.__typename;
    image_treatment = {
      __typename: value$88,
      value: value$87
    };
  } else {
    image_treatment = null;
  }
  var value$89 = value.copy_block_alignment;
  var copy_block_alignment;
  if (value$89 !== undefined) {
    var value$90 = value$89.value;
    var value$91 = value$90 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$90) : null;
    var value$92 = value$89.__typename;
    copy_block_alignment = {
      __typename: value$92,
      value: value$91
    };
  } else {
    copy_block_alignment = null;
  }
  var value$93 = value.background_colour;
  var background_colour;
  if (value$93 !== undefined) {
    var value$94 = value$93.value;
    var value$95 = value$94 !== undefined ? value$94 : null;
    var value$96 = value$93.__typename;
    background_colour = {
      __typename: value$96,
      value: value$95
    };
  } else {
    background_colour = null;
  }
  var value$97 = value.system;
  var value$98 = value$97.codename;
  var value$99 = value$97.id;
  var value$100 = value$97.__typename;
  var system = {
    __typename: value$100,
    id: value$99,
    codename: value$98
  };
  var value$101 = value.__typename;
  return {
          __typename: value$101,
          system: system,
          background_colour: background_colour,
          copy_block_alignment: copy_block_alignment,
          image_treatment: image_treatment,
          title: title,
          highlight_text_colour: highlight_text_colour,
          title_font_style: title_font_style,
          title_text_colour: title_text_colour,
          title_font_size: title_font_size,
          description: description,
          description_text_colour: description_text_colour,
          description_font_size: description_font_size,
          buttons: buttons,
          image_desktop: image_desktop,
          image_tablet: image_tablet,
          image_mobile: image_mobile,
          image__2_desktop: image__2_desktop,
          image__2_tablet: image__2_tablet,
          image__2_mobile: image__2_mobile,
          display_badge: display_badge,
          badge_alignment: badge_alignment,
          badge_text: badge_text,
          badge_text_colour: badge_text_colour,
          badge_background_colour: badge_background_colour,
          badge_outline_colour: badge_outline_colour
        };
}

function verifyArgsAndParse(_N1ColumnHero, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var N1ColumnHero = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var CallToAction;

export {
  CallToAction ,
  N1ColumnHero ,
}
/* query Not a pure module */
