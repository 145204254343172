// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "AppStoreItemContentType") {
    return ;
  }
  var c = content.VAL;
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var app_store_name = Belt_Option.getWithDefault(Belt_Option.flatMap(c.app_store_name, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.app_store_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var app_store_image;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description = Belt_Option.getWithDefault(value.description, "");
    var url = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    app_store_image = {
      name: name,
      description: description,
      url: url,
      width: width,
      height: height
    };
  } else {
    app_store_image = undefined;
  }
  var app_store_download_link = Belt_Option.getWithDefault(Belt_Option.flatMap(c.app_store_download_link, (function (c) {
              return c.value;
            })), "");
  return {
          smartLink: smartLink,
          app_store_name: app_store_name,
          app_store_image: app_store_image,
          app_store_download_link: app_store_download_link
        };
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
