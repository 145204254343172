import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

export const validateCustomerEmailErrors = {
  invalidEmail: 'Email: Customer matching query does not exist.',
  blankEmail: 'Email: This field may not be blank.',
};

/**
 * Request to validate a customers email in our database.
 * @param email Email that requires validating.
 * @returns Promise of decoded validate email response.
 */
const validateCustomerEmail = (email: string) => {
  return createRequest('api/v3/customer/validate-email/', {
    method: 'POST',
    body: JSON.stringify({ email }),
    withMagicUserAgent: true,
  }).then(handleErrors);
};

export default validateCustomerEmail;
