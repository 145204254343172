// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__Footer__Decoder__LinkItem from "./Gql__Footer__Decoder__LinkItem.bs.js";
import * as Gql__Footer__Decoder__MailtoLink from "./Gql__Footer__Decoder__MailtoLink.bs.js";
import * as Gql__Footer__Decoder__OnetrustCookieSettingButton from "./Gql__Footer__Decoder__OnetrustCookieSettingButton.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  var variant = content.NAME;
  if (variant === "LinkItemContentType") {
    return {
            NAME: "LinkItemContentType",
            VAL: Gql__Footer__Decoder__LinkItem.get(content.VAL)
          };
  } else if (variant === "MailtoLinkContentType") {
    return {
            NAME: "MailtoLinkContentType",
            VAL: Gql__Footer__Decoder__MailtoLink.get(content.VAL)
          };
  } else if (variant === "OnetrustCookieSettingButtonContentType") {
    return {
            NAME: "OnetrustCookieSettingButtonContentType",
            VAL: Gql__Footer__Decoder__OnetrustCookieSettingButton.get(content.VAL)
          };
  } else {
    return ;
  }
}

var LinkItem;

var MailtoLink;

var LinkItemIcon;

var CookieSettingButton;

export {
  LinkItem ,
  MailtoLink ,
  LinkItemIcon ,
  CookieSettingButton ,
  get ,
}
/* No side effect */
