// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as AuthUtils from "./AuthUtils.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as UserContext from "./UserContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function AuthContext(props) {
  var value = props.value;
  var match = React.useReducer(UserContext.reducer, value !== undefined ? /* Authenticated */({
            _0: value
          }) : /* Guest */0);
  var dispatch = match[1];
  React.useEffect((function () {
          var currentState = Belt_Option.mapWithDefault(AuthUtils.getUserInfo(undefined), /* Logout */0, (function (i) {
                  return {
                          TAG: /* Login */0,
                          _0: i
                        };
                }));
          Curry._1(dispatch, currentState);
        }), [value]);
  return JsxRuntime.jsx(UserContext.UserProvider.make, {
              value: [
                match[0],
                dispatch
              ],
              children: props.children
            });
}

var make = AuthContext;

var $$default = AuthContext;

export {
  make ,
  $$default ,
  $$default as default,
}
/* react Not a pure module */
