import logger from 'utils/logger';

const scriptNotLoaded = () => {
  return typeof window.analytics === 'undefined' || !window.analytics.invoked;
};

const brazeAlias = () => {
  try {
    if (window.analytics) {
      analytics.ready(() => {
        const ajs_aid = analytics.user().anonymousId();
        if (window.appboy) window.appboy.getUser().addAlias(ajs_aid, 'anonymous_id');
      });
    }
  } catch (e) {
    logger.info({
      message: 'info: could not add Braze alias',
      error: e,
    });
  }
};

const debug = (enable = true) => {
  try {
    analytics.debug(enable);
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics debug',
      error: e,
    });
  }
};

const identify = (userId, traits, context) => {
  try {
    if (userId) {
      traits['id'] = userId;
      analytics.identify(userId, traits, context);
    } else {
      analytics.identify(traits, context);
    }
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics identify',
      error: e,
    });
  }
};

const track = (eventName, eventProperties, eventContext) => {
  try {
    analytics.track(eventName, eventProperties, eventContext);
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics track',
      type: 'sync',
      error: e,
    });
  }
};

const trackAsync = async (eventName, eventProperties, eventContext) => {
  try {
    await analytics.track(eventName, eventProperties, eventContext);
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics track',
      type: 'async',
      error: e,
    });
  }
};

const page = (pageCategory, pageName, pageProperties, pageContext) => {
  try {
    analytics.page(pageCategory, pageName, pageProperties, pageContext);
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics page tracking',
      error: e,
    });
  }
};

const group = (groupId, traits) => {
  try {
    analytics.group(groupId, traits);
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics group tracking',
      error: e,
    });
  }
};

const reset = () => {
  try {
    analytics.reset();
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics group reset',
      error: e,
    });
  }
};

const setAnonymousId = id => {
  try {
    analytics.setAnonymousId(id);
  } catch (e) {
    logger.info({
      message: 'info: could not execute Segment Analytics set anonymous ID',
      error: e,
    });
  }
};

function hash(message) {
  return new Promise((resolve, reject) => {
    async function getHash() {
      try {
        if (crypto.subtle) {
          const msgUint8 = new TextEncoder().encode(message);
          const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
          return hashHex;
        }
        return '';
      } catch (e) {
        reject(e);
      }
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(async () => resolve(await getHash()));
    } else {
      setTimeout(async () => resolve(await getHash()), 500);
    }
  });
}

export { scriptNotLoaded, brazeAlias, debug, identify, track, trackAsync, page, group, reset, setAnonymousId, hash };
