export enum Color {
  Error = '#E30B17',
  BorderGray = '#767676',
  DarkGray = '#313E49',
  LightGray = '#A9AAA9',
  BackgroundGray = '#E5E5E5',
  InactiveGray = '#a9a9a9',
  PrimaryBlue = '#3E77B0',
  LightBlue = '#ECF1F7',
  Primary = '#E30B17',
  White = '#FFFFFF',

  Purple700 = '#2B0840',
  Purple600 = '#32064C',
  Purple500 = '#410861',
  Purple400 = '#48096C',
  Purple300 = '#6D3A89',
  Purple200 = '#916BA7',
  Purple100 = '#DACEE2',
  Purple50 = '#EDE6F0',

  Red700 = '#480610',
  Red600 = '#900C20',
  Red500 = '#BE1807',
  Red400 = '#ED1E09',
  Red300 = '#F1453A',
  Red200 = '#F4736B',
  Red100 = '#FABCB5',
  Red50 = '#FDE9E6',

  Pink700 = '#CC7970',
  Pink600 = '#FF978C',
  Pink500 = '#FFB6AF',
  Pink400 = '#FFD2BD',
  Pink300 = '#FFE0D1',
  Pink200 = '#FFE9DE',
  Pink100 = '#FFF6F2',
  Pink50 = '#FFFBF8',

  Lilac700 = '#A09EB0',
  Lilac600 = '#B7B5C9',
  Lilac500 = '#CECBE2',
  Lilac400 = '#E5E2FB',
  Lilac300 = '#EAE8FC',
  Lilac200 = '#EDEBFC7',
  Lilac100 = '#F7F5FD',
  Lilac50 = '#F6F7F9',

  Blue700 = '#102C60',
  Blue600 = '#174291',
  Blue500 = '#1E54B7',
  Blue400 = '#276EF1',
  Blue300 = '#5B91F5',
  Blue200 = '#A0BFF8',
  Blue100 = '#D4E2FC',
  Blue50 = '#EFF3FE',

  Green700 = '#0B3429',
  Green600 = '#166852',
  Green500 = '#1E8B6D',
  Green400 = '#25AE88',
  Green300 = '#51BEA0',
  Green200 = '#7CCEB8',
  Green100 = '#A8DFCF',
  Green50 = '#E9F7F3',

  Yellow700 = '#806022',
  Yellow600 = '#B3862F',
  Yellow500 = '#CC9A36',
  Yellow400 = '#FFC043',
  Yellow300 = '#FFCF70',
  Yellow200 = '#FFE3AC',
  Yellow100 = '#FFF2D9',
  Yellow50 = '#FFFAF0',

  Orange700 = '#7D2714',
  Orange600 = '#AE371C',
  Orange500 = '#CC492C',
  Orange400 = '#FF6937',
  Orange300 = '#FA9269',
  Orange200 = '#FABDA5',
  Orange100 = '#FFE1D6',
  Orange50 = '#FFF3EF',

  Black = '#000000',
  Gray800 = '#1E1E1E',
  Gray700 = '#302F2F',
  Gray600 = '#4B4B4B',
  Gray500 = '#787878',
  Gray400 = '#A0A0A0',
  Gray300 = '#C7C3C3',
  Gray200 = '#E0DEDE',
  Gray100 = '#F0EDED',
  Gray50 = '#F8F8F8',

  AccentPink = '#FFC5EE',
  AccentYellow = '#C7C3C3',
  AccentBlue = '#C7C3C3',
}

export const Styles = {
  BorderRadius: '10px',
};

//https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hexValue = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(',') : null;
}
