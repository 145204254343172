// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../bindings/Pino.bs.js";
import * as ErrorData from "./ErrorData.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function card(json) {
  return {
          themeUid: Json_decode.field("theme_uid", Json_decode.string, json),
          themeImage: Json_decode.field("theme_image", Json_decode.string, json),
          themekeyColour: Json_decode.field("theme_key_colour", Json_decode.string, json),
          themeTextColour: Json_decode.field("theme_text_colour", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          originalBalance: Json_decode.field("original_balance", Json_decode.string, json),
          valueCurrency: Json_decode.field("value_currency", Json_decode.string, json),
          originalBalanceCurrency: Json_decode.field("original_balance_currency", Json_decode.string, json),
          balance: Json_decode.field("balance", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          balanceCurrency: Json_decode.field("balance_currency", Json_decode.string, json),
          expiry: Json_decode.field("expiry", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          isExpired: Json_decode.field("is_expired", Json_decode.bool, json),
          balanceUpdatedAt: Json_decode.field("balance_updated_at", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          notes: Json_decode.field("uid", Json_decode.string, json),
          uid: Json_decode.field("uid", Json_decode.string, json),
          productType: Json_decode.field("product_type", Json_decode.string, json),
          cardLabel: Json_decode.field("card_label", Json_decode.string, json),
          cardPin: Json_decode.field("card_pin", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          cardId: Json_decode.field("card_id", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.string, json),
          barcodeNumber: Json_decode.field("barcode_number", Json_decode.string, json),
          barcodeLabel: Json_decode.field("barcode_label", Json_decode.string, json),
          instoreNumber: Json_decode.field("instore_number", Json_decode.string, json),
          onlineNumber: Json_decode.field("online_number", Json_decode.string, json),
          pin: Json_decode.field("pin", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          pinFieldName: Json_decode.field("pin_field_name", Json_decode.string, json),
          cardBarcodeFormat: Json_decode.field("card_barcode_format", Json_decode.string, json),
          cardLogo: Json_decode.field("card_logo", Json_decode.string, json),
          merchantTerms: Json_decode.field("merchant_terms", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          status: Json_decode.field("status", Json_decode.string, json),
          subtype: Json_decode.field("subtype", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          updatedAt: Json_decode.field("updated_at", Json_decode.string, json),
          activatedAt: Json_decode.field("activated_at", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          createdAt: Json_decode.field("created_at", Json_decode.string, json),
          receivedAt: Json_decode.field("received_at", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          gift: Json_decode.field("gift", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          isSwapCard: Json_decode.field("is_swap_card", Json_decode.bool, json),
          isSchemeCard: Json_decode.field("is_scheme_card", Json_decode.bool, json),
          swapCard: Json_decode.field("swap_card", Json_decode.bool, json),
          donation: Json_decode.field("donation", Json_decode.bool, json),
          supportsRegift: Json_decode.field("supports_regift", Json_decode.bool, json),
          supportsBalanceCheck: Json_decode.field("supports_balance_check", Json_decode.bool, json),
          supportsAppleWalletExport: Json_decode.field("supports_apple_wallet_export", Json_decode.bool, json),
          supportsGoogleWalletExport: Json_decode.field("supports_google_wallet_export", Json_decode.bool, json),
          receiptText: Json_decode.field("receipt_text", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          receiptLogoTop: Json_decode.field("receipt_logo_top", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          redemptionUrl: Json_decode.field("redemption_url", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          balanceCheckUrl: Json_decode.field("balance_check_url", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          howToUse: Json_decode.field("how_to_use", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          skuDisplayName: Json_decode.field("sku_display_name", Json_decode.string, json),
          skuUid: Json_decode.field("sku_uid", Json_decode.string, json),
          pdfUrl: Json_decode.field("pdf_url", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          defaultProductCode: Json_decode.field("default_product_code", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          defaultProductThemeCode: Json_decode.field("default_product_theme_code", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          defaultSkuUid: Json_decode.field("default_sku_uid", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json)
        };
}

function theme(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          thumbImage: Json_decode.field("thumb_image", Json_decode.string, json),
          squareIcon: Json_decode.field("square_icon", Json_decode.string, json),
          textureImage: Json_decode.field("texture_image", Json_decode.string, json),
          keyColour: Json_decode.field("key_colour", Json_decode.string, json),
          textColour: Json_decode.field("text_colour", Json_decode.string, json)
        };
}

function providerDetails(json) {
  return {
          videoId: Json_decode.field("video_id", Json_decode.string, json),
          accountId: Json_decode.field("account_id", Json_decode.string, json),
          playerId: Json_decode.field("player_id", Json_decode.string, json)
        };
}

function media(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          mediaType: Json_decode.field("media_type", Json_decode.string, json),
          provider: Json_decode.field("provider", Json_decode.string, json),
          providerDetails: Json_decode.field("provider_details", providerDetails, json)
        };
}

function historyCard(json) {
  return {
          uid: Json_decode.optional((function (param) {
                  return Json_decode.field("uid", Json_decode.string, param);
                }), json),
          cardLabel: Json_decode.field("card_label", Json_decode.string, json),
          themeImage: Json_decode.field("theme_image", Json_decode.string, json),
          balance: Json_decode.field("balance", Json_decode.string, json),
          balanceCurrency: Json_decode.field("balance_currency", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          valueCurrency: Json_decode.field("value_currency", Json_decode.string, json),
          originalBalance: Json_decode.field("original_balance", Json_decode.string, json),
          originalBalanceCurrency: Json_decode.field("original_balance_currency", Json_decode.string, json)
        };
}

function historyGift(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          card: Json_decode.field("card", historyCard, json),
          theme: Json_decode.field("theme", theme, json)
        };
}

function skuTheme(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.string, json),
          image: Json_decode.field("image", Json_decode.string, json),
          keyColour: Json_decode.field("key_colour", Json_decode.string, json),
          textColour: Json_decode.field("text_colour", Json_decode.string, json)
        };
}

function failedCard(json) {
  return {
          orderNumber: Json_decode.field("order_number", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          valueCurrency: Json_decode.field("value_currency", Json_decode.string, json),
          skuTheme: Json_decode.field("sku_theme", skuTheme, json),
          skuDisplayName: Json_decode.field("sku_display_name", Json_decode.string, json),
          uid: Json_decode.field("uid", Json_decode.string, json)
        };
}

function giftData(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          regifted: Json_decode.field("regifted", Json_decode.bool, json),
          message: Json_decode.field("message", Json_decode.string, json),
          recipientName: Json_decode.field("recipient_name", Json_decode.string, json),
          recipientEmail: Json_decode.optional((function (param) {
                  return Json_decode.field("recipient_email", Json_decode.string, param);
                }), json),
          senderName: Json_decode.field("sender_name", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          senderEmail: Json_decode.optional((function (param) {
                  return Json_decode.field("sender_email", Json_decode.string, param);
                }), json),
          senderType: Json_decode.optional((function (param) {
                  return Json_decode.field("sender_type", Json_decode.string, param);
                }), json),
          senderLogoUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("sender_logo_url", Json_decode.string, param);
                }), json),
          card: Json_decode.field("card", card, json),
          theme: Json_decode.field("theme", theme, json),
          media: Json_decode.field("media", (function (param) {
                  return Json_decode.array(media, param);
                }), json),
          exchangeHistory: Json_decode.optional((function (param) {
                  return Json_decode.field("exchange_history", (function (param) {
                                return Json_decode.array(historyGift, param);
                              }), param);
                }), json),
          exchangeHistoryFailure: Json_decode.optional((function (param) {
                  return Json_decode.field("exchange_history_failure", (function (param) {
                                return Json_decode.array(failedCard, param);
                              }), param);
                }), json),
          giphyId: Json_decode.optional((function (param) {
                  return Json_decode.field("giphy_id", Json_decode.string, param);
                }), json),
          giphyUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("giphy_url", Json_decode.string, param);
                }), json),
          canAddToWallet: Json_decode.optional((function (param) {
                  return Json_decode.field("can_add_to_wallet", Json_decode.bool, param);
                }), json),
          unwrappedAt: Json_decode.optional((function (param) {
                  return Json_decode.field("unwrapped_at", Json_decode.string, param);
                }), json),
          unwrappingAuthFlowV2: Json_decode.field("unwrapping_auth_flow_v2", Json_decode.bool, json),
          relatedGiftingOccasions: Json_decode.optional((function (param) {
                  return Json_decode.field("related_gifting_occasions", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          pinStatus: Json_decode.field("pin_status", Json_decode.string, json),
          forcedSignIn: Json_decode.field("forced_sign_in", Json_decode.string, json)
        };
}

function safeDecodeGiftData(json) {
  var result;
  try {
    result = giftData(json);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      Pino.logger.error({
            err: e._1,
            type: "Safe Decode Gift Data"
          }, "error: Could not decode response from the server");
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw e;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode = {
  card: card,
  theme: theme,
  providerDetails: providerDetails,
  media: media,
  historyCard: historyCard,
  historyGift: historyGift,
  skuTheme: skuTheme,
  failedCard: failedCard,
  giftData: giftData,
  safeDecodeGiftData: safeDecodeGiftData
};

export {
  Decode ,
}
/* Pino Not a pure module */
