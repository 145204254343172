import { createDeliveryClient as kontentCreateDeliveryClient } from '@kentico/kontent-delivery';
import { magicUserAgentHeader } from 'config/Config.bs';
import { cookieName, getToken } from '../auth/user/AuthUtils.bs';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

import { decryptUserCookie } from 'utils/authUtils';
import logger from 'utils/logger';

declare global {
  interface Window {
    sardineContext: any;
    _Sardine: any;
  }
}

export const getUrlFromPath = (path: string): string => {
  return `${process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL}${path}`;
};

export type RequestParams = RequestInit & {
  isUrl?: boolean;
  logRequest?: boolean;
  withAuth?: boolean;
  withMagicUserAgent?: boolean;
  withSecurityToken?: boolean;
  withAuthHeaderFromServer?: string;
  otherHeaders?: Record<string, string>;
};

/**
 * Creates a new fetch request with correct server URL and headers.
 * @param path Relative api path to create request with.
 * @param optionsParam Default request options object extending custom auth and user agent header options.
 * @returns Generated fetch promise.
 */
export const createRequest = (path: string, optionsParam?: RequestParams) => {
  const {
    isUrl = false,
    logRequest = false,
    withAuth = false,
    withSecurityToken = false,
    withMagicUserAgent = false,
    withAuthHeaderFromServer = false,
    otherHeaders,
    ...otherOptions
  } = optionsParam || {};
  let headers = {
    'content-type': 'application/json',
    accept: 'application/json',
  };
  const fingerprint = Cookies.get('fingerprint-pz');

  if (withAuth) {
    const userCookie = Cookies.get(cookieName);
    const decryptedUser = userCookie ? decryptUserCookie(userCookie) : undefined;
    const isMasq = decryptedUser?.masquerade;
    const tokenType = isMasq ? 'Masq' : 'Bearer';

    headers['Authorization'] = withAuthHeaderFromServer
      ? `${tokenType} ${withAuthHeaderFromServer}`
      : `${tokenType} ${getToken()}`;
  }

  if (fingerprint) headers['x-device-fingerprint'] = fingerprint;

  if (withMagicUserAgent) headers['User-Agent'] = magicUserAgentHeader;
  if (withSecurityToken) {
    const sessionId = getSessionIdToken();
    if (sessionId) headers['X-SECURITY-SESSIONID'] = sessionId;
  }

  headers = {
    ...headers,
    ...otherHeaders,
  };

  const url = isUrl ? path : getUrlFromPath(path);
  const request = {
    headers,
    ...otherOptions,
  };

  if (logRequest) {
    const headerlessRequest = { ...request, headers: { ...headers, Authorization: '' } };
    logger.info({ message: 'Request ' + url, headerlessRequest });
  }

  return fetch(url, request).then(response => {
    if (logRequest) {
      logger.info({
        message: 'Response ' + url,
        response,
      });
    }

    return response;
  });
};

const waitForWindowObject = (
  variablePath: string,
  maxRetries: number = 3,
  checkInterval: number = 100
): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    let attempts = 0;
    const intervalId = setInterval(() => {
      attempts++;
      const parts = variablePath.split('.');
      let obj: any = window;
      for (const part of parts) {
        obj = obj[part];
        if (!obj) {
          break;
        }
      }
      if (obj) {
        clearInterval(intervalId);
        resolve(obj);
      } else if (attempts >= maxRetries) {
        clearInterval(intervalId);
        reject(new Error(`Object ${variablePath} not found after ${maxRetries} attempts.`));
      }
    }, checkInterval);
  });
};

export const getSessionIdToken = ({
  updateConfig = true,
  resetToken = false,
}: {
  updateConfig?: boolean;
  resetToken?: boolean;
} = {}) => {
  let sessionId = Cookies.get('sessionIdToken');
  if (!sessionId || resetToken) {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 60); // add 30 minutes to the current time
    sessionId = uuidv4();
    Cookies.set('sessionIdToken', sessionId, { expires: date });
    if (updateConfig && typeof window !== 'undefined') {
      waitForWindowObject('sardineContext')
        .then(() => {
          window.sardineContext.updateConfig({ sessionKey: sessionId });
        })
        .catch(error => {
          logger.error({ error }, 'Failed to update sardine context with session key');
        });
    }
  }
  return sessionId;
};

export const createDeliveryClient = (language: string, isPreview: boolean) => {
  const deliveryClient = kontentCreateDeliveryClient({
    projectId: process.env.NEXT_PUBLIC_KONTENT_ID,
    defaultLanguage: language,
    defaultQueryConfig: {
      [isPreview ? 'usePreviewMode' : 'useSecuredMode']: true,
    },
    [isPreview ? 'previewApiKey' : 'secureApiKey']: isPreview
      ? process.env.NEXT_PUBLIC_KONTENT_PREVIEW_KEY
      : process.env.NEXT_PUBLIC_KONTENT_SECURE_ACCESS_KEY,
  });

  return deliveryClient;
};
