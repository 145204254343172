import { createContext, useContext } from 'react';

// TODO: Add correct type in here. Type is coming from ReasonML and will need to drill genType to get it which isn't ideal.
export const LayoutDataContext = createContext<any>(null);

export const useLayoutData = () => {
  const ctx = useContext(LayoutDataContext);

  if (!ctx) throw new Error('useLayoutData must be used within a LayoutDataContext.Provider');

  return ctx;
};
