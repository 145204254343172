// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment SkuCarouselBlock on SkuCarouselBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
      language
    }
    title  {
      __typename
      value
    }
    subtitle  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    button  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    background  {
      __typename
      name
      value  {
        __typename
        codename
      }
    }
    sku_list  {
      __typename
      value
    }
  }
  ${frag_0}
`)(Gql__CallToAction__Fragment.CallToAction.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.subtitle;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.description;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.button;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$9) : undefined
    };
  }
  var value$10 = value.background;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      name: value$10.name,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$11) : undefined
    };
  }
  var value$12 = value.sku_list;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? value$13 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename,
            language: value$1.language
          },
          title: tmp,
          subtitle: tmp$1,
          description: tmp$2,
          button: tmp$3,
          background: tmp$4,
          sku_list: tmp$5
        };
}

function serialize(value) {
  var value$1 = value.sku_list;
  var sku_list;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    sku_list = {
      __typename: value$4,
      value: value$3
    };
  } else {
    sku_list = null;
  }
  var value$5 = value.background;
  var background;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$6) : null;
    var value$8 = value$5.name;
    var value$9 = value$5.__typename;
    background = {
      __typename: value$9,
      name: value$8,
      value: value$7
    };
  } else {
    background = null;
  }
  var value$10 = value.button;
  var button;
  if (value$10 !== undefined) {
    var value$11 = value$10.value;
    var value$12 = value$11 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$11) : null;
    var value$13 = value$10.__typename;
    button = {
      __typename: value$13,
      value: value$12
    };
  } else {
    button = null;
  }
  var value$14 = value.description;
  var description;
  if (value$14 !== undefined) {
    var value$15 = value$14.value;
    var value$16 = value$15 !== undefined ? value$15 : null;
    var value$17 = value$14.__typename;
    description = {
      __typename: value$17,
      value: value$16
    };
  } else {
    description = null;
  }
  var value$18 = value.subtitle;
  var subtitle;
  if (value$18 !== undefined) {
    var value$19 = value$18.value;
    var value$20 = value$19 !== undefined ? value$19 : null;
    var value$21 = value$18.__typename;
    subtitle = {
      __typename: value$21,
      value: value$20
    };
  } else {
    subtitle = null;
  }
  var value$22 = value.title;
  var title;
  if (value$22 !== undefined) {
    var value$23 = value$22.value;
    var value$24 = value$23 !== undefined ? value$23 : null;
    var value$25 = value$22.__typename;
    title = {
      __typename: value$25,
      value: value$24
    };
  } else {
    title = null;
  }
  var value$26 = value.system;
  var value$27 = value$26.language;
  var value$28 = value$26.codename;
  var value$29 = value$26.id;
  var value$30 = value$26.__typename;
  var system = {
    __typename: value$30,
    id: value$29,
    codename: value$28,
    language: value$27
  };
  var value$31 = value.__typename;
  return {
          __typename: value$31,
          system: system,
          title: title,
          subtitle: subtitle,
          description: description,
          button: button,
          background: background,
          sku_list: sku_list
        };
}

function verifyArgsAndParse(_SkuCarouselBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SkuCarouselBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var CallToAction;

export {
  CallToAction ,
  SkuCarouselBlock ,
}
/* query Not a pure module */
