// From https://emailregex.com/ Javascript
export const emailRegex: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const recipientNameRegex: RegExp = /^[A-Za-z]+[A-Za-z\s]*$/;

export const containsLowerCaseRegex: RegExp = /(?=.*?[a-z]).*/;

export const containsUpperCaseRegex: RegExp = /(?=.*?[A-Z]).*/;

export const containsDigitRegex: RegExp = /\d/;

export const linuxOrWindows = new RegExp(/(Windows|Linux)/);

export const curlyBrackets: RegExp = /{.*\}/;

export const specialRegex: RegExp = /[$&+,:;=?@#|\\\/'<>.^*()%!-]/;

export const htmlTag: RegExp = /<(.|\n)*?>/;
