// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorData from "./ErrorData.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function config(json) {
  return {
          featureStripeBillingAddress: Json_decode.field("feature_stripe_billing_address", Json_decode.bool, json),
          featureBraintreePaymentOptionGuest: Json_decode.field("feature_braintree_payment_option_guest", Json_decode.bool, json),
          featureBraintreePaymentOptionUser: Json_decode.field("feature_braintree_payment_option_member", Json_decode.bool, json),
          featureGuestCheckout: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("feature_guest_checkout_web", Json_decode.bool, param);
                }), json),
          senderAliases: Json_decode.field("gift_sender_aliases", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          featureLayer: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("feature_layer", Json_decode.bool, param);
                }), json),
          featureLayerForClassic: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("feature_layer_for_classic", Json_decode.bool, param);
                }), json),
          featureEmailTypoDetection: Json_decode.field("feature_email_typo_detection", Json_decode.bool, json)
        };
}

function safeDecodeWebConfig(json) {
  var value;
  try {
    value = config(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: value
        };
}

var Decode = {
  config: config,
  safeDecodeWebConfig: safeDecodeWebConfig
};

export {
  Decode ,
}
/* No side effect */
