// Generated by ReScript, PLEASE EDIT WITH CARE


var Raw = {};

var query = (require("@apollo/client").gql`
  fragment Video on VideoContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    url  {
      __typename
      value
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.url;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          url: tmp
        };
}

function serialize(value) {
  var value$1 = value.url;
  var url;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    url = {
      __typename: value$4,
      value: value$3
    };
  } else {
    url = null;
  }
  var value$5 = value.system;
  var value$6 = value$5.codename;
  var value$7 = value$5.id;
  var value$8 = value$5.__typename;
  var system = {
    __typename: value$8,
    id: value$7,
    codename: value$6
  };
  var value$9 = value.__typename;
  return {
          __typename: value$9,
          system: system,
          url: url
        };
}

function verifyArgsAndParse(_Video, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Video = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  Video ,
}
/* query Not a pure module */
