import { createDeliveryClient } from '../helpers';
import { IContentItem, Elements } from '@kentico/kontent-delivery';

export type InspiredMessageText = IContentItem<{
  message: Elements.TextElement;
}>;

export type InspiredMessaging = IContentItem<{
  occassion: Elements.TextElement;
  messages: Elements.LinkedItemsElement<InspiredMessageText>;
  status: Elements.MultipleChoiceElement;
}>;

const personalisedMessages = async (language: string, isPreview: boolean): Promise<string[] | null> => {
  const client = createDeliveryClient(language, isPreview);

  const data = await (await client.items<InspiredMessaging>().type('inspired_messaging').toPromise()).data;

  const filteredOccasions = [].concat.apply(
    [],
    data.items.filter(item => item.elements?.status?.value?.[0]).map(item => item.elements?.messages?.value)
  );

  const filteredMessages = filteredOccasions.map(occasion => data.linkedItems[occasion].elements?.message?.value);

  return filteredMessages.length ? filteredMessages : null;
};

export default personalisedMessages;
