import logger from 'utils/logger';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { IncomingMessage, ServerResponse } from 'http';
import { useMemo } from 'react';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export type ResolverContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const { message, locations, path } = error;
      logger.error(
        {
          err: error,
          locations,
          path,
          type: 'GraphQL Error',
        },
        `error: Graphql error: ${message}`
      );
    });
  }

  if (networkError) {
    logger.error(
      {
        err: networkError,
        type: 'GraphQL Network Error',
      },
      `error: Graphql Network error: ${networkError.message}`
    );
  }
});

export function createIsomorphLink(context: ResolverContext = {}) {
  const url = context.req.url;
  const isPreview = url.includes('preview=true');

  //flag for Kontent development env
  const isDevKontent = url.includes('dev=true');
  const { HttpLink } = require('@apollo/client');

  //use Kontent development env for stg and local only
  if (isDevKontent && process.env.NEXT_PUBLIC_KONTENT_DEV_ID) {
    return new HttpLink({
      uri: `https://${isPreview ? 'preview-' : ''}graphql.kontent.ai/${process.env.NEXT_PUBLIC_KONTENT_DEV_ID}`,
      headers: {
        Authorization: `Bearer ${
          isPreview
            ? process.env.NEXT_PUBLIC_KONTENT_DEV_PREVIEW_KEY
            : process.env.NEXT_PUBLIC_KONTENT_DEV_SECURE_ACCESS_KEY
        }`,
      },
    });
  }

  return new HttpLink({
    uri: `https://${isPreview ? 'preview-' : ''}graphql.kontent.ai/${process.env.NEXT_PUBLIC_KONTENT_ID}`,
    headers: {
      Authorization: `Bearer ${
        isPreview ? process.env.NEXT_PUBLIC_KONTENT_PREVIEW_KEY : process.env.NEXT_PUBLIC_KONTENT_SECURE_ACCESS_KEY
      }`,
    },
  });
}

function createApolloClient(context?: ResolverContext) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: errorLink.concat(createIsomorphLink(context)),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(
  initialState: any = null,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
  context?: ResolverContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
