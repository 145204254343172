// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__HeroBanner__N1ColumnBanner__Decoder from "./Gql__HeroBanner__N1ColumnBanner__Decoder.bs.js";
import * as Gql__HeroBanner__N2ColumnBanner__Decoder from "./Gql__HeroBanner__N2ColumnBanner__Decoder.bs.js";

function get(c) {
  var width = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.width, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "full_width", (function (str) {
          return str;
        }));
  var isSearchBarOverlay = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.search_bar_overlay, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "no", (function (str) {
          return str;
        })) === "yes";
  var isBorderRadius = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.apply_radius, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "no", (function (str) {
          return str;
        })) === "yes";
  var n1ColumnBanners = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.n1_column_banner_s, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f === "object" && f.NAME === "N1ColumnHeroBannerContentType") {
            return Gql__HeroBanner__N1ColumnBanner__Decoder.get(f.VAL.n1ColumnHero);
          }
          
        }));
  var n2ColumnBanner = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.n2_column_banner, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (f) {
              if (typeof f === "object" && f.NAME === "N2ColumnHeroBannerContentType") {
                return Gql__HeroBanner__N2ColumnBanner__Decoder.get(f.VAL.n2ColumnHero);
              }
              
            })), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          smartLink: smartLink,
          width: width,
          isSearchBarOverlay: isSearchBarOverlay,
          isBorderRadius: isBorderRadius,
          n1ColumnBanners: n1ColumnBanners,
          n2ColumnBanner: n2ColumnBanner
        };
}

export {
  get ,
}
/* No side effect */
