// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Media from "./Media";

var mediaStyles = Media.mediaStyles;

var make = Media.Media;

var Media$1 = {
  make: make
};

var make$1 = Media.MediaContextProvider;

var MediaContextProvider = {
  make: make$1
};

export {
  mediaStyles ,
  Media$1 as Media,
  MediaContextProvider ,
}
/* mediaStyles Not a pure module */
