import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';

type PhoneValidationResponse = {
  phone: string;
};

/**
 * Request to validate a phone number
 * @param phone Phone number to validate.
 * @returns Promise with validated phone response.
 */
const validatePhone = (phone: string): Promise<PhoneValidationResponse> => {
  return createRequest(`api/v3/customer/validate-phone/`, {
    method: 'POST',
    withAuth: true,
    withMagicUserAgent: true,
    body: JSON.stringify({ phone }),
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as PhoneValidationResponse);
};

export default validatePhone;
