// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__Icon__Decoder from "../Icon/Gql__Icon__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "IconContentType") {
    return Gql__Icon__Decoder.get(content.VAL.icon);
  }
  
}

var Icon;

export {
  Icon ,
  get ,
}
/* No side effect */
