import { camelKeys } from 'js-convert-case';
import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { Inventory } from 'models/sku.model';

/**
 * Request to fetch inventory.
 * @returns Promise with decoded inventory response.
 */
const inventory = (authToken?: string): Promise<Inventory> =>
  createRequest('api/v3/inventory/', {
    withAuth: true,
    withMagicUserAgent: true,
    withAuthHeaderFromServer: authToken,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as Inventory);

export default inventory;
