// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../bindings/Pino.bs.js";
import * as Belt_List from "@rescript/std/lib/es6/belt_List.js";
import * as ErrorData from "../api/Data/ErrorData.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function checkout(l) {
  var schedule = Belt_Option.mapWithDefault(l.scheduledDeliveryAt, /* [] */0, (function (schedule) {
          return {
                  hd: [
                    "scheduled_delivery_at",
                    schedule
                  ],
                  tl: /* [] */0
                };
        }));
  var giftMedia = Belt_Option.mapWithDefault(l.giftMediaUid, /* [] */0, (function (schedule) {
          return {
                  hd: [
                    "giftmedia_uid",
                    schedule
                  ],
                  tl: /* [] */0
                };
        }));
  return Json_encode.object_(Belt_List.concat(Belt_List.concat({
                      hd: [
                        "value",
                        l.value
                      ],
                      tl: {
                        hd: [
                          "sender_alias",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), l.senderAlias)
                        ],
                        tl: {
                          hd: [
                            "sku_uid",
                            l.skuUid
                          ],
                          tl: {
                            hd: [
                              "promo_code",
                              l.promoCode
                            ],
                            tl: {
                              hd: [
                                "theme_uid",
                                l.themeUid
                              ],
                              tl: {
                                hd: [
                                  "gifttheme_uid",
                                  l.giftthemeUid
                                ],
                                tl: {
                                  hd: [
                                    "name",
                                    l.name
                                  ],
                                  tl: {
                                    hd: [
                                      "email",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), l.email)
                                    ],
                                    tl: {
                                      hd: [
                                        "phone",
                                        Json_encode.nullable((function (prim) {
                                                return prim;
                                              }), l.phone)
                                      ],
                                      tl: {
                                        hd: [
                                          "message",
                                          l.message
                                        ],
                                        tl: {
                                          hd: [
                                            "guest_first_name",
                                            l.guestFirstName
                                          ],
                                          tl: {
                                            hd: [
                                              "guest_last_name",
                                              l.guestLastName
                                            ],
                                            tl: {
                                              hd: [
                                                "guest_email",
                                                l.guestEmail
                                              ],
                                              tl: {
                                                hd: [
                                                  "type",
                                                  l.guestType
                                                ],
                                                tl: {
                                                  hd: [
                                                    "status",
                                                    l.status
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      "recipient_timezone",
                                                      Json_encode.nullable((function (prim) {
                                                              return prim;
                                                            }), l.recipientTimezone)
                                                    ],
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }, schedule), giftMedia));
}

var Encode = {
  checkout: checkout
};

var ForAFriend = {
  Encode: Encode
};

function checkout$1(l) {
  return Json_encode.object_({
              hd: [
                "value",
                l.value
              ],
              tl: {
                hd: [
                  "sku_uid",
                  l.skuUid
                ],
                tl: {
                  hd: [
                    "promo_code",
                    l.promoCode
                  ],
                  tl: {
                    hd: [
                      "theme_uid",
                      l.themeUid
                    ],
                    tl: {
                      hd: [
                        "gifttheme_uid",
                        l.giftthemeUid
                      ],
                      tl: {
                        hd: [
                          "guest_first_name",
                          l.guestFirstName
                        ],
                        tl: {
                          hd: [
                            "guest_last_name",
                            l.guestLastName
                          ],
                          tl: {
                            hd: [
                              "guest_email",
                              l.guestEmail
                            ],
                            tl: {
                              hd: [
                                "type",
                                l.guestType
                              ],
                              tl: {
                                hd: [
                                  "status",
                                  l.status
                                ],
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Encode$1 = {
  checkout: checkout$1
};

var ForSelf = {
  Encode: Encode$1
};

var GuestCheckout = {
  ForAFriend: ForAFriend,
  ForSelf: ForSelf
};

function checkout$2(l) {
  return Json_encode.object_({
              hd: [
                "sku_uid",
                l.skuUid
              ],
              tl: {
                hd: [
                  "promo_code",
                  l.promoCode
                ],
                tl: {
                  hd: [
                    "theme_uid",
                    l.themeUid
                  ],
                  tl: {
                    hd: [
                      "value",
                      l.value
                    ],
                    tl: {
                      hd: [
                        "status",
                        l.status
                      ],
                      tl: {
                        hd: [
                          "device_id",
                          l.deviceId
                        ],
                        tl: {
                          hd: [
                            "status",
                            l.status
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Encode$2 = {
  checkout: checkout$2
};

var ForSelf$1 = {
  Encode: Encode$2
};

function checkout$3(l) {
  var schedule = Belt_Option.mapWithDefault(l.scheduledDeliveryAt, /* [] */0, (function (schedule) {
          return {
                  hd: [
                    "scheduled_delivery_at",
                    schedule
                  ],
                  tl: /* [] */0
                };
        }));
  var giftMedia = Belt_Option.mapWithDefault(l.giftMediaUid, /* [] */0, (function (schedule) {
          return {
                  hd: [
                    "giftmedia_uid",
                    schedule
                  ],
                  tl: /* [] */0
                };
        }));
  return Json_encode.object_(Belt_List.concat(Belt_List.concat({
                      hd: [
                        "email",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), l.email)
                      ],
                      tl: {
                        hd: [
                          "gifttheme_uid",
                          l.giftThemeUid
                        ],
                        tl: {
                          hd: [
                            "message",
                            l.message
                          ],
                          tl: {
                            hd: [
                              "sender_alias",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), l.senderAlias)
                            ],
                            tl: {
                              hd: [
                                "name",
                                l.name
                              ],
                              tl: {
                                hd: [
                                  "sku_uid",
                                  l.skuUid
                                ],
                                tl: {
                                  hd: [
                                    "promo_code",
                                    l.promoCode
                                  ],
                                  tl: {
                                    hd: [
                                      "theme_uid",
                                      l.themeUid
                                    ],
                                    tl: {
                                      hd: [
                                        "value",
                                        l.value
                                      ],
                                      tl: {
                                        hd: [
                                          "phone",
                                          Json_encode.nullable((function (prim) {
                                                  return prim;
                                                }), l.phone)
                                        ],
                                        tl: {
                                          hd: [
                                            "status",
                                            l.status
                                          ],
                                          tl: {
                                            hd: [
                                              "device_id",
                                              l.deviceId
                                            ],
                                            tl: {
                                              hd: [
                                                "recipient_timezone",
                                                Json_encode.nullable((function (prim) {
                                                        return prim;
                                                      }), l.recipientTimezone)
                                              ],
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }, schedule), giftMedia));
}

var Encode$3 = {
  checkout: checkout$3
};

var ForAFriend$1 = {
  Encode: Encode$3
};

var UserCheckout = {
  ForSelf: ForSelf$1,
  ForAFriend: ForAFriend$1
};

function response(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          regifted: Json_decode.field("regifted", Json_decode.bool, json),
          message: Json_decode.field("message", Json_decode.string, json),
          recipientName: Json_decode.field("recipient_name", Json_decode.string, json),
          recipientEmail: Json_decode.field("recipient_email", Json_decode.string, json),
          senderName: Json_decode.field("sender_name", Json_decode.string, json),
          senderEmail: Json_decode.field("sender_email", Json_decode.string, json),
          orderNumber: Json_decode.field("order_number", Json_decode.string, json)
        };
}

function safeDecodeResponse(json) {
  var result;
  try {
    result = response(json);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      Pino.logger.error({
            err: e._1,
            type: "Payment Model"
          }, "error: Could not decode error from the server");
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw e;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode = {
  response: response,
  safeDecodeResponse: safeDecodeResponse
};

var $$Response = {
  Decode: Decode
};

function paymentOptions(json) {
  return {
          stripe: Json_decode.field("stripe", Json_decode.string, json),
          braintree: Json_decode.optional((function (param) {
                  return Json_decode.field("braintree", Json_decode.string, param);
                }), json)
        };
}

function draftOrderResponse(json) {
  return {
          order: Json_decode.field("order", Json_decode.string, json),
          total: Json_decode.field("total", Json_decode.string, json),
          orderNumber: Json_decode.optional((function (param) {
                  return Json_decode.field("order_number", Json_decode.string, param);
                }), json),
          totalCurrency: Json_decode.field("total_currency", Json_decode.string, json),
          paymentOptions: Json_decode.field("payment_options", paymentOptions, json),
          collectBillingAddress: Json_decode.field("feature_stripe_billing_address", Json_decode.bool, json)
        };
}

function safeDecodeDraftOrderResponse(json) {
  var result;
  try {
    result = draftOrderResponse(json);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      Pino.logger.error({
            err: e._1
          }, "error: could not decode Draft Order Response");
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw e;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode$1 = {
  paymentOptions: paymentOptions,
  draftOrderResponse: draftOrderResponse,
  safeDecodeDraftOrderResponse: safeDecodeDraftOrderResponse
};

var DraftOrderResponse = {
  Decode: Decode$1
};

function paymentIntentRequest(l) {
  return Json_encode.object_({
              hd: [
                "total",
                l.total
              ],
              tl: {
                hd: [
                  "total_currency",
                  l.totalCurrency
                ],
                tl: {
                  hd: [
                    "payment_method",
                    l.paymentMethod
                  ],
                  tl: {
                    hd: [
                      "device_id",
                      l.deviceId
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function paymentIntentBraintreeRequest(l) {
  return Json_encode.object_({
              hd: [
                "total",
                l.total
              ],
              tl: {
                hd: [
                  "total_currency",
                  l.totalCurrency
                ],
                tl: {
                  hd: [
                    "payment_method_type",
                    "nonce"
                  ],
                  tl: {
                    hd: [
                      "payment_method",
                      l.paymentMethod
                    ],
                    tl: {
                      hd: [
                        "device_id",
                        l.deviceId
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var Encode$4 = {
  paymentIntentRequest: paymentIntentRequest,
  paymentIntentBraintreeRequest: paymentIntentBraintreeRequest
};

var PaymentIntentRequest = {
  Encode: Encode$4
};

function paymentIntentResponse(json) {
  return {
          stripeClientSecret: Json_decode.field("stripe_client_secret", Json_decode.string, json),
          stripePaymentIntent: Json_decode.field("stripe_payment_intent", Json_decode.string, json)
        };
}

function safeDecodePaymentIntentResponse(json) {
  var result;
  try {
    result = paymentIntentResponse(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode$2 = {
  paymentIntentResponse: paymentIntentResponse,
  safeDecodePaymentIntentResponse: safeDecodePaymentIntentResponse
};

var PaymentIntentResponse = {
  Decode: Decode$2
};

function finalisePaymentRequest(l) {
  return Json_encode.object_({
              hd: [
                "status",
                l.status
              ],
              tl: {
                hd: [
                  "payment-method-nonce",
                  l.paymentMethodNonce
                ],
                tl: /* [] */0
              }
            });
}

var Encode$5 = {
  finalisePaymentRequest: finalisePaymentRequest
};

var FinalisePaymentRequest = {
  Encode: Encode$5
};

function order(json) {
  return {
          orderNumber: Json_decode.field("order_number", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

function safeDecodeOrder(json) {
  var result;
  try {
    result = order(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode$3 = {
  order: order,
  safeDecodeOrder: safeDecodeOrder
};

var Order = {
  Decode: Decode$3
};

export {
  GuestCheckout ,
  UserCheckout ,
  $$Response ,
  DraftOrderResponse ,
  PaymentIntentRequest ,
  PaymentIntentResponse ,
  FinalisePaymentRequest ,
  Order ,
}
/* Pino Not a pure module */
