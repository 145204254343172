import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { getToken } from 'auth/user/AuthUtils.bs';
import { camelKeys } from 'js-convert-case';
import { Customer } from 'models/customer.model';

export const customerDataErrors = {
  mfaRequired: 'Server MFA Required Error',
  detailsNotProvided: 'Authentication credentials were not provided.',
  tokenMissing: 'No cookie token was found',
};

/**
 * @secure
 * Request to get customer data with provided auth headers.
 * @returns Decoded promise of customer API response.
 */
const getCustomerData = (headers?: Record<string, string>): Promise<Customer> => {
  const token = getToken();
  if (!token) {
    throw new Error(customerDataErrors.tokenMissing);
  }
  return createRequest('api/v3/customer/', {
    credentials: 'same-origin',
    withAuth: true,
    logRequest: true,
    otherHeaders: headers,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as Customer);
};

export default getCustomerData;
