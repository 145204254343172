// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";
import * as Gql__DownloadAppsBlock__Decoder__AppStore from "./Gql__DownloadAppsBlock__Decoder__AppStore.bs.js";
import * as Gql__DownloadAppsBlock__Decoder__SellingPoint from "./Gql__DownloadAppsBlock__Decoder__SellingPoint.bs.js";

function get(c) {
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var selling_points = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.selling_points, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__DownloadAppsBlock__Decoder__SellingPoint.get);
  var app_stores = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.app_stores, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__DownloadAppsBlock__Decoder__AppStore.get);
  var background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (x) {
              if (typeof x === "object" && x.NAME === "ImageContentType") {
                return Gql__Image__Decoder.get(x.VAL.image);
              }
              
            })), 0);
  var mobile_background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (x) {
              if (typeof x === "object" && x.NAME === "ImageContentType") {
                return Gql__Image__Decoder.get(x.VAL.image);
              }
              
            })), 0);
  return {
          smartLink: smartLink,
          title: title,
          description: description,
          selling_points: selling_points,
          app_stores: app_stores,
          background_image: background_image,
          mobile_background_image: mobile_background_image
        };
}

var SellingPoints;

var AppStore;

var $$Image;

export {
  SellingPoints ,
  AppStore ,
  $$Image ,
  get ,
}
/* No side effect */
