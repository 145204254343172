import { gql } from '@apollo/client';

const IMAGE = gql`
  fragment ImageFields on Image {
    _system_ {
      id
      codename
    }
    file {
      url
      name
      description
      width
      height
    }
  }
`;

export default IMAGE;
