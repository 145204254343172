import logger from 'utils/logger';

import { parseRichTextData } from 'utils/parseRichTextData';
import { getEnrichedTextBlockFields } from '../graphql/CmsData.bs';
import { ContentBlock } from '../../common/basicContent';
import { Inventory } from 'models/sku.model';
import * as API from 'api/queries/index';
import { Card_t } from 'models/Models.gen';
import { Card } from 'models/Models.bs';
import { Nullable } from 'models/types';
import { envUtil } from 'common/util';

export interface EnrichedTextBlockType {
  smartLink: {
    itemId: string;
    codename: string;
  };
  content: {
    elements: Array<{
      tag: string;
      data: string;
    }>;
    isObjectItems: boolean;
  };
  width: string;
  alignment: string;
}

const fetchLinkedItems = async (language: string, preview: boolean, codenameList: string[]) => {
  let res;
  try {
    res = await getEnrichedTextBlockFields(language, preview, codenameList);
  } catch (error) {
    logger.error(
      {
        err: error,
        journey: 'Page Content',
      },
      'error: cannot retrieve data from Kontent'
    );
  }

  const data = res; // @note: apply the additional business conditional mapper
  return data;
};

const getInventoryData = async () => {
  try {
    return await API.inventory();
  } catch (error) {
    logger.error(
      {
        err: error,
        source: 'Inventory API',
        journey: 'modifyBlocks function',
      },
      `error: Could not retrieve inventory data`
    );

    throw new Error('Could not retrieve inventory data');
  }
};

export const handleObjectType = (blockFields, codename: string) => {
  let linkedItem = blockFields.callToActionList.find(elem => elem.smartLink.codename === codename);

  if (linkedItem) {
    return {
      tag: 'CTA',
      data: JSON.stringify(linkedItem),
    };
  }

  linkedItem = blockFields.imageList.find(elem => elem.smartLink.codename === codename);

  if (linkedItem) {
    return {
      tag: 'IMAGE',
      data: JSON.stringify(linkedItem),
    };
  }

  return { tag: 'OBJECT', data: codename };
};

const isEnrichedTextBlock = (jsonString: string) => {
  if (!jsonString) return false;
  return jsonString.includes(`"NAME":"EnrichedTextBlock"`);
};

const isSkuCarousel = (jsonString: string) => {
  if (!jsonString) return false;
  return jsonString.includes(`"NAME":"SkuCarouselBlock"`);
};

const modifyContentBlocks = async (
  contentBlocks: ContentBlock[],
  preview: boolean,
  language: string,
  isIndexPage: Nullable<boolean> = false
) => {
  let result = await Promise.all(
    contentBlocks.map(async block => {
      if (block.NAME === 'EnrichedTextBlock') {
        let value = block.VAL;

        if (value?.content?.isObjectItems) {
          let elements = value.content.elements;

          let codenames = elements.filter(element => element.tag === 'OBJECT').map(element => element.data);

          let linkedItems = await fetchLinkedItems(language, preview, codenames);

          let modifiedElements = elements.map(element => {
            if (element.tag === 'OBJECT') {
              let newElement = handleObjectType(linkedItems, element.data);

              return newElement;
            }

            return element;
          });

          block.VAL.content.elements = modifiedElements;
        }

        return block;
      }
      // add inventory as prop to block only to not index page
      if (block.NAME === 'SkuCarouselBlock' && !isIndexPage) {
        const inventory: Inventory = await getInventoryData();
        const cards: Card_t[] = inventory.skus.map(card => Card.skuToCard(card));
        // provide only nessesary fields for Sku Carousel Block
        const minifiedCards = cards.map(
          ({
            uid,
            categories,
            slug,
            themes,
            imgUrl,
            name,
            cmsId,
            isSwappable,
            tagline,
            priceRange,
            displayName,
            promos,
          }) => ({
            uid,
            categories,
            slug,
            themes,
            imgUrl,
            name,
            cmsId,
            isSwappable,
            tagline,
            priceRange,
            displayName,
            promos,
          })
        );
        const pinnedSkuList =
          block.VAL.skuList !== '' ? block.VAL.skuList.split(',').map(str => Card.makeSlug(str)) : null;
        // only featured cards or specified skus
        const filteredCards = !pinnedSkuList
          ? minifiedCards.filter(card => card.categories.find(category => category.name === 'Featured'))
          : minifiedCards.filter(card => pinnedSkuList.includes(card.slug));

        const noOfCards = cards.filter(card => {
          if (card.denominations.length) {
            return card.denominations[0].valueCurrency === envUtil.currencyCode;
          }
        }).length;

        block.VAL.inventory = filteredCards;
        block.VAL.noOfCards = noOfCards;
        return block;
      }
      return block;
    })
  );

  return result;
};

export const decodeQuery = (data): EnrichedTextBlockType => {
  if (!data) return void 0;
  let content = parseRichTextData(data.content.html);
  let linkedItems = data.content.linkedItems.items;

  content.elements = content.elements.map(element => {
    if (element.tag === 'OBJECT') {
      let newElement = handleObjectType(linkedItems, element.data);

      return newElement;
    }

    return element;
  });

  return {
    smartLink: {
      codename: data._system_.codename,
      itemId: data._system_.id,
    },
    content,
    alignment: data.alignment.items.length ? data.alignment.items[0]._system_.codename : 'left',
    width: data.width.items.length ? data.width.items[0]._system_.codename : 'n12columns',
  };
};

export const modifyStoreCategory = async (jsonString: string, preview, language) => {
  let storeCategory = JSON.parse(jsonString);

  if (!storeCategory.top_content) return jsonString;

  let block = storeCategory.top_content;

  if (block.content.isObjectItems) {
    let elements = block.content.elements;

    let codenames = elements.filter(element => element.tag === 'OBJECT').map(element => element.data);

    let linkedItems = await fetchLinkedItems(language, preview, codenames);

    let modifiedElements = elements.map(element => {
      if (element.tag === 'OBJECT') {
        let newElement = handleObjectType(linkedItems, element.data);

        return newElement;
      }

      return element;
    });

    block.content.elements = modifiedElements;
    storeCategory.top_content = block;

    return JSON.stringify(storeCategory);
  }

  return jsonString;
};

type StoreContentItem = any;

/**
 * Links the list of OBJECT Content items
 * @param value
 * @param preview
 * @param language
 */
const linkItems = async (value: any, preview, language) => {
  const linkedBlock = {};
  await Promise.all(
    Object.keys(value).map(async (itemCode: string) => {
      const contentItem: StoreContentItem = value[itemCode];
      if (contentItem?.content?.isObjectItems) {
        let elements = contentItem.content.elements;
        let codenameList = elements.filter(element => element.tag === 'OBJECT').map(element => element.data);
        let linkedItems = await fetchLinkedItems(language, preview, codenameList);
        contentItem.content.elements = elements.map(element => {
          return element.tag === 'OBJECT' ? handleObjectType(linkedItems, element.data) : element;
        });
        linkedBlock[itemCode] = contentItem;
      }
    })
  );
  return linkedBlock;
};

/**
 * Maps the Store Content with the mapped default content
 * @param jsonString
 * @param preview
 * @param language
 */
export const modifyStoreContent = async (jsonString: string, preview, language) => {
  let storeContent = JSON.parse(await modifyBlocks(jsonString, preview, language));
  if (!storeContent.store_content) {
    return jsonString;
  }
  try {
    storeContent.store_content.VAL.content.elements = await linkItems(
      storeContent.store_content.VAL,
      preview,
      language
    );
  } catch (error) {
    logger.error(
      {
        storeContentValue: storeContent?.store_content?.VAL,
        err: error,
        journey: 'Page Content',
        source: 'CMS',
      },
      'error: Store Content item exception'
    );
  }

  return JSON.stringify(storeContent);
};

export const modifyBlocks = async (
  jsonString: string,
  preview: boolean,
  language: string,
  isIndexPage: Nullable<boolean> = false
) => {
  if (!isEnrichedTextBlock(jsonString) && !isSkuCarousel(jsonString)) {
    return jsonString;
  }

  let data = JSON.parse(jsonString);

  data.content_blocks = await modifyContentBlocks(data.content_blocks, preview, language, isIndexPage);

  return JSON.stringify(data);
};

export const modifyLandingBlocks = async (jsonString: string, preview: boolean, language: string) => {
  if (!isEnrichedTextBlock(jsonString)) {
    return jsonString;
  }

  let data = JSON.parse(jsonString);

  data.content.content_blocks = await modifyContentBlocks(data.content.content_blocks, preview, language);

  return JSON.stringify(data);
};
