// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var code = Belt_Option.getWithDefault(Belt_Option.flatMap(c.code, (function (w) {
              return w.value;
            })), "");
  var file = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(file, (function (f) {
              return f.height;
            })), 0);
  var image = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var key_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.key_colour, (function (f) {
              return f.value;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (f) {
              return f.value;
            })), "");
  return {
          code: code,
          key_colour: key_colour,
          description: description$1,
          smartLink: smartLink,
          image: image
        };
}

export {
  get ,
}
/* No side effect */
