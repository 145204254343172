// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";
import * as Gql__FontAwesomeIcon__Fragment from "../FontAwesomeIcon/Gql__FontAwesomeIcon__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment GiftExperienceBlock on TheGiftExperienceContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    promo_image_asset  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    buttons  {
      __typename
      value  {
        __typename
        ...on CallToActionLinkD6a3d61ContentType   {
          ...CallToAction
        }
      }
    }
    list_items  {
      __typename
      value  {
        __typename
        ...on TheGiftExperienceListItemContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          description  {
            __typename
            value
          }
          fontawesome_icon  {
            __typename
            value  {
              __typename
              ...on FontAwesomeContentType   {
                ...FontAwesomeIcon
              }
            }
          }
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__FontAwesomeIcon__Fragment.FontAwesomeIcon.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.promo_image_asset;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$5) : undefined
    };
  }
  var value$6 = value.buttons;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                      NAME: "CallToActionLinkD6a3d61ContentType",
                      VAL: {
                        callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$7) : undefined
    };
  }
  var value$8 = value.list_items;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "TheGiftExperienceListItemContentType") {
                  var value$1 = value.system;
                  var value$2 = value.description;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.fontawesome_icon;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "FontAwesomeContentType" ? ({
                                      NAME: "FontAwesomeContentType",
                                      VAL: {
                                        fontAwesomeIcon: Gql__FontAwesomeIcon__Fragment.FontAwesomeIcon.verifyArgsAndParse("FontAwesomeIcon", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$5) : undefined
                    };
                  }
                  tmp = {
                    NAME: "TheGiftExperienceListItemContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      description: tmp$1,
                      fontawesome_icon: tmp$2
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$9) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          promo_image_asset: tmp$1,
          buttons: tmp$2,
          list_items: tmp$3
        };
}

function serialize(value) {
  var value$1 = value.list_items;
  var list_items;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.fontawesome_icon;
              var fontawesome_icon;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "FontAwesomeContentType") {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__FontAwesomeIcon__Fragment.FontAwesomeIcon.serialize(value.VAL.fontAwesomeIcon)]);
                            } else {
                              return {};
                            }
                          } else {
                            return null;
                          }
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                fontawesome_icon = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                fontawesome_icon = null;
              }
              var value$6 = value$1.description;
              var description;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                description = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                description = null;
              }
              var value$10 = value$1.system;
              var value$11 = value$10.codename;
              var value$12 = value$10.id;
              var value$13 = value$10.__typename;
              var system = {
                __typename: value$13,
                id: value$12,
                codename: value$11
              };
              return {
                      __typename: "TheGiftExperienceListItemContentType",
                      system: system,
                      description: description,
                      fontawesome_icon: fontawesome_icon
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    list_items = {
      __typename: value$4,
      value: value$3
    };
  } else {
    list_items = null;
  }
  var value$5 = value.buttons;
  var buttons;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    buttons = {
      __typename: value$8,
      value: value$7
    };
  } else {
    buttons = null;
  }
  var value$9 = value.promo_image_asset;
  var promo_image_asset;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$10) : null;
    var value$12 = value$9.__typename;
    promo_image_asset = {
      __typename: value$12,
      value: value$11
    };
  } else {
    promo_image_asset = null;
  }
  var value$13 = value.title;
  var title;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    title = {
      __typename: value$16,
      value: value$15
    };
  } else {
    title = null;
  }
  var value$17 = value.system;
  var value$18 = value$17.codename;
  var value$19 = value$17.id;
  var value$20 = value$17.__typename;
  var system = {
    __typename: value$20,
    id: value$19,
    codename: value$18
  };
  var value$21 = value.__typename;
  return {
          __typename: value$21,
          system: system,
          title: title,
          promo_image_asset: promo_image_asset,
          buttons: buttons,
          list_items: list_items
        };
}

function verifyArgsAndParse(_GiftExperienceBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var GiftExperienceBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

var CallToAction;

var FontAwesomeIcon;

export {
  $$Image ,
  CallToAction ,
  FontAwesomeIcon ,
  GiftExperienceBlock ,
}
/* query Not a pure module */
