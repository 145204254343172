// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment CustomerCareCallUsBlock on CustomerCareCallUsContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    pre_title  {
      __typename
      value
    }
    title  {
      __typename
      value
    }
    text  {
      __typename
      value
    }
    subtext  {
      __typename
      value
    }
    phone_contact_method_icon_asset  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    form_contact_method_icon_asset  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    local_phone_number  {
      __typename
      value
    }
    form_contact_method_label  {
      __typename
      value
    }
    form_contact_method_url  {
      __typename
      value
    }
    international_phone_number_text  {
      __typename
      value
    }
    international_phone_number  {
      __typename
      value
    }
    location_phone_numbers  {
      __typename
      value  {
        __typename
        ...on LocationPhoneNumbersContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          location  {
            __typename
            value
          }
          phone_number  {
            __typename
            value
          }
        }
      }
    }
    location_addresses  {
      __typename
      value  {
        __typename
        ...on AddressEntryContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          address_block  {
            __typename
            value
          }
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.pre_title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.title;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.text;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.subtext;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.phone_contact_method_icon_asset;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$11) : undefined
    };
  }
  var value$12 = value.form_contact_method_icon_asset;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$13) : undefined
    };
  }
  var value$14 = value.local_phone_number;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.form_contact_method_label;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.form_contact_method_url;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.international_phone_number_text;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? value$21 : undefined
    };
  }
  var value$22 = value.international_phone_number;
  var tmp$10;
  if (value$22 == null) {
    tmp$10 = undefined;
  } else {
    var value$23 = value$22.value;
    tmp$10 = {
      __typename: value$22.__typename,
      value: !(value$23 == null) ? value$23 : undefined
    };
  }
  var value$24 = value.location_phone_numbers;
  var tmp$11;
  if (value$24 == null) {
    tmp$11 = undefined;
  } else {
    var value$25 = value$24.value;
    tmp$11 = {
      __typename: value$24.__typename,
      value: !(value$25 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "LocationPhoneNumbersContentType") {
                  var value$1 = value.system;
                  var value$2 = value.location;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.phone_number;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  tmp = {
                    NAME: "LocationPhoneNumbersContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      location: tmp$1,
                      phone_number: tmp$2
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$25) : undefined
    };
  }
  var value$26 = value.location_addresses;
  var tmp$12;
  if (value$26 == null) {
    tmp$12 = undefined;
  } else {
    var value$27 = value$26.value;
    tmp$12 = {
      __typename: value$26.__typename,
      value: !(value$27 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "AddressEntryContentType") {
                  var value$1 = value.system;
                  var value$2 = value.address_block;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  tmp = {
                    NAME: "AddressEntryContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      address_block: tmp$1
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$27) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          pre_title: tmp,
          title: tmp$1,
          text: tmp$2,
          subtext: tmp$3,
          phone_contact_method_icon_asset: tmp$4,
          form_contact_method_icon_asset: tmp$5,
          local_phone_number: tmp$6,
          form_contact_method_label: tmp$7,
          form_contact_method_url: tmp$8,
          international_phone_number_text: tmp$9,
          international_phone_number: tmp$10,
          location_phone_numbers: tmp$11,
          location_addresses: tmp$12
        };
}

function serialize(value) {
  var value$1 = value.location_addresses;
  var location_addresses;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.address_block;
              var address_block;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                address_block = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                address_block = null;
              }
              var value$6 = value$1.system;
              var value$7 = value$6.codename;
              var value$8 = value$6.id;
              var value$9 = value$6.__typename;
              var system = {
                __typename: value$9,
                id: value$8,
                codename: value$7
              };
              return {
                      __typename: "AddressEntryContentType",
                      system: system,
                      address_block: address_block
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    location_addresses = {
      __typename: value$4,
      value: value$3
    };
  } else {
    location_addresses = null;
  }
  var value$5 = value.location_phone_numbers;
  var location_phone_numbers;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.phone_number;
              var phone_number;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                phone_number = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                phone_number = null;
              }
              var value$6 = value$1.location;
              var $$location;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                $$location = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                $$location = null;
              }
              var value$10 = value$1.system;
              var value$11 = value$10.codename;
              var value$12 = value$10.id;
              var value$13 = value$10.__typename;
              var system = {
                __typename: value$13,
                id: value$12,
                codename: value$11
              };
              return {
                      __typename: "LocationPhoneNumbersContentType",
                      system: system,
                      location: $$location,
                      phone_number: phone_number
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    location_phone_numbers = {
      __typename: value$8,
      value: value$7
    };
  } else {
    location_phone_numbers = null;
  }
  var value$9 = value.international_phone_number;
  var international_phone_number;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    international_phone_number = {
      __typename: value$12,
      value: value$11
    };
  } else {
    international_phone_number = null;
  }
  var value$13 = value.international_phone_number_text;
  var international_phone_number_text;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    international_phone_number_text = {
      __typename: value$16,
      value: value$15
    };
  } else {
    international_phone_number_text = null;
  }
  var value$17 = value.form_contact_method_url;
  var form_contact_method_url;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    form_contact_method_url = {
      __typename: value$20,
      value: value$19
    };
  } else {
    form_contact_method_url = null;
  }
  var value$21 = value.form_contact_method_label;
  var form_contact_method_label;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    form_contact_method_label = {
      __typename: value$24,
      value: value$23
    };
  } else {
    form_contact_method_label = null;
  }
  var value$25 = value.local_phone_number;
  var local_phone_number;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? value$26 : null;
    var value$28 = value$25.__typename;
    local_phone_number = {
      __typename: value$28,
      value: value$27
    };
  } else {
    local_phone_number = null;
  }
  var value$29 = value.form_contact_method_icon_asset;
  var form_contact_method_icon_asset;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$30) : null;
    var value$32 = value$29.__typename;
    form_contact_method_icon_asset = {
      __typename: value$32,
      value: value$31
    };
  } else {
    form_contact_method_icon_asset = null;
  }
  var value$33 = value.phone_contact_method_icon_asset;
  var phone_contact_method_icon_asset;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$34) : null;
    var value$36 = value$33.__typename;
    phone_contact_method_icon_asset = {
      __typename: value$36,
      value: value$35
    };
  } else {
    phone_contact_method_icon_asset = null;
  }
  var value$37 = value.subtext;
  var subtext;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? value$38 : null;
    var value$40 = value$37.__typename;
    subtext = {
      __typename: value$40,
      value: value$39
    };
  } else {
    subtext = null;
  }
  var value$41 = value.text;
  var text;
  if (value$41 !== undefined) {
    var value$42 = value$41.value;
    var value$43 = value$42 !== undefined ? value$42 : null;
    var value$44 = value$41.__typename;
    text = {
      __typename: value$44,
      value: value$43
    };
  } else {
    text = null;
  }
  var value$45 = value.title;
  var title;
  if (value$45 !== undefined) {
    var value$46 = value$45.value;
    var value$47 = value$46 !== undefined ? value$46 : null;
    var value$48 = value$45.__typename;
    title = {
      __typename: value$48,
      value: value$47
    };
  } else {
    title = null;
  }
  var value$49 = value.pre_title;
  var pre_title;
  if (value$49 !== undefined) {
    var value$50 = value$49.value;
    var value$51 = value$50 !== undefined ? value$50 : null;
    var value$52 = value$49.__typename;
    pre_title = {
      __typename: value$52,
      value: value$51
    };
  } else {
    pre_title = null;
  }
  var value$53 = value.system;
  var value$54 = value$53.codename;
  var value$55 = value$53.id;
  var value$56 = value$53.__typename;
  var system = {
    __typename: value$56,
    id: value$55,
    codename: value$54
  };
  var value$57 = value.__typename;
  return {
          __typename: value$57,
          system: system,
          pre_title: pre_title,
          title: title,
          text: text,
          subtext: subtext,
          phone_contact_method_icon_asset: phone_contact_method_icon_asset,
          form_contact_method_icon_asset: form_contact_method_icon_asset,
          local_phone_number: local_phone_number,
          form_contact_method_label: form_contact_method_label,
          form_contact_method_url: form_contact_method_url,
          international_phone_number_text: international_phone_number_text,
          international_phone_number: international_phone_number,
          location_phone_numbers: location_phone_numbers,
          location_addresses: location_addresses
        };
}

function verifyArgsAndParse(_CustomerCareCallUsBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var CustomerCareCallUsBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  CustomerCareCallUsBlock ,
}
/* query Not a pure module */
