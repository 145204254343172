// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../common/util.bs.js";
import * as BsCss from "../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as CmsImage from "../CmsImage.bs.js";
import * as AssetType from "../../api/graphql/AssetType.bs.js";
import * as MenuPopup from "./MenuPopup.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as UserContext from "../../auth/user/UserContext.bs.js";
import * as Router from "next/router";
import * as LayoutDataContext from "../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Auth$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Auth/Auth.bs.js";
import * as Card$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Card/Card.bs.js";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../pages/index/KontentContextProvider.bs.js";
import * as PaginatedDrawerContext from "../../utils/PaginatedDrawerContext.bs.js";
import * as MediaQuery$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/ReactResponsive/MediaQuery.bs.js";
import * as ProfileMenu$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/ProfileMenu/ProfileMenu.bs.js";
import * as Gql__CallToAction__Decoder from "../../api/graphql/CallToAction/Gql__CallToAction__Decoder.bs.js";
import UseWindowSizeTs from "../../utils/useWindowSize.ts";
import * as Auth__SignedIn$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Auth/Auth__SignedIn.bs.js";
import * as Auth__SignedOut$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Auth/Auth__SignedOut.bs.js";
import * as Gql__DictionaryItems__Decoder from "../../api/graphql/DictionaryItems/Gql__DictionaryItems__Decoder.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as ProfileMenu__Line$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/ProfileMenu/ProfileMenu__Line.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as ProfileMenu__HiLine$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/ProfileMenu/ProfileMenu__HiLine.bs.js";
import * as ProfileMenu__Section$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/ProfileMenu/ProfileMenu__Section.bs.js";
import * as Auth__SignedInMenuItem$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Auth/Auth__SignedInMenuItem.bs.js";
import * as Auth__Business__SignedOut$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Auth/Auth__Business__SignedOut.bs.js";

function useWindowSize(prim) {
  return UseWindowSizeTs(prim);
}

var customKontentEditSignInBtn = Curry._1(BsCss.style, {
      hd: BsCss.transform({
            NAME: "translateX",
            VAL: BsCss.px(-40)
          }),
      tl: /* [] */0
    });

var customKontentEditSignOutBtn = Curry._1(BsCss.style, {
      hd: BsCss.height(BsCss.px(1)),
      tl: {
        hd: BsCss.transform({
              NAME: "translateY",
              VAL: BsCss.px(-61)
            }),
        tl: /* [] */0
      }
    });

var customKontentEditInlineName = Curry._1(BsCss.style, {
      hd: BsCss.height(BsCss.px(1)),
      tl: {
        hd: BsCss.transform({
              NAME: "translateY",
              VAL: BsCss.px(-33)
            }),
        tl: /* [] */0
      }
    });

var signOutAnchor = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexBasis(BsCss.pct(100.0)),
        tl: /* [] */0
      }
    });

var icon = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.em(1)),
      tl: /* [] */0
    });

var Styles = {
  customKontentEditSignInBtn: customKontentEditSignInBtn,
  customKontentEditSignOutBtn: customKontentEditSignOutBtn,
  customKontentEditInlineName: customKontentEditInlineName,
  signOutAnchor: signOutAnchor,
  icon: icon
};

function MenuCard$MenuCard(props) {
  var state = LayoutDataContext.useContext(undefined);
  var router = Router.useRouter();
  var navMenuItems = Belt_Option.getWithDefault(Belt_Option.map(state, (function (s) {
              return s.mobileNavMenuBlock;
            })), []);
  var dictItems = Belt_Option.flatMap(state, (function (s) {
          return s.dictionaryItems;
        }));
  var hiLine = Belt_Option.mapWithDefault(Belt_Option.flatMap(dictItems, (function (s) {
              return s.app_menu_hi;
            })), {
        value: "Hi, {0}",
        smartLink: Gql__DictionaryItems__Decoder.emptyDictionaryEntry.smartLink
      }, (function (param) {
          var smartLink = param.smartLink;
          var value = param.value;
          if (value === "") {
            return {
                    value: "Hi, {0}",
                    smartLink: smartLink
                  };
          } else {
            return {
                    value: value,
                    smartLink: smartLink
                  };
          }
        }));
  var hiLineText = hiLine.value.replace("{0}", props.name);
  var match = hiLine.smartLink;
  var menuItems = Belt_Array.mapWithIndex(navMenuItems, (function (idx, param) {
          var smartLink = param.smartLink;
          var url = param.url;
          var icon = param.icon;
          var profileLine = icon !== undefined ? JsxRuntime.jsx(ProfileMenu__Line$PrezzeeRibbon.make, {
                  icon: JsxRuntime.jsx(CmsImage.make, {
                        src: icon.url,
                        alt: icon.description,
                        width: icon.width,
                        height: icon.height
                      }),
                  children: param.title,
                  onClick: (function (param) {
                      router.push(url);
                    })
                }) : null;
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: profileLine,
                      itemId: smartLink.itemId,
                      elementCodename: smartLink.codename
                    }, String(idx));
        }));
  return JsxRuntime.jsx(Card$PrezzeeRibbon.make, {
              children: JsxRuntime.jsx(ProfileMenu$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsxs(ProfileMenu__Section$PrezzeeRibbon.make, {
                          children: [
                            JsxRuntime.jsx(MediaQuery$PrezzeeRibbon.make, {
                                  query: Theme__Responsive$PrezzeeRibbon.belowLargeScreen,
                                  children: JsxRuntime.jsx(KontentContextProvider.make, {
                                        children: JsxRuntime.jsx(ProfileMenu__HiLine$PrezzeeRibbon.make, {
                                              children: hiLineText
                                            }),
                                        itemId: match.itemId,
                                        elementCodename: match.codename
                                      })
                                }),
                            menuItems
                          ]
                        })
                  })
            });
}

var MenuCard = {
  make: MenuCard$MenuCard
};

function MenuCard$SignedIn(props) {
  var state = LayoutDataContext.useContext(undefined);
  var router = Router.useRouter();
  var menuCard = JsxRuntime.jsx(MenuCard$MenuCard, {
        name: props.user
      });
  var navMenuData = Belt_Option.getWithDefault(Belt_Option.map(state, (function (n) {
              return n.desktopNavMenuBlock;
            })), []);
  var activeState = function (url) {
    if (router.pathname.includes(url)) {
      return true;
    } else {
      return false;
    }
  };
  var menuButtons = Belt_Array.mapWithIndex(navMenuData, (function (i, item) {
          var match = item.fontawesomeIcon;
          if (match !== undefined) {
            return JsxRuntime.jsx(Link, {
                        href: item.url,
                        children: JsxRuntime.jsx("a", {
                              children: JsxRuntime.jsx(Auth__SignedInMenuItem$PrezzeeRibbon.make, {
                                    title: item.title,
                                    icon: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                          icon: [
                                            match.style,
                                            match.icon
                                          ]
                                        }),
                                    activeState: activeState(item.url)
                                  })
                            })
                      }, String(i));
          } else {
            return null;
          }
        }));
  return JsxRuntime.jsx(Auth__SignedIn$PrezzeeRibbon.make, {
              menuCard: menuCard,
              children: menuButtons
            });
}

var SignedIn = {
  make: MenuCard$SignedIn
};

function MenuCard$BusinessSignedIn(props) {
  var menuCard = JsxRuntime.jsx(MenuCard$MenuCard, {
        name: props.user
      });
  var navMenuData = [{
      title: "Dashboard",
      icon: AssetType.emptyAsset,
      fontawesomeIcon: undefined,
      url: "/business/home/",
      smartLink: {
        itemId: "",
        codename: ""
      }
    }];
  var menuButtons = Belt_Array.mapWithIndex(navMenuData, (function (i, item) {
          var match = item.icon;
          if (match !== undefined) {
            return JsxRuntime.jsx(Link, {
                        href: item.url,
                        children: JsxRuntime.jsx("a", {
                              children: JsxRuntime.jsx(Auth__SignedInMenuItem$PrezzeeRibbon.make, {
                                    title: item.title,
                                    icon: JsxRuntime.jsx(CmsImage.make, {
                                          src: match.url,
                                          alt: match.description,
                                          width: match.width,
                                          height: match.height,
                                          className: icon
                                        }),
                                    activeState: false
                                  })
                            })
                      }, String(i));
          } else {
            return null;
          }
        }));
  return JsxRuntime.jsx(Auth__SignedIn$PrezzeeRibbon.make, {
              menuCard: menuCard,
              children: menuButtons
            });
}

var BusinessSignedIn = {
  make: MenuCard$BusinessSignedIn
};

function MenuCard$SignedOut(props) {
  var state = LayoutDataContext.useContext(undefined);
  var match = UseWindowSizeTs(undefined);
  var width = match.width;
  var buttonList = Belt_Option.getWithDefault(Belt_Option.map(state, (function (s) {
              return s.classicLoginCtaList;
            })), []);
  var buttonElements = Belt_Array.mapWithIndex(buttonList, (function (index, v) {
          var match = width > Theme__Responsive$PrezzeeRibbon.largeScreenBreakpoint;
          if (v.NAME === "CallToAction") {
            var block = v.VAL;
            var smartLink = block.smartLink;
            var cta_url = block.cta_url;
            var target = block.open_in_new_window ? "_blank" : "";
            return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                        children: JsxRuntime.jsx(Link, {
                              href: cta_url,
                              prefetch: false,
                              children: JsxRuntime.jsx("a", {
                                    children: JsxRuntime.jsx(KontentContextProvider.make, {
                                          children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                                children: JsxRuntime.jsx("button", {
                                                      children: block.cta_text
                                                    })
                                              }),
                                          itemId: smartLink.itemId,
                                          elementCodename: smartLink.codename
                                        }, cta_url),
                                    className: signOutAnchor,
                                    target: target
                                  })
                            }),
                        fill: block.fill,
                        colour: block.colour,
                        variant: block.variant,
                        className: Auth__SignedOut$PrezzeeRibbon.Styles.authButton
                      }, String(index));
          }
          var block$1 = v.VAL;
          if (match) {
            var match$1 = Belt_Option.getWithDefault(block$1.selectCTA, Gql__CallToAction__Decoder.emptyButton);
            var isLastItem = (index + 1 | 0) === buttonList.length;
            var links = Belt_Array.map(block$1.optionCTAList, (function (cta) {
                    if (cta !== undefined) {
                      return {
                              text: cta.cta_text,
                              url: cta.cta_url
                            };
                    }
                    
                  }));
            return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                        children: JsxRuntime.jsx(MenuPopup.make, {
                              buttonLabel: match$1.cta_text,
                              isPopupRight: isLastItem,
                              links: links,
                              smartLink: block$1.smartLink
                            }),
                        fill: match$1.fill,
                        colour: match$1.colour,
                        variant: match$1.variant,
                        className: Auth__SignedOut$PrezzeeRibbon.Styles.authButton
                      }, String(index));
          }
          var match$2 = PaginatedDrawerContext.useContext(undefined);
          var smartLink$1 = block$1.smartLink;
          var setPage = match$2.setPage;
          var match$3 = Belt_Option.getWithDefault(block$1.selectCTA, Gql__CallToAction__Decoder.emptyButton);
          var codename = smartLink$1.codename;
          return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(KontentContextProvider.make, {
                            children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                  children: JsxRuntime.jsx("button", {
                                        children: match$3.cta_text,
                                        className: signOutAnchor,
                                        onClick: (function (param) {
                                            Curry._1(setPage, {
                                                  code: codename,
                                                  parent: PaginatedDrawerContext.defaultPage
                                                });
                                          })
                                      })
                                }),
                            itemId: smartLink$1.itemId,
                            elementCodename: codename
                          }, match$3.cta_url),
                      fill: match$3.fill,
                      colour: match$3.colour,
                      variant: match$3.variant,
                      className: Auth__Business__SignedOut$PrezzeeRibbon.Styles.authButton
                    }, String(index));
        }));
  return JsxRuntime.jsx(React.Fragment, {
              children: buttonElements
            });
}

var SignedOut = {
  make: MenuCard$SignedOut
};

function MenuCard$BusinessSignedOut(props) {
  var state = LayoutDataContext.useContext(undefined);
  var buttonElements = Belt_Array.mapWithIndex(Belt_Option.getWithDefault(Belt_Option.map(state, (function (s) {
                  return s.businessLoginCtaList;
                })), []), (function (index, param) {
          var smartLink = param.smartLink;
          var target = param.open_in_new_window ? "_blank" : "";
          return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(Link, {
                            href: param.cta_url,
                            prefetch: false,
                            children: JsxRuntime.jsx("a", {
                                  children: JsxRuntime.jsx(KontentContextProvider.make, {
                                        children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                              children: JsxRuntime.jsx("button", {
                                                    children: param.cta_text
                                                  })
                                            }),
                                        itemId: smartLink.itemId,
                                        elementCodename: smartLink.codename
                                      }),
                                  className: signOutAnchor,
                                  target: target
                                })
                          }),
                      fill: param.fill,
                      colour: param.colour,
                      variant: param.variant,
                      className: Auth__Business__SignedOut$PrezzeeRibbon.Styles.authButton
                    }, String(index));
        }));
  return JsxRuntime.jsx(React.Fragment, {
              children: buttonElements
            });
}

var BusinessSignedOut = {
  make: MenuCard$BusinessSignedOut
};

function MenuCard$AuthMenu(props) {
  var match = UserContext.useUser(undefined);
  var userCtx = match[0];
  var user = userCtx ? userCtx._0.firstName : undefined;
  var signedOut = JsxRuntime.jsx(MenuCard$SignedOut, {});
  var signedIn = function (u) {
    return JsxRuntime.jsx(MenuCard$SignedIn, {
                user: u
              });
  };
  return JsxRuntime.jsx(Auth$PrezzeeRibbon.make, {
              user: user,
              signedIn: signedIn,
              signedOut: signedOut
            });
}

var AuthMenu = {
  make: MenuCard$AuthMenu
};

function MenuCard$BusinessAuthMenu(props) {
  var user = Belt_Option.map(Util.Business.getUser(undefined), (function (param) {
          return "";
        }));
  var signedOut = JsxRuntime.jsx(MenuCard$BusinessSignedOut, {});
  var signedIn = function (u) {
    return JsxRuntime.jsx(MenuCard$BusinessSignedIn, {
                user: u
              });
  };
  return JsxRuntime.jsx(Auth$PrezzeeRibbon.make, {
              user: user,
              signedIn: signedIn,
              signedOut: signedOut
            });
}

var BusinessAuthMenu = {
  make: MenuCard$BusinessAuthMenu
};

var CardIcon;

var MenuIcon;

var Link$1;

export {
  useWindowSize ,
  CardIcon ,
  Styles ,
  MenuIcon ,
  Link$1 as Link,
  MenuCard ,
  SignedIn ,
  BusinessSignedIn ,
  SignedOut ,
  BusinessSignedOut ,
  AuthMenu ,
  BusinessAuthMenu ,
}
/* customKontentEditSignInBtn Not a pure module */
