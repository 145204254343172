import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    mobile: 600,
    md: 768,
    lg: 1024,
    xl: 1380,
    tablet: 1288,
    // Ribbon
    rbMobile: 0,
    rbTablet: 425, // 425px
    rbSmallScreen: 768, // 768px
    rbDesktop: 1024, // 1024x
    rbLargeDesktop: 1288, //1288px
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
