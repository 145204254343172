// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  var cookieIcon = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.cookie_popup_icon, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.url;
            })), "");
  var cookieMessage = Belt_Option.getWithDefault(Belt_Option.flatMap(content.cookie_popup_text, (function (c) {
              return c.value;
            })), "");
  var acceptText = Belt_Option.getWithDefault(Belt_Option.flatMap(content.cookie_popup_accept_button, (function (c) {
              return c.value;
            })), "");
  var declineText = Belt_Option.getWithDefault(Belt_Option.flatMap(content.cookie_popup_decline_button, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = content.system.id;
  var smartLink_codename = content.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          cookieIcon: cookieIcon,
          cookieMessage: cookieMessage,
          acceptText: acceptText,
          declineText: declineText,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
