// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Header__Decoder__Page from "./Gql__Header__Decoder__Page.bs.js";

function get(header) {
  var subpages = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(header.subpages, (function (subpagesContent) {
                  return subpagesContent.value;
                })), []), GqlUtils.id);
  var subpagesData = Belt_Array.keepMap(Belt_Array.map(subpages, Gql__Header__Decoder__Page.get), GqlUtils.id);
  if (subpagesData.length !== 0) {
    return subpagesData;
  }
  
}

var Page;

export {
  Page ,
  get ,
}
/* No side effect */
