// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment BoardOfDirectorsBlock on BoardOfDirectorsBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    board_of_directors_cards  {
      __typename
      value  {
        __typename
        ...on BoardOfDirectorsCardContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          name  {
            __typename
            value
          }
          title  {
            __typename
            value
          }
          link  {
            __typename
            value
          }
          photo_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.board_of_directors_cards;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "BoardOfDirectorsCardContentType") {
                  var value$1 = value.system;
                  var value$2 = value.name;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.title;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.link;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.photo_asset;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$9) : undefined
                    };
                  }
                  tmp = {
                    NAME: "BoardOfDirectorsCardContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      name: tmp$1,
                      title: tmp$2,
                      link: tmp$3,
                      photo_asset: tmp$4
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$5) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          board_of_directors_cards: tmp$1
        };
}

function serialize(value) {
  var value$1 = value.board_of_directors_cards;
  var board_of_directors_cards;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME !== "BoardOfDirectorsCardContentType") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.photo_asset;
              var photo_asset;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                photo_asset = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                photo_asset = null;
              }
              var value$6 = value$1.link;
              var link;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                link = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                link = null;
              }
              var value$10 = value$1.title;
              var title;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                title = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                title = null;
              }
              var value$14 = value$1.name;
              var name;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? value$15 : null;
                var value$17 = value$14.__typename;
                name = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                name = null;
              }
              var value$18 = value$1.system;
              var value$19 = value$18.codename;
              var value$20 = value$18.id;
              var value$21 = value$18.__typename;
              var system = {
                __typename: value$21,
                id: value$20,
                codename: value$19
              };
              return {
                      __typename: "BoardOfDirectorsCardContentType",
                      system: system,
                      name: name,
                      title: title,
                      link: link,
                      photo_asset: photo_asset
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    board_of_directors_cards = {
      __typename: value$4,
      value: value$3
    };
  } else {
    board_of_directors_cards = null;
  }
  var value$5 = value.title;
  var title;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    title = {
      __typename: value$8,
      value: value$7
    };
  } else {
    title = null;
  }
  var value$9 = value.system;
  var value$10 = value$9.codename;
  var value$11 = value$9.id;
  var value$12 = value$9.__typename;
  var system = {
    __typename: value$12,
    id: value$11,
    codename: value$10
  };
  var value$13 = value.__typename;
  return {
          __typename: value$13,
          system: system,
          title: title,
          board_of_directors_cards: board_of_directors_cards
        };
}

function verifyArgsAndParse(_BoardOfDirectorsBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var BoardOfDirectorsBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

export {
  $$Image ,
  BoardOfDirectorsBlock ,
}
/* query Not a pure module */
