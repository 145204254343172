// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(header) {
  var subpages = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(header.subpages_subviews, (function (subpagesContent) {
                  return subpagesContent.value;
                })), []), GqlUtils.id);
  var subpagesData = Belt_Array.map(subpages, (function (content) {
          if (typeof content === "object") {
            if (content.NAME === "HeaderNavigationContentType") {
              var nav = content.VAL;
              var title = Belt_Option.getWithDefault(Belt_Option.flatMap(nav.label, (function (t) {
                          return t.value;
                        })), "");
              var url = Belt_Option.getWithDefault(Belt_Option.flatMap(nav.url, (function (u) {
                          return u.value;
                        })), "");
              var smartLink_itemId = nav.system.id;
              var smartLink_codename = nav.system.codename;
              var smartLink = {
                itemId: smartLink_itemId,
                codename: smartLink_codename
              };
              return {
                      title: title,
                      url: url,
                      smartLink: smartLink
                    };
            }
            throw {
                  RE_EXN_ID: "Match_failure",
                  _1: [
                    "Gql__HeaderNavigation__Decoder.res",
                    17,
                    4
                  ],
                  Error: new Error()
                };
          }
          throw {
                RE_EXN_ID: "Match_failure",
                _1: [
                  "Gql__HeaderNavigation__Decoder.res",
                  17,
                  4
                ],
                Error: new Error()
              };
        }));
  if (subpagesData.length !== 0) {
    return subpagesData;
  }
  
}

export {
  get ,
}
/* No side effect */
