// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FAQ__Decoder from "../FAQ/Gql__FAQ__Decoder.bs.js";
import * as Gql__SeoMeta__Decoder from "../SeoMetaContentType/Gql__SeoMeta__Decoder.bs.js";
import * as Gql__NarrowCta__Decoder from "../NarrowCta/Gql__NarrowCta__Decoder.bs.js";
import * as Gql__HeroBanner__Decoder from "../HeroBanner/Gql__HeroBanner__Decoder.bs.js";
import * as Gql__StoreContent__Decoder from "../StoreContentContentType/Gql__StoreContent__Decoder.bs.js";
import * as Gql__AutomateSimplify__Decoder from "../AutomateSimplify/Gql__AutomateSimplify__Decoder.bs.js";
import * as Gql__NarrowBannerFull__Decoder from "../NarrowBannerFull/Gql__NarrowBannerFull__Decoder.bs.js";
import * as Gql__ProductHeroBlock__Decoder from "../ProductHeroBlock/Gql__ProductHeroBlock__Decoder.bs.js";
import * as Gql__EnrichedTextBlock__Decoder from "../EnrichedTextBlock/Gql__EnrichedTextBlock__Decoder.bs.js";
import * as Gql__CustomerCareBanner__Decoder from "../CustomerCareBanner/Gql__CustomerCareBanner__Decoder.bs.js";
import * as Gql__CustomerCareCallUs__Decoder from "../CustomerCareCallUs/Gql__CustomerCareCallUs__Decoder.bs.js";
import * as Gql__SellingPointsBlock__Decoder from "../SellingPointsBlock/Gql__SellingPointsBlock__Decoder.bs.js";
import * as Gql__BusinessContactForm__Decoder from "../BusinessContactForm/Gql__BusinessContactForm__Decoder.bs.js";
import * as Gql__IconCardBlock__Decoder__Block from "../IconCardBlock/Gql__IconCardBlock__Decoder__Block.bs.js";
import * as Gql__GiftExperience__Decoder__Block from "../GiftExperience/Gql__GiftExperience__Decoder__Block.bs.js";
import * as Gql__SkuCarousel__Decoder__Carousel from "../SkuCarousel/Gql__SkuCarousel__Decoder__Carousel.bs.js";
import * as Gql__TrustpilotWidgetBlock__Decoder from "../TrustpilotWidgetBlock/Gql__TrustpilotWidgetBlock__Decoder.bs.js";
import * as Gql__VideoTextPromo__Decoder__Block from "../VideoTextPromo/Gql__VideoTextPromo__Decoder__Block.bs.js";
import * as Gql__NewsletterBlock__Decoder__Block from "../NewsletterBlock/Gql__NewsletterBlock__Decoder__Block.bs.js";
import * as Gql__OffsetTilesBlock__Decoder__Block from "../OffsetTilesBlock/Gql__OffsetTilesBlock__Decoder__Block.bs.js";
import * as Gql__TeamMembersBlock__Decoder__Block from "../TeamMembersBlock/Gql__TeamMembersBlock__Decoder__Block.bs.js";
import * as Gql__DownloadAppsBlock__Decoder__Block from "../DownloadAppsBlock/Gql__DownloadAppsBlock__Decoder__Block.bs.js";
import * as Gql__HeroTiles__Decoder__HeroTileBlock from "../HeroTiles/Gql__HeroTiles__Decoder__HeroTileBlock.bs.js";
import * as Gql__FullWidthTextBlock__Decoder__Block from "../FullWidthTextBlock/Gql__FullWidthTextBlock__Decoder__Block.bs.js";
import * as Gql__FullWidthHeroBanner__Decoder__Block from "../FullWidthHeroBanner/Gql__FullWidthHeroBanner__Decoder__Block.bs.js";
import * as Gql__InnovativeWayToGift__Decoder__Block from "../InnovativeWayToGift/Gql__InnovativeWayToGift__Decoder__Block.bs.js";
import * as Gql__PromoTiles__Decoder__PromoTileBlock from "../PromoTiles/Gql__PromoTiles__Decoder__PromoTileBlock.bs.js";
import * as Gql__StatsTiles__Decoder__StatsTilesBlock from "../StatsTiles/Gql__StatsTiles__Decoder__StatsTilesBlock.bs.js";
import * as Gql__BoardOfDirectorsBlock__Decoder__Block from "../BoardOfDirectorsBlock/Gql__BoardOfDirectorsBlock__Decoder__Block.bs.js";
import * as Gql__LandingPageContactForm__Decoder__Block from "../LandingPageContactForm/Gql__LandingPageContactForm__Decoder__Block.bs.js";
import * as Gql__TestimonialCarouselBlock__Decoder__Block from "../TestimonialCarouselBlock/Gql__TestimonialCarouselBlock__Decoder__Block.bs.js";
import * as Gql__UniqueSellingPointsBlock__Decoder__Block from "../UniqueSellingPointsBlock/Gql__UniqueSellingPointsBlock__Decoder__Block.bs.js";
import * as Gql__CategoryTiles__Decoder__CategoryTileBlock from "../CategoryTiles/Gql__CategoryTiles__Decoder__CategoryTileBlock.bs.js";

function contentBlockSwitch(content) {
  if (typeof content !== "object") {
    return ;
  }
  var variant = content.NAME;
  if (variant === "NarrowCtaContentType") {
    return {
            NAME: "NarrowCta",
            VAL: Gql__NarrowCta__Decoder.get(content.VAL.narrowCta)
          };
  } else if (variant === "OffsetTilesBlockContentType") {
    return {
            NAME: "OffsetTilesBlock",
            VAL: Gql__OffsetTilesBlock__Decoder__Block.get(content.VAL.offsetTilesBlock)
          };
  } else if (variant === "FaqBlockContentType") {
    return {
            NAME: "FAQBlock",
            VAL: Gql__FAQ__Decoder.get(content.VAL.fAQBlock)
          };
  } else if (variant === "TestimonialCarouselBlockContentType") {
    return {
            NAME: "TestimonialCarouselBlock",
            VAL: Gql__TestimonialCarouselBlock__Decoder__Block.get(content.VAL.testimonialCarouselBlock)
          };
  } else if (variant === "IconCardBlockContentType") {
    return {
            NAME: "IconCardBlock",
            VAL: Gql__IconCardBlock__Decoder__Block.get(content.VAL.iconCardBlock)
          };
  } else if (variant === "SellingPointsBlockContentType") {
    return {
            NAME: "SellingPointsBlock",
            VAL: Gql__SellingPointsBlock__Decoder.get(content.VAL.sellingPointsBlock)
          };
  } else if (variant === "CategoryTileBlockContentType") {
    return {
            NAME: "CategoryTileBlock",
            VAL: Gql__CategoryTiles__Decoder__CategoryTileBlock.get(content.VAL.categoryTileBlock)
          };
  } else if (variant === "HeroBannerContentType") {
    return {
            NAME: "HeroBanner",
            VAL: Gql__HeroBanner__Decoder.get(content.VAL.heroBanner)
          };
  } else if (variant === "ProductHeroBlockContentType") {
    return {
            NAME: "ProductHeroBlock",
            VAL: Gql__ProductHeroBlock__Decoder.get(content.VAL.productHeroBlock)
          };
  } else if (variant === "TeamMembersBlockContentType") {
    return {
            NAME: "TeamMembersBlock",
            VAL: Gql__TeamMembersBlock__Decoder__Block.get(content.VAL.teamMembersBlock)
          };
  } else if (variant === "LandingPageContactFormContentType") {
    return {
            NAME: "LandingPageContactFormBlock",
            VAL: Gql__LandingPageContactForm__Decoder__Block.get(content.VAL.landingPageContactFormBlock)
          };
  } else if (variant === "CustomerCareCallUsContentType") {
    return {
            NAME: "CustomerCareCallUsBlock",
            VAL: Gql__CustomerCareCallUs__Decoder.get(content.VAL.customerCareCallUsBlock)
          };
  } else if (variant === "EnvironmentalImpactsBlockContentType") {
    return {
            NAME: "StatsTilesBlock",
            VAL: Gql__StatsTiles__Decoder__StatsTilesBlock.get(content.VAL.statsTilesBlock)
          };
  } else if (variant === "VideoTextPromoBoxContentType") {
    return {
            NAME: "VideoTextPromoBlock",
            VAL: Gql__VideoTextPromo__Decoder__Block.get(content.VAL.videoTextPromoBlock)
          };
  } else if (variant === "PromoTileBlockContentType") {
    return {
            NAME: "PromoTileBlock",
            VAL: Gql__PromoTiles__Decoder__PromoTileBlock.get(content.VAL.promoTileBlock)
          };
  } else if (variant === "SkuCarouselBlockContentType") {
    return {
            NAME: "SkuCarouselBlock",
            VAL: Gql__SkuCarousel__Decoder__Carousel.get(content.VAL.skuCarouselBlock)
          };
  } else if (variant === "AppDownloadBlockContentType") {
    return {
            NAME: "DownloadAppsBlock",
            VAL: Gql__DownloadAppsBlock__Decoder__Block.get(content.VAL.appDownloadBlock)
          };
  } else if (variant === "BusinessContactFormContentType") {
    return {
            NAME: "BusinessContactFormBlock",
            VAL: Gql__BusinessContactForm__Decoder.get(content.VAL.businessContactFormBlock)
          };
  } else if (variant === "InnovativeWayToGiftContentType") {
    return {
            NAME: "InnovativeWayToGiftBlock",
            VAL: Gql__InnovativeWayToGift__Decoder__Block.get(content.VAL.innovativeWayToGiftBlock)
          };
  } else if (variant === "EmailSignUpBlockContentType") {
    return {
            NAME: "NewsletterBlock",
            VAL: Gql__NewsletterBlock__Decoder__Block.get(content.VAL.newsletterBlock)
          };
  } else if (variant === "NarrowBannerFullContentType") {
    return {
            NAME: "NarrowBannerFull",
            VAL: Gql__NarrowBannerFull__Decoder.get(content.VAL.narrowBannerFull)
          };
  } else if (variant === "FullWidthTextBlockContentType") {
    return {
            NAME: "FullWidthTextBlock",
            VAL: Gql__FullWidthTextBlock__Decoder__Block.get(content.VAL.fullWidthTextBlock)
          };
  } else if (variant === "TheGiftExperienceContentType") {
    return {
            NAME: "GiftExperienceBlock",
            VAL: Gql__GiftExperience__Decoder__Block.get(content.VAL.giftExperienceBlock)
          };
  } else if (variant === "FullWidthHeroContentType") {
    return {
            NAME: "FullWidthHeroBannerBlock",
            VAL: Gql__FullWidthHeroBanner__Decoder__Block.get(content.VAL.fullWidthHeroBannerBlock)
          };
  } else if (variant === "BoardOfDirectorsBlockContentType") {
    return {
            NAME: "BoardOfDirectorsBlock",
            VAL: Gql__BoardOfDirectorsBlock__Decoder__Block.get(content.VAL.boardOfDirectorsBlock)
          };
  } else if (variant === "AutomateAndSimplifyContentType") {
    return {
            NAME: "AutomateSimplifyBlock",
            VAL: Gql__AutomateSimplify__Decoder.get(content.VAL.automateSimplifyBlock)
          };
  } else if (variant === "UniqueSellingPointsBlockContentType") {
    return {
            NAME: "UniqueSellingPointsBlock",
            VAL: Gql__UniqueSellingPointsBlock__Decoder__Block.get(content.VAL.uniqueSellingPointsBlock)
          };
  } else if (variant === "TrustpilotWidgetBlockContentType") {
    return {
            NAME: "TrustpilotWidgetBlock",
            VAL: Gql__TrustpilotWidgetBlock__Decoder.get(content.VAL.trustpilotWidgetBlock)
          };
  } else if (variant === "CustomerCareBannerContentType") {
    return {
            NAME: "CustomerCareBannerBlock",
            VAL: Gql__CustomerCareBanner__Decoder.get(content.VAL.customerCareBannerBlock)
          };
  } else if (variant === "EnrichedTextBlockContentType") {
    return {
            NAME: "EnrichedTextBlock",
            VAL: Gql__EnrichedTextBlock__Decoder.get(content.VAL.enrichedTextBlock)
          };
  } else if (variant === "HeroTileBlockContentType") {
    return {
            NAME: "HeroTileBlock",
            VAL: Gql__HeroTiles__Decoder__HeroTileBlock.get(content.VAL.heroTileBlock)
          };
  } else {
    return ;
  }
}

var Private = {
  contentBlockSwitch: contentBlockSwitch
};

function seoMetaBlock(content) {
  if (typeof content === "object" && content.NAME === "SeoMetaContentType") {
    return {
            NAME: "SeoMetaBlock",
            VAL: Gql__SeoMeta__Decoder.get(content.VAL.seoMetaBlock)
          };
  }
  
}

var SeoMetaMapper = {
  seoMetaBlock: seoMetaBlock
};

function storeContentBlock(content) {
  if (typeof content === "object" && content.NAME === "StoreContentContentType") {
    return {
            NAME: "StoreContentBlock",
            VAL: Gql__StoreContent__Decoder.get(content.VAL.storeContentBlock)
          };
  }
  
}

var StoreContentMapper = {
  storeContentBlock: storeContentBlock
};

function get(content) {
  var content_blocks = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.content_blocks, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), contentBlockSwitch);
  var seo_meta = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), seoMetaBlock), 0);
  var store_content = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.store_content, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), storeContentBlock), 0);
  return {
          content_blocks: content_blocks,
          seo_meta: seo_meta,
          store_content: store_content
        };
}

var CategoryTileBlock;

var AppDownloadBlock;

var FullWidthTextBlock;

var NewsletterBlock;

var HeroTileBlock;

var UniqueSellingPointsBlock;

var PromoTileBlock;

var StatsTilesBlock;

var VideoTextPromoBlock;

var SkuCarouselBlock;

var TeamMembersBlock;

var CustomerCareBannerBlock;

var CustomerCareCallUsBlock;

var BoardOfDirectorsBlock;

var FullWidthHeroBannerBlock;

var InnovativeWayToGiftBlock;

var IconCardBlock;

var GiftExperienceBlock;

var TestimonialCarouselBlock;

var BusinessContactFormBlock;

var AutomateSimplifyBlock;

var OffsetTilesBlock;

var FAQBlock;

var LandingPageContactFormBlock;

var StoreContentBlock;

var SeoMetaBlock;

var EnrichedTextBlock;

var TrustpilotWidgetBlock;

var NarrowBannerFull;

var ProductHeroBlock;

var SellingPointsBlock;

var NarrowCta;

var HeroBanner;

export {
  CategoryTileBlock ,
  AppDownloadBlock ,
  FullWidthTextBlock ,
  NewsletterBlock ,
  HeroTileBlock ,
  UniqueSellingPointsBlock ,
  PromoTileBlock ,
  StatsTilesBlock ,
  VideoTextPromoBlock ,
  SkuCarouselBlock ,
  TeamMembersBlock ,
  CustomerCareBannerBlock ,
  CustomerCareCallUsBlock ,
  BoardOfDirectorsBlock ,
  FullWidthHeroBannerBlock ,
  InnovativeWayToGiftBlock ,
  IconCardBlock ,
  GiftExperienceBlock ,
  TestimonialCarouselBlock ,
  BusinessContactFormBlock ,
  AutomateSimplifyBlock ,
  OffsetTilesBlock ,
  FAQBlock ,
  LandingPageContactFormBlock ,
  StoreContentBlock ,
  SeoMetaBlock ,
  EnrichedTextBlock ,
  TrustpilotWidgetBlock ,
  NarrowBannerFull ,
  ProductHeroBlock ,
  SellingPointsBlock ,
  NarrowCta ,
  HeroBanner ,
  Private ,
  SeoMetaMapper ,
  StoreContentMapper ,
  get ,
}
/* Gql__StoreContent__Decoder Not a pure module */
