// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var backgroundColour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image_desktop, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image_tablet, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.name;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.description;
            })), "");
  var url$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.url;
            })), "");
  var width$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.width;
            })), 0);
  var height$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$1, (function (f) {
              return f.height;
            })), 0);
  var result$1 = {
    name: name$1,
    description: description$1,
    url: url$1,
    width: width$1,
    height: height$1
  };
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image_mobile, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.name;
            })), "");
  var description$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.description;
            })), "");
  var url$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.url;
            })), "");
  var width$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.width;
            })), 0);
  var height$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(value$2, (function (f) {
              return f.height;
            })), 0);
  var result$2 = {
    name: name$2,
    description: description$2,
    url: url$2,
    width: width$2,
    height: height$2
  };
  var cta = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cta, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), 0), (function (f) {
          if (typeof f === "object" && f.NAME === "CallToActionLinkD6a3d61ContentType") {
            return Gql__CallToAction__Decoder.get(f.VAL.callToAction);
          }
          
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          backgroundColour: backgroundColour,
          bgImageDesktop: result,
          bgImageTablet: result$1,
          bgImageMobile: result$2,
          cta: cta,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
