// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment AlertBannerBlock on AlertBannerContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    desktop_text  {
      __typename
      value
    }
    mobile_text  {
      __typename
      value
    }
    background_color  {
      __typename
      value  {
        __typename
        codename
      }
    }
    custom_color  {
      __typename
      value
    }
    url  {
      __typename
      value
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.desktop_text;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.mobile_text;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.background_color;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.custom_color;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.url;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? value$11 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          desktop_text: tmp,
          mobile_text: tmp$1,
          background_color: tmp$2,
          custom_color: tmp$3,
          url: tmp$4
        };
}

function serialize(value) {
  var value$1 = value.url;
  var url;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    url = {
      __typename: value$4,
      value: value$3
    };
  } else {
    url = null;
  }
  var value$5 = value.custom_color;
  var custom_color;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    custom_color = {
      __typename: value$8,
      value: value$7
    };
  } else {
    custom_color = null;
  }
  var value$9 = value.background_color;
  var background_color;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$10) : null;
    var value$12 = value$9.__typename;
    background_color = {
      __typename: value$12,
      value: value$11
    };
  } else {
    background_color = null;
  }
  var value$13 = value.mobile_text;
  var mobile_text;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    mobile_text = {
      __typename: value$16,
      value: value$15
    };
  } else {
    mobile_text = null;
  }
  var value$17 = value.desktop_text;
  var desktop_text;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    desktop_text = {
      __typename: value$20,
      value: value$19
    };
  } else {
    desktop_text = null;
  }
  var value$21 = value.system;
  var value$22 = value$21.codename;
  var value$23 = value$21.id;
  var value$24 = value$21.__typename;
  var system = {
    __typename: value$24,
    id: value$23,
    codename: value$22
  };
  var value$25 = value.__typename;
  return {
          __typename: value$25,
          system: system,
          desktop_text: desktop_text,
          mobile_text: mobile_text,
          background_color: background_color,
          custom_color: custom_color,
          url: url
        };
}

function verifyArgsAndParse(_AlertBannerBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var AlertBannerBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  AlertBannerBlock ,
}
/* query Not a pure module */
