// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Js_null from "@rescript/std/lib/es6/js_null.js";
import * as ErrorData from "./ErrorData.bs.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function optionalNullable(decode, json) {
  try {
    return Js_null.fromOption(Caml_option.some(Curry._1(decode, json)));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return null;
    }
    throw exn;
  }
}

function denominations(json) {
  return {
          code: Json_decode.field("code", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          valueCurrency: Json_decode.field("value_currency", Json_decode.string, json),
          price: Json_decode.field("price", Json_decode.string, json),
          priceCurrency: Json_decode.field("price_currency", Json_decode.string, json)
        };
}

function theme(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.string, json),
          image: Json_decode.field("image", Json_decode.string, json)
        };
}

function template(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          imageFrame: Json_decode.field("image_frame", Json_decode.string, json),
          imagePreview: Json_decode.field("image_preview", Json_decode.string, json)
        };
}

function promo(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json),
          promoText: Json_decode.field("promo_text", Json_decode.string, json),
          terms: Json_decode.field("terms", Json_decode.string, json)
        };
}

function category(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          slug: Json_decode.field("slug", Json_decode.string, json),
          position: Json_decode.field("position", Json_decode.$$int, json),
          order: Json_decode.field("order", Json_decode.$$int, json),
          skuPosition: Json_decode.field("sku_position", Json_decode.$$int, json)
        };
}

function alert(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          messageMarkdown: Json_decode.field("message_markdown", Json_decode.string, json),
          level: Json_decode.field("level", Json_decode.string, json),
          priority: Json_decode.field("priority", Json_decode.$$int, json)
        };
}

function merchant(json) {
  return {
          terms: Json_decode.field("terms", Json_decode.string, json)
        };
}

function sku(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          searchableTerms: Json_decode.field("searchable_terms", Json_decode.string, json),
          similarTerms: Json_decode.field("similar_terms", Json_decode.string, json),
          displayName: Json_decode.field("display_name", Json_decode.string, json),
          productType: Json_decode.field("product_type", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.string, json),
          templates: Json_decode.field("templates", (function (param) {
                  return Json_decode.array(template, param);
                }), json),
          themes: Json_decode.field("themes", (function (param) {
                  return Json_decode.array(theme, param);
                }), json),
          promos: Json_decode.field("promos", (function (param) {
                  return Json_decode.array(promo, param);
                }), json),
          denominations: Json_decode.field("denominations", (function (param) {
                  return Json_decode.array(denominations, param);
                }), json),
          description: Json_decode.field("description", Json_decode.string, json),
          longDescription: optionalNullable((function (param) {
                  return Json_decode.field("long_description", Json_decode.string, param);
                }), json),
          tagline: optionalNullable((function (param) {
                  return Json_decode.field("tagline", Json_decode.string, param);
                }), json),
          descriptionMarkdown: Json_decode.field("description_markdown", Json_decode.string, json),
          isSwappable: Json_decode.field("is_swappable", Json_decode.bool, json),
          categories: Json_decode.field("categories", (function (param) {
                  return Json_decode.array(category, param);
                }), json),
          expiryTermDisplay: Json_decode.field("expiry_term_display", Json_decode.string, json),
          alerts: Json_decode.field("alerts", (function (param) {
                  return Json_decode.array(alert, param);
                }), json),
          slug: Json_decode.field("slug", Json_decode.string, json),
          isPbCategoryHidden: Json_decode.field("hide_from_all_business_categories", Json_decode.bool, json),
          isPcCategoryHidden: Json_decode.field("hide_from_all_classic_categories", Json_decode.bool, json),
          merchant: Json_decode.field("merchant", merchant, json),
          cmsId: Json_decode.field("cms_id", Json_decode.string, json),
          createdAt: Json_decode.field("created_at", Json_decode.string, json),
          layerEnabled: optionalNullable((function (param) {
                  return Json_decode.field("layer_enabled", Json_decode.bool, param);
                }), json),
          layerExpiryTerm: optionalNullable((function (param) {
                  return Json_decode.field("layer_expiry_term", Json_decode.string, param);
                }), json)
        };
}

function inventory(json) {
  return {
          skus: Json_decode.field("skus", (function (param) {
                  return Json_decode.array(sku, param);
                }), json)
        };
}

function safeDecodeInventory(json) {
  var value;
  try {
    value = inventory(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: value
        };
}

function safeDecodeInventoryForData(json) {
  var value;
  try {
    value = inventory(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ;
    }
    throw exn;
  }
  return value;
}

function safeDecodeSku(json) {
  var value;
  try {
    value = sku(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ;
    }
    throw exn;
  }
  return value;
}

var Decode = {
  denominations: denominations,
  theme: theme,
  template: template,
  promo: promo,
  category: category,
  alert: alert,
  merchant: merchant,
  sku: sku,
  inventory: inventory,
  safeDecodeInventory: safeDecodeInventory,
  safeDecodeInventoryForData: safeDecodeInventoryForData,
  safeDecodeSku: safeDecodeSku
};

export {
  optionalNullable ,
  Decode ,
}
/* No side effect */
