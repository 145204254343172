// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

var Region = {};

function getRegionLocation($$location) {
  if (typeof $$location !== "object") {
    return ;
  }
  if ($$location.NAME !== "RegionLocationContentType") {
    return ;
  }
  var match = $$location.VAL;
  var system = match.system;
  var currency = Belt_Option.getWithDefault(Belt_Option.flatMap(match.currency, (function (c) {
              return c.value;
            })), "");
  var country_name = Belt_Option.getWithDefault(Belt_Option.flatMap(match.country_name, (function (c) {
              return c.value;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(match.url, (function (url) {
              return url.value;
            })), "");
  var smartLink_itemId = system.id;
  var smartLink_codename = system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var country_flag = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(match.country_flag, (function (c) {
                                  return c.value;
                                })), []), GqlUtils.id), (function (f) {
                      return f.url;
                    })), GqlUtils.id), 0), "");
  var region = {
    name: country_name,
    currency: currency,
    flag: country_flag,
    smartLink: smartLink
  };
  return {
          regionListLine: region,
          url: url
        };
}

function getModalChangeLocation(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "ModalChangeLocationContentType") {
    return ;
  }
  var $$location = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap($$location.title, (function (title) {
              return title.value;
            })), "");
  var subtitle = Belt_Option.getWithDefault(Belt_Option.flatMap($$location.subtitle, (function (subtitle) {
              return subtitle.value;
            })), "");
  var ctaText = Belt_Option.getWithDefault(Belt_Option.flatMap($$location.cta_text, (function (cta_text) {
              return cta_text.value;
            })), "");
  var regions = Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap($$location.region_location, (function ($$location) {
                          return $$location.value;
                        })), []), GqlUtils.id), getRegionLocation), GqlUtils.id);
  var smartLink_itemId = $$location.system.id;
  var smartLink_codename = $$location.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          regions: regions,
          title: title,
          subtitle: subtitle,
          ctaText: ctaText,
          smartLink: smartLink
        };
}

export {
  Region ,
  getRegionLocation ,
  getModalChangeLocation ,
}
/* No side effect */
