import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';
import { AccountDetails } from 'models/customer.model';

/**
 * @secure
 * Request to get account details with provided auth headers.
 * @returns Decoded promise of account details response.
 */
const getAccountDetails = (headers?: Record<string, string>): Promise<AccountDetails> => {
  return createRequest('api/v3/account/details/', {
    credentials: 'same-origin',
    withAuth: true,
    otherHeaders: headers,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as AccountDetails);
};

export default getAccountDetails;
