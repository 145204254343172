// Generated by ReScript, PLEASE EDIT WITH CARE


var buildVersion = (process.env.NEXT_PUBLIC_BUILD_VERSION || 'unknown');

var regenerateAfter = 60;

var magicUserAgentHeader = "Prezzee Frontend 2.0 (FWKNqc0E)";

export {
  regenerateAfter ,
  magicUserAgentHeader ,
  buildVersion ,
}
/* buildVersion Not a pure module */
