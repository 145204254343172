import { camelKeys } from 'js-convert-case';
import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

/**
 * Request to fetch sku categories.
 * @returns Promise with decoded category response.
 */
const categories = () =>
  createRequest('api/v3/categories/', {
    withMagicUserAgent: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }));

export default categories;
