// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Regex from "../../../utils/Regex.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var title_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_text, (function (f) {
              return f.value;
            })), "");
  var title_alignment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "h2", (function (str) {
          return str;
        }));
  var title_heading = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_heading, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var widget_code = Belt_Option.getWithDefault(Belt_Option.flatMap(c.widget_code, (function (f) {
                return f.value;
              })), "").replace(Regex.htmlCommentRegex, "");
  var top_distance = Belt_Option.flatMap(c.top_distance, (function (c) {
          return c.value;
        }));
  var bottom_distance = Belt_Option.flatMap(c.bottom_distance, (function (c) {
          return c.value;
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          smartLink: smartLink,
          title_text: title_text,
          title_alignment: title_alignment,
          title_heading: title_heading,
          widget_code: widget_code,
          bottom_distance: bottom_distance,
          top_distance: top_distance
        };
}

export {
  get ,
}
/* No side effect */
