// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../bindings/Pino.bs.js";
import * as Util from "../../common/util.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function detail(json) {
  return {
          email: Util.JsonUtil.optionalField("email", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          enquiryType: Util.JsonUtil.optionalField("enquiry_type", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          message: Util.JsonUtil.optionalField("message", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          name: Util.JsonUtil.optionalField("full_name", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          phoneNumber: Util.JsonUtil.optionalField("phone_number", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

function error(json) {
  return {
          code: Json_decode.field("code", Json_decode.string, json),
          detail: Json_decode.field("detail", detail, json)
        };
}

function contactError(json) {
  return {
          error: Json_decode.field("error", error, json)
        };
}

function safeDecodeContactError(json) {
  var e;
  try {
    e = contactError(json);
  }
  catch (raw_e){
    var e$1 = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e$1.RE_EXN_ID === Json_decode.DecodeError) {
      Pino.logger.error({
            err: e$1._1,
            type: "Safe Decode Contact"
          }, "error: Could not decode error from the server");
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* JsonParsingError */1,
                _0: "Could not decode error from the server."
              }
            };
    }
    throw e$1;
  }
  Pino.logger.error({
        err: e,
        type: "Safe Decode Contact"
      }, "error: Could not decode response from the server");
  return {
          TAG: /* Error */1,
          _0: {
            TAG: /* ServerError */2,
            _0: {
              TAG: /* ServerContactValidationError */1,
              _0: e.error.detail
            }
          }
        };
}

var Decode = {
  detail: detail,
  error: error,
  contactError: contactError,
  safeDecodeContactError: safeDecodeContactError
};

export {
  Decode ,
}
/* Pino Not a pure module */
