// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__EnrichedTextBlock__Decoder from "../EnrichedTextBlock/Gql__EnrichedTextBlock__Decoder.bs.js";
import * as Gql__SmartCardHeroBlock__Decoder from "../SmartCardHeroBlock/Gql__SmartCardHeroBlock__Decoder.bs.js";

function get(content) {
  var smart_card_content = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.smart_card_content, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), (function (f) {
          if (typeof f !== "object") {
            return ;
          }
          var variant = f.NAME;
          if (variant === "SmartCardHeroBlockContentType") {
            return {
                    NAME: "SmartCardHeroBlock",
                    VAL: Gql__SmartCardHeroBlock__Decoder.get(f.VAL.smartCardHeroBlock)
                  };
          } else if (variant === "EnrichedTextBlockContentType") {
            return {
                    NAME: "EnrichedTextBlock",
                    VAL: Gql__EnrichedTextBlock__Decoder.get(f.VAL.enrichedTextBlock)
                  };
          } else {
            return ;
          }
        }));
  var retailer_card_content = Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.retailer_card_content, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (f) {
                  if (typeof f === "object" && f.NAME === "EnrichedTextBlockContentType") {
                    return Gql__EnrichedTextBlock__Decoder.get(f.VAL.enrichedTextBlock);
                  }
                  
                })), GqlUtils.id), 0);
  var end_of_page_content = Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.end_of_page_content, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (f) {
                  if (typeof f === "object" && f.NAME === "EnrichedTextBlockContentType") {
                    return Gql__EnrichedTextBlock__Decoder.get(f.VAL.enrichedTextBlock);
                  }
                  
                })), GqlUtils.id), 0);
  return {
          smart_card_content: smart_card_content,
          retailer_card_content: retailer_card_content,
          end_of_page_content: end_of_page_content
        };
}

export {
  get ,
}
/* Gql__EnrichedTextBlock__Decoder Not a pure module */
