import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';

/**
 * All possible errors returned from gift open response.
 */
export const giftOpenErrors = {
  claimedNotLoggedIn: 'Card is claimed, but you are not logged in',
  invalidPin: 'invalid_pin',
  expiredPin: 'expired_pin',
  oldStyleUrlExpired: 'gift_url_expired',
  oldStyleUrlUsed: 'gift_requires_new_style_url_with_pin',
};

/**
 * Request to fetch gift data on gift unwrapping page.
 * @param uid The gift unique identifier.
 * @param blob The gift encrypted blob.
 * @param pin The 4 digit pin code entered at unlock screen.
 * @returns Promise with decoded gift open response.
 */
const giftOpen = (uid: string, blob?: string, pin?: string): any => {
  let route = `api/v3/gift/open/${uid}/`;
  let options = {
    withAuth: true,
    withMagicUserAgent: true,
  };

  if (blob) {
    route += `?b=${blob}`;
  }

  if (pin) {
    options['method'] = 'POST';
    options['body'] = JSON.stringify({ pin });
  }

  return createRequest(route, options)
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }));
};

export const giftOpenCard = (uid: string, blob?: string, pin?: string): any => {
  let route = `api/v3/gift/${uid}/card/`;
  let options = {
    withAuth: true,
    withMagicUserAgent: true,
    method: 'POST',
  };

  if (blob) {
    route += `?b=${blob}`;
  }

  if (pin) {
    options['body'] = JSON.stringify({ pin });
  }

  return createRequest(route, options)
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }));
};

/**
 * The frontend side needs to handle 2 different special cases respectively for the 401 HTTP response in project butterfly
 * Currently, the error message content used to determine which handler will be used.
 * This checker is for identifying a 401 response case returned by a merchant requires forced sign in
 * TODO: it's a temporary solution, need to revisit to find a better approach by using the error codes or status codes
 *
 * @param error The error thrown by the `handleErrors()`
 * @returns true if HTTP response status code is 401 with credentials error message
 */
export const isAuthCredentialsError = (error: unknown): boolean => {
  if (error instanceof Error) {
    return error.message.includes('Authentication credentials were not provided');
  }

  return false;
};

export default giftOpen;
