// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LayoutDataContextTs from "../contexts/LayoutDataContext.ts";

var context = LayoutDataContextTs.LayoutDataContext;

function useLayoutData(prim) {
  return LayoutDataContextTs.useLayoutData();
}

var initContext;

var useContext = useLayoutData;

export {
  initContext ,
  context ,
  useLayoutData ,
  useContext ,
}
/* context Not a pure module */
