// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment AppStoreItem on AppStoreItemContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    app_store_name  {
      __typename
      value
    }
    app_store_download_link  {
      __typename
      value
    }
    app_store_asset  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.app_store_name;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.app_store_download_link;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.app_store_asset;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$7) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          app_store_name: tmp,
          app_store_download_link: tmp$1,
          app_store_asset: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.app_store_asset;
  var app_store_asset;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    app_store_asset = {
      __typename: value$4,
      value: value$3
    };
  } else {
    app_store_asset = null;
  }
  var value$5 = value.app_store_download_link;
  var app_store_download_link;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    app_store_download_link = {
      __typename: value$8,
      value: value$7
    };
  } else {
    app_store_download_link = null;
  }
  var value$9 = value.app_store_name;
  var app_store_name;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    app_store_name = {
      __typename: value$12,
      value: value$11
    };
  } else {
    app_store_name = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.codename;
  var value$15 = value$13.id;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    id: value$15,
    codename: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          app_store_name: app_store_name,
          app_store_download_link: app_store_download_link,
          app_store_asset: app_store_asset
        };
}

function verifyArgsAndParse(_AppStoreItem, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var AppStoreItem = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

export {
  $$Image ,
  AppStoreItem ,
}
/* query Not a pure module */
