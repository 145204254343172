// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "@rescript/std/lib/es6/array.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Config from "../config/Config.bs.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as AuthUtils from "../auth/user/AuthUtils.bs.js";
import * as JsCookie from "js-cookie";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Caml_array from "@rescript/std/lib/es6/caml_array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as HelpersTs from "../api/helpers.ts";

function s(prim) {
  return prim;
}

function ate(prim) {
  return prim;
}

function Util$ReactStuff$Spread(props) {
  return React.cloneElement(props.children, props.props);
}

var Spread = {
  make: Util$ReactStuff$Spread
};

var ReactStuff = {
  s: s,
  ate: ate,
  Spread: Spread
};

function getSessionIdToken(prim) {
  return HelpersTs.getSessionIdToken(prim);
}

function isJsonObject(json) {
  if (typeof json === "object" && !Array.isArray(json)) {
    return json !== null;
  } else {
    return false;
  }
}

var Private = {
  isJsonObject: isJsonObject
};

function toDict(json) {
  if (isJsonObject(json)) {
    return json;
  } else {
    return {};
  }
}

function optionalField(fieldName, decoder, json) {
  var field = Js_dict.get(toDict(json), fieldName);
  if (field !== undefined) {
    return Caml_option.some(Curry._1(decoder, Caml_option.valFromOption(field)));
  }
  
}

function optionalWithDefault(fieldName, decoder, json, $$default) {
  return Belt_Option.getWithDefault(optionalField(fieldName, decoder, json), $$default);
}

var JsonUtil = {
  Private: Private,
  toDict: toDict,
  optionalField: optionalField,
  optionalWithDefault: optionalWithDefault
};

var countryCode = process.env.NEXT_PUBLIC_APP_COUNTRY_CODE;

var currencyCode = process.env.NEXT_PUBLIC_APP_CURRENCY_CODE;

var countriesAvailable = [
  /* Australia */0,
  /* GreatBritain */1,
  /* UnitedStates */2,
  /* NewZealand */3
];

var gdprCountries = ["GB"];

function getCountry(param) {
  switch (countryCode) {
    case "AU" :
        return {
                TAG: /* Ok */0,
                _0: /* Australia */0
              };
    case "GB" :
        return {
                TAG: /* Ok */0,
                _0: /* GreatBritain */1
              };
    case "NZ" :
        return {
                TAG: /* Ok */0,
                _0: /* NewZealand */3
              };
    case "US" :
        return {
                TAG: /* Ok */0,
                _0: /* UnitedStates */2
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid country code."
            };
  }
}

function getCountryCode(country) {
  switch (country) {
    case /* Australia */0 :
        return "AU";
    case /* GreatBritain */1 :
        return "GB";
    case /* UnitedStates */2 :
        return "US";
    case /* NewZealand */3 :
        return "NZ";
    
  }
}

function checkGdprCountry(country) {
  return gdprCountries.includes(country);
}

function getDomainPostfix(country) {
  switch (country) {
    case /* Australia */0 :
        return ".com.au";
    case /* GreatBritain */1 :
        return ".uk";
    case /* UnitedStates */2 :
        return ".com";
    case /* NewZealand */3 :
        return ".co.nz";
    
  }
}

function getEnv(param) {
  var isStg = process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL.includes("stg");
  if (isStg) {
    return /* Staging */0;
  } else {
    return /* Production */1;
  }
}

function getCurrency(currencyCode) {
  switch (currencyCode) {
    case "AUD" :
        return {
                TAG: /* Ok */0,
                _0: /* AUD */0
              };
    case "GBP" :
        return {
                TAG: /* Ok */0,
                _0: /* GBP */1
              };
    case "NZD" :
        return {
                TAG: /* Ok */0,
                _0: /* NZD */3
              };
    case "USD" :
        return {
                TAG: /* Ok */0,
                _0: /* USD */2
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid currency code."
            };
  }
}

function getCurrencyCode(currency) {
  switch (currency) {
    case /* AUD */0 :
        return "AUD";
    case /* GBP */1 :
        return "GBP";
    case /* USD */2 :
        return "USD";
    case /* NZD */3 :
        return "NZD";
    
  }
}

function getTimezone(country) {
  switch (country) {
    case /* Australia */0 :
        return "Australia/Sydney";
    case /* GreatBritain */1 :
        return "Europe/London";
    case /* UnitedStates */2 :
        return "America/New_York";
    case /* NewZealand */3 :
        return "Pacific/Auckland";
    
  }
}

var currentCountry = getCountry(countryCode);

var currentCurrency = getCurrency(currencyCode);

function getCmsLanguageFromRegion(param) {
  switch (countryCode) {
    case "AU" :
        return "en-AU";
    case "GB" :
        return "en-UK";
    case "NZ" :
        return "en-NZ";
    case "US" :
        return "en-US";
    default:
      return "100-en-US";
  }
}

function getCmsLanguage(lang) {
  var opt = (lang == null) ? undefined : Caml_option.some(lang);
  return Belt_Option.getWithDefault(opt, getCmsLanguageFromRegion(undefined));
}

var EnvUtil = {
  getEnv: getEnv,
  currentCountry: currentCountry,
  currentCurrency: currentCurrency,
  checkGdprCountry: checkGdprCountry,
  getCountry: getCountry,
  getCurrency: getCurrency,
  getTimezone: getTimezone,
  getCountryCode: getCountryCode,
  getCurrencyCode: getCurrencyCode,
  countriesAvailable: countriesAvailable,
  countryCode: countryCode,
  getDomainPostfix: getDomainPostfix,
  currencyCode: currencyCode,
  getCmsLanguageFromRegion: getCmsLanguageFromRegion,
  getCmsLanguage: getCmsLanguage
};

function getHrefLang(x) {
  switch (x) {
    case /* Australia */0 :
        return "en-au";
    case /* GreatBritain */1 :
        return "en-gb";
    case /* UnitedStates */2 :
        return "en-us";
    case /* NewZealand */3 :
        return "en-nz";
    
  }
}

function getBaseHref(x) {
  switch (x) {
    case /* Australia */0 :
        return "https://www.prezzee.com.au";
    case /* GreatBritain */1 :
        return "https://www.prezzee.uk";
    case /* UnitedStates */2 :
        return "https://www.prezzee.com";
    case /* NewZealand */3 :
        return "https://www.prezzee.co.nz";
    
  }
}

function getLanguageAlternates(route) {
  return Belt_Array.map(countriesAvailable, (function (c) {
                return {
                        hrefLang: getHrefLang(c),
                        href: getBaseHref(c) + route
                      };
              }));
}

function getCanonicalUrl(route) {
  var path = route.slice(1);
  var pathWithoutQueryString = Caml_array.get(path.split("?"), 0);
  return process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL + pathWithoutQueryString;
}

var SEOUtil = {
  getHrefLang: getHrefLang,
  getBaseHref: getBaseHref,
  getLanguageAlternates: getLanguageAlternates,
  getCanonicalUrl: getCanonicalUrl
};

function build(param) {
  var fingerprint = JsCookie.get("fingerprint-pz");
  if (fingerprint == null) {
    return [];
  } else {
    return [[
              "x-device-fingerprint",
              fingerprint
            ]];
  }
}

function withContentTypeJson(headers) {
  return $$Array.append(headers, [
              [
                "content-type",
                "application/json"
              ],
              [
                "accept",
                "application/json"
              ]
            ]);
}

function acceptContentTypeJson(headers) {
  return $$Array.append(headers, [[
                "accept",
                "application/json"
              ]]);
}

function withAuthHeaderIfAvailable(headers) {
  var cookie = AuthUtils.getTokenFromCookie(undefined);
  if (cookie !== undefined) {
    return $$Array.append(headers, [[
                  "Authorization",
                  Belt_Option.getWithDefault(cookie.masquerade, false) ? "Masq " + cookie.token : "Bearer " + cookie.token
                ]]);
  } else {
    return headers;
  }
}

function withSecurityToken(headers) {
  var fingerprint = HelpersTs.getSessionIdToken({
        updateConfig: false,
        resetToken: false
      });
  return $$Array.append(headers, [[
                "X-SECURITY-SESSIONID",
                fingerprint
              ]]);
}

function withAuthHeaderFromServer(headers, token) {
  if (token !== undefined) {
    return $$Array.append(headers, [[
                  "Authorization",
                  "Bearer " + token
                ]]);
  } else {
    return headers;
  }
}

function withPreferRespondAsync(headers) {
  return $$Array.append(headers, [[
                "Prefer",
                "respond-async"
              ]]);
}

function withMagicUserAgentOnServerOnly(headers) {
  if (process.browser) {
    return headers;
  } else {
    return $$Array.append(headers, [[
                  "User-Agent",
                  Config.magicUserAgentHeader
                ]]);
  }
}

function makeWithArray(headers) {
  return headers;
}

var HeadersUtil = {
  build: build,
  withContentTypeJson: withContentTypeJson,
  acceptContentTypeJson: acceptContentTypeJson,
  withAuthHeaderIfAvailable: withAuthHeaderIfAvailable,
  withSecurityToken: withSecurityToken,
  withAuthHeaderFromServer: withAuthHeaderFromServer,
  withPreferRespondAsync: withPreferRespondAsync,
  withMagicUserAgentOnServerOnly: withMagicUserAgentOnServerOnly,
  makeWithArray: makeWithArray
};

var createFormData = (function (data) {
      var formData = new FormData();
      Array.from(data).forEach(function(element) {
      formData.append('files', element);
      });
      return formData;
    });

var convertToBlob = (function(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpg' });
  });

var FileUtil = {
  createFormData: createFormData,
  convertToBlob: convertToBlob
};

function getDenominationSymbol(currencyCode) {
  switch (currencyCode) {
    case "EUR" :
        return "€";
    case "GBP" :
        return String.fromCharCode(163);
    case "AUD" :
    case "CAD" :
    case "NZD" :
    case "USD" :
        return "$";
    default:
      return "";
  }
}

var CurrencyUtils = {
  getDenominationSymbol: getDenominationSymbol
};

function getDefaultBalance(balance) {
  if (balance !== undefined) {
    return balance;
  } else {
    return "0";
  }
}

var DefaultBalance = {
  getDefaultBalance: getDefaultBalance
};

function isEnterKey(e) {
  var key = e.key;
  var keyCode = e.keyCode;
  if (key === "Enter") {
    return true;
  } else {
    return keyCode === 13;
  }
}

var EventHelpers = {
  isEnterKey: isEnterKey
};

function getUser(param) {
  return Caml_option.nullable_to_opt(JsCookie.get("PB_STORAGE"));
}

var Business = {
  getUser: getUser,
  pathname: "/business"
};

var CookieUtils = {
  acceptCookies: "acceptCookiesPrezzee"
};

export {
  ReactStuff ,
  getSessionIdToken ,
  JsonUtil ,
  EnvUtil ,
  SEOUtil ,
  HeadersUtil ,
  FileUtil ,
  CurrencyUtils ,
  DefaultBalance ,
  EventHelpers ,
  Business ,
  CookieUtils ,
}
/* countryCode Not a pure module */
