import React from 'react';
import Box from '@prezzee/ribbon-ui/lib/components/Box';
import { useNavVisibilty } from '../../contexts/NavVisibilityContext';

const HeaderContainer = ({ children, isMenuOpen }) => {
  const isVisible = useNavVisibilty();
  return (
    <Box
      as="header"
      position="sticky"
      top="0px"
      left="0px"
      right="0px"
      backgroundColor="white"
      borderBottom="1px"
      borderBottomColor="gray.100"
      zIndex="50"
      transition={{ base: 'transform 400ms ease', xl: 'unset' }}
      transform={{
        base: isVisible || isMenuOpen.contents ? 'translateY(0%)' : 'translateY(-100%)',
        xl: 'unset',
      }}
    >
      {children}
    </Box>
  );
};

export default HeaderContainer;
