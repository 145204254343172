// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import Link from "next/link";
import * as UserContext from "../../auth/user/UserContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

function NavBarUserDropdown(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  var match$1 = UserContext.useUser(undefined);
  var user = match$1[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("button", {
                      children: [
                        user ? Util.ReactStuff.s("Hi " + user._0.firstName) : null,
                        JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                              icon: [
                                "fas",
                                "ellipsis-v"
                              ],
                              className: "ml-1"
                            })
                      ],
                      "aria-label": "Logout Button",
                      className: "relative z-10 block text-sm text-prezzee-red hover:text-prezzee-blue focus:outline-none mr-3 lg:mr-5 xl:mr-8",
                      onClick: (function (param) {
                          Curry._1(setIsOpen, (function (param) {
                                  return !isOpen;
                                }));
                        })
                    }),
                isOpen ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("button", {
                                className: "fixed inset-0 w-full h-full cursor-default",
                                tabIndex: -1,
                                onClick: (function (param) {
                                    Curry._1(setIsOpen, (function (param) {
                                            return false;
                                          }));
                                  })
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(Link, {
                                        href: "/my-details",
                                        prefetch: false,
                                        children: JsxRuntime.jsx("a", {
                                              children: Util.ReactStuff.s("My Account"),
                                              className: "block px-4 py-2 text-dark-gray hover:bg-prezzee-blue hover:text-white text-sm",
                                              title: "My Account"
                                            })
                                      }),
                                  JsxRuntime.jsx("hr", {
                                        className: "border-solid border-smoke-light border-b w-full"
                                      }),
                                  JsxRuntime.jsx(Link, {
                                        href: "/forgot-password",
                                        prefetch: false,
                                        children: JsxRuntime.jsx("a", {
                                              children: Util.ReactStuff.s("Change Password"),
                                              className: "block px-4 py-2 text-dark-gray hover:bg-prezzee-blue hover:text-white text-sm",
                                              title: "Change Password"
                                            })
                                      }),
                                  JsxRuntime.jsx("hr", {
                                        className: "border-solid border-smoke-light border-b w-full"
                                      }),
                                  JsxRuntime.jsx(Link, {
                                        href: "/logout",
                                        prefetch: false,
                                        children: JsxRuntime.jsx("a", {
                                              children: Util.ReactStuff.s("Sign Out"),
                                              className: "block px-4 py-2 text-dark-gray hover:bg-prezzee-blue hover:text-white text-sm",
                                              title: "Sign Out"
                                            })
                                      })
                                ],
                                className: "bg-white rounded-lg py-1 w-48 mt-2 shadow-xl absolute right-0 border"
                              })
                        ]
                      }) : null
              ],
              className: "relative"
            });
}

var Link$1;

var make = NavBarUserDropdown;

export {
  Link$1 as Link,
  make ,
}
/* Util Not a pure module */
