// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function parse_alert_banner_style(style) {
  switch (style) {
    case "active_blue" :
        return /* Blue */0;
    case "green" :
        return /* Green */1;
    case "orange" :
        return /* Orange */3;
    case "primary_red" :
        return /* Red */2;
    case "yellow" :
        return /* Yellow */4;
    default:
      return /* Green */1;
  }
}

function get(content) {
  var desktopText = Belt_Option.flatMap(content.desktop_text, (function (c) {
          return c.value;
        }));
  var mobileText = Belt_Option.flatMap(content.mobile_text, (function (c) {
          return c.value;
        }));
  var url = Belt_Option.flatMap(content.url, (function (c) {
          return c.value;
        }));
  var backgroundColor = parse_alert_banner_style(Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.background_color, (function (c) {
                                  return c.value;
                                })), []), GqlUtils.id), (function (v) {
                      return v.codename;
                    })), 0), "green"));
  var customColor = Belt_Option.getWithDefault(Belt_Option.flatMap(content.custom_color, (function (c) {
              return c.value;
            })), "");
  var bannerColor = customColor === "" ? backgroundColor : /* Custom */({
        _0: Curry._1(BsCss.style, {
              hd: BsCss.unsafe("backgroundColor", customColor),
              tl: /* [] */0
            })
      });
  var smartLink_itemId = content.system.id;
  var smartLink_codename = content.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          desktopText: desktopText,
          mobileText: mobileText,
          url: url,
          bannerColor: bannerColor,
          smartLink: smartLink
        };
}

export {
  parse_alert_banner_style ,
  get ,
}
/* BsCss Not a pure module */
