// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__LandingPageContactForm__Decoder__Control from "./Gql__LandingPageContactForm__Decoder__Control.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "FormControlWrapperContentType") {
    return ;
  }
  var c = content.VAL;
  var desktop_width = Belt_Option.getWithDefault(Belt_Option.flatMap(c.desktop_width, (function (c) {
              return c.value;
            })), "");
  var tablet_width = Belt_Option.getWithDefault(Belt_Option.flatMap(c.tablet_width, (function (c) {
              return c.value;
            })), "");
  var mobile_width = Belt_Option.getWithDefault(Belt_Option.flatMap(c.mobile_width, (function (c) {
              return c.value;
            })), "");
  var control = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.control, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__LandingPageContactForm__Decoder__Control.get), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          desktop_width: desktop_width,
          tablet_width: tablet_width,
          mobile_width: mobile_width,
          control: control,
          smartLink: smartLink
        };
}

var Control;

export {
  Control ,
  get ,
}
/* No side effect */
