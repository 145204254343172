// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  query PageContent($language: String!, $preview: Boolean, $codenames: [String])  {
    callToActions: itemsByType(type: "call_to_action_link_d6a3d61", depth: 5, language: $language, preview: $preview, filters: [{type: IN_, prop: "system.codename", list: $codenames}])  {
      __typename
      ...on CallToActionLinkD6a3d61ContentType   {
        ...CallToAction
      }
    }
    images: itemsByType(type: "image", depth: 5, language: $language, preview: $preview, filters: [{type: IN_, prop: "system.codename", list: $codenames}])  {
      __typename
      ...on ImageContentType   {
        ...Image
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__CallToAction__Fragment.CallToAction.query, Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.callToActions;
  var value$2 = value.images;
  return {
          callToActions: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                          NAME: "CallToActionLinkD6a3d61ContentType",
                          VAL: {
                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                          }
                        }) : ({
                          NAME: "UnspecifiedFragment",
                          VAL: typename
                        });
                    return tmp;
                  }), value$1) : undefined,
          images: !(value$2 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp = typename === "ImageContentType" ? ({
                          NAME: "ImageContentType",
                          VAL: {
                            image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                          }
                        }) : ({
                          NAME: "UnspecifiedFragment",
                          VAL: typename
                        });
                    return tmp;
                  }), value$2) : undefined
        };
}

function serialize(value) {
  var value$1 = value.images;
  var images = value$1 !== undefined ? Js_array.map((function (value) {
            if (value !== undefined) {
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              } else {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
              }
            } else {
              return null;
            }
          }), value$1) : null;
  var value$2 = value.callToActions;
  var callToActions = value$2 !== undefined ? Js_array.map((function (value) {
            if (value !== undefined) {
              if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
              } else {
                return {};
              }
            } else {
              return null;
            }
          }), value$2) : null;
  return {
          callToActions: callToActions,
          images: images
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  var a$1 = inp.codenames;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined,
          codenames: a$1 !== undefined ? Js_array.map((function (b) {
                    if (b !== undefined) {
                      return b;
                    }
                    
                  }), a$1) : undefined
        };
}

function makeVariables(language, preview, codenames, param) {
  return {
          language: language,
          preview: preview,
          codenames: codenames
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var $$Image;

var CallToAction;

export {
  $$Image ,
  CallToAction ,
  Query ,
}
/* query Not a pure module */
