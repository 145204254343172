const { library } = require('@fortawesome/fontawesome-svg-core');

import { faBlogger } from '@fortawesome/free-brands-svg-icons/faBlogger';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faMars } from '@fortawesome/free-solid-svg-icons/faMars';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMouse } from '@fortawesome/free-solid-svg-icons/faMouse';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons/faPlaneDeparture';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons/faShoppingBasket';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faCross } from '@fortawesome/free-solid-svg-icons/faCross';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faVenus } from '@fortawesome/free-solid-svg-icons/faVenus';
import { faCheck as faCheckSolid } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCalendarStar as faCalendarStarSolid } from '@fortawesome/pro-solid-svg-icons/faCalendarStar';

import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';

import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faBoxHeart } from '@fortawesome/pro-regular-svg-icons/faBoxHeart';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons/faCalendarStar';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons/faCommentLines';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen';
import { faGift as farGift } from '@fortawesome/pro-regular-svg-icons/faGift';
import { faGiftCard } from '@fortawesome/pro-regular-svg-icons/faGiftCard';
import { faGifts } from '@fortawesome/pro-regular-svg-icons/faGifts';
import { faHandSparkles } from '@fortawesome/pro-regular-svg-icons/faHandSparkles';
import { faLeafHeart } from '@fortawesome/pro-regular-svg-icons/faLeafHeart';
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faPhoneLaptop } from '@fortawesome/pro-regular-svg-icons/faPhoneLaptop';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faWallet } from '@fortawesome/pro-regular-svg-icons/faWallet';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faCheckCircle as faCheckCircleReg } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faSearch, faSlidersH } from '@fortawesome/pro-regular-svg-icons';

library.add(
  faCross,
  faBlogger,
  faInstagram,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faCreditCard,
  faUser,
  faStar,
  faUtensils,
  faPlaneDeparture,
  faMouse,
  faMars,
  faMinus,
  faVenus,
  faArrowRight,
  faBars,
  faTimes,
  faPlus,
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowDown,
  faCheckCircle,
  faCaretDown,
  faGift,
  faPrint,
  faTrashAlt,
  faCopy,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faThumbsUp,
  faEllipsisV,
  faCircle,
  faShoppingBasket,
  faPlusCircle,
  faWallet,
  faPhone,
  faSignOut,
  faChevronRight,
  faChevronLeft,
  faGifts,
  faPaperPlane,
  faLeafHeart,
  faEnvelopeOpen,
  faCalendarStar,
  faHandSparkles,
  faCalendarAlt,
  faMobile,
  faEnvelope,
  faBoxHeart,
  faGiftCard,
  faExclamationCircle,
  faTimesCircle,
  farGift,
  farTimes,
  faSpinner,
  faHandSparkles,
  faPhoneLaptop,
  faCommentLines,
  faThLarge,
  faYoutube,
  faTiktok,
  faCalendarStarSolid,
  faCheck,
  faPlayCircle,
  faUndo,
  faCheckCircleReg,
  faCheckSolid,
  faChevronDown,
  farAngleLeft,
  farArrowRight,
  faSearch,
  faSlidersH,
  faLink
);
