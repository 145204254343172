import { gql } from '@apollo/client';
import IMAGE from '../Image/fragment';
import CALL_TO_ACTION_LINK from '../CallToActionLink/fragment';

const ENRICHED_TEXT_BLOCK = gql`
  ${IMAGE}
  ${CALL_TO_ACTION_LINK}

  fragment EnrichedTextBlockFields on EnrichedTextBlock {
    _system_ {
      id
      codename
    }
    alignment {
      items {
        _system_ {
          codename
        }
      }
    }
    width {
      items {
        _system_ {
          codename
        }
      }
    }
    content {
      html
      linkedItems {
        items {
          ... on CallToActionLinkD6a3d61 {
            ...CallToActionFields
          }
          ... on Image {
            ...ImageFields
          }
        }
      }
    }
  }
`;

export default ENRICHED_TEXT_BLOCK;
