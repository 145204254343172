// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Cookie from "cookie";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as JsCookie from "js-cookie";
import * as UserCookie from "./UserCookie.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Aes from "crypto-js/aes";
import * as EncUtf8 from "crypto-js/enc-utf8";

var appInfo = process.env.NEXT_PUBLIC_KEY;

var cookieName = "storage";

function encrypt(data) {
  return Aes.encrypt(data, appInfo).toString();
}

function saveTokenCookie(uc) {
  JsCookie.set(cookieName, encrypt(Json.stringify(UserCookie.Encode.userCookie(uc))), {
        expires: 0.5
      });
}

function decrypt(userCookie) {
  try {
    return Belt_Option.map(Json.parse(Aes.decrypt(userCookie, appInfo).toString(EncUtf8)), UserCookie.Decode.userCookie);
  }
  catch (exn){
    return ;
  }
}

function getTokenFromCookie(param) {
  return Belt_Option.flatMap(Caml_option.nullable_to_opt(JsCookie.get(cookieName)), decrypt);
}

function getUserInfo(param) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(JsCookie.get(cookieName)), decrypt), (function (userCookie) {
                var infoOpt = userCookie.userDetails;
                return Belt_Option.map(infoOpt === null ? undefined : Caml_option.some(infoOpt), (function (info) {
                              return {
                                      firstName: info.firstName,
                                      lastName: info.lastName,
                                      email: info.email,
                                      phoneNumber: info.phoneNumber
                                    };
                            }));
              }));
}

function getFullUserInfo(param) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(JsCookie.get(cookieName)), decrypt), (function (userCookie) {
                return Caml_option.null_to_opt(userCookie.userDetails);
              }));
}

function getToken(param) {
  return Belt_Option.mapWithDefault(getTokenFromCookie(undefined), "", (function (x) {
                return x.token;
              }));
}

function getTokenOpt(param) {
  return Belt_Option.mapWithDefault(getTokenFromCookie(undefined), "", (function (x) {
                return x.token;
              }));
}

function getTokenOptServer(cookiesStr) {
  var result;
  try {
    result = Js_dict.get(Cookie.parse(cookiesStr), cookieName);
  }
  catch (exn){
    return ;
  }
  return Belt_Option.map(Belt_Option.flatMap(result, decrypt), (function (x) {
                return x.token;
              }));
}

export {
  appInfo ,
  cookieName ,
  encrypt ,
  saveTokenCookie ,
  decrypt ,
  getTokenFromCookie ,
  getUserInfo ,
  getFullUserInfo ,
  getToken ,
  getTokenOpt ,
  getTokenOptServer ,
}
/* appInfo Not a pure module */
