// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../bindings/Pino.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function banner(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json),
          text: Json_decode.field("text", Json_decode.string, json),
          textColour: Json_decode.field("text_colour", Json_decode.string, json),
          backgroundColour: Json_decode.field("background_colour", Json_decode.string, json),
          buttonText: Json_decode.field("button_text", Json_decode.string, json),
          prefixText: Json_decode.field("prefix_text", Json_decode.string, json),
          fontawesomeIconClass: Json_decode.field("fontawesome_icon_class", Json_decode.string, json)
        };
}

function slide(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          buttonBackgroundColour: Json_decode.field("button_background_colour", Json_decode.string, json),
          buttonBorderColour: Json_decode.field("button_border_colour", Json_decode.string, json),
          buttonTextColour: Json_decode.field("button_text_colour", Json_decode.string, json),
          buttonBackgroundColourHover: Json_decode.field("button_background_colour_hover", Json_decode.string, json),
          buttonBorderColourHover: Json_decode.field("button_border_colour_hover", Json_decode.string, json),
          buttonTextColourHover: Json_decode.field("button_text_colour_hover", Json_decode.string, json),
          backgroundImage: Json_decode.field("background_image", Json_decode.string, json),
          leftImage: Json_decode.field("left_image", Json_decode.string, json),
          centeredImage: Json_decode.field("centered_image", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          titleColour: Json_decode.field("title_colour", Json_decode.string, json),
          titleShadowColour: Json_decode.field("title_shadow_colour", Json_decode.string, json),
          enableTitleShadow: Json_decode.field("enable_title_shadow", Json_decode.bool, json),
          title: Json_decode.field("title", Json_decode.string, json),
          subtitle: Json_decode.field("subtitle", Json_decode.string, json),
          subtitleColour: Json_decode.field("subtitle_colour", Json_decode.string, json),
          buttonVimeoVideoId: Json_decode.field("button_vimeo_video_id", Json_decode.string, json),
          buttonShowVideoPopup: Json_decode.field("button_show_video_popup", Json_decode.bool, json),
          buttonUrl: Json_decode.field("button_url", Json_decode.string, json),
          buttonLabel: Json_decode.field("button_label", Json_decode.string, json)
        };
}

function homepage(json) {
  return {
          banner: Json_decode.field("banner", (function (param) {
                  return Json_decode.nullable(banner, param);
                }), json),
          slides: Json_decode.field("slides", (function (param) {
                  return Json_decode.array(slide, param);
                }), json)
        };
}

function safeDecodeHomepageData(json) {
  var homepage$1;
  try {
    homepage$1 = homepage(json);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      Pino.logger.error({
            err: e._1,
            type: "Safe Decode Homepage Data"
          }, "error: Could not decode response from the server");
      return ;
    }
    throw e;
  }
  return homepage$1;
}

var Decode = {
  banner: banner,
  slide: slide,
  homepage: homepage,
  safeDecodeHomepageData: safeDecodeHomepageData
};

export {
  Decode ,
}
/* Pino Not a pure module */
