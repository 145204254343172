// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(c.name, (function (f) {
              return f.value;
            })), "");
  var type_ = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.type, (function (f) {
                              return f.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.codename;
            })), "text", (function (str) {
          if (str === "phone") {
            return "tel";
          } else {
            return str;
          }
        }));
  var label = Belt_Option.getWithDefault(Belt_Option.flatMap(c.label, (function (f) {
              return f.value;
            })), "");
  var placeholder = Belt_Option.getWithDefault(Belt_Option.flatMap(c.placeholder, (function (f) {
              return f.value;
            })), "");
  var validation_message = Belt_Option.getWithDefault(Belt_Option.flatMap(c.validation_message, (function (f) {
              return f.value;
            })), "");
  var required = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.required, (function (f) {
                              return f.value;
                            })), []), GqlUtils.id), 0), (function (f) {
              return f.codename;
            })), true, (function (str) {
          switch (str) {
            case "false" :
                return false;
            case "true" :
                return true;
            default:
              return true;
          }
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          name: name,
          type_: type_,
          label: label,
          placeholder: placeholder,
          validation_message: validation_message,
          required: required,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
