// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";
import * as Gql__FontAwesomeIcon__Decoder from "../FontAwesomeIcon/Gql__FontAwesomeIcon__Decoder.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  var variant = content.NAME;
  if (variant === "ImageContentType") {
    return {
            TAG: /* Image */1,
            _0: Gql__Image__Decoder.get(content.VAL.image)
          };
  } else if (variant === "FontAwesomeContentType") {
    return {
            TAG: /* FontAwesomeIcon */0,
            _0: Gql__FontAwesomeIcon__Decoder.get(content.VAL.fontAwesomeIcon)
          };
  } else {
    return ;
  }
}

var FontAwesomeIconDecoder;

var ImageDecoder;

export {
  FontAwesomeIconDecoder ,
  ImageDecoder ,
  get ,
}
/* No side effect */
