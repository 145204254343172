// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";

function get(content) {
  if (typeof content === "object" && content.NAME === "CallToActionLinkD6a3d61ContentType") {
    return Gql__CallToAction__Decoder.get(content.VAL.callToAction);
  }
  
}

export {
  get ,
}
/* No side effect */
