import { camelKeys, snakeKeys } from 'js-convert-case';
import { createRequest } from 'api/helpers';
import handleErrors from 'api/handleErrors';
import { FinalisePaymentPayload } from 'models/payment.model';
import { Order } from 'models/purchaseForm.model';
import { Gift } from 'models/gift.model';

export type FinaliseOrderResponse = Order | Gift;

/**
 * Request to finalise order.
 * @param orderUrl - The URL of the order to finalise
 * @param finalisePaymentRequestData - The data to finalise the payment with
 * @returns The response from the finalse order API
 */
const finaliseOrder = (
  orderUrl: string,
  finalisePaymentRequestData: FinalisePaymentPayload
): Promise<FinaliseOrderResponse> =>
  createRequest(orderUrl, {
    body: JSON.stringify(snakeKeys(finalisePaymentRequestData)),
    isUrl: true,
    logRequest: true,
    method: 'PATCH',
    mode: 'cors',
    withAuth: true,
    withSecurityToken: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as Order);

export default finaliseOrder;
