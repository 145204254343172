// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Footer__Decoder__Links from "./Gql__Footer__Decoder__Links.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "LinkColumnContentType") {
    return ;
  }
  var column = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(column.title, (function (c) {
              return c.value;
            })), "");
  var links = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(column.links, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__Footer__Decoder__Links.get);
  var smartLink_itemId = column.system.id;
  var smartLink_codename = column.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          links: links,
          smartLink: smartLink
        };
}

var Links;

var LinkItem;

var MailtoLink;

export {
  Links ,
  LinkItem ,
  MailtoLink ,
  get ,
}
/* No side effect */
