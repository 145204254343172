// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__LandingPageContactForm__Decoder__ControlWrapper from "./Gql__LandingPageContactForm__Decoder__ControlWrapper.bs.js";

function get(c) {
  var action = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.action, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), undefined, (function (a) {
          switch (a) {
            case "gleam" :
                return /* Gleam */1;
            case "hubspot" :
                return /* Hubspot */0;
            default:
              return ;
          }
        }));
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var form_name = Belt_Option.getWithDefault(Belt_Option.flatMap(c.form_name, (function (c) {
              return c.value;
            })), "");
  var body_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.body_text, (function (c) {
              return c.value;
            })), "");
  var fields = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.fields, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__LandingPageContactForm__Decoder__ControlWrapper.get);
  var submit_cta_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.submit_cta_text, (function (c) {
              return c.value;
            })), "");
  var success_message_heading = Belt_Option.getWithDefault(Belt_Option.flatMap(c.success_message_heading, (function (c) {
              return c.value;
            })), "");
  var success_message_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.success_message_text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          action: action,
          form_name: form_name,
          title: title,
          body_text: body_text,
          fields: fields,
          submit_cta_text: submit_cta_text,
          success_message_heading: success_message_heading,
          success_message_text: success_message_text,
          smartLink: smartLink
        };
}

var ControlWrapper;

export {
  ControlWrapper ,
  get ,
}
/* No side effect */
