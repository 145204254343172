// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

var btnStyle = "bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white rounded py-2 px-4 border-black focus:outline-none";

function Button(props) {
  var className = props.className;
  var disabled = props.disabled;
  var title = props.title;
  var onClick = props.onClick;
  var onClick$1 = onClick !== undefined ? onClick : (function (param) {
        
      });
  var disabled$1 = disabled !== undefined ? disabled : false;
  var className$1 = className !== undefined ? className : btnStyle;
  return JsxRuntime.jsx("button", {
              children: title,
              className: className$1,
              title: title,
              disabled: disabled$1,
              onClick: onClick$1
            });
}

var primaryStyle = "text-white rounded-lg bg-prezzee-red focus:outline-none";

var secondaryStyle = "text-white rounded-lg bg-prezzee-blue focus:outline-none";

var linkBlueStyle = "text-white rounded-lg tracking-wider bg-link-blue focus:outline-none";

var primaryOutlineStyle = "bg-transparent hover:text-white hover:bg-prezzee-red rounded-md border text-prezzee-red border-prezzee-red focus:outline-none";

var secondaryOutlineStyle = "bg-transparent hover:text-white hover:bg-prezzee-blue rounded-md border text-prezzee-blue border-prezzee-blue focus:outline-none";

var linkBlueOutlineStyle = "bg-transparent hover:text-white hover:bg-prezzee-blue rounded-md border text-link-blue border-link-blue focus:outline-none";

var grayOutlineStyle = "rounded-md border focus:outline-none";

var disabled = "text-white rounded-lg bg-prezzee-red cursor-not-allowed opacity-25";

var redCircleStyle = "rounded-full bg-prezzee-red focus:outline-none";

var blueCircleStyle = "rounded-full bg-prezzee-blue focus:outline-none";

var grayCircleStyle = "rounded-full bg-gray-300 text-white focus:outline-none";

var whiteCircleStyle = "rounded-full bg-white text-prezzee-red focus:outline-none";

var linkBlueCircleStyle = "rounded-full bg-link-blue text-white focus:outline-none";

var make = Button;

export {
  btnStyle ,
  primaryStyle ,
  secondaryStyle ,
  linkBlueStyle ,
  primaryOutlineStyle ,
  secondaryOutlineStyle ,
  linkBlueOutlineStyle ,
  grayOutlineStyle ,
  disabled ,
  redCircleStyle ,
  blueCircleStyle ,
  grayCircleStyle ,
  whiteCircleStyle ,
  linkBlueCircleStyle ,
  make ,
}
/* react/jsx-runtime Not a pure module */
