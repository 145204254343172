// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "@rescript/std/lib/es6/belt_List.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function userDetails(json) {
  return {
          uid: Json_decode.field("uid", (function (param) {
                  return Json_decode.nullable(Json_decode.string, param);
                }), json),
          firstName: Json_decode.field("first_name", Json_decode.string, json),
          lastName: Json_decode.field("last_name", Json_decode.string, json),
          email: Json_decode.field("email", Json_decode.string, json),
          phoneNumber: Json_decode.field("phone_number", Json_decode.string, json)
        };
}

function userCookie(json) {
  return {
          userDetails: Json_decode.field("user_details", (function (param) {
                  return Json_decode.nullable(userDetails, param);
                }), json),
          token: Json_decode.field("token", Json_decode.string, json),
          masquerade: Json_decode.optional((function (param) {
                  return Json_decode.field("masquerade", Json_decode.bool, param);
                }), json)
        };
}

var Decode = {
  userDetails: userDetails,
  userCookie: userCookie
};

function userDetails$1(u) {
  return Json_encode.object_({
              hd: [
                "uid",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), Caml_option.null_to_opt(u.uid))
              ],
              tl: {
                hd: [
                  "first_name",
                  u.firstName
                ],
                tl: {
                  hd: [
                    "last_name",
                    u.lastName
                  ],
                  tl: {
                    hd: [
                      "email",
                      u.email
                    ],
                    tl: {
                      hd: [
                        "phone_number",
                        u.phoneNumber
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function userCookie$1(c) {
  var masquerade = Belt_Option.mapWithDefault(c.masquerade, /* [] */0, (function (masq) {
          return {
                  hd: [
                    "masquerade",
                    masq
                  ],
                  tl: /* [] */0
                };
        }));
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "user_details",
                    Json_encode.nullable(userDetails$1, Caml_option.null_to_opt(c.userDetails))
                  ],
                  tl: {
                    hd: [
                      "token",
                      c.token
                    ],
                    tl: /* [] */0
                  }
                }, masquerade));
}

var Encode = {
  userDetails: userDetails$1,
  userCookie: userCookie$1
};

export {
  Decode ,
  Encode ,
}
/* No side effect */
