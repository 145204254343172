// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__NewsletterBlock__Decoder__Button from "./Gql__NewsletterBlock__Decoder__Button.bs.js";
import * as Gql__NewsletterBlock__Decoder__BackgroundImage from "./Gql__NewsletterBlock__Decoder__BackgroundImage.bs.js";
import * as Gql__NewsletterBlock__Decoder__BackgroundImageMobile from "./Gql__NewsletterBlock__Decoder__BackgroundImageMobile.bs.js";
import * as Gql__NewsletterBlock__Decoder__BackgroundImageTablet from "./Gql__NewsletterBlock__Decoder__BackgroundImageTablet.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var text_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), /* Dark */0, (function (str) {
          switch (str) {
            case "dark" :
                return /* Dark */0;
            case "light" :
                return /* Light */1;
            default:
              return /* Dark */0;
          }
        }));
  var input_box_placeholder_text = Belt_Option.getWithDefault(Belt_Option.flatMap(c.input_box_placeholder_text, (function (c) {
              return c.value;
            })), "Email address");
  var success_message = Belt_Option.getWithDefault(Belt_Option.flatMap(c.success_message, (function (c) {
              return c.value;
            })), "");
  var background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__NewsletterBlock__Decoder__BackgroundImage.get), 0);
  var background_image_tablet = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image_tablet, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__NewsletterBlock__Decoder__BackgroundImageTablet.get), 0);
  var background_image_mobile = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image_mobile, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__NewsletterBlock__Decoder__BackgroundImageMobile.get), 0);
  var background_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  var button = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.button, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), Gql__NewsletterBlock__Decoder__Button.get), Gql__NewsletterBlock__Decoder__Button.emptyButton);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          description: description,
          text_colour: text_colour,
          input_box_placeholder_text: input_box_placeholder_text,
          success_message: success_message,
          background_image: background_image,
          background_image_tablet: background_image_tablet,
          background_image_mobile: background_image_mobile,
          background_colour: background_colour,
          button: button,
          smartLink: smartLink
        };
}

var $$Image;

var BackgroundImage;

var BackgroundImageTablet;

var BackgroundImageMobile;

var Button;

export {
  $$Image ,
  BackgroundImage ,
  BackgroundImageTablet ,
  BackgroundImageMobile ,
  Button ,
  get ,
}
/* No side effect */
