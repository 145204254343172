// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__FontAwesomeIcon__Fragment from "../FontAwesomeIcon/Gql__FontAwesomeIcon__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment FooterBlock on FooterContainerContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    link_columns  {
      __typename
      value  {
        __typename
        ...on LinkColumnContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          title  {
            __typename
            value
          }
          links  {
            __typename
            value  {
              __typename
              ...on LinkItemContentType   {
                __typename
                system  {
                  __typename
                  id
                  codename
                }
                link_text  {
                  __typename
                  value
                }
                url  {
                  __typename
                  value
                }
                open_in_new_window  {
                  __typename
                  value  {
                    __typename
                    codename
                  }
                }
                links  {
                  __typename
                  value  {
                    __typename
                    ...on FontAwesomeContentType   {
                      ...FontAwesomeIcon
                    }
                    ...on ImageContentType   {
                      ...Image
                    }
                  }
                }
              }
              ...on MailtoLinkContentType   {
                __typename
                system  {
                  __typename
                  id
                  codename
                }
                link_title  {
                  __typename
                  value
                }
                email_address  {
                  __typename
                  value
                }
              }
              ...on OnetrustCookieSettingButtonContentType   {
                __typename
                system  {
                  __typename
                  id
                  codename
                }
                text  {
                  __typename
                  value
                }
              }
            }
          }
        }
      }
    }
    subscribe_title  {
      __typename
      value
    }
    subscribe_description  {
      __typename
      value
    }
    subscribe_helper_text  {
      __typename
      value
    }
    subscribe_cta  {
      __typename
      value
    }
    cta_style__style  {
      __typename
      value  {
        __typename
        codename
      }
    }
    region_selector_text  {
      __typename
      value
    }
    app_store_title  {
      __typename
      value
    }
    app_stores_container  {
      __typename
      value  {
        __typename
        ...on AppStoreItemContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          app_store_name  {
            __typename
            value
          }
          app_store_download_link  {
            __typename
            value
          }
          app_store_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
        }
      }
    }
    footer_disclaimer_level_1  {
      __typename
      value
    }
    footer_disclaimer_level_2  {
      __typename
      value
    }
    background_colour  {
      __typename
      value
    }
    heading_colour  {
      __typename
      value
    }
    body_colour  {
      __typename
      value
    }
    body_hover_colour  {
      __typename
      value
    }
    icon_background_colour  {
      __typename
      value
    }
    icon_hover_background_colour  {
      __typename
      value
    }
    icon_colour  {
      __typename
      value
    }
    icon_hover_colour  {
      __typename
      value
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__FontAwesomeIcon__Fragment.FontAwesomeIcon.query, Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.link_columns;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "LinkColumnContentType") {
                  var value$1 = value.system;
                  var value$2 = value.title;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.links;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp;
                                switch (typename) {
                                  case "LinkItemContentType" :
                                      var value$1 = value.system;
                                      var value$2 = value.link_text;
                                      var tmp$1;
                                      if (value$2 == null) {
                                        tmp$1 = undefined;
                                      } else {
                                        var value$3 = value$2.value;
                                        tmp$1 = {
                                          __typename: value$2.__typename,
                                          value: !(value$3 == null) ? value$3 : undefined
                                        };
                                      }
                                      var value$4 = value.url;
                                      var tmp$2;
                                      if (value$4 == null) {
                                        tmp$2 = undefined;
                                      } else {
                                        var value$5 = value$4.value;
                                        tmp$2 = {
                                          __typename: value$4.__typename,
                                          value: !(value$5 == null) ? value$5 : undefined
                                        };
                                      }
                                      var value$6 = value.open_in_new_window;
                                      var tmp$3;
                                      if (value$6 == null) {
                                        tmp$3 = undefined;
                                      } else {
                                        var value$7 = value$6.value;
                                        tmp$3 = {
                                          __typename: value$6.__typename,
                                          value: !(value$7 == null) ? Js_array.map((function (value) {
                                                    if (value == null) {
                                                      return ;
                                                    }
                                                    var value$1 = value.codename;
                                                    return {
                                                            __typename: value.__typename,
                                                            codename: !(value$1 == null) ? value$1 : undefined
                                                          };
                                                  }), value$7) : undefined
                                        };
                                      }
                                      var value$8 = value.links;
                                      var tmp$4;
                                      if (value$8 == null) {
                                        tmp$4 = undefined;
                                      } else {
                                        var value$9 = value$8.value;
                                        tmp$4 = {
                                          __typename: value$8.__typename,
                                          value: !(value$9 == null) ? Js_array.map((function (value) {
                                                    if (value == null) {
                                                      return ;
                                                    }
                                                    var typename = value["__typename"];
                                                    var tmp;
                                                    switch (typename) {
                                                      case "FontAwesomeContentType" :
                                                          tmp = {
                                                            NAME: "FontAwesomeContentType",
                                                            VAL: {
                                                              fontAwesomeIcon: Gql__FontAwesomeIcon__Fragment.FontAwesomeIcon.verifyArgsAndParse("FontAwesomeIcon", value)
                                                            }
                                                          };
                                                          break;
                                                      case "ImageContentType" :
                                                          tmp = {
                                                            NAME: "ImageContentType",
                                                            VAL: {
                                                              image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                                            }
                                                          };
                                                          break;
                                                      default:
                                                        tmp = {
                                                          NAME: "UnspecifiedFragment",
                                                          VAL: typename
                                                        };
                                                    }
                                                    return tmp;
                                                  }), value$9) : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "LinkItemContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$1.__typename,
                                            id: value$1.id,
                                            codename: value$1.codename
                                          },
                                          link_text: tmp$1,
                                          url: tmp$2,
                                          open_in_new_window: tmp$3,
                                          links: tmp$4
                                        }
                                      };
                                      break;
                                  case "MailtoLinkContentType" :
                                      var value$10 = value.system;
                                      var value$11 = value.link_title;
                                      var tmp$5;
                                      if (value$11 == null) {
                                        tmp$5 = undefined;
                                      } else {
                                        var value$12 = value$11.value;
                                        tmp$5 = {
                                          __typename: value$11.__typename,
                                          value: !(value$12 == null) ? value$12 : undefined
                                        };
                                      }
                                      var value$13 = value.email_address;
                                      var tmp$6;
                                      if (value$13 == null) {
                                        tmp$6 = undefined;
                                      } else {
                                        var value$14 = value$13.value;
                                        tmp$6 = {
                                          __typename: value$13.__typename,
                                          value: !(value$14 == null) ? value$14 : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "MailtoLinkContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$10.__typename,
                                            id: value$10.id,
                                            codename: value$10.codename
                                          },
                                          link_title: tmp$5,
                                          email_address: tmp$6
                                        }
                                      };
                                      break;
                                  case "OnetrustCookieSettingButtonContentType" :
                                      var value$15 = value.system;
                                      var value$16 = value.text;
                                      var tmp$7;
                                      if (value$16 == null) {
                                        tmp$7 = undefined;
                                      } else {
                                        var value$17 = value$16.value;
                                        tmp$7 = {
                                          __typename: value$16.__typename,
                                          value: !(value$17 == null) ? value$17 : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "OnetrustCookieSettingButtonContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$15.__typename,
                                            id: value$15.id,
                                            codename: value$15.codename
                                          },
                                          text: tmp$7
                                        }
                                      };
                                      break;
                                  default:
                                    tmp = {
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    };
                                }
                                return tmp;
                              }), value$5) : undefined
                    };
                  }
                  tmp = {
                    NAME: "LinkColumnContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      title: tmp$1,
                      links: tmp$2
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$3) : undefined
    };
  }
  var value$4 = value.subscribe_title;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.subscribe_description;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.subscribe_helper_text;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.subscribe_cta;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? value$11 : undefined
    };
  }
  var value$12 = value.cta_style__style;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$13) : undefined
    };
  }
  var value$14 = value.region_selector_text;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.app_store_title;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.app_stores_container;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "AppStoreItemContentType") {
                  var value$1 = value.system;
                  var value$2 = value.app_store_name;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.app_store_download_link;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.app_store_asset;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$7) : undefined
                    };
                  }
                  tmp = {
                    NAME: "AppStoreItemContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      app_store_name: tmp$1,
                      app_store_download_link: tmp$2,
                      app_store_asset: tmp$3
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$19) : undefined
    };
  }
  var value$20 = value.footer_disclaimer_level_1;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? value$21 : undefined
    };
  }
  var value$22 = value.footer_disclaimer_level_2;
  var tmp$10;
  if (value$22 == null) {
    tmp$10 = undefined;
  } else {
    var value$23 = value$22.value;
    tmp$10 = {
      __typename: value$22.__typename,
      value: !(value$23 == null) ? value$23 : undefined
    };
  }
  var value$24 = value.background_colour;
  var tmp$11;
  if (value$24 == null) {
    tmp$11 = undefined;
  } else {
    var value$25 = value$24.value;
    tmp$11 = {
      __typename: value$24.__typename,
      value: !(value$25 == null) ? value$25 : undefined
    };
  }
  var value$26 = value.heading_colour;
  var tmp$12;
  if (value$26 == null) {
    tmp$12 = undefined;
  } else {
    var value$27 = value$26.value;
    tmp$12 = {
      __typename: value$26.__typename,
      value: !(value$27 == null) ? value$27 : undefined
    };
  }
  var value$28 = value.body_colour;
  var tmp$13;
  if (value$28 == null) {
    tmp$13 = undefined;
  } else {
    var value$29 = value$28.value;
    tmp$13 = {
      __typename: value$28.__typename,
      value: !(value$29 == null) ? value$29 : undefined
    };
  }
  var value$30 = value.body_hover_colour;
  var tmp$14;
  if (value$30 == null) {
    tmp$14 = undefined;
  } else {
    var value$31 = value$30.value;
    tmp$14 = {
      __typename: value$30.__typename,
      value: !(value$31 == null) ? value$31 : undefined
    };
  }
  var value$32 = value.icon_background_colour;
  var tmp$15;
  if (value$32 == null) {
    tmp$15 = undefined;
  } else {
    var value$33 = value$32.value;
    tmp$15 = {
      __typename: value$32.__typename,
      value: !(value$33 == null) ? value$33 : undefined
    };
  }
  var value$34 = value.icon_hover_background_colour;
  var tmp$16;
  if (value$34 == null) {
    tmp$16 = undefined;
  } else {
    var value$35 = value$34.value;
    tmp$16 = {
      __typename: value$34.__typename,
      value: !(value$35 == null) ? value$35 : undefined
    };
  }
  var value$36 = value.icon_colour;
  var tmp$17;
  if (value$36 == null) {
    tmp$17 = undefined;
  } else {
    var value$37 = value$36.value;
    tmp$17 = {
      __typename: value$36.__typename,
      value: !(value$37 == null) ? value$37 : undefined
    };
  }
  var value$38 = value.icon_hover_colour;
  var tmp$18;
  if (value$38 == null) {
    tmp$18 = undefined;
  } else {
    var value$39 = value$38.value;
    tmp$18 = {
      __typename: value$38.__typename,
      value: !(value$39 == null) ? value$39 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          link_columns: tmp,
          subscribe_title: tmp$1,
          subscribe_description: tmp$2,
          subscribe_helper_text: tmp$3,
          subscribe_cta: tmp$4,
          cta_style__style: tmp$5,
          region_selector_text: tmp$6,
          app_store_title: tmp$7,
          app_stores_container: tmp$8,
          footer_disclaimer_level_1: tmp$9,
          footer_disclaimer_level_2: tmp$10,
          background_colour: tmp$11,
          heading_colour: tmp$12,
          body_colour: tmp$13,
          body_hover_colour: tmp$14,
          icon_background_colour: tmp$15,
          icon_hover_background_colour: tmp$16,
          icon_colour: tmp$17,
          icon_hover_colour: tmp$18
        };
}

function serialize(value) {
  var value$1 = value.icon_hover_colour;
  var icon_hover_colour;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    icon_hover_colour = {
      __typename: value$4,
      value: value$3
    };
  } else {
    icon_hover_colour = null;
  }
  var value$5 = value.icon_colour;
  var icon_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    icon_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    icon_colour = null;
  }
  var value$9 = value.icon_hover_background_colour;
  var icon_hover_background_colour;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    icon_hover_background_colour = {
      __typename: value$12,
      value: value$11
    };
  } else {
    icon_hover_background_colour = null;
  }
  var value$13 = value.icon_background_colour;
  var icon_background_colour;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    icon_background_colour = {
      __typename: value$16,
      value: value$15
    };
  } else {
    icon_background_colour = null;
  }
  var value$17 = value.body_hover_colour;
  var body_hover_colour;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    body_hover_colour = {
      __typename: value$20,
      value: value$19
    };
  } else {
    body_hover_colour = null;
  }
  var value$21 = value.body_colour;
  var body_colour;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    body_colour = {
      __typename: value$24,
      value: value$23
    };
  } else {
    body_colour = null;
  }
  var value$25 = value.heading_colour;
  var heading_colour;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? value$26 : null;
    var value$28 = value$25.__typename;
    heading_colour = {
      __typename: value$28,
      value: value$27
    };
  } else {
    heading_colour = null;
  }
  var value$29 = value.background_colour;
  var background_colour;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? value$30 : null;
    var value$32 = value$29.__typename;
    background_colour = {
      __typename: value$32,
      value: value$31
    };
  } else {
    background_colour = null;
  }
  var value$33 = value.footer_disclaimer_level_2;
  var footer_disclaimer_level_2;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    footer_disclaimer_level_2 = {
      __typename: value$36,
      value: value$35
    };
  } else {
    footer_disclaimer_level_2 = null;
  }
  var value$37 = value.footer_disclaimer_level_1;
  var footer_disclaimer_level_1;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? value$38 : null;
    var value$40 = value$37.__typename;
    footer_disclaimer_level_1 = {
      __typename: value$40,
      value: value$39
    };
  } else {
    footer_disclaimer_level_1 = null;
  }
  var value$41 = value.app_stores_container;
  var app_stores_container;
  if (value$41 !== undefined) {
    var value$42 = value$41.value;
    var value$43 = value$42 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.app_store_asset;
              var app_store_asset;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                app_store_asset = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                app_store_asset = null;
              }
              var value$6 = value$1.app_store_download_link;
              var app_store_download_link;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                app_store_download_link = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                app_store_download_link = null;
              }
              var value$10 = value$1.app_store_name;
              var app_store_name;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                app_store_name = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                app_store_name = null;
              }
              var value$14 = value$1.system;
              var value$15 = value$14.codename;
              var value$16 = value$14.id;
              var value$17 = value$14.__typename;
              var system = {
                __typename: value$17,
                id: value$16,
                codename: value$15
              };
              return {
                      __typename: "AppStoreItemContentType",
                      system: system,
                      app_store_name: app_store_name,
                      app_store_download_link: app_store_download_link,
                      app_store_asset: app_store_asset
                    };
            }), value$42) : null;
    var value$44 = value$41.__typename;
    app_stores_container = {
      __typename: value$44,
      value: value$43
    };
  } else {
    app_stores_container = null;
  }
  var value$45 = value.app_store_title;
  var app_store_title;
  if (value$45 !== undefined) {
    var value$46 = value$45.value;
    var value$47 = value$46 !== undefined ? value$46 : null;
    var value$48 = value$45.__typename;
    app_store_title = {
      __typename: value$48,
      value: value$47
    };
  } else {
    app_store_title = null;
  }
  var value$49 = value.region_selector_text;
  var region_selector_text;
  if (value$49 !== undefined) {
    var value$50 = value$49.value;
    var value$51 = value$50 !== undefined ? value$50 : null;
    var value$52 = value$49.__typename;
    region_selector_text = {
      __typename: value$52,
      value: value$51
    };
  } else {
    region_selector_text = null;
  }
  var value$53 = value.cta_style__style;
  var cta_style__style;
  if (value$53 !== undefined) {
    var value$54 = value$53.value;
    var value$55 = value$54 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$54) : null;
    var value$56 = value$53.__typename;
    cta_style__style = {
      __typename: value$56,
      value: value$55
    };
  } else {
    cta_style__style = null;
  }
  var value$57 = value.subscribe_cta;
  var subscribe_cta;
  if (value$57 !== undefined) {
    var value$58 = value$57.value;
    var value$59 = value$58 !== undefined ? value$58 : null;
    var value$60 = value$57.__typename;
    subscribe_cta = {
      __typename: value$60,
      value: value$59
    };
  } else {
    subscribe_cta = null;
  }
  var value$61 = value.subscribe_helper_text;
  var subscribe_helper_text;
  if (value$61 !== undefined) {
    var value$62 = value$61.value;
    var value$63 = value$62 !== undefined ? value$62 : null;
    var value$64 = value$61.__typename;
    subscribe_helper_text = {
      __typename: value$64,
      value: value$63
    };
  } else {
    subscribe_helper_text = null;
  }
  var value$65 = value.subscribe_description;
  var subscribe_description;
  if (value$65 !== undefined) {
    var value$66 = value$65.value;
    var value$67 = value$66 !== undefined ? value$66 : null;
    var value$68 = value$65.__typename;
    subscribe_description = {
      __typename: value$68,
      value: value$67
    };
  } else {
    subscribe_description = null;
  }
  var value$69 = value.subscribe_title;
  var subscribe_title;
  if (value$69 !== undefined) {
    var value$70 = value$69.value;
    var value$71 = value$70 !== undefined ? value$70 : null;
    var value$72 = value$69.__typename;
    subscribe_title = {
      __typename: value$72,
      value: value$71
    };
  } else {
    subscribe_title = null;
  }
  var value$73 = value.link_columns;
  var link_columns;
  if (value$73 !== undefined) {
    var value$74 = value$73.value;
    var value$75 = value$74 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME !== "LinkColumnContentType") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.links;
              var links;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var variant = value.NAME;
                          if (variant === "MailtoLinkContentType") {
                            var value$1 = value.VAL;
                            var value$2 = value$1.email_address;
                            var email_address;
                            if (value$2 !== undefined) {
                              var value$3 = value$2.value;
                              var value$4 = value$3 !== undefined ? value$3 : null;
                              var value$5 = value$2.__typename;
                              email_address = {
                                __typename: value$5,
                                value: value$4
                              };
                            } else {
                              email_address = null;
                            }
                            var value$6 = value$1.link_title;
                            var link_title;
                            if (value$6 !== undefined) {
                              var value$7 = value$6.value;
                              var value$8 = value$7 !== undefined ? value$7 : null;
                              var value$9 = value$6.__typename;
                              link_title = {
                                __typename: value$9,
                                value: value$8
                              };
                            } else {
                              link_title = null;
                            }
                            var value$10 = value$1.system;
                            var value$11 = value$10.codename;
                            var value$12 = value$10.id;
                            var value$13 = value$10.__typename;
                            var system = {
                              __typename: value$13,
                              id: value$12,
                              codename: value$11
                            };
                            return {
                                    __typename: "MailtoLinkContentType",
                                    system: system,
                                    link_title: link_title,
                                    email_address: email_address
                                  };
                          }
                          if (variant === "UnspecifiedFragment") {
                            return {};
                          }
                          if (variant === "OnetrustCookieSettingButtonContentType") {
                            var value$14 = value.VAL;
                            var value$15 = value$14.text;
                            var text;
                            if (value$15 !== undefined) {
                              var value$16 = value$15.value;
                              var value$17 = value$16 !== undefined ? value$16 : null;
                              var value$18 = value$15.__typename;
                              text = {
                                __typename: value$18,
                                value: value$17
                              };
                            } else {
                              text = null;
                            }
                            var value$19 = value$14.system;
                            var value$20 = value$19.codename;
                            var value$21 = value$19.id;
                            var value$22 = value$19.__typename;
                            var system$1 = {
                              __typename: value$22,
                              id: value$21,
                              codename: value$20
                            };
                            return {
                                    __typename: "OnetrustCookieSettingButtonContentType",
                                    system: system$1,
                                    text: text
                                  };
                          }
                          var value$23 = value.VAL;
                          var value$24 = value$23.links;
                          var links;
                          if (value$24 !== undefined) {
                            var value$25 = value$24.value;
                            var value$26 = value$25 !== undefined ? Js_array.map((function (value) {
                                      if (value === undefined) {
                                        return null;
                                      }
                                      var variant = value.NAME;
                                      if (variant === "UnspecifiedFragment") {
                                        return {};
                                      } else if (variant === "FontAwesomeContentType") {
                                        return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                                      return {};
                                                    }), [Gql__FontAwesomeIcon__Fragment.FontAwesomeIcon.serialize(value.VAL.fontAwesomeIcon)]);
                                      } else {
                                        return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                                      return {};
                                                    }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                                      }
                                    }), value$25) : null;
                            var value$27 = value$24.__typename;
                            links = {
                              __typename: value$27,
                              value: value$26
                            };
                          } else {
                            links = null;
                          }
                          var value$28 = value$23.open_in_new_window;
                          var open_in_new_window;
                          if (value$28 !== undefined) {
                            var value$29 = value$28.value;
                            var value$30 = value$29 !== undefined ? Js_array.map((function (value) {
                                      if (value === undefined) {
                                        return null;
                                      }
                                      var value$1 = value.codename;
                                      var codename = value$1 !== undefined ? value$1 : null;
                                      var value$2 = value.__typename;
                                      return {
                                              __typename: value$2,
                                              codename: codename
                                            };
                                    }), value$29) : null;
                            var value$31 = value$28.__typename;
                            open_in_new_window = {
                              __typename: value$31,
                              value: value$30
                            };
                          } else {
                            open_in_new_window = null;
                          }
                          var value$32 = value$23.url;
                          var url;
                          if (value$32 !== undefined) {
                            var value$33 = value$32.value;
                            var value$34 = value$33 !== undefined ? value$33 : null;
                            var value$35 = value$32.__typename;
                            url = {
                              __typename: value$35,
                              value: value$34
                            };
                          } else {
                            url = null;
                          }
                          var value$36 = value$23.link_text;
                          var link_text;
                          if (value$36 !== undefined) {
                            var value$37 = value$36.value;
                            var value$38 = value$37 !== undefined ? value$37 : null;
                            var value$39 = value$36.__typename;
                            link_text = {
                              __typename: value$39,
                              value: value$38
                            };
                          } else {
                            link_text = null;
                          }
                          var value$40 = value$23.system;
                          var value$41 = value$40.codename;
                          var value$42 = value$40.id;
                          var value$43 = value$40.__typename;
                          var system$2 = {
                            __typename: value$43,
                            id: value$42,
                            codename: value$41
                          };
                          return {
                                  __typename: "LinkItemContentType",
                                  system: system$2,
                                  link_text: link_text,
                                  url: url,
                                  open_in_new_window: open_in_new_window,
                                  links: links
                                };
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                links = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                links = null;
              }
              var value$6 = value$1.title;
              var title;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                title = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                title = null;
              }
              var value$10 = value$1.system;
              var value$11 = value$10.codename;
              var value$12 = value$10.id;
              var value$13 = value$10.__typename;
              var system = {
                __typename: value$13,
                id: value$12,
                codename: value$11
              };
              return {
                      __typename: "LinkColumnContentType",
                      system: system,
                      title: title,
                      links: links
                    };
            }), value$74) : null;
    var value$76 = value$73.__typename;
    link_columns = {
      __typename: value$76,
      value: value$75
    };
  } else {
    link_columns = null;
  }
  var value$77 = value.system;
  var value$78 = value$77.codename;
  var value$79 = value$77.id;
  var value$80 = value$77.__typename;
  var system = {
    __typename: value$80,
    id: value$79,
    codename: value$78
  };
  var value$81 = value.__typename;
  return {
          __typename: value$81,
          system: system,
          link_columns: link_columns,
          subscribe_title: subscribe_title,
          subscribe_description: subscribe_description,
          subscribe_helper_text: subscribe_helper_text,
          subscribe_cta: subscribe_cta,
          cta_style__style: cta_style__style,
          region_selector_text: region_selector_text,
          app_store_title: app_store_title,
          app_stores_container: app_stores_container,
          footer_disclaimer_level_1: footer_disclaimer_level_1,
          footer_disclaimer_level_2: footer_disclaimer_level_2,
          background_colour: background_colour,
          heading_colour: heading_colour,
          body_colour: body_colour,
          body_hover_colour: body_hover_colour,
          icon_background_colour: icon_background_colour,
          icon_hover_background_colour: icon_hover_background_colour,
          icon_colour: icon_colour,
          icon_hover_colour: icon_hover_colour
        };
}

function verifyArgsAndParse(_FooterBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var FooterBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var FontAwesomeIcon;

var $$Image;

export {
  FontAwesomeIcon ,
  $$Image ,
  FooterBlock ,
}
/* query Not a pure module */
