// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  if (typeof c !== "object") {
    return ;
  }
  if (c.NAME !== "RegisterContentType") {
    return ;
  }
  var c$1 = c.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.register_title, (function (c) {
              return c.value;
            })), "");
  var subtitle = Belt_Option.flatMap(c$1.register_subtitle, (function (c) {
          return c.value;
        }));
  var nextButtonText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.next_button_text, (function (c) {
              return c.value;
            })), "");
  var backButtonText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.back_button_text, (function (c) {
              return c.value;
            })), "");
  var stepTitleName = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.step_title___name, (function (c) {
              return c.value;
            })), "");
  var firstNameLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.first_name_label, (function (c) {
              return c.value;
            })), "");
  var firstNameRequiredText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.first_name_required_text, (function (c) {
              return c.value;
            })), "");
  var invalidFirstNameText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.invalid_first_name_text, (function (c) {
              return c.value;
            })), "");
  var lastNameLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.last_name_label, (function (c) {
              return c.value;
            })), "");
  var lastNameRequiredText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.last_name_required_text, (function (c) {
              return c.value;
            })), "");
  var invalidLastNameText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.invalid_last_name_text, (function (c) {
              return c.value;
            })), "");
  var stepTitleEmail = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.step_title___email, (function (c) {
              return c.value;
            })), "");
  var emailLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.email_label, (function (c) {
              return c.value;
            })), "");
  var emailRequiredText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.email_required_text, (function (c) {
              return c.value;
            })), "");
  var invalidEmailText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.invalid_email_text, (function (c) {
              return c.value;
            })), "");
  var stepTitlePassword = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.step_title___password, (function (c) {
              return c.value;
            })), "");
  var passwordRequirements = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.password_requirements, (function (c) {
              return c.value;
            })), "");
  var passwordLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.password_label, (function (c) {
              return c.value;
            })), "");
  var passwordRequiredText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.password_required_text, (function (c) {
              return c.value;
            })), "");
  var invalidPasswordText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.invalid_password_text, (function (c) {
              return c.value;
            })), "");
  var stepTitlePhoneNumber = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.step_title___phone_number, (function (c) {
              return c.value;
            })), "");
  var phoneNumberRequiredText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.phone_number_required_text, (function (c) {
              return c.value;
            })), "");
  var invalidPhoneNumberText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.invalid_phone_number_text, (function (c) {
              return c.value;
            })), "");
  var newsletterCheckboxLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.newsletter_checkbox_label, (function (c) {
              return c.value;
            })), "");
  var tAndCsCheckboxLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.t_cs_checkbox_label, (function (c) {
              return c.value;
            })), "");
  var nzNotPrezzyDisclaimer = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.nz_not_prezzy_disclaimer, (function (c) {
              return c.value;
            })), "");
  var sendSecurityCodeCtaText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.send_security_code_cta_text, (function (c) {
              return c.value;
            })), "");
  var stepTitleSecurityCode = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.step_title___security_code, (function (c) {
              return c.value;
            })), "");
  var resendCodeText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.resend_code_text, (function (c) {
              return c.value;
            })), "");
  var resendingCodeText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.resending_code_text, (function (c) {
              return c.value;
            })), "");
  var signUpCtaText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.sign_up_cta_text, (function (c) {
              return c.value;
            })), "");
  var alreadyRegisteredText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.already_registered_text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c$1.system.id;
  var smartLink_codename = c$1.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          subtitle: subtitle,
          nextButtonText: nextButtonText,
          backButtonText: backButtonText,
          stepTitleName: stepTitleName,
          firstNameLabel: firstNameLabel,
          firstNameRequiredText: firstNameRequiredText,
          invalidFirstNameText: invalidFirstNameText,
          lastNameLabel: lastNameLabel,
          lastNameRequiredText: lastNameRequiredText,
          invalidLastNameText: invalidLastNameText,
          stepTitleEmail: stepTitleEmail,
          emailLabel: emailLabel,
          emailRequiredText: emailRequiredText,
          invalidEmailText: invalidEmailText,
          stepTitlePassword: stepTitlePassword,
          passwordRequirements: passwordRequirements,
          passwordLabel: passwordLabel,
          passwordRequiredText: passwordRequiredText,
          invalidPasswordText: invalidPasswordText,
          stepTitlePhoneNumber: stepTitlePhoneNumber,
          phoneNumberRequiredText: phoneNumberRequiredText,
          invalidPhoneNumberText: invalidPhoneNumberText,
          newsletterCheckboxLabel: newsletterCheckboxLabel,
          tAndCsCheckboxLabel: tAndCsCheckboxLabel,
          nzNotPrezzyDisclaimer: nzNotPrezzyDisclaimer,
          sendSecurityCodeCtaText: sendSecurityCodeCtaText,
          stepTitleSecurityCode: stepTitleSecurityCode,
          resendCodeText: resendCodeText,
          resendingCodeText: resendingCodeText,
          signUpCtaText: signUpCtaText,
          alreadyRegisteredText: alreadyRegisteredText,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
