// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment HeaderLogo on HeaderLogoContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    classic_logo_asset  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    classic_logo_width  {
      __typename
      value
    }
    business_logo_asset  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    business_logo_width  {
      __typename
      value
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.classic_logo_asset;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.classic_logo_width;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.business_logo_asset;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.business_logo_width;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          classic_logo_asset: tmp,
          classic_logo_width: tmp$1,
          business_logo_asset: tmp$2,
          business_logo_width: tmp$3
        };
}

function serialize(value) {
  var value$1 = value.business_logo_width;
  var business_logo_width;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    business_logo_width = {
      __typename: value$4,
      value: value$3
    };
  } else {
    business_logo_width = null;
  }
  var value$5 = value.business_logo_asset;
  var business_logo_asset;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    business_logo_asset = {
      __typename: value$8,
      value: value$7
    };
  } else {
    business_logo_asset = null;
  }
  var value$9 = value.classic_logo_width;
  var classic_logo_width;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    classic_logo_width = {
      __typename: value$12,
      value: value$11
    };
  } else {
    classic_logo_width = null;
  }
  var value$13 = value.classic_logo_asset;
  var classic_logo_asset;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$14) : null;
    var value$16 = value$13.__typename;
    classic_logo_asset = {
      __typename: value$16,
      value: value$15
    };
  } else {
    classic_logo_asset = null;
  }
  var value$17 = value.system;
  var value$18 = value$17.codename;
  var value$19 = value$17.id;
  var value$20 = value$17.__typename;
  var system = {
    __typename: value$20,
    id: value$19,
    codename: value$18
  };
  var value$21 = value.__typename;
  return {
          __typename: value$21,
          system: system,
          classic_logo_asset: classic_logo_asset,
          classic_logo_width: classic_logo_width,
          business_logo_asset: business_logo_asset,
          business_logo_width: business_logo_width
        };
}

function verifyArgsAndParse(_HeaderLogo, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var HeaderLogo = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

export {
  $$Image ,
  HeaderLogo ,
}
/* query Not a pure module */
