// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function securityCodeResponse(json) {
  return {
          detail: Json_decode.field("detail", Json_decode.string, json)
        };
}

function safeDecodeSecurityCodeResponse(json) {
  var response;
  try {
    response = securityCodeResponse(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* ServerError */2,
                _0: {
                  TAG: /* ServerValidationError */0,
                  _0: "Invalid security"
                }
              }
            };
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: response
        };
}

var Decode = {
  securityCodeResponse: securityCodeResponse,
  safeDecodeSecurityCodeResponse: safeDecodeSecurityCodeResponse
};

export {
  Decode ,
}
/* No side effect */
