// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "IconCardContentType") {
    return ;
  }
  var c = content.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(c.url, (function (c) {
              return c.value;
            })), "");
  var open_in_new_window = Belt_Option.mapWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.open_in_new_window, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), false, (function (v) {
          return v === "yes";
        }));
  var title_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_colour, (function (c) {
              return c.value;
            })), "");
  var text_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (c) {
              return c.value;
            })), "");
  var background_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon_asset, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var icon;
  if (value !== undefined) {
    var name = Belt_Option.getWithDefault(value.name, "");
    var description$1 = Belt_Option.getWithDefault(value.description, "");
    var url$1 = Belt_Option.getWithDefault(value.url, "");
    var width = Belt_Option.getWithDefault(value.width, 0);
    var height = Belt_Option.getWithDefault(value.height, 0);
    icon = {
      name: name,
      description: description$1,
      url: url$1,
      width: width,
      height: height
    };
  } else {
    icon = undefined;
  }
  var iconSize = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon_size, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "small", (function (str) {
          return str;
        }));
  var contentAlignment = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.content_alignment, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "left", (function (str) {
          return str;
        }));
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          description: description,
          url: url,
          open_in_new_window: open_in_new_window,
          title_colour: title_colour,
          text_colour: text_colour,
          background_colour: background_colour,
          icon: icon,
          iconSize: iconSize,
          contentAlignment: contentAlignment,
          smartLink: smartLink
        };
}

var CardIcon;

export {
  CardIcon ,
  get ,
}
/* No side effect */
