// Generated by ReScript, PLEASE EDIT WITH CARE


var giftPaths = [
  "/gift/open/",
  "/gift/open-ndl/",
  "/gift/say-thanks/",
  "/gift/",
  "/exchange/card/",
  "/history/"
];

export {
  giftPaths ,
}
/* No side effect */
