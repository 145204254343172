import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import useBreakpointValue from '@prezzee/ribbon-ui/lib/hooks/useBreakpointValue';
import debounce from 'utils/debounce';

const NavVisibilityContext = createContext(false);

export const NavVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  const hasScrollListener = useBreakpointValue({ base: true, xl: false }, 'xl');

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = debounce(() => {
      const currentScrollTop = window.pageYOffset;

      if (currentScrollTop <= 0) {
        setIsVisible(true);
      } else if (currentScrollTop > lastScrollTop && currentScrollTop > 75) {
        setIsVisible(false);
      } else if (currentScrollTop < lastScrollTop && Math.abs(lastScrollTop - currentScrollTop) >= 75) {
        setIsVisible(true);
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    }, 100);

    if (hasScrollListener) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (hasScrollListener) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasScrollListener]);

  return <NavVisibilityContext.Provider value={isVisible}>{children}</NavVisibilityContext.Provider>;
};

export const NavVisibilityConsumer = ({ children }: { children: (value: boolean) => ReactNode }) => {
  return <NavVisibilityContext.Consumer>{children}</NavVisibilityContext.Consumer>;
};

export const useNavVisibilty = () => {
  const ctx = useContext(NavVisibilityContext);
  if (typeof ctx === 'undefined') throw new Error('useNavVisibilty must be used within the NavVisibilityProvider');
  return ctx;
};
