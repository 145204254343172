// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Image__Decoder from "../Image/Gql__Image__Decoder.bs.js";

function get(content) {
  var seoMetaBasicTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__basic__title, (function (c) {
              return c.value;
            })), "");
  var seoMetaBasicDescription = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__basic__description, (function (c) {
              return c.value;
            })), "");
  var seoMetaOgTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__og__title, (function (c) {
              return c.value;
            })), "");
  var seoMetaOgDescription = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__og__description, (function (c) {
              return c.value;
            })), "");
  var seoMetaOgImageAsset = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__og__image_asset, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (x) {
              if (typeof x === "object" && x.NAME === "ImageContentType") {
                return Gql__Image__Decoder.get(x.VAL.image);
              }
              
            })), 0);
  var seoMetaOgType = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__og__type, (function (c) {
              return c.value;
            })), "");
  var seoMetaOgUrl = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__og__url, (function (c) {
              return c.value;
            })), "");
  var seoMetaTwTitle = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__tw__title, (function (c) {
              return c.value;
            })), "");
  var seoMetaTwDescription = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__tw__description, (function (c) {
              return c.value;
            })), "");
  var seoMetaTwImageAsset = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__tw__image_asset, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), (function (x) {
              if (typeof x === "object" && x.NAME === "ImageContentType") {
                return Gql__Image__Decoder.get(x.VAL.image);
              }
              
            })), 0);
  var seoMetaTwImageAssetUrl = Belt_Option.getWithDefault(Belt_Option.flatMap(content.seo_meta__tw__image_asset_url, (function (c) {
              return c.value;
            })), "");
  return {
          seoMetaBasicTitle: seoMetaBasicTitle,
          seoMetaBasicDescription: seoMetaBasicDescription,
          seoMetaOgTitle: seoMetaOgTitle,
          seoMetaOgDescription: seoMetaOgDescription,
          seoMetaOgImageAsset: seoMetaOgImageAsset,
          seoMetaOgType: seoMetaOgType,
          seoMetaOgUrl: seoMetaOgUrl,
          seoMetaTwTitle: seoMetaTwTitle,
          seoMetaTwDescription: seoMetaTwDescription,
          seoMetaTwImageAsset: seoMetaTwImageAsset,
          seoMetaTwImageAssetUrl: seoMetaTwImageAssetUrl
        };
}

var $$Image;

export {
  $$Image ,
  get ,
}
/* No side effect */
