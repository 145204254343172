// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";
import * as Gql__ImageCollection__Fragment from "../ImageCollection/Gql__ImageCollection__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment TestimonialCarouselBlock on TestimonialCarouselBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    testimonial_image_grid  {
      __typename
      value  {
        __typename
        ...on ImageCollectionContentType   {
          ...ImageCollection
        }
      }
    }
    testimonial_carousel_items  {
      __typename
      value  {
        __typename
        ...on TestimonialCarouselItemContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          image_asset  {
            __typename
            value  {
              __typename
              name
              description
              url
              width
              height
            }
          }
          quote  {
            __typename
            value
          }
          author  {
            __typename
            value
          }
          url  {
            __typename
            value  {
              __typename
              ...on LinkItemContentType   {
                __typename
                url  {
                  __typename
                  value
                }
                open_in_new_window  {
                  __typename
                  value  {
                    __typename
                    codename
                  }
                }
              }
            }
          }
          background_image  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          background_image_tablet  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          background_image_mobile  {
            __typename
            value  {
              __typename
              ...on ImageContentType   {
                ...Image
              }
            }
          }
          background_colour  {
            __typename
            value
          }
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__Image__Fragment.$$Image.query, Gql__ImageCollection__Fragment.ImageCollection.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.testimonial_image_grid;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageCollectionContentType" ? ({
                      NAME: "ImageCollectionContentType",
                      VAL: {
                        imageCollection: Gql__ImageCollection__Fragment.ImageCollection.verifyArgsAndParse("ImageCollection", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$5) : undefined
    };
  }
  var value$6 = value.testimonial_carousel_items;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "TestimonialCarouselItemContentType") {
                  var value$1 = value.system;
                  var value$2 = value.image_asset;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.name;
                                var value$2 = value.description;
                                var value$3 = value.url;
                                var value$4 = value.width;
                                var value$5 = value.height;
                                return {
                                        __typename: value.__typename,
                                        name: !(value$1 == null) ? value$1 : undefined,
                                        description: !(value$2 == null) ? value$2 : undefined,
                                        url: !(value$3 == null) ? value$3 : undefined,
                                        width: !(value$4 == null) ? value$4 : undefined,
                                        height: !(value$5 == null) ? value$5 : undefined
                                      };
                              }), value$3) : undefined
                    };
                  }
                  var value$4 = value.quote;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.author;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.url;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp;
                                if (typename === "LinkItemContentType") {
                                  var value$1 = value.url;
                                  var tmp$1;
                                  if (value$1 == null) {
                                    tmp$1 = undefined;
                                  } else {
                                    var value$2 = value$1.value;
                                    tmp$1 = {
                                      __typename: value$1.__typename,
                                      value: !(value$2 == null) ? value$2 : undefined
                                    };
                                  }
                                  var value$3 = value.open_in_new_window;
                                  var tmp$2;
                                  if (value$3 == null) {
                                    tmp$2 = undefined;
                                  } else {
                                    var value$4 = value$3.value;
                                    tmp$2 = {
                                      __typename: value$3.__typename,
                                      value: !(value$4 == null) ? Js_array.map((function (value) {
                                                if (value == null) {
                                                  return ;
                                                }
                                                var value$1 = value.codename;
                                                return {
                                                        __typename: value.__typename,
                                                        codename: !(value$1 == null) ? value$1 : undefined
                                                      };
                                              }), value$4) : undefined
                                    };
                                  }
                                  tmp = {
                                    NAME: "LinkItemContentType",
                                    VAL: {
                                      __typename: value.__typename,
                                      url: tmp$1,
                                      open_in_new_window: tmp$2
                                    }
                                  };
                                } else {
                                  tmp = {
                                    NAME: "UnspecifiedFragment",
                                    VAL: typename
                                  };
                                }
                                return tmp;
                              }), value$9) : undefined
                    };
                  }
                  var value$10 = value.background_image;
                  var tmp$5;
                  if (value$10 == null) {
                    tmp$5 = undefined;
                  } else {
                    var value$11 = value$10.value;
                    tmp$5 = {
                      __typename: value$10.__typename,
                      value: !(value$11 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$11) : undefined
                    };
                  }
                  var value$12 = value.background_image_tablet;
                  var tmp$6;
                  if (value$12 == null) {
                    tmp$6 = undefined;
                  } else {
                    var value$13 = value$12.value;
                    tmp$6 = {
                      __typename: value$12.__typename,
                      value: !(value$13 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$13) : undefined
                    };
                  }
                  var value$14 = value.background_image_mobile;
                  var tmp$7;
                  if (value$14 == null) {
                    tmp$7 = undefined;
                  } else {
                    var value$15 = value$14.value;
                    tmp$7 = {
                      __typename: value$14.__typename,
                      value: !(value$15 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "ImageContentType" ? ({
                                      NAME: "ImageContentType",
                                      VAL: {
                                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$15) : undefined
                    };
                  }
                  var value$16 = value.background_colour;
                  var tmp$8;
                  if (value$16 == null) {
                    tmp$8 = undefined;
                  } else {
                    var value$17 = value$16.value;
                    tmp$8 = {
                      __typename: value$16.__typename,
                      value: !(value$17 == null) ? value$17 : undefined
                    };
                  }
                  tmp = {
                    NAME: "TestimonialCarouselItemContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      image_asset: tmp$1,
                      quote: tmp$2,
                      author: tmp$3,
                      url: tmp$4,
                      background_image: tmp$5,
                      background_image_tablet: tmp$6,
                      background_image_mobile: tmp$7,
                      background_colour: tmp$8
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$7) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          testimonial_image_grid: tmp$1,
          testimonial_carousel_items: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.testimonial_carousel_items;
  var testimonial_carousel_items;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.background_colour;
              var background_colour;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? value$3 : null;
                var value$5 = value$2.__typename;
                background_colour = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                background_colour = null;
              }
              var value$6 = value$1.background_image_mobile;
              var background_image_mobile;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$7) : null;
                var value$9 = value$6.__typename;
                background_image_mobile = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                background_image_mobile = null;
              }
              var value$10 = value$1.background_image_tablet;
              var background_image_tablet;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$11) : null;
                var value$13 = value$10.__typename;
                background_image_tablet = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                background_image_tablet = null;
              }
              var value$14 = value$1.background_image;
              var background_image;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "UnspecifiedFragment") {
                              return {};
                            } else {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                            }
                          } else {
                            return null;
                          }
                        }), value$15) : null;
                var value$17 = value$14.__typename;
                background_image = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                background_image = null;
              }
              var value$18 = value$1.url;
              var url;
              if (value$18 !== undefined) {
                var value$19 = value$18.value;
                var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          }
                          var value$1 = value.VAL;
                          var value$2 = value$1.open_in_new_window;
                          var open_in_new_window;
                          if (value$2 !== undefined) {
                            var value$3 = value$2.value;
                            var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                                      if (value === undefined) {
                                        return null;
                                      }
                                      var value$1 = value.codename;
                                      var codename = value$1 !== undefined ? value$1 : null;
                                      var value$2 = value.__typename;
                                      return {
                                              __typename: value$2,
                                              codename: codename
                                            };
                                    }), value$3) : null;
                            var value$5 = value$2.__typename;
                            open_in_new_window = {
                              __typename: value$5,
                              value: value$4
                            };
                          } else {
                            open_in_new_window = null;
                          }
                          var value$6 = value$1.url;
                          var url;
                          if (value$6 !== undefined) {
                            var value$7 = value$6.value;
                            var value$8 = value$7 !== undefined ? value$7 : null;
                            var value$9 = value$6.__typename;
                            url = {
                              __typename: value$9,
                              value: value$8
                            };
                          } else {
                            url = null;
                          }
                          return {
                                  __typename: "LinkItemContentType",
                                  url: url,
                                  open_in_new_window: open_in_new_window
                                };
                        }), value$19) : null;
                var value$21 = value$18.__typename;
                url = {
                  __typename: value$21,
                  value: value$20
                };
              } else {
                url = null;
              }
              var value$22 = value$1.author;
              var author;
              if (value$22 !== undefined) {
                var value$23 = value$22.value;
                var value$24 = value$23 !== undefined ? value$23 : null;
                var value$25 = value$22.__typename;
                author = {
                  __typename: value$25,
                  value: value$24
                };
              } else {
                author = null;
              }
              var value$26 = value$1.quote;
              var quote;
              if (value$26 !== undefined) {
                var value$27 = value$26.value;
                var value$28 = value$27 !== undefined ? value$27 : null;
                var value$29 = value$26.__typename;
                quote = {
                  __typename: value$29,
                  value: value$28
                };
              } else {
                quote = null;
              }
              var value$30 = value$1.image_asset;
              var image_asset;
              if (value$30 !== undefined) {
                var value$31 = value$30.value;
                var value$32 = value$31 !== undefined ? Js_array.map((function (value) {
                          if (value === undefined) {
                            return null;
                          }
                          var value$1 = value.height;
                          var height = value$1 !== undefined ? value$1 : null;
                          var value$2 = value.width;
                          var width = value$2 !== undefined ? value$2 : null;
                          var value$3 = value.url;
                          var url = value$3 !== undefined ? value$3 : null;
                          var value$4 = value.description;
                          var description = value$4 !== undefined ? value$4 : null;
                          var value$5 = value.name;
                          var name = value$5 !== undefined ? value$5 : null;
                          var value$6 = value.__typename;
                          return {
                                  __typename: value$6,
                                  name: name,
                                  description: description,
                                  url: url,
                                  width: width,
                                  height: height
                                };
                        }), value$31) : null;
                var value$33 = value$30.__typename;
                image_asset = {
                  __typename: value$33,
                  value: value$32
                };
              } else {
                image_asset = null;
              }
              var value$34 = value$1.system;
              var value$35 = value$34.codename;
              var value$36 = value$34.id;
              var value$37 = value$34.__typename;
              var system = {
                __typename: value$37,
                id: value$36,
                codename: value$35
              };
              return {
                      __typename: "TestimonialCarouselItemContentType",
                      system: system,
                      image_asset: image_asset,
                      quote: quote,
                      author: author,
                      url: url,
                      background_image: background_image,
                      background_image_tablet: background_image_tablet,
                      background_image_mobile: background_image_mobile,
                      background_colour: background_colour
                    };
            }), value$2) : null;
    var value$4 = value$1.__typename;
    testimonial_carousel_items = {
      __typename: value$4,
      value: value$3
    };
  } else {
    testimonial_carousel_items = null;
  }
  var value$5 = value.testimonial_image_grid;
  var testimonial_image_grid;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "ImageCollectionContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__ImageCollection__Fragment.ImageCollection.serialize(value.VAL.imageCollection)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    testimonial_image_grid = {
      __typename: value$8,
      value: value$7
    };
  } else {
    testimonial_image_grid = null;
  }
  var value$9 = value.title;
  var title;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    title = {
      __typename: value$12,
      value: value$11
    };
  } else {
    title = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.codename;
  var value$15 = value$13.id;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    id: value$15,
    codename: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          title: title,
          testimonial_image_grid: testimonial_image_grid,
          testimonial_carousel_items: testimonial_carousel_items
        };
}

function verifyArgsAndParse(_TestimonialCarouselBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var TestimonialCarouselBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var ImageCollection;

var $$Image;

export {
  ImageCollection ,
  $$Image ,
  TestimonialCarouselBlock ,
}
/* query Not a pure module */
