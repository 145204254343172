import type P from 'pino';
import pino from 'pino';
import { datadogLogs } from '@datadog/browser-logs';

// Pino Logger types
export type Logger = P.Logger;
export type LogLevel = 'info' | 'debug' | 'warn' | 'error';
export type LevelWithSilent = P.LevelWithSilent;
export type Env = 'local' | 'development' | 'staging' | 'production';

export const redactionsList: string[] = [
  'access_token',
  'data.access_token',
  'data.*.access_token',
  'accessToken',
  'data.accessToken',
  'data.*.accessToken',
  'email',
  'data.email',
  'data.*.email',
  'hostname',
  'jwt',
  'data.jwt',
  'data.*.jwt',
  'JWT',
  'data.JWT',
  'data.*.JWT',
  'params',
];

const isBrowser = typeof window !== 'undefined';
const pinoLogger = pino({
  level: 'info',
  redact: redactionsList,
  mixin() {
    // not supported by browser API - https://github.com/pinojs/pino/issues/765
    return {
      buildVersion: process.env.NEXT_PUBLIC_BUILD_VERSION ?? 'unknown',
    };
  },
});

const getMethodNameByLevel = (level: LogLevel) => {
  if (level === 'debug') return 'debug';
  if (level === 'error') return 'error';
  if (level === 'info') return 'info';
  if (level === 'warn') return 'warn';
};

function log(level: LogLevel, msg: string): void;
function log(level: LogLevel, context: object, msg?: string, env?: Env[]): void;
function log(level: LogLevel, messageOrContext: string | object, msg?: string, env?: Env[]): void;
function log(
  level: LogLevel,
  messageOrContext: string | object,
  msg?: string,
  env: Env[] = ['local', 'development', 'staging', 'production']
): void {
  if (!env.includes(process.env.NEXT_PUBLIC_APP_ENV as Env)) return;

  const methodName = getMethodNameByLevel(level);
  const baseContext = {
    countryCode: process.env.NEXT_PUBLIC_APP_COUNTRY_CODE,
  };
  const context = typeof messageOrContext === 'string' ? baseContext : { ...baseContext, ...messageOrContext };
  const message =
    typeof messageOrContext === 'string' ? messageOrContext : msg ?? `${methodName.toUpperCase} without message`;

  if ((window as any)['DD_LOGS']) {
    datadogLogs.logger?.[methodName]?.(message, context);
  }

  pinoLogger[methodName](context, message);
}

const browserLogger = {
  debug: (messageOrContext: string | object, msg?: string, env?: Env[]) => log('debug', messageOrContext, msg, env),
  error: (messageOrContext: string | object, msg?: string, env?: Env[]) => log('error', messageOrContext, msg, env),
  info: (messageOrContext: string | object, msg?: string, env?: Env[]) => log('info', messageOrContext, msg, env),
  warn: (messageOrContext: string | object, msg?: string, env?: Env[]) => log('warn', messageOrContext, msg, env),
};

export const logger = isBrowser ? browserLogger : pinoLogger;

export default logger;
