import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';
import { camelKeys } from 'js-convert-case';

// TODO: Add all possible gift resend errors.
/**
 * All possible errors returned from gift resend response.
 */
export const resendGiftErrors = {};

/**
 * Request to resend a gift, with optional blob for gifts that require pin.
 * @param uid The gift unique identifier.
 * @returns Promise with decoded gift resend response.
 */
const resendGift = (uid: string) => {
  return createRequest(`api/v3/gift/resend-notification/${uid}/`, {
    method: 'POST',
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }));
};

export default resendGift;
