// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__SellingPointsBlockItem__Fragment from "../SellingPointsBlockItem/Gql__SellingPointsBlockItem__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment SellingPointsBlock on SellingPointsBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    background_colour  {
      __typename
      value
    }
    items  {
      __typename
      value  {
        __typename
        ...on SellingPointsBlockItemContentType   {
          ...SellingPointsBlockItem
        }
      }
    }
  }
  ${frag_0}
`)(Gql__SellingPointsBlockItem__Fragment.SellingPointsBlockItem.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.background_colour;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.items;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "SellingPointsBlockItemContentType" ? ({
                      NAME: "SellingPointsBlockItemContentType",
                      VAL: {
                        sellingPointsBlockItem: Gql__SellingPointsBlockItem__Fragment.SellingPointsBlockItem.verifyArgsAndParse("SellingPointsBlockItem", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$7) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          background_colour: tmp$1,
          items: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.items;
  var items;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__SellingPointsBlockItem__Fragment.SellingPointsBlockItem.serialize(value.VAL.sellingPointsBlockItem)]);
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    items = {
      __typename: value$4,
      value: value$3
    };
  } else {
    items = null;
  }
  var value$5 = value.background_colour;
  var background_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    background_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    background_colour = null;
  }
  var value$9 = value.title;
  var title;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    title = {
      __typename: value$12,
      value: value$11
    };
  } else {
    title = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.codename;
  var value$15 = value$13.id;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    id: value$15,
    codename: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          title: title,
          background_colour: background_colour,
          items: items
        };
}

function verifyArgsAndParse(_SellingPointsBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SellingPointsBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var SellingPointsBlockItem;

export {
  SellingPointsBlockItem ,
  SellingPointsBlock ,
}
/* query Not a pure module */
