import logger from 'utils/logger';
import { v4 as uuidv4 } from 'uuid';

import { DecryptedUser } from 'common/types';
import Aes from 'crypto-js/aes';
import EncUtf8 from 'crypto-js/enc-utf8';
import { camelKeys } from 'js-convert-case';

/**
 * Decrypts the user details from the auth cookie
 * @param cookie - the cookie to decrypt
 * @returns the decrypted user or throws an error
 */
export function decryptUserCookie(cookie: string) {
  try {
    return camelKeys(JSON.parse(Aes.decrypt(cookie, process.env.NEXT_PUBLIC_KEY).toString(EncUtf8)), {
      recursive: true,
      recursiveInArray: true,
    }) as DecryptedUser;
  } catch (err) {
    logger.error(
      {
        err,
        journey: 'Authentication',
      },
      'error: unable to decrypt user cookie'
    );
  }
}

/**
 * Generates the unique signup ID
 */
export function generateUniqueSignupId(): string {
  const timeStamp = Date.now().toString();
  return timeStamp.concat(uuidv4());
}
