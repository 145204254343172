// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import Link from "next/link";
import * as NavBarLinks from "../components/library/NavBarLinks.bs.js";
import * as UserContext from "../auth/user/UserContext.bs.js";
import * as HeaderStyles from "./HeaderStyles.bs.js";
import * as NavBarUserModal from "../components/library/NavBarUserModal.bs.js";
import * as NavBarLinksMobile from "../components/library/NavBarLinksMobile.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavBarUserDropdown from "../components/library/NavBarUserDropdown.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import HeaderContainerTsx from "../components/library/HeaderContainer.tsx";

var make = HeaderContainerTsx;

var HeaderContainer = {
  make: make
};

var isMenuOpen = {
  contents: false
};

function Header(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setModalVisible = match$1[1];
  var isModalVisible = match$1[0];
  var match$2 = UserContext.useUser(undefined);
  var disableWindowScroll = function (isDisabled) {
    document.documentElement.style.setProperty("touch-action", isDisabled ? "none" : "");
    document.documentElement.style.setProperty("overflow", isDisabled ? "hidden" : "");
  };
  React.useEffect((function () {
          disableWindowScroll(isOpen || isModalVisible);
        }), [
        isOpen,
        isModalVisible
      ]);
  isMenuOpen.contents = isOpen || isModalVisible;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(make, {
                      children: JsxRuntime.jsx("nav", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("button", {
                                          children: isOpen ? JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                  icon: [
                                                    "fas",
                                                    "times"
                                                  ],
                                                  className: "w-5 h-5 fill-current"
                                                }) : JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                  icon: [
                                                    "fas",
                                                    "bars"
                                                  ],
                                                  className: "w-5 h-5 fill-current"
                                                }),
                                          "aria-label": "Navigation menu toggle",
                                          className: HeaderStyles.responsiveLeftButton,
                                          onClick: (function (param) {
                                              Curry._1(setIsOpen, (function (param) {
                                                      return !isOpen;
                                                    }));
                                            })
                                        }),
                                    JsxRuntime.jsx(Link, {
                                          href: "/",
                                          prefetch: false,
                                          children: JsxRuntime.jsx("a", {
                                                children: JsxRuntime.jsx("img", {
                                                      className: "w-7",
                                                      alt: "Prezzee Logo",
                                                      src: (require("../../public/images/prezzee_logo.svg"))
                                                    }),
                                                title: "Homepage"
                                              })
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(NavBarLinks.make, {
                                                visible: isOpen,
                                                setVisible: setIsOpen
                                              }),
                                          className: "hidden lg:block h-full w-11/12 lg:w-9/12 xl:w-8/12"
                                        }),
                                    match$2[0] ? JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx(NavBarUserDropdown.make, {}),
                                                    className: "hidden lg:block"
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    className: "w-1/3"
                                                  })
                                            ]
                                          }) : JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("button", {
                                                  children: JsxRuntime.jsx("img", {
                                                        className: "h-4 w-4",
                                                        alt: "user icon",
                                                        src: require("../../public/images/navbar_user_icon.svg")
                                                      }),
                                                  "aria-label": "Login Button",
                                                  className: Cn.make([
                                                        "h-12 w-12 border-1 rounded-full flex items-center justify-center lg:mr-5 xl:mr-8 transition-all duration-300 ease-in-out focus:outline-none",
                                                        isModalVisible ? "border-prezzee-red shadow-md bg-white-smoke" : "hover:border-prezzee-red hover:shadow-md hover:bg-white-smoke"
                                                      ]),
                                                  onClick: (function (e) {
                                                      e.preventDefault();
                                                      Curry._1(setModalVisible, (function (param) {
                                                              return !isModalVisible;
                                                            }));
                                                    })
                                                })
                                          })
                                  ],
                                  className: "flex items-center justify-between h-full px-5 lg:p-0 w-full"
                                }),
                            className: "inset-x-0 z-50 bg-white w-full lg:flex lg:justify-between lg:items-center lg:pl-10 h-18 border-b relative"
                          }),
                      isMenuOpen: isMenuOpen
                    }),
                JsxRuntime.jsx("div", {
                      children: isOpen ? JsxRuntime.jsx(NavBarLinksMobile.make, {
                              visible: isOpen,
                              setVisible: setIsOpen
                            }) : null,
                      className: "lg:hidden"
                    }),
                JsxRuntime.jsx(NavBarUserModal.make, {
                      visible: isModalVisible,
                      setVisible: setModalVisible
                    })
              ]
            });
}

var Link$1;

var make$1 = Header;

export {
  Link$1 as Link,
  HeaderContainer ,
  isMenuOpen ,
  make$1 as make,
}
/* make Not a pure module */
