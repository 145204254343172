// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

function terms(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          html_title: Json_decode.field("html_title", Json_decode.string, json),
          meta_description: Json_decode.field("meta_description", Json_decode.string, json),
          content: Json_decode.field("content", Json_decode.string, json)
        };
}

var Decode = {
  terms: terms
};

export {
  Decode ,
}
/* No side effect */
