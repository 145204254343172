// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__TextField__Fragment from "../TextField/Gql__TextField__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment LandingPageContactFormBlock on LandingPageContactFormContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    action  {
      __typename
      value  {
        __typename
        codename
      }
    }
    form_name  {
      __typename
      value
    }
    title  {
      __typename
      value
    }
    body_text  {
      __typename
      value
    }
    fields  {
      __typename
      value  {
        __typename
        ...on FormControlWrapperContentType   {
          __typename
          system  {
            __typename
            id
            codename
          }
          desktop_width  {
            __typename
            value
          }
          tablet_width  {
            __typename
            value
          }
          mobile_width  {
            __typename
            value
          }
          control  {
            __typename
            value  {
              __typename
              ...on TextFieldContentType   {
                ...TextField
              }
            }
          }
        }
      }
    }
    submit_cta_text  {
      __typename
      value
    }
    success_message_heading  {
      __typename
      value
    }
    success_message_text  {
      __typename
      value
    }
  }
  ${frag_0}
`)(Gql__TextField__Fragment.TextField.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.action;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.form_name;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.title;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.body_text;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.fields;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                if (typename === "FormControlWrapperContentType") {
                  var value$1 = value.system;
                  var value$2 = value.desktop_width;
                  var tmp$1;
                  if (value$2 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$3 = value$2.value;
                    tmp$1 = {
                      __typename: value$2.__typename,
                      value: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.tablet_width;
                  var tmp$2;
                  if (value$4 == null) {
                    tmp$2 = undefined;
                  } else {
                    var value$5 = value$4.value;
                    tmp$2 = {
                      __typename: value$4.__typename,
                      value: !(value$5 == null) ? value$5 : undefined
                    };
                  }
                  var value$6 = value.mobile_width;
                  var tmp$3;
                  if (value$6 == null) {
                    tmp$3 = undefined;
                  } else {
                    var value$7 = value$6.value;
                    tmp$3 = {
                      __typename: value$6.__typename,
                      value: !(value$7 == null) ? value$7 : undefined
                    };
                  }
                  var value$8 = value.control;
                  var tmp$4;
                  if (value$8 == null) {
                    tmp$4 = undefined;
                  } else {
                    var value$9 = value$8.value;
                    tmp$4 = {
                      __typename: value$8.__typename,
                      value: !(value$9 == null) ? Js_array.map((function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var typename = value["__typename"];
                                var tmp = typename === "TextFieldContentType" ? ({
                                      NAME: "TextFieldContentType",
                                      VAL: {
                                        textField: Gql__TextField__Fragment.TextField.verifyArgsAndParse("TextField", value)
                                      }
                                    }) : ({
                                      NAME: "UnspecifiedFragment",
                                      VAL: typename
                                    });
                                return tmp;
                              }), value$9) : undefined
                    };
                  }
                  tmp = {
                    NAME: "FormControlWrapperContentType",
                    VAL: {
                      __typename: value.__typename,
                      system: {
                        __typename: value$1.__typename,
                        id: value$1.id,
                        codename: value$1.codename
                      },
                      desktop_width: tmp$1,
                      tablet_width: tmp$2,
                      mobile_width: tmp$3,
                      control: tmp$4
                    }
                  };
                } else {
                  tmp = {
                    NAME: "UnspecifiedFragment",
                    VAL: typename
                  };
                }
                return tmp;
              }), value$11) : undefined
    };
  }
  var value$12 = value.submit_cta_text;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? value$13 : undefined
    };
  }
  var value$14 = value.success_message_heading;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.success_message_text;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          action: tmp,
          form_name: tmp$1,
          title: tmp$2,
          body_text: tmp$3,
          fields: tmp$4,
          submit_cta_text: tmp$5,
          success_message_heading: tmp$6,
          success_message_text: tmp$7
        };
}

function serialize(value) {
  var value$1 = value.success_message_text;
  var success_message_text;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    success_message_text = {
      __typename: value$4,
      value: value$3
    };
  } else {
    success_message_text = null;
  }
  var value$5 = value.success_message_heading;
  var success_message_heading;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? value$6 : null;
    var value$8 = value$5.__typename;
    success_message_heading = {
      __typename: value$8,
      value: value$7
    };
  } else {
    success_message_heading = null;
  }
  var value$9 = value.submit_cta_text;
  var submit_cta_text;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    submit_cta_text = {
      __typename: value$12,
      value: value$11
    };
  } else {
    submit_cta_text = null;
  }
  var value$13 = value.fields;
  var fields;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              }
              var value$1 = value.VAL;
              var value$2 = value$1.control;
              var control;
              if (value$2 !== undefined) {
                var value$3 = value$2.value;
                var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                          if (value !== undefined) {
                            if (value.NAME === "TextFieldContentType") {
                              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                            return {};
                                          }), [Gql__TextField__Fragment.TextField.serialize(value.VAL.textField)]);
                            } else {
                              return {};
                            }
                          } else {
                            return null;
                          }
                        }), value$3) : null;
                var value$5 = value$2.__typename;
                control = {
                  __typename: value$5,
                  value: value$4
                };
              } else {
                control = null;
              }
              var value$6 = value$1.mobile_width;
              var mobile_width;
              if (value$6 !== undefined) {
                var value$7 = value$6.value;
                var value$8 = value$7 !== undefined ? value$7 : null;
                var value$9 = value$6.__typename;
                mobile_width = {
                  __typename: value$9,
                  value: value$8
                };
              } else {
                mobile_width = null;
              }
              var value$10 = value$1.tablet_width;
              var tablet_width;
              if (value$10 !== undefined) {
                var value$11 = value$10.value;
                var value$12 = value$11 !== undefined ? value$11 : null;
                var value$13 = value$10.__typename;
                tablet_width = {
                  __typename: value$13,
                  value: value$12
                };
              } else {
                tablet_width = null;
              }
              var value$14 = value$1.desktop_width;
              var desktop_width;
              if (value$14 !== undefined) {
                var value$15 = value$14.value;
                var value$16 = value$15 !== undefined ? value$15 : null;
                var value$17 = value$14.__typename;
                desktop_width = {
                  __typename: value$17,
                  value: value$16
                };
              } else {
                desktop_width = null;
              }
              var value$18 = value$1.system;
              var value$19 = value$18.codename;
              var value$20 = value$18.id;
              var value$21 = value$18.__typename;
              var system = {
                __typename: value$21,
                id: value$20,
                codename: value$19
              };
              return {
                      __typename: "FormControlWrapperContentType",
                      system: system,
                      desktop_width: desktop_width,
                      tablet_width: tablet_width,
                      mobile_width: mobile_width,
                      control: control
                    };
            }), value$14) : null;
    var value$16 = value$13.__typename;
    fields = {
      __typename: value$16,
      value: value$15
    };
  } else {
    fields = null;
  }
  var value$17 = value.body_text;
  var body_text;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    body_text = {
      __typename: value$20,
      value: value$19
    };
  } else {
    body_text = null;
  }
  var value$21 = value.title;
  var title;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    title = {
      __typename: value$24,
      value: value$23
    };
  } else {
    title = null;
  }
  var value$25 = value.form_name;
  var form_name;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? value$26 : null;
    var value$28 = value$25.__typename;
    form_name = {
      __typename: value$28,
      value: value$27
    };
  } else {
    form_name = null;
  }
  var value$29 = value.action;
  var action;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$30) : null;
    var value$32 = value$29.__typename;
    action = {
      __typename: value$32,
      value: value$31
    };
  } else {
    action = null;
  }
  var value$33 = value.system;
  var value$34 = value$33.codename;
  var value$35 = value$33.id;
  var value$36 = value$33.__typename;
  var system = {
    __typename: value$36,
    id: value$35,
    codename: value$34
  };
  var value$37 = value.__typename;
  return {
          __typename: value$37,
          system: system,
          action: action,
          form_name: form_name,
          title: title,
          body_text: body_text,
          fields: fields,
          submit_cta_text: submit_cta_text,
          success_message_heading: success_message_heading,
          success_message_text: success_message_text
        };
}

function verifyArgsAndParse(_LandingPageContactFormBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var LandingPageContactFormBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var TextField;

export {
  TextField ,
  LandingPageContactFormBlock ,
}
/* query Not a pure module */
