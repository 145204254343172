// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var value = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.image, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var name = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.name;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.description;
            })), "");
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.url;
            })), "");
  var width = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.width;
            })), 0);
  var height = Belt_Option.getWithDefault(Belt_Option.flatMap(value, (function (f) {
              return f.height;
            })), 0);
  var result = {
    name: name,
    description: description,
    url: url,
    width: width,
    height: height
  };
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var value$1 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.video_mp4, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var video_mp4;
  if (value$1 !== undefined) {
    var name$1 = Belt_Option.getWithDefault(value$1.name, "");
    var description$2 = Belt_Option.getWithDefault(value$1.description, "");
    var url$1 = Belt_Option.getWithDefault(value$1.url, "");
    var width$1 = Belt_Option.getWithDefault(value$1.width, 0);
    var height$1 = Belt_Option.getWithDefault(value$1.height, 0);
    video_mp4 = {
      name: name$1,
      description: description$2,
      url: url$1,
      width: width$1,
      height: height$1
    };
  } else {
    video_mp4 = undefined;
  }
  var value$2 = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.video_webm, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var video_webm;
  if (value$2 !== undefined) {
    var name$2 = Belt_Option.getWithDefault(value$2.name, "");
    var description$3 = Belt_Option.getWithDefault(value$2.description, "");
    var url$2 = Belt_Option.getWithDefault(value$2.url, "");
    var width$2 = Belt_Option.getWithDefault(value$2.width, 0);
    var height$2 = Belt_Option.getWithDefault(value$2.height, 0);
    video_webm = {
      name: name$2,
      description: description$3,
      url: url$2,
      width: width$2,
      height: height$2
    };
  } else {
    video_webm = undefined;
  }
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          image: result,
          title: title,
          description: description$1,
          smartLink: smartLink,
          video_mp4: video_mp4,
          video_webm: video_webm
        };
}

export {
  get ,
}
/* No side effect */
