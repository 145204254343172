// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__PageBuilder__Decoder from "../PageBuilder/Gql__PageBuilder__Decoder.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "PageContentType") {
    return ;
  }
  var contentData = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.VAL.content, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var content$1 = contentData !== undefined && typeof contentData === "object" && contentData.NAME === "PageBuilderContentType" ? Gql__PageBuilder__Decoder.get(contentData.VAL.pageContent) : undefined;
  return {
          content: content$1
        };
}

var HeaderBlock;

var NavMenuBlock;

var FooterBlock;

var PageBuilder;

export {
  HeaderBlock ,
  NavMenuBlock ,
  FooterBlock ,
  PageBuilder ,
  get ,
}
/* Gql__PageBuilder__Decoder Not a pure module */
