// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FAQ__Decoder__Section from "./Gql__FAQ__Decoder__Section.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var faq_sections = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.faq_sections, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__FAQ__Decoder__Section.get);
  var additionalContent = Belt_Option.getWithDefault(Belt_Option.flatMap(c.additional_content, (function (c) {
              return c.value;
            })), "");
  var smart_link_itemId = c.system.id;
  var smart_link_codename = c.system.codename;
  var smart_link = {
    itemId: smart_link_itemId,
    codename: smart_link_codename
  };
  return {
          title: title,
          faq_sections: faq_sections,
          additionalContent: additionalContent,
          smart_link: smart_link
        };
}

var Section;

export {
  Section ,
  get ,
}
/* No side effect */
