// Generated by ReScript, PLEASE EDIT WITH CARE


var emptyAsset = {
  name: "",
  description: "",
  url: "",
  width: 0,
  height: 0
};

export {
  emptyAsset ,
}
/* No side effect */
