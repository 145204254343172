import Box from '@prezzee/ribbon-ui/lib/components/Box';
import Button from '@prezzee/ribbon-ui/lib/components/Button';
import { VStack } from '@prezzee/ribbon-ui/lib/components/Stack';
import Typography from '@prezzee/ribbon-ui/lib/components/Typography';
import LockIcon from '@prezzee/ribbon-ui/lib/components/icons/LockKeyholeIcon';
import ArrowRightIcon from '@prezzee/ribbon-ui/lib/components/icons/ArrowRightIcon';
import { useGrowthbookFeatureValue } from 'contexts/GrowthBookContext';
import useSegment from 'hooks/useSegment';
import { useRouter } from 'next/router';

// Pages we exclude from pesenting the Activate CTA Button
const EXCLUDED_PATHNAMES = ['/wallet', '/my-details', '/reminders', '/orders', '/login', '/register'];

const PrezzeeCampaignActivateButton = ({ displayWhen }) => {
  const businessCampaignRedeemCodeCtaEnabled = useGrowthbookFeatureValue('business-campaign-redeem-code-cta');
  const { customElementInteraction } = useSegment();
  const router = useRouter();

  if (!businessCampaignRedeemCodeCtaEnabled) return null;
  if (EXCLUDED_PATHNAMES.some(path => router.pathname.startsWith(path))) return null;

  const onClickCta = () => {
    customElementInteraction({
      journey: 'campaign_redeem_code',
      element: 'campaign_activate_cta',
      user_type: 'consumer',
    });
  };

  return (
    <Box
      as={VStack}
      alignItems="flex-start"
      mt="6"
      gap="3"
      gridColumn="1/-1"
      sx={{
        '@media (max-width: 720px)': {
          display: displayWhen === 'mobile' ? 'flex' : 'none',
        },
        '@media (min-width: 728px)': {
          display: displayWhen === 'tabletOrDesktop' ? 'flex' : 'none',
        },
      }}
    >
      <Typography variant="body1" fontWeight="normal" textColor="gray.800">
        Looking for me?
      </Typography>
      <a href="/code" target="_blank" onClick={onClickCta}>
        <Button variant="secondary" leftIcon={<LockIcon />} rightIcon={<ArrowRightIcon />}>
          <Box as="span" mr="1" mb="0.5">
            Activate my gift
          </Box>
        </Button>
      </a>
    </Box>
  );
};

export default PrezzeeCampaignActivateButton;
