// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as PrezzeePublicClient from "../api/PrezzeePublicClient.bs.js";

function getFieldError(errors, name) {
  return Belt_Option.map(Js_dict.get(errors, name), (function (err) {
                return err.message;
              }));
}

function getFieldErrorWithDefault(errors, name, defaultMsg) {
  var error = Js_dict.get(errors, name);
  if (error !== undefined) {
    return Caml_option.some(defaultMsg);
  }
  
}

function getValue(value) {
  return Belt_Option.getWithDefault((value == null) ? undefined : Caml_option.some(value), "");
}

function onError(param) {
  
}

function getError(touched, fieldName, errors) {
  if (touched == null) {
    return ;
  }
  var tt = Js_dict.get(touched, fieldName);
  if (Belt_Option.isSome(tt)) {
    return getFieldError(errors, fieldName);
  }
  
}

function hasError(error) {
  return Belt_Option.getWithDefault(error, "") !== "";
}

function getPhoneControllerOptions(control, name, isRequired) {
  return {
          name: name,
          control: control,
          rules: {
            validate: (async function (value) {
                if (value == null) {
                  return false;
                }
                var match = value.number.trim();
                if (match === "") {
                  return !isRequired;
                }
                try {
                  var response = await PrezzeePublicClient.validatePhoneNumber(match);
                  if (response.TAG === /* Ok */0) {
                    return true;
                  } else {
                    return false;
                  }
                }
                catch (exn){
                  return false;
                }
              })
          },
          defaultValue: null
        };
}

function getCountryCode(value) {
  return Belt_Option.mapWithDefault((value == null) ? undefined : Caml_option.some(value), "", (function (a) {
                return a.countryCode;
              }));
}

function getPhoneNumber(value) {
  return Belt_Option.mapWithDefault((value == null) ? undefined : Caml_option.some(value), "", (function (a) {
                return a.number;
              }));
}

function getTouchedError(form, name) {
  var touched = form.formState.touched;
  if (touched == null) {
    return ;
  }
  var t = Js_dict.get(touched, name);
  if (!Belt_Option.isSome(t)) {
    return ;
  }
  var err = Js_dict.get(form.errors, name);
  return Belt_Option.map(err, (function (e) {
                return e.message;
              }));
}

function getPhoneError(errors, value) {
  var err = Js_dict.get(errors, "phoneNumber");
  return Belt_Option.map(err, (function (param) {
                if (getPhoneNumber(value) === "") {
                  return "Phone number is required";
                } else {
                  return "Please enter a valid mobile number";
                }
              }));
}

function getPhoneTouchedError(form, value) {
  var err = getTouchedError(form, "phoneNumber");
  return Belt_Option.map(err, (function (param) {
                if (getPhoneNumber(value) === "") {
                  return "Phone number is required";
                } else {
                  return "Please enter a valid mobile number";
                }
              }));
}

export {
  getFieldError ,
  getFieldErrorWithDefault ,
  getValue ,
  onError ,
  getError ,
  hasError ,
  getPhoneControllerOptions ,
  getCountryCode ,
  getPhoneNumber ,
  getTouchedError ,
  getPhoneError ,
  getPhoneTouchedError ,
}
/* PrezzeePublicClient Not a pure module */
