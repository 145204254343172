// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__SkuPresentation__Fragment from "./Gql__SkuPresentation__Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  query PageContent($language: String!, $preview: Boolean, $codenames: [String])  {
    itemsByType(type: "sku_presentation", depth: 5, language: $language, preview: $preview, filters: [{type: IN_, prop: "system.codename", list: $codenames}])  {
      __typename
      ...on SkuPresentationContentType   {
        ...SkuPresentationFragment
      }
    }
  }
  ${frag_0}
`)(Gql__SkuPresentation__Fragment.SkuPresentationFragment.query);

function parse(value) {
  var value$1 = value.itemsByType;
  return {
          itemsByType: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp = typename === "SkuPresentationContentType" ? ({
                          NAME: "SkuPresentationContentType",
                          VAL: {
                            skuPresentationFragment: Gql__SkuPresentation__Fragment.SkuPresentationFragment.verifyArgsAndParse("SkuPresentationFragment", value)
                          }
                        }) : ({
                          NAME: "UnspecifiedFragment",
                          VAL: typename
                        });
                    return tmp;
                  }), value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.itemsByType;
  var itemsByType = value$1 !== undefined ? Js_array.map((function (value) {
            if (value !== undefined) {
              if (value.NAME === "UnspecifiedFragment") {
                return {};
              } else {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__SkuPresentation__Fragment.SkuPresentationFragment.serialize(value.VAL.skuPresentationFragment)]);
              }
            } else {
              return null;
            }
          }), value$1) : null;
  return {
          itemsByType: itemsByType
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  var a$1 = inp.codenames;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined,
          codenames: a$1 !== undefined ? Js_array.map((function (b) {
                    if (b !== undefined) {
                      return b;
                    }
                    
                  }), a$1) : undefined
        };
}

function makeVariables(language, preview, codenames, param) {
  return {
          language: language,
          preview: preview,
          codenames: codenames
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var SkuPresentationFragment;

export {
  SkuPresentationFragment ,
  Query ,
}
/* query Not a pure module */
