import React, { createContext, useContext, ReactNode, useState } from 'react';

export enum ErrorCodes {
  DEFAULT = '000',
  ERR_OUT_OF_STOCK = '532',
  ERR_OUT_OF_STOCK_KEEP_LOWER = '532-Keep',
  ERR_OUT_OF_STOCK_SWAP_LOWER = '532-Swap',
}
type Message = {
  title?: string;
  message?: string;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  onClose?: () => void;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
};

type ErrorCode = { errorCode: ErrorCodes; message?: Message };

type Error = ErrorCode | { errorCode?: string; message: Message };

type ErrorModalProps = {
  errorMessage: Message | null;
  showErrorModal: (error: Error) => void;
  hideErrorModal: () => void;
};

const ErrorModalContext = createContext<ErrorModalProps | undefined>(undefined);

export function useErrorModal() {
  const context = useContext(ErrorModalContext);

  if (!context) {
    throw new Error('useErrorModal must be used within an ErrorModalProvider');
  }
  return context;
}

type ErrorModalProviderProps = {
  children: ReactNode;
};

export function ErrorModalProvider({ children }: ErrorModalProviderProps) {
  const [errorMessage, setErrorMessage] = useState<Message | null>(null);

  const errorMessages: Record<ErrorCodes, Message> = {
    [ErrorCodes.ERR_OUT_OF_STOCK]: {
      title: 'This brand is temporarily unavailable',
      message: 'Go back and try again tomorrow or, alternatively, swap it for another brand of your choice.',
      primaryButtonText: 'Choose another brand',
      secondaryButtonText: 'Go back',
    },
    [ErrorCodes.ERR_OUT_OF_STOCK_KEEP_LOWER]: {
      title: 'Card value is currently unavailable',
      message:
        'You can keep a smaller amount of it, swap it for another brand card or close this window and try again later.',
      primaryButtonText: 'Swap it for another brand ',
      secondaryButtonText: 'Keep a smaller amount',
    },
    [ErrorCodes.ERR_OUT_OF_STOCK_SWAP_LOWER]: {
      title: 'Card value isn’t available',
      message:
        'This brand card is only available at a lower value. You can swap a smaller amount and keep the remaining balance on your original card, or choose another brand.',
      primaryButtonText: 'Swap it for another brand ',
      secondaryButtonText: 'Swap a smaller amount',
    },
    [ErrorCodes.DEFAULT]: {
      title: 'We couldn’t complete your swap',
      message: 'Try swapping your card for another brand, or, alternatively, go back and try again later.',
      primaryButtonText: 'Choose another brand',
      secondaryButtonText: 'Go back',
    },
  };

  const showErrorModal = (error: Error) => {
    let message = errorMessages[ErrorCodes.DEFAULT];
    if (error.errorCode && error.errorCode in errorMessages) {
      message = {
        ...errorMessages[error.errorCode],
        ...error.message,
      };
    } else if (error['message']) {
      message = {
        ...message,
        ...error.message,
      };
    }
    setErrorMessage(message);
  };

  const hideErrorModal = () => {
    setErrorMessage(null);
  };

  const value = {
    errorMessage,
    showErrorModal,
    hideErrorModal,
  };

  return <ErrorModalContext.Provider value={value}>{children}</ErrorModalContext.Provider>;
}
