// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__CallToAction__Decoder from "../CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Footer__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Theme.bs.js";
import * as Gql__Footer__Decoder__AppStore from "./Gql__Footer__Decoder__AppStore.bs.js";
import * as Gql__Footer__Decoder__LinkColumn from "./Gql__Footer__Decoder__LinkColumn.bs.js";

var emptyFooter_link_columns = [];

var emptyFooter_subscribe_cta_style = Gql__CallToAction__Decoder.parse_cta_style("primary_regular");

var emptyFooter_app_stores_container = [];

var emptyFooter_theme = Footer__Theme$PrezzeeRibbon.t(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var emptyFooter = {
  link_columns: emptyFooter_link_columns,
  subscribe_title: "",
  subscribe_description: "",
  subscribe_helper_text: "",
  subscribe_cta: "",
  subscribe_cta_style: emptyFooter_subscribe_cta_style,
  region_selector_text: "",
  app_store_title: "",
  app_stores_container: emptyFooter_app_stores_container,
  smartLink: SmartLink.emptySmartLink,
  theme: emptyFooter_theme,
  disclaimer_level_1: "",
  disclaimer_level_2: ""
};

function get(footer) {
  var link_columns = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(footer.link_columns, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__Footer__Decoder__LinkColumn.get);
  var subscribe_title = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.subscribe_title, (function (c) {
              return c.value;
            })), "");
  var subscribe_helper_text = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.subscribe_helper_text, (function (c) {
              return c.value;
            })), "");
  var subscribe_cta = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.subscribe_cta, (function (c) {
              return c.value;
            })), "");
  var subscribe_cta_style = Gql__CallToAction__Decoder.parse_cta_style(Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(footer.cta_style__style, (function (c) {
                                  return c.value;
                                })), []), GqlUtils.id), (function (v) {
                      return v.codename;
                    })), 0), "primary_regular"));
  var subscribe_description = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.subscribe_description, (function (c) {
              return c.value;
            })), "");
  var region_selector_text = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.region_selector_text, (function (c) {
              return c.value;
            })), "");
  var app_store_title = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.app_store_title, (function (c) {
              return c.value;
            })), "");
  var app_stores_container = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(footer.app_stores_container, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__Footer__Decoder__AppStore.get);
  var smartLink_itemId = footer.system.id;
  var smartLink_codename = footer.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var background_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.background_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var heading_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.heading_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var body_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.body_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var body_hover_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.body_hover_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var icon_background_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.icon_background_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var icon_hover_background_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.icon_hover_background_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var icon_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.icon_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var icon_hover_color = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(footer.icon_hover_colour, (function (c) {
                  return c.value;
                })), GqlUtils.ifNotEmpty), (function (c) {
          return {
                  NAME: "hex",
                  VAL: c
                };
        }));
  var disclaimer_level_1 = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.footer_disclaimer_level_1, (function (c) {
              return c.value;
            })), "");
  var disclaimer_level_2 = Belt_Option.getWithDefault(Belt_Option.flatMap(footer.footer_disclaimer_level_2, (function (c) {
              return c.value;
            })), "");
  var theme = Footer__Theme$PrezzeeRibbon.t(background_color, heading_color, body_color, body_hover_color, icon_background_color, icon_hover_background_color, icon_color, icon_hover_color, undefined);
  return {
          link_columns: link_columns,
          subscribe_title: subscribe_title,
          subscribe_description: subscribe_description,
          subscribe_helper_text: subscribe_helper_text,
          subscribe_cta: subscribe_cta,
          subscribe_cta_style: subscribe_cta_style,
          region_selector_text: region_selector_text,
          app_store_title: app_store_title,
          app_stores_container: app_stores_container,
          smartLink: smartLink,
          theme: theme,
          disclaimer_level_1: disclaimer_level_1,
          disclaimer_level_2: disclaimer_level_2
        };
}

var LinkColumn;

var AppStore;

export {
  LinkColumn ,
  AppStore ,
  emptyFooter ,
  get ,
}
/* emptyFooter Not a pure module */
