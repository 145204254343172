// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function securityCode(l) {
  return Json_encode.object_({
              hd: [
                "email",
                l.email
              ],
              tl: {
                hd: [
                  "phone",
                  l.phoneNumber
                ],
                tl: /* [] */0
              }
            });
}

var Encode = {
  securityCode: securityCode
};

export {
  Encode ,
}
/* No side effect */
