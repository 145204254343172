// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__FullWidthHeroBanner__Decoder__ScrollingTextItem from "./Gql__FullWidthHeroBanner__Decoder__ScrollingTextItem.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "TextListContentType") {
    return ;
  }
  var c = content.VAL;
  var items = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_list_items, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__FullWidthHeroBanner__Decoder__ScrollingTextItem.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          items: items,
          smartLink: smartLink
        };
}

var ScrollingTextItem;

export {
  ScrollingTextItem ,
  get ,
}
/* No side effect */
