// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function initValue_1(param) {
  
}

var initValue = [
  /* Guest */0,
  initValue_1
];

var context = React.createContext(initValue);

function useUser(param) {
  return React.useContext(context);
}

function reducer(state, action) {
  if (typeof action === "number") {
    return /* Guest */0;
  }
  if (action.TAG === /* Login */0) {
    return /* Authenticated */{
            _0: action._0
          };
  }
  if (!state) {
    return state;
  }
  var userInfo = state._0;
  return /* Authenticated */{
          _0: {
            firstName: action._0,
            lastName: action._1,
            email: userInfo.email,
            phoneNumber: userInfo.phoneNumber
          }
        };
}

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var UserProvider = {
  makeProps: makeProps,
  make: make
};

function isAuthenticated(x) {
  if (x) {
    return true;
  } else {
    return false;
  }
}

export {
  initValue ,
  context ,
  useUser ,
  reducer ,
  UserProvider ,
  isAuthenticated ,
}
/* context Not a pure module */
