// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function decodeFontAwesomeStyle(style) {
  switch (style) {
    case "brands_style__fab_" :
        return "fab";
    case "duotone_style__fad_" :
        return "fad";
    case "light_style__fal_" :
        return "fal";
    case "regular_style__far_" :
        return "far";
    case "solid_style__fas_" :
        return "fas";
    default:
      return style;
  }
}

function get(content) {
  var icon = Belt_Option.getWithDefault(Belt_Option.flatMap(content.font_awesome_icon_name, (function (c) {
              return c.value;
            })), "");
  var style = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(content.style, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), "");
  return {
          icon: icon,
          style: decodeFontAwesomeStyle(style)
        };
}

export {
  decodeFontAwesomeStyle ,
  get ,
}
/* No side effect */
