// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__FAQ__Fragment from "../FAQ/Gql__FAQ__Fragment.bs.js";
import * as Gql__SeoMeta__Fragment from "../SeoMetaContentType/Gql__SeoMeta__Fragment.bs.js";
import * as Gql__HeroTiles__Fragment from "../HeroTiles/Gql__HeroTiles__Fragment.bs.js";
import * as Gql__NarrowCta__Fragment from "../NarrowCta/Gql__NarrowCta__Fragment.bs.js";
import * as Gql__HeroBanner__Fragment from "../HeroBanner/Gql__HeroBanner__Fragment.bs.js";
import * as Gql__PromoTiles__Fragment from "../PromoTiles/Gql__PromoTiles__Fragment.bs.js";
import * as Gql__StatsTiles__Fragment from "../StatsTiles/Gql__StatsTiles__Fragment.bs.js";
import * as Gql__SkuCarousel__Fragment from "../SkuCarousel/Gql__SkuCarousel__Fragment.bs.js";
import * as Gql__StoreContent__Fragment from "../StoreContentContentType/Gql__StoreContent__Fragment.bs.js";
import * as Gql__CategoryTiles__Fragment from "../CategoryTiles/Gql__CategoryTiles__Fragment.bs.js";
import * as Gql__IconCardBlock__Fragment from "../IconCardBlock/Gql__IconCardBlock__Fragment.bs.js";
import * as Gql__GiftExperience__Fragment from "../GiftExperience/Gql__GiftExperience__Fragment.bs.js";
import * as Gql__VideoTextPromo__Fragment from "../VideoTextPromo/Gql__VideoTextPromo__Fragment.bs.js";
import * as Gql__NewsletterBlock__Fragment from "../NewsletterBlock/Gql__NewsletterBlock__Fragment.bs.js";
import * as Gql__AutomateSimplify__Fragment from "../AutomateSimplify/Gql__AutomateSimplify__Fragment.bs.js";
import * as Gql__NarrowBannerFull__Fragment from "../NarrowBannerFull/Gql__NarrowBannerFull__Fragment.bs.js";
import * as Gql__OffsetTilesBlock__Fragment from "../OffsetTilesBlock/Gql__OffsetTilesBlock__Fragment.bs.js";
import * as Gql__ProductHeroBlock__Fragment from "../ProductHeroBlock/Gql__ProductHeroBlock__Fragment.bs.js";
import * as Gql__TeamMembersBlock__Fragment from "../TeamMembersBlock/Gql__TeamMembersBlock__Fragment.bs.js";
import * as Gql__DownloadAppsBlock__Fragment from "../DownloadAppsBlock/Gql__DownloadAppsBlock__Fragment.bs.js";
import * as Gql__EnrichedTextBlock__Fragment from "../EnrichedTextBlock/Gql__EnrichedTextBlock__Fragment.bs.js";
import * as Gql__CustomerCareBanner__Fragment from "../CustomerCareBanner/Gql__CustomerCareBanner__Fragment.bs.js";
import * as Gql__CustomerCareCallUs__Fragment from "../CustomerCareCallUs/Gql__CustomerCareCallUs__Fragment.bs.js";
import * as Gql__FullWidthTextBlock__Fragment from "../FullWidthTextBlock/Gql__FullWidthTextBlock__Fragment.bs.js";
import * as Gql__SellingPointsBlock__Fragment from "../SellingPointsBlock/Gql__SellingPointsBlock__Fragment.bs.js";
import * as Gql__BusinessContactForm__Fragment from "../BusinessContactForm/Gql__BusinessContactForm__Fragment.bs.js";
import * as Gql__FullWidthHeroBanner__Fragment from "../FullWidthHeroBanner/Gql__FullWidthHeroBanner__Fragment.bs.js";
import * as Gql__InnovativeWayToGift__Fragment from "../InnovativeWayToGift/Gql__InnovativeWayToGift__Fragment.bs.js";
import * as Gql__BoardOfDirectorsBlock__Fragment from "../BoardOfDirectorsBlock/Gql__BoardOfDirectorsBlock__Fragment.bs.js";
import * as Gql__TrustpilotWidgetBlock__Fragment from "../TrustpilotWidgetBlock/Gql__TrustpilotWidgetBlock__Fragment.bs.js";
import * as Gql__LandingPageContactForm__Fragment from "../LandingPageContactForm/Gql__LandingPageContactForm__Fragment.bs.js";
import * as Gql__TestimonialCarouselBlock__Fragment from "../TestimonialCarouselBlock/Gql__TestimonialCarouselBlock__Fragment.bs.js";
import * as Gql__UniqueSellingPointsBlock__Fragment from "../UniqueSellingPointsBlock/Gql__UniqueSellingPointsBlock__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1, frag_2, frag_3, frag_4, frag_5, frag_6, frag_7, frag_8, frag_9, frag_10, frag_11, frag_12, frag_13, frag_14, frag_15, frag_16, frag_17, frag_18, frag_19, frag_20, frag_21, frag_22, frag_23, frag_24, frag_25, frag_26, frag_27, frag_28, frag_29, frag_30, frag_31, frag_32) => require("@apollo/client").gql`
  fragment PageContent on PageBuilderContentType   {
    __typename
    system  {
      __typename
      codename
      language
    }
    seo_meta  {
      __typename
      value  {
        __typename
        ...on SeoMetaContentType   {
          ...SeoMetaBlock
        }
      }
    }
    store_content  {
      __typename
      value  {
        __typename
        ...on StoreContentContentType   {
          ...StoreContentBlock
        }
      }
    }
    content_blocks  {
      __typename
      value  {
        __typename
        ...on CategoryTileBlockContentType   {
          ...CategoryTileBlock
        }
        ...on AppDownloadBlockContentType   {
          ...AppDownloadBlock
        }
        ...on FullWidthTextBlockContentType   {
          ...FullWidthTextBlock
        }
        ...on EmailSignUpBlockContentType   {
          ...NewsletterBlock
        }
        ...on HeroTileBlockContentType   {
          ...HeroTileBlock
        }
        ...on UniqueSellingPointsBlockContentType   {
          ...UniqueSellingPointsBlock
        }
        ...on PromoTileBlockContentType   {
          ...PromoTileBlock
        }
        ...on EnvironmentalImpactsBlockContentType   {
          ...StatsTilesBlock
        }
        ...on VideoTextPromoBoxContentType   {
          ...VideoTextPromoBlock
        }
        ...on SkuCarouselBlockContentType   {
          ...SkuCarouselBlock
        }
        ...on TeamMembersBlockContentType   {
          ...TeamMembersBlock
        }
        ...on CustomerCareBannerContentType   {
          ...CustomerCareBannerBlock
        }
        ...on CustomerCareCallUsContentType   {
          ...CustomerCareCallUsBlock
        }
        ...on BoardOfDirectorsBlockContentType   {
          ...BoardOfDirectorsBlock
        }
        ...on FullWidthHeroContentType   {
          ...FullWidthHeroBannerBlock
        }
        ...on InnovativeWayToGiftContentType   {
          ...InnovativeWayToGiftBlock
        }
        ...on IconCardBlockContentType   {
          ...IconCardBlock
        }
        ...on TheGiftExperienceContentType   {
          ...GiftExperienceBlock
        }
        ...on TestimonialCarouselBlockContentType   {
          ...TestimonialCarouselBlock
        }
        ...on BusinessContactFormContentType   {
          ...BusinessContactFormBlock
        }
        ...on AutomateAndSimplifyContentType   {
          ...AutomateSimplifyBlock
        }
        ...on OffsetTilesBlockContentType   {
          ...OffsetTilesBlock
        }
        ...on FaqBlockContentType   {
          ...FAQBlock
        }
        ...on LandingPageContactFormContentType   {
          ...LandingPageContactFormBlock
        }
        ...on EnrichedTextBlockContentType   {
          ...EnrichedTextBlock
        }
        ...on TrustpilotWidgetBlockContentType   {
          ...TrustpilotWidgetBlock
        }
        ...on NarrowBannerFullContentType   {
          ...NarrowBannerFull
        }
        ...on HeroBannerContentType   {
          ...HeroBanner
        }
        ...on ProductHeroBlockContentType   {
          ...ProductHeroBlock
        }
        ...on SellingPointsBlockContentType   {
          ...SellingPointsBlock
        }
        ...on NarrowCtaContentType   {
          ...NarrowCta
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
  ${frag_2}
  ${frag_3}
  ${frag_4}
  ${frag_5}
  ${frag_6}
  ${frag_7}
  ${frag_8}
  ${frag_9}
  ${frag_10}
  ${frag_11}
  ${frag_12}
  ${frag_13}
  ${frag_14}
  ${frag_15}
  ${frag_16}
  ${frag_17}
  ${frag_18}
  ${frag_19}
  ${frag_20}
  ${frag_21}
  ${frag_22}
  ${frag_23}
  ${frag_24}
  ${frag_25}
  ${frag_26}
  ${frag_27}
  ${frag_28}
  ${frag_29}
  ${frag_30}
  ${frag_31}
  ${frag_32}
`)(Gql__DownloadAppsBlock__Fragment.AppDownloadBlock.query, Gql__AutomateSimplify__Fragment.AutomateSimplifyBlock.query, Gql__BoardOfDirectorsBlock__Fragment.BoardOfDirectorsBlock.query, Gql__BusinessContactForm__Fragment.BusinessContactFormBlock.query, Gql__CategoryTiles__Fragment.CategoryTileBlock.query, Gql__CustomerCareBanner__Fragment.CustomerCareBannerBlock.query, Gql__CustomerCareCallUs__Fragment.CustomerCareCallUsBlock.query, Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.query, Gql__FAQ__Fragment.FAQBlock.query, Gql__FullWidthHeroBanner__Fragment.FullWidthHeroBannerBlock.query, Gql__FullWidthTextBlock__Fragment.FullWidthTextBlock.query, Gql__GiftExperience__Fragment.GiftExperienceBlock.query, Gql__HeroBanner__Fragment.HeroBanner.query, Gql__HeroTiles__Fragment.HeroTileBlock.query, Gql__IconCardBlock__Fragment.IconCardBlock.query, Gql__InnovativeWayToGift__Fragment.InnovativeWayToGiftBlock.query, Gql__LandingPageContactForm__Fragment.LandingPageContactFormBlock.query, Gql__NarrowBannerFull__Fragment.NarrowBannerFull.query, Gql__NarrowCta__Fragment.NarrowCta.query, Gql__NewsletterBlock__Fragment.NewsletterBlock.query, Gql__OffsetTilesBlock__Fragment.OffsetTilesBlock.query, Gql__ProductHeroBlock__Fragment.ProductHeroBlock.query, Gql__PromoTiles__Fragment.PromoTileBlock.query, Gql__SellingPointsBlock__Fragment.SellingPointsBlock.query, Gql__SeoMeta__Fragment.SeoMetaBlock.query, Gql__SkuCarousel__Fragment.SkuCarouselBlock.query, Gql__StatsTiles__Fragment.StatsTilesBlock.query, Gql__StoreContent__Fragment.StoreContentBlock.query, Gql__TeamMembersBlock__Fragment.TeamMembersBlock.query, Gql__TestimonialCarouselBlock__Fragment.TestimonialCarouselBlock.query, Gql__TrustpilotWidgetBlock__Fragment.TrustpilotWidgetBlock.query, Gql__UniqueSellingPointsBlock__Fragment.UniqueSellingPointsBlock.query, Gql__VideoTextPromo__Fragment.VideoTextPromoBlock.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.seo_meta;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "SeoMetaContentType" ? ({
                      NAME: "SeoMetaContentType",
                      VAL: {
                        seoMetaBlock: Gql__SeoMeta__Fragment.SeoMetaBlock.verifyArgsAndParse("SeoMetaBlock", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$3) : undefined
    };
  }
  var value$4 = value.store_content;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "StoreContentContentType" ? ({
                      NAME: "StoreContentContentType",
                      VAL: {
                        storeContentBlock: Gql__StoreContent__Fragment.StoreContentBlock.verifyArgsAndParse("StoreContentBlock", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$5) : undefined
    };
  }
  var value$6 = value.content_blocks;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                switch (typename) {
                  case "AppDownloadBlockContentType" :
                      tmp = {
                        NAME: "AppDownloadBlockContentType",
                        VAL: {
                          appDownloadBlock: Gql__DownloadAppsBlock__Fragment.AppDownloadBlock.verifyArgsAndParse("AppDownloadBlock", value)
                        }
                      };
                      break;
                  case "AutomateAndSimplifyContentType" :
                      tmp = {
                        NAME: "AutomateAndSimplifyContentType",
                        VAL: {
                          automateSimplifyBlock: Gql__AutomateSimplify__Fragment.AutomateSimplifyBlock.verifyArgsAndParse("AutomateSimplifyBlock", value)
                        }
                      };
                      break;
                  case "BoardOfDirectorsBlockContentType" :
                      tmp = {
                        NAME: "BoardOfDirectorsBlockContentType",
                        VAL: {
                          boardOfDirectorsBlock: Gql__BoardOfDirectorsBlock__Fragment.BoardOfDirectorsBlock.verifyArgsAndParse("BoardOfDirectorsBlock", value)
                        }
                      };
                      break;
                  case "BusinessContactFormContentType" :
                      tmp = {
                        NAME: "BusinessContactFormContentType",
                        VAL: {
                          businessContactFormBlock: Gql__BusinessContactForm__Fragment.BusinessContactFormBlock.verifyArgsAndParse("BusinessContactFormBlock", value)
                        }
                      };
                      break;
                  case "CategoryTileBlockContentType" :
                      tmp = {
                        NAME: "CategoryTileBlockContentType",
                        VAL: {
                          categoryTileBlock: Gql__CategoryTiles__Fragment.CategoryTileBlock.verifyArgsAndParse("CategoryTileBlock", value)
                        }
                      };
                      break;
                  case "CustomerCareBannerContentType" :
                      tmp = {
                        NAME: "CustomerCareBannerContentType",
                        VAL: {
                          customerCareBannerBlock: Gql__CustomerCareBanner__Fragment.CustomerCareBannerBlock.verifyArgsAndParse("CustomerCareBannerBlock", value)
                        }
                      };
                      break;
                  case "CustomerCareCallUsContentType" :
                      tmp = {
                        NAME: "CustomerCareCallUsContentType",
                        VAL: {
                          customerCareCallUsBlock: Gql__CustomerCareCallUs__Fragment.CustomerCareCallUsBlock.verifyArgsAndParse("CustomerCareCallUsBlock", value)
                        }
                      };
                      break;
                  case "EmailSignUpBlockContentType" :
                      tmp = {
                        NAME: "EmailSignUpBlockContentType",
                        VAL: {
                          newsletterBlock: Gql__NewsletterBlock__Fragment.NewsletterBlock.verifyArgsAndParse("NewsletterBlock", value)
                        }
                      };
                      break;
                  case "EnrichedTextBlockContentType" :
                      tmp = {
                        NAME: "EnrichedTextBlockContentType",
                        VAL: {
                          enrichedTextBlock: Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.verifyArgsAndParse("EnrichedTextBlock", value)
                        }
                      };
                      break;
                  case "EnvironmentalImpactsBlockContentType" :
                      tmp = {
                        NAME: "EnvironmentalImpactsBlockContentType",
                        VAL: {
                          statsTilesBlock: Gql__StatsTiles__Fragment.StatsTilesBlock.verifyArgsAndParse("StatsTilesBlock", value)
                        }
                      };
                      break;
                  case "FaqBlockContentType" :
                      tmp = {
                        NAME: "FaqBlockContentType",
                        VAL: {
                          fAQBlock: Gql__FAQ__Fragment.FAQBlock.verifyArgsAndParse("FAQBlock", value)
                        }
                      };
                      break;
                  case "FullWidthHeroContentType" :
                      tmp = {
                        NAME: "FullWidthHeroContentType",
                        VAL: {
                          fullWidthHeroBannerBlock: Gql__FullWidthHeroBanner__Fragment.FullWidthHeroBannerBlock.verifyArgsAndParse("FullWidthHeroBannerBlock", value)
                        }
                      };
                      break;
                  case "FullWidthTextBlockContentType" :
                      tmp = {
                        NAME: "FullWidthTextBlockContentType",
                        VAL: {
                          fullWidthTextBlock: Gql__FullWidthTextBlock__Fragment.FullWidthTextBlock.verifyArgsAndParse("FullWidthTextBlock", value)
                        }
                      };
                      break;
                  case "HeroBannerContentType" :
                      tmp = {
                        NAME: "HeroBannerContentType",
                        VAL: {
                          heroBanner: Gql__HeroBanner__Fragment.HeroBanner.verifyArgsAndParse("HeroBanner", value)
                        }
                      };
                      break;
                  case "HeroTileBlockContentType" :
                      tmp = {
                        NAME: "HeroTileBlockContentType",
                        VAL: {
                          heroTileBlock: Gql__HeroTiles__Fragment.HeroTileBlock.verifyArgsAndParse("HeroTileBlock", value)
                        }
                      };
                      break;
                  case "IconCardBlockContentType" :
                      tmp = {
                        NAME: "IconCardBlockContentType",
                        VAL: {
                          iconCardBlock: Gql__IconCardBlock__Fragment.IconCardBlock.verifyArgsAndParse("IconCardBlock", value)
                        }
                      };
                      break;
                  case "InnovativeWayToGiftContentType" :
                      tmp = {
                        NAME: "InnovativeWayToGiftContentType",
                        VAL: {
                          innovativeWayToGiftBlock: Gql__InnovativeWayToGift__Fragment.InnovativeWayToGiftBlock.verifyArgsAndParse("InnovativeWayToGiftBlock", value)
                        }
                      };
                      break;
                  case "LandingPageContactFormContentType" :
                      tmp = {
                        NAME: "LandingPageContactFormContentType",
                        VAL: {
                          landingPageContactFormBlock: Gql__LandingPageContactForm__Fragment.LandingPageContactFormBlock.verifyArgsAndParse("LandingPageContactFormBlock", value)
                        }
                      };
                      break;
                  case "NarrowBannerFullContentType" :
                      tmp = {
                        NAME: "NarrowBannerFullContentType",
                        VAL: {
                          narrowBannerFull: Gql__NarrowBannerFull__Fragment.NarrowBannerFull.verifyArgsAndParse("NarrowBannerFull", value)
                        }
                      };
                      break;
                  case "NarrowCtaContentType" :
                      tmp = {
                        NAME: "NarrowCtaContentType",
                        VAL: {
                          narrowCta: Gql__NarrowCta__Fragment.NarrowCta.verifyArgsAndParse("NarrowCta", value)
                        }
                      };
                      break;
                  case "OffsetTilesBlockContentType" :
                      tmp = {
                        NAME: "OffsetTilesBlockContentType",
                        VAL: {
                          offsetTilesBlock: Gql__OffsetTilesBlock__Fragment.OffsetTilesBlock.verifyArgsAndParse("OffsetTilesBlock", value)
                        }
                      };
                      break;
                  case "ProductHeroBlockContentType" :
                      tmp = {
                        NAME: "ProductHeroBlockContentType",
                        VAL: {
                          productHeroBlock: Gql__ProductHeroBlock__Fragment.ProductHeroBlock.verifyArgsAndParse("ProductHeroBlock", value)
                        }
                      };
                      break;
                  case "PromoTileBlockContentType" :
                      tmp = {
                        NAME: "PromoTileBlockContentType",
                        VAL: {
                          promoTileBlock: Gql__PromoTiles__Fragment.PromoTileBlock.verifyArgsAndParse("PromoTileBlock", value)
                        }
                      };
                      break;
                  case "SellingPointsBlockContentType" :
                      tmp = {
                        NAME: "SellingPointsBlockContentType",
                        VAL: {
                          sellingPointsBlock: Gql__SellingPointsBlock__Fragment.SellingPointsBlock.verifyArgsAndParse("SellingPointsBlock", value)
                        }
                      };
                      break;
                  case "SkuCarouselBlockContentType" :
                      tmp = {
                        NAME: "SkuCarouselBlockContentType",
                        VAL: {
                          skuCarouselBlock: Gql__SkuCarousel__Fragment.SkuCarouselBlock.verifyArgsAndParse("SkuCarouselBlock", value)
                        }
                      };
                      break;
                  case "TeamMembersBlockContentType" :
                      tmp = {
                        NAME: "TeamMembersBlockContentType",
                        VAL: {
                          teamMembersBlock: Gql__TeamMembersBlock__Fragment.TeamMembersBlock.verifyArgsAndParse("TeamMembersBlock", value)
                        }
                      };
                      break;
                  case "TestimonialCarouselBlockContentType" :
                      tmp = {
                        NAME: "TestimonialCarouselBlockContentType",
                        VAL: {
                          testimonialCarouselBlock: Gql__TestimonialCarouselBlock__Fragment.TestimonialCarouselBlock.verifyArgsAndParse("TestimonialCarouselBlock", value)
                        }
                      };
                      break;
                  case "TheGiftExperienceContentType" :
                      tmp = {
                        NAME: "TheGiftExperienceContentType",
                        VAL: {
                          giftExperienceBlock: Gql__GiftExperience__Fragment.GiftExperienceBlock.verifyArgsAndParse("GiftExperienceBlock", value)
                        }
                      };
                      break;
                  case "TrustpilotWidgetBlockContentType" :
                      tmp = {
                        NAME: "TrustpilotWidgetBlockContentType",
                        VAL: {
                          trustpilotWidgetBlock: Gql__TrustpilotWidgetBlock__Fragment.TrustpilotWidgetBlock.verifyArgsAndParse("TrustpilotWidgetBlock", value)
                        }
                      };
                      break;
                  case "UniqueSellingPointsBlockContentType" :
                      tmp = {
                        NAME: "UniqueSellingPointsBlockContentType",
                        VAL: {
                          uniqueSellingPointsBlock: Gql__UniqueSellingPointsBlock__Fragment.UniqueSellingPointsBlock.verifyArgsAndParse("UniqueSellingPointsBlock", value)
                        }
                      };
                      break;
                  case "VideoTextPromoBoxContentType" :
                      tmp = {
                        NAME: "VideoTextPromoBoxContentType",
                        VAL: {
                          videoTextPromoBlock: Gql__VideoTextPromo__Fragment.VideoTextPromoBlock.verifyArgsAndParse("VideoTextPromoBlock", value)
                        }
                      };
                      break;
                  default:
                    tmp = {
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    };
                }
                return tmp;
              }), value$7) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            codename: value$1.codename,
            language: value$1.language
          },
          seo_meta: tmp,
          store_content: tmp$1,
          content_blocks: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.content_blocks;
  var content_blocks;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var variant = value.NAME;
              if (variant === "OffsetTilesBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__OffsetTilesBlock__Fragment.OffsetTilesBlock.serialize(value.VAL.offsetTilesBlock)]);
              } else if (variant === "FaqBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__FAQ__Fragment.FAQBlock.serialize(value.VAL.fAQBlock)]);
              } else if (variant === "TestimonialCarouselBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__TestimonialCarouselBlock__Fragment.TestimonialCarouselBlock.serialize(value.VAL.testimonialCarouselBlock)]);
              } else if (variant === "IconCardBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__IconCardBlock__Fragment.IconCardBlock.serialize(value.VAL.iconCardBlock)]);
              } else if (variant === "SellingPointsBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__SellingPointsBlock__Fragment.SellingPointsBlock.serialize(value.VAL.sellingPointsBlock)]);
              } else if (variant === "CategoryTileBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__CategoryTiles__Fragment.CategoryTileBlock.serialize(value.VAL.categoryTileBlock)]);
              } else if (variant === "HeroBannerContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__HeroBanner__Fragment.HeroBanner.serialize(value.VAL.heroBanner)]);
              } else if (variant === "ProductHeroBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__ProductHeroBlock__Fragment.ProductHeroBlock.serialize(value.VAL.productHeroBlock)]);
              } else if (variant === "TeamMembersBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__TeamMembersBlock__Fragment.TeamMembersBlock.serialize(value.VAL.teamMembersBlock)]);
              } else if (variant === "LandingPageContactFormContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__LandingPageContactForm__Fragment.LandingPageContactFormBlock.serialize(value.VAL.landingPageContactFormBlock)]);
              } else if (variant === "CustomerCareCallUsContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__CustomerCareCallUs__Fragment.CustomerCareCallUsBlock.serialize(value.VAL.customerCareCallUsBlock)]);
              } else if (variant === "EnvironmentalImpactsBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__StatsTiles__Fragment.StatsTilesBlock.serialize(value.VAL.statsTilesBlock)]);
              } else if (variant === "VideoTextPromoBoxContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__VideoTextPromo__Fragment.VideoTextPromoBlock.serialize(value.VAL.videoTextPromoBlock)]);
              } else if (variant === "PromoTileBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__PromoTiles__Fragment.PromoTileBlock.serialize(value.VAL.promoTileBlock)]);
              } else if (variant === "SkuCarouselBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__SkuCarousel__Fragment.SkuCarouselBlock.serialize(value.VAL.skuCarouselBlock)]);
              } else if (variant === "AppDownloadBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__DownloadAppsBlock__Fragment.AppDownloadBlock.serialize(value.VAL.appDownloadBlock)]);
              } else if (variant === "BusinessContactFormContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__BusinessContactForm__Fragment.BusinessContactFormBlock.serialize(value.VAL.businessContactFormBlock)]);
              } else if (variant === "InnovativeWayToGiftContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__InnovativeWayToGift__Fragment.InnovativeWayToGiftBlock.serialize(value.VAL.innovativeWayToGiftBlock)]);
              } else if (variant === "EmailSignUpBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__NewsletterBlock__Fragment.NewsletterBlock.serialize(value.VAL.newsletterBlock)]);
              } else if (variant === "UnspecifiedFragment") {
                return {};
              } else if (variant === "NarrowBannerFullContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__NarrowBannerFull__Fragment.NarrowBannerFull.serialize(value.VAL.narrowBannerFull)]);
              } else if (variant === "FullWidthTextBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__FullWidthTextBlock__Fragment.FullWidthTextBlock.serialize(value.VAL.fullWidthTextBlock)]);
              } else if (variant === "TheGiftExperienceContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__GiftExperience__Fragment.GiftExperienceBlock.serialize(value.VAL.giftExperienceBlock)]);
              } else if (variant === "FullWidthHeroContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__FullWidthHeroBanner__Fragment.FullWidthHeroBannerBlock.serialize(value.VAL.fullWidthHeroBannerBlock)]);
              } else if (variant === "BoardOfDirectorsBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__BoardOfDirectorsBlock__Fragment.BoardOfDirectorsBlock.serialize(value.VAL.boardOfDirectorsBlock)]);
              } else if (variant === "AutomateAndSimplifyContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__AutomateSimplify__Fragment.AutomateSimplifyBlock.serialize(value.VAL.automateSimplifyBlock)]);
              } else if (variant === "UniqueSellingPointsBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__UniqueSellingPointsBlock__Fragment.UniqueSellingPointsBlock.serialize(value.VAL.uniqueSellingPointsBlock)]);
              } else if (variant === "TrustpilotWidgetBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__TrustpilotWidgetBlock__Fragment.TrustpilotWidgetBlock.serialize(value.VAL.trustpilotWidgetBlock)]);
              } else if (variant === "CustomerCareBannerContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__CustomerCareBanner__Fragment.CustomerCareBannerBlock.serialize(value.VAL.customerCareBannerBlock)]);
              } else if (variant === "EnrichedTextBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.serialize(value.VAL.enrichedTextBlock)]);
              } else if (variant === "HeroTileBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__HeroTiles__Fragment.HeroTileBlock.serialize(value.VAL.heroTileBlock)]);
              } else {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__NarrowCta__Fragment.NarrowCta.serialize(value.VAL.narrowCta)]);
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    content_blocks = {
      __typename: value$4,
      value: value$3
    };
  } else {
    content_blocks = null;
  }
  var value$5 = value.store_content;
  var store_content;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__StoreContent__Fragment.StoreContentBlock.serialize(value.VAL.storeContentBlock)]);
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    store_content = {
      __typename: value$8,
      value: value$7
    };
  } else {
    store_content = null;
  }
  var value$9 = value.seo_meta;
  var seo_meta;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "SeoMetaContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__SeoMeta__Fragment.SeoMetaBlock.serialize(value.VAL.seoMetaBlock)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    seo_meta = {
      __typename: value$12,
      value: value$11
    };
  } else {
    seo_meta = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.language;
  var value$15 = value$13.codename;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    codename: value$15,
    language: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          seo_meta: seo_meta,
          store_content: store_content,
          content_blocks: content_blocks
        };
}

function verifyArgsAndParse(_PageContent, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var PageContent = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var CategoryTileBlock;

var AppDownloadBlock;

var FullWidthTextBlock;

var NewsletterBlock;

var HeroTileBlock;

var UniqueSellingPointsBlock;

var PromoTileBlock;

var StatsTilesBlock;

var VideoTextPromoBlock;

var SkuCarouselBlock;

var TeamMembersBlock;

var CustomerCareBannerBlock;

var CustomerCareCallUsBlock;

var BoardOfDirectorsBlock;

var FullWidthHeroBannerBlock;

var InnovativeWayToGiftBlock;

var IconCardBlock;

var GiftExperienceBlock;

var TestimonialCarouselBlock;

var BusinessContactFormBlock;

var AutomateSimplifyBlock;

var OffsetTilesBlock;

var FAQBlock;

var LandingPageContactFormBlock;

var SeoMetaBlock;

var StoreContentBlock;

var EnrichedTextBlock;

var TrustpilotWidgetBlock;

var NarrowBannerFull;

var ProductHeroBlock;

var SellingPointsBlock;

var NarrowCta;

var HeroBanner;

export {
  CategoryTileBlock ,
  AppDownloadBlock ,
  FullWidthTextBlock ,
  NewsletterBlock ,
  HeroTileBlock ,
  UniqueSellingPointsBlock ,
  PromoTileBlock ,
  StatsTilesBlock ,
  VideoTextPromoBlock ,
  SkuCarouselBlock ,
  TeamMembersBlock ,
  CustomerCareBannerBlock ,
  CustomerCareCallUsBlock ,
  BoardOfDirectorsBlock ,
  FullWidthHeroBannerBlock ,
  InnovativeWayToGiftBlock ,
  IconCardBlock ,
  GiftExperienceBlock ,
  TestimonialCarouselBlock ,
  BusinessContactFormBlock ,
  AutomateSimplifyBlock ,
  OffsetTilesBlock ,
  FAQBlock ,
  LandingPageContactFormBlock ,
  SeoMetaBlock ,
  StoreContentBlock ,
  EnrichedTextBlock ,
  TrustpilotWidgetBlock ,
  NarrowBannerFull ,
  ProductHeroBlock ,
  SellingPointsBlock ,
  NarrowCta ,
  HeroBanner ,
  PageContent ,
}
/* query Not a pure module */
