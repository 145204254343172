// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Footer__Fragment from "../Footer/Gql__Footer__Fragment.bs.js";
import * as Gql__Header__Fragment from "../Header/Gql__Header__Fragment.bs.js";
import * as Gql__HeaderLogo__Fragment from "../HeaderLogo/Gql__HeaderLogo__Fragment.bs.js";
import * as Gql__AlertBanner__Fragment from "../AlertBanner/Gql__AlertBanner__Fragment.bs.js";
import * as Gql__CookiePopup__Fragment from "../CookiePopup/Gql__CookiePopup__Fragment.bs.js";
import * as Gql__AppStoreItem__Fragment from "../AppStoreItem/Gql__AppStoreItem__Fragment.bs.js";
import * as Gql__CallToAction__Fragment from "../CallToAction/Gql__CallToAction__Fragment.bs.js";
import * as Gql__NavMenuItems__Fragment from "../NavMenuItems/Gql__NavMenuItems__Fragment.bs.js";
import * as Gql__NavbarSelectCta__Fragment from "../NavbarSelectCta/Gql__NavbarSelectCta__Fragment.bs.js";
import * as Gql__NoSearchResults__Fragment from "../NoSearchResults/Gql__NoSearchResults__Fragment.bs.js";
import * as Gql__HeaderNavigation__Fragment from "../HeaderNavigation/Gql__HeaderNavigation__Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0, frag_1, frag_2, frag_3, frag_4, frag_5, frag_6, frag_7, frag_8, frag_9, frag_10) => require("@apollo/client").gql`
  query GlobalConfiguration($language: String!, $preview: Boolean)  {
    globalConfiguration: itemsByType(type: "global_configuration", limit: 1, depth: 5, language: $language, preview: $preview)  {
      __typename
      ...on GlobalConfigurationContentType   {
        __typename
        gdpr_cookie_popup  {
          __typename
          value  {
            __typename
            ...on GdprCookiePopupContentType   {
              ...CookiePopupBlock
            }
          }
        }
        alert_banner  {
          __typename
          value  {
            __typename
            ...on AlertBannerContentType   {
              ...AlertBannerBlock
            }
          }
        }
        navigation_root  {
          __typename
          value  {
            __typename
            ...on AppContentType   {
              ...HeaderNavigation
            }
            ...on PageContentType   {
              ...HeaderBlock
            }
          }
        }
        business_navigation  {
          __typename
          value  {
            __typename
            ...on AppContentType   {
              ...HeaderNavigation
            }
            ...on PageContentType   {
              ...HeaderBlock
            }
          }
        }
        desktop_navbar_menu_items  {
          __typename
          value  {
            __typename
            ...on NavbarMenuItemContentType   {
              ...NavMenuBlock
            }
          }
        }
        mobile_navbar_menu_items  {
          __typename
          value  {
            __typename
            ...on NavbarMenuItemContentType   {
              ...NavMenuBlock
            }
          }
        }
        footer  {
          __typename
          value  {
            __typename
            ...on FooterContainerContentType   {
              ...FooterBlock
            }
          }
        }
        business_footer  {
          __typename
          value  {
            __typename
            ...on FooterContainerContentType   {
              ...FooterBlock
            }
          }
        }
        login  {
          __typename
          value  {
            __typename
            ...on LoginContentType   {
              __typename
              system  {
                __typename
                codename
                id
              }
              title  {
                __typename
                value
              }
              subtitle  {
                __typename
                value
              }
              email_label  {
                __typename
                value
              }
              password_label  {
                __typename
                value
              }
              required_email_text  {
                __typename
                value
              }
              required_password_text  {
                __typename
                value
              }
              invalid_email_text  {
                __typename
                value
              }
              submit_cta_text  {
                __typename
                value
              }
              submit_cta_submitting_text  {
                __typename
                value
              }
              forgot_password_text  {
                __typename
                value
              }
              forgot_email_text  {
                __typename
                value
              }
            }
          }
        }
        register  {
          __typename
          value  {
            __typename
            ...on RegisterContentType   {
              __typename
              system  {
                __typename
                codename
                id
              }
              register_title  {
                __typename
                value
              }
              register_subtitle  {
                __typename
                value
              }
              next_button_text  {
                __typename
                value
              }
              back_button_text  {
                __typename
                value
              }
              step_title___name  {
                __typename
                value
              }
              first_name_label  {
                __typename
                value
              }
              first_name_required_text  {
                __typename
                value
              }
              invalid_first_name_text  {
                __typename
                value
              }
              last_name_label  {
                __typename
                value
              }
              last_name_required_text  {
                __typename
                value
              }
              invalid_last_name_text  {
                __typename
                value
              }
              step_title___email  {
                __typename
                value
              }
              email_label  {
                __typename
                value
              }
              email_required_text  {
                __typename
                value
              }
              invalid_email_text  {
                __typename
                value
              }
              step_title___password  {
                __typename
                value
              }
              password_requirements  {
                __typename
                value
              }
              password_label  {
                __typename
                value
              }
              password_required_text  {
                __typename
                value
              }
              invalid_password_text  {
                __typename
                value
              }
              step_title___phone_number  {
                __typename
                value
              }
              phone_number_required_text  {
                __typename
                value
              }
              invalid_phone_number_text  {
                __typename
                value
              }
              newsletter_checkbox_label  {
                __typename
                value
              }
              t_cs_checkbox_label  {
                __typename
                value
              }
              nz_not_prezzy_disclaimer  {
                __typename
                value
              }
              send_security_code_cta_text  {
                __typename
                value
              }
              step_title___security_code  {
                __typename
                value
              }
              resend_code_text  {
                __typename
                value
              }
              resending_code_text  {
                __typename
                value
              }
              sign_up_cta_text  {
                __typename
                value
              }
              already_registered_text  {
                __typename
                value
              }
            }
          }
        }
        warnings  {
          __typename
          value  {
            __typename
            ...on WarningContentType   {
              __typename
              id  {
                __typename
                value
              }
              heading  {
                __typename
                value
              }
              description  {
                __typename
                value
              }
            }
          }
        }
        header_logo  {
          __typename
          value  {
            __typename
            ...on HeaderLogoContentType   {
              ...HeaderLogo
            }
          }
        }
        not_logged_in_navbar_menu_items___consumer  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
            ...on NavbarSelectCtaContentType   {
              ...NavbarSelectCTA
            }
          }
        }
        not_logged_in_navbar_menu_items___business  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
          }
        }
        search_bar_placeholder_text  {
          __typename
          value
        }
        no_search_result  {
          __typename
          value  {
            __typename
            ...on NoSearchResultsContentType   {
              ...NoSearchResults
            }
          }
        }
        sold_out_badge_text  {
          __typename
          value
        }
      }
    }
    dictionaryItems: itemsByType(type: "dictionary_item", language: $language, preview: $preview, depth: 3)  {
      __typename
      ...on DictionaryItemContentType   {
        __typename
        system  {
          __typename
          id
          codename
        }
        value  {
          __typename
          value
        }
      }
    }
    locations: itemsByType(type: "modal_change_location", language: $language, preview: $preview, limit: 3, depth: 3)  {
      __typename
      ...on ModalChangeLocationContentType   {
        __typename
        system  {
          __typename
          id
          codename
        }
        title  {
          __typename
          value
        }
        subtitle  {
          __typename
          value
        }
        cta_text  {
          __typename
          value
        }
        region_location  {
          __typename
          value  {
            __typename
            ...on RegionLocationContentType   {
              __typename
              system  {
                __typename
                id
                codename
              }
              currency  {
                __typename
                value
              }
              country_name  {
                __typename
                value
              }
              country_flag  {
                __typename
                value  {
                  __typename
                  name
                  url
                }
              }
              url  {
                __typename
                value
              }
            }
          }
        }
      }
    }
    giftLandingPage: itemsByType(type: "gift_landing_page", language: $language, preview: $preview, depth: 3)  {
      __typename
      ...on GiftLandingPageContentType   {
        __typename
        system  {
          __typename
          codename
          id
        }
        classic_logo_asset  {
          __typename
          value  {
            __typename
            name
            description
            url
            width
            height
          }
        }
        business_logo_asset  {
          __typename
          value  {
            __typename
            name
            description
            url
            width
            height
          }
        }
        sender_message  {
          __typename
          value
        }
        gift_reveal_cta_text  {
          __typename
          value
        }
        confetti_lottie  {
          __typename
          value  {
            __typename
            url
          }
        }
        faqs  {
          __typename
          value  {
            __typename
            ...on GiftLandingPageFaqItemContentType   {
              __typename
              system  {
                __typename
                codename
                id
              }
              title  {
                __typename
                value
              }
              body_text  {
                __typename
                value
              }
              include_how_to_use  {
                __typename
                value  {
                  __typename
                  codename
                }
              }
              include_pdf_download  {
                __typename
                value  {
                  __typename
                  codename
                }
              }
              include_card_merchant_terms  {
                __typename
                value  {
                  __typename
                  codename
                }
              }
            }
          }
        }
        no_expiry_text  {
          __typename
          value
        }
        expiry_label  {
          __typename
          value
        }
        check_balance_text  {
          __typename
          value
        }
        check_balance_icon_asset  {
          __typename
          value  {
            __typename
            name
            description
            url
            width
            height
          }
        }
        store_in_wallet_cta_title  {
          __typename
          value
        }
        swap_egift_card_cta_title  {
          __typename
          value
        }
        optional_gift_card_cta  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
          }
        }
        gift_unwrap_cta_text  {
          __typename
          value
        }
        gift_unwrap_lottie_segments  {
          __typename
          value
        }
        classic_gift_unwrap_lottie  {
          __typename
          value  {
            __typename
            url
          }
        }
        business_gift_unwrap_lottie  {
          __typename
          value  {
            __typename
            url
          }
        }
        replay_button_icon_asset  {
          __typename
          value  {
            __typename
            name
            description
            url
            width
            height
          }
        }
        replay_button_text  {
          __typename
          value
        }
        redeem_online_text  {
          __typename
          value
        }
        redeem_online_text_colour  {
          __typename
          value
        }
        redeem_online_button  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
          }
        }
        download_option_text  {
          __typename
          value
        }
        download_option_text_colour  {
          __typename
          value
        }
        download_button  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
          }
        }
        spend_it_now_heading  {
          __typename
          value
        }
        clipboard_item_copy_text  {
          __typename
          value
        }
        clipboard_item_copied_text  {
          __typename
          value
        }
        barcode_number_clipboard_heading  {
          __typename
          value
        }
        in_store_number_clipboard_heading  {
          __typename
          value
        }
        online_number_clipboard_heading  {
          __typename
          value
        }
        online_and_in_store_clipboard_heading  {
          __typename
          value
        }
        swap_cards_title  {
          __typename
          value
        }
        swap_card_alt  {
          __typename
          value
        }
        swap_card_original_value_label  {
          __typename
          value
        }
        swap_card_button_style  {
          __typename
          value  {
            __typename
            ...on CallToActionLinkD6a3d61ContentType   {
              ...CallToAction
            }
          }
        }
        save_in_apple_google_wallet_text  {
          __typename
          value
        }
        save_in_apple_google_wallet_text_colour  {
          __typename
          value
        }
        google_wallet_image_asset  {
          __typename
          value  {
            __typename
            name
            description
            url
            width
            height
          }
        }
        apple_wallet_image_asset  {
          __typename
          value  {
            __typename
            name
            description
            url
            width
            height
          }
        }
        footer_copyright_text  {
          __typename
          value
        }
        footer_download_text  {
          __typename
          value
        }
        app_store_icons  {
          __typename
          value  {
            __typename
            ...on AppStoreItemContentType   {
              ...AppStoreItem
            }
          }
        }
        create_account_modal_title  {
          __typename
          value
        }
        create_account_modal_list  {
          __typename
          value
        }
        create_account_modal_cta_text  {
          __typename
          value
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
  ${frag_2}
  ${frag_3}
  ${frag_4}
  ${frag_5}
  ${frag_6}
  ${frag_7}
  ${frag_8}
  ${frag_9}
  ${frag_10}
`)(Gql__AlertBanner__Fragment.AlertBannerBlock.query, Gql__AppStoreItem__Fragment.AppStoreItem.query, Gql__CallToAction__Fragment.CallToAction.query, Gql__CookiePopup__Fragment.CookiePopupBlock.query, Gql__Footer__Fragment.FooterBlock.query, Gql__Header__Fragment.HeaderBlock.query, Gql__HeaderLogo__Fragment.HeaderLogo.query, Gql__HeaderNavigation__Fragment.HeaderNavigation.query, Gql__NavMenuItems__Fragment.NavMenuBlock.query, Gql__NavbarSelectCta__Fragment.NavbarSelectCTA.query, Gql__NoSearchResults__Fragment.NoSearchResults.query);

function parse(value) {
  var value$1 = value.globalConfiguration;
  var value$2 = value.dictionaryItems;
  var value$3 = value.locations;
  var value$4 = value.giftLandingPage;
  return {
          globalConfiguration: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "GlobalConfigurationContentType") {
                      var value$1 = value.gdpr_cookie_popup;
                      var tmp$1;
                      if (value$1 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$2 = value$1.value;
                        tmp$1 = {
                          __typename: value$1.__typename,
                          value: !(value$2 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "GdprCookiePopupContentType" ? ({
                                          NAME: "GdprCookiePopupContentType",
                                          VAL: {
                                            cookiePopupBlock: Gql__CookiePopup__Fragment.CookiePopupBlock.verifyArgsAndParse("CookiePopupBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$2) : undefined
                        };
                      }
                      var value$3 = value.alert_banner;
                      var tmp$2;
                      if (value$3 == null) {
                        tmp$2 = undefined;
                      } else {
                        var value$4 = value$3.value;
                        tmp$2 = {
                          __typename: value$3.__typename,
                          value: !(value$4 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "AlertBannerContentType" ? ({
                                          NAME: "AlertBannerContentType",
                                          VAL: {
                                            alertBannerBlock: Gql__AlertBanner__Fragment.AlertBannerBlock.verifyArgsAndParse("AlertBannerBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$4) : undefined
                        };
                      }
                      var value$5 = value.navigation_root;
                      var tmp$3;
                      if (value$5 == null) {
                        tmp$3 = undefined;
                      } else {
                        var value$6 = value$5.value;
                        tmp$3 = {
                          __typename: value$5.__typename,
                          value: !(value$6 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    switch (typename) {
                                      case "AppContentType" :
                                          tmp = {
                                            NAME: "AppContentType",
                                            VAL: {
                                              headerNavigation: Gql__HeaderNavigation__Fragment.HeaderNavigation.verifyArgsAndParse("HeaderNavigation", value)
                                            }
                                          };
                                          break;
                                      case "PageContentType" :
                                          tmp = {
                                            NAME: "PageContentType",
                                            VAL: {
                                              headerBlock: Gql__Header__Fragment.HeaderBlock.verifyArgsAndParse("HeaderBlock", value)
                                            }
                                          };
                                          break;
                                      default:
                                        tmp = {
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        };
                                    }
                                    return tmp;
                                  }), value$6) : undefined
                        };
                      }
                      var value$7 = value.business_navigation;
                      var tmp$4;
                      if (value$7 == null) {
                        tmp$4 = undefined;
                      } else {
                        var value$8 = value$7.value;
                        tmp$4 = {
                          __typename: value$7.__typename,
                          value: !(value$8 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    switch (typename) {
                                      case "AppContentType" :
                                          tmp = {
                                            NAME: "AppContentType",
                                            VAL: {
                                              headerNavigation: Gql__HeaderNavigation__Fragment.HeaderNavigation.verifyArgsAndParse("HeaderNavigation", value)
                                            }
                                          };
                                          break;
                                      case "PageContentType" :
                                          tmp = {
                                            NAME: "PageContentType",
                                            VAL: {
                                              headerBlock: Gql__Header__Fragment.HeaderBlock.verifyArgsAndParse("HeaderBlock", value)
                                            }
                                          };
                                          break;
                                      default:
                                        tmp = {
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        };
                                    }
                                    return tmp;
                                  }), value$8) : undefined
                        };
                      }
                      var value$9 = value.desktop_navbar_menu_items;
                      var tmp$5;
                      if (value$9 == null) {
                        tmp$5 = undefined;
                      } else {
                        var value$10 = value$9.value;
                        tmp$5 = {
                          __typename: value$9.__typename,
                          value: !(value$10 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "NavbarMenuItemContentType" ? ({
                                          NAME: "NavbarMenuItemContentType",
                                          VAL: {
                                            navMenuBlock: Gql__NavMenuItems__Fragment.NavMenuBlock.verifyArgsAndParse("NavMenuBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$10) : undefined
                        };
                      }
                      var value$11 = value.mobile_navbar_menu_items;
                      var tmp$6;
                      if (value$11 == null) {
                        tmp$6 = undefined;
                      } else {
                        var value$12 = value$11.value;
                        tmp$6 = {
                          __typename: value$11.__typename,
                          value: !(value$12 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "NavbarMenuItemContentType" ? ({
                                          NAME: "NavbarMenuItemContentType",
                                          VAL: {
                                            navMenuBlock: Gql__NavMenuItems__Fragment.NavMenuBlock.verifyArgsAndParse("NavMenuBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$12) : undefined
                        };
                      }
                      var value$13 = value.footer;
                      var tmp$7;
                      if (value$13 == null) {
                        tmp$7 = undefined;
                      } else {
                        var value$14 = value$13.value;
                        tmp$7 = {
                          __typename: value$13.__typename,
                          value: !(value$14 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "FooterContainerContentType" ? ({
                                          NAME: "FooterContainerContentType",
                                          VAL: {
                                            footerBlock: Gql__Footer__Fragment.FooterBlock.verifyArgsAndParse("FooterBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$14) : undefined
                        };
                      }
                      var value$15 = value.business_footer;
                      var tmp$8;
                      if (value$15 == null) {
                        tmp$8 = undefined;
                      } else {
                        var value$16 = value$15.value;
                        tmp$8 = {
                          __typename: value$15.__typename,
                          value: !(value$16 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "FooterContainerContentType" ? ({
                                          NAME: "FooterContainerContentType",
                                          VAL: {
                                            footerBlock: Gql__Footer__Fragment.FooterBlock.verifyArgsAndParse("FooterBlock", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$16) : undefined
                        };
                      }
                      var value$17 = value.login;
                      var tmp$9;
                      if (value$17 == null) {
                        tmp$9 = undefined;
                      } else {
                        var value$18 = value$17.value;
                        tmp$9 = {
                          __typename: value$17.__typename,
                          value: !(value$18 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    if (typename === "LoginContentType") {
                                      var value$1 = value.system;
                                      var value$2 = value.title;
                                      var tmp$1;
                                      if (value$2 == null) {
                                        tmp$1 = undefined;
                                      } else {
                                        var value$3 = value$2.value;
                                        tmp$1 = {
                                          __typename: value$2.__typename,
                                          value: !(value$3 == null) ? value$3 : undefined
                                        };
                                      }
                                      var value$4 = value.subtitle;
                                      var tmp$2;
                                      if (value$4 == null) {
                                        tmp$2 = undefined;
                                      } else {
                                        var value$5 = value$4.value;
                                        tmp$2 = {
                                          __typename: value$4.__typename,
                                          value: !(value$5 == null) ? value$5 : undefined
                                        };
                                      }
                                      var value$6 = value.email_label;
                                      var tmp$3;
                                      if (value$6 == null) {
                                        tmp$3 = undefined;
                                      } else {
                                        var value$7 = value$6.value;
                                        tmp$3 = {
                                          __typename: value$6.__typename,
                                          value: !(value$7 == null) ? value$7 : undefined
                                        };
                                      }
                                      var value$8 = value.password_label;
                                      var tmp$4;
                                      if (value$8 == null) {
                                        tmp$4 = undefined;
                                      } else {
                                        var value$9 = value$8.value;
                                        tmp$4 = {
                                          __typename: value$8.__typename,
                                          value: !(value$9 == null) ? value$9 : undefined
                                        };
                                      }
                                      var value$10 = value.required_email_text;
                                      var tmp$5;
                                      if (value$10 == null) {
                                        tmp$5 = undefined;
                                      } else {
                                        var value$11 = value$10.value;
                                        tmp$5 = {
                                          __typename: value$10.__typename,
                                          value: !(value$11 == null) ? value$11 : undefined
                                        };
                                      }
                                      var value$12 = value.required_password_text;
                                      var tmp$6;
                                      if (value$12 == null) {
                                        tmp$6 = undefined;
                                      } else {
                                        var value$13 = value$12.value;
                                        tmp$6 = {
                                          __typename: value$12.__typename,
                                          value: !(value$13 == null) ? value$13 : undefined
                                        };
                                      }
                                      var value$14 = value.invalid_email_text;
                                      var tmp$7;
                                      if (value$14 == null) {
                                        tmp$7 = undefined;
                                      } else {
                                        var value$15 = value$14.value;
                                        tmp$7 = {
                                          __typename: value$14.__typename,
                                          value: !(value$15 == null) ? value$15 : undefined
                                        };
                                      }
                                      var value$16 = value.submit_cta_text;
                                      var tmp$8;
                                      if (value$16 == null) {
                                        tmp$8 = undefined;
                                      } else {
                                        var value$17 = value$16.value;
                                        tmp$8 = {
                                          __typename: value$16.__typename,
                                          value: !(value$17 == null) ? value$17 : undefined
                                        };
                                      }
                                      var value$18 = value.submit_cta_submitting_text;
                                      var tmp$9;
                                      if (value$18 == null) {
                                        tmp$9 = undefined;
                                      } else {
                                        var value$19 = value$18.value;
                                        tmp$9 = {
                                          __typename: value$18.__typename,
                                          value: !(value$19 == null) ? value$19 : undefined
                                        };
                                      }
                                      var value$20 = value.forgot_password_text;
                                      var tmp$10;
                                      if (value$20 == null) {
                                        tmp$10 = undefined;
                                      } else {
                                        var value$21 = value$20.value;
                                        tmp$10 = {
                                          __typename: value$20.__typename,
                                          value: !(value$21 == null) ? value$21 : undefined
                                        };
                                      }
                                      var value$22 = value.forgot_email_text;
                                      var tmp$11;
                                      if (value$22 == null) {
                                        tmp$11 = undefined;
                                      } else {
                                        var value$23 = value$22.value;
                                        tmp$11 = {
                                          __typename: value$22.__typename,
                                          value: !(value$23 == null) ? value$23 : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "LoginContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$1.__typename,
                                            codename: value$1.codename,
                                            id: value$1.id
                                          },
                                          title: tmp$1,
                                          subtitle: tmp$2,
                                          email_label: tmp$3,
                                          password_label: tmp$4,
                                          required_email_text: tmp$5,
                                          required_password_text: tmp$6,
                                          invalid_email_text: tmp$7,
                                          submit_cta_text: tmp$8,
                                          submit_cta_submitting_text: tmp$9,
                                          forgot_password_text: tmp$10,
                                          forgot_email_text: tmp$11
                                        }
                                      };
                                    } else {
                                      tmp = {
                                        NAME: "UnspecifiedFragment",
                                        VAL: typename
                                      };
                                    }
                                    return tmp;
                                  }), value$18) : undefined
                        };
                      }
                      var value$19 = value.register;
                      var tmp$10;
                      if (value$19 == null) {
                        tmp$10 = undefined;
                      } else {
                        var value$20 = value$19.value;
                        tmp$10 = {
                          __typename: value$19.__typename,
                          value: !(value$20 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    if (typename === "RegisterContentType") {
                                      var value$1 = value.system;
                                      var value$2 = value.register_title;
                                      var tmp$1;
                                      if (value$2 == null) {
                                        tmp$1 = undefined;
                                      } else {
                                        var value$3 = value$2.value;
                                        tmp$1 = {
                                          __typename: value$2.__typename,
                                          value: !(value$3 == null) ? value$3 : undefined
                                        };
                                      }
                                      var value$4 = value.register_subtitle;
                                      var tmp$2;
                                      if (value$4 == null) {
                                        tmp$2 = undefined;
                                      } else {
                                        var value$5 = value$4.value;
                                        tmp$2 = {
                                          __typename: value$4.__typename,
                                          value: !(value$5 == null) ? value$5 : undefined
                                        };
                                      }
                                      var value$6 = value.next_button_text;
                                      var tmp$3;
                                      if (value$6 == null) {
                                        tmp$3 = undefined;
                                      } else {
                                        var value$7 = value$6.value;
                                        tmp$3 = {
                                          __typename: value$6.__typename,
                                          value: !(value$7 == null) ? value$7 : undefined
                                        };
                                      }
                                      var value$8 = value.back_button_text;
                                      var tmp$4;
                                      if (value$8 == null) {
                                        tmp$4 = undefined;
                                      } else {
                                        var value$9 = value$8.value;
                                        tmp$4 = {
                                          __typename: value$8.__typename,
                                          value: !(value$9 == null) ? value$9 : undefined
                                        };
                                      }
                                      var value$10 = value.step_title___name;
                                      var tmp$5;
                                      if (value$10 == null) {
                                        tmp$5 = undefined;
                                      } else {
                                        var value$11 = value$10.value;
                                        tmp$5 = {
                                          __typename: value$10.__typename,
                                          value: !(value$11 == null) ? value$11 : undefined
                                        };
                                      }
                                      var value$12 = value.first_name_label;
                                      var tmp$6;
                                      if (value$12 == null) {
                                        tmp$6 = undefined;
                                      } else {
                                        var value$13 = value$12.value;
                                        tmp$6 = {
                                          __typename: value$12.__typename,
                                          value: !(value$13 == null) ? value$13 : undefined
                                        };
                                      }
                                      var value$14 = value.first_name_required_text;
                                      var tmp$7;
                                      if (value$14 == null) {
                                        tmp$7 = undefined;
                                      } else {
                                        var value$15 = value$14.value;
                                        tmp$7 = {
                                          __typename: value$14.__typename,
                                          value: !(value$15 == null) ? value$15 : undefined
                                        };
                                      }
                                      var value$16 = value.invalid_first_name_text;
                                      var tmp$8;
                                      if (value$16 == null) {
                                        tmp$8 = undefined;
                                      } else {
                                        var value$17 = value$16.value;
                                        tmp$8 = {
                                          __typename: value$16.__typename,
                                          value: !(value$17 == null) ? value$17 : undefined
                                        };
                                      }
                                      var value$18 = value.last_name_label;
                                      var tmp$9;
                                      if (value$18 == null) {
                                        tmp$9 = undefined;
                                      } else {
                                        var value$19 = value$18.value;
                                        tmp$9 = {
                                          __typename: value$18.__typename,
                                          value: !(value$19 == null) ? value$19 : undefined
                                        };
                                      }
                                      var value$20 = value.last_name_required_text;
                                      var tmp$10;
                                      if (value$20 == null) {
                                        tmp$10 = undefined;
                                      } else {
                                        var value$21 = value$20.value;
                                        tmp$10 = {
                                          __typename: value$20.__typename,
                                          value: !(value$21 == null) ? value$21 : undefined
                                        };
                                      }
                                      var value$22 = value.invalid_last_name_text;
                                      var tmp$11;
                                      if (value$22 == null) {
                                        tmp$11 = undefined;
                                      } else {
                                        var value$23 = value$22.value;
                                        tmp$11 = {
                                          __typename: value$22.__typename,
                                          value: !(value$23 == null) ? value$23 : undefined
                                        };
                                      }
                                      var value$24 = value.step_title___email;
                                      var tmp$12;
                                      if (value$24 == null) {
                                        tmp$12 = undefined;
                                      } else {
                                        var value$25 = value$24.value;
                                        tmp$12 = {
                                          __typename: value$24.__typename,
                                          value: !(value$25 == null) ? value$25 : undefined
                                        };
                                      }
                                      var value$26 = value.email_label;
                                      var tmp$13;
                                      if (value$26 == null) {
                                        tmp$13 = undefined;
                                      } else {
                                        var value$27 = value$26.value;
                                        tmp$13 = {
                                          __typename: value$26.__typename,
                                          value: !(value$27 == null) ? value$27 : undefined
                                        };
                                      }
                                      var value$28 = value.email_required_text;
                                      var tmp$14;
                                      if (value$28 == null) {
                                        tmp$14 = undefined;
                                      } else {
                                        var value$29 = value$28.value;
                                        tmp$14 = {
                                          __typename: value$28.__typename,
                                          value: !(value$29 == null) ? value$29 : undefined
                                        };
                                      }
                                      var value$30 = value.invalid_email_text;
                                      var tmp$15;
                                      if (value$30 == null) {
                                        tmp$15 = undefined;
                                      } else {
                                        var value$31 = value$30.value;
                                        tmp$15 = {
                                          __typename: value$30.__typename,
                                          value: !(value$31 == null) ? value$31 : undefined
                                        };
                                      }
                                      var value$32 = value.step_title___password;
                                      var tmp$16;
                                      if (value$32 == null) {
                                        tmp$16 = undefined;
                                      } else {
                                        var value$33 = value$32.value;
                                        tmp$16 = {
                                          __typename: value$32.__typename,
                                          value: !(value$33 == null) ? value$33 : undefined
                                        };
                                      }
                                      var value$34 = value.password_requirements;
                                      var tmp$17;
                                      if (value$34 == null) {
                                        tmp$17 = undefined;
                                      } else {
                                        var value$35 = value$34.value;
                                        tmp$17 = {
                                          __typename: value$34.__typename,
                                          value: !(value$35 == null) ? value$35 : undefined
                                        };
                                      }
                                      var value$36 = value.password_label;
                                      var tmp$18;
                                      if (value$36 == null) {
                                        tmp$18 = undefined;
                                      } else {
                                        var value$37 = value$36.value;
                                        tmp$18 = {
                                          __typename: value$36.__typename,
                                          value: !(value$37 == null) ? value$37 : undefined
                                        };
                                      }
                                      var value$38 = value.password_required_text;
                                      var tmp$19;
                                      if (value$38 == null) {
                                        tmp$19 = undefined;
                                      } else {
                                        var value$39 = value$38.value;
                                        tmp$19 = {
                                          __typename: value$38.__typename,
                                          value: !(value$39 == null) ? value$39 : undefined
                                        };
                                      }
                                      var value$40 = value.invalid_password_text;
                                      var tmp$20;
                                      if (value$40 == null) {
                                        tmp$20 = undefined;
                                      } else {
                                        var value$41 = value$40.value;
                                        tmp$20 = {
                                          __typename: value$40.__typename,
                                          value: !(value$41 == null) ? value$41 : undefined
                                        };
                                      }
                                      var value$42 = value.step_title___phone_number;
                                      var tmp$21;
                                      if (value$42 == null) {
                                        tmp$21 = undefined;
                                      } else {
                                        var value$43 = value$42.value;
                                        tmp$21 = {
                                          __typename: value$42.__typename,
                                          value: !(value$43 == null) ? value$43 : undefined
                                        };
                                      }
                                      var value$44 = value.phone_number_required_text;
                                      var tmp$22;
                                      if (value$44 == null) {
                                        tmp$22 = undefined;
                                      } else {
                                        var value$45 = value$44.value;
                                        tmp$22 = {
                                          __typename: value$44.__typename,
                                          value: !(value$45 == null) ? value$45 : undefined
                                        };
                                      }
                                      var value$46 = value.invalid_phone_number_text;
                                      var tmp$23;
                                      if (value$46 == null) {
                                        tmp$23 = undefined;
                                      } else {
                                        var value$47 = value$46.value;
                                        tmp$23 = {
                                          __typename: value$46.__typename,
                                          value: !(value$47 == null) ? value$47 : undefined
                                        };
                                      }
                                      var value$48 = value.newsletter_checkbox_label;
                                      var tmp$24;
                                      if (value$48 == null) {
                                        tmp$24 = undefined;
                                      } else {
                                        var value$49 = value$48.value;
                                        tmp$24 = {
                                          __typename: value$48.__typename,
                                          value: !(value$49 == null) ? value$49 : undefined
                                        };
                                      }
                                      var value$50 = value.t_cs_checkbox_label;
                                      var tmp$25;
                                      if (value$50 == null) {
                                        tmp$25 = undefined;
                                      } else {
                                        var value$51 = value$50.value;
                                        tmp$25 = {
                                          __typename: value$50.__typename,
                                          value: !(value$51 == null) ? value$51 : undefined
                                        };
                                      }
                                      var value$52 = value.nz_not_prezzy_disclaimer;
                                      var tmp$26;
                                      if (value$52 == null) {
                                        tmp$26 = undefined;
                                      } else {
                                        var value$53 = value$52.value;
                                        tmp$26 = {
                                          __typename: value$52.__typename,
                                          value: !(value$53 == null) ? value$53 : undefined
                                        };
                                      }
                                      var value$54 = value.send_security_code_cta_text;
                                      var tmp$27;
                                      if (value$54 == null) {
                                        tmp$27 = undefined;
                                      } else {
                                        var value$55 = value$54.value;
                                        tmp$27 = {
                                          __typename: value$54.__typename,
                                          value: !(value$55 == null) ? value$55 : undefined
                                        };
                                      }
                                      var value$56 = value.step_title___security_code;
                                      var tmp$28;
                                      if (value$56 == null) {
                                        tmp$28 = undefined;
                                      } else {
                                        var value$57 = value$56.value;
                                        tmp$28 = {
                                          __typename: value$56.__typename,
                                          value: !(value$57 == null) ? value$57 : undefined
                                        };
                                      }
                                      var value$58 = value.resend_code_text;
                                      var tmp$29;
                                      if (value$58 == null) {
                                        tmp$29 = undefined;
                                      } else {
                                        var value$59 = value$58.value;
                                        tmp$29 = {
                                          __typename: value$58.__typename,
                                          value: !(value$59 == null) ? value$59 : undefined
                                        };
                                      }
                                      var value$60 = value.resending_code_text;
                                      var tmp$30;
                                      if (value$60 == null) {
                                        tmp$30 = undefined;
                                      } else {
                                        var value$61 = value$60.value;
                                        tmp$30 = {
                                          __typename: value$60.__typename,
                                          value: !(value$61 == null) ? value$61 : undefined
                                        };
                                      }
                                      var value$62 = value.sign_up_cta_text;
                                      var tmp$31;
                                      if (value$62 == null) {
                                        tmp$31 = undefined;
                                      } else {
                                        var value$63 = value$62.value;
                                        tmp$31 = {
                                          __typename: value$62.__typename,
                                          value: !(value$63 == null) ? value$63 : undefined
                                        };
                                      }
                                      var value$64 = value.already_registered_text;
                                      var tmp$32;
                                      if (value$64 == null) {
                                        tmp$32 = undefined;
                                      } else {
                                        var value$65 = value$64.value;
                                        tmp$32 = {
                                          __typename: value$64.__typename,
                                          value: !(value$65 == null) ? value$65 : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "RegisterContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$1.__typename,
                                            codename: value$1.codename,
                                            id: value$1.id
                                          },
                                          register_title: tmp$1,
                                          register_subtitle: tmp$2,
                                          next_button_text: tmp$3,
                                          back_button_text: tmp$4,
                                          step_title___name: tmp$5,
                                          first_name_label: tmp$6,
                                          first_name_required_text: tmp$7,
                                          invalid_first_name_text: tmp$8,
                                          last_name_label: tmp$9,
                                          last_name_required_text: tmp$10,
                                          invalid_last_name_text: tmp$11,
                                          step_title___email: tmp$12,
                                          email_label: tmp$13,
                                          email_required_text: tmp$14,
                                          invalid_email_text: tmp$15,
                                          step_title___password: tmp$16,
                                          password_requirements: tmp$17,
                                          password_label: tmp$18,
                                          password_required_text: tmp$19,
                                          invalid_password_text: tmp$20,
                                          step_title___phone_number: tmp$21,
                                          phone_number_required_text: tmp$22,
                                          invalid_phone_number_text: tmp$23,
                                          newsletter_checkbox_label: tmp$24,
                                          t_cs_checkbox_label: tmp$25,
                                          nz_not_prezzy_disclaimer: tmp$26,
                                          send_security_code_cta_text: tmp$27,
                                          step_title___security_code: tmp$28,
                                          resend_code_text: tmp$29,
                                          resending_code_text: tmp$30,
                                          sign_up_cta_text: tmp$31,
                                          already_registered_text: tmp$32
                                        }
                                      };
                                    } else {
                                      tmp = {
                                        NAME: "UnspecifiedFragment",
                                        VAL: typename
                                      };
                                    }
                                    return tmp;
                                  }), value$20) : undefined
                        };
                      }
                      var value$21 = value.warnings;
                      var tmp$11;
                      if (value$21 == null) {
                        tmp$11 = undefined;
                      } else {
                        var value$22 = value$21.value;
                        tmp$11 = {
                          __typename: value$21.__typename,
                          value: !(value$22 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    if (typename === "WarningContentType") {
                                      var value$1 = value.id;
                                      var tmp$1;
                                      if (value$1 == null) {
                                        tmp$1 = undefined;
                                      } else {
                                        var value$2 = value$1.value;
                                        tmp$1 = {
                                          __typename: value$1.__typename,
                                          value: !(value$2 == null) ? value$2 : undefined
                                        };
                                      }
                                      var value$3 = value.heading;
                                      var tmp$2;
                                      if (value$3 == null) {
                                        tmp$2 = undefined;
                                      } else {
                                        var value$4 = value$3.value;
                                        tmp$2 = {
                                          __typename: value$3.__typename,
                                          value: !(value$4 == null) ? value$4 : undefined
                                        };
                                      }
                                      var value$5 = value.description;
                                      var tmp$3;
                                      if (value$5 == null) {
                                        tmp$3 = undefined;
                                      } else {
                                        var value$6 = value$5.value;
                                        tmp$3 = {
                                          __typename: value$5.__typename,
                                          value: !(value$6 == null) ? value$6 : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "WarningContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          id: tmp$1,
                                          heading: tmp$2,
                                          description: tmp$3
                                        }
                                      };
                                    } else {
                                      tmp = {
                                        NAME: "UnspecifiedFragment",
                                        VAL: typename
                                      };
                                    }
                                    return tmp;
                                  }), value$22) : undefined
                        };
                      }
                      var value$23 = value.header_logo;
                      var tmp$12;
                      if (value$23 == null) {
                        tmp$12 = undefined;
                      } else {
                        var value$24 = value$23.value;
                        tmp$12 = {
                          __typename: value$23.__typename,
                          value: !(value$24 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "HeaderLogoContentType" ? ({
                                          NAME: "HeaderLogoContentType",
                                          VAL: {
                                            headerLogo: Gql__HeaderLogo__Fragment.HeaderLogo.verifyArgsAndParse("HeaderLogo", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$24) : undefined
                        };
                      }
                      var value$25 = value.not_logged_in_navbar_menu_items___consumer;
                      var tmp$13;
                      if (value$25 == null) {
                        tmp$13 = undefined;
                      } else {
                        var value$26 = value$25.value;
                        tmp$13 = {
                          __typename: value$25.__typename,
                          value: !(value$26 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    switch (typename) {
                                      case "CallToActionLinkD6a3d61ContentType" :
                                          tmp = {
                                            NAME: "CallToActionLinkD6a3d61ContentType",
                                            VAL: {
                                              callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                            }
                                          };
                                          break;
                                      case "NavbarSelectCtaContentType" :
                                          tmp = {
                                            NAME: "NavbarSelectCtaContentType",
                                            VAL: {
                                              navbarSelectCTA: Gql__NavbarSelectCta__Fragment.NavbarSelectCTA.verifyArgsAndParse("NavbarSelectCTA", value)
                                            }
                                          };
                                          break;
                                      default:
                                        tmp = {
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        };
                                    }
                                    return tmp;
                                  }), value$26) : undefined
                        };
                      }
                      var value$27 = value.not_logged_in_navbar_menu_items___business;
                      var tmp$14;
                      if (value$27 == null) {
                        tmp$14 = undefined;
                      } else {
                        var value$28 = value$27.value;
                        tmp$14 = {
                          __typename: value$27.__typename,
                          value: !(value$28 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                          NAME: "CallToActionLinkD6a3d61ContentType",
                                          VAL: {
                                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$28) : undefined
                        };
                      }
                      var value$29 = value.search_bar_placeholder_text;
                      var tmp$15;
                      if (value$29 == null) {
                        tmp$15 = undefined;
                      } else {
                        var value$30 = value$29.value;
                        tmp$15 = {
                          __typename: value$29.__typename,
                          value: !(value$30 == null) ? value$30 : undefined
                        };
                      }
                      var value$31 = value.no_search_result;
                      var tmp$16;
                      if (value$31 == null) {
                        tmp$16 = undefined;
                      } else {
                        var value$32 = value$31.value;
                        tmp$16 = {
                          __typename: value$31.__typename,
                          value: !(value$32 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "NoSearchResultsContentType" ? ({
                                          NAME: "NoSearchResultsContentType",
                                          VAL: {
                                            noSearchResults: Gql__NoSearchResults__Fragment.NoSearchResults.verifyArgsAndParse("NoSearchResults", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$32) : undefined
                        };
                      }
                      var value$33 = value.sold_out_badge_text;
                      var tmp$17;
                      if (value$33 == null) {
                        tmp$17 = undefined;
                      } else {
                        var value$34 = value$33.value;
                        tmp$17 = {
                          __typename: value$33.__typename,
                          value: !(value$34 == null) ? value$34 : undefined
                        };
                      }
                      tmp = {
                        NAME: "GlobalConfigurationContentType",
                        VAL: {
                          __typename: value.__typename,
                          gdpr_cookie_popup: tmp$1,
                          alert_banner: tmp$2,
                          navigation_root: tmp$3,
                          business_navigation: tmp$4,
                          desktop_navbar_menu_items: tmp$5,
                          mobile_navbar_menu_items: tmp$6,
                          footer: tmp$7,
                          business_footer: tmp$8,
                          login: tmp$9,
                          register: tmp$10,
                          warnings: tmp$11,
                          header_logo: tmp$12,
                          not_logged_in_navbar_menu_items___consumer: tmp$13,
                          not_logged_in_navbar_menu_items___business: tmp$14,
                          search_bar_placeholder_text: tmp$15,
                          no_search_result: tmp$16,
                          sold_out_badge_text: tmp$17
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$1) : undefined,
          dictionaryItems: !(value$2 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "DictionaryItemContentType") {
                      var value$1 = value.system;
                      var value$2 = value.value;
                      var tmp$1;
                      if (value$2 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$3 = value$2.value;
                        tmp$1 = {
                          __typename: value$2.__typename,
                          value: !(value$3 == null) ? value$3 : undefined
                        };
                      }
                      tmp = {
                        NAME: "DictionaryItemContentType",
                        VAL: {
                          __typename: value.__typename,
                          system: {
                            __typename: value$1.__typename,
                            id: value$1.id,
                            codename: value$1.codename
                          },
                          value: tmp$1
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$2) : undefined,
          locations: !(value$3 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "ModalChangeLocationContentType") {
                      var value$1 = value.system;
                      var value$2 = value.title;
                      var tmp$1;
                      if (value$2 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$3 = value$2.value;
                        tmp$1 = {
                          __typename: value$2.__typename,
                          value: !(value$3 == null) ? value$3 : undefined
                        };
                      }
                      var value$4 = value.subtitle;
                      var tmp$2;
                      if (value$4 == null) {
                        tmp$2 = undefined;
                      } else {
                        var value$5 = value$4.value;
                        tmp$2 = {
                          __typename: value$4.__typename,
                          value: !(value$5 == null) ? value$5 : undefined
                        };
                      }
                      var value$6 = value.cta_text;
                      var tmp$3;
                      if (value$6 == null) {
                        tmp$3 = undefined;
                      } else {
                        var value$7 = value$6.value;
                        tmp$3 = {
                          __typename: value$6.__typename,
                          value: !(value$7 == null) ? value$7 : undefined
                        };
                      }
                      var value$8 = value.region_location;
                      var tmp$4;
                      if (value$8 == null) {
                        tmp$4 = undefined;
                      } else {
                        var value$9 = value$8.value;
                        tmp$4 = {
                          __typename: value$8.__typename,
                          value: !(value$9 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    if (typename === "RegionLocationContentType") {
                                      var value$1 = value.system;
                                      var value$2 = value.currency;
                                      var tmp$1;
                                      if (value$2 == null) {
                                        tmp$1 = undefined;
                                      } else {
                                        var value$3 = value$2.value;
                                        tmp$1 = {
                                          __typename: value$2.__typename,
                                          value: !(value$3 == null) ? value$3 : undefined
                                        };
                                      }
                                      var value$4 = value.country_name;
                                      var tmp$2;
                                      if (value$4 == null) {
                                        tmp$2 = undefined;
                                      } else {
                                        var value$5 = value$4.value;
                                        tmp$2 = {
                                          __typename: value$4.__typename,
                                          value: !(value$5 == null) ? value$5 : undefined
                                        };
                                      }
                                      var value$6 = value.country_flag;
                                      var tmp$3;
                                      if (value$6 == null) {
                                        tmp$3 = undefined;
                                      } else {
                                        var value$7 = value$6.value;
                                        tmp$3 = {
                                          __typename: value$6.__typename,
                                          value: !(value$7 == null) ? Js_array.map((function (value) {
                                                    if (value == null) {
                                                      return ;
                                                    }
                                                    var value$1 = value.name;
                                                    var value$2 = value.url;
                                                    return {
                                                            __typename: value.__typename,
                                                            name: !(value$1 == null) ? value$1 : undefined,
                                                            url: !(value$2 == null) ? value$2 : undefined
                                                          };
                                                  }), value$7) : undefined
                                        };
                                      }
                                      var value$8 = value.url;
                                      var tmp$4;
                                      if (value$8 == null) {
                                        tmp$4 = undefined;
                                      } else {
                                        var value$9 = value$8.value;
                                        tmp$4 = {
                                          __typename: value$8.__typename,
                                          value: !(value$9 == null) ? value$9 : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "RegionLocationContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$1.__typename,
                                            id: value$1.id,
                                            codename: value$1.codename
                                          },
                                          currency: tmp$1,
                                          country_name: tmp$2,
                                          country_flag: tmp$3,
                                          url: tmp$4
                                        }
                                      };
                                    } else {
                                      tmp = {
                                        NAME: "UnspecifiedFragment",
                                        VAL: typename
                                      };
                                    }
                                    return tmp;
                                  }), value$9) : undefined
                        };
                      }
                      tmp = {
                        NAME: "ModalChangeLocationContentType",
                        VAL: {
                          __typename: value.__typename,
                          system: {
                            __typename: value$1.__typename,
                            id: value$1.id,
                            codename: value$1.codename
                          },
                          title: tmp$1,
                          subtitle: tmp$2,
                          cta_text: tmp$3,
                          region_location: tmp$4
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$3) : undefined,
          giftLandingPage: !(value$4 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "GiftLandingPageContentType") {
                      var value$1 = value.system;
                      var value$2 = value.classic_logo_asset;
                      var tmp$1;
                      if (value$2 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$3 = value$2.value;
                        tmp$1 = {
                          __typename: value$2.__typename,
                          value: !(value$3 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.name;
                                    var value$2 = value.description;
                                    var value$3 = value.url;
                                    var value$4 = value.width;
                                    var value$5 = value.height;
                                    return {
                                            __typename: value.__typename,
                                            name: !(value$1 == null) ? value$1 : undefined,
                                            description: !(value$2 == null) ? value$2 : undefined,
                                            url: !(value$3 == null) ? value$3 : undefined,
                                            width: !(value$4 == null) ? value$4 : undefined,
                                            height: !(value$5 == null) ? value$5 : undefined
                                          };
                                  }), value$3) : undefined
                        };
                      }
                      var value$4 = value.business_logo_asset;
                      var tmp$2;
                      if (value$4 == null) {
                        tmp$2 = undefined;
                      } else {
                        var value$5 = value$4.value;
                        tmp$2 = {
                          __typename: value$4.__typename,
                          value: !(value$5 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.name;
                                    var value$2 = value.description;
                                    var value$3 = value.url;
                                    var value$4 = value.width;
                                    var value$5 = value.height;
                                    return {
                                            __typename: value.__typename,
                                            name: !(value$1 == null) ? value$1 : undefined,
                                            description: !(value$2 == null) ? value$2 : undefined,
                                            url: !(value$3 == null) ? value$3 : undefined,
                                            width: !(value$4 == null) ? value$4 : undefined,
                                            height: !(value$5 == null) ? value$5 : undefined
                                          };
                                  }), value$5) : undefined
                        };
                      }
                      var value$6 = value.sender_message;
                      var tmp$3;
                      if (value$6 == null) {
                        tmp$3 = undefined;
                      } else {
                        var value$7 = value$6.value;
                        tmp$3 = {
                          __typename: value$6.__typename,
                          value: !(value$7 == null) ? value$7 : undefined
                        };
                      }
                      var value$8 = value.gift_reveal_cta_text;
                      var tmp$4;
                      if (value$8 == null) {
                        tmp$4 = undefined;
                      } else {
                        var value$9 = value$8.value;
                        tmp$4 = {
                          __typename: value$8.__typename,
                          value: !(value$9 == null) ? value$9 : undefined
                        };
                      }
                      var value$10 = value.confetti_lottie;
                      var tmp$5;
                      if (value$10 == null) {
                        tmp$5 = undefined;
                      } else {
                        var value$11 = value$10.value;
                        tmp$5 = {
                          __typename: value$10.__typename,
                          value: !(value$11 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$11) : undefined
                        };
                      }
                      var value$12 = value.faqs;
                      var tmp$6;
                      if (value$12 == null) {
                        tmp$6 = undefined;
                      } else {
                        var value$13 = value$12.value;
                        tmp$6 = {
                          __typename: value$12.__typename,
                          value: !(value$13 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp;
                                    if (typename === "GiftLandingPageFaqItemContentType") {
                                      var value$1 = value.system;
                                      var value$2 = value.title;
                                      var tmp$1;
                                      if (value$2 == null) {
                                        tmp$1 = undefined;
                                      } else {
                                        var value$3 = value$2.value;
                                        tmp$1 = {
                                          __typename: value$2.__typename,
                                          value: !(value$3 == null) ? value$3 : undefined
                                        };
                                      }
                                      var value$4 = value.body_text;
                                      var tmp$2;
                                      if (value$4 == null) {
                                        tmp$2 = undefined;
                                      } else {
                                        var value$5 = value$4.value;
                                        tmp$2 = {
                                          __typename: value$4.__typename,
                                          value: !(value$5 == null) ? value$5 : undefined
                                        };
                                      }
                                      var value$6 = value.include_how_to_use;
                                      var tmp$3;
                                      if (value$6 == null) {
                                        tmp$3 = undefined;
                                      } else {
                                        var value$7 = value$6.value;
                                        tmp$3 = {
                                          __typename: value$6.__typename,
                                          value: !(value$7 == null) ? Js_array.map((function (value) {
                                                    if (value == null) {
                                                      return ;
                                                    }
                                                    var value$1 = value.codename;
                                                    return {
                                                            __typename: value.__typename,
                                                            codename: !(value$1 == null) ? value$1 : undefined
                                                          };
                                                  }), value$7) : undefined
                                        };
                                      }
                                      var value$8 = value.include_pdf_download;
                                      var tmp$4;
                                      if (value$8 == null) {
                                        tmp$4 = undefined;
                                      } else {
                                        var value$9 = value$8.value;
                                        tmp$4 = {
                                          __typename: value$8.__typename,
                                          value: !(value$9 == null) ? Js_array.map((function (value) {
                                                    if (value == null) {
                                                      return ;
                                                    }
                                                    var value$1 = value.codename;
                                                    return {
                                                            __typename: value.__typename,
                                                            codename: !(value$1 == null) ? value$1 : undefined
                                                          };
                                                  }), value$9) : undefined
                                        };
                                      }
                                      var value$10 = value.include_card_merchant_terms;
                                      var tmp$5;
                                      if (value$10 == null) {
                                        tmp$5 = undefined;
                                      } else {
                                        var value$11 = value$10.value;
                                        tmp$5 = {
                                          __typename: value$10.__typename,
                                          value: !(value$11 == null) ? Js_array.map((function (value) {
                                                    if (value == null) {
                                                      return ;
                                                    }
                                                    var value$1 = value.codename;
                                                    return {
                                                            __typename: value.__typename,
                                                            codename: !(value$1 == null) ? value$1 : undefined
                                                          };
                                                  }), value$11) : undefined
                                        };
                                      }
                                      tmp = {
                                        NAME: "GiftLandingPageFaqItemContentType",
                                        VAL: {
                                          __typename: value.__typename,
                                          system: {
                                            __typename: value$1.__typename,
                                            codename: value$1.codename,
                                            id: value$1.id
                                          },
                                          title: tmp$1,
                                          body_text: tmp$2,
                                          include_how_to_use: tmp$3,
                                          include_pdf_download: tmp$4,
                                          include_card_merchant_terms: tmp$5
                                        }
                                      };
                                    } else {
                                      tmp = {
                                        NAME: "UnspecifiedFragment",
                                        VAL: typename
                                      };
                                    }
                                    return tmp;
                                  }), value$13) : undefined
                        };
                      }
                      var value$14 = value.no_expiry_text;
                      var tmp$7;
                      if (value$14 == null) {
                        tmp$7 = undefined;
                      } else {
                        var value$15 = value$14.value;
                        tmp$7 = {
                          __typename: value$14.__typename,
                          value: !(value$15 == null) ? value$15 : undefined
                        };
                      }
                      var value$16 = value.expiry_label;
                      var tmp$8;
                      if (value$16 == null) {
                        tmp$8 = undefined;
                      } else {
                        var value$17 = value$16.value;
                        tmp$8 = {
                          __typename: value$16.__typename,
                          value: !(value$17 == null) ? value$17 : undefined
                        };
                      }
                      var value$18 = value.check_balance_text;
                      var tmp$9;
                      if (value$18 == null) {
                        tmp$9 = undefined;
                      } else {
                        var value$19 = value$18.value;
                        tmp$9 = {
                          __typename: value$18.__typename,
                          value: !(value$19 == null) ? value$19 : undefined
                        };
                      }
                      var value$20 = value.check_balance_icon_asset;
                      var tmp$10;
                      if (value$20 == null) {
                        tmp$10 = undefined;
                      } else {
                        var value$21 = value$20.value;
                        tmp$10 = {
                          __typename: value$20.__typename,
                          value: !(value$21 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.name;
                                    var value$2 = value.description;
                                    var value$3 = value.url;
                                    var value$4 = value.width;
                                    var value$5 = value.height;
                                    return {
                                            __typename: value.__typename,
                                            name: !(value$1 == null) ? value$1 : undefined,
                                            description: !(value$2 == null) ? value$2 : undefined,
                                            url: !(value$3 == null) ? value$3 : undefined,
                                            width: !(value$4 == null) ? value$4 : undefined,
                                            height: !(value$5 == null) ? value$5 : undefined
                                          };
                                  }), value$21) : undefined
                        };
                      }
                      var value$22 = value.store_in_wallet_cta_title;
                      var tmp$11;
                      if (value$22 == null) {
                        tmp$11 = undefined;
                      } else {
                        var value$23 = value$22.value;
                        tmp$11 = {
                          __typename: value$22.__typename,
                          value: !(value$23 == null) ? value$23 : undefined
                        };
                      }
                      var value$24 = value.swap_egift_card_cta_title;
                      var tmp$12;
                      if (value$24 == null) {
                        tmp$12 = undefined;
                      } else {
                        var value$25 = value$24.value;
                        tmp$12 = {
                          __typename: value$24.__typename,
                          value: !(value$25 == null) ? value$25 : undefined
                        };
                      }
                      var value$26 = value.optional_gift_card_cta;
                      var tmp$13;
                      if (value$26 == null) {
                        tmp$13 = undefined;
                      } else {
                        var value$27 = value$26.value;
                        tmp$13 = {
                          __typename: value$26.__typename,
                          value: !(value$27 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                          NAME: "CallToActionLinkD6a3d61ContentType",
                                          VAL: {
                                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$27) : undefined
                        };
                      }
                      var value$28 = value.gift_unwrap_cta_text;
                      var tmp$14;
                      if (value$28 == null) {
                        tmp$14 = undefined;
                      } else {
                        var value$29 = value$28.value;
                        tmp$14 = {
                          __typename: value$28.__typename,
                          value: !(value$29 == null) ? value$29 : undefined
                        };
                      }
                      var value$30 = value.gift_unwrap_lottie_segments;
                      var tmp$15;
                      if (value$30 == null) {
                        tmp$15 = undefined;
                      } else {
                        var value$31 = value$30.value;
                        tmp$15 = {
                          __typename: value$30.__typename,
                          value: !(value$31 == null) ? value$31 : undefined
                        };
                      }
                      var value$32 = value.classic_gift_unwrap_lottie;
                      var tmp$16;
                      if (value$32 == null) {
                        tmp$16 = undefined;
                      } else {
                        var value$33 = value$32.value;
                        tmp$16 = {
                          __typename: value$32.__typename,
                          value: !(value$33 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$33) : undefined
                        };
                      }
                      var value$34 = value.business_gift_unwrap_lottie;
                      var tmp$17;
                      if (value$34 == null) {
                        tmp$17 = undefined;
                      } else {
                        var value$35 = value$34.value;
                        tmp$17 = {
                          __typename: value$34.__typename,
                          value: !(value$35 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.url;
                                    return {
                                            __typename: value.__typename,
                                            url: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$35) : undefined
                        };
                      }
                      var value$36 = value.replay_button_icon_asset;
                      var tmp$18;
                      if (value$36 == null) {
                        tmp$18 = undefined;
                      } else {
                        var value$37 = value$36.value;
                        tmp$18 = {
                          __typename: value$36.__typename,
                          value: !(value$37 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.name;
                                    var value$2 = value.description;
                                    var value$3 = value.url;
                                    var value$4 = value.width;
                                    var value$5 = value.height;
                                    return {
                                            __typename: value.__typename,
                                            name: !(value$1 == null) ? value$1 : undefined,
                                            description: !(value$2 == null) ? value$2 : undefined,
                                            url: !(value$3 == null) ? value$3 : undefined,
                                            width: !(value$4 == null) ? value$4 : undefined,
                                            height: !(value$5 == null) ? value$5 : undefined
                                          };
                                  }), value$37) : undefined
                        };
                      }
                      var value$38 = value.replay_button_text;
                      var tmp$19;
                      if (value$38 == null) {
                        tmp$19 = undefined;
                      } else {
                        var value$39 = value$38.value;
                        tmp$19 = {
                          __typename: value$38.__typename,
                          value: !(value$39 == null) ? value$39 : undefined
                        };
                      }
                      var value$40 = value.redeem_online_text;
                      var tmp$20;
                      if (value$40 == null) {
                        tmp$20 = undefined;
                      } else {
                        var value$41 = value$40.value;
                        tmp$20 = {
                          __typename: value$40.__typename,
                          value: !(value$41 == null) ? value$41 : undefined
                        };
                      }
                      var value$42 = value.redeem_online_text_colour;
                      var tmp$21;
                      if (value$42 == null) {
                        tmp$21 = undefined;
                      } else {
                        var value$43 = value$42.value;
                        tmp$21 = {
                          __typename: value$42.__typename,
                          value: !(value$43 == null) ? value$43 : undefined
                        };
                      }
                      var value$44 = value.redeem_online_button;
                      var tmp$22;
                      if (value$44 == null) {
                        tmp$22 = undefined;
                      } else {
                        var value$45 = value$44.value;
                        tmp$22 = {
                          __typename: value$44.__typename,
                          value: !(value$45 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                          NAME: "CallToActionLinkD6a3d61ContentType",
                                          VAL: {
                                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$45) : undefined
                        };
                      }
                      var value$46 = value.download_option_text;
                      var tmp$23;
                      if (value$46 == null) {
                        tmp$23 = undefined;
                      } else {
                        var value$47 = value$46.value;
                        tmp$23 = {
                          __typename: value$46.__typename,
                          value: !(value$47 == null) ? value$47 : undefined
                        };
                      }
                      var value$48 = value.download_option_text_colour;
                      var tmp$24;
                      if (value$48 == null) {
                        tmp$24 = undefined;
                      } else {
                        var value$49 = value$48.value;
                        tmp$24 = {
                          __typename: value$48.__typename,
                          value: !(value$49 == null) ? value$49 : undefined
                        };
                      }
                      var value$50 = value.download_button;
                      var tmp$25;
                      if (value$50 == null) {
                        tmp$25 = undefined;
                      } else {
                        var value$51 = value$50.value;
                        tmp$25 = {
                          __typename: value$50.__typename,
                          value: !(value$51 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                          NAME: "CallToActionLinkD6a3d61ContentType",
                                          VAL: {
                                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$51) : undefined
                        };
                      }
                      var value$52 = value.spend_it_now_heading;
                      var tmp$26;
                      if (value$52 == null) {
                        tmp$26 = undefined;
                      } else {
                        var value$53 = value$52.value;
                        tmp$26 = {
                          __typename: value$52.__typename,
                          value: !(value$53 == null) ? value$53 : undefined
                        };
                      }
                      var value$54 = value.clipboard_item_copy_text;
                      var tmp$27;
                      if (value$54 == null) {
                        tmp$27 = undefined;
                      } else {
                        var value$55 = value$54.value;
                        tmp$27 = {
                          __typename: value$54.__typename,
                          value: !(value$55 == null) ? value$55 : undefined
                        };
                      }
                      var value$56 = value.clipboard_item_copied_text;
                      var tmp$28;
                      if (value$56 == null) {
                        tmp$28 = undefined;
                      } else {
                        var value$57 = value$56.value;
                        tmp$28 = {
                          __typename: value$56.__typename,
                          value: !(value$57 == null) ? value$57 : undefined
                        };
                      }
                      var value$58 = value.barcode_number_clipboard_heading;
                      var tmp$29;
                      if (value$58 == null) {
                        tmp$29 = undefined;
                      } else {
                        var value$59 = value$58.value;
                        tmp$29 = {
                          __typename: value$58.__typename,
                          value: !(value$59 == null) ? value$59 : undefined
                        };
                      }
                      var value$60 = value.in_store_number_clipboard_heading;
                      var tmp$30;
                      if (value$60 == null) {
                        tmp$30 = undefined;
                      } else {
                        var value$61 = value$60.value;
                        tmp$30 = {
                          __typename: value$60.__typename,
                          value: !(value$61 == null) ? value$61 : undefined
                        };
                      }
                      var value$62 = value.online_number_clipboard_heading;
                      var tmp$31;
                      if (value$62 == null) {
                        tmp$31 = undefined;
                      } else {
                        var value$63 = value$62.value;
                        tmp$31 = {
                          __typename: value$62.__typename,
                          value: !(value$63 == null) ? value$63 : undefined
                        };
                      }
                      var value$64 = value.online_and_in_store_clipboard_heading;
                      var tmp$32;
                      if (value$64 == null) {
                        tmp$32 = undefined;
                      } else {
                        var value$65 = value$64.value;
                        tmp$32 = {
                          __typename: value$64.__typename,
                          value: !(value$65 == null) ? value$65 : undefined
                        };
                      }
                      var value$66 = value.swap_cards_title;
                      var tmp$33;
                      if (value$66 == null) {
                        tmp$33 = undefined;
                      } else {
                        var value$67 = value$66.value;
                        tmp$33 = {
                          __typename: value$66.__typename,
                          value: !(value$67 == null) ? value$67 : undefined
                        };
                      }
                      var value$68 = value.swap_card_alt;
                      var tmp$34;
                      if (value$68 == null) {
                        tmp$34 = undefined;
                      } else {
                        var value$69 = value$68.value;
                        tmp$34 = {
                          __typename: value$68.__typename,
                          value: !(value$69 == null) ? value$69 : undefined
                        };
                      }
                      var value$70 = value.swap_card_original_value_label;
                      var tmp$35;
                      if (value$70 == null) {
                        tmp$35 = undefined;
                      } else {
                        var value$71 = value$70.value;
                        tmp$35 = {
                          __typename: value$70.__typename,
                          value: !(value$71 == null) ? value$71 : undefined
                        };
                      }
                      var value$72 = value.swap_card_button_style;
                      var tmp$36;
                      if (value$72 == null) {
                        tmp$36 = undefined;
                      } else {
                        var value$73 = value$72.value;
                        tmp$36 = {
                          __typename: value$72.__typename,
                          value: !(value$73 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "CallToActionLinkD6a3d61ContentType" ? ({
                                          NAME: "CallToActionLinkD6a3d61ContentType",
                                          VAL: {
                                            callToAction: Gql__CallToAction__Fragment.CallToAction.verifyArgsAndParse("CallToAction", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$73) : undefined
                        };
                      }
                      var value$74 = value.save_in_apple_google_wallet_text;
                      var tmp$37;
                      if (value$74 == null) {
                        tmp$37 = undefined;
                      } else {
                        var value$75 = value$74.value;
                        tmp$37 = {
                          __typename: value$74.__typename,
                          value: !(value$75 == null) ? value$75 : undefined
                        };
                      }
                      var value$76 = value.save_in_apple_google_wallet_text_colour;
                      var tmp$38;
                      if (value$76 == null) {
                        tmp$38 = undefined;
                      } else {
                        var value$77 = value$76.value;
                        tmp$38 = {
                          __typename: value$76.__typename,
                          value: !(value$77 == null) ? value$77 : undefined
                        };
                      }
                      var value$78 = value.google_wallet_image_asset;
                      var tmp$39;
                      if (value$78 == null) {
                        tmp$39 = undefined;
                      } else {
                        var value$79 = value$78.value;
                        tmp$39 = {
                          __typename: value$78.__typename,
                          value: !(value$79 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.name;
                                    var value$2 = value.description;
                                    var value$3 = value.url;
                                    var value$4 = value.width;
                                    var value$5 = value.height;
                                    return {
                                            __typename: value.__typename,
                                            name: !(value$1 == null) ? value$1 : undefined,
                                            description: !(value$2 == null) ? value$2 : undefined,
                                            url: !(value$3 == null) ? value$3 : undefined,
                                            width: !(value$4 == null) ? value$4 : undefined,
                                            height: !(value$5 == null) ? value$5 : undefined
                                          };
                                  }), value$79) : undefined
                        };
                      }
                      var value$80 = value.apple_wallet_image_asset;
                      var tmp$40;
                      if (value$80 == null) {
                        tmp$40 = undefined;
                      } else {
                        var value$81 = value$80.value;
                        tmp$40 = {
                          __typename: value$80.__typename,
                          value: !(value$81 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var value$1 = value.name;
                                    var value$2 = value.description;
                                    var value$3 = value.url;
                                    var value$4 = value.width;
                                    var value$5 = value.height;
                                    return {
                                            __typename: value.__typename,
                                            name: !(value$1 == null) ? value$1 : undefined,
                                            description: !(value$2 == null) ? value$2 : undefined,
                                            url: !(value$3 == null) ? value$3 : undefined,
                                            width: !(value$4 == null) ? value$4 : undefined,
                                            height: !(value$5 == null) ? value$5 : undefined
                                          };
                                  }), value$81) : undefined
                        };
                      }
                      var value$82 = value.footer_copyright_text;
                      var tmp$41;
                      if (value$82 == null) {
                        tmp$41 = undefined;
                      } else {
                        var value$83 = value$82.value;
                        tmp$41 = {
                          __typename: value$82.__typename,
                          value: !(value$83 == null) ? value$83 : undefined
                        };
                      }
                      var value$84 = value.footer_download_text;
                      var tmp$42;
                      if (value$84 == null) {
                        tmp$42 = undefined;
                      } else {
                        var value$85 = value$84.value;
                        tmp$42 = {
                          __typename: value$84.__typename,
                          value: !(value$85 == null) ? value$85 : undefined
                        };
                      }
                      var value$86 = value.app_store_icons;
                      var tmp$43;
                      if (value$86 == null) {
                        tmp$43 = undefined;
                      } else {
                        var value$87 = value$86.value;
                        tmp$43 = {
                          __typename: value$86.__typename,
                          value: !(value$87 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "AppStoreItemContentType" ? ({
                                          NAME: "AppStoreItemContentType",
                                          VAL: {
                                            appStoreItem: Gql__AppStoreItem__Fragment.AppStoreItem.verifyArgsAndParse("AppStoreItem", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$87) : undefined
                        };
                      }
                      var value$88 = value.create_account_modal_title;
                      var tmp$44;
                      if (value$88 == null) {
                        tmp$44 = undefined;
                      } else {
                        var value$89 = value$88.value;
                        tmp$44 = {
                          __typename: value$88.__typename,
                          value: !(value$89 == null) ? value$89 : undefined
                        };
                      }
                      var value$90 = value.create_account_modal_list;
                      var tmp$45;
                      if (value$90 == null) {
                        tmp$45 = undefined;
                      } else {
                        var value$91 = value$90.value;
                        tmp$45 = {
                          __typename: value$90.__typename,
                          value: !(value$91 == null) ? value$91 : undefined
                        };
                      }
                      var value$92 = value.create_account_modal_cta_text;
                      var tmp$46;
                      if (value$92 == null) {
                        tmp$46 = undefined;
                      } else {
                        var value$93 = value$92.value;
                        tmp$46 = {
                          __typename: value$92.__typename,
                          value: !(value$93 == null) ? value$93 : undefined
                        };
                      }
                      tmp = {
                        NAME: "GiftLandingPageContentType",
                        VAL: {
                          __typename: value.__typename,
                          system: {
                            __typename: value$1.__typename,
                            codename: value$1.codename,
                            id: value$1.id
                          },
                          classic_logo_asset: tmp$1,
                          business_logo_asset: tmp$2,
                          sender_message: tmp$3,
                          gift_reveal_cta_text: tmp$4,
                          confetti_lottie: tmp$5,
                          faqs: tmp$6,
                          no_expiry_text: tmp$7,
                          expiry_label: tmp$8,
                          check_balance_text: tmp$9,
                          check_balance_icon_asset: tmp$10,
                          store_in_wallet_cta_title: tmp$11,
                          swap_egift_card_cta_title: tmp$12,
                          optional_gift_card_cta: tmp$13,
                          gift_unwrap_cta_text: tmp$14,
                          gift_unwrap_lottie_segments: tmp$15,
                          classic_gift_unwrap_lottie: tmp$16,
                          business_gift_unwrap_lottie: tmp$17,
                          replay_button_icon_asset: tmp$18,
                          replay_button_text: tmp$19,
                          redeem_online_text: tmp$20,
                          redeem_online_text_colour: tmp$21,
                          redeem_online_button: tmp$22,
                          download_option_text: tmp$23,
                          download_option_text_colour: tmp$24,
                          download_button: tmp$25,
                          spend_it_now_heading: tmp$26,
                          clipboard_item_copy_text: tmp$27,
                          clipboard_item_copied_text: tmp$28,
                          barcode_number_clipboard_heading: tmp$29,
                          in_store_number_clipboard_heading: tmp$30,
                          online_number_clipboard_heading: tmp$31,
                          online_and_in_store_clipboard_heading: tmp$32,
                          swap_cards_title: tmp$33,
                          swap_card_alt: tmp$34,
                          swap_card_original_value_label: tmp$35,
                          swap_card_button_style: tmp$36,
                          save_in_apple_google_wallet_text: tmp$37,
                          save_in_apple_google_wallet_text_colour: tmp$38,
                          google_wallet_image_asset: tmp$39,
                          apple_wallet_image_asset: tmp$40,
                          footer_copyright_text: tmp$41,
                          footer_download_text: tmp$42,
                          app_store_icons: tmp$43,
                          create_account_modal_title: tmp$44,
                          create_account_modal_list: tmp$45,
                          create_account_modal_cta_text: tmp$46
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$4) : undefined
        };
}

function serialize(value) {
  var value$1 = value.giftLandingPage;
  var giftLandingPage = value$1 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME !== "GiftLandingPageContentType") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.create_account_modal_cta_text;
            var create_account_modal_cta_text;
            if (value$2 !== undefined) {
              var value$3 = value$2.value;
              var value$4 = value$3 !== undefined ? value$3 : null;
              var value$5 = value$2.__typename;
              create_account_modal_cta_text = {
                __typename: value$5,
                value: value$4
              };
            } else {
              create_account_modal_cta_text = null;
            }
            var value$6 = value$1.create_account_modal_list;
            var create_account_modal_list;
            if (value$6 !== undefined) {
              var value$7 = value$6.value;
              var value$8 = value$7 !== undefined ? value$7 : null;
              var value$9 = value$6.__typename;
              create_account_modal_list = {
                __typename: value$9,
                value: value$8
              };
            } else {
              create_account_modal_list = null;
            }
            var value$10 = value$1.create_account_modal_title;
            var create_account_modal_title;
            if (value$10 !== undefined) {
              var value$11 = value$10.value;
              var value$12 = value$11 !== undefined ? value$11 : null;
              var value$13 = value$10.__typename;
              create_account_modal_title = {
                __typename: value$13,
                value: value$12
              };
            } else {
              create_account_modal_title = null;
            }
            var value$14 = value$1.app_store_icons;
            var app_store_icons;
            if (value$14 !== undefined) {
              var value$15 = value$14.value;
              var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__AppStoreItem__Fragment.AppStoreItem.serialize(value.VAL.appStoreItem)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$15) : null;
              var value$17 = value$14.__typename;
              app_store_icons = {
                __typename: value$17,
                value: value$16
              };
            } else {
              app_store_icons = null;
            }
            var value$18 = value$1.footer_download_text;
            var footer_download_text;
            if (value$18 !== undefined) {
              var value$19 = value$18.value;
              var value$20 = value$19 !== undefined ? value$19 : null;
              var value$21 = value$18.__typename;
              footer_download_text = {
                __typename: value$21,
                value: value$20
              };
            } else {
              footer_download_text = null;
            }
            var value$22 = value$1.footer_copyright_text;
            var footer_copyright_text;
            if (value$22 !== undefined) {
              var value$23 = value$22.value;
              var value$24 = value$23 !== undefined ? value$23 : null;
              var value$25 = value$22.__typename;
              footer_copyright_text = {
                __typename: value$25,
                value: value$24
              };
            } else {
              footer_copyright_text = null;
            }
            var value$26 = value$1.apple_wallet_image_asset;
            var apple_wallet_image_asset;
            if (value$26 !== undefined) {
              var value$27 = value$26.value;
              var value$28 = value$27 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.height;
                        var height = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.width;
                        var width = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.url;
                        var url = value$3 !== undefined ? value$3 : null;
                        var value$4 = value.description;
                        var description = value$4 !== undefined ? value$4 : null;
                        var value$5 = value.name;
                        var name = value$5 !== undefined ? value$5 : null;
                        var value$6 = value.__typename;
                        return {
                                __typename: value$6,
                                name: name,
                                description: description,
                                url: url,
                                width: width,
                                height: height
                              };
                      }), value$27) : null;
              var value$29 = value$26.__typename;
              apple_wallet_image_asset = {
                __typename: value$29,
                value: value$28
              };
            } else {
              apple_wallet_image_asset = null;
            }
            var value$30 = value$1.google_wallet_image_asset;
            var google_wallet_image_asset;
            if (value$30 !== undefined) {
              var value$31 = value$30.value;
              var value$32 = value$31 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.height;
                        var height = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.width;
                        var width = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.url;
                        var url = value$3 !== undefined ? value$3 : null;
                        var value$4 = value.description;
                        var description = value$4 !== undefined ? value$4 : null;
                        var value$5 = value.name;
                        var name = value$5 !== undefined ? value$5 : null;
                        var value$6 = value.__typename;
                        return {
                                __typename: value$6,
                                name: name,
                                description: description,
                                url: url,
                                width: width,
                                height: height
                              };
                      }), value$31) : null;
              var value$33 = value$30.__typename;
              google_wallet_image_asset = {
                __typename: value$33,
                value: value$32
              };
            } else {
              google_wallet_image_asset = null;
            }
            var value$34 = value$1.save_in_apple_google_wallet_text_colour;
            var save_in_apple_google_wallet_text_colour;
            if (value$34 !== undefined) {
              var value$35 = value$34.value;
              var value$36 = value$35 !== undefined ? value$35 : null;
              var value$37 = value$34.__typename;
              save_in_apple_google_wallet_text_colour = {
                __typename: value$37,
                value: value$36
              };
            } else {
              save_in_apple_google_wallet_text_colour = null;
            }
            var value$38 = value$1.save_in_apple_google_wallet_text;
            var save_in_apple_google_wallet_text;
            if (value$38 !== undefined) {
              var value$39 = value$38.value;
              var value$40 = value$39 !== undefined ? value$39 : null;
              var value$41 = value$38.__typename;
              save_in_apple_google_wallet_text = {
                __typename: value$41,
                value: value$40
              };
            } else {
              save_in_apple_google_wallet_text = null;
            }
            var value$42 = value$1.swap_card_button_style;
            var swap_card_button_style;
            if (value$42 !== undefined) {
              var value$43 = value$42.value;
              var value$44 = value$43 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$43) : null;
              var value$45 = value$42.__typename;
              swap_card_button_style = {
                __typename: value$45,
                value: value$44
              };
            } else {
              swap_card_button_style = null;
            }
            var value$46 = value$1.swap_card_original_value_label;
            var swap_card_original_value_label;
            if (value$46 !== undefined) {
              var value$47 = value$46.value;
              var value$48 = value$47 !== undefined ? value$47 : null;
              var value$49 = value$46.__typename;
              swap_card_original_value_label = {
                __typename: value$49,
                value: value$48
              };
            } else {
              swap_card_original_value_label = null;
            }
            var value$50 = value$1.swap_card_alt;
            var swap_card_alt;
            if (value$50 !== undefined) {
              var value$51 = value$50.value;
              var value$52 = value$51 !== undefined ? value$51 : null;
              var value$53 = value$50.__typename;
              swap_card_alt = {
                __typename: value$53,
                value: value$52
              };
            } else {
              swap_card_alt = null;
            }
            var value$54 = value$1.swap_cards_title;
            var swap_cards_title;
            if (value$54 !== undefined) {
              var value$55 = value$54.value;
              var value$56 = value$55 !== undefined ? value$55 : null;
              var value$57 = value$54.__typename;
              swap_cards_title = {
                __typename: value$57,
                value: value$56
              };
            } else {
              swap_cards_title = null;
            }
            var value$58 = value$1.online_and_in_store_clipboard_heading;
            var online_and_in_store_clipboard_heading;
            if (value$58 !== undefined) {
              var value$59 = value$58.value;
              var value$60 = value$59 !== undefined ? value$59 : null;
              var value$61 = value$58.__typename;
              online_and_in_store_clipboard_heading = {
                __typename: value$61,
                value: value$60
              };
            } else {
              online_and_in_store_clipboard_heading = null;
            }
            var value$62 = value$1.online_number_clipboard_heading;
            var online_number_clipboard_heading;
            if (value$62 !== undefined) {
              var value$63 = value$62.value;
              var value$64 = value$63 !== undefined ? value$63 : null;
              var value$65 = value$62.__typename;
              online_number_clipboard_heading = {
                __typename: value$65,
                value: value$64
              };
            } else {
              online_number_clipboard_heading = null;
            }
            var value$66 = value$1.in_store_number_clipboard_heading;
            var in_store_number_clipboard_heading;
            if (value$66 !== undefined) {
              var value$67 = value$66.value;
              var value$68 = value$67 !== undefined ? value$67 : null;
              var value$69 = value$66.__typename;
              in_store_number_clipboard_heading = {
                __typename: value$69,
                value: value$68
              };
            } else {
              in_store_number_clipboard_heading = null;
            }
            var value$70 = value$1.barcode_number_clipboard_heading;
            var barcode_number_clipboard_heading;
            if (value$70 !== undefined) {
              var value$71 = value$70.value;
              var value$72 = value$71 !== undefined ? value$71 : null;
              var value$73 = value$70.__typename;
              barcode_number_clipboard_heading = {
                __typename: value$73,
                value: value$72
              };
            } else {
              barcode_number_clipboard_heading = null;
            }
            var value$74 = value$1.clipboard_item_copied_text;
            var clipboard_item_copied_text;
            if (value$74 !== undefined) {
              var value$75 = value$74.value;
              var value$76 = value$75 !== undefined ? value$75 : null;
              var value$77 = value$74.__typename;
              clipboard_item_copied_text = {
                __typename: value$77,
                value: value$76
              };
            } else {
              clipboard_item_copied_text = null;
            }
            var value$78 = value$1.clipboard_item_copy_text;
            var clipboard_item_copy_text;
            if (value$78 !== undefined) {
              var value$79 = value$78.value;
              var value$80 = value$79 !== undefined ? value$79 : null;
              var value$81 = value$78.__typename;
              clipboard_item_copy_text = {
                __typename: value$81,
                value: value$80
              };
            } else {
              clipboard_item_copy_text = null;
            }
            var value$82 = value$1.spend_it_now_heading;
            var spend_it_now_heading;
            if (value$82 !== undefined) {
              var value$83 = value$82.value;
              var value$84 = value$83 !== undefined ? value$83 : null;
              var value$85 = value$82.__typename;
              spend_it_now_heading = {
                __typename: value$85,
                value: value$84
              };
            } else {
              spend_it_now_heading = null;
            }
            var value$86 = value$1.download_button;
            var download_button;
            if (value$86 !== undefined) {
              var value$87 = value$86.value;
              var value$88 = value$87 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$87) : null;
              var value$89 = value$86.__typename;
              download_button = {
                __typename: value$89,
                value: value$88
              };
            } else {
              download_button = null;
            }
            var value$90 = value$1.download_option_text_colour;
            var download_option_text_colour;
            if (value$90 !== undefined) {
              var value$91 = value$90.value;
              var value$92 = value$91 !== undefined ? value$91 : null;
              var value$93 = value$90.__typename;
              download_option_text_colour = {
                __typename: value$93,
                value: value$92
              };
            } else {
              download_option_text_colour = null;
            }
            var value$94 = value$1.download_option_text;
            var download_option_text;
            if (value$94 !== undefined) {
              var value$95 = value$94.value;
              var value$96 = value$95 !== undefined ? value$95 : null;
              var value$97 = value$94.__typename;
              download_option_text = {
                __typename: value$97,
                value: value$96
              };
            } else {
              download_option_text = null;
            }
            var value$98 = value$1.redeem_online_button;
            var redeem_online_button;
            if (value$98 !== undefined) {
              var value$99 = value$98.value;
              var value$100 = value$99 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$99) : null;
              var value$101 = value$98.__typename;
              redeem_online_button = {
                __typename: value$101,
                value: value$100
              };
            } else {
              redeem_online_button = null;
            }
            var value$102 = value$1.redeem_online_text_colour;
            var redeem_online_text_colour;
            if (value$102 !== undefined) {
              var value$103 = value$102.value;
              var value$104 = value$103 !== undefined ? value$103 : null;
              var value$105 = value$102.__typename;
              redeem_online_text_colour = {
                __typename: value$105,
                value: value$104
              };
            } else {
              redeem_online_text_colour = null;
            }
            var value$106 = value$1.redeem_online_text;
            var redeem_online_text;
            if (value$106 !== undefined) {
              var value$107 = value$106.value;
              var value$108 = value$107 !== undefined ? value$107 : null;
              var value$109 = value$106.__typename;
              redeem_online_text = {
                __typename: value$109,
                value: value$108
              };
            } else {
              redeem_online_text = null;
            }
            var value$110 = value$1.replay_button_text;
            var replay_button_text;
            if (value$110 !== undefined) {
              var value$111 = value$110.value;
              var value$112 = value$111 !== undefined ? value$111 : null;
              var value$113 = value$110.__typename;
              replay_button_text = {
                __typename: value$113,
                value: value$112
              };
            } else {
              replay_button_text = null;
            }
            var value$114 = value$1.replay_button_icon_asset;
            var replay_button_icon_asset;
            if (value$114 !== undefined) {
              var value$115 = value$114.value;
              var value$116 = value$115 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.height;
                        var height = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.width;
                        var width = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.url;
                        var url = value$3 !== undefined ? value$3 : null;
                        var value$4 = value.description;
                        var description = value$4 !== undefined ? value$4 : null;
                        var value$5 = value.name;
                        var name = value$5 !== undefined ? value$5 : null;
                        var value$6 = value.__typename;
                        return {
                                __typename: value$6,
                                name: name,
                                description: description,
                                url: url,
                                width: width,
                                height: height
                              };
                      }), value$115) : null;
              var value$117 = value$114.__typename;
              replay_button_icon_asset = {
                __typename: value$117,
                value: value$116
              };
            } else {
              replay_button_icon_asset = null;
            }
            var value$118 = value$1.business_gift_unwrap_lottie;
            var business_gift_unwrap_lottie;
            if (value$118 !== undefined) {
              var value$119 = value$118.value;
              var value$120 = value$119 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$119) : null;
              var value$121 = value$118.__typename;
              business_gift_unwrap_lottie = {
                __typename: value$121,
                value: value$120
              };
            } else {
              business_gift_unwrap_lottie = null;
            }
            var value$122 = value$1.classic_gift_unwrap_lottie;
            var classic_gift_unwrap_lottie;
            if (value$122 !== undefined) {
              var value$123 = value$122.value;
              var value$124 = value$123 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$123) : null;
              var value$125 = value$122.__typename;
              classic_gift_unwrap_lottie = {
                __typename: value$125,
                value: value$124
              };
            } else {
              classic_gift_unwrap_lottie = null;
            }
            var value$126 = value$1.gift_unwrap_lottie_segments;
            var gift_unwrap_lottie_segments;
            if (value$126 !== undefined) {
              var value$127 = value$126.value;
              var value$128 = value$127 !== undefined ? value$127 : null;
              var value$129 = value$126.__typename;
              gift_unwrap_lottie_segments = {
                __typename: value$129,
                value: value$128
              };
            } else {
              gift_unwrap_lottie_segments = null;
            }
            var value$130 = value$1.gift_unwrap_cta_text;
            var gift_unwrap_cta_text;
            if (value$130 !== undefined) {
              var value$131 = value$130.value;
              var value$132 = value$131 !== undefined ? value$131 : null;
              var value$133 = value$130.__typename;
              gift_unwrap_cta_text = {
                __typename: value$133,
                value: value$132
              };
            } else {
              gift_unwrap_cta_text = null;
            }
            var value$134 = value$1.optional_gift_card_cta;
            var optional_gift_card_cta;
            if (value$134 !== undefined) {
              var value$135 = value$134.value;
              var value$136 = value$135 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$135) : null;
              var value$137 = value$134.__typename;
              optional_gift_card_cta = {
                __typename: value$137,
                value: value$136
              };
            } else {
              optional_gift_card_cta = null;
            }
            var value$138 = value$1.swap_egift_card_cta_title;
            var swap_egift_card_cta_title;
            if (value$138 !== undefined) {
              var value$139 = value$138.value;
              var value$140 = value$139 !== undefined ? value$139 : null;
              var value$141 = value$138.__typename;
              swap_egift_card_cta_title = {
                __typename: value$141,
                value: value$140
              };
            } else {
              swap_egift_card_cta_title = null;
            }
            var value$142 = value$1.store_in_wallet_cta_title;
            var store_in_wallet_cta_title;
            if (value$142 !== undefined) {
              var value$143 = value$142.value;
              var value$144 = value$143 !== undefined ? value$143 : null;
              var value$145 = value$142.__typename;
              store_in_wallet_cta_title = {
                __typename: value$145,
                value: value$144
              };
            } else {
              store_in_wallet_cta_title = null;
            }
            var value$146 = value$1.check_balance_icon_asset;
            var check_balance_icon_asset;
            if (value$146 !== undefined) {
              var value$147 = value$146.value;
              var value$148 = value$147 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.height;
                        var height = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.width;
                        var width = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.url;
                        var url = value$3 !== undefined ? value$3 : null;
                        var value$4 = value.description;
                        var description = value$4 !== undefined ? value$4 : null;
                        var value$5 = value.name;
                        var name = value$5 !== undefined ? value$5 : null;
                        var value$6 = value.__typename;
                        return {
                                __typename: value$6,
                                name: name,
                                description: description,
                                url: url,
                                width: width,
                                height: height
                              };
                      }), value$147) : null;
              var value$149 = value$146.__typename;
              check_balance_icon_asset = {
                __typename: value$149,
                value: value$148
              };
            } else {
              check_balance_icon_asset = null;
            }
            var value$150 = value$1.check_balance_text;
            var check_balance_text;
            if (value$150 !== undefined) {
              var value$151 = value$150.value;
              var value$152 = value$151 !== undefined ? value$151 : null;
              var value$153 = value$150.__typename;
              check_balance_text = {
                __typename: value$153,
                value: value$152
              };
            } else {
              check_balance_text = null;
            }
            var value$154 = value$1.expiry_label;
            var expiry_label;
            if (value$154 !== undefined) {
              var value$155 = value$154.value;
              var value$156 = value$155 !== undefined ? value$155 : null;
              var value$157 = value$154.__typename;
              expiry_label = {
                __typename: value$157,
                value: value$156
              };
            } else {
              expiry_label = null;
            }
            var value$158 = value$1.no_expiry_text;
            var no_expiry_text;
            if (value$158 !== undefined) {
              var value$159 = value$158.value;
              var value$160 = value$159 !== undefined ? value$159 : null;
              var value$161 = value$158.__typename;
              no_expiry_text = {
                __typename: value$161,
                value: value$160
              };
            } else {
              no_expiry_text = null;
            }
            var value$162 = value$1.faqs;
            var faqs;
            if (value$162 !== undefined) {
              var value$163 = value$162.value;
              var value$164 = value$163 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        if (value.NAME === "UnspecifiedFragment") {
                          return {};
                        }
                        var value$1 = value.VAL;
                        var value$2 = value$1.include_card_merchant_terms;
                        var include_card_merchant_terms;
                        if (value$2 !== undefined) {
                          var value$3 = value$2.value;
                          var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                                    if (value === undefined) {
                                      return null;
                                    }
                                    var value$1 = value.codename;
                                    var codename = value$1 !== undefined ? value$1 : null;
                                    var value$2 = value.__typename;
                                    return {
                                            __typename: value$2,
                                            codename: codename
                                          };
                                  }), value$3) : null;
                          var value$5 = value$2.__typename;
                          include_card_merchant_terms = {
                            __typename: value$5,
                            value: value$4
                          };
                        } else {
                          include_card_merchant_terms = null;
                        }
                        var value$6 = value$1.include_pdf_download;
                        var include_pdf_download;
                        if (value$6 !== undefined) {
                          var value$7 = value$6.value;
                          var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                                    if (value === undefined) {
                                      return null;
                                    }
                                    var value$1 = value.codename;
                                    var codename = value$1 !== undefined ? value$1 : null;
                                    var value$2 = value.__typename;
                                    return {
                                            __typename: value$2,
                                            codename: codename
                                          };
                                  }), value$7) : null;
                          var value$9 = value$6.__typename;
                          include_pdf_download = {
                            __typename: value$9,
                            value: value$8
                          };
                        } else {
                          include_pdf_download = null;
                        }
                        var value$10 = value$1.include_how_to_use;
                        var include_how_to_use;
                        if (value$10 !== undefined) {
                          var value$11 = value$10.value;
                          var value$12 = value$11 !== undefined ? Js_array.map((function (value) {
                                    if (value === undefined) {
                                      return null;
                                    }
                                    var value$1 = value.codename;
                                    var codename = value$1 !== undefined ? value$1 : null;
                                    var value$2 = value.__typename;
                                    return {
                                            __typename: value$2,
                                            codename: codename
                                          };
                                  }), value$11) : null;
                          var value$13 = value$10.__typename;
                          include_how_to_use = {
                            __typename: value$13,
                            value: value$12
                          };
                        } else {
                          include_how_to_use = null;
                        }
                        var value$14 = value$1.body_text;
                        var body_text;
                        if (value$14 !== undefined) {
                          var value$15 = value$14.value;
                          var value$16 = value$15 !== undefined ? value$15 : null;
                          var value$17 = value$14.__typename;
                          body_text = {
                            __typename: value$17,
                            value: value$16
                          };
                        } else {
                          body_text = null;
                        }
                        var value$18 = value$1.title;
                        var title;
                        if (value$18 !== undefined) {
                          var value$19 = value$18.value;
                          var value$20 = value$19 !== undefined ? value$19 : null;
                          var value$21 = value$18.__typename;
                          title = {
                            __typename: value$21,
                            value: value$20
                          };
                        } else {
                          title = null;
                        }
                        var value$22 = value$1.system;
                        var value$23 = value$22.id;
                        var value$24 = value$22.codename;
                        var value$25 = value$22.__typename;
                        var system = {
                          __typename: value$25,
                          codename: value$24,
                          id: value$23
                        };
                        return {
                                __typename: "GiftLandingPageFaqItemContentType",
                                system: system,
                                title: title,
                                body_text: body_text,
                                include_how_to_use: include_how_to_use,
                                include_pdf_download: include_pdf_download,
                                include_card_merchant_terms: include_card_merchant_terms
                              };
                      }), value$163) : null;
              var value$165 = value$162.__typename;
              faqs = {
                __typename: value$165,
                value: value$164
              };
            } else {
              faqs = null;
            }
            var value$166 = value$1.confetti_lottie;
            var confetti_lottie;
            if (value$166 !== undefined) {
              var value$167 = value$166.value;
              var value$168 = value$167 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.url;
                        var url = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.__typename;
                        return {
                                __typename: value$2,
                                url: url
                              };
                      }), value$167) : null;
              var value$169 = value$166.__typename;
              confetti_lottie = {
                __typename: value$169,
                value: value$168
              };
            } else {
              confetti_lottie = null;
            }
            var value$170 = value$1.gift_reveal_cta_text;
            var gift_reveal_cta_text;
            if (value$170 !== undefined) {
              var value$171 = value$170.value;
              var value$172 = value$171 !== undefined ? value$171 : null;
              var value$173 = value$170.__typename;
              gift_reveal_cta_text = {
                __typename: value$173,
                value: value$172
              };
            } else {
              gift_reveal_cta_text = null;
            }
            var value$174 = value$1.sender_message;
            var sender_message;
            if (value$174 !== undefined) {
              var value$175 = value$174.value;
              var value$176 = value$175 !== undefined ? value$175 : null;
              var value$177 = value$174.__typename;
              sender_message = {
                __typename: value$177,
                value: value$176
              };
            } else {
              sender_message = null;
            }
            var value$178 = value$1.business_logo_asset;
            var business_logo_asset;
            if (value$178 !== undefined) {
              var value$179 = value$178.value;
              var value$180 = value$179 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.height;
                        var height = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.width;
                        var width = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.url;
                        var url = value$3 !== undefined ? value$3 : null;
                        var value$4 = value.description;
                        var description = value$4 !== undefined ? value$4 : null;
                        var value$5 = value.name;
                        var name = value$5 !== undefined ? value$5 : null;
                        var value$6 = value.__typename;
                        return {
                                __typename: value$6,
                                name: name,
                                description: description,
                                url: url,
                                width: width,
                                height: height
                              };
                      }), value$179) : null;
              var value$181 = value$178.__typename;
              business_logo_asset = {
                __typename: value$181,
                value: value$180
              };
            } else {
              business_logo_asset = null;
            }
            var value$182 = value$1.classic_logo_asset;
            var classic_logo_asset;
            if (value$182 !== undefined) {
              var value$183 = value$182.value;
              var value$184 = value$183 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var value$1 = value.height;
                        var height = value$1 !== undefined ? value$1 : null;
                        var value$2 = value.width;
                        var width = value$2 !== undefined ? value$2 : null;
                        var value$3 = value.url;
                        var url = value$3 !== undefined ? value$3 : null;
                        var value$4 = value.description;
                        var description = value$4 !== undefined ? value$4 : null;
                        var value$5 = value.name;
                        var name = value$5 !== undefined ? value$5 : null;
                        var value$6 = value.__typename;
                        return {
                                __typename: value$6,
                                name: name,
                                description: description,
                                url: url,
                                width: width,
                                height: height
                              };
                      }), value$183) : null;
              var value$185 = value$182.__typename;
              classic_logo_asset = {
                __typename: value$185,
                value: value$184
              };
            } else {
              classic_logo_asset = null;
            }
            var value$186 = value$1.system;
            var value$187 = value$186.id;
            var value$188 = value$186.codename;
            var value$189 = value$186.__typename;
            var system = {
              __typename: value$189,
              codename: value$188,
              id: value$187
            };
            return {
                    __typename: "GiftLandingPageContentType",
                    system: system,
                    classic_logo_asset: classic_logo_asset,
                    business_logo_asset: business_logo_asset,
                    sender_message: sender_message,
                    gift_reveal_cta_text: gift_reveal_cta_text,
                    confetti_lottie: confetti_lottie,
                    faqs: faqs,
                    no_expiry_text: no_expiry_text,
                    expiry_label: expiry_label,
                    check_balance_text: check_balance_text,
                    check_balance_icon_asset: check_balance_icon_asset,
                    store_in_wallet_cta_title: store_in_wallet_cta_title,
                    swap_egift_card_cta_title: swap_egift_card_cta_title,
                    optional_gift_card_cta: optional_gift_card_cta,
                    gift_unwrap_cta_text: gift_unwrap_cta_text,
                    gift_unwrap_lottie_segments: gift_unwrap_lottie_segments,
                    classic_gift_unwrap_lottie: classic_gift_unwrap_lottie,
                    business_gift_unwrap_lottie: business_gift_unwrap_lottie,
                    replay_button_icon_asset: replay_button_icon_asset,
                    replay_button_text: replay_button_text,
                    redeem_online_text: redeem_online_text,
                    redeem_online_text_colour: redeem_online_text_colour,
                    redeem_online_button: redeem_online_button,
                    download_option_text: download_option_text,
                    download_option_text_colour: download_option_text_colour,
                    download_button: download_button,
                    spend_it_now_heading: spend_it_now_heading,
                    clipboard_item_copy_text: clipboard_item_copy_text,
                    clipboard_item_copied_text: clipboard_item_copied_text,
                    barcode_number_clipboard_heading: barcode_number_clipboard_heading,
                    in_store_number_clipboard_heading: in_store_number_clipboard_heading,
                    online_number_clipboard_heading: online_number_clipboard_heading,
                    online_and_in_store_clipboard_heading: online_and_in_store_clipboard_heading,
                    swap_cards_title: swap_cards_title,
                    swap_card_alt: swap_card_alt,
                    swap_card_original_value_label: swap_card_original_value_label,
                    swap_card_button_style: swap_card_button_style,
                    save_in_apple_google_wallet_text: save_in_apple_google_wallet_text,
                    save_in_apple_google_wallet_text_colour: save_in_apple_google_wallet_text_colour,
                    google_wallet_image_asset: google_wallet_image_asset,
                    apple_wallet_image_asset: apple_wallet_image_asset,
                    footer_copyright_text: footer_copyright_text,
                    footer_download_text: footer_download_text,
                    app_store_icons: app_store_icons,
                    create_account_modal_title: create_account_modal_title,
                    create_account_modal_list: create_account_modal_list,
                    create_account_modal_cta_text: create_account_modal_cta_text
                  };
          }), value$1) : null;
  var value$2 = value.locations;
  var locations = value$2 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME === "UnspecifiedFragment") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.region_location;
            var region_location;
            if (value$2 !== undefined) {
              var value$3 = value$2.value;
              var value$4 = value$3 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        if (value.NAME === "UnspecifiedFragment") {
                          return {};
                        }
                        var value$1 = value.VAL;
                        var value$2 = value$1.url;
                        var url;
                        if (value$2 !== undefined) {
                          var value$3 = value$2.value;
                          var value$4 = value$3 !== undefined ? value$3 : null;
                          var value$5 = value$2.__typename;
                          url = {
                            __typename: value$5,
                            value: value$4
                          };
                        } else {
                          url = null;
                        }
                        var value$6 = value$1.country_flag;
                        var country_flag;
                        if (value$6 !== undefined) {
                          var value$7 = value$6.value;
                          var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                                    if (value === undefined) {
                                      return null;
                                    }
                                    var value$1 = value.url;
                                    var url = value$1 !== undefined ? value$1 : null;
                                    var value$2 = value.name;
                                    var name = value$2 !== undefined ? value$2 : null;
                                    var value$3 = value.__typename;
                                    return {
                                            __typename: value$3,
                                            name: name,
                                            url: url
                                          };
                                  }), value$7) : null;
                          var value$9 = value$6.__typename;
                          country_flag = {
                            __typename: value$9,
                            value: value$8
                          };
                        } else {
                          country_flag = null;
                        }
                        var value$10 = value$1.country_name;
                        var country_name;
                        if (value$10 !== undefined) {
                          var value$11 = value$10.value;
                          var value$12 = value$11 !== undefined ? value$11 : null;
                          var value$13 = value$10.__typename;
                          country_name = {
                            __typename: value$13,
                            value: value$12
                          };
                        } else {
                          country_name = null;
                        }
                        var value$14 = value$1.currency;
                        var currency;
                        if (value$14 !== undefined) {
                          var value$15 = value$14.value;
                          var value$16 = value$15 !== undefined ? value$15 : null;
                          var value$17 = value$14.__typename;
                          currency = {
                            __typename: value$17,
                            value: value$16
                          };
                        } else {
                          currency = null;
                        }
                        var value$18 = value$1.system;
                        var value$19 = value$18.codename;
                        var value$20 = value$18.id;
                        var value$21 = value$18.__typename;
                        var system = {
                          __typename: value$21,
                          id: value$20,
                          codename: value$19
                        };
                        return {
                                __typename: "RegionLocationContentType",
                                system: system,
                                currency: currency,
                                country_name: country_name,
                                country_flag: country_flag,
                                url: url
                              };
                      }), value$3) : null;
              var value$5 = value$2.__typename;
              region_location = {
                __typename: value$5,
                value: value$4
              };
            } else {
              region_location = null;
            }
            var value$6 = value$1.cta_text;
            var cta_text;
            if (value$6 !== undefined) {
              var value$7 = value$6.value;
              var value$8 = value$7 !== undefined ? value$7 : null;
              var value$9 = value$6.__typename;
              cta_text = {
                __typename: value$9,
                value: value$8
              };
            } else {
              cta_text = null;
            }
            var value$10 = value$1.subtitle;
            var subtitle;
            if (value$10 !== undefined) {
              var value$11 = value$10.value;
              var value$12 = value$11 !== undefined ? value$11 : null;
              var value$13 = value$10.__typename;
              subtitle = {
                __typename: value$13,
                value: value$12
              };
            } else {
              subtitle = null;
            }
            var value$14 = value$1.title;
            var title;
            if (value$14 !== undefined) {
              var value$15 = value$14.value;
              var value$16 = value$15 !== undefined ? value$15 : null;
              var value$17 = value$14.__typename;
              title = {
                __typename: value$17,
                value: value$16
              };
            } else {
              title = null;
            }
            var value$18 = value$1.system;
            var value$19 = value$18.codename;
            var value$20 = value$18.id;
            var value$21 = value$18.__typename;
            var system = {
              __typename: value$21,
              id: value$20,
              codename: value$19
            };
            return {
                    __typename: "ModalChangeLocationContentType",
                    system: system,
                    title: title,
                    subtitle: subtitle,
                    cta_text: cta_text,
                    region_location: region_location
                  };
          }), value$2) : null;
  var value$3 = value.dictionaryItems;
  var dictionaryItems = value$3 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME !== "DictionaryItemContentType") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.value;
            var value$3;
            if (value$2 !== undefined) {
              var value$4 = value$2.value;
              var value$5 = value$4 !== undefined ? value$4 : null;
              var value$6 = value$2.__typename;
              value$3 = {
                __typename: value$6,
                value: value$5
              };
            } else {
              value$3 = null;
            }
            var value$7 = value$1.system;
            var value$8 = value$7.codename;
            var value$9 = value$7.id;
            var value$10 = value$7.__typename;
            var system = {
              __typename: value$10,
              id: value$9,
              codename: value$8
            };
            return {
                    __typename: "DictionaryItemContentType",
                    system: system,
                    value: value$3
                  };
          }), value$3) : null;
  var value$4 = value.globalConfiguration;
  var globalConfiguration = value$4 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME !== "GlobalConfigurationContentType") {
              return {};
            }
            var value$1 = value.VAL;
            var value$2 = value$1.sold_out_badge_text;
            var sold_out_badge_text;
            if (value$2 !== undefined) {
              var value$3 = value$2.value;
              var value$4 = value$3 !== undefined ? value$3 : null;
              var value$5 = value$2.__typename;
              sold_out_badge_text = {
                __typename: value$5,
                value: value$4
              };
            } else {
              sold_out_badge_text = null;
            }
            var value$6 = value$1.no_search_result;
            var no_search_result;
            if (value$6 !== undefined) {
              var value$7 = value$6.value;
              var value$8 = value$7 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__NoSearchResults__Fragment.NoSearchResults.serialize(value.VAL.noSearchResults)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$7) : null;
              var value$9 = value$6.__typename;
              no_search_result = {
                __typename: value$9,
                value: value$8
              };
            } else {
              no_search_result = null;
            }
            var value$10 = value$1.search_bar_placeholder_text;
            var search_bar_placeholder_text;
            if (value$10 !== undefined) {
              var value$11 = value$10.value;
              var value$12 = value$11 !== undefined ? value$11 : null;
              var value$13 = value$10.__typename;
              search_bar_placeholder_text = {
                __typename: value$13,
                value: value$12
              };
            } else {
              search_bar_placeholder_text = null;
            }
            var value$14 = value$1.not_logged_in_navbar_menu_items___business;
            var not_logged_in_navbar_menu_items___business;
            if (value$14 !== undefined) {
              var value$15 = value$14.value;
              var value$16 = value$15 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "CallToActionLinkD6a3d61ContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$15) : null;
              var value$17 = value$14.__typename;
              not_logged_in_navbar_menu_items___business = {
                __typename: value$17,
                value: value$16
              };
            } else {
              not_logged_in_navbar_menu_items___business = null;
            }
            var value$18 = value$1.not_logged_in_navbar_menu_items___consumer;
            var not_logged_in_navbar_menu_items___consumer;
            if (value$18 !== undefined) {
              var value$19 = value$18.value;
              var value$20 = value$19 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var variant = value.NAME;
                        if (variant === "UnspecifiedFragment") {
                          return {};
                        } else if (variant === "CallToActionLinkD6a3d61ContentType") {
                          return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                        return {};
                                      }), [Gql__CallToAction__Fragment.CallToAction.serialize(value.VAL.callToAction)]);
                        } else {
                          return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                        return {};
                                      }), [Gql__NavbarSelectCta__Fragment.NavbarSelectCTA.serialize(value.VAL.navbarSelectCTA)]);
                        }
                      }), value$19) : null;
              var value$21 = value$18.__typename;
              not_logged_in_navbar_menu_items___consumer = {
                __typename: value$21,
                value: value$20
              };
            } else {
              not_logged_in_navbar_menu_items___consumer = null;
            }
            var value$22 = value$1.header_logo;
            var header_logo;
            if (value$22 !== undefined) {
              var value$23 = value$22.value;
              var value$24 = value$23 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "HeaderLogoContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__HeaderLogo__Fragment.HeaderLogo.serialize(value.VAL.headerLogo)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$23) : null;
              var value$25 = value$22.__typename;
              header_logo = {
                __typename: value$25,
                value: value$24
              };
            } else {
              header_logo = null;
            }
            var value$26 = value$1.warnings;
            var warnings;
            if (value$26 !== undefined) {
              var value$27 = value$26.value;
              var value$28 = value$27 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        if (value.NAME === "UnspecifiedFragment") {
                          return {};
                        }
                        var value$1 = value.VAL;
                        var value$2 = value$1.description;
                        var description;
                        if (value$2 !== undefined) {
                          var value$3 = value$2.value;
                          var value$4 = value$3 !== undefined ? value$3 : null;
                          var value$5 = value$2.__typename;
                          description = {
                            __typename: value$5,
                            value: value$4
                          };
                        } else {
                          description = null;
                        }
                        var value$6 = value$1.heading;
                        var heading;
                        if (value$6 !== undefined) {
                          var value$7 = value$6.value;
                          var value$8 = value$7 !== undefined ? value$7 : null;
                          var value$9 = value$6.__typename;
                          heading = {
                            __typename: value$9,
                            value: value$8
                          };
                        } else {
                          heading = null;
                        }
                        var value$10 = value$1.id;
                        var id;
                        if (value$10 !== undefined) {
                          var value$11 = value$10.value;
                          var value$12 = value$11 !== undefined ? value$11 : null;
                          var value$13 = value$10.__typename;
                          id = {
                            __typename: value$13,
                            value: value$12
                          };
                        } else {
                          id = null;
                        }
                        return {
                                __typename: "WarningContentType",
                                id: id,
                                heading: heading,
                                description: description
                              };
                      }), value$27) : null;
              var value$29 = value$26.__typename;
              warnings = {
                __typename: value$29,
                value: value$28
              };
            } else {
              warnings = null;
            }
            var value$30 = value$1.register;
            var register;
            if (value$30 !== undefined) {
              var value$31 = value$30.value;
              var value$32 = value$31 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        if (value.NAME === "UnspecifiedFragment") {
                          return {};
                        }
                        var value$1 = value.VAL;
                        var value$2 = value$1.already_registered_text;
                        var already_registered_text;
                        if (value$2 !== undefined) {
                          var value$3 = value$2.value;
                          var value$4 = value$3 !== undefined ? value$3 : null;
                          var value$5 = value$2.__typename;
                          already_registered_text = {
                            __typename: value$5,
                            value: value$4
                          };
                        } else {
                          already_registered_text = null;
                        }
                        var value$6 = value$1.sign_up_cta_text;
                        var sign_up_cta_text;
                        if (value$6 !== undefined) {
                          var value$7 = value$6.value;
                          var value$8 = value$7 !== undefined ? value$7 : null;
                          var value$9 = value$6.__typename;
                          sign_up_cta_text = {
                            __typename: value$9,
                            value: value$8
                          };
                        } else {
                          sign_up_cta_text = null;
                        }
                        var value$10 = value$1.resending_code_text;
                        var resending_code_text;
                        if (value$10 !== undefined) {
                          var value$11 = value$10.value;
                          var value$12 = value$11 !== undefined ? value$11 : null;
                          var value$13 = value$10.__typename;
                          resending_code_text = {
                            __typename: value$13,
                            value: value$12
                          };
                        } else {
                          resending_code_text = null;
                        }
                        var value$14 = value$1.resend_code_text;
                        var resend_code_text;
                        if (value$14 !== undefined) {
                          var value$15 = value$14.value;
                          var value$16 = value$15 !== undefined ? value$15 : null;
                          var value$17 = value$14.__typename;
                          resend_code_text = {
                            __typename: value$17,
                            value: value$16
                          };
                        } else {
                          resend_code_text = null;
                        }
                        var value$18 = value$1.step_title___security_code;
                        var step_title___security_code;
                        if (value$18 !== undefined) {
                          var value$19 = value$18.value;
                          var value$20 = value$19 !== undefined ? value$19 : null;
                          var value$21 = value$18.__typename;
                          step_title___security_code = {
                            __typename: value$21,
                            value: value$20
                          };
                        } else {
                          step_title___security_code = null;
                        }
                        var value$22 = value$1.send_security_code_cta_text;
                        var send_security_code_cta_text;
                        if (value$22 !== undefined) {
                          var value$23 = value$22.value;
                          var value$24 = value$23 !== undefined ? value$23 : null;
                          var value$25 = value$22.__typename;
                          send_security_code_cta_text = {
                            __typename: value$25,
                            value: value$24
                          };
                        } else {
                          send_security_code_cta_text = null;
                        }
                        var value$26 = value$1.nz_not_prezzy_disclaimer;
                        var nz_not_prezzy_disclaimer;
                        if (value$26 !== undefined) {
                          var value$27 = value$26.value;
                          var value$28 = value$27 !== undefined ? value$27 : null;
                          var value$29 = value$26.__typename;
                          nz_not_prezzy_disclaimer = {
                            __typename: value$29,
                            value: value$28
                          };
                        } else {
                          nz_not_prezzy_disclaimer = null;
                        }
                        var value$30 = value$1.t_cs_checkbox_label;
                        var t_cs_checkbox_label;
                        if (value$30 !== undefined) {
                          var value$31 = value$30.value;
                          var value$32 = value$31 !== undefined ? value$31 : null;
                          var value$33 = value$30.__typename;
                          t_cs_checkbox_label = {
                            __typename: value$33,
                            value: value$32
                          };
                        } else {
                          t_cs_checkbox_label = null;
                        }
                        var value$34 = value$1.newsletter_checkbox_label;
                        var newsletter_checkbox_label;
                        if (value$34 !== undefined) {
                          var value$35 = value$34.value;
                          var value$36 = value$35 !== undefined ? value$35 : null;
                          var value$37 = value$34.__typename;
                          newsletter_checkbox_label = {
                            __typename: value$37,
                            value: value$36
                          };
                        } else {
                          newsletter_checkbox_label = null;
                        }
                        var value$38 = value$1.invalid_phone_number_text;
                        var invalid_phone_number_text;
                        if (value$38 !== undefined) {
                          var value$39 = value$38.value;
                          var value$40 = value$39 !== undefined ? value$39 : null;
                          var value$41 = value$38.__typename;
                          invalid_phone_number_text = {
                            __typename: value$41,
                            value: value$40
                          };
                        } else {
                          invalid_phone_number_text = null;
                        }
                        var value$42 = value$1.phone_number_required_text;
                        var phone_number_required_text;
                        if (value$42 !== undefined) {
                          var value$43 = value$42.value;
                          var value$44 = value$43 !== undefined ? value$43 : null;
                          var value$45 = value$42.__typename;
                          phone_number_required_text = {
                            __typename: value$45,
                            value: value$44
                          };
                        } else {
                          phone_number_required_text = null;
                        }
                        var value$46 = value$1.step_title___phone_number;
                        var step_title___phone_number;
                        if (value$46 !== undefined) {
                          var value$47 = value$46.value;
                          var value$48 = value$47 !== undefined ? value$47 : null;
                          var value$49 = value$46.__typename;
                          step_title___phone_number = {
                            __typename: value$49,
                            value: value$48
                          };
                        } else {
                          step_title___phone_number = null;
                        }
                        var value$50 = value$1.invalid_password_text;
                        var invalid_password_text;
                        if (value$50 !== undefined) {
                          var value$51 = value$50.value;
                          var value$52 = value$51 !== undefined ? value$51 : null;
                          var value$53 = value$50.__typename;
                          invalid_password_text = {
                            __typename: value$53,
                            value: value$52
                          };
                        } else {
                          invalid_password_text = null;
                        }
                        var value$54 = value$1.password_required_text;
                        var password_required_text;
                        if (value$54 !== undefined) {
                          var value$55 = value$54.value;
                          var value$56 = value$55 !== undefined ? value$55 : null;
                          var value$57 = value$54.__typename;
                          password_required_text = {
                            __typename: value$57,
                            value: value$56
                          };
                        } else {
                          password_required_text = null;
                        }
                        var value$58 = value$1.password_label;
                        var password_label;
                        if (value$58 !== undefined) {
                          var value$59 = value$58.value;
                          var value$60 = value$59 !== undefined ? value$59 : null;
                          var value$61 = value$58.__typename;
                          password_label = {
                            __typename: value$61,
                            value: value$60
                          };
                        } else {
                          password_label = null;
                        }
                        var value$62 = value$1.password_requirements;
                        var password_requirements;
                        if (value$62 !== undefined) {
                          var value$63 = value$62.value;
                          var value$64 = value$63 !== undefined ? value$63 : null;
                          var value$65 = value$62.__typename;
                          password_requirements = {
                            __typename: value$65,
                            value: value$64
                          };
                        } else {
                          password_requirements = null;
                        }
                        var value$66 = value$1.step_title___password;
                        var step_title___password;
                        if (value$66 !== undefined) {
                          var value$67 = value$66.value;
                          var value$68 = value$67 !== undefined ? value$67 : null;
                          var value$69 = value$66.__typename;
                          step_title___password = {
                            __typename: value$69,
                            value: value$68
                          };
                        } else {
                          step_title___password = null;
                        }
                        var value$70 = value$1.invalid_email_text;
                        var invalid_email_text;
                        if (value$70 !== undefined) {
                          var value$71 = value$70.value;
                          var value$72 = value$71 !== undefined ? value$71 : null;
                          var value$73 = value$70.__typename;
                          invalid_email_text = {
                            __typename: value$73,
                            value: value$72
                          };
                        } else {
                          invalid_email_text = null;
                        }
                        var value$74 = value$1.email_required_text;
                        var email_required_text;
                        if (value$74 !== undefined) {
                          var value$75 = value$74.value;
                          var value$76 = value$75 !== undefined ? value$75 : null;
                          var value$77 = value$74.__typename;
                          email_required_text = {
                            __typename: value$77,
                            value: value$76
                          };
                        } else {
                          email_required_text = null;
                        }
                        var value$78 = value$1.email_label;
                        var email_label;
                        if (value$78 !== undefined) {
                          var value$79 = value$78.value;
                          var value$80 = value$79 !== undefined ? value$79 : null;
                          var value$81 = value$78.__typename;
                          email_label = {
                            __typename: value$81,
                            value: value$80
                          };
                        } else {
                          email_label = null;
                        }
                        var value$82 = value$1.step_title___email;
                        var step_title___email;
                        if (value$82 !== undefined) {
                          var value$83 = value$82.value;
                          var value$84 = value$83 !== undefined ? value$83 : null;
                          var value$85 = value$82.__typename;
                          step_title___email = {
                            __typename: value$85,
                            value: value$84
                          };
                        } else {
                          step_title___email = null;
                        }
                        var value$86 = value$1.invalid_last_name_text;
                        var invalid_last_name_text;
                        if (value$86 !== undefined) {
                          var value$87 = value$86.value;
                          var value$88 = value$87 !== undefined ? value$87 : null;
                          var value$89 = value$86.__typename;
                          invalid_last_name_text = {
                            __typename: value$89,
                            value: value$88
                          };
                        } else {
                          invalid_last_name_text = null;
                        }
                        var value$90 = value$1.last_name_required_text;
                        var last_name_required_text;
                        if (value$90 !== undefined) {
                          var value$91 = value$90.value;
                          var value$92 = value$91 !== undefined ? value$91 : null;
                          var value$93 = value$90.__typename;
                          last_name_required_text = {
                            __typename: value$93,
                            value: value$92
                          };
                        } else {
                          last_name_required_text = null;
                        }
                        var value$94 = value$1.last_name_label;
                        var last_name_label;
                        if (value$94 !== undefined) {
                          var value$95 = value$94.value;
                          var value$96 = value$95 !== undefined ? value$95 : null;
                          var value$97 = value$94.__typename;
                          last_name_label = {
                            __typename: value$97,
                            value: value$96
                          };
                        } else {
                          last_name_label = null;
                        }
                        var value$98 = value$1.invalid_first_name_text;
                        var invalid_first_name_text;
                        if (value$98 !== undefined) {
                          var value$99 = value$98.value;
                          var value$100 = value$99 !== undefined ? value$99 : null;
                          var value$101 = value$98.__typename;
                          invalid_first_name_text = {
                            __typename: value$101,
                            value: value$100
                          };
                        } else {
                          invalid_first_name_text = null;
                        }
                        var value$102 = value$1.first_name_required_text;
                        var first_name_required_text;
                        if (value$102 !== undefined) {
                          var value$103 = value$102.value;
                          var value$104 = value$103 !== undefined ? value$103 : null;
                          var value$105 = value$102.__typename;
                          first_name_required_text = {
                            __typename: value$105,
                            value: value$104
                          };
                        } else {
                          first_name_required_text = null;
                        }
                        var value$106 = value$1.first_name_label;
                        var first_name_label;
                        if (value$106 !== undefined) {
                          var value$107 = value$106.value;
                          var value$108 = value$107 !== undefined ? value$107 : null;
                          var value$109 = value$106.__typename;
                          first_name_label = {
                            __typename: value$109,
                            value: value$108
                          };
                        } else {
                          first_name_label = null;
                        }
                        var value$110 = value$1.step_title___name;
                        var step_title___name;
                        if (value$110 !== undefined) {
                          var value$111 = value$110.value;
                          var value$112 = value$111 !== undefined ? value$111 : null;
                          var value$113 = value$110.__typename;
                          step_title___name = {
                            __typename: value$113,
                            value: value$112
                          };
                        } else {
                          step_title___name = null;
                        }
                        var value$114 = value$1.back_button_text;
                        var back_button_text;
                        if (value$114 !== undefined) {
                          var value$115 = value$114.value;
                          var value$116 = value$115 !== undefined ? value$115 : null;
                          var value$117 = value$114.__typename;
                          back_button_text = {
                            __typename: value$117,
                            value: value$116
                          };
                        } else {
                          back_button_text = null;
                        }
                        var value$118 = value$1.next_button_text;
                        var next_button_text;
                        if (value$118 !== undefined) {
                          var value$119 = value$118.value;
                          var value$120 = value$119 !== undefined ? value$119 : null;
                          var value$121 = value$118.__typename;
                          next_button_text = {
                            __typename: value$121,
                            value: value$120
                          };
                        } else {
                          next_button_text = null;
                        }
                        var value$122 = value$1.register_subtitle;
                        var register_subtitle;
                        if (value$122 !== undefined) {
                          var value$123 = value$122.value;
                          var value$124 = value$123 !== undefined ? value$123 : null;
                          var value$125 = value$122.__typename;
                          register_subtitle = {
                            __typename: value$125,
                            value: value$124
                          };
                        } else {
                          register_subtitle = null;
                        }
                        var value$126 = value$1.register_title;
                        var register_title;
                        if (value$126 !== undefined) {
                          var value$127 = value$126.value;
                          var value$128 = value$127 !== undefined ? value$127 : null;
                          var value$129 = value$126.__typename;
                          register_title = {
                            __typename: value$129,
                            value: value$128
                          };
                        } else {
                          register_title = null;
                        }
                        var value$130 = value$1.system;
                        var value$131 = value$130.id;
                        var value$132 = value$130.codename;
                        var value$133 = value$130.__typename;
                        var system = {
                          __typename: value$133,
                          codename: value$132,
                          id: value$131
                        };
                        return {
                                __typename: "RegisterContentType",
                                system: system,
                                register_title: register_title,
                                register_subtitle: register_subtitle,
                                next_button_text: next_button_text,
                                back_button_text: back_button_text,
                                step_title___name: step_title___name,
                                first_name_label: first_name_label,
                                first_name_required_text: first_name_required_text,
                                invalid_first_name_text: invalid_first_name_text,
                                last_name_label: last_name_label,
                                last_name_required_text: last_name_required_text,
                                invalid_last_name_text: invalid_last_name_text,
                                step_title___email: step_title___email,
                                email_label: email_label,
                                email_required_text: email_required_text,
                                invalid_email_text: invalid_email_text,
                                step_title___password: step_title___password,
                                password_requirements: password_requirements,
                                password_label: password_label,
                                password_required_text: password_required_text,
                                invalid_password_text: invalid_password_text,
                                step_title___phone_number: step_title___phone_number,
                                phone_number_required_text: phone_number_required_text,
                                invalid_phone_number_text: invalid_phone_number_text,
                                newsletter_checkbox_label: newsletter_checkbox_label,
                                t_cs_checkbox_label: t_cs_checkbox_label,
                                nz_not_prezzy_disclaimer: nz_not_prezzy_disclaimer,
                                send_security_code_cta_text: send_security_code_cta_text,
                                step_title___security_code: step_title___security_code,
                                resend_code_text: resend_code_text,
                                resending_code_text: resending_code_text,
                                sign_up_cta_text: sign_up_cta_text,
                                already_registered_text: already_registered_text
                              };
                      }), value$31) : null;
              var value$33 = value$30.__typename;
              register = {
                __typename: value$33,
                value: value$32
              };
            } else {
              register = null;
            }
            var value$34 = value$1.login;
            var login;
            if (value$34 !== undefined) {
              var value$35 = value$34.value;
              var value$36 = value$35 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        if (value.NAME === "UnspecifiedFragment") {
                          return {};
                        }
                        var value$1 = value.VAL;
                        var value$2 = value$1.forgot_email_text;
                        var forgot_email_text;
                        if (value$2 !== undefined) {
                          var value$3 = value$2.value;
                          var value$4 = value$3 !== undefined ? value$3 : null;
                          var value$5 = value$2.__typename;
                          forgot_email_text = {
                            __typename: value$5,
                            value: value$4
                          };
                        } else {
                          forgot_email_text = null;
                        }
                        var value$6 = value$1.forgot_password_text;
                        var forgot_password_text;
                        if (value$6 !== undefined) {
                          var value$7 = value$6.value;
                          var value$8 = value$7 !== undefined ? value$7 : null;
                          var value$9 = value$6.__typename;
                          forgot_password_text = {
                            __typename: value$9,
                            value: value$8
                          };
                        } else {
                          forgot_password_text = null;
                        }
                        var value$10 = value$1.submit_cta_submitting_text;
                        var submit_cta_submitting_text;
                        if (value$10 !== undefined) {
                          var value$11 = value$10.value;
                          var value$12 = value$11 !== undefined ? value$11 : null;
                          var value$13 = value$10.__typename;
                          submit_cta_submitting_text = {
                            __typename: value$13,
                            value: value$12
                          };
                        } else {
                          submit_cta_submitting_text = null;
                        }
                        var value$14 = value$1.submit_cta_text;
                        var submit_cta_text;
                        if (value$14 !== undefined) {
                          var value$15 = value$14.value;
                          var value$16 = value$15 !== undefined ? value$15 : null;
                          var value$17 = value$14.__typename;
                          submit_cta_text = {
                            __typename: value$17,
                            value: value$16
                          };
                        } else {
                          submit_cta_text = null;
                        }
                        var value$18 = value$1.invalid_email_text;
                        var invalid_email_text;
                        if (value$18 !== undefined) {
                          var value$19 = value$18.value;
                          var value$20 = value$19 !== undefined ? value$19 : null;
                          var value$21 = value$18.__typename;
                          invalid_email_text = {
                            __typename: value$21,
                            value: value$20
                          };
                        } else {
                          invalid_email_text = null;
                        }
                        var value$22 = value$1.required_password_text;
                        var required_password_text;
                        if (value$22 !== undefined) {
                          var value$23 = value$22.value;
                          var value$24 = value$23 !== undefined ? value$23 : null;
                          var value$25 = value$22.__typename;
                          required_password_text = {
                            __typename: value$25,
                            value: value$24
                          };
                        } else {
                          required_password_text = null;
                        }
                        var value$26 = value$1.required_email_text;
                        var required_email_text;
                        if (value$26 !== undefined) {
                          var value$27 = value$26.value;
                          var value$28 = value$27 !== undefined ? value$27 : null;
                          var value$29 = value$26.__typename;
                          required_email_text = {
                            __typename: value$29,
                            value: value$28
                          };
                        } else {
                          required_email_text = null;
                        }
                        var value$30 = value$1.password_label;
                        var password_label;
                        if (value$30 !== undefined) {
                          var value$31 = value$30.value;
                          var value$32 = value$31 !== undefined ? value$31 : null;
                          var value$33 = value$30.__typename;
                          password_label = {
                            __typename: value$33,
                            value: value$32
                          };
                        } else {
                          password_label = null;
                        }
                        var value$34 = value$1.email_label;
                        var email_label;
                        if (value$34 !== undefined) {
                          var value$35 = value$34.value;
                          var value$36 = value$35 !== undefined ? value$35 : null;
                          var value$37 = value$34.__typename;
                          email_label = {
                            __typename: value$37,
                            value: value$36
                          };
                        } else {
                          email_label = null;
                        }
                        var value$38 = value$1.subtitle;
                        var subtitle;
                        if (value$38 !== undefined) {
                          var value$39 = value$38.value;
                          var value$40 = value$39 !== undefined ? value$39 : null;
                          var value$41 = value$38.__typename;
                          subtitle = {
                            __typename: value$41,
                            value: value$40
                          };
                        } else {
                          subtitle = null;
                        }
                        var value$42 = value$1.title;
                        var title;
                        if (value$42 !== undefined) {
                          var value$43 = value$42.value;
                          var value$44 = value$43 !== undefined ? value$43 : null;
                          var value$45 = value$42.__typename;
                          title = {
                            __typename: value$45,
                            value: value$44
                          };
                        } else {
                          title = null;
                        }
                        var value$46 = value$1.system;
                        var value$47 = value$46.id;
                        var value$48 = value$46.codename;
                        var value$49 = value$46.__typename;
                        var system = {
                          __typename: value$49,
                          codename: value$48,
                          id: value$47
                        };
                        return {
                                __typename: "LoginContentType",
                                system: system,
                                title: title,
                                subtitle: subtitle,
                                email_label: email_label,
                                password_label: password_label,
                                required_email_text: required_email_text,
                                required_password_text: required_password_text,
                                invalid_email_text: invalid_email_text,
                                submit_cta_text: submit_cta_text,
                                submit_cta_submitting_text: submit_cta_submitting_text,
                                forgot_password_text: forgot_password_text,
                                forgot_email_text: forgot_email_text
                              };
                      }), value$35) : null;
              var value$37 = value$34.__typename;
              login = {
                __typename: value$37,
                value: value$36
              };
            } else {
              login = null;
            }
            var value$38 = value$1.business_footer;
            var business_footer;
            if (value$38 !== undefined) {
              var value$39 = value$38.value;
              var value$40 = value$39 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "FooterContainerContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__Footer__Fragment.FooterBlock.serialize(value.VAL.footerBlock)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$39) : null;
              var value$41 = value$38.__typename;
              business_footer = {
                __typename: value$41,
                value: value$40
              };
            } else {
              business_footer = null;
            }
            var value$42 = value$1.footer;
            var footer;
            if (value$42 !== undefined) {
              var value$43 = value$42.value;
              var value$44 = value$43 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "FooterContainerContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__Footer__Fragment.FooterBlock.serialize(value.VAL.footerBlock)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$43) : null;
              var value$45 = value$42.__typename;
              footer = {
                __typename: value$45,
                value: value$44
              };
            } else {
              footer = null;
            }
            var value$46 = value$1.mobile_navbar_menu_items;
            var mobile_navbar_menu_items;
            if (value$46 !== undefined) {
              var value$47 = value$46.value;
              var value$48 = value$47 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__NavMenuItems__Fragment.NavMenuBlock.serialize(value.VAL.navMenuBlock)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$47) : null;
              var value$49 = value$46.__typename;
              mobile_navbar_menu_items = {
                __typename: value$49,
                value: value$48
              };
            } else {
              mobile_navbar_menu_items = null;
            }
            var value$50 = value$1.desktop_navbar_menu_items;
            var desktop_navbar_menu_items;
            if (value$50 !== undefined) {
              var value$51 = value$50.value;
              var value$52 = value$51 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__NavMenuItems__Fragment.NavMenuBlock.serialize(value.VAL.navMenuBlock)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$51) : null;
              var value$53 = value$50.__typename;
              desktop_navbar_menu_items = {
                __typename: value$53,
                value: value$52
              };
            } else {
              desktop_navbar_menu_items = null;
            }
            var value$54 = value$1.business_navigation;
            var business_navigation;
            if (value$54 !== undefined) {
              var value$55 = value$54.value;
              var value$56 = value$55 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var variant = value.NAME;
                        if (variant === "PageContentType") {
                          return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                        return {};
                                      }), [Gql__Header__Fragment.HeaderBlock.serialize(value.VAL.headerBlock)]);
                        } else if (variant === "UnspecifiedFragment") {
                          return {};
                        } else {
                          return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                        return {};
                                      }), [Gql__HeaderNavigation__Fragment.HeaderNavigation.serialize(value.VAL.headerNavigation)]);
                        }
                      }), value$55) : null;
              var value$57 = value$54.__typename;
              business_navigation = {
                __typename: value$57,
                value: value$56
              };
            } else {
              business_navigation = null;
            }
            var value$58 = value$1.navigation_root;
            var navigation_root;
            if (value$58 !== undefined) {
              var value$59 = value$58.value;
              var value$60 = value$59 !== undefined ? Js_array.map((function (value) {
                        if (value === undefined) {
                          return null;
                        }
                        var variant = value.NAME;
                        if (variant === "PageContentType") {
                          return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                        return {};
                                      }), [Gql__Header__Fragment.HeaderBlock.serialize(value.VAL.headerBlock)]);
                        } else if (variant === "UnspecifiedFragment") {
                          return {};
                        } else {
                          return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                        return {};
                                      }), [Gql__HeaderNavigation__Fragment.HeaderNavigation.serialize(value.VAL.headerNavigation)]);
                        }
                      }), value$59) : null;
              var value$61 = value$58.__typename;
              navigation_root = {
                __typename: value$61,
                value: value$60
              };
            } else {
              navigation_root = null;
            }
            var value$62 = value$1.alert_banner;
            var alert_banner;
            if (value$62 !== undefined) {
              var value$63 = value$62.value;
              var value$64 = value$63 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__AlertBanner__Fragment.AlertBannerBlock.serialize(value.VAL.alertBannerBlock)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$63) : null;
              var value$65 = value$62.__typename;
              alert_banner = {
                __typename: value$65,
                value: value$64
              };
            } else {
              alert_banner = null;
            }
            var value$66 = value$1.gdpr_cookie_popup;
            var gdpr_cookie_popup;
            if (value$66 !== undefined) {
              var value$67 = value$66.value;
              var value$68 = value$67 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "GdprCookiePopupContentType") {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__CookiePopup__Fragment.CookiePopupBlock.serialize(value.VAL.cookiePopupBlock)]);
                          } else {
                            return {};
                          }
                        } else {
                          return null;
                        }
                      }), value$67) : null;
              var value$69 = value$66.__typename;
              gdpr_cookie_popup = {
                __typename: value$69,
                value: value$68
              };
            } else {
              gdpr_cookie_popup = null;
            }
            return {
                    __typename: "GlobalConfigurationContentType",
                    gdpr_cookie_popup: gdpr_cookie_popup,
                    alert_banner: alert_banner,
                    navigation_root: navigation_root,
                    business_navigation: business_navigation,
                    desktop_navbar_menu_items: desktop_navbar_menu_items,
                    mobile_navbar_menu_items: mobile_navbar_menu_items,
                    footer: footer,
                    business_footer: business_footer,
                    login: login,
                    register: register,
                    warnings: warnings,
                    header_logo: header_logo,
                    not_logged_in_navbar_menu_items___consumer: not_logged_in_navbar_menu_items___consumer,
                    not_logged_in_navbar_menu_items___business: not_logged_in_navbar_menu_items___business,
                    search_bar_placeholder_text: search_bar_placeholder_text,
                    no_search_result: no_search_result,
                    sold_out_badge_text: sold_out_badge_text
                  };
          }), value$4) : null;
  return {
          globalConfiguration: globalConfiguration,
          dictionaryItems: dictionaryItems,
          locations: locations,
          giftLandingPage: giftLandingPage
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined
        };
}

function makeVariables(language, preview, param) {
  return {
          language: language,
          preview: preview
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var AlertBannerBlock;

var NavMenuBlock;

var HeaderNavigation;

var HeaderBlock;

var FooterBlock;

var $$Image;

var AppStoreItem;

var CallToAction;

var Icon;

var CookiePopupBlock;

var HeaderLogo;

var NavbarSelectCTA;

var NoSearchResults;

export {
  AlertBannerBlock ,
  NavMenuBlock ,
  HeaderNavigation ,
  HeaderBlock ,
  FooterBlock ,
  $$Image ,
  AppStoreItem ,
  CallToAction ,
  Icon ,
  CookiePopupBlock ,
  HeaderLogo ,
  NavbarSelectCTA ,
  NoSearchResults ,
  Query ,
}
/* query Not a pure module */
