// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (f) {
              return f.value;
            })), "");
  var file = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), 0);
  var icon;
  if (file !== undefined) {
    var alt = Belt_Option.getWithDefault(file.description, "");
    var src = Belt_Option.getWithDefault(file.url, "");
    var width = Belt_Option.getWithDefault(file.width, 72);
    var height = Belt_Option.getWithDefault(file.height, 72);
    icon = {
      src: src,
      alt: alt,
      width: width,
      height: height
    };
  } else {
    icon = undefined;
  }
  var scp_title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title_scp, (function (c) {
              return c.value;
            })), "");
  var scp_description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description_scp, (function (f) {
              return f.value;
            })), "");
  return {
          title: title,
          description: description,
          icon: icon,
          scp_title: scp_title,
          scp_description: scp_description
        };
}

export {
  get ,
}
/* No side effect */
