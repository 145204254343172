// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment FullWidthTextBlock on FullWidthTextBlockContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    title  {
      __typename
      value
    }
    description  {
      __typename
      value
    }
    text_colour  {
      __typename
      value  {
        __typename
        codename
      }
    }
    background_colour  {
      __typename
      value
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.description;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.text_colour;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.codename;
                return {
                        __typename: value.__typename,
                        codename: !(value$1 == null) ? value$1 : undefined
                      };
              }), value$7) : undefined
    };
  }
  var value$8 = value.background_colour;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          title: tmp,
          description: tmp$1,
          text_colour: tmp$2,
          background_colour: tmp$3
        };
}

function serialize(value) {
  var value$1 = value.background_colour;
  var background_colour;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    background_colour = {
      __typename: value$4,
      value: value$3
    };
  } else {
    background_colour = null;
  }
  var value$5 = value.text_colour;
  var text_colour;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.codename;
              var codename = value$1 !== undefined ? value$1 : null;
              var value$2 = value.__typename;
              return {
                      __typename: value$2,
                      codename: codename
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    text_colour = {
      __typename: value$8,
      value: value$7
    };
  } else {
    text_colour = null;
  }
  var value$9 = value.description;
  var description;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    description = {
      __typename: value$12,
      value: value$11
    };
  } else {
    description = null;
  }
  var value$13 = value.title;
  var title;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    title = {
      __typename: value$16,
      value: value$15
    };
  } else {
    title = null;
  }
  var value$17 = value.system;
  var value$18 = value$17.codename;
  var value$19 = value$17.id;
  var value$20 = value$17.__typename;
  var system = {
    __typename: value$20,
    id: value$19,
    codename: value$18
  };
  var value$21 = value.__typename;
  return {
          __typename: value$21,
          system: system,
          title: title,
          description: description,
          text_colour: text_colour,
          background_colour: background_colour
        };
}

function verifyArgsAndParse(_FullWidthTextBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var FullWidthTextBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  FullWidthTextBlock ,
}
/* query Not a pure module */
