// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__PageBuilder__Fragment from "../PageBuilder/Gql__PageBuilder__Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  query PageContent($language: String!, $preview: Boolean, $url: String!)  {
    itemsByType(type: "page", limit: 1, depth: 5, language: $language, preview: $preview, filters: [{type: EQUALS, prop: "elements.url", val: $url}])  {
      __typename
      ...on PageContentType   {
        __typename
        content  {
          __typename
          value  {
            __typename
            ...on PageBuilderContentType   {
              ...PageContent
            }
          }
        }
      }
    }
  }
  ${frag_0}
`)(Gql__PageBuilder__Fragment.PageContent.query);

function parse(value) {
  var value$1 = value.itemsByType;
  return {
          itemsByType: !(value$1 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var typename = value["__typename"];
                    var tmp;
                    if (typename === "PageContentType") {
                      var value$1 = value.content;
                      var tmp$1;
                      if (value$1 == null) {
                        tmp$1 = undefined;
                      } else {
                        var value$2 = value$1.value;
                        tmp$1 = {
                          __typename: value$1.__typename,
                          value: !(value$2 == null) ? Js_array.map((function (value) {
                                    if (value == null) {
                                      return ;
                                    }
                                    var typename = value["__typename"];
                                    var tmp = typename === "PageBuilderContentType" ? ({
                                          NAME: "PageBuilderContentType",
                                          VAL: {
                                            pageContent: Gql__PageBuilder__Fragment.PageContent.verifyArgsAndParse("PageContent", value)
                                          }
                                        }) : ({
                                          NAME: "UnspecifiedFragment",
                                          VAL: typename
                                        });
                                    return tmp;
                                  }), value$2) : undefined
                        };
                      }
                      tmp = {
                        NAME: "PageContentType",
                        VAL: {
                          __typename: value.__typename,
                          content: tmp$1
                        }
                      };
                    } else {
                      tmp = {
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      };
                    }
                    return tmp;
                  }), value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.itemsByType;
  var itemsByType = value$1 !== undefined ? Js_array.map((function (value) {
            if (value === undefined) {
              return null;
            }
            if (value.NAME === "UnspecifiedFragment") {
              return {};
            }
            var value$1 = value.VAL.content;
            var content;
            if (value$1 !== undefined) {
              var value$2 = value$1.value;
              var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
                        if (value !== undefined) {
                          if (value.NAME === "UnspecifiedFragment") {
                            return {};
                          } else {
                            return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                          return {};
                                        }), [Gql__PageBuilder__Fragment.PageContent.serialize(value.VAL.pageContent)]);
                          }
                        } else {
                          return null;
                        }
                      }), value$2) : null;
              var value$4 = value$1.__typename;
              content = {
                __typename: value$4,
                value: value$3
              };
            } else {
              content = null;
            }
            return {
                    __typename: "PageContentType",
                    content: content
                  };
          }), value$1) : null;
  return {
          itemsByType: itemsByType
        };
}

function serializeVariables(inp) {
  var a = inp.preview;
  return {
          language: inp.language,
          preview: a !== undefined ? a : undefined,
          url: inp.url
        };
}

function makeVariables(language, preview, url, param) {
  return {
          language: language,
          preview: preview,
          url: url
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var PageContent;

export {
  PageContent ,
  Query ,
}
/* query Not a pure module */
