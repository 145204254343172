// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment ProductHeroIcon on ProductHeroIconContentType   {
    __typename
    system  {
      __typename
      id
      codename
    }
    icon  {
      __typename
      value  {
        __typename
        name
        description
        url
        width
        height
      }
    }
    text  {
      __typename
      value
    }
  }
`);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.icon;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.name;
                var value$2 = value.description;
                var value$3 = value.url;
                var value$4 = value.width;
                var value$5 = value.height;
                return {
                        __typename: value.__typename,
                        name: !(value$1 == null) ? value$1 : undefined,
                        description: !(value$2 == null) ? value$2 : undefined,
                        url: !(value$3 == null) ? value$3 : undefined,
                        width: !(value$4 == null) ? value$4 : undefined,
                        height: !(value$5 == null) ? value$5 : undefined
                      };
              }), value$3) : undefined
    };
  }
  var value$4 = value.text;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            id: value$1.id,
            codename: value$1.codename
          },
          icon: tmp,
          text: tmp$1
        };
}

function serialize(value) {
  var value$1 = value.text;
  var text;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    text = {
      __typename: value$4,
      value: value$3
    };
  } else {
    text = null;
  }
  var value$5 = value.icon;
  var icon;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var value$1 = value.height;
              var height = value$1 !== undefined ? value$1 : null;
              var value$2 = value.width;
              var width = value$2 !== undefined ? value$2 : null;
              var value$3 = value.url;
              var url = value$3 !== undefined ? value$3 : null;
              var value$4 = value.description;
              var description = value$4 !== undefined ? value$4 : null;
              var value$5 = value.name;
              var name = value$5 !== undefined ? value$5 : null;
              var value$6 = value.__typename;
              return {
                      __typename: value$6,
                      name: name,
                      description: description,
                      url: url,
                      width: width,
                      height: height
                    };
            }), value$6) : null;
    var value$8 = value$5.__typename;
    icon = {
      __typename: value$8,
      value: value$7
    };
  } else {
    icon = null;
  }
  var value$9 = value.system;
  var value$10 = value$9.codename;
  var value$11 = value$9.id;
  var value$12 = value$9.__typename;
  var system = {
    __typename: value$12,
    id: value$11,
    codename: value$10
  };
  var value$13 = value.__typename;
  return {
          __typename: value$13,
          system: system,
          icon: icon,
          text: text
        };
}

function verifyArgsAndParse(_ProductHeroIcon, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ProductHeroIcon = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  ProductHeroIcon ,
}
/* query Not a pure module */
