import { FC } from 'react';
import Image from 'next/future/image';

export interface ImageConfig {
  width: number;
  height: number;
  src: string;
  alt: string;
}
interface Props {
  src: string;
  alt: string;
  width: number;
  height: number;
  className?: string;
  priority?: boolean;
  fill?: boolean;
  loading?: 'lazy' | 'eager';
}

const StaticImage: FC<Props> = props => {
  let { src, alt, width, height, className, priority = false, fill = false, loading } = props;

  return (
    <Image
      priority={priority}
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      fill={fill}
      loading={loading}
    />
  );
};

export default StaticImage;
