// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__VideoTextPromo__Decoder__Video from "./Gql__VideoTextPromo__Decoder__Video.bs.js";
import * as Gql__VideoTextPromo__Decoder__SideImage from "./Gql__VideoTextPromo__Decoder__SideImage.bs.js";
import * as Gql__VideoTextPromo__Decoder__MobileVideo from "./Gql__VideoTextPromo__Decoder__MobileVideo.bs.js";
import * as Gql__VideoTextPromo__Decoder__VideoPoster from "./Gql__VideoTextPromo__Decoder__VideoPoster.bs.js";

var emptyBlock = {
  tagline: "",
  title: "",
  description: "",
  text_colour: /* Dark */0,
  video: undefined,
  mobile_video: undefined,
  loop_video: true,
  video_poster: undefined,
  side_image: undefined,
  smartLink: SmartLink.emptySmartLink
};

function get(c) {
  var tagline = Belt_Option.getWithDefault(Belt_Option.flatMap(c.tagline, (function (c) {
              return c.value;
            })), "");
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c.description, (function (c) {
              return c.value;
            })), "");
  var text_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), /* Dark */0, (function (str) {
          switch (str) {
            case "dark" :
                return /* Dark */0;
            case "light" :
                return /* Light */1;
            default:
              return /* Dark */0;
          }
        }));
  var video = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.featured_video, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__VideoTextPromo__Decoder__Video.get), 0);
  var mobile_video = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.featured_video_mobile, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__VideoTextPromo__Decoder__MobileVideo.get), 0);
  var loop_video = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.loop_featured_video, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), true, (function (str) {
          switch (str) {
            case "false" :
                return false;
            case "true" :
                return true;
            default:
              return true;
          }
        }));
  var video_poster = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.feature_video_poster, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__VideoTextPromo__Decoder__VideoPoster.get), 0);
  var side_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__VideoTextPromo__Decoder__SideImage.get), 0);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          tagline: tagline,
          title: title,
          description: description,
          text_colour: text_colour,
          video: video,
          mobile_video: mobile_video,
          loop_video: loop_video,
          video_poster: video_poster,
          side_image: side_image,
          smartLink: smartLink
        };
}

var $$Image;

var Video;

var MobileVideo;

var VideoPoster;

var SideImage;

export {
  $$Image ,
  Video ,
  MobileVideo ,
  VideoPoster ,
  SideImage ,
  emptyBlock ,
  get ,
}
/* No side effect */
