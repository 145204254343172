// Generated by ReScript, PLEASE EDIT WITH CARE

import MenuPopupTsx from "./MenuPopup.tsx";

var make = MenuPopupTsx;

export {
  make ,
}
/* make Not a pure module */
