// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as SmartLink from "../../../components/library/SmartLink.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__HeroTiles__Decoder__HeroTile from "./Gql__HeroTiles__Decoder__HeroTile.bs.js";

var emptyBlock_hero_tiles = [];

var emptyBlock_bottom_distance = 0;

var emptyBlock_top_distance = 0;

var emptyBlock = {
  block_style: /* TwoOneStack */0,
  hero_tiles: emptyBlock_hero_tiles,
  smartLink: SmartLink.emptySmartLink,
  bottom_distance: emptyBlock_bottom_distance,
  top_distance: emptyBlock_top_distance
};

function get(c) {
  var block_style = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.block_style, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), /* TwoOneStack */0, (function (str) {
          switch (str) {
            case "full_width" :
                return /* FullWidth */2;
            case "tiled_offset" :
                return /* TiledOffset */1;
            case "two_one_stack" :
                return /* TwoOneStack */0;
            default:
              return /* TwoOneStack */0;
          }
        }));
  var hero_tiles = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.hero_tiles, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__HeroTiles__Decoder__HeroTile.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var bottom_distance = Belt_Option.flatMap(c.bottom_distance, (function (c) {
          return c.value;
        }));
  var top_distance = Belt_Option.flatMap(c.top_distance, (function (c) {
          return c.value;
        }));
  return {
          block_style: block_style,
          hero_tiles: hero_tiles,
          smartLink: smartLink,
          bottom_distance: bottom_distance,
          top_distance: top_distance
        };
}

var HeroTile;

export {
  HeroTile ,
  emptyBlock ,
  get ,
}
/* No side effect */
