// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Pervasives from "@rescript/std/lib/es6/pervasives.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

var query = Js_dict.fromList({
      hd: [
        "query",
        "query {\n      webConfig {\n        featureBffNewsletter\n      }\n    }"
      ],
      tl: /* [] */0
    });

function featureBffNewsletter(json) {
  return {
          featureBffNewsletter: Json_decode.nullable((function (param) {
                  return Json_decode.field("featureBffNewsletter", Json_decode.bool, param);
                }), json)
        };
}

function webConfig(json) {
  return {
          webConfig: Json_decode.field("webConfig", featureBffNewsletter, json)
        };
}

function data(json) {
  return {
          data: Json_decode.field("data", webConfig, json)
        };
}

var Decode = {
  featureBffNewsletter: featureBffNewsletter,
  webConfig: webConfig,
  data: data
};

var NewsletterConfig = {
  query: query,
  Decode: Decode
};

function query$1(email, region, subscribed, store, _type) {
  return Js_dict.fromList({
              hd: [
                "query",
                "mutation {\n      postNewsletterSubscription(email: \"" + email + "\", region: \"" + region + "\", subscribed: " + Pervasives.string_of_bool(subscribed) + ", store: \"" + store + "\", type: \"" + _type + "\") {\n        globalGuid\n        email\n        regions {\n          AU {\n            regionId\n          }\n          NZ {\n            regionId\n          }\n          UK {\n            regionId\n          }\n          US {\n            regionId\n          }\n        }\n        lastUpdatedAt\n      }\n    }"
              ],
              tl: /* [] */0
            });
}

function region(json) {
  return {
          regionId: Json_decode.field("regionId", Json_decode.string, json)
        };
}

function regions(json) {
  return {
          au: Json_decode.optional((function (param) {
                  return Json_decode.field("AU", region, param);
                }), json),
          nz: Json_decode.optional((function (param) {
                  return Json_decode.field("NZ", region, param);
                }), json),
          uk: Json_decode.optional((function (param) {
                  return Json_decode.field("UK", region, param);
                }), json),
          us: Json_decode.optional((function (param) {
                  return Json_decode.field("US", region, param);
                }), json)
        };
}

function postNewsletterSubscriptionData(json) {
  return {
          globalGuid: Json_decode.field("globalGuid", Json_decode.string, json),
          email: Json_decode.field("email", Json_decode.string, json),
          regions: Json_decode.field("regions", regions, json),
          lastUpdatedAt: Json_decode.field("lastUpdatedAt", Json_decode.string, json)
        };
}

function postNewsletterSubscription(json) {
  return {
          postNewsletterSubscription: Json_decode.field("postNewsletterSubscription", postNewsletterSubscriptionData, json)
        };
}

function data$1(json) {
  return {
          data: Json_decode.field("data", postNewsletterSubscription, json)
        };
}

var Decode$1 = {
  region: region,
  regions: regions,
  postNewsletterSubscriptionData: postNewsletterSubscriptionData,
  postNewsletterSubscription: postNewsletterSubscription,
  data: data$1
};

var NewsletterSubscription = {
  query: query$1,
  Decode: Decode$1
};

export {
  NewsletterConfig ,
  NewsletterSubscription ,
}
/* query Not a pure module */
