// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Button from "./Button.bs.js";
import Link from "next/link";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

function visibilityContainer(v) {
  return Cn.make([v ? "visible animate__animated animate__fadeIn" : "invisible"]);
}

function visibilityModal(v) {
  return Cn.make([v ? "visible animate__animated animate__fadeIn" : "invisible"]);
}

var liStyle = "flex items-center py-1";

var iconStyle = "mr-4 text-prezzee-red h-2 w-2";

function NavBarUserModal(props) {
  var setVisible = props.setVisible;
  var visible = props.visible;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("span", {
                                  children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                        icon: [
                                          "fas",
                                          "angle-right"
                                        ]
                                      }),
                                  className: Cn.make([
                                        "hidden lg:flex items-center justify-center h-8 w-8 overflow-visible rounded-full bg-prezzee-red fill-current text-white cursor-pointer relative -ml-3 z-40 transition-colors duration-300 ease-in-out hover:shadow-md",
                                        visibilityContainer(visible)
                                      ]),
                                  onClick: (function (e) {
                                      e.preventDefault();
                                      Curry._1(setVisible, (function (param) {
                                              return false;
                                            }));
                                    })
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Link, {
                                          href: "/register",
                                          prefetch: false,
                                          children: JsxRuntime.jsx("a", {
                                                children: Util.ReactStuff.s("Create my account"),
                                                className: Cn.make([
                                                      Button.primaryStyle,
                                                      "block mx-auto w-56 mt-12 text-white text-center shadow-md border-2 py-3 border-prezzee-red hover:text-prezzee-red hover:bg-white hover:border-prezzee-red focus:text-prezzee-red focus:bg-white focus:border-prezzee-red transition-colors duration-300 ease-in-out"
                                                    ]),
                                                onClick: (function (param) {
                                                    Curry._1(setVisible, (function (param) {
                                                            return false;
                                                          }));
                                                  })
                                              })
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx("img", {
                                                className: "block ml-auto",
                                                alt: "Why create an account",
                                                src: require("../../../public/images/nav_user.svg")
                                              }),
                                          className: "w-1/2 my-2"
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("strong", {
                                                  children: Util.ReactStuff.s("WHY?"),
                                                  className: "leading-normal font-bold"
                                                }),
                                            JsxRuntime.jsxs("ul", {
                                                  children: [
                                                    JsxRuntime.jsxs("li", {
                                                          children: [
                                                            JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                                  icon: [
                                                                    "fas",
                                                                    "circle"
                                                                  ],
                                                                  className: iconStyle
                                                                }),
                                                            Util.ReactStuff.s("Keep all my gift cards in one place")
                                                          ],
                                                          className: liStyle
                                                        }),
                                                    JsxRuntime.jsxs("li", {
                                                          children: [
                                                            JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                                  icon: [
                                                                    "fas",
                                                                    "circle"
                                                                  ],
                                                                  className: iconStyle
                                                                }),
                                                            Util.ReactStuff.s("Fast access from my phone while I shop")
                                                          ],
                                                          className: liStyle
                                                        }),
                                                    JsxRuntime.jsxs("li", {
                                                          children: [
                                                            JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                                  icon: [
                                                                    "fas",
                                                                    "circle"
                                                                  ],
                                                                  className: iconStyle
                                                                }),
                                                            Util.ReactStuff.s("Secure digital wallet")
                                                          ],
                                                          className: liStyle
                                                        }),
                                                    JsxRuntime.jsxs("li", {
                                                          children: [
                                                            JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                                  icon: [
                                                                    "fas",
                                                                    "circle"
                                                                  ],
                                                                  className: iconStyle
                                                                }),
                                                            Util.ReactStuff.s("Get offers")
                                                          ],
                                                          className: liStyle
                                                        }),
                                                    JsxRuntime.jsxs("li", {
                                                          children: [
                                                            JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                                  icon: [
                                                                    "fas",
                                                                    "circle"
                                                                  ],
                                                                  className: iconStyle
                                                                }),
                                                            Util.ReactStuff.s("Send gift cards even faster")
                                                          ],
                                                          className: liStyle
                                                        })
                                                  ],
                                                  className: "mt-4"
                                                })
                                          ],
                                          className: "w-9/12 mx-auto"
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("hr", {
                                                  className: "border-solid border-light-gray border-b w-1/3 mr-3"
                                                }),
                                            Util.ReactStuff.s(" or "),
                                            JsxRuntime.jsx("hr", {
                                                  className: "border-solid border-light-gray border-b w-1/3 ml-3"
                                                })
                                          ],
                                          className: "flex items-center text-light-gray justify-center my-10"
                                        }),
                                    JsxRuntime.jsx(Link, {
                                          href: "/login",
                                          prefetch: false,
                                          children: JsxRuntime.jsx("a", {
                                                children: Util.ReactStuff.s("Log in"),
                                                className: Cn.make([
                                                      Button.primaryOutlineStyle,
                                                      "mt-4 block mx-auto w-56 text-center text-white shadow-md border-2 py-3 border-prezzee-red focus:bg-prezzee-red focus:text-white transition-colors duration-300 ease-in-out"
                                                    ]),
                                                onClick: (function (param) {
                                                    Curry._1(setVisible, (function (param) {
                                                            return false;
                                                          }));
                                                  })
                                              })
                                        }),
                                    JsxRuntime.jsx("span", {
                                          children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                                icon: [
                                                  "fas",
                                                  "times"
                                                ]
                                              }),
                                          className: "absolute flex items-center justify-center left-0 top-0 ml-2 mt-2 h-6 w-6 rounded-full text-gray-500 cursor-pointer lg:hidden",
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Curry._1(setVisible, (function (param) {
                                                      return false;
                                                    }));
                                            })
                                        })
                                  ],
                                  className: Cn.make([
                                        "fixed right-0 inset-y-0 bg-white w-full md:w-7/12 lg:w-1/2 xl:w-4/12 overflow-auto mt-18",
                                        visibilityModal(visible)
                                      ]),
                                  onClick: (function (e) {
                                      e.preventDefault();
                                    })
                                })
                          ],
                          className: "fixed flex items-center w-full md:w-7/12 lg:w-1/2 xl:w-4/12 h-full right-0"
                        }),
                    className: Cn.make([
                          "fixed z-40 inset-0 flex bg-smoke-light",
                          visibilityContainer(visible)
                        ]),
                    tabIndex: -1,
                    onClick: (function (e) {
                        e.preventDefault();
                        Curry._1(setVisible, (function (param) {
                                return false;
                              }));
                      })
                  })
            });
}

var Link$1;

var make = NavBarUserModal;

export {
  Link$1 as Link,
  visibilityContainer ,
  visibilityModal ,
  liStyle ,
  iconStyle ,
  make ,
}
/* Util Not a pure module */
