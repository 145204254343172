// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  if (typeof c !== "object") {
    return ;
  }
  if (c.NAME !== "WarningContentType") {
    return ;
  }
  var c$1 = c.VAL;
  var id = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.id, (function (c) {
              return c.value;
            })), "");
  var heading = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.heading, (function (c) {
              return c.value;
            })), "");
  var description = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.description, (function (c) {
              return c.value;
            })), "");
  return {
          id: id,
          heading: heading,
          description: description
        };
}

export {
  get ,
}
/* No side effect */
