// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__TeamMembersBlock__Decoder__Card from "./Gql__TeamMembersBlock__Decoder__Card.bs.js";

function get(c) {
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var cards_per_row = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.cards_per_row, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), (function (v) {
                  return v.codename;
                })), 0), "");
  var team_member_cards = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.team_member_cards, (function (c) {
                      return c.value;
                    })), []), GqlUtils.id), Gql__TeamMembersBlock__Decoder__Card.get);
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          cards_per_row: cards_per_row,
          team_member_cards: team_member_cards,
          smartLink: smartLink
        };
}

var Card;

export {
  Card ,
  get ,
}
/* No side effect */
