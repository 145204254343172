// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../bindings/BsCss.bs.js";
import * as Theme from "./Theme.bs.js";
import * as Tag__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Tag/Tag__Theme.bs.js";
import * as Footer__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Footer/Footer__Theme.bs.js";
import * as Tagline__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Tagline/Tagline__Theme.bs.js";
import * as EmailSignUp__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/EmailSignUp/EmailSignUp__Theme.bs.js";
import * as RegionPicker__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RegionPicker/RegionPicker__Theme.bs.js";
import * as ThemeContext__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext__Theme.bs.js";
import * as FullWidthCarousel__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__Theme.bs.js";
import * as ThemeContext__Components$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext__Components.bs.js";
import * as VideoTextPromoBlock__Theme$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__Theme.bs.js";

var personal = ThemeContext__Theme$PrezzeeRibbon.t({
      default: Theme.Color.red400,
      defaultText: Theme.Color.white,
      hover: Theme.Color.red500,
      hoverText: Theme.Color.white
    }, {
      default: Theme.Color.purple400,
      defaultText: Theme.Color.white,
      hover: Theme.Color.purple600,
      hoverText: Theme.Color.white
    }, {
      default: Theme.Color.gray700,
      defaultText: Theme.Color.white,
      hover: Theme.Color.gray800,
      hoverText: Theme.Color.white
    }, [
      {
        NAME: "custom",
        VAL: "DM Serif Display"
      },
      {
        NAME: "custom",
        VAL: "sofia-pro"
      },
      {
        NAME: "custom",
        VAL: "-apple-system"
      },
      {
        NAME: "custom",
        VAL: "BlinkMacSystemFont"
      },
      {
        NAME: "custom",
        VAL: "Helvetica Neue"
      },
      {
        NAME: "custom",
        VAL: "Arial"
      },
      "sansSerif"
    ], undefined, undefined, BsCss.px(4), ThemeContext__Components$PrezzeeRibbon.t(undefined, undefined, undefined, undefined, undefined, Tag__Theme$PrezzeeRibbon.t(Theme.Color.purple50, Theme.Color.purple400, undefined), Tagline__Theme$PrezzeeRibbon.t(Theme.Color.red400, undefined), undefined, VideoTextPromoBlock__Theme$PrezzeeRibbon.t(Theme.Color.lilac50, Theme.Color.gray800, Theme.Color.gray600, Theme.Color.blue400, undefined), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined);

var business = ThemeContext__Theme$PrezzeeRibbon.t({
      default: Theme.Color.purple400,
      defaultText: Theme.Color.white,
      hover: Theme.Color.purple600,
      hoverText: Theme.Color.white
    }, {
      default: {
        NAME: "hex",
        VAL: "F93C28"
      },
      defaultText: Theme.Color.white,
      hover: {
        NAME: "hex",
        VAL: "900C20"
      },
      hoverText: Theme.Color.white
    }, {
      default: Theme.Color.gray700,
      defaultText: Theme.Color.white,
      hover: Theme.Color.gray800,
      hoverText: Theme.Color.white
    }, undefined, undefined, undefined, BsCss.px(4), ThemeContext__Components$PrezzeeRibbon.t(FullWidthCarousel__Theme$PrezzeeRibbon.t(Theme.Color.gray300, Theme.Color.purple400, undefined), undefined, undefined, undefined, RegionPicker__Theme$PrezzeeRibbon.t(Theme.Color.gray800, Theme.Color.gray600, undefined, undefined, undefined, undefined, undefined), undefined, undefined, undefined, undefined, Footer__Theme$PrezzeeRibbon.t(Theme.Color.purple600, Theme.Color.white, Theme.Color.white, Theme.Color.gray300, Theme.Color.white, Theme.Color.gray300, Theme.Color.gray800, Theme.Color.gray800, undefined), undefined, EmailSignUp__Theme$PrezzeeRibbon.t(Theme.Color.lilac400, undefined, undefined, undefined), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined);

export {
  personal ,
  business ,
}
/* personal Not a pure module */
