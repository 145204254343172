// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../../../bindings/Pino.bs.js";
import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__Location__Decoder from "../Location/Gql__Location__Decoder.bs.js";
import * as Gql__DictionaryItems__Decoder from "../DictionaryItems/Gql__DictionaryItems__Decoder.bs.js";
import * as Gql__GiftLandingPage__Decoder__Block from "../GiftLandingPage/Gql__GiftLandingPage__Decoder__Block.bs.js";
import * as Gql__GlobalConfiguration__Decoder__Config from "./Gql__GlobalConfiguration__Decoder__Config.bs.js";

function decode(gqlQueryResult) {
  var globalConfig = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.globalConfiguration, []), 0), GqlUtils.id), Gql__GlobalConfiguration__Decoder__Config.get);
  var dictionaryItems = Gql__DictionaryItems__Decoder.get(Belt_Array.keepMap(Belt_Option.getWithDefault(gqlQueryResult.dictionaryItems, []), GqlUtils.id));
  var locations = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.locations, []), 0), GqlUtils.id), Gql__Location__Decoder.getModalChangeLocation);
  var giftLandingPage = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(Belt_Option.getWithDefault(gqlQueryResult.giftLandingPage, []), 0), GqlUtils.id), Gql__GiftLandingPage__Decoder__Block.get);
  return {
          globalConfig: globalConfig,
          dictionaryItems: dictionaryItems,
          locations: locations,
          giftLandingPage: giftLandingPage
        };
}

function handleResult(result) {
  if (result.TAG === /* Ok */0) {
    return Promise.resolve(decode(result._0.data));
  }
  var logData;
  logData = result.TAG === /* Ok */0 ? result._0.data : undefined;
  Pino.logger.error({
        error: result._0,
        type: "Global Config decoder",
        data: JSON.stringify(logData)
      }, "Graphql errors");
  return Promise.resolve(undefined);
}

var Decoder = {
  decode: decode,
  handleResult: handleResult
};

var GlobalConfiguration;

var DictionaryItems;

var Locations;

var GiftLandingPage;

export {
  GlobalConfiguration ,
  DictionaryItems ,
  Locations ,
  GiftLandingPage ,
  Decoder ,
}
/* Pino Not a pure module */
