// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pino from "../bindings/Pino.bs.js";
import * as Util from "../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as BFFData from "./Data/BFFData.bs.js";
import * as Datadog from "../bindings/Datadog.bs.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as $$Request from "../common/Request.bs.js";
import * as GiftData from "./Data/GiftData.bs.js";
import * as ApiErrors from "./ApiErrors.bs.js";
import * as ErrorData from "./Data/ErrorData.bs.js";
import * as TermsData from "./Data/TermsData.bs.js";
import * as WebConfig from "./Data/WebConfig.bs.js";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as CustomerData from "./Data/CustomerData.bs.js";
import * as HomePageData from "./Data/HomePageData.bs.js";
import * as GiftThemeData from "./Data/GiftThemeData.bs.js";
import * as InventoryData from "./Data/InventoryData.bs.js";
import * as PaymentModels from "../models/PaymentModels.bs.js";
import * as PromoCodeData from "./Data/PromoCodeData.bs.js";
import * as SayThanksData from "./Data/SayThanksData.bs.js";
import * as CategoriesData from "./Data/CategoriesData.bs.js";
import * as ContactErrorData from "./Data/ContactErrorData.bs.js";
import * as SecurityCodeData from "./Data/SecurityCodeData.bs.js";
import * as ResetPasswordData from "./Data/ResetPasswordData.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";
import * as PromotionTermsData from "./Data/PromotionTermsData.bs.js";
import * as SecurityCodeResponseData from "./Data/SecurityCodeResponseData.bs.js";
import * as ValidateCustomerPasswordData from "./Data/ValidateCustomerPasswordData.bs.js";

function resetPassword(data) {
  var payload = ResetPasswordData.Encode.toRequestPayload(data);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var url = $$Request.makeUrl("api/v3/reset-password/");
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined);
  Datadog.Logger.requestInfo("Request " + url, headerlessRequest, $$Request.logger);
  return Js_promise.then_((function (response) {
                Datadog.Logger.responseInfo("Response " + url, response, $$Request.logger);
                var n = response.status;
                if (n !== 200 && n !== 204) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  var e = ErrorData.Decode.safeDecodeError(json);
                                  var tmp;
                                  if (e.TAG === /* Ok */0) {
                                    tmp = e;
                                  } else {
                                    var match = e._0;
                                    switch (match.TAG | 0) {
                                      case /* NetworkError */0 :
                                      case /* JsonParsingError */1 :
                                          tmp = e;
                                          break;
                                      case /* ServerError */2 :
                                          var detail = match._0;
                                          tmp = typeof detail === "number" || detail.TAG !== /* ServerValidationError */0 ? e : ({
                                                TAG: /* Error */1,
                                                _0: {
                                                  TAG: /* ServerError */2,
                                                  _0: {
                                                    TAG: /* ServerValidationError */0,
                                                    _0: detail._0
                                                  }
                                                }
                                              });
                                          break;
                                      
                                    }
                                  }
                                  return Promise.resolve(tmp);
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: response
                            });
                }
              }), fetch(url, request));
}

function validateCustomerPassword(data) {
  var payload = ValidateCustomerPasswordData.Encode.toRequestPayload(data);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var url = $$Request.makeUrl("api/v3/customer/validate-password/");
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  Datadog.Logger.requestInfo("Request " + url, headerlessRequest, $$Request.logger);
  return Js_promise.then_((function (response) {
                Datadog.Logger.responseInfo("Response " + url, response, $$Request.logger);
                var n = response.status;
                if (n !== 200 && n !== 204) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  var e = ErrorData.Decode.safeDecodeError(json);
                                  var tmp;
                                  if (e.TAG === /* Ok */0) {
                                    tmp = e;
                                  } else {
                                    var match = e._0;
                                    switch (match.TAG | 0) {
                                      case /* NetworkError */0 :
                                      case /* JsonParsingError */1 :
                                          tmp = e;
                                          break;
                                      case /* ServerError */2 :
                                          var detail = match._0;
                                          tmp = typeof detail === "number" || detail.TAG !== /* ServerValidationError */0 ? e : ({
                                                TAG: /* Error */1,
                                                _0: {
                                                  TAG: /* ServerError */2,
                                                  _0: {
                                                    TAG: /* ServerValidationError */0,
                                                    _0: detail._0
                                                  }
                                                }
                                              });
                                          break;
                                      
                                    }
                                  }
                                  return Promise.resolve(tmp);
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: response
                            });
                }
              }), fetch(url, request));
}

function securityCode(securityCodeData) {
  var payload = SecurityCodeData.Encode.securityCode(securityCodeData);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var url = $$Request.makeUrl("api/v3/customer/securitycode/request/");
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, SecurityCodeResponseData.Decode.safeDecodeSecurityCodeResponse);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function exchangeInventory(cardUid) {
  var route = "api/v3/inventory/" + (cardUid + "/");
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var url = $$Request.makeUrl(route);
  var request = Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, InventoryData.Decode.safeDecodeInventory);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function validatePhoneNumber(phone) {
  var payload = Js_dict.fromList({
        hd: [
          "phone",
          phone
        ],
        tl: /* [] */0
      });
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var url = $$Request.makeUrl("api/v3/customer/validate-phone/");
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, CustomerData.Decode.safeDecodePhoneValidation);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function getInventoryForProduct(token) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  return Js_promise.$$catch((function (e) {
                Pino.logger.error({
                      err: e,
                      source: "Inventory API"
                    }, "error: Could not retrieve Inventory for Product");
                return Promise.resolve(undefined);
              }), Js_promise.then_((function (json) {
                    return Promise.resolve(InventoryData.Decode.safeDecodeInventoryForData(json));
                  }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl("api/v3/inventory/"), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)))));
}

function getSkuBySlug(slug, token) {
  var route = "api/v3/sku/by-slug/" + slug;
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  return Js_promise.$$catch((function (e) {
                Pino.logger.error({
                      err: e,
                      source: "SKU by Slug API"
                    }, "error: Could not retrieve SKU by Slug");
                return Promise.resolve(undefined);
              }), Js_promise.then_((function (json) {
                    return Promise.resolve(InventoryData.Decode.safeDecodeSku(json));
                  }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl(route), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)))));
}

function getCategories(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var callback = function (response) {
    return $$Request.checkResponse(response, CategoriesData.Decode.safeDecodeCategoriesData);
  };
  var request = Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  return $$Request.makeRequest($$Request.makeUrl("api/v3/categories/"), request, headerlessRequest, callback, false);
}

function getGiftThemes(code, param) {
  var route = code !== undefined ? "api/v3/giftthemes/?sku_code=" + code : "api/v3/giftthemes/";
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  return Js_promise.$$catch((function (e) {
                Pino.logger.error({
                      err: e,
                      source: "Gift Themes API"
                    }, "error: Could not retrieve SKU by Slug Gift Themes failed");
                return Promise.resolve(undefined);
              }), Js_promise.then_((function (json) {
                    return Promise.resolve(GiftThemeData.Decode.safeDecodeGiftData(json));
                  }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl(route), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)))));
}

function openGift(uid, blob, pin) {
  var payload = Js_dict.fromList({
        hd: [
          "pin",
          Belt_Option.getWithDefault(pin, "")
        ],
        tl: /* [] */0
      });
  var route = blob !== undefined ? "api/v3/gift/open/" + (uid + ("/?b=" + blob)) : "api/v3/gift/open/" + (uid + "/");
  var url = $$Request.makeUrl(route);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  var callback = function (response) {
    return $$Request.checkResponse(response, GiftData.Decode.safeDecodeGiftData);
  };
  var initReq;
  var exit = 0;
  if (blob !== undefined && pin !== undefined) {
    initReq = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    initReq = Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  }
  var headerlessRequest;
  var exit$1 = 0;
  if (blob !== undefined && pin !== undefined) {
    headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    headerlessRequest = Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  }
  return $$Request.makeRequest(url, initReq, headerlessRequest, callback, true);
}

function openGiftServer(uid) {
  var route = "api/v3/gift/" + (uid + "/say-thanks/");
  var url = $$Request.makeUrl(route);
  var callback = function (response) {
    return $$Request.checkResponse(response, SayThanksData.Decode.safeDecodeSayThanksData);
  };
  var request = Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  return $$Request.makeRequest(url, request, request, callback, true);
}

function claimGift(uid) {
  var route = "api/v3/gift/claim/" + (uid + "/");
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var url = $$Request.makeUrl(route);
  var request = Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, GiftData.Decode.safeDecodeGiftData);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function validatePromoCode(skuUid, denomination, promoCode, recipientCount) {
  var payload = Js_dict.fromList({
        hd: [
          "sku_uid",
          skuUid
        ],
        tl: {
          hd: [
            "denomination",
            denomination
          ],
          tl: {
            hd: [
              "promo_code",
              promoCode
            ],
            tl: {
              hd: [
                "item_count",
                recipientCount
              ],
              tl: /* [] */0
            }
          }
        }
      });
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var url = $$Request.makeUrl("api/v3/validate-promo-code/");
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, PromoCodeData.Decode.safeDecodePromoCodeData);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function getWebConfigs(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  var url = $$Request.makeUrl("api/v3/config/client/web/");
  var request = Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  return Js_promise.then_((function (json) {
                return Promise.resolve(WebConfig.Decode.safeDecodeWebConfig(json));
              }), Js_promise.then_(Fetch.$$Response.json, fetch(url, request)));
}

function getHomePageData(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  return Js_promise.$$catch((function (e) {
                Pino.logger.error({
                      err: e,
                      source: "Homepage API"
                    }, "error: Could not retrieve Home Page Data");
                return Promise.resolve(undefined);
              }), Js_promise.then_((function (json) {
                    return Promise.resolve(HomePageData.Decode.safeDecodeHomepageData(json));
                  }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl("api/v3/home-page/"), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)))));
}

function getTermsOfSale(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  return Js_promise.then_((function (json) {
                return Promise.resolve(TermsData.Decode.terms(json));
              }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl("api/v3/articles/by-slug/terms-of-sale/"), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))));
}

function getTermsOfService(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  return Js_promise.then_((function (json) {
                return Promise.resolve(TermsData.Decode.terms(json));
              }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl("api/v3/articles/by-slug/terms-of-service/"), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))));
}

function getPromotionTerms(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  return Js_promise.then_((function (json) {
                return Promise.resolve(PromotionTermsData.Decode.promotionTerms(json));
              }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl("api/v3/articles/?type=promo-terms&page_size=100"), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))));
}

function getPromotionTermsSingle(slug) {
  var route = "api/v3/articles/by-slug/" + slug;
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  return Js_promise.then_((function (json) {
                return Promise.resolve(PromotionTermsData.Decode.singlePromotionTerm(json));
              }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl(route), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))));
}

function getPrivacyPolicy(param) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withMagicUserAgentOnServerOnly(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
  return Js_promise.then_((function (json) {
                return Promise.resolve(TermsData.Decode.terms(json));
              }), Js_promise.then_(Fetch.$$Response.json, fetch($$Request.makeUrl("api/v3/articles/by-slug/privacy-policy/"), Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))));
}

function createDraftOrder(checkout) {
  var route;
  if (typeof checkout === "number") {
    route = "";
  } else {
    switch (checkout.TAG | 0) {
      case /* GuestCheckoutSelf */0 :
      case /* GuestCheckoutFriend */1 :
          route = "api/v3/gifts-guest/";
          break;
      case /* UserCheckoutSelf */2 :
          route = "api/v3/cards/";
          break;
      case /* UserCheckoutFriend */3 :
          route = "api/v3/gifts/";
          break;
      
    }
  }
  var payload;
  if (typeof checkout === "number") {
    payload = null;
  } else {
    switch (checkout.TAG | 0) {
      case /* GuestCheckoutSelf */0 :
          payload = Curry._1(PaymentModels.GuestCheckout.ForSelf.Encode.checkout, checkout._0);
          break;
      case /* GuestCheckoutFriend */1 :
          payload = Curry._1(PaymentModels.GuestCheckout.ForAFriend.Encode.checkout, checkout._0);
          break;
      case /* UserCheckoutSelf */2 :
          payload = Curry._1(PaymentModels.UserCheckout.ForSelf.Encode.checkout, checkout._0);
          break;
      case /* UserCheckoutFriend */3 :
          payload = Curry._1(PaymentModels.UserCheckout.ForAFriend.Encode.checkout, checkout._0);
          break;
      
    }
  }
  var headers;
  var exit = 0;
  if (typeof checkout === "number") {
    exit = 2;
  } else {
    switch (checkout.TAG | 0) {
      case /* UserCheckoutSelf */2 :
      case /* UserCheckoutFriend */3 :
          exit = 1;
          break;
      default:
        exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withSecurityToken(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
        break;
    case 2 :
        headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withSecurityToken(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined))));
        break;
    
  }
  var url = $$Request.makeUrl(route);
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, PaymentModels.DraftOrderResponse.Decode.safeDecodeDraftOrderResponse);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function getPaymentIntent(url, paymentIntentRequest) {
  var payload = Curry._1(PaymentModels.PaymentIntentRequest.Encode.paymentIntentRequest, paymentIntentRequest);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withSecurityToken(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse(response, PaymentModels.PaymentIntentResponse.Decode.safeDecodePaymentIntentResponse);
  };
  return $$Request.makeRequest(url, request, headerlessRequest, callback, true);
}

function getBraintreePaymentIntent(url, paymentIntentRequest) {
  var payload = Curry._1(PaymentModels.PaymentIntentRequest.Encode.paymentIntentBraintreeRequest, paymentIntentRequest);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withSecurityToken(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  Datadog.Logger.requestInfo("Request " + url, headerlessRequest, $$Request.logger);
  return Js_promise.then_((function (response) {
                Datadog.Logger.responseInfo("Response " + url, response, $$Request.logger);
                var n = response.status;
                if (n !== 200 && n !== 204) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  return Promise.resolve(ErrorData.Decode.safeDecodeError(json));
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: undefined
                            });
                }
              }), fetch(url, request));
}

function finaliseOrder(orderUrl, finalisePaymentRequest) {
  var payload = Curry._1(PaymentModels.FinalisePaymentRequest.Encode.finalisePaymentRequest, finalisePaymentRequest);
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withSecurityToken(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)))));
  var request = Fetch.RequestInit.make(/* Patch */8, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Patch */8, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var callback = function (response) {
    return $$Request.checkResponse1(response, PaymentModels.Order.Decode.safeDecodeOrder);
  };
  return $$Request.makeRequest(orderUrl, request, headerlessRequest, callback, true);
}

function edmOptIn(email) {
  var payload = Js_dict.fromList({
        hd: [
          "email",
          email
        ],
        tl: /* [] */0
      });
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var url = $$Request.makeUrl("api/v3/subscriptions/");
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  Datadog.Logger.requestInfo("Request " + url, headerlessRequest, $$Request.logger);
  return Js_promise.then_((function (response) {
                Datadog.Logger.responseInfo("Response " + url, response, $$Request.logger);
                return Promise.resolve({
                            TAG: /* Ok */0,
                            _0: response
                          });
              }), fetch(url, request));
}

function getConfig(param) {
  var url = process.env.NEXT_PUBLIC_BFF_ENDPOINT;
  var payload = BFFData.NewsletterConfig.query;
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  return Js_promise.then_((function (response) {
                var bffNewsletterResponse;
                var exit = 0;
                var value;
                try {
                  value = Curry._1(BFFData.NewsletterConfig.Decode.data, response);
                  exit = 1;
                }
                catch (raw_exn){
                  var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                  if (exn.RE_EXN_ID === Json_decode.DecodeError) {
                    bffNewsletterResponse = undefined;
                  } else {
                    throw exn;
                  }
                }
                if (exit === 1) {
                  bffNewsletterResponse = value;
                }
                if (bffNewsletterResponse !== undefined) {
                  return Promise.resolve(Belt_Option.getWithDefault(Caml_option.null_to_opt(bffNewsletterResponse.data.webConfig.featureBffNewsletter), false));
                } else {
                  return Promise.resolve(false);
                }
              }), Js_promise.then_(Fetch.$$Response.json, fetch(url, request)));
}

var BFFNewsletterConfig = {
  getConfig: getConfig
};

function subscribe(email, region, subscribe$1, store) {
  var url = process.env.NEXT_PUBLIC_BFF_ENDPOINT;
  var payload = BFFData.NewsletterSubscription.query(email, region, subscribe$1, store, "newsletter_subscriber");
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var parseResult = function (region, response) {
    if (region === undefined) {
      return {
              TAG: /* Error */1,
              _0: response
            };
    }
    var match = region.regionId;
    if (match === "") {
      return {
              TAG: /* Error */1,
              _0: response
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: response
            };
    }
  };
  return Js_promise.then_((function (response) {
                var edmResponse;
                var exit = 0;
                var value;
                try {
                  value = Curry._1(BFFData.NewsletterSubscription.Decode.data, response);
                  exit = 1;
                }
                catch (raw_exn){
                  var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                  if (exn.RE_EXN_ID === Json_decode.DecodeError) {
                    edmResponse = undefined;
                  } else {
                    throw exn;
                  }
                }
                if (exit === 1) {
                  edmResponse = value;
                }
                if (edmResponse === undefined) {
                  return Promise.resolve({
                              TAG: /* Error */1,
                              _0: response
                            });
                }
                var regions = edmResponse.data.postNewsletterSubscription.regions;
                var match = process.env.NEXT_PUBLIC_APP_COUNTRY_CODE;
                switch (match) {
                  case "AU" :
                      return Promise.resolve(parseResult(regions.au, response));
                  case "GB" :
                      return Promise.resolve(parseResult(regions.uk, response));
                  case "NZ" :
                      return Promise.resolve(parseResult(regions.nz, response));
                  case "US" :
                      return Promise.resolve(parseResult(regions.us, response));
                  default:
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: response
                              });
                }
              }), Js_promise.then_((function (response) {
                    Datadog.Logger.responseInfo("Response " + url, response, $$Request.logger);
                    return Fetch.$$Response.json(response);
                  }), fetch(url, request)));
}

var BFFNewsletter = {
  subscribe: subscribe
};

function contact(name, email, enquiryType, message, phoneNumber) {
  var payload = Js_dict.fromList({
        hd: [
          "full_name",
          name
        ],
        tl: {
          hd: [
            "email",
            email
          ],
          tl: {
            hd: [
              "enquiry_type",
              enquiryType
            ],
            tl: {
              hd: [
                "message",
                message
              ],
              tl: {
                hd: [
                  "phone_number",
                  phoneNumber
                ],
                tl: /* [] */0
              }
            }
          }
        }
      });
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  return Js_promise.then_((function (response) {
                var n = response.status;
                if (n !== 204) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  return Promise.resolve(ContactErrorData.Decode.safeDecodeContactError(json));
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: response
                            });
                }
              }), fetch($$Request.makeUrl("api/v3/contact/"), Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)));
}

function businessLandingContactForm(payload, route) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  return Js_promise.then_((function (response) {
                var n = response.status;
                if (n !== 200) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  return Promise.resolve(ErrorData.Decode.safeDecodeError(json));
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: response
                            });
                }
              }), fetch($$Request.makeUrl(route), request));
}

function uploadTheme(image, imageName, templateUUID) {
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withAuthHeaderIfAvailable(Util.HeadersUtil.acceptContentTypeJson(Util.HeadersUtil.build(undefined))));
  if (!process.browser) {
    return ;
  }
  var formData = new FormData();
  Fetch.$$FormData.set("uid", templateUUID, formData);
  var blob = Util.FileUtil.convertToBlob(image);
  Fetch.$$FormData.appendBlob("image", blob, imageName, formData);
  Js_promise.then_((function (response) {
          Datadog.Logger.responseInfo("Response", response, $$Request.logger);
          return Fetch.$$Response.json(response);
        }), fetch($$Request.makeUrl("api/v3/designs/"), Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)));
}

function sayThanks(uid, message) {
  var payload = Js_dict.fromList({
        hd: [
          "message",
          message
        ],
        tl: /* [] */0
      });
  var route = "api/v3/gift/" + uid + "/say-thanks/";
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var url = $$Request.makeUrl(route);
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var headerlessRequest = Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  Datadog.Logger.requestInfo("Request " + url, headerlessRequest, $$Request.logger);
  return Js_promise.then_((function (response) {
                Datadog.Logger.responseInfo("Response " + url, response, $$Request.logger);
                var n = response.status;
                if (n !== 200) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  return Promise.resolve(ErrorData.Decode.safeDecodeError(json));
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: response
                            });
                }
              }), fetch(url, request));
}

function businessContact(fields) {
  var payload = Js_dict.fromList({
        hd: [
          "form_name",
          "contact_me"
        ],
        tl: {
          hd: [
            "fields",
            fields
          ],
          tl: /* [] */0
        }
      });
  var headers = Util.HeadersUtil.makeWithArray(Util.HeadersUtil.withContentTypeJson(Util.HeadersUtil.build(undefined)));
  var request = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(payload)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  return Js_promise.then_((function (response) {
                var n = response.status;
                if (n !== 200) {
                  if (n >= 400 && n < 500) {
                    return Js_promise.then_((function (json) {
                                  return Promise.resolve(ErrorData.Decode.safeDecodeError(json));
                                }), Fetch.$$Response.json(response));
                  } else {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: ApiErrors.networkError(undefined, n)
                              });
                  }
                } else {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: response
                            });
                }
              }), fetch($$Request.makeUrl("api/v3/hubspot/form/"), request));
}

export {
  resetPassword ,
  validateCustomerPassword ,
  securityCode ,
  exchangeInventory ,
  validatePhoneNumber ,
  getInventoryForProduct ,
  getSkuBySlug ,
  getCategories ,
  getGiftThemes ,
  openGift ,
  openGiftServer ,
  claimGift ,
  validatePromoCode ,
  getWebConfigs ,
  getHomePageData ,
  getTermsOfSale ,
  getTermsOfService ,
  getPromotionTerms ,
  getPromotionTermsSingle ,
  getPrivacyPolicy ,
  createDraftOrder ,
  getPaymentIntent ,
  getBraintreePaymentIntent ,
  finaliseOrder ,
  edmOptIn ,
  BFFNewsletterConfig ,
  BFFNewsletter ,
  contact ,
  businessLandingContactForm ,
  uploadTheme ,
  sayThanks ,
  businessContact ,
}
/* Pino Not a pure module */
