import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

export const validateMfaTokenErrors = {};

/**
 * @secure
 * Request to validate a customers MFA token.
 * @param code Mfa code that requires validating
 * @returns Promise of decoded validate email response.
 */
const validateMfaToken = (code: string) => {
  return createRequest('api/v3/customer/securitycode/validate/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify({ code }),
    withAuth: true,
  }).then(handleErrors);
};

export default validateMfaToken;
