// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlUtils from "../GqlUtils.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Gql__StatsTiles__Decoder__Icon from "./Gql__StatsTiles__Decoder__Icon.bs.js";
import * as Gql__StatsTiles__Decoder__BackgroundImage from "./Gql__StatsTiles__Decoder__BackgroundImage.bs.js";
import * as Gql__StatsTiles__Decoder__BackgroundImageMobile from "./Gql__StatsTiles__Decoder__BackgroundImageMobile.bs.js";

function get(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "EnvironmentalImpactCardContentType") {
    return ;
  }
  var c = content.VAL;
  var smartLink_itemId = c.system.id;
  var smartLink_codename = c.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c.title, (function (c) {
              return c.value;
            })), "");
  var subtitle = Belt_Option.getWithDefault(Belt_Option.flatMap(c.subtitle, (function (c) {
              return c.value;
            })), "");
  var text_colour = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.text_colour, (function (c) {
                              return c.value;
                            })), []), GqlUtils.id), 0), (function (c) {
              return c.codename;
            })), /* Dark */0, (function (str) {
          switch (str) {
            case "dark" :
                return /* Dark */0;
            case "light" :
                return /* Light */1;
            default:
              return /* Dark */0;
          }
        }));
  var icon = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.icon, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__StatsTiles__Decoder__Icon.get), 0);
  var fontawesome_icon_class = Belt_Option.flatMap(c.fontawesome_icon_class, (function (c) {
          return c.value;
        }));
  var background_image = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__StatsTiles__Decoder__BackgroundImage.get), 0);
  var background_image_mobile = Belt_Array.get(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_image_mobile, (function (c) {
                          return c.value;
                        })), []), GqlUtils.id), Gql__StatsTiles__Decoder__BackgroundImageMobile.get), 0);
  var background_colour = Belt_Option.getWithDefault(Belt_Option.flatMap(c.background_colour, (function (c) {
              return c.value;
            })), "");
  return {
          smartLink: smartLink,
          title: title,
          subtitle: subtitle,
          text_colour: text_colour,
          icon: icon,
          fontawesome_icon_class: fontawesome_icon_class,
          background_image: background_image,
          background_image_mobile: background_image_mobile,
          background_colour: background_colour
        };
}

var Icon;

var StatsTileIcon;

var $$Image;

var BackgroundImage;

var BackgroundImageMobile;

export {
  Icon ,
  StatsTileIcon ,
  $$Image ,
  BackgroundImage ,
  BackgroundImageMobile ,
  get ,
}
/* No side effect */
