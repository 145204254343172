// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__Image__Fragment from "../Image/Gql__Image__Fragment.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment SeoMetaBlock on SeoMetaContentType   {
    __typename
    system  {
      __typename
      codename
      language
    }
    seo_meta__basic__title  {
      __typename
      value
    }
    seo_meta__basic__description  {
      __typename
      value
    }
    seo_meta__og__title  {
      __typename
      value
    }
    seo_meta__og__description  {
      __typename
      value
    }
    seo_meta__og__image_asset  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    seo_meta__og__type  {
      __typename
      value
    }
    seo_meta__og__url  {
      __typename
      value
    }
    seo_meta__tw__title  {
      __typename
      value
    }
    seo_meta__tw__description  {
      __typename
      value
    }
    seo_meta__tw__image_asset  {
      __typename
      value  {
        __typename
        ...on ImageContentType   {
          ...Image
        }
      }
    }
    seo_meta__tw__image_asset_url  {
      __typename
      value
    }
  }
  ${frag_0}
`)(Gql__Image__Fragment.$$Image.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.seo_meta__basic__title;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.seo_meta__basic__description;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? value$5 : undefined
    };
  }
  var value$6 = value.seo_meta__og__title;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? value$7 : undefined
    };
  }
  var value$8 = value.seo_meta__og__description;
  var tmp$3;
  if (value$8 == null) {
    tmp$3 = undefined;
  } else {
    var value$9 = value$8.value;
    tmp$3 = {
      __typename: value$8.__typename,
      value: !(value$9 == null) ? value$9 : undefined
    };
  }
  var value$10 = value.seo_meta__og__image_asset;
  var tmp$4;
  if (value$10 == null) {
    tmp$4 = undefined;
  } else {
    var value$11 = value$10.value;
    tmp$4 = {
      __typename: value$10.__typename,
      value: !(value$11 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$11) : undefined
    };
  }
  var value$12 = value.seo_meta__og__type;
  var tmp$5;
  if (value$12 == null) {
    tmp$5 = undefined;
  } else {
    var value$13 = value$12.value;
    tmp$5 = {
      __typename: value$12.__typename,
      value: !(value$13 == null) ? value$13 : undefined
    };
  }
  var value$14 = value.seo_meta__og__url;
  var tmp$6;
  if (value$14 == null) {
    tmp$6 = undefined;
  } else {
    var value$15 = value$14.value;
    tmp$6 = {
      __typename: value$14.__typename,
      value: !(value$15 == null) ? value$15 : undefined
    };
  }
  var value$16 = value.seo_meta__tw__title;
  var tmp$7;
  if (value$16 == null) {
    tmp$7 = undefined;
  } else {
    var value$17 = value$16.value;
    tmp$7 = {
      __typename: value$16.__typename,
      value: !(value$17 == null) ? value$17 : undefined
    };
  }
  var value$18 = value.seo_meta__tw__description;
  var tmp$8;
  if (value$18 == null) {
    tmp$8 = undefined;
  } else {
    var value$19 = value$18.value;
    tmp$8 = {
      __typename: value$18.__typename,
      value: !(value$19 == null) ? value$19 : undefined
    };
  }
  var value$20 = value.seo_meta__tw__image_asset;
  var tmp$9;
  if (value$20 == null) {
    tmp$9 = undefined;
  } else {
    var value$21 = value$20.value;
    tmp$9 = {
      __typename: value$20.__typename,
      value: !(value$21 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "ImageContentType" ? ({
                      NAME: "ImageContentType",
                      VAL: {
                        image: Gql__Image__Fragment.$$Image.verifyArgsAndParse("Image", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$21) : undefined
    };
  }
  var value$22 = value.seo_meta__tw__image_asset_url;
  var tmp$10;
  if (value$22 == null) {
    tmp$10 = undefined;
  } else {
    var value$23 = value$22.value;
    tmp$10 = {
      __typename: value$22.__typename,
      value: !(value$23 == null) ? value$23 : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            codename: value$1.codename,
            language: value$1.language
          },
          seo_meta__basic__title: tmp,
          seo_meta__basic__description: tmp$1,
          seo_meta__og__title: tmp$2,
          seo_meta__og__description: tmp$3,
          seo_meta__og__image_asset: tmp$4,
          seo_meta__og__type: tmp$5,
          seo_meta__og__url: tmp$6,
          seo_meta__tw__title: tmp$7,
          seo_meta__tw__description: tmp$8,
          seo_meta__tw__image_asset: tmp$9,
          seo_meta__tw__image_asset_url: tmp$10
        };
}

function serialize(value) {
  var value$1 = value.seo_meta__tw__image_asset_url;
  var seo_meta__tw__image_asset_url;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? value$2 : null;
    var value$4 = value$1.__typename;
    seo_meta__tw__image_asset_url = {
      __typename: value$4,
      value: value$3
    };
  } else {
    seo_meta__tw__image_asset_url = null;
  }
  var value$5 = value.seo_meta__tw__image_asset;
  var seo_meta__tw__image_asset;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    seo_meta__tw__image_asset = {
      __typename: value$8,
      value: value$7
    };
  } else {
    seo_meta__tw__image_asset = null;
  }
  var value$9 = value.seo_meta__tw__description;
  var seo_meta__tw__description;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? value$10 : null;
    var value$12 = value$9.__typename;
    seo_meta__tw__description = {
      __typename: value$12,
      value: value$11
    };
  } else {
    seo_meta__tw__description = null;
  }
  var value$13 = value.seo_meta__tw__title;
  var seo_meta__tw__title;
  if (value$13 !== undefined) {
    var value$14 = value$13.value;
    var value$15 = value$14 !== undefined ? value$14 : null;
    var value$16 = value$13.__typename;
    seo_meta__tw__title = {
      __typename: value$16,
      value: value$15
    };
  } else {
    seo_meta__tw__title = null;
  }
  var value$17 = value.seo_meta__og__url;
  var seo_meta__og__url;
  if (value$17 !== undefined) {
    var value$18 = value$17.value;
    var value$19 = value$18 !== undefined ? value$18 : null;
    var value$20 = value$17.__typename;
    seo_meta__og__url = {
      __typename: value$20,
      value: value$19
    };
  } else {
    seo_meta__og__url = null;
  }
  var value$21 = value.seo_meta__og__type;
  var seo_meta__og__type;
  if (value$21 !== undefined) {
    var value$22 = value$21.value;
    var value$23 = value$22 !== undefined ? value$22 : null;
    var value$24 = value$21.__typename;
    seo_meta__og__type = {
      __typename: value$24,
      value: value$23
    };
  } else {
    seo_meta__og__type = null;
  }
  var value$25 = value.seo_meta__og__image_asset;
  var seo_meta__og__image_asset;
  if (value$25 !== undefined) {
    var value$26 = value$25.value;
    var value$27 = value$26 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "UnspecifiedFragment") {
                  return {};
                } else {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__Image__Fragment.$$Image.serialize(value.VAL.image)]);
                }
              } else {
                return null;
              }
            }), value$26) : null;
    var value$28 = value$25.__typename;
    seo_meta__og__image_asset = {
      __typename: value$28,
      value: value$27
    };
  } else {
    seo_meta__og__image_asset = null;
  }
  var value$29 = value.seo_meta__og__description;
  var seo_meta__og__description;
  if (value$29 !== undefined) {
    var value$30 = value$29.value;
    var value$31 = value$30 !== undefined ? value$30 : null;
    var value$32 = value$29.__typename;
    seo_meta__og__description = {
      __typename: value$32,
      value: value$31
    };
  } else {
    seo_meta__og__description = null;
  }
  var value$33 = value.seo_meta__og__title;
  var seo_meta__og__title;
  if (value$33 !== undefined) {
    var value$34 = value$33.value;
    var value$35 = value$34 !== undefined ? value$34 : null;
    var value$36 = value$33.__typename;
    seo_meta__og__title = {
      __typename: value$36,
      value: value$35
    };
  } else {
    seo_meta__og__title = null;
  }
  var value$37 = value.seo_meta__basic__description;
  var seo_meta__basic__description;
  if (value$37 !== undefined) {
    var value$38 = value$37.value;
    var value$39 = value$38 !== undefined ? value$38 : null;
    var value$40 = value$37.__typename;
    seo_meta__basic__description = {
      __typename: value$40,
      value: value$39
    };
  } else {
    seo_meta__basic__description = null;
  }
  var value$41 = value.seo_meta__basic__title;
  var seo_meta__basic__title;
  if (value$41 !== undefined) {
    var value$42 = value$41.value;
    var value$43 = value$42 !== undefined ? value$42 : null;
    var value$44 = value$41.__typename;
    seo_meta__basic__title = {
      __typename: value$44,
      value: value$43
    };
  } else {
    seo_meta__basic__title = null;
  }
  var value$45 = value.system;
  var value$46 = value$45.language;
  var value$47 = value$45.codename;
  var value$48 = value$45.__typename;
  var system = {
    __typename: value$48,
    codename: value$47,
    language: value$46
  };
  var value$49 = value.__typename;
  return {
          __typename: value$49,
          system: system,
          seo_meta__basic__title: seo_meta__basic__title,
          seo_meta__basic__description: seo_meta__basic__description,
          seo_meta__og__title: seo_meta__og__title,
          seo_meta__og__description: seo_meta__og__description,
          seo_meta__og__image_asset: seo_meta__og__image_asset,
          seo_meta__og__type: seo_meta__og__type,
          seo_meta__og__url: seo_meta__og__url,
          seo_meta__tw__title: seo_meta__tw__title,
          seo_meta__tw__description: seo_meta__tw__description,
          seo_meta__tw__image_asset: seo_meta__tw__image_asset,
          seo_meta__tw__image_asset_url: seo_meta__tw__image_asset_url
        };
}

function verifyArgsAndParse(_SeoMetaBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SeoMetaBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var $$Image;

export {
  $$Image ,
  SeoMetaBlock ,
}
/* query Not a pure module */
