// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(mailItem) {
  var smartLink_itemId = mailItem.system.id;
  var smartLink_codename = mailItem.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  var link_text = Belt_Option.getWithDefault(Belt_Option.flatMap(mailItem.link_title, (function (c) {
              return c.value;
            })), "");
  var email_address = Belt_Option.getWithDefault(Belt_Option.flatMap(mailItem.email_address, (function (c) {
              return c.value;
            })), "");
  return {
          link_text: link_text,
          email_address: email_address,
          smartLink: smartLink
        };
}

var LinkItemIcon;

export {
  LinkItemIcon ,
  get ,
}
/* No side effect */
