// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Gql__EnrichedTextBlock__Fragment from "../EnrichedTextBlock/Gql__EnrichedTextBlock__Fragment.bs.js";
import * as Gql__SmartCardHeroBlock__Fragment from "../SmartCardHeroBlock/Gql__SmartCardHeroBlock__Fragment.bs.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  fragment StoreContentBlock on StoreContentContentType   {
    __typename
    system  {
      __typename
      codename
      language
    }
    smart_card_content  {
      __typename
      value  {
        __typename
        ...on EnrichedTextBlockContentType   {
          ...EnrichedTextBlock
        }
        ...on SmartCardHeroBlockContentType   {
          ...SmartCardHeroBlock
        }
      }
    }
    retailer_card_content  {
      __typename
      value  {
        __typename
        ...on EnrichedTextBlockContentType   {
          ...EnrichedTextBlock
        }
      }
    }
    end_of_page_content  {
      __typename
      value  {
        __typename
        ...on EnrichedTextBlockContentType   {
          ...EnrichedTextBlock
        }
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.query, Gql__SmartCardHeroBlock__Fragment.SmartCardHeroBlock.query);

function parse(value) {
  var value$1 = value.system;
  var value$2 = value.smart_card_content;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.value;
    tmp = {
      __typename: value$2.__typename,
      value: !(value$3 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp;
                switch (typename) {
                  case "EnrichedTextBlockContentType" :
                      tmp = {
                        NAME: "EnrichedTextBlockContentType",
                        VAL: {
                          enrichedTextBlock: Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.verifyArgsAndParse("EnrichedTextBlock", value)
                        }
                      };
                      break;
                  case "SmartCardHeroBlockContentType" :
                      tmp = {
                        NAME: "SmartCardHeroBlockContentType",
                        VAL: {
                          smartCardHeroBlock: Gql__SmartCardHeroBlock__Fragment.SmartCardHeroBlock.verifyArgsAndParse("SmartCardHeroBlock", value)
                        }
                      };
                      break;
                  default:
                    tmp = {
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    };
                }
                return tmp;
              }), value$3) : undefined
    };
  }
  var value$4 = value.retailer_card_content;
  var tmp$1;
  if (value$4 == null) {
    tmp$1 = undefined;
  } else {
    var value$5 = value$4.value;
    tmp$1 = {
      __typename: value$4.__typename,
      value: !(value$5 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "EnrichedTextBlockContentType" ? ({
                      NAME: "EnrichedTextBlockContentType",
                      VAL: {
                        enrichedTextBlock: Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.verifyArgsAndParse("EnrichedTextBlock", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$5) : undefined
    };
  }
  var value$6 = value.end_of_page_content;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var value$7 = value$6.value;
    tmp$2 = {
      __typename: value$6.__typename,
      value: !(value$7 == null) ? Js_array.map((function (value) {
                if (value == null) {
                  return ;
                }
                var typename = value["__typename"];
                var tmp = typename === "EnrichedTextBlockContentType" ? ({
                      NAME: "EnrichedTextBlockContentType",
                      VAL: {
                        enrichedTextBlock: Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.verifyArgsAndParse("EnrichedTextBlock", value)
                      }
                    }) : ({
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    });
                return tmp;
              }), value$7) : undefined
    };
  }
  return {
          __typename: value.__typename,
          system: {
            __typename: value$1.__typename,
            codename: value$1.codename,
            language: value$1.language
          },
          smart_card_content: tmp,
          retailer_card_content: tmp$1,
          end_of_page_content: tmp$2
        };
}

function serialize(value) {
  var value$1 = value.end_of_page_content;
  var end_of_page_content;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    var value$3 = value$2 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "EnrichedTextBlockContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.serialize(value.VAL.enrichedTextBlock)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$2) : null;
    var value$4 = value$1.__typename;
    end_of_page_content = {
      __typename: value$4,
      value: value$3
    };
  } else {
    end_of_page_content = null;
  }
  var value$5 = value.retailer_card_content;
  var retailer_card_content;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    var value$7 = value$6 !== undefined ? Js_array.map((function (value) {
              if (value !== undefined) {
                if (value.NAME === "EnrichedTextBlockContentType") {
                  return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                                return {};
                              }), [Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.serialize(value.VAL.enrichedTextBlock)]);
                } else {
                  return {};
                }
              } else {
                return null;
              }
            }), value$6) : null;
    var value$8 = value$5.__typename;
    retailer_card_content = {
      __typename: value$8,
      value: value$7
    };
  } else {
    retailer_card_content = null;
  }
  var value$9 = value.smart_card_content;
  var smart_card_content;
  if (value$9 !== undefined) {
    var value$10 = value$9.value;
    var value$11 = value$10 !== undefined ? Js_array.map((function (value) {
              if (value === undefined) {
                return null;
              }
              var variant = value.NAME;
              if (variant === "UnspecifiedFragment") {
                return {};
              } else if (variant === "EnrichedTextBlockContentType") {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__EnrichedTextBlock__Fragment.EnrichedTextBlock.serialize(value.VAL.enrichedTextBlock)]);
              } else {
                return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                              return {};
                            }), [Gql__SmartCardHeroBlock__Fragment.SmartCardHeroBlock.serialize(value.VAL.smartCardHeroBlock)]);
              }
            }), value$10) : null;
    var value$12 = value$9.__typename;
    smart_card_content = {
      __typename: value$12,
      value: value$11
    };
  } else {
    smart_card_content = null;
  }
  var value$13 = value.system;
  var value$14 = value$13.language;
  var value$15 = value$13.codename;
  var value$16 = value$13.__typename;
  var system = {
    __typename: value$16,
    codename: value$15,
    language: value$14
  };
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          system: system,
          smart_card_content: smart_card_content,
          retailer_card_content: retailer_card_content,
          end_of_page_content: end_of_page_content
        };
}

function verifyArgsAndParse(_StoreContentBlock, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var StoreContentBlock = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var EnrichedTextBlock;

var SmartCardHeroBlock;

export {
  EnrichedTextBlock ,
  SmartCardHeroBlock ,
  StoreContentBlock ,
}
/* query Not a pure module */
