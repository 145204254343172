// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";

function get(c) {
  if (typeof c !== "object") {
    return ;
  }
  if (c.NAME !== "LoginContentType") {
    return ;
  }
  var c$1 = c.VAL;
  var title = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.title, (function (c) {
              return c.value;
            })), "");
  var subtitle = Belt_Option.flatMap(c$1.subtitle, (function (c) {
          return c.value;
        }));
  var emailLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.email_label, (function (c) {
              return c.value;
            })), "");
  var passwordLabel = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.password_label, (function (c) {
              return c.value;
            })), "");
  var requiredEmailText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.required_email_text, (function (c) {
              return c.value;
            })), "");
  var requiredPasswordText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.required_password_text, (function (c) {
              return c.value;
            })), "");
  var invalidEmailText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.invalid_email_text, (function (c) {
              return c.value;
            })), "");
  var submitCtaText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.submit_cta_text, (function (c) {
              return c.value;
            })), "");
  var submitCtaSubmittingText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.submit_cta_submitting_text, (function (c) {
              return c.value;
            })), "");
  var forgotPasswordText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.forgot_password_text, (function (c) {
              return c.value;
            })), "");
  var forgotEmailText = Belt_Option.getWithDefault(Belt_Option.flatMap(c$1.forgot_email_text, (function (c) {
              return c.value;
            })), "");
  var smartLink_itemId = c$1.system.id;
  var smartLink_codename = c$1.system.codename;
  var smartLink = {
    itemId: smartLink_itemId,
    codename: smartLink_codename
  };
  return {
          title: title,
          subtitle: subtitle,
          emailLabel: emailLabel,
          passwordLabel: passwordLabel,
          requiredEmailText: requiredEmailText,
          requiredPasswordText: requiredPasswordText,
          invalidEmailText: invalidEmailText,
          submitCtaText: submitCtaText,
          submitCtaSubmittingText: submitCtaSubmittingText,
          forgotPasswordText: forgotPasswordText,
          forgotEmailText: forgotEmailText,
          smartLink: smartLink
        };
}

export {
  get ,
}
/* No side effect */
