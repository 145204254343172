// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorData from "./ErrorData.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function t(json) {
  return {
          senderName: Json_decode.field("sender_name", Json_decode.string, json),
          amount: Json_decode.field("amount", Json_decode.string, json),
          currency: Json_decode.field("currency", Json_decode.string, json),
          sku: Json_decode.field("sku", Json_decode.string, json),
          thanksGivenAt: Json_decode.optional((function (param) {
                  return Json_decode.field("thanks_given_at", Json_decode.string, param);
                }), json),
          thanksMessage: Json_decode.optional((function (param) {
                  return Json_decode.field("thanks_message", Json_decode.string, param);
                }), json)
        };
}

function safeDecodeSayThanksData(json) {
  var result;
  try {
    result = t(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ErrorData.Decode.safeDecodeError(json);
    }
    throw exn;
  }
  return {
          TAG: /* Ok */0,
          _0: result
        };
}

var Decode = {
  t: t,
  safeDecodeSayThanksData: safeDecodeSayThanksData
};

export {
  Decode ,
}
/* No side effect */
